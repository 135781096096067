import * as React from 'react';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import {
  TooltippedCell,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from '@shared/components';

import type { IFOSupplierUser } from '@inteliam/foundation/lib/types';

const EnterpriseUserOptionLabel: React.FCC<{ user: IFOSupplierUser }> = ({
  user,
}) => {
  const { t } = useEssentials();
  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell width={150} align='center'>
              <TooltippedCell
                textOverflowHidden
                title={user.contactInfo.firstName}
              >
                <span>{user.contactInfo.firstName}</span>
              </TooltippedCell>
            </TableCell>
            <TableCell width={150} align='center'>
              <TooltippedCell
                textOverflowHidden
                title={user.contactInfo.lastName}
              >
                <span>{user.contactInfo.lastName}</span>
              </TooltippedCell>
            </TableCell>
            <TableCell width={350} align='center'>
              <span>
                {UsersUtils.getTranslatedUserRoles(user.roles, t).join(' - ')}
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EnterpriseUserOptionLabel;
