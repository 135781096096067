import { yupResolver } from '@hookform/resolvers/yup';
import {
  IARStatusRankEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  DateFormatter,
  PriceUtils,
  Validations,
} from '@inteliam/foundation/lib/utils';
import { defaults, set } from 'lodash-es';

import React, { memo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  QUERY_KEYS,
  useFetchCampaignTypeByType,
  useFetchPricingCategories,
  useQuery,
} from '@core/queries';
import useFetchPricingGrid from '@core/queries/use-fetch-pricing-grid';

import { ARUtils, SubscriptionsUtils } from '@core/utils';

import { IDiscountPeriodsEnum, IDiscountTypesEnum } from '@core/enums';

import { CountriesApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type {
  IARDeadlineFormKey,
  IPartialInitiateSurveyForm,
} from '@core/types';

import {
  Button,
  Controls,
  FormGroup,
  Grid,
  Typography,
} from '@shared/components';

import { makeStyles, ValidationUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  IAR,
  IDiscountData,
  IStatus,
  PricingCategory,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';
import { initiateSurveySchema } from '../update-deadlines/schemas';
import { InitiateSurveyDiscountsForm, InitiateSurveyEmailingFields } from './';
import PricingGrid from './pricing-grid';

interface Props {
  title?: React.ReactNode;
  open: boolean;
  isLoading: boolean;
  errors?: ValidationErrorResponse;
  onClose: () => void;
  assessmentRequest: IAR;
  statuses: IStatus[];
  defaultValues?: IPartialInitiateSurveyForm;
  onSubmit: (data: IPartialInitiateSurveyForm) => void;
  manufacturerDiscount: IDiscountData | undefined;
  hasActiveDiscount: boolean;
}

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.anchor?.main,
    textDecoration: 'underline',
    marginLeft: '20px',
  },
}));

const DEFAULT_DISCOUNT_VALUES_1_YEAR: IDiscountData = {
  type: IDiscountTypesEnum.TYPE_PERCENTAGE,
  percentage: 0,
  period: IDiscountPeriodsEnum.PERIOD_1_YEAR,
  enabled: false,
};
const DEFAULT_DISCOUNT_VALUES_3_YEAR: IDiscountData = {
  type: IDiscountTypesEnum.TYPE_PERCENTAGE,
  percentage: 0,
  period: IDiscountPeriodsEnum.PERIOD_3_YEARS,
  enabled: false,
};

const INITIAL_DEFAULT_VALUES: IPartialInitiateSurveyForm = Object.values(
  IARStatusRankEnum
).reduce(
  (accumulator, rank) => {
    const key: IARDeadlineFormKey = `statuses.${
      rank as IARStatusRankEnum
    }.deadline`;
    set(accumulator, key, new Date());
    return accumulator;
  },
  {
    subscriptionCategory: 'LESS_THAN_50',
    inteliamDiscounts: {
      '1_YEAR': DEFAULT_DISCOUNT_VALUES_1_YEAR,
      '3_YEAR': DEFAULT_DISCOUNT_VALUES_3_YEAR,
    },
    manufacturerDiscounts: {
      '1_YEAR': DEFAULT_DISCOUNT_VALUES_1_YEAR,
      '3_YEAR': DEFAULT_DISCOUNT_VALUES_3_YEAR,
    },
    inteliamDiscountEnabled: false,
    manufacturerDiscountEnabled: false,
  } as IPartialInitiateSurveyForm
);

const InitiateSurveyForm: React.FCC<Props> = ({
  onClose,
  title,
  defaultValues,
  statuses,
  open,
  onSubmit,
  errors,
  isLoading,
  hasActiveDiscount,
  manufacturerDiscount,
  assessmentRequest,
}) => {
  const { t, na } = useEssentials();
  const classes = useStyles();
  const { formattedPricingCategories } = useFetchPricingCategories();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });

  const { pricingGrid } = useFetchPricingGrid();

  const countryCode =
    assessmentRequest.scoredCompany.address.country?.code ?? 'NOT_FOUND';
  const query = useQuery<{ classification: number }, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.COUNTRIES, countryCode],
    () =>
      CountriesApi.getCountryClassification(
        assessmentRequest.scoredCompany.address.country?.code ?? 'NOT_FOUND'
      ),
    {
      cacheTime: Number.POSITIVE_INFINITY,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const countryClassification = query.data?.classification ?? 2;

  const manufacturerDiscountIs1Year =
    manufacturerDiscount?.period === IDiscountPeriodsEnum.PERIOD_1_YEAR;

  const methods = useForm<IPartialInitiateSurveyForm>({
    defaultValues: {
      ...INITIAL_DEFAULT_VALUES,
      ...defaultValues,
      manufacturerDiscountEnabled:
        hasActiveDiscount === undefined ? false : hasActiveDiscount,
      manufacturerDiscounts: hasActiveDiscount
        ? {
            '1_YEAR': manufacturerDiscountIs1Year
              ? defaults(manufacturerDiscount, DEFAULT_DISCOUNT_VALUES_1_YEAR)
              : undefined,
            '3_YEAR': manufacturerDiscountIs1Year
              ? undefined
              : defaults(manufacturerDiscount, DEFAULT_DISCOUNT_VALUES_3_YEAR),
          }
        : {
            '1_YEAR': DEFAULT_DISCOUNT_VALUES_1_YEAR,
            '3_YEAR': DEFAULT_DISCOUNT_VALUES_3_YEAR,
          },
      inteliamDiscountEnabled: false,
      inteliamDiscounts: {
        '1_YEAR': DEFAULT_DISCOUNT_VALUES_1_YEAR,
        '3_YEAR': DEFAULT_DISCOUNT_VALUES_3_YEAR,
      },
    },
    resolver: yupResolver(initiateSurveySchema),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const watchedManufacturerDiscounts = methods.watch('manufacturerDiscounts');
  const watchedInteliamDiscounts = methods.watch('inteliamDiscounts');

  const subscriptionCategory = useWatch({
    control: methods.control,
    name: 'subscriptionCategory',
  });

  const [gridPriceOpen, setGridPriceOpen] = useState<boolean>(false);

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: (name, error) =>
          methods.setError(name as keyof IPartialInitiateSurveyForm, error),
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, methods.setError]);
  if (!campaignTypeQuery.isSettled) {
    return <BaseDialog.DialogPlaceholder />;
  }

  if (!campaignTypeQuery.isSuccess) {
    return (
      <BaseDialog.DialogPlaceholder>
        {t('Unable to find the requested standard campaign')}
      </BaseDialog.DialogPlaceholder>
    );
  }

  const sameTypeFor1Year =
    watchedManufacturerDiscounts?.['1_YEAR']?.enabled &&
    watchedInteliamDiscounts?.['1_YEAR']?.enabled
      ? watchedManufacturerDiscounts?.['1_YEAR']?.type ===
        watchedInteliamDiscounts?.['1_YEAR']?.type
      : true;
  const sameTypeFor3Years =
    watchedManufacturerDiscounts?.['3_YEAR']?.enabled &&
    watchedInteliamDiscounts?.['3_YEAR']?.enabled
      ? watchedManufacturerDiscounts?.['3_YEAR']?.type ===
        watchedInteliamDiscounts?.['3_YEAR']?.type
      : true;
  const finalOneYearSubscriptionPrice = SubscriptionsUtils.applyDiscount(
    SubscriptionsUtils.getSubscriptionPrice(
      pricingGrid,
      subscriptionCategory as PricingCategory,
      'SUBSCRIPTION_STANDARD_1_YEAR',
      countryClassification
    )?.value ?? 0,
    SubscriptionsUtils.getOneYearDiscount(
      watchedManufacturerDiscounts?.['1_YEAR'],
      watchedInteliamDiscounts?.['1_YEAR']
    )
  );

  const finalThreeYearsSubscriptionPrice = SubscriptionsUtils.applyDiscount(
    3 *
      (SubscriptionsUtils.getSubscriptionPrice(
        pricingGrid,
        subscriptionCategory as PricingCategory,
        'SUBSCRIPTION_STANDARD_1_YEAR',
        countryClassification
      )?.value ?? 0),
    SubscriptionsUtils.getThreeYearsDiscount(
      watchedManufacturerDiscounts?.['3_YEAR'],
      watchedInteliamDiscounts?.['3_YEAR']
    )
  );

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <Controls.Form methods={methods} submitHandler={onSubmit}>
        <BaseDialog.Title id='Update Deadline' onClose={onClose}>
          <span>{title || t('Update Deadline')}</span>
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container rowSpacing={3}>
            <h3>{t('Deadlines')}</h3>
            {statuses
              .filter((status) => status.isDeadlinable)
              .map((status) => {
                const name = `statuses.${status.rank}.deadline`;
                return (
                  <Grid item md={12} key={status.codename}>
                    <FormGroup>
                      <Controls.FormDatePicker
                        inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
                        name={name}
                        label={t(status.codename)}
                        onChange={(date) => {
                          const nextDates =
                            ARUtils.calculateNextDefaultDeadlines({
                              assessmentRequest,
                              standardCampaignType: campaignTypeQuery.data.data,
                              updatedStatus: status,
                              updatedDate: date as Date,
                            });

                          nextDates.forEach((newRankedDeadline) => {
                            const statusFieldName =
                              `statuses.${newRankedDeadline.rank}.deadline` as IARDeadlineFormKey;

                            methods.setValue(
                              statusFieldName,
                              newRankedDeadline.deadline
                            );
                          });
                        }}
                        pickerType='date'
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        componentsProps={{
                          actionBar: {
                            actions: ['today'],
                          },
                        }}
                      />
                    </FormGroup>
                  </Grid>
                );
              })}
            <Typography variant='h5'>{t('Subscriptions')}</Typography>
            <Grid item md={12} style={{ padding: 0 }}>
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='subscriptionCategory'
                  label={t(
                    'Please select the subscription fees for {{distributorName}}  (Annual gross sales: {{distributorGrossSales}})',
                    {
                      distributorName: assessmentRequest.scoredCompany.name,
                      distributorGrossSales: `${
                        PriceUtils.getDecomposedValue(
                          assessmentRequest.scoredCompany.turnover?.value
                        ).value ?? 0
                      } ${
                        PriceUtils.getDecomposedValue(
                          assessmentRequest.scoredCompany.turnover?.value
                        ).unit
                      }`,
                    }
                  )}
                  options={formattedPricingCategories}
                />
              </FormGroup>
            </Grid>

            <p className={classes.link} onClick={() => setGridPriceOpen(true)}>
              {t('See pricing table')}
            </p>
            {gridPriceOpen && (
              <PricingGrid onClose={() => setGridPriceOpen(false)} />
            )}
            <InitiateSurveyDiscountsForm />
            <Grid item md={12} xs={12} display='grid' justifyContent='center'>
              <Typography>
                {`Final Price for 1-year Subscription: ${
                  subscriptionCategory === undefined || !sameTypeFor1Year
                    ? na
                    : `€ ${finalOneYearSubscriptionPrice}`
                }
                `}
              </Typography>
              <Typography>
                {`Final Price for 3-year Subscription: ${
                  subscriptionCategory === undefined || !sameTypeFor3Years
                    ? na
                    : `€ ${finalThreeYearsSubscriptionPrice}`
                }
                `}
              </Typography>
            </Grid>

            <InitiateSurveyEmailingFields
              assessmentRequest={assessmentRequest}
            />
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-initial-survey'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-initial-survey'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(InitiateSurveyForm);
