import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { CampaignsApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  ICampaignType,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

const useFetchCampaignTypes = (): InteliamUseQueryResult<
  IPaginationResponse<ICampaignType>,
  BaseAxiosErrorResponse
> & {
  campaignTypes: ICampaignType[];
} => {
  const query = useQuery<
    IPaginationResponse<ICampaignType>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.OPEN_CAMPAIGN_TYPES_LIST],
    () => {
      return CampaignsApi.paginateTypes({
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          operator: 'or',
          conditions: [],
        },
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData:
        DatatableUtils.createInitialPaginationResponse<ICampaignType>(),
    }
  );

  const campaignTypes = Helpers.ensureValueAsArray(query.data?.data);

  return {
    ...query,

    campaignTypes,
  };
};

export default useFetchCampaignTypes;
