import { yupResolver } from '@hookform/resolvers/yup';
import { IQuestionStatusEnum } from '@inteliam/foundation/lib/enums';
import { useBoolean, useOnMount } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo, useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormActions } from '@core/components';

import { DndUtils, SsqQuestionnaireUtils } from '@core/utils';

import { SSQ_STATUSES } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type {
  IMinimalSsqQuestion,
  ISsqQuestionnaireDefinition,
  ISsqQuestionnaireDefinitionFormData,
} from '@core/types';

import {
  Alert,
  Button,
  Controls,
  Grid,
  Box,
  FormGroup,
  Card,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IAnyEnterprise,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { SsqQuestionList, SsqQuestionsModal } from '.';
import { create as schema } from './schema';

interface Props {
  onSubmit: (data: ISsqQuestionnaireDefinitionFormData) => void;
  defaultValues?: ISsqQuestionnaireDefinition;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  manufacturer: IAnyEnterprise;
  mode: 'CREATE' | 'EDIT';
}

const Form: React.FCC<Props> = ({
  manufacturer,
  defaultValues,
  onSubmit,
  isLoading,
  errors,
  mode,
}) => {
  const { t, navigate } = useEssentials();
  const [open, { setFalse: onClose, setTrue: onOpen }] = useBoolean(false);
  const methods = useForm<ISsqQuestionnaireDefinitionFormData>({
    defaultValues: SsqQuestionnaireUtils.normalizer.initializeForm(
      manufacturer,
      defaultValues
    ),
    resolver: yupResolver(schema),
  });
  useOnMount(() => {
    methods.register('questions');
  });
  const watchedQuestions = useWatch({
    control: methods.control,
    name: 'questions',
    defaultValue: defaultValues?.questions ?? [],
  });
  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const handleMove = useCallback(
    (a: number, b: number) => {
      const questions = methods.getValues().questions;
      const ordered = DndUtils.reorder(questions, a, b);
      methods.setValue('questions', ordered);
    },
    [methods]
  );

  const handleRemove = useCallback(
    (questionId: string) => {
      const questions = methods.getValues().questions;

      methods.setValue(
        'questions',
        questions.filter((question) => question.id !== questionId)
      );
    },
    [methods]
  );

  const getQuestionsToAdd = (
    questionsFromModal: Array<IMinimalSsqQuestion>
  ) => {
    onClose();
    methods.setValue('questions', questionsFromModal);
  };
  const questionsErrorMessage = get(
    methods.formState.errors,
    'questions.message'
  ) as string | undefined;

  const ALLOWED_STATUSES =
    defaultValues && defaultValues?.status !== IQuestionStatusEnum.draft
      ? SSQ_STATUSES.filter(
          (option) => option.value !== IQuestionStatusEnum.draft
        )
      : SSQ_STATUSES;

  const disableStructuralFields =
    mode === 'EDIT' && defaultValues?.status !== IQuestionStatusEnum.draft;
  return (
    <>
      <Controls.Form submitHandler={onSubmit} methods={methods}>
        <Card.CContainer>
          <Card.Header title={t('Basic')} />
          <Card.Body>
            <Grid container spacing={3}>
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('Questionnaire Name')}
                    name='name'
                    required
                  />
                </FormGroup>
              </Grid>

              <Grid item md={3} xs={12}>
                <FormGroup>
                  <Controls.FormSelect
                    label={t('Status')}
                    name='status'
                    options={ALLOWED_STATUSES.map((item) => ({
                      ...item,
                      label: t(item.label),
                    }))}
                    required
                  />
                </FormGroup>
              </Grid>

              <Grid item md={6} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('Comments')}
                    name='comments'
                    multiline
                    rows={3}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Card.Body>
        </Card.CContainer>
        <Card.CContainer style={{ marginTop: 20 }}>
          <Card.Body>
            <Box display={'flex'}>
              <Button
                id='add-ssq-question'
                onClick={onOpen}
                className=''
                color='primary'
                variant='outlined'
                disabled={disableStructuralFields}
                style={{ width: '100%' }}
              >
                {t('Add questions')}
              </Button>
            </Box>
            {questionsErrorMessage && (
              <Box marginTop={5}>
                <Alert severity='error'>
                  {/* TODO: use error code to find the appropriete error #after the Core api refactor */}
                  {t(questionsErrorMessage, {
                    missingQuestionsCodes: errors?.errors[0].value,
                  })}
                </Alert>
              </Box>
            )}
            {watchedQuestions.length === 0 && (
              <Box marginTop={5}>
                <Alert severity='warning'>{t('No questions added yet')}</Alert>
              </Box>
            )}
            <Box
              height={'60vh'}
              style={{
                overflowY: 'auto',
                pointerEvents: disableStructuralFields ? 'none' : 'all',
                opacity: disableStructuralFields ? 0.7 : 1,
              }}
            >
              <SsqQuestionList
                questions={watchedQuestions}
                onMove={handleMove}
                onRemove={handleRemove}
              />
            </Box>
          </Card.Body>
        </Card.CContainer>
        <FormActions
          isLoading={isLoading}
          cancelText={t('Cancel')}
          onCancel={() => {
            navigate(`/manufacturers/${manufacturer.id}/questionnaires`);
          }}
        />
      </Controls.Form>
      {open && (
        <SsqQuestionsModal
          defaultValues={watchedQuestions}
          onSubmit={getQuestionsToAdd}
          onClose={onClose}
          open={open}
          manufacturerId={manufacturer.id}
        />
      )}
    </>
  );
};
export default memo(Form);
