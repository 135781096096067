import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { SurveyApi, ThemesApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IPaginationQuery,
  IThemeConfiguration,
  IAPIResponse,
  IClassificationType,
  IRawClassification,
  IThemeClassification,
  ICriterionClassification,
} from '@inteliam/foundation/lib/types';

const mapQuery: () => {
  [key in IClassificationType]: {
    key: Array<string>;
    api: (
      paginator: IPaginationQuery
    ) => Promise<
      IAPIResponse<Array<IRawClassification | IThemeClassification>>
    >;
  };
} = () => ({
  themes: {
    key: QUERY_KEYS.QUESTION_THEMES,
    api: (...args) => ThemesApi.getThemes(...args),
  },
  criteria: {
    key: QUERY_KEYS.QUESTION_CRITERIA,
    api: (...args) => SurveyApi.getCriteria(...args),
  },
});

const useFetchClassifications = ({
  type,
  questionnaireType = 'INTELIAM',
}: {
  type: IClassificationType;
  questionnaireType?: IThemeConfiguration['questionnaireType'];
}): InteliamUseQueryResult<
  IAPIResponse<
    (ICriterionClassification | IThemeClassification)[],
    unknown,
    unknown
  >,
  BaseAxiosErrorResponse<unknown, unknown, unknown>
> & {
  classifications: (IThemeClassification | ICriterionClassification)[];
} => {
  const query = useQuery<
    IAPIResponse<(IThemeClassification | ICriterionClassification)[]>,
    BaseAxiosErrorResponse
  >(
    mapQuery()[type].key,
    () =>
      mapQuery()[type].api({
        itemsPerPage: -1,
        page: 1,
        sort: {
          _id: 'asc',
        },
        criteria: {
          conditions: [],
          operator: 'and',
          options: {
            questionnaireType,
          },
        },
      }),
    {
      ...DEFAULT_QUERY_OPTIONS,
    }
  );

  return {
    ...query,
    classifications: query.data?.data || [],
  };
};

export default useFetchClassifications;
