import { yupResolver } from '@hookform/resolvers/yup';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import {
  BasicInformation,
  CreationSchemas,
  LanguageManagement,
  Logic,
  QuestionAndOptionScope,
  QuestionClassification,
  QuestionConfiguration,
  QuestionFormProvider,
} from '@core/components/questions';

import { QuestionsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IFormattedDistributorEnums, IQuestionForm } from '@core/types';

import { Controls, Grid } from '@shared/components';

import { ErrorCodesLookup, ValidationUtils } from '@shared/utils';

import type {
  IPartialQuestion,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { FormActions } from '../';
import ScoringConfiguration from './scoring-configuration';

interface Props {
  title: string;
  defaultValues?: IPartialQuestion;
  onSubmit: (data: IPartialQuestion) => void;
  mode?: 'CREATE' | 'NEW_VERSION' | 'EDIT';
  isLoading: boolean;
  errors?: ValidationErrorResponse;
  qualificationEnums: IFormattedDistributorEnums;
}

const QuestionForm: React.FCC<Props> = ({
  title,
  defaultValues,
  onSubmit,
  mode = 'CREATE',
  isLoading,
  errors,
  qualificationEnums,
}) => {
  const { t, notify, INotifierEnum } = useEssentials();

  const methods = useForm<IQuestionForm>({
    defaultValues: QuestionsUtils.initializeQuestion(
      qualificationEnums,
      defaultValues
    ),
    resolver: yupResolver<typeof CreationSchemas.create>(
      CreationSchemas.create
    ),
    mode: 'all',
    reValidateMode: 'onBlur',
    shouldUnregister: false,
  });
  const readOnly = mode === 'EDIT' && defaultValues?.status !== 'draft';

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);
  return (
    <QuestionFormProvider
      mode={mode}
      readOnly={readOnly}
      defaultValues={defaultValues}
    >
      <Controls.Form
        autoComplete='off'
        noValidate
        methods={methods}
        submitHandler={(formData) => {
          onSubmit(QuestionsUtils.normalizer.formToPayload(formData));
        }}
        failureHandler={() => {
          notify({
            message: t(ErrorCodesLookup.E0000.title),
            type: INotifierEnum.Error,
          });
        }}
      >
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <span>
            <strong>{title}</strong>
          </span>
        </Grid>
        <BasicInformation />
        <QuestionConfiguration />
        <QuestionClassification />
        <QuestionAndOptionScope />
        <Logic />
        <LanguageManagement />
        <ScoringConfiguration />
        <FormActions isLoading={isLoading} />
      </Controls.Form>
    </QuestionFormProvider>
  );
};

export default memo(QuestionForm);
