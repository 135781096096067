import { yupResolver } from '@hookform/resolvers/yup';
import { IDistributorStatusEnum } from '@inteliam/foundation/lib/enums';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { CompanyInformation, IntegrationIdsManagement } from '@core/components';
import { createCompany } from '@core/components/assessment-requests/registration/schemas';

import { RegistrationsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IDistributorForm } from '@core/types';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Controls,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

import Contacts from './contacts';

interface Props {
  onSubmit: (data: IDistributorForm) => void;
  defaultValues?: Partial<IDistributorForm>;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  errors,
  isLoading,
}) => {
  const methods = useForm<IDistributorForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(createCompany),
  });
  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);
  const { t, confirm } = useEssentials();

  const handleOnReset = useCallback(() => {
    confirm({
      description: t(
        'All the prefilled fields will be erased. Do you want to continue?'
      ),
    })
      .then(() => {
        return methods.reset({
          ...RegistrationsUtils.DEFAULT_COMPANY,
          status: IDistributorStatusEnum.NEW,
        });
      })
      .catch(() => {});
  }, [methods, confirm, t]);

  return (
    <Controls.Form
      submitHandler={(values) => {
        onSubmit({
          ...defaultValues,
          ...values,
        });
      }}
      methods={methods}
    >
      <CompanyInformation onReset={handleOnReset} />
      <IntegrationIdsManagement />
      <Contacts />
      <Card.CContainer styled>
        <Box justifyContent='space-between' display='flex' p={2}>
          <Button
            id='cancel-distributor-creation'
            disabled={isLoading}
            type='button'
            variant='contained'
            color='secondary'
          >
            {t('Cancel')}{' '}
            {isLoading && <CircularProgress color='inherit' size={16} />}
          </Button>
          <div>
            <Button
              id={'submit-distributor-creation'}
              type='submit'
              variant='contained'
              color='primary'
              disabled={isLoading || !methods.formState.isDirty}
              style={{ marginLeft: '10px' }}
              onClick={() => {}}
            >
              {t('Match & Merge')}
            </Button>
          </div>
        </Box>
      </Card.CContainer>
    </Controls.Form>
  );
};
export default memo(Form);
