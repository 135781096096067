import {
  CancellationReasonEnum,
  IARStatusEnum,
  IARTransitionEnum,
} from '@inteliam/foundation/lib/enums';
import { DateFormatter } from '@inteliam/foundation/lib/utils';

import React, { useState } from 'react';

import { ARTransitionProvider, useAssessmentMachine } from '@core/hooks';

import { useFetchGlobalProgressById } from '@core/queries';

import { useDashboard, useEssentials } from '@core/contexts';

import { FormControl, NativeSelect, Tooltip } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IAR, ITransition } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles(() => ({
  select: {
    height: '100%',
  },
}));

interface Props {
  assessmentRequest: IAR;
}

const CANCELLATION_REASON_LOOKUP: Record<CancellationReasonEnum, string> = {
  CANCELLED_BY_INTELIAM: 'Cancelled by Inteliam',
  DECLINED_BY_DISTRIBUTOR: 'Declined by Distributor',
  CANCELLED_BY_REQUESTER: 'Cancelled by Requester',
  OTHER: 'Other',
};
const StatusCell: React.FCC<Props> = ({ assessmentRequest }) => {
  const classes = useStyles();
  const { t, user } = useEssentials();
  const { dashboard, checkReadOnly } = useDashboard();
  const { transition } = useAssessmentMachine({ assessmentRequest, dashboard });

  const [
    mouseEnteredStatusCellInProgressStatus,
    setMouseEnteredStatusCellInProgressStatus,
  ] = useState(false);
  const [
    mouseEnteredStatusCellInCancelledStatus,
    setMouseEnteredStatusCellInCancelledStatus,
  ] = useState(false);

  const { globalProgress } = useFetchGlobalProgressById({
    id: assessmentRequest.id,
    options: {
      enabled: mouseEnteredStatusCellInProgressStatus,
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const transitionToRun = event.target.value as IARTransitionEnum;
    transition.run({ transition: transitionToRun }).catch(() => {});
  };

  const readOnly = checkReadOnly(assessmentRequest, user);

  const handleMouseEnter = () => {
    setMouseEnteredStatusCellInProgressStatus(
      assessmentRequest.status === IARStatusEnum.ANSWERING_SURVEY
    );
    setMouseEnteredStatusCellInCancelledStatus(
      assessmentRequest.status === IARStatusEnum.CANCELLED
    );
  };

  const statusCellContent = (
    <FormControl fullWidth onMouseEnter={handleMouseEnter}>
      <NativeSelect
        id={`select-status-${assessmentRequest.businessId}`}
        value={assessmentRequest?.status}
        onChange={assessmentRequest.isFinalStatus ? undefined : handleChange}
        disabled={assessmentRequest.isFinalStatus || readOnly}
        name='status'
        inputProps={{
          className: classes.select,
        }}
        fullWidth
      >
        {assessmentRequest?.status && (
          <option value={assessmentRequest?.status}>
            {t(assessmentRequest?.status)}
            {assessmentRequest.status === IARStatusEnum.ANSWERING_SURVEY &&
              ` (${globalProgress?.surveyProgress ?? '-'}%)`}
          </option>
        )}
        {assessmentRequest?.availableTransitions.map(
          (transition: ITransition) => (
            <option
              id={`select-status-${transition.name}`}
              key={transition.action}
              value={transition.action}
            >
              {t(transition.name)}
            </option>
          )
        )}
      </NativeSelect>
    </FormControl>
  );
  if (globalProgress && mouseEnteredStatusCellInProgressStatus) {
    return (
      <Tooltip
        title={
          <p>
            {t('Global progress : {{progress}}%', {
              progress: globalProgress.surveyProgress,
            })}
            <br />
            {t('Questionnaire Start : {{date}}', {
              date: DateFormatter.formatTimestamp(
                new Date(globalProgress.startedAt),
                DateFormatter.FORMATS.fr_FR.SHORT
              ),
            })}
            <br />
            {globalProgress.lastConnectionDate &&
              t('Last Connection : {{date}}', {
                date: DateFormatter.formatTimestamp(
                  new Date(globalProgress.lastConnectionDate),
                  DateFormatter.FORMATS.fr_FR.SHORT
                ),
              })}
          </p>
        }
      >
        {statusCellContent}
      </Tooltip>
    );
  }

  if (
    assessmentRequest.status === IARStatusEnum.CANCELLED &&
    mouseEnteredStatusCellInCancelledStatus
  ) {
    return (
      <Tooltip
        title={
          <p>
            {`${
              CANCELLATION_REASON_LOOKUP[
                assessmentRequest.cancellation?.reason ??
                  CancellationReasonEnum.REASON_CANCELLED_BY_INTELIAM
              ]
            }: ${assessmentRequest.cancellation?.comment || 'N/A'}`}
          </p>
        }
      >
        {statusCellContent}
      </Tooltip>
    );
  }
  return <>{statusCellContent}</>;
};

const EnhancedStatusCell: React.FCC<Props> = (props) => (
  <ARTransitionProvider>
    <StatusCell {...props} />
  </ARTransitionProvider>
);

export default EnhancedStatusCell;
