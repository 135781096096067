import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  EnterpriseBaseInformation,
  EnterpriseDomainNames,
  FormActions,
} from '@core/components';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseForm } from '@core/types';

import { Controls } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IPartialEnterprise,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { create as schema } from '../schema';
import Contacts from './contacts';

interface Props {
  onSubmit: (data: IPartialEnterprise) => void;
  defaultValues?: IPartialEnterprise;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  errors,
  isLoading,
}) => {
  const { user } = useEssentials();
  const methods = useForm<IEnterpriseForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema(user)),
  });

  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Controls.Form
      submitHandler={(values) => {
        const payload = {
          ...defaultValues,
          ...values,
        };
        const omittedFields = [];
        if (!payload.configuration.enterpriseAccess) {
          omittedFields.push('users', 'domainNames', 'authorizationFiles');
        }
        if (!payload.level) {
          omittedFields.push('parent');
        }
        onSubmit(
          payload.configuration.enterpriseAccess
            ? payload
            : omit(payload, omittedFields)
        );
      }}
      methods={methods}
    >
      <EnterpriseBaseInformation mode='CREATE' />
      <Contacts />
      <EnterpriseDomainNames />
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};
export default memo(Form);
