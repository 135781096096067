import { IQuestionStatusEnum } from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { ChangeEvent, memo, useState } from 'react';

import { BaseDialog } from '@core/components/modals';

import { useFetchSsqQuestions } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IMinimalSsqQuestion } from '@core/types';

import {
  Button,
  CenteredSpinner,
  Checkbox,
  FormControlLabel,
  Paper,
  Grid,
  Box,
} from '@shared/components';

import { makeStyles, DatatableUtils } from '@shared/utils';

import type {
  IAnySsqQuestion,
  IAnyEnterprise,
} from '@inteliam/foundation/lib/types';

/* eslint-disable @inteliam/i18n/raw-text-detected */
interface Props {
  onSubmit: (data: Array<IMinimalSsqQuestion>) => void;
  onClose: () => void;
  open: boolean;
  defaultValues?: Array<IMinimalSsqQuestion>;
  manufacturerId: IAnyEnterprise['id'];
}
const useStyles = makeStyles(() => ({
  root: {
    width: 'calc(100% - 420px)',
  },
  paper: {
    width: '100%',
    height: '90px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15px',
  },
}));

const Questions: React.FCC<Props> = ({
  onClose,
  open,
  onSubmit,
  defaultValues = [],
  manufacturerId,
}) => {
  const { t } = useEssentials();
  const classes = useStyles();

  const ssqQuestionsQuery = useFetchSsqQuestions({
    manufacturerId,
    paginator: {
      itemsPerPage: -1,
      sort: { id: 'desc' },
      page: 1,
      criteria: {
        operator: 'or',
        conditions: [
          DatatableUtils.createCondition(
            'status',
            'eq',
            IQuestionStatusEnum.active
          ),
        ],
      },
    },
  });
  const [selectedQuestions, setSelectedQuestions] =
    useState<Array<IMinimalSsqQuestion>>(defaultValues);

  const handleQuestionsSelection = (
    event: ChangeEvent<HTMLInputElement>,
    question: IAnySsqQuestion
  ) => {
    if (event.target.checked) {
      setSelectedQuestions([...selectedQuestions, question]);
    } else {
      setSelectedQuestions(
        selectedQuestions.filter((item) => item !== question)
      );
    }
  };

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box display={'flex'} flexDirection='column'>
            <strong>{t('Add questions')}</strong>
            <small>
              {t('Select the questions you want to add to the questionnaire')}
            </small>
          </Box>
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        {!ssqQuestionsQuery.isSettled && <CenteredSpinner />}

        {ssqQuestionsQuery.isSuccess &&
          ssqQuestionsQuery.questions.length === 0 &&
          t('No data available at this moment')}
        {ssqQuestionsQuery.questions.map((question) => {
          return (
            <Paper className={classes.paper} key={question.id} elevation={3}>
              <FormControlLabel
                id={`select-${question.code}`}
                control={
                  <Checkbox
                    onChange={(event) => {
                      handleQuestionsSelection(event, question);
                    }}
                    checked={
                      !isNil(
                        selectedQuestions.find(
                          (selectedQuestion) =>
                            selectedQuestion.id === question.id
                        )
                      )
                    }
                  />
                }
                label={
                  <div>
                    <div>
                      {t('Code:')} {question.code},{' '}
                    </div>
                    <div>
                      {t('Title:')} {I18n.getTranslatedValue(question.title)}
                    </div>
                  </div>
                }
              ></FormControlLabel>
            </Paper>
          );
        })}
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-add-questions'
          onClick={onClose}
          type='button'
          color='primary'
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-add-questions'
          type='submit'
          color='primary'
          onClick={() => {
            onSubmit(selectedQuestions);
          }}
        >
          {t('Confirm')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};
export default memo(Questions);
