/* eslint-disable sonarjs/cognitive-complexity */
import {
  IUserStatusEnum,
  IDistributorRolesEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useLanguagesByScope } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { TITLES } from '@core/enums';

import { useEssentials } from '@core/contexts';

import { Controls, Grid, FormGroup } from '@shared/components';

import type {
  IOptionItem,
  IPartialAnyUser,
  IUserFormParameters,
} from '@inteliam/foundation/lib/types';

const TITLES_ARRAY = Object.values(TITLES);

const DEFAULT_VISIBILITY = {
  title: true,
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  position: true,
  language: true,
  role: true,
  status: true,
};

interface Props<TUser extends IPartialAnyUser> {
  visibility?: IUserFormParameters;
  readOnly?: IUserFormParameters;
  defaultValues?: TUser;
  baseName?: string;
  allowedRoles: IOptionItem[];
  multipleRoleSelection?: boolean;
}

const BaseUserForm = <TUser extends IPartialAnyUser>({
  defaultValues,
  visibility,
  readOnly,
  allowedRoles,
  baseName,
  multipleRoleSelection = false,
}: Props<TUser>) => {
  const { t } = useEssentials();
  const _baseFieldName = baseName ? `${baseName}.` : '';
  const _visibility = { ...DEFAULT_VISIBILITY, ...visibility };
  const { formattedLanguages, isSuccess } = useLanguagesByScope({
    scope: 'UserCommunication',
  });

  const helperMessage = getRoleHelperText(defaultValues);
  return (
    <>
      {_visibility.title && (
        <Grid item md={4} xs={12}>
          <FormGroup>
            <Controls.FormSelect
              required
              fullWidth
              label={t('Title')}
              name={`${_baseFieldName}contactInfo.title`}
              options={TITLES_ARRAY}
              defaultValue={
                defaultValues?.contactInfo?.title || TITLES_ARRAY[0].value
              }
              readOnly={readOnly?.title}
            />
          </FormGroup>
        </Grid>
      )}
      {_visibility.firstName && (
        <Grid item md={4} xs={12}>
          <Controls.FormInput
            fullWidth
            label={t('First Name')}
            name={`${_baseFieldName}contactInfo.firstName`}
            defaultValue={defaultValues?.contactInfo?.firstName}
            required
            readOnly={readOnly?.firstName}
          />
        </Grid>
      )}
      {_visibility.lastName && (
        <Grid item md={4} xs={12}>
          <Controls.FormInput
            fullWidth
            label={t('Last name')}
            name={`${_baseFieldName}contactInfo.lastName`}
            defaultValue={defaultValues?.contactInfo?.firstName}
            required
            readOnly={readOnly?.lastName}
          />
        </Grid>
      )}
      {_visibility.email && (
        <Grid item md={6} xs={12}>
          <Controls.FormInput
            fullWidth
            label={t('Email')}
            name={`${_baseFieldName}contactInfo.email`}
            defaultValue={defaultValues?.contactInfo?.email}
            required
            readOnly={readOnly?.email}
          />
        </Grid>
      )}
      {_visibility.phoneNumber && (
        <Grid item md={6} xs={12}>
          <Controls.FormPhoneNumberField
            fullWidth
            label={t('Phone number')}
            name={`${_baseFieldName}contactInfo.phoneNumber`}
            defaultValue={defaultValues?.contactInfo?.phoneNumber || ''}
            readOnly={readOnly?.phoneNumber}
          />
        </Grid>
      )}
      {_visibility.position && (
        <Grid item md={6} xs={12}>
          <Controls.FormInput
            fullWidth
            label={t('Position')}
            name={`${_baseFieldName}contactInfo.position`}
            defaultValue={defaultValues?.contactInfo?.position}
            required
            readOnly={readOnly?.position}
          />
        </Grid>
      )}
      {_visibility.language && isSuccess && (
        <Grid item md={6} xs={12}>
          <FormGroup>
            <Controls.FormSelect
              fullWidth
              label={t('Language')}
              name={`${_baseFieldName}contactInfo.language`}
              defaultValue={defaultValues?.contactInfo?.language || ''}
              options={formattedLanguages}
              required
              readOnly={readOnly?.language}
            />
          </FormGroup>
        </Grid>
      )}
      {_visibility.role && (
        <Grid item md={6} xs={12}>
          <FormGroup>
            <Controls.FormSelect
              name={`${_baseFieldName}${
                multipleRoleSelection ? 'roles' : 'roles.0'
              }`}
              label={t('Access role')}
              multiple={multipleRoleSelection}
              options={allowedRoles}
              readOnly={readOnly?.role}
              required
              disabled={shouldDisableRole(defaultValues)}
              helperText={helperMessage ? t(helperMessage) : undefined}
            />
          </FormGroup>
        </Grid>
      )}
      {_visibility.status && (
        <Grid item md={6} xs={12}>
          <FormGroup style={{ alignItems: 'start' }}>
            <Controls.FormRadioGroup
              defaultValue={
                defaultValues?.status || IUserStatusEnum.STATUS_DISABLED
              }
              label={t('Status')}
              className='mr-1'
              name={`${_baseFieldName}status`}
              options={UsersUtils.getUserStatusesEnum()}
              labelPlacement='start'
              row
              required
              readOnly={readOnly?.status}
            />
          </FormGroup>
        </Grid>
      )}
    </>
  );
};
export default memo(BaseUserForm);

function getRoleHelperText(
  defaultValues: IPartialAnyUser | undefined
): string | undefined {
  if (defaultValues?.kind === 'DISTRIBUTOR') {
    return defaultValues?.primaryContact ||
      (defaultValues?.questionnaireContactFor?.length || 0) > 0
      ? 'You cannot change the access role, please choose your new primary and/or questionnaire contact first'
      : undefined;
  }
  return undefined;
}

function shouldDisableRole(
  defaultValues: IPartialAnyUser | undefined
): boolean {
  if (defaultValues?.kind === 'DISTRIBUTOR') {
    return Boolean(
      defaultValues?.roles?.includes(
        IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD
      ) &&
        (defaultValues?.primaryContact || defaultValues?.questionnaireContact)
    );
  }

  return false;
}
