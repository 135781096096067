import { IEnterpriseSubscriptionsEnum } from '@inteliam/foundation/lib/enums';

type IEnterpriseSubscriptionsPreset = {
  [key in IEnterpriseSubscriptionsEnum]: {
    value: IEnterpriseSubscriptionsEnum;
    label: string;
  };
};

export const ENTERPRISE_SUBSCRIPTIONS: IEnterpriseSubscriptionsPreset = {
  [IEnterpriseSubscriptionsEnum.STANDARD]: {
    value: IEnterpriseSubscriptionsEnum.STANDARD,
    label: 'Standard',
  },
  [IEnterpriseSubscriptionsEnum.PREMIUM]: {
    value: IEnterpriseSubscriptionsEnum.PREMIUM,
    label: 'Premium',
  },
};
