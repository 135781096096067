/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import { useManageArReviews } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Alert, Box, Card, CenteredSpinner, Grid } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type {
  IAnyAssessmentReview,
  IAssessmentStrength,
  IAssessmentWeakness,
  IPartialScore,
  IScoreCard,
} from '@inteliam/foundation/lib/types';

import { ValidationDraggables, ValidationReviewsGroupedByCriteria } from '.';

type Props = {
  partialScore: IPartialScore;
  readOnly?: boolean;
  scoreCard: IScoreCard;
};

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    marginTop: theme.spacing(1),
  },
}));

const ThemeTab: React.FCC<Props> = ({
  partialScore,
  readOnly = false,
  scoreCard,
}) => {
  const { t, na } = useEssentials();
  const classes = useStyles();
  const { paginateByTheme } = useManageArReviews({
    assessmentRequestId: scoreCard.assessmentRequest.id,
    themeId: partialScore.theme.id,
  });

  if (!paginateByTheme.query.isSettled) {
    return <CenteredSpinner />;
  }

  if (!paginateByTheme.query.isSuccess) {
    return (
      <Alert severity='error'>
        {t(
          paginateByTheme.query.error?.response?.data.message ||
            'Something went wrong'
        )}
      </Alert>
    );
  }
  const reviews = paginateByTheme.query.data?.data;

  const strengths = reviews.filter(
    (review: IAnyAssessmentReview) => review.kind === 'STRENGTH'
  ) as Array<IAssessmentStrength>;
  const weaknesses = reviews?.filter(
    (review: IAnyAssessmentReview) => review.kind === 'WEAKNESS'
  ) as Array<IAssessmentWeakness>;
  return (
    <Box marginTop={4}>
      {partialScore.configuration?.groupableReviews ? (
        <ValidationReviewsGroupedByCriteria
          {...{ partialScore, readOnly, strengths, weaknesses }}
        />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card.CContainer>
              <Card.Body>
                <Box p={1} className={classes.header}>
                  {t('Strengths')} <hr />
                </Box>
                <Box p={1}>
                  {strengths.length > 0 ? (
                    <ValidationDraggables
                      reviews={strengths}
                      readOnly={readOnly}
                      themeId={partialScore.theme.id}
                    />
                  ) : (
                    <div>{na}</div>
                  )}
                </Box>
              </Card.Body>
            </Card.CContainer>
          </Grid>
          <Grid item xs={6}>
            <Card.CContainer>
              <Card.Body>
                <Box p={1} className={classes.header}>
                  {t('Areas of Improvements')} <hr />
                </Box>
                <Box p={1}>
                  {weaknesses.length > 0 ? (
                    <ValidationDraggables
                      reviews={weaknesses}
                      readOnly={readOnly}
                      themeId={partialScore.theme.id}
                    />
                  ) : (
                    <div>{na}</div>
                  )}
                </Box>
              </Card.Body>
            </Card.CContainer>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default React.memo(ThemeTab);
