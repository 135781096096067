import {
  IARStatusRankEnum,
  IBORolesEnum,
} from '@inteliam/foundation/lib/enums';

import { UsersUtils } from '@core/utils';

import type { IAR, JwtBOUser } from '@inteliam/foundation/lib/types';

export const canManageDeadline = (authenticatedUser: JwtBOUser): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) ||
    UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, authenticatedUser)
  );
};

export const canManageCampaign = (authenticatedUser: JwtBOUser): boolean => {
  return UsersUtils.isGranted(
    IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
    authenticatedUser
  );
};

// eslint-disable-next-line sonarjs/no-identical-functions
export const canCancel = (authenticatedUser: JwtBOUser): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) ||
    UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, authenticatedUser)
  );
};

export const canReInvite = (
  authenticatedUser: JwtBOUser,
  assessmentRequest: IAR
): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) &&
    assessmentRequest.currentStatusMeta.rank === IARStatusRankEnum.FRESH_SURVEY
  );
};

export const canExtendDeadline = (
  authenticatedUser: JwtBOUser,
  assessmentRequest: IAR
): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) &&
    [
      IARStatusRankEnum.FRESH_SURVEY,
      IARStatusRankEnum.ANSWERING_SURVEY,
    ].includes(assessmentRequest.currentStatusMeta.rank)
  );
};

export const canAuthorizeSurveySubmission = (
  authenticatedUser: JwtBOUser,
  assessmentRequest: IAR
): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) &&
    assessmentRequest.currentStatusMeta.rank >=
      IARStatusRankEnum.ANSWERING_SURVEY
  );
};

export const canAccessPage = (
  authenticatedUser: JwtBOUser,
  assessmentRequest: IAR
): boolean => {
  return Boolean(
    (UsersUtils.isGranted(
      IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
      authenticatedUser
    ) ||
      UsersUtils.isGranted(
        IBORolesEnum.ROLE_REGULAR_ANALYST,
        authenticatedUser
      )) &&
      assessmentRequest.scoredCompany.status
  );
};

export const canAccessToScoreFile = (authenticatedUser: JwtBOUser): boolean => {
  return UsersUtils.isGranted(
    IBORolesEnum.ROLE_REGULAR_ANALYST,
    authenticatedUser
  );
};
