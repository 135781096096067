import React, { memo } from 'react';

import { NewsForm } from '@core/components/news';

import { QUERY_KEYS, useManageNews } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Grid } from '@shared/components';

import { BaseDialog } from '..';

interface Props {
  assessmentRequestId: string;
  open: boolean;
  onClose: () => void;
}
const CreateNewsModal: React.FCC<Props> = ({
  assessmentRequestId,
  open,
  onClose,
}) => {
  const { t } = useEssentials();
  const { create } = useManageNews({
    create: {
      keysToInvalidateOnSuccess: [
        [...QUERY_KEYS.ASSESSMENT_REQUEST, assessmentRequestId],
      ],
      onSuccess: () => {
        onClose();
      },
    },
  });

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Add news')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <NewsForm
          onSubmit={create.run}
          isLoading={create.mutation.isLoading}
          assessmentRequestId={assessmentRequestId}
          onCancel={onClose}
          mode='CREATE'
        />
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateNewsModal);
