import { IQuestionStatusEnum } from '@inteliam/foundation/lib/enums';

import type {
  ISsqQuestionnaireDefinitionPayload,
  ISsqQuestionnaireDefinitionFormData,
} from '@core/types';

import type { IAnyEnterprise } from '@inteliam/foundation/lib/types';

export const normalizer = {
  initializeForm: (
    manufacturer: IAnyEnterprise,
    defaultValues?: ISsqQuestionnaireDefinitionFormData
  ): ISsqQuestionnaireDefinitionFormData => {
    return {
      name: `${
        manufacturer.name
      }.${new Date().getFullYear()}.${new Date().getMonth()}`,
      status: IQuestionStatusEnum.draft,
      comments: '',
      questions: [],
      ...defaultValues,
    };
  },
  formDataToPayload: (
    formData: ISsqQuestionnaireDefinitionFormData,
    manufacturerId: IAnyEnterprise['id']
  ): ISsqQuestionnaireDefinitionPayload => {
    return {
      ...formData,
      manufacturer: { id: manufacturerId },
      questions:
        formData.questions?.map((question) => ({ id: question.id })) ?? [],
    };
  },
};
