import React, { memo } from 'react';

import {
  QUERY_KEYS,
  useManageAssessmentRequest,
  useMutation,
} from '@core/queries';

import { RenewalsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IARNextActionForm, IRenewal } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

import NextRenewalActionForm from './next-renewal-action-form';

interface Props {
  renewal: IRenewal;
  open: boolean;
  onClose: () => void;
}

const UpdateNextRenewalActionModal: React.FCC<Props> = ({
  renewal,
  open,
  onClose,
}) => {
  const { t, notify } = useEssentials();

  const mutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IARNextActionForm & { id: IRenewal['id'] }
  >(RenewalsApi.updateNextAction, {
    keysToInvalidateOnSuccess: [
      QUERY_KEYS.DASHBOARD['RENEWALS'],
      QUERY_KEYS.TODAYS_TO_DO_LIST,
    ],
    onSuccess: () => {
      notify({
        message: t('Next action updated.'),
        type: INotifierEnum.Success,
      });
      onClose();
    },
  });

  const { updateNextAction } = useManageAssessmentRequest({
    updateNextAction: {},
  });

  const handleOnSubmit = (data: IARNextActionForm & { id: IRenewal['id'] }) => {
    return mutation.mutateAsync({
      id: renewal.id,
      description: data.description,
      assignee: data.assignee,
      date: data.date,
    });
  };

  return (
    <NextRenewalActionForm
      defaultValues={
        renewal.nextAction as IARNextActionForm & { id: IRenewal['id'] }
      }
      open={open}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      errors={updateNextAction.error?.response?.data}
      isLoading={updateNextAction.isLoading}
    />
  );
};

export default memo(UpdateNextRenewalActionModal);
