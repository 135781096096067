import { get } from 'lodash-es';

import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

import Typeahead, { TypeaheadProps } from '../typeahead/typeahead';

const FormSelectTypeahead: React.FCC<
  TypeaheadProps<IOptionItem> & {
    name: string;
    helperText?: string;
    options: IOptionItem[];
    size?: 'small' | 'medium';
  }
> = (props) => {
  const {
    name,
    label,
    helperText,
    defaultValue,
    required,
    options,
    multiple,
    ...rest
  } = props;
  const [internalValue, setInternalValue] = useState<
    IOptionItem | IOptionItem[]
  >(defaultValue || multiple ? [] : { value: '', label: '' });
  const {
    control,

    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();
  const translatedOptions = options.map((option) => ({
    ...option,
    label: t(option.label),
  }));
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={internalValue}
      render={({ field }) => {
        const nextValue =
          multiple && Array.isArray(field.value)
            ? translatedOptions.filter((option) =>
                field.value.includes(option.value)
              )
            : translatedOptions.find(
                (option) => option.value === field.value
              ) || {
                value: '',
                label: '',
              };
        return (
          <Typeahead<IOptionItem>
            {...{ name, label, required, multiple }}
            options={translatedOptions}
            {...rest}
            value={nextValue}
            onChange={(_, data) => {
              if (Array.isArray(data)) {
                field.onChange(data.map(({ value }) => value));
              } else {
                field.onChange(data?.value || { value: '', label: '' });
              }
              setInternalValue(data || { value: '', label: '' });
            }}
            error={!!get(errors, `${name}.message`)}
            helperText={t(
              (get(errors, `${name}.message`) ||
                helperText) as unknown as string
            )}
            autoHighlight
          />
        );
      }}
    />
  );
};

export default FormSelectTypeahead;
