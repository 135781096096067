import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';

import type {
  IOptionItem,
  ITranslatableList,
} from '@inteliam/foundation/lib/types';

export const calculateUnusedLanguages = (
  translations: ITranslatableList,
  formattedExceptEnglish: Array<IOptionItem>,
  index: number
): Array<IOptionItem> => {
  const usedLanguages = translations.map((item) => item.locale);
  return formattedExceptEnglish.filter(
    (item) =>
      item.value === usedLanguages[index] ||
      !usedLanguages.includes(item.value as ILanguagesEnum)
  );
};
