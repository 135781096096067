import type {
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { QUERY_KEYS, useMutation, useQuery } from '@core/queries';

import { MediaApi } from '@core/api';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

type CreateArgs = Parameters<typeof MediaApi.create>[0];
type CreateResponse = Awaited<ReturnType<typeof MediaApi.create>>;

type RemoveArgs = Parameters<typeof MediaApi.remove>[0];
type RemoveResponse = Awaited<ReturnType<typeof MediaApi.remove>>;

type ListArgs = Parameters<typeof MediaApi.paginate>[0];
type ListResponse = Awaited<ReturnType<typeof MediaApi.paginate>>;

type ManageMedia = (parameters?: {
  create?: {
    options?: InteliamUseMutationOptions<
      CreateResponse,
      AxiosValidationErrorResponse,
      CreateArgs
    >;
  };
  remove?: {
    options?: InteliamUseMutationOptions<
      RemoveResponse,
      AxiosValidationErrorResponse,
      RemoveArgs
    >;
  };
  list?: {
    args: Partial<ListArgs>;
    options?: InteliamUseQueryOptions<ListResponse, BaseAxiosErrorResponse>;
  };
}) => {
  create: InteliamUseMutationResult<
    CreateResponse,
    AxiosValidationErrorResponse,
    CreateArgs
  >;
  remove: InteliamUseMutationResult<
    RemoveResponse,
    AxiosValidationErrorResponse,
    RemoveArgs
  >;
  list: InteliamUseQueryResult<ListResponse, BaseAxiosErrorResponse>;
};

const useManageMedia: ManageMedia = (args) => {
  const createMutation = useMutation<
    CreateResponse,
    AxiosValidationErrorResponse,
    CreateArgs
  >(MediaApi.create, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.MEDIA],
    ...args?.create?.options,
  });
  const removeMutation = useMutation<
    RemoveResponse,
    AxiosValidationErrorResponse,
    RemoveArgs
  >(MediaApi.remove, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.MEDIA],
    ...args?.remove?.options,
  });
  const listQuery = useQuery<ListResponse, BaseAxiosErrorResponse>(
    [],
    () =>
      MediaApi.paginate({
        itemsPerPage: -1,
        page: 1,
        criteria: {
          conditions: [],
          operator: 'and',
        },
        sort: {},
        ...args?.list?.args,
      }),
    {
      keysToInvalidateOnSuccess: [QUERY_KEYS.MEDIA],
      ...args?.list?.options,
    }
  );

  return {
    create: createMutation,
    remove: removeMutation,
    list: listQuery,
  };
};

export default useManageMedia;
