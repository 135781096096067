/* eslint-disable unicorn/no-null */
import { IBORolesEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';
import { noop } from 'lodash-es';

import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  FormCompanyScoreInput,
  FormCompanyWeightSelect,
} from '@core/components';

import { useScoreCardHandlers, useWorkingPage } from '@core/hooks';

import { CurrencyUtils, UsersUtils } from '@core/utils';

import { useEssentials, useWorkingPageContext } from '@core/contexts';

import { Box, Controls, FormGroup, Grid, Typography } from '@shared/components';

import type {
  IPrice,
  IThemeConfiguration,
} from '@inteliam/foundation/lib/types';

export interface ThemeBlockProps {
  themeIndex: number;
}

interface ICreditLimitForm {
  recommendedCreditLimit: IPrice;
}

const ThemeBlock: React.FCC<ThemeBlockProps> = ({ themeIndex }) => {
  const { theme, user, t } = useEssentials();
  const { scoreCard } = useWorkingPage();
  const { readOnly } = useWorkingPageContext();
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const configMethods = useForm<IThemeConfiguration>();
  const creditLimitMethods = useForm<ICreditLimitForm>({
    defaultValues: { recommendedCreditLimit: scoreCard.recommendedCreditLimit },
  });
  const watchedScorableCriteria = useWatch({
    control: configMethods.control,
    name: 'scorableCriteria',
    defaultValue: Boolean(themePartialScore.configuration?.scorableCriteria),
  });
  const watchedDefaultScoringMode = useWatch({
    control: configMethods.control,
    name: 'defaultScoringMode',
    defaultValue: themePartialScore.configuration?.defaultScoringMode,
  });

  const { onUpdate, onReset } = useScoreCardHandlers();

  const watchedRecommendedCreditLimitValue = useWatch({
    control: creditLimitMethods.control,
    name: 'recommendedCreditLimit.value',
    defaultValue: 0,
  });
  const watchedRecommendedCreditLimitCurrency = useWatch({
    control: creditLimitMethods.control,
    name: 'recommendedCreditLimit.currency',
    defaultValue: 'EUR',
  });

  useOnUpdate(() => {
    onReset();
    configMethods.setValue(
      'scorableCriteria',
      watchedDefaultScoringMode === 'AGGREGATED_SCORING'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedDefaultScoringMode]);

  useOnUpdate(() => {
    onUpdate(
      {
        recommendedCreditLimit: {
          value: watchedRecommendedCreditLimitValue,
          currency: watchedRecommendedCreditLimitCurrency,
        },
      },
      scoreCard.id
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchedRecommendedCreditLimitValue,
    watchedRecommendedCreditLimitCurrency,
    scoreCard.id,
  ]);
  return (
    <Box width={'100%'}>
      <Controls.Form methods={configMethods} submitHandler={noop}>
        {themePartialScore.configuration?.configurableScoringMode && (
          <Controls.FormRadioGroup
            id={`${themePartialScore.theme.code}-defaultScoringMode`}
            name='defaultScoringMode'
            label={''}
            options={[
              {
                value: 'AGGREGATED_SCORING',
                label:
                  'Calculate theme score automatically through criteria score',
              },
              {
                value: 'MANUAL_SCORING',
                label: 'Input score manually',
              },
            ]}
            defaultValue={themePartialScore.configuration?.defaultScoringMode}
            onChange={(_, value) => {
              // This will erase the score and weight
              onUpdate(
                {
                  [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                    value,
                  [`partialScores[${themeIndex}].configuration.scorableCriteria`]:
                    value === 'AGGREGATED_SCORING',
                  [`partialScores[${themeIndex}].scoringMeta.score`]: null,
                  [`partialScores[${themeIndex}].scoringMeta.weight`]: null,
                  // reset all criteria scores/weights
                  ...Object.keys(
                    themePartialScore.criteriaPartialScores
                  ).reduce((accumulator, __, criterionIndex) => {
                    accumulator[
                      `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].scoringMeta.score`
                    ] = null;
                    accumulator[
                      `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].scoringMeta.weight`
                    ] = null;
                    return accumulator;
                  }, {} as Record<string, null>),
                },
                scoreCard.id
              );
            }}
            readOnly={
              !UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user)
            }
            row
          />
        )}
        {themePartialScore.configuration?.configurableCriteriaScore && (
          <Controls.FormCheckbox
            name='scorableCriteria'
            label={'Scores for all criteria are available'}
            defaultChecked={themePartialScore.configuration?.scorableCriteria}
            onChange={(_, checked) => {
              onUpdate(
                {
                  [`partialScores[${themeIndex}].configuration.scorableCriteria`]:
                    checked,
                },
                scoreCard.id
              );
            }}
          />
        )}
      </Controls.Form>
      <Box
        border={1}
        borderColor='gray'
        borderRadius='4px'
        padding={1}
        style={{
          opacity: watchedScorableCriteria ? 1 : 0.5,
          pointerEvents: watchedScorableCriteria ? 'auto' : 'none',
          backgroundColor: watchedScorableCriteria
            ? undefined
            : theme.palette.grey[200],
        }}
      >
        <Grid container spacing={2}>
          {themePartialScore.criteriaPartialScores?.map(
            (criteriaPartialScore, criterionIndex) => {
              if (!themePartialScore.configuration) {
                throw new Error(
                  `Theme ${themePartialScore.theme.id} has no configuration for scoreCard ${scoreCard.id}`
                );
              }
              return (
                <CriteriaBlock
                  key={criteriaPartialScore.criterion.id}
                  {...{
                    criteriaPartialScore,
                    scoreCard,
                  }}
                  themeIndex={themeIndex}
                  criterionIndex={criterionIndex}
                  weightableCriteria={
                    themePartialScore.configuration.weightableCriteria
                  }
                  selectedScoringMode={watchedDefaultScoringMode}
                />
              );
            }
          )}
          <Grid item xs={12} sm={2} md={4}>
            <Controls.Form methods={creditLimitMethods} submitHandler={noop}>
              <Typography gutterBottom>
                {t('Recommended Credit Limit')}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '20px',
                }}
              >
                <Controls.FormInput
                  label={t('Recommended Credit Limit')}
                  name='recommendedCreditLimit.value'
                  min={0}
                  type='number'
                  readOnly={readOnly}
                />
                <Controls.FormSelect
                  label={t('Currency')}
                  name='recommendedCreditLimit.currency'
                  options={Object.entries(CurrencyUtils.CURRENCIES).map(
                    ([key, value]) => ({
                      value: key,
                      label: `${value} (${key})`,
                    })
                  )}
                  sx={{ width: '45%' }}
                  readOnly={readOnly}
                  disabled={watchedScorableCriteria === false}
                />
              </div>
            </Controls.Form>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const CriteriaBlock: React.FCC<{
  selectedScoringMode: IThemeConfiguration['defaultScoringMode'];
  weightableCriteria: IThemeConfiguration['weightableCriteria'];
  themeIndex: number;
  criterionIndex: number;
}> = ({
  selectedScoringMode,
  themeIndex,
  criterionIndex,
  weightableCriteria,
}) => {
  const { scoreCard } = useWorkingPage();
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const { readOnly } = useWorkingPageContext();
  const criteriaPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];

  const basePath = `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].scoringMeta`;
  const { methods: scoringMethods, onReset, onUpdate } = useScoreCardHandlers();
  const criterionName = I18n.getTranslatedValue(
    criteriaPartialScore.criterion.name
  );
  useOnUpdate(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScoringMode]);

  return (
    <Grid item xs={12} sm={2} md={2}>
      <Controls.Form methods={scoringMethods} submitHandler={noop}>
        <Typography gutterBottom>{criterionName}</Typography>
        <FormGroup>
          <FormCompanyScoreInput
            readOnly={readOnly}
            id={`${criteriaPartialScore.criterion.code}-score`}
            defaultScore={criteriaPartialScore.scoringMeta.score}
            onChange={(value) => {
              scoringMethods.setValue('validateScore', true);
              scoringMethods.setValue('validateWeight', false);
              onUpdate(
                {
                  [`${basePath}.score`]: value,
                },
                scoreCard.id
              );
            }}
          />
        </FormGroup>
        {weightableCriteria && (
          <FormGroup style={{ marginTop: 10 }}>
            <FormCompanyWeightSelect
              readOnly={readOnly}
              id={`${criteriaPartialScore.criterion.code}-weight`}
              defaultWeight={criteriaPartialScore.scoringMeta.weight}
              onChange={(value) => {
                {
                  scoringMethods.setValue('validateScore', false);
                  scoringMethods.setValue('validateWeight', true);

                  onUpdate(
                    {
                      [`${basePath}.weight`]: value,
                    },
                    scoreCard.id
                  );
                }
              }}
            />
          </FormGroup>
        )}
      </Controls.Form>
    </Grid>
  );
};

export default React.memo(ThemeBlock);
