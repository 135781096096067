import * as React from 'react';

import { TableRow, TableCell } from '@shared/components';

import type { RenderProps } from '@inteliam/foundation/lib/types';

type Args = {
  width: number;
};

type Layouts = {
  FullWidth: React.FCC;
  ScoringSectionTitle: React.FCC;
  Question: React.FCC;
  Option: React.FCC;
  Textbox: React.FCC;
  Dropdown: React.FCC;
  Reference: React.FCC;
  MaxScore: React.FCC;
  AdjustedMaxScore: React.FCC;
  PointsRules: React.FCC;
  FinalScore: React.FCC;
};

const getLayouts = ({ width }: Args): Layouts => {
  return {
    FullWidth: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          width,
        }}
        colSpan={4}
      >
        {children}
      </TableCell>
    ),
    Question: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          minWidth: width * 0.5,
          maxWidth: width * 0.5,
        }}
      >
        {children}
      </TableCell>
    ),
    Option: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          minWidth: width * 0.16,
          maxWidth: width * 0.16,
        }}
      >
        {children}
      </TableCell>
    ),
    Textbox: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          width: width * 0.06,
        }}
      >
        {children}
      </TableCell>
    ),
    // eslint-disable-next-line sonarjs/no-identical-functions
    Dropdown: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          width: width * 0.09,
        }}
      >
        {children}
      </TableCell>
    ),
    // eslint-disable-next-line sonarjs/no-identical-functions
    Reference: ({ children }) => (
      <TableCell
        align='center'
        style={{
          padding: 0,
          border: 0,
          width: width * 0.06,
        }}
      >
        {children}
      </TableCell>
    ),
    MaxScore: ({ children }) => (
      <TableCell
        align='center'
        style={{
          padding: 0,
          border: 0,
          minWidth: width * 0.06,
          width: width * 0.06,
        }}
      >
        {children}
      </TableCell>
    ),
    AdjustedMaxScore: ({ children }) => (
      <TableCell
        align='center'
        style={{
          padding: 0,
          border: 0,
          minWidth: width * 0.12,
          width: width * 0.12,
        }}
      >
        {children}
      </TableCell>
    ),
    PointsRules: ({ children }) => (
      <TableCell
        align='center'
        style={{
          padding: 0,
          border: 0,
          minWidth: width * 0.08,
          width: width * 0.08,
        }}
      >
        {children}
      </TableCell>
    ),
    FinalScore: ({ children }) => (
      <TableCell
        align='center'
        style={{
          padding: 0,
          border: 0,
          minWidth: width * 0.07,
          width: width * 0.07,
        }}
      >
        {children}
      </TableCell>
    ),
    ScoringSectionTitle: ({ children }) => (
      <TableCell
        style={{
          border: 0,
          display: 'flex',
          alignItems: 'center',
        }}
        colSpan={4}
      >
        {children}
      </TableCell>
    ),
  };
};
export const AnswerAnalysisRow: React.FC<
  RenderProps<{
    Layouts: Layouts;
  }>
> = ({ children }) => {
  const [width, setWidth] = React.useState(0);
  React.useLayoutEffect(() => {
    const modalWidth = document.getElementById(
      'modal-question-item'
    )?.clientWidth;
    const accordionWidth = document.getElementById(
      'answer-analysis-accordion'
    )?.clientWidth;

    setWidth(modalWidth || accordionWidth || 0);
  }, []);

  if (!width) return <></>;
  const Layouts = getLayouts({ width: width - 50 });
  // -50 for padding
  return (
    <TableRow
      style={{
        verticalAlign: 'baseline',
        padding: '3px',
        width,
        display: 'table',
      }}
    >
      {typeof children === 'function' ? children({ Layouts }) : children}
    </TableRow>
  );
};
