import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { SsqQuestionsApi } from '@core/api';

import type {
  IAPIResponse,
  IAnyEnterprise,
  IAnySsqQuestion,
  IPaginationQuery,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

interface Args {
  manufacturerId: IAnyEnterprise['id'];
  paginator?: IPaginationQuery;
}
const DEFAULT_PAGINATOR: IPaginationQuery = {
  itemsPerPage: -1,
  sort: { id: 'desc' },
  page: 1,
  criteria: {
    operator: 'or',
    conditions: [],
  },
};
const useFetchSsqQuestions = ({
  manufacturerId,
  paginator = DEFAULT_PAGINATOR,
}: Args): InteliamUseQueryResult<
  IAPIResponse<Array<IAnySsqQuestion>>,
  BaseAxiosErrorResponse
> & {
  questions: Array<IAnySsqQuestion>;
} => {
  const query = useQuery<
    IAPIResponse<Array<IAnySsqQuestion>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.SSQ_QUESTIONS_LIST, manufacturerId],
    () => SsqQuestionsApi.paginate(manufacturerId, paginator),
    {
      ...DEFAULT_QUERY_OPTIONS,
    }
  );

  const questions = Helpers.ensureValueAsArray(query.data?.data);
  return {
    ...query,
    questions,
  };
};

export default useFetchSsqQuestions;
