import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isICarbonCalculationParameter } from '@core/guards';
import type { ICarbonCalculationParameter } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type CalculationParameterActions = 'paginate' | 'patch';

const CalculationParameterApi = ApiFactory.generate<
  ICarbonCalculationParameter,
  JwtBOUser,
  CalculationParameterActions
>({
  baseRoute: '/ghg/parameters',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isICarbonCalculationParameter,
});

export default {
  ...CalculationParameterApi.actions,
};
