/* eslint-disable @inteliam/i18n/raw-text-detected */
import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { lighten } from '@mui/material';

import type { QueryKey } from '@tanstack/react-query';
import React, { memo } from 'react';

import { clsx } from 'clsx';

import { useMutation } from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';

import {
  Tooltip,
  Typography,
  CircularProgress,
  IconButton,
  DeleteIcon,
  Toolbar,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IFilterableOption, Theme } from '@shared/types';

import type {
  FilterChangerFn,
  IAPIResponse,
  Id,
  IFilter,
  VisibilityChangerFn,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

import CriteriaBuilder from './filter';
import Settings from './settings';

const useToolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingRight: 0,
    marginTop: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

interface TableToolbarProps {
  queryKey: QueryKey;
  selected: Id[];
  onDelete?: (id: Id) => Promise<IAPIResponse>;
  onDeleteSuccess: () => void;
  columns: IFilterableOption[];
  onChangeVisibility: VisibilityChangerFn;
  filter: IFilter;
  onChangeFilter: FilterChangerFn;
  children: React.ReactNode;
}
const TableToolbar: React.FC<TableToolbarProps> = ({
  selected,
  onDelete,
  onChangeVisibility,
  onDeleteSuccess,
  queryKey,
  columns,
  filter,
  onChangeFilter,
  children,
}) => {
  const classes = useToolbarStyles();
  const queryClient = useQueryClient();
  const { t, confirm, notify } = useEssentials();

  const mutation = useMutation<IAPIResponse[], BaseAxiosErrorResponse, Id[]>(
    (parameter) => {
      if (onDelete) {
        // eslint-disable-next-line unicorn/no-array-callback-reference
        const promises = parameter.map(onDelete);
        return Promise.all(promises);
      } else {
        return Promise.resolve([]);
      }
    },
    {
      onSuccess() {
        onDeleteSuccess();
        notify({
          message: t('Operation successfully completed'),
          type: INotifierEnum.Success,
        });
      },
      onError() {
        notify({
          message: t('Something went wrong'),
          type: INotifierEnum.Error,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey).catch(() => {});
      },
    }
  );
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}
    >
      {selected.length > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography style={{ flex: 1 }}></Typography>
      )}
      {selected.length > 0 && (
        <>
          {mutation.isLoading ? (
            <Tooltip title='Loading'>
              <CircularProgress color='inherit' size={16} />
            </Tooltip>
          ) : (
            <>
              {onDelete && (
                <Tooltip title={t('Delete')}>
                  <IconButton
                    id={'delete-items'}
                    onClick={() => {
                      confirm({
                        description: t('Are you sure ?'),
                      })
                        .then(() => {
                          mutation.mutate(selected);
                        })
                        .catch(() => {});
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
      <React.Fragment>
        {children}
        <CriteriaBuilder
          columns={columns}
          filter={filter}
          onChangeFilter={onChangeFilter}
        />
        <Settings columns={columns} onChangeVisibility={onChangeVisibility} />
      </React.Fragment>
    </Toolbar>
  );
};

export default memo(TableToolbar);
