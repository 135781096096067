import { yupResolver } from '@hookform/resolvers/yup';

import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Button, Controls } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type { IResetPasswordForm } from '@inteliam/foundation/lib/types';

import { resetPasswordSchema as schema } from './schema';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPasswordForm: React.FC<{
  onSubmit: (data: IResetPasswordForm) => void;
}> = ({ onSubmit }) => {
  const classes = useStyles();
  const methods = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema),
  });

  const { t } = useEssentials();
  return (
    <Controls.Form
      methods={methods}
      submitHandler={onSubmit}
      className={classes.form}
    >
      <Controls.FormInput
        required
        fullWidth
        margin='normal'
        name='password'
        label={t('New password')}
        autoComplete='new-password'
        type='password'
      />
      <Controls.FormInput
        required
        margin='normal'
        fullWidth
        name='confirmPassword'
        autoComplete='new-password'
        label={t('Confirm new password')}
        type='password'
      />
      <Button
        id='submit'
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
      >
        {t('Reset password')}
      </Button>
    </Controls.Form>
  );
};

export default ResetPasswordForm;
