import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import type { IOrderHighlightPayload } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAR,
  IAssessmentHighlight,
  Id,
  IPaginationQuery,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/assessment-requests';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const toggleSelection = async (args: {
  assessmentRequestId: Id;
  analysisHighlights: { ids: Array<Id> };
}): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch<IAPIResponse>(
    `/${args.assessmentRequestId}/highlights`,
    args.analysisHighlights
  );
  return data;
};

const reorder = (query: IOrderHighlightPayload): Promise<void> => {
  return client.instance.patch(
    `/${query.assessmentRequestId}/highlights/${query.assessmentHighlightId}/re-order`,
    query.body
  );
};

const getHighlights = async (
  id: IAR['id']
): Promise<IAPIResponse<Array<IAssessmentHighlight>>> => {
  const { data } = await client.instance.get<
    IAPIResponse<Array<IAssessmentHighlight>>
  >(`/${id}/highlights`);
  return data;
};

const paginate = async (
  query: IPaginationQuery
): Promise<IPaginationResponse<IAssessmentHighlight>> => {
  // TODO: to be changed within a convenient api
  const highlightsClient = new PrivateApi(
    {
      baseRoute: '/assessment-requests/highlights/translations',
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: query,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  const { data } = await highlightsClient.instance.get<
    IPaginationResponse<IAssessmentHighlight>
  >('');
  return data;
};

export default {
  toggleSelection,
  reorder,
  getHighlights,
  paginate,
};
