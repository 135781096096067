import { ApiFactory } from '@inteliam/foundation/lib/api';
import { isIEnterprise } from '@inteliam/foundation/lib/guards';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAnyEnterprise, JwtBOUser } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/enterprises';
type EnterprisesActions = 'paginate' | 'getOneById' | 'create' | 'update';

const baseEnterpriseApi = ApiFactory.generate<
  IAnyEnterprise,
  JwtBOUser,
  EnterprisesActions
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIEnterprise,
});

export default {
  ...baseEnterpriseApi.actions,
};
