import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const START_YEAR = 2021;
const END_YEAR = new Date().getFullYear();

const YearFilterer: React.FCC<
  FormSelectProps & { defaultItem: IFilterDefaultItem }
> = (props) => {
  return (
    <Controls.FormSelect
      {...omit(props, ['defaultItem'])}
      options={Array.from(
        { length: END_YEAR - START_YEAR + 1 },
        (_, index) => ({
          value: START_YEAR + index,
          label: `${START_YEAR + index}`,
        })
      )}
      required
    />
  );
};

export default YearFilterer;
