/* eslint-disable @typescript-eslint/no-explicit-any */
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';

import React, { HTMLProps, forwardRef } from 'react';
import { FieldValues, FormProvider, SubmitHandler } from 'react-hook-form';

import type { FormMethods } from '@shared/types';

// Author's note : !!! the declare keyword here is mandatory since we need to redeclare and redefine global module
// For more details check for "Declaration merging" and "Higher order function type inference"
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

interface Props<T extends FieldValues> extends HTMLProps<HTMLFormElement> {
  methods: FormMethods<T>;
  submitHandler: SubmitHandler<T>;
  failureHandler?: () => void;
}

function ControlsFormInner<T extends FieldValues>(
  props: Props<T>,
  ref: React.Ref<HTMLFormElement>
) {
  const { submitHandler, failureHandler, methods, ...rest } = props;
  const {
    formState: { submitCount, errors },
    handleSubmit,
  } = methods;

  useOnUpdate(
    (prevDeps: [prevSubmitCount: number]) => {
      const [prevSubmitCount] = prevDeps;
      if (prevSubmitCount !== submitCount && Object.keys(errors).length > 0) {
        failureHandler?.();
      }
    },
    [submitCount, errors, failureHandler]
  );
  return (
    <FormProvider {...methods}>
      <form
        {...rest}
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(submitHandler)}
        noValidate
      />
    </FormProvider>
  );
}

const ControlsFormWithRef = forwardRef(ControlsFormInner);

export default ControlsFormWithRef;
