import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { QUERY_KEYS, useMutation, useQuery } from '@core/queries';

import { EnterprisesApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type { QueryTypes } from '@shared/types';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  IAnyEnterprise,
  IPartialEnterprise,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

type GetOneResponse = Awaited<ReturnType<typeof EnterprisesApi.getOneById>>;
type UpdateResponse = Awaited<ReturnType<typeof EnterprisesApi.update>>;
type CreateResponse = Awaited<ReturnType<typeof EnterprisesApi.create>>;
type PaginateResponse = Awaited<ReturnType<typeof EnterprisesApi.paginate>>;
type PaginateArgs = Parameters<typeof EnterprisesApi.paginate>[0];

type ManageEnterprise = (parameters?: {
  getOneById?: {
    args: {
      id?: IAnyEnterprise['id'];
    };
    options?: InteliamUseQueryOptions<GetOneResponse, BaseAxiosErrorResponse>;
  };
  update?: {
    options?: InteliamUseMutationOptions<
      UpdateResponse,
      AxiosValidationErrorResponse,
      IPatchObject<IPartialEnterprise>
    >;
  };
  create?: {
    options?: InteliamUseMutationOptions<
      CreateResponse,
      AxiosValidationErrorResponse,
      IPartialEnterprise
    >;
  };
  paginate?: {
    options?: InteliamUseQueryOptions<PaginateResponse, BaseAxiosErrorResponse>;
    args?: PaginateArgs;
  };
}) => {
  getOneById: InteliamUseQueryResult<GetOneResponse, BaseAxiosErrorResponse>;
  update: InteliamUseMutationResult<
    UpdateResponse,
    AxiosValidationErrorResponse,
    IPatchObject<IPartialEnterprise>
  >;
  create: InteliamUseMutationResult<
    CreateResponse,
    AxiosValidationErrorResponse,
    IPartialEnterprise
  >;
  paginate: InteliamUseQueryResult<PaginateResponse, BaseAxiosErrorResponse>;
};

const useManageEnterprises: ManageEnterprise = (args) => {
  const getOneByIdQuery = useQuery<GetOneResponse, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.MANUFACTURERS_LIST, args?.getOneById?.args.id],
    () =>
      args?.getOneById?.args.id
        ? EnterprisesApi.getOneById(args?.getOneById?.args.id)
        : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      enabled: Boolean(args?.getOneById?.args.id),
      initialData: undefined,
      cacheTime: 1000 * 20,
      refetchOnMount: false,
      ...args?.getOneById?.options,
    }
  );

  const updateMutation = useMutation<
    UpdateResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(EnterprisesApi.update, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.MANUFACTURERS_LIST],
    ...args?.update?.options,
  });

  const createMutation = useMutation<
    CreateResponse,
    AxiosValidationErrorResponse,
    IPartialEnterprise
  >(EnterprisesApi.create, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.MANUFACTURERS_LIST],
    ...args?.create?.options,
  });

  type Query = QueryTypes<typeof EnterprisesApi.paginate>;
  const paginateQuery = useQuery<PaginateResponse, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.MANUFACTURERS_LIST, args?.paginate?.args],
    () =>
      args?.paginate?.args
        ? EnterprisesApi.paginate(args?.paginate?.args)
        : Promise.reject(),
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData:
        DatatableUtils.createInitialPaginationResponse<
          Query['functionReturnType']['data'][number]
        >(),
      enabled: Boolean(args?.paginate?.args),
      ...args?.paginate?.options,
    }
  );
  return {
    getOneById: getOneByIdQuery,
    update: updateMutation,
    create: createMutation,
    paginate: paginateQuery,
  };
};
export default useManageEnterprises;
