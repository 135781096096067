import { Helpers } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import {
  Box,
  Controls,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@shared/components';

const OptionsScoringConfiguration: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext<IQuestionForm>();
  const watchedOptions =
    useWatch({
      control: methods.control,
      name: 'options',
    }) || [];

  const OPTIONS_SCORING_PARAMETERS_ROWS = watchedOptions ?? [];

  const OPTIONS_SCORING_PARAMETERS_COLUMNS = [
    {
      label: 'Option Text',
    },
    {
      label: 'Option Reference',
    },
    {
      label: 'Max Option Score',
    },
  ];

  return (
    <Grid item md={12} xs={12}>
      <TableContainer component={Paper}>
        <Table size='small' style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              {OPTIONS_SCORING_PARAMETERS_COLUMNS.map((column, index) => {
                return (
                  <TableCell key={index} align='center'>
                    {t(column.label)}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {OPTIONS_SCORING_PARAMETERS_ROWS.map((_, index) => {
              const fieldName = Helpers.arrayDotNotation('options', index);
              return (
                <TableRow key={index}>
                  <TableCell>
                    <FormGroup>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Box p={1} display='flex'>
                          <div style={{ height: 30 }}>
                            {get(watchedOptions, `${index}.label.defaultValue`)}
                          </div>
                        </Box>
                      </Box>
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Box p={1} display='flex'>
                          <div style={{ height: 30 }}>
                            {get(watchedOptions, `${index}.reference`)}
                          </div>
                        </Box>
                      </Box>
                    </FormGroup>
                  </TableCell>
                  <TableCell>
                    <FormGroup>
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <Box p={1} display='flex'>
                          <Controls.FormInput
                            label={t('Max Option Score')}
                            name={`${fieldName}.scoringMeta.maxScore`}
                            type='number'
                            min={0}
                            max={100}
                            required
                          />
                        </Box>
                      </Box>
                    </FormGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
export default OptionsScoringConfiguration;
