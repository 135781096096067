import {
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { addBusinessDays } from 'date-fns';
import { set } from 'lodash-es';

import React, { memo } from 'react';

import {
  useFetchCampaignTypeByType,
  useSpecificTransition,
} from '@core/queries';

import { ARUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import DeadlineForm from '../update-deadlines/deadline-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const SURVEY_STATUSES = new Set([
  IARStatusEnum.ANSWERING_SURVEY,
  IARStatusEnum.IN_REVIEW,
  IARStatusEnum.IN_DOC_ANALYSIS,
  IARStatusEnum.IN_SCORING,
  IARStatusEnum.RTA,
]);

const AnswerSurveyDialog: React.FCC<Props> = ({
  assessmentRequest,
  onCancel,
  onConfirm,
  open,
}) => {
  const { t } = useEssentials();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });
  const { run: runUpdate, mutation } = useSpecificTransition({
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',
    assessmentRequest: assessmentRequest,
    transition: IARTransitionEnum.ANSWER_SURVEY,
    successMessage: 'Assessment request status successfully updated',
    confirmMessage:
      'Are you sure you want to update the questionnaire answering deadline ?',
    onSuccess: onConfirm,
  });

  const handleOnSubmit = (deadlines: IARDeadlinesForm) => {
    runUpdate({
      data: deadlines,
    }).catch(() => {});
  };

  if (!campaignTypeQuery.isSettled) {
    return <BaseDialog.DialogPlaceholder />;
  }
  if (!campaignTypeQuery.isSuccess) {
    return (
      <BaseDialog.DialogPlaceholder>
        {t('Unable to find the requested standard campaign')}
      </BaseDialog.DialogPlaceholder>
    );
  }

  let defaultValues = undefined;
  let statuses = assessmentRequest.statuses.filter(
    (status) => status.codename === IARStatusEnum.ANSWERING_SURVEY
  );

  let disabledStatuses = assessmentRequest.statuses
    .filter((status) => status.codename !== IARStatusEnum.ANSWERING_SURVEY)
    .map((status) => status.codename);

  const isBackTransition =
    assessmentRequest.currentStatusMeta.codename === IARStatusEnum.IN_REVIEW;

  if (isBackTransition) {
    statuses = assessmentRequest.statuses.filter((status) =>
      [
        IARStatusEnum.ANSWERING_SURVEY,
        IARStatusEnum.IN_REVIEW,
        IARStatusEnum.IN_DOC_ANALYSIS,
        IARStatusEnum.IN_SCORING,
        IARStatusEnum.RTA,
      ].includes(status.codename)
    );

    disabledStatuses = [];

    defaultValues = assessmentRequest.statuses
      .filter((status) => SURVEY_STATUSES.has(status.codename))
      .reduce((accumulator, status) => {
        set(
          accumulator,
          ARUtils.getStatusDeadlineKey(status),
          ARUtils.getDefaultDeadlineByStatus({
            targetStatus: status,
            campaignType:
              assessmentRequest.campaign?.type || campaignTypeQuery.data.data,
            startDate: addBusinessDays(new Date(), 3),
          })
        );
        return accumulator;
      }, {} as Partial<IARDeadlinesForm>);
  }

  return (
    <DeadlineForm
      title={t('Reopen the questionnaire')}
      onClose={onCancel}
      onSubmit={handleOnSubmit}
      errors={mutation.error?.response?.data}
      isLoading={mutation.isLoading}
      {...{
        assessmentRequest,
        defaultValues,
        disabledStatuses,
        statuses,
        open,
      }}
    />
  );
};

export default memo(AnswerSurveyDialog);
