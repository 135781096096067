import type { IARPhaseEnum } from '@inteliam/foundation/lib/enums';
import { omit } from 'lodash-es';

import * as React from 'react';

import { useFetchAssignableUsers } from '@core/queries';

import { Controls, Noop } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const AssignmentFilterer: React.FCC<
  FormSelectProps & {
    phase: IARPhaseEnum;
    defaultItem: IFilterDefaultItem;
  }
> = ({ phase, defaultValue, ...rest }) => {
  const { formatted: users, isLoading } = useFetchAssignableUsers({ phase });

  if (isLoading) {
    return <Noop />;
  }
  return (
    <Controls.FormSelect
      {...omit(rest, ['defaultItem'])}
      defaultValue={defaultValue}
      options={users}
      required
    />
  );
};

export default AssignmentFilterer;
