/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

import { questionTranslationFieldSchema } from '@shared/schemas';

import type { StringOrUndefined } from '@inteliam/foundation/lib/types';

export const create = Yup.object().shape({
  code: Yup.string().required(ErrorCodesLookup.E0001.title),
  title: questionTranslationFieldSchema(true),
  helpTextContent: questionTranslationFieldSchema(),
  kind: Yup.string().required(ErrorCodesLookup.E0001.title),
  version: Yup.number().required(ErrorCodesLookup.E0001.title),
  required: Yup.boolean(),
  theme: Yup.object().shape({
    id: Yup.string().required(ErrorCodesLookup.E0001.title),
  }),
  criterion: Yup.object().shape({
    required: Yup.boolean(),
    id: Yup.string().when('required', {
      is: true,
      then: Yup.string().test(
        'required-criterion',
        'validations.question.criterion.required',
        (value) => {
          return Boolean(value) === true;
        }
      ),
      otherwise: Yup.string().optional(),
    }),
  }),
  options: Yup.array().when('kind', {
    is: (kind: IQuestionKindEnum) =>
      kind === IQuestionKindEnum.SingleFreeFormField,
    then: Yup.array().optional(),
    otherwise: Yup.array().of(
      Yup.object().shape({
        label: questionTranslationFieldSchema(true),
        helpTextContent: questionTranslationFieldSchema(),
        order: Yup.number(),
        scopes: Yup.array().of(
          Yup.object().shape({
            country: Yup.number(),
            size: Yup.string(),
            category: Yup.string(),
          })
        ),
        resetOption: Yup.boolean(),
        textBox: Yup.string(),
        textBoxLabel: Yup.object().when('textBox', {
          is: 'no',
          then: questionTranslationFieldSchema(),
          otherwise: questionTranslationFieldSchema(true),
        }),
        textBoxType: Yup.string(),
        reference: Yup.string(),
        dropDown: Yup.string(),
        dropDownLabel: Yup.object().when('dropDown', {
          is: 'no',
          then: questionTranslationFieldSchema(),
          otherwise: questionTranslationFieldSchema(true),
        }),
        dropDownChoices: Yup.array().when('dropDown', {
          is: 'no',
          then: Yup.array().nullable(),
          otherwise: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string().required(),
                label: questionTranslationFieldSchema(),
              })
            )
            .min(2),
        }),
        dropDownMultiAnswers: Yup.boolean(),
        scoringMeta: Yup.object().shape({
          maxScore: Yup.number()
            .typeError('Please select a value between 0 and 100')
            .min(0)
            .max(100)
            .required(),
        }),
      })
    ),
  }),
  parentDependency: Yup.object().shape({
    question: Yup.object().shape({
      id: Yup.string(),
    }),
    optionIds: Yup.array().when('question.id', {
      is: (questionId: StringOrUndefined) => true === Boolean(questionId),
      then: Yup.array().of(Yup.string().required()).min(1),
      otherwise: Yup.array().nullable(),
    }),
  }),
  // If you added a child, the question and options become required
  childrenDependencies: Yup.array().of(
    Yup.object().shape({
      question: Yup.object().shape({
        id: Yup.string().required(ErrorCodesLookup.E0001.title),
      }),
      optionIds: Yup.array()
        .of(Yup.string().required(ErrorCodesLookup.E0001.title))
        .min(1),
    })
  ),
  scoringMeta: Yup.object().shape({
    maxScore: Yup.number()
      .typeError('Please select a value between 0 and 100')
      .min(0)
      .max(100)
      .required(),
  }),
});
