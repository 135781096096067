import { useBoolean, useQueryClient } from '@inteliam/foundation/lib/hooks';
import { StringFormatter } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { ManageARUsersModal } from '@core/components/modals';

import { QUERY_KEYS } from '@core/queries';

import { useDashboard, useEssentials } from '@core/contexts';

import {
  IconButton,
  TooltippedCell,
  Tooltip,
  Grid,
  Box,
  EditIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
}

const useStyles = makeStyles(() => ({
  multiLine: {
    lineHeight: 'normal',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const ContactCell: React.FCC<Props> = ({ assessmentRequest }) => {
  const [open, openHandlers] = useBoolean();
  const { dashboard } = useDashboard();
  const classes = useStyles();
  const { t, na } = useEssentials();

  const queryClient = useQueryClient();
  const onClose = () => {
    queryClient
      .invalidateQueries(QUERY_KEYS.DASHBOARD[dashboard])
      .catch(() => {});
    openHandlers.setFalse();
  };

  const content = (
    <div className={classes.multiLine}>
      <div>
        {t('Primary')}
        {':'}
        {StringFormatter.formatName(
          assessmentRequest.scoredCompany?.primaryContact?.contactInfo
            ?.firstName,
          assessmentRequest.scoredCompany?.primaryContact?.contactInfo?.lastName
        ) || na}
      </div>
      <div>
        {t('Questionnaire')}
        {':'}
        {StringFormatter.formatName(
          assessmentRequest.questionnaireContact?.contactInfo?.firstName,
          assessmentRequest.questionnaireContact?.contactInfo?.lastName
        ) || na}
      </div>
    </div>
  );

  return (
    <Grid
      container
      direction='row'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item sx={{ width: '70%', overflowX: 'hidden' }}>
        <TooltippedCell title={content} disabled={open}>
          <Box>{content}</Box>
        </TooltippedCell>
      </Grid>
      <Grid item>
        <Tooltip title={t('Manage contacts')}>
          <span>
            <IconButton
              id={`open-contact-${assessmentRequest.businessId}`}
              edge='start'
              color='primary'
              onClick={openHandlers.setTrue}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <ManageARUsersModal
        key={`${assessmentRequest.questionnaireContact?.id || '-'}-${
          assessmentRequest.scoredCompany.primaryContact?.id || '-'
        }`}
        assessmentRequest={assessmentRequest}
        {...{ open, onClose }}
      />
    </Grid>
  );
};

export default ContactCell;
