import { isDate } from '@inteliam/foundation/lib/guards';
import { formatISO } from 'date-fns';

const normalizer = (value: unknown): string => {
  if (typeof value === 'string' || typeof value === 'number' || isDate(value)) {
    return formatISO(new Date(value));
  }
  throw new Error('[Date Filterer] : value should be a date');
};

export default normalizer;
