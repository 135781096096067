import { isNil } from 'lodash-es';

import { ErrorCodesLookup, ErrorCodesEnumType } from '@shared/utils';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

export const formatValidationErrors = (response: ValidationErrorResponse) => {
  if (isNil(response?.errors)) {
    return [];
  }
  return response.errors.map((item) => {
    return {
      ...item,
      errors: item.errors.map(
        (errorMessage) =>
          ErrorCodesLookup[errorMessage as ErrorCodesEnumType].title
      ),
    };
  });
};
