import {
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useSpecificTransition } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import DeadlineForm from '../update-deadlines/deadline-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const REVIEW_STATUSES = new Set([
  IARStatusEnum.IN_REVIEW,
  IARStatusEnum.IN_DOC_ANALYSIS,
  IARStatusEnum.IN_SCORING,
  IARStatusEnum.RTA,
]);

const ReviewSurveyDialog: React.FCC<Props> = ({
  assessmentRequest,
  onCancel,
  onConfirm,
  open,
}) => {
  const { t } = useEssentials();
  const { run: runUpdate, mutation } = useSpecificTransition({
    assessmentRequest: assessmentRequest,
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',
    transition: IARTransitionEnum.REVIEW,
    successMessage: 'The deadline was successfully updated',
    confirmMessage: 'Are you sure you want to update the next deadline ?',
    onSuccess: onConfirm,
  });

  const statuses = assessmentRequest.statuses.filter((status) =>
    REVIEW_STATUSES.has(status.codename)
  );

  const handleOnSubmit = (deadlines: IARDeadlinesForm) => {
    runUpdate({
      data: deadlines,
    }).catch(() => {});
  };
  return (
    <DeadlineForm
      title={t('Update Next Deadline')}
      onClose={onCancel}
      onSubmit={handleOnSubmit}
      errors={mutation.error?.response?.data}
      isLoading={mutation.isLoading}
      {...{ assessmentRequest, open, statuses }}
    />
  );
};

export default memo(ReviewSurveyDialog);
