import { Yup } from '@inteliam/foundation/lib/utils';

import {
  discountSchema,
  validatePercentagesSum,
  validateSameDiscountsType,
} from '@core/components/distributors/subscription';

import { ErrorCodesLookup } from '@shared/utils';

const statusesSchema = Yup.array().of(
  Yup.object().shape({
    deadline: Yup.date().when('passed', {
      is: false,
      then: Yup.date()
        .nullable()
        .notRequired()
        .transform((current, orig) => (orig === '' ? undefined : current))
        .required(ErrorCodesLookup.E0001.title),
      otherwise: Yup.date().nullable().notRequired(),
    }),
  })
);
export const updateDeadlinesSchema = Yup.object().shape({
  statuses: statusesSchema,
});

export const initiateSurveySchema = Yup.object()
  .shape({
    statuses: statusesSchema,
    subscriptionCategory: Yup.string().required(),
    inteliamDiscountEnabled: Yup.boolean().default(false),
    manufacturerDiscountEnabled: Yup.boolean().default(false),
    manufacturerDiscounts: Yup.object().when('manufacturerDiscountEnabled', {
      is: true,
      then: Yup.object().shape({
        '1_YEAR': Yup.object().when('manufacturersDiscount.1_YEAR.enabled', {
          is: true,
          then: discountSchema,
        }),
        '3_YEAR': Yup.object().when('manufacturersDiscount.3_YEAR.enabled', {
          is: true,
          then: discountSchema,
        }),
      }),
    }),
    inteliamDiscounts: Yup.object().when('inteliamDiscountEnabled', {
      is: true,
      then: Yup.object().shape({
        '1_YEAR': Yup.object().when('inteliamDiscounts.1_YEAR.enabled', {
          is: true,
          then: discountSchema,
        }),
        '3_YEAR': Yup.object().when('inteliamDiscounts.3_YEAR.enabled', {
          is: true,
          then: discountSchema,
        }),
      }),
    }),
  })
  .test(
    'manufacturer discount percentage validation with inteliam',
    (value, testContext) =>
      validatePercentagesSum(
        value,
        testContext,
        'manufacturerDiscount.percentage'
      )
  )
  .test(
    'manufacturer discount type must be the same as inteliam',
    (value, testContext) =>
      validateSameDiscountsType(
        value,
        '1_YEAR',
        testContext,
        'manufacturerDiscounts.1_YEAR.type',
        'Types for Inteliam and enterprise discounts must be the same'
      )
  )
  .test(
    'manufacturer discount type must be the same as inteliam',
    (value, testContext) =>
      validateSameDiscountsType(
        value,
        '3_YEAR',
        testContext,
        'manufacturerDiscounts.3_YEAR.type',
        'Types for Inteliam and enterprise discounts must be the same'
      )
  );

export const reuseARSchema: Yup.AnyObjectSchema = Yup.object().shape({
  deadline: Yup.date()
    .min(new Date(), 'The deadline cannot be in the past')
    .required(),
});
