import {
  IAnyCompany,
  IBOUser,
  ICountry,
  ICriterionClassification,
  IIdentifiedObject,
  IThemeClassification,
  ReferenceOne,
} from '@inteliam/foundation/lib/types';

type ICompanyType = 'DISTRIBUTOR' | 'SUPPLIER' | 'TRADING_GROUP' | 'OTHER';
export interface IBestPracticeForm extends IIdentifiedObject {
  createdAt: Date;
  updatedAt?: Date;
  bestPracticeDate: number;
  company?: Omit<
    IAnyCompany,
    | 'assessmentRequest'
    | 'memberOf'
    | 'users'
    | 'primaryContact'
    | 'lastSurveyContact'
    | 'subscriptionLog'
    | 'subscriptionInfo'
    | 'lastCarbonAssessment'
    | 'parent'
  >;
  companyName?: string;
  companyType: ICompanyType;
  createdBy?: Omit<IBOUser, 'manufacturers'>;
  description: string;
  internalComment: string;
  theme: ReferenceOne<IThemeClassification>;
  criterion: ReferenceOne<ICriterionClassification>;
  country?: ICountry;
  size?: string;
  visibleForEnterprises: boolean;
}

export type IBestPractice = IBestPracticeForm;

export const isBestPractice = (arg: any): arg is IBestPractice => {
  return typeof arg.id === 'string';
};
