import type { SharingRequestStatusEnum } from '@inteliam/foundation/lib/enums';

import * as React from 'react';

import { useSharingRequestMachine, SRTransitionProvider } from '@core/hooks';

import { SRUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { NativeSelect, FormControl } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { ISharingRequest } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles(() => ({
  select: {
    height: '100%',
  },
}));

interface Props {
  sharingRequest: ISharingRequest;
}
const StatusCell: React.FCC<Props> = ({ sharingRequest }) => {
  const classes = useStyles();
  const { t } = useEssentials();
  const { transition } = useSharingRequestMachine({ sharingRequest });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const targetStatus = event.target.value as SharingRequestStatusEnum;
    transition.run({ target: targetStatus }).catch(() => {});
  };

  return (
    <FormControl fullWidth>
      <NativeSelect
        // todo what could be the unique identifier for sharing request and also easy to get for Hichem
        id={`select-status-${sharingRequest.scoredCompany.id}`}
        value={sharingRequest?.status}
        onChange={handleChange}
        name='status'
        inputProps={{
          className: classes.select,
        }}
        fullWidth
      >
        {[
          sharingRequest.status,
          ...SRUtils.getPossibleTransitions({ current: sharingRequest.status }),
        ].map((possibleStatus) => (
          <option
            data-cy={`select-status-${possibleStatus}`}
            key={possibleStatus}
            value={possibleStatus}
          >
            {t(possibleStatus)}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

const EnhancedStatusCell: React.FCC<Props> = (props) => (
  <SRTransitionProvider>
    <StatusCell {...props} />
  </SRTransitionProvider>
);

export default EnhancedStatusCell;
