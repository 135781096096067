import { CONDITIONS_CODES } from '@core/enums';

export function getConditionTranslation(condition: number): string | '' {
  switch (condition) {
    case CONDITIONS_CODES.OPTION_SELECTED_REFERENCE_APPROVED: {
      return 'Option selected, reference approved';
    }
    case CONDITIONS_CODES.OPTION_SELECTED_REFERENCE_REJECTED: {
      return 'Option selected, reference rejected';
    }
    case CONDITIONS_CODES.OPTION_SELECTED_WITHOUT_REFERENCE: {
      return 'Option selected without reference';
    }
    case CONDITIONS_CODES.OPTION_NOT_SELECTED: {
      return 'Option not selected';
    }
    default: {
      return '';
    }
  }
}
