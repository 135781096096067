import { Card as MuiCard, CardProps } from '@mui/material';

import * as React from 'react';

import { makeStyles } from '@shared/utils';

// Why not Actions: https://github.com/import-js/eslint-plugin-import/issues/2289

const useStyles = makeStyles((theme) => ({
  parentCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: 10,
  },
}));

interface Props extends CardProps {
  styled?: boolean;
}

export const CContainer: React.FCC<Props> = ({ styled, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiCard
      className={styled ? classes.parentCard : undefined}
      {...rest}
    ></MuiCard>
  );
};

export {
  CardHeader as Header,
  CardContent as Body,
  CardActions as CActions,
} from '@mui/material';
