import { ITypeEnum } from '@inteliam/foundation/lib/enums';

import React, { memo, useCallback, useMemo } from 'react';

import { ContactLogForm } from '@core/components/contact-logs';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { ContactLogsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IContactLogForm, ICreateContactLogRequest } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IAR,
  IContactLog,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

type AssessmentRequestPageProps = {
  view: 'AR';
  assessmentRequest: IAR;
};
type DistributorPageProps = {
  view: 'DISTRIBUTOR';
  distributorId: string;
};
interface RequiredProps {
  selectedContactLog: IContactLog;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}
const UpdateContactLogModal: React.FCC<
  (AssessmentRequestPageProps | DistributorPageProps) & RequiredProps
> = (props) => {
  const { t, notify, user } = useEssentials();
  const updateContactLogMutation = useMutation<
    IAPIResponse<IContactLog>,
    AxiosValidationErrorResponse,
    IPatchObject<ICreateContactLogRequest>
  >(ContactLogsApi.update, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.CONTACT_LOG_LIST],
    onSuccess: () => {
      notify({
        message: t('The contact log was successfully updated'),
        type: INotifierEnum.Success,
      });
      props.onClose();
    },
    onError: (error) => {
      notify({
        message: t(
          error?.response?.data.message ||
            'There was a problem while updating the contact log'
        ),
        type: INotifierEnum.Error,
      });
    },
  });

  const updateContactLog = useCallback(
    (body: IContactLogForm) => {
      updateContactLogMutation.mutate({
        body: {
          ...body,
          assessmentRequestId: props.selectedContactLog.assessmentRequest
            ?.id as string,
          userId: user.id,
          companyId: props.selectedContactLog.assessmentRequest?.scoredCompany
            ?.id as string,
          type: ITypeEnum.MANUAL,
        },
        id: props.selectedContactLog.id,
      });
    },
    [
      user,
      props.selectedContactLog.assessmentRequest,
      updateContactLogMutation,
      props.selectedContactLog.id,
    ]
  );

  const handleOnSubmit = useCallback(
    (body: IContactLogForm) => {
      updateContactLog(body);
    },
    [updateContactLog]
  );
  const defaultValues: IContactLogForm = useMemo(
    () => ({
      ...props.selectedContactLog,
      assessmentRequestId: props.selectedContactLog.assessmentRequest
        ?.id as IContactLogForm['assessmentRequestId'],
      files: props.selectedContactLog.files as IContactLogForm['files'],
      distributorUsers: [],
    }),
    [props.selectedContactLog]
  );

  return (
    <ContactLogForm
      open={props.open}
      onClose={props.onClose}
      onSubmit={handleOnSubmit}
      isLoading={
        updateContactLogMutation.isLoading || updateContactLogMutation.isSuccess
      }
      defaultValues={defaultValues}
      errors={updateContactLogMutation.error?.response?.data}
      title={t('Edit interaction')}
      {...(props.view === 'AR'
        ? {
            view: 'AR',
            assessmentRequest: props.assessmentRequest,
          }
        : {
            view: 'DISTRIBUTOR',
            distributorId: props.distributorId,
          })}
    />
  );
};

export default memo(UpdateContactLogModal);
