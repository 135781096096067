import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getImpersonationToken = async (
  id: string
): Promise<IAPIResponse<string>> => {
  const { data } = await client.instance.get(`/impersonation-token/${id}`);
  return data;
};

const logout = async (token: string): Promise<void> => {
  await client.instance.get(`logout`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export default {
  getImpersonationToken,
  logout,
};
