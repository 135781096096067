import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type {
  ICreateSustainabilityCardPayload,
  ISustainabilityCard,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAnyEnterprise,
  IPaginationResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/enterprises';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getSustainabilityCards = async (
  id: IAnyEnterprise['id']
): Promise<IPaginationResponse<ISustainabilityCard>> => {
  const { data } = await client.instance.get<
    IPaginationResponse<ISustainabilityCard>
  >(`${id}/sustainability-cards`);

  return data;
};

const create = async (
  payload: ICreateSustainabilityCardPayload
): Promise<void> => {
  const { data } = await client.instance.post<void>(
    `${payload.enterpriseId}/sustainability-cards`,
    payload
  );
  return data;
};

const getOneById = async (
  enterpriseId: IAnyEnterprise['id'],
  id: string
): Promise<IAPIResponse<ISustainabilityCard>> => {
  const { data } = await client.instance.get<IAPIResponse<ISustainabilityCard>>(
    `${enterpriseId}/sustainability-cards/${id}`
  );

  return data;
};

const update = async (
  payload: IPatchObject<ICreateSustainabilityCardPayload>
): Promise<void> => {
  const { data } = await client.instance.put<void>(
    `/${payload.body.enterpriseId}/sustainability-cards/${payload.id}`,
    payload.body
  );
  return data;
};

const remove = async ({
  id,
  sustainabilityCardId,
}: {
  id: string;
  sustainabilityCardId: string;
}): Promise<void> => {
  await client.instance.delete<Promise<IAPIResponse>>(
    `/${id}/sustainability-cards/${sustainabilityCardId}`
  );
};

export default {
  getOneById,
  create,
  update,
  remove,
  getSustainabilityCards,
};
