import { DateFormatter } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls } from '@shared/components';

import type { FormDatePickerProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const DateFilterer: React.FCC<
  Omit<FormDatePickerProps, 'pickerType'> & { defaultItem: IFilterDefaultItem }
> = (props) => {
  return (
    <Controls.FormDatePicker
      {...omit(props, ['defaultItem'])}
      inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
      pickerType='date'
    />
  );
};

export default DateFilterer;
