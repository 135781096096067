import { formatISO } from 'date-fns';

const normalizer = (year: unknown): string => {
  const yearAsNumber = Number.parseInt(year as string);
  if (typeof yearAsNumber === 'number' && !Number.isNaN(yearAsNumber)) {
    const startOfYear = new Date(yearAsNumber, 0, 1);
    const endOfYear = new Date(yearAsNumber, 11, 31);
    return JSON.stringify({
      from: formatISO(startOfYear),
      to: formatISO(endOfYear),
    });
  }
  throw new Error('[Year Filterer] : value should be a number');
};

export default normalizer;
