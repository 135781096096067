import { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate, useQueryString } from '@inteliam/foundation/lib/hooks';
import { DateFormatter, I18n } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useMemo, useState } from 'react';

import { QUERY_KEYS, useFetchDocumentsEvaluationByArId } from '@core/queries';

import type { IEvaluationStatusEnum } from '@core/enums';

import { DocumentEvaluationsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IDocumentEvaluation, IEvaluationQuery } from '@core/types';

import {
  Accordion,
  Datatable,
  ExpandMoreIcon,
  Grid,
  TooltippedCell,
  Typography,
} from '@shared/components';

import { DatatableUtils } from '@shared/utils';

import type { IFilterableColumn } from '@shared/types';

import type {
  DatatableState,
  FieldOperator,
} from '@inteliam/foundation/lib/types';

import {
  ReviewDocumentsLibraryActionsCell,
  ReviewDocumentsLibraryInsightsBar,
  ReviewDocumentsLibraryReferencesCell,
} from '.';

/* eslint-disable @inteliam/i18n/raw-text-detected */
interface Props {
  assessmentRequestId: string;
  defaultExpanded?: boolean;
}

const ReviewDocumentsLibraryTable: React.FCC<Props> = ({
  assessmentRequestId,
  defaultExpanded = true,
}) => {
  const { t } = useEssentials();
  const [query, setQuery] = useState<IEvaluationQuery>({
    statuses: { APPROVED: true, PENDING: true, REJECTED: true },
  });

  const [modal, setModal] = useState<string | undefined>(undefined);

  const { data } = useFetchDocumentsEvaluationByArId({
    id: assessmentRequestId,
    total: true,
  });
  const columns = useMemo<IFilterableColumn<IDocumentEvaluation>[]>(
    () => [
      {
        field: 'document.name',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Name'),
        width: 200,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={
                parameters.row.document.qualificationData?.name ||
                parameters.row.document.name
              }
            >
              {parameters.row.document.qualificationData?.name ||
                parameters.row.document.name}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'scopeName',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Company/Scope Level'),
        width: 100,
        renderCell: (parameters) => {
          return parameters.row.document?.qualificationData ? (
            <TooltippedCell
              title={`${
                parameters.row.document.qualificationData?.scopeName || '-'
              }/${
                parameters.row.document.qualificationData?.scopeLevel || '-'
              }`}
            >
              {`${
                parameters.row.document.qualificationData?.scopeName || '-'
              }/${
                parameters.row.document.qualificationData?.scopeLevel || '-'
              }`}
            </TooltippedCell>
          ) : (
            <div />
          );
        },
      },
      {
        field: 'issueDate',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Issue date'),
        width: 100,
        renderCell: (parameters) => {
          const date = parameters.row.document.qualificationData?.issueDate;
          const formattedDate = date
            ? DateFormatter.formatTimestamp(date)
            : '-';
          return (
            <TooltippedCell title={formattedDate}>
              {formattedDate}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'document.languageName',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Language'),
        width: 100,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.document.languageName}>
              {parameters.row?.document.languageName}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'document.sharingVisibility',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Shared with'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={t(parameters.row.document.sharingVisibility as string)}
            >
              {t(parameters.row.document.sharingVisibility as string)}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'document.themes',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Themes'),
        width: 150,
        renderCell: (parameters) => {
          const value = parameters.row.document?.themes
            .map((theme) => I18n.getTranslatedValue(theme.name))
            ?.join(', ');
          return <TooltippedCell title={value}>{value}</TooltippedCell>;
        },
      },
      {
        field: 'referencesProgress',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Evidences'),
        width: 200,
        renderCell: (parameters) => {
          return (
            <ReviewDocumentsLibraryReferencesCell
              referenceProgress={parameters.row.referencesProgress}
            />
          );
        },
      },
      {
        field: 'actions',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Actions'),
        width: 250,
        renderCell: (parameters) => {
          return (
            <ReviewDocumentsLibraryActionsCell
              assessmentRequestId={assessmentRequestId}
              documentEvaluation={parameters.row}
              onOpen={(id) => setModal(id)}
              onClose={() => setModal(undefined)}
              open={!isNil(modal) && modal === parameters.row.id}
            />
          );
        },
      },
    ],
    [t, assessmentRequestId, modal]
  );

  const datatableHandlers = useQueryString<DatatableState<IEvaluationQuery>>(
    `${QUERY_KEYS.DOCUMENT_EVALUATIONS.join('-')}-${assessmentRequestId}-qs`,
    {
      persisted: false, //temp until we figure out a clean way to invalidate LS cache :((((
      initialValue: {
        ...DatatableUtils.getInitialState(),
        criteria: {
          ...DatatableUtils.getInitialState().criteria,
          conditions: [buildConditions(query)],
        },
        visibleColumns: columns.map((column) => column.field),
      },
      syncOnMount: true,
      formatter: (oldValue, initialValue) => ({
        ...initialValue,
        ...(typeof oldValue === 'string' ? JSON.parse(oldValue) : oldValue),
      }),
    }
  );
  useOnUpdate(() => {
    datatableHandlers[1]((prevQueryState) => {
      return {
        ...prevQueryState,
        criteria: {
          ...prevQueryState.criteria,
          conditions: [buildConditions(query)],
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.statuses.APPROVED,
    query.statuses.REJECTED,
    query.statuses.PENDING,
  ]);

  return (
    <Accordion.Container
      defaultExpanded={defaultExpanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Accordion.Summary
        expandIcon={<ExpandMoreIcon />}
        id='docs-library-accordion'
      >
        <Typography>
          {t('Docs library')}({data?.meta.total ?? 0})
        </Typography>
      </Accordion.Summary>
      <Accordion.Details>
        <Grid container spacing={2}>
          <ReviewDocumentsLibraryInsightsBar
            {...{ query, setQuery, assessmentRequestId }}
          />
          <Grid item xs={12}>
            <Datatable.Container
              queryKey={[
                ...QUERY_KEYS.DOCUMENT_EVALUATIONS,
                assessmentRequestId,
              ]}
              columns={columns}
              fetcher={(paginator) =>
                DocumentEvaluationsApi.paginateByAR({
                  id: assessmentRequestId,
                  query: paginator,
                })
              }
              stateExternalHandlers={datatableHandlers}
            >
              <div style={{ height: '100%', width: '100%' }}>
                <Datatable.Table autoHeight checkboxSelection={false} />
              </div>
            </Datatable.Container>
          </Grid>
        </Grid>
      </Accordion.Details>
    </Accordion.Container>
  );
};

export default memo(ReviewDocumentsLibraryTable);

function buildConditions(query: IEvaluationQuery): {
  operator: FieldOperator;
  propertyPath: string;
  value: string;
} {
  return {
    operator: 'in',
    propertyPath: 'status',
    value: Object.keys(query.statuses)
      .filter(
        (status) => query.statuses[status as IEvaluationStatusEnum] === true
      )
      .join(','),
  };
}
