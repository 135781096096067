import { get } from 'lodash-es';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { MaskedInput, ErrorHelpText, FormControl } from '@shared/components';

export type Props = {
  name: string;
  label: string;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  helperText?: string;
  mask: (string | RegExp)[];
};

const FormMaskedInput: React.FCC<Props> = ({
  label,
  name,
  defaultValue,
  helperText,
  mask,
  fullWidth = false,
  disabled = false,
  required = false,
}) => {
  const { t } = useEssentials();
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;
  return (
    <FormControl
      variant='outlined'
      error={!!errorMessage}
      {...{ required, fullWidth, disabled }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <MaskedInput
            {...{ name, label, fullWidth, disabled, mask }}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value.replace(/-/g, ''));
            }}
          />
        )}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

export default FormMaskedInput;
