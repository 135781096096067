import { MathUtils } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { ScoreInput } from '@core/components';

import { useWorkingPage } from '@core/hooks';

import { useEssentials, useWorkingPageContext } from '@core/contexts';
import type { IAnswerEvaluation } from '@core/types';

import { Box, Grid, InfoIcon, TooltippedCell } from '@shared/components';

type Props = {
  answerEvaluation: IAnswerEvaluation;
  themeIndex: number;
  criterionIndex: number;
  questionIndex: number;
};
// This is not actually a cell
const AnswerAnalysisQuestionCell: React.FCC<Props> = ({
  answerEvaluation,
  themeIndex,
  criterionIndex,
  questionIndex,
}) => {
  const { scoreCard } = useWorkingPage();
  const { readOnly } = useWorkingPageContext();
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const criterionPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];
  const questionPartialScore =
    criterionPartialScore.questionsPartialScores[questionIndex];
  const { code, version, label, helpTextContent, kind } =
    answerEvaluation.answer.field;
  const codeWithVersion = `${code} [V${version}]`;
  const { t } = useEssentials();
  const fieldName = `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].questionsPartialScores[${questionIndex}]`;

  return (
    <Box display={'flex'} fontSize={15}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <span>
            {t('{{code}} : {{label}}', {
              code: codeWithVersion,
              label,
            })}
          </span>
        </Grid>
        <Grid item xs={2}>
          <Box display={'flex'}>
            <span>
              <TooltippedCell
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: helpTextContent || '',
                    }}
                  ></div>
                }
                disabled={!helpTextContent}
              >
                <InfoIcon color={helpTextContent ? 'inherit' : 'disabled'} />
              </TooltippedCell>
            </span>
            {/* eslint-disable-next-line @inteliam/i18n/raw-text-detected */}
            <span>[{t(kind.toLowerCase())}]</span>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <ScoreInput
            readOnly={readOnly}
            label={t('Max Score')}
            name={`${fieldName}.scoringMeta.maxScore`}
            additionalUpdate={{
              [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                'QUESTIONS_BASED_SCORING',
            }}
            defaultValue={questionPartialScore.scoringMeta.maxScore}
          />
        </Grid>
        <Grid item xs={2}>
          <Box display={'flex'}>
            <ScoreInput
              label={t('Adjusted Max Score Criteria level(/100)')}
              name={`${fieldName}.scoringMeta.adjustedMaxScore`}
              defaultValue={MathUtils.precisionRound(
                questionPartialScore.scoringMeta.adjustedMaxScore,
                {
                  precision: 2,
                }
              )}
              readOnly={true}
            />
          </Box>
        </Grid>

        <Grid item xs={2}>
          <ScoreInput
            label={t('Question Effective Score(/100)')}
            name={`${fieldName}.scoringMeta.score`}
            readOnly={true}
            defaultValue={MathUtils.precisionRound(
              questionPartialScore.scoringMeta.score,
              {
                precision: 2,
              }
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnswerAnalysisQuestionCell;
