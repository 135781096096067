import { IBORolesEnum } from '@inteliam/foundation/lib/enums';

type IBORolePreset = {
  [key in IBORolesEnum]: {
    value: IBORolesEnum;
    label: string;
    tag: 'ADMIN' | 'SPECIALIST' | 'ANALYST' | 'CSM';
  };
};

export const BO_ROLES: IBORolePreset = {
  [IBORolesEnum.ROLE_TECHNICAL_SUPER_ADMIN]: {
    value: IBORolesEnum.ROLE_TECHNICAL_SUPER_ADMIN,
    label: 'Administrator',
    tag: 'ADMIN',
  },
  [IBORolesEnum.ROLE_SUPER_ADMIN]: {
    value: IBORolesEnum.ROLE_SUPER_ADMIN,
    label: 'Administrator',
    tag: 'ADMIN',
  },
  [IBORolesEnum.ROLE_CONNECT_AS]: {
    value: IBORolesEnum.ROLE_CONNECT_AS,
    label: 'Impersonator',
    tag: 'ADMIN',
  },
  [IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST]: {
    value: IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
    label: 'Senior engagement specialist',
    tag: 'SPECIALIST',
  },
  [IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST]: {
    value: IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
    label: 'Regular engagement specialist',
    tag: 'SPECIALIST',
  },
  [IBORolesEnum.ROLE_JUNIOR_ENGAGEMENT_SPECIALIST]: {
    value: IBORolesEnum.ROLE_JUNIOR_ENGAGEMENT_SPECIALIST,
    label: 'Junior engagement specialist',
    tag: 'SPECIALIST',
  },
  [IBORolesEnum.ROLE_JUNIOR_ANALYST]: {
    value: IBORolesEnum.ROLE_JUNIOR_ANALYST,
    label: 'Junior analyst',
    tag: 'ANALYST',
  },
  [IBORolesEnum.ROLE_REGULAR_ANALYST]: {
    value: IBORolesEnum.ROLE_REGULAR_ANALYST,
    label: 'Regular analyst',
    tag: 'ANALYST',
  },
  [IBORolesEnum.ROLE_SENIOR_ANALYST]: {
    value: IBORolesEnum.ROLE_SENIOR_ANALYST,
    label: 'Senior analyst',
    tag: 'ANALYST',
  },
  [IBORolesEnum.ROLE_CSM]: {
    value: IBORolesEnum.ROLE_CSM,
    label: 'CSM',
    tag: 'CSM',
  },
  [IBORolesEnum.ROLE_SENIOR_CSM]: {
    value: IBORolesEnum.ROLE_SENIOR_CSM,
    label: 'CSM senior',
    tag: 'CSM',
  },
};
