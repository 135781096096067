import { add, endOfDay, startOfDay } from 'date-fns';

import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Controls, Grid } from '@shared/components';

import type { FormDatePickerProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

import { Component as DefaultDateRange } from '../date-range-filterer';

type PresetType = '' | 'today' | 'within-2-days' | 'within-3-days';
type PresetItemType = {
  label: string;
  value: PresetType;
  calculator: { from: Date; to: Date };
};
const DEFAULT_PRESETS: PresetItemType[] = [
  {
    label: 'Today',
    value: 'today',
    calculator: { from: startOfDay(new Date()), to: endOfDay(new Date()) },
  },
  {
    label: 'Within 2 days',
    value: 'within-2-days',
    calculator: {
      from: startOfDay(new Date()),
      to: endOfDay(add(new Date(), { days: 2 })),
    },
  },
  {
    label: 'Within 3 days',
    value: 'within-3-days',
    calculator: {
      from: startOfDay(new Date()),
      to: endOfDay(add(new Date(), { days: 3 })),
    },
  },
];

const DateRangeWithPresetsFilterer: React.FCC<
  Omit<FormDatePickerProps, 'pickerType'> & { defaultItem: IFilterDefaultItem }
> = ({ name, ...rest }) => {
  const methods = useFormContext();
  const presetFieldName = `${name}-preset`;
  const watchedPreset = useWatch({
    control: methods.control,
    name: presetFieldName,
    defaultValue: '',
  }) as PresetType;

  useEffect(() => {
    if (watchedPreset) {
      const calculator = DEFAULT_PRESETS.find(
        (item) => item.value === watchedPreset
      )?.calculator;
      if (calculator) {
        methods.setValue(`${name}.from`, calculator.from);
        methods.setValue(`${name}.to`, calculator.to);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPreset]);

  return (
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item>
        <Controls.FormSelect
          label='Presets'
          name={presetFieldName}
          defaultValue={watchedPreset}
          options={DEFAULT_PRESETS}
        />
      </Grid>
      <Grid item>
        <DefaultDateRange name={name} {...rest} />
      </Grid>
    </Grid>
  );
};

export default DateRangeWithPresetsFilterer;
