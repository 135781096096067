import {
  NativeSelect as MUINativeSelect,
  NativeSelectProps,
} from '@mui/material';

import * as React from 'react';

type Props = NativeSelectProps & { id: string };

const NativeSelect: React.FCC<Props> = ({ id, inputProps, ...rest }) => {
  return (
    <MUINativeSelect
      {...rest}
      id={id}
      // @ts-expect-error material ui doesn't support this
      inputProps={{ ...inputProps, 'data-cy': id }}
    />
  );
};

export default NativeSelect;
