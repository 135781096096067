/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import type { IResource } from '@inteliam/foundation/lib/types';

import DropzoneAreaBase, {
  FileObjectShape,
  DropzoneAreaBaseProps,
} from './dropzone-area-base';
import { createFileFromUrl, readFile } from './helpers';

const splitDropzoneAreaProps = (props: DropzoneAreaProps) => {
  const {
    clearOnUnmount = true,
    initialFiles = [],
    onChange,
    onDelete,
    ...dropzoneAreaProps
  } = props;
  return [
    { clearOnUnmount, initialFiles, onChange, onDelete },
    dropzoneAreaProps,
  ];
};

export interface DropzoneAreaProps extends DropzoneAreaBaseProps {
  /** Clear uploaded files when component is unmounted. */
  clearOnUnmount?: boolean;
  /** List containing File objects or URL strings.<br/>
   * **Note:** Please take care of CORS.
   */
  initialFiles?: {
    file: File;
    resource: IResource;
  }[];
  /** Maximum number of files that can be loaded into the dropzone. */
  filesLimit?: number;
  /**
   * Fired when the files inside dropzone change.
   *
   * @param {File[]} loadedFiles All the files currently loaded into the dropzone.
   */
  onChange?(...args: unknown[]): unknown;
  /**
   * Fired when a file is deleted from the previews panel.
   *
   */
  onDelete?(deletedFile: FileObjectShape, deletedFileIndex: number): unknown;
}

type State = {
  fileObjects: FileObjectShape[];
};

/**
 * This components creates an uncontrolled Material-UI Dropzone, with previews and snackbar notifications.
 *
 * It supports all props of `DropzoneAreaBase` but keeps the files state internally.
 *
 * **Note** To listen to file changes use `onChange` event handler and notice that `onDelete` returns a `File` instance instead of `FileObject`.
 */
class DropzoneArea extends React.PureComponent<DropzoneAreaProps, State> {
  state: State = {
    fileObjects: [],
  };

  componentDidMount(): void {
    this.loadInitialFiles().catch(() => {});
  }

  componentWillUnmount(): void {
    const { clearOnUnmount = true } = this.props;

    if (clearOnUnmount) {
      this.setState(
        {
          fileObjects: [],
        },
        this.notifyFileChange
      );
    }
  }

  notifyFileChange = (): void => {
    const { onChange } = this.props;
    const { fileObjects } = this.state;

    if (onChange) {
      onChange(fileObjects.map((fileObject) => fileObject.file));
    }
  };

  loadInitialFiles = async (): Promise<void> => {
    const { initialFiles = [] } = this.props;
    try {
      const fileObjs = await Promise.all(
        initialFiles.map(async (initialFile) => {
          const file =
            typeof initialFile === 'string'
              ? await createFileFromUrl(initialFile)
              : initialFile.file;
          const data = await readFile(file);

          return {
            file,
            data,
            resource: initialFile.resource,
          };
        })
      );

      this.setState((prevState) => ({
        fileObjects: [...prevState.fileObjects, ...fileObjs],
      }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  addFiles = (newFileObjects: FileObjectShape[]): void => {
    const { filesLimit = 3 } = this.props;
    // Update component state
    this.setState((state) => {
      // Handle a single file
      if (filesLimit <= 1) {
        return {
          fileObjects: [newFileObjects[0]],
        };
      }

      // Handle multiple files
      return {
        fileObjects: [...state.fileObjects, ...newFileObjects],
      };
    }, this.notifyFileChange);
  };

  deleteFile = (
    removedFileObject: FileObjectShape,
    removedFileObjectIndex: number
  ): void => {
    // event.stopPropagation();

    const { onDelete } = this.props;
    const { fileObjects } = this.state;

    // Calculate remaining fileObjects array
    const remainingFileObjs = fileObjects.filter((_, index) => {
      return index !== removedFileObjectIndex;
    });

    // Notify removed file
    if (onDelete) {
      onDelete(removedFileObject, removedFileObjectIndex);
    }

    // Update local state
    this.setState(
      {
        fileObjects: remainingFileObjs,
      },
      this.notifyFileChange
    );
  };

  render(): JSX.Element {
    const [, dropzoneAreaProps] = splitDropzoneAreaProps(this.props);
    const { fileObjects } = this.state;
    return (
      <DropzoneAreaBase
        {...dropzoneAreaProps}
        fileObjects={fileObjects}
        onAdd={this.addFiles}
        onDelete={this.deleteFile}
        style={{ height: '50px' }}
      />
    );
  }
}

export default DropzoneArea;
