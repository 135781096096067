import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import { useCompanyQualificationEnums } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import { Card, Divider } from '@shared/components';

import Configurations from './configurations';

const QuestionConfiguration: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext<IQuestionForm>();
  const questionType = useWatch({
    control: methods.control,
    name: 'kind',
  });
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  const { defaultValues, readOnly } = useQuestionFormContext();

  const Component = questionType ? Configurations[questionType] : undefined;
  return (
    <Card.CContainer styled>
      <Card.Header title={t('Configuration')} />
      <Divider />
      <Card.Body>
        {questionType && Component && companyQualificationEnums && (
          <Component {...{ defaultValues, readOnly }} />
        )}
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(QuestionConfiguration);
