import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { useManageArReviews } from '@core/queries';

import { DndUtils } from '@core/utils';

import type { IAssessmentReviewReorderPayload } from '@core/types';

import {
  Typography,
  Box,
  DndProvider,
  DragIndicatorSharpIcon,
} from '@shared/components';

import type { QueryOptimisticUpdater } from '@shared/types';

import type {
  IAnyAssessmentReview,
  IIdentifiedObject,
} from '@inteliam/foundation/lib/types';

type Items = IAnyAssessmentReview[] | undefined;

type Props = {
  reviews: Items;
  readOnly?: boolean;
  themeId: string;
};

const ValidationDraggables: React.FCC<Props> = ({
  reviews,
  readOnly = false,
  themeId,
}) => {
  const { id: assessmentRequestId } = useParams<IIdentifiedObject>();

  const { paginateByTheme, reorder } = useManageArReviews({
    assessmentRequestId,
    themeId,
  });

  const handleMove = React.useCallback(
    (a: number, b: number) => {
      handleOnReorder(a, b, reviews, paginateByTheme.updater, reorder.run);
    },
    [reviews, paginateByTheme.updater, reorder.run]
  );

  return (
    <DndProvider onMove={readOnly ? () => {} : handleMove}>
      {({ getItemStyle }) => (
        <>
          {reviews?.map((item, index) => {
            return (
              <Draggable
                key={item.id}
                draggableId={item.id || 'uniqueid'}
                index={index}
                isDragDisabled={readOnly}
              >
                {(draggableProvided, draggableSnapshot) => {
                  return (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={getItemStyle(
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style
                      )}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        border='2px solid black'
                        borderRadius='4px'
                        width='100%'
                        padding='1rem'
                      >
                        {!readOnly && (
                          <span
                            {...(reviews.length > 0 &&
                              draggableProvided.dragHandleProps)}
                          >
                            <DragIndicatorSharpIcon opacity='0.3' />
                          </span>
                        )}

                        <Typography>
                          {item.localizedConcatenatedValue}
                        </Typography>
                      </Box>
                    </div>
                  );
                }}
              </Draggable>
            );
          })}
        </>
      )}
    </DndProvider>
  );
};

export default React.memo(ValidationDraggables);

function handleOnReorder(
  from: number,
  to: number,
  items: Items,
  updater: QueryOptimisticUpdater<Array<IAnyAssessmentReview>>,
  runReorder: (
    payload: Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>
  ) => void
) {
  if (from !== to && items) {
    const ordered = DndUtils.reorder(items as IAnyAssessmentReview[], from, to);

    // Optimistic update
    updater((data) => {
      if (data?.data) {
        const orderedIds =
          ordered.map((item: IAnyAssessmentReview) => item.id) ?? [];
        return {
          ...data,
          data: [
            ...data.data.filter((item) => !orderedIds.includes(item.id)),
            ...ordered,
          ],
        };
      }
      return data;
    });
    // Update server data
    // Next MR
    runReorder({
      assessmentReviewId: items[from].id,
      order: to,
    });
    // To be continued
  }
}
