import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, SnackbarContent } from '@mui/material';

import * as React from 'react';

import { clsx } from 'clsx';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) => ({
  successAlert: {
    backgroundColor: theme.palette.success.main,
  },
  errorAlert: {
    backgroundColor: theme.palette.error.main,
  },
  infoAlert: {
    backgroundColor: theme.palette.info.main,
  },
  warningAlert: {
    backgroundColor: theme.palette.warning.main,
  },
  message: {
    'display': 'flex',
    'alignItems': 'center',
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
  },
  closeButton: {},
}));

type Props = {
  classes?: Record<string, string>;
  className?: string;
  message: React.ReactNode;
  onClose: () => void;
  variant: 'success' | 'warning' | 'error' | 'info';
};
const SnackbarContentWrapper: React.FCC<Props> = (props) => {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[`${variant}Alert`], className)}
      aria-describedby='client-snackbar'
      message={
        <span id='client-snackbar' className={classes.message}>
          <Icon className={classes.icon} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
};

export default SnackbarContentWrapper;
