import * as React from 'react';

import * as XLSX from 'xlsx';

import { BOErrorTracking } from '@shared/utils';

type SheetData = Array<{ sheet: string; data: Array<Array<string>> }>;

const Viewer: React.FCC<{ src: string }> = ({ src }) => {
  const [pages, setPages] = React.useState<SheetData>([]);
  const [selectedPage, setSelectedPage] = React.useState<number | undefined>(
    undefined
  );

  function sheetToJson(workbook: XLSX.WorkBook) {
    const result: SheetData = [];
    workbook.SheetNames.forEach(function (sheetName) {
      const csv = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
      if (csv.length > 0) {
        result.push({
          sheet: sheetName,
          data: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
          }),
        });
      }
    });

    setPages(result);
    setSelectedPage(0);
  }

  React.useEffect(() => {
    fetch(src)
      .then((response) => response.arrayBuffer())
      .then((data) => {
        const wb = XLSX.read(data, { type: 'array' });
        sheetToJson(wb);
      })
      .catch((error) => {
        BOErrorTracking.report(error, 'SpreadSheetViewer');
      });
  }, [src]);
  const sheetData =
    selectedPage === undefined ? undefined : pages[selectedPage];

  return (
    <div>
      {pages.map((page, index) => (
        <button key={page.sheet} onClick={() => setSelectedPage(index)}>
          {page.sheet}
        </button>
      ))}
      {sheetData && (
        <table>
          <tbody>
            {sheetData.data.map((row, index) => {
              return (
                <tr key={index}>
                  {row.map((cell, index) => {
                    return <td key={index}>{cell}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Viewer;
