import * as React from 'react';
import PhoneInput from 'react-phone-input-2';

import '@core/styles/phone-input.scss';

import type { FormPhoneNumberFieldProps } from '@shared/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneInputComponent = (PhoneInput as any).default || PhoneInput;

// todo add more masks
const DEFAULT_MASKS = {
  ca: '(...) ...-....',
  us: '(...) ...-....',
  fr: '(.). .. .. .. ..',
  tn: '(..) ... ...',
};
const PhoneNumberInput: React.FCC<FormPhoneNumberFieldProps> = ({
  value,
  onChange,
  fullWidth,
  label,
  disabled,
  id,
  name,
}) => {
  return (
    <PhoneInputComponent
      data-cy={name}
      country={'fr'}
      value={value}
      specialLabel={label}
      masks={DEFAULT_MASKS}
      inputProps={{ id }}
      onChange={onChange}
      inputStyle={{ width: fullWidth ? '100%' : undefined }}
      disabled={disabled}
    />
  );
};

export default PhoneNumberInput;
