import * as React from 'react';

import { clsx } from 'clsx';

import type { IEvaluationProgress } from '@core/types';

import { Box, BookmarkIcon, BookmarkBorderIcon } from '@shared/components';

import { makeStyles } from '@shared/utils';

import { EvaluationInsights } from '../../common';

const useStyles = makeStyles((theme) => ({
  root: {
    'cursor': 'pointer',
    '& svg.pending': {
      color: 'gray',
    },
    '& svg.approved': {
      color: theme.palette.success.main,
    },
    '& svg.rejected': {
      color: theme.palette.error.main,
    },
  },
}));

type Props = {
  referenceProgress: IEvaluationProgress;
  onOpen: () => void;
};
const AnswersAnalysisReferencesCell: React.FCC<Props> = ({
  referenceProgress,
  onOpen,
  children,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent={'center'}>
        <Box className={classes.root} display='flex' onClick={() => onOpen()}>
          {referenceProgress.pending > 0 && referenceProgress.approved === 0 ? (
            <BookmarkBorderIcon />
          ) : (
            <BookmarkIcon
              className={clsx({
                approved: referenceProgress.approved > 0,
                rejected:
                  referenceProgress.rejected > 0 &&
                  referenceProgress.approved === 0 &&
                  referenceProgress.pending === 0,
              })}
            />
          )}
        </Box>
        <EvaluationInsights evaluationProgress={referenceProgress} />
      </Box>
      {children}
    </>
  );
};

export default AnswersAnalysisReferencesCell;
