import { IBORolesEnum, IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';
import type { GridCellParams, GridRowModel } from '@mui/x-data-grid';

import React, { useMemo, useState } from 'react';

import {
  ReviewsReferenceStatusSwitch,
  EvaluationStatusFilter,
} from '@core/components';

import { QUERY_KEYS } from '@core/queries';

import { UsersUtils } from '@core/utils';

import type { IEvaluationStatusEnum } from '@core/enums';

import { ReferenceEvaluationsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type {
  IDocumentEvaluation,
  IEvaluationQuery,
  IReferenceEvaluation,
} from '@core/types';

import {
  Noop,
  Datatable,
  TooltippedCell,
  Typography,
  Grid,
  Box,
  BookmarkBorderIcon,
} from '@shared/components';

import { DatatableUtils } from '@shared/utils';

import type { IFilterableColumn } from '@shared/types';

import type {
  DatatableState,
  FieldOperator,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

/* eslint-disable @inteliam/i18n/raw-text-detected */
const ReviewReferenceEvaluationTable: React.FCC<{
  documentEvaluation: IDocumentEvaluation;
  assessmentRequestId: string;
  selectedReferenceId?: string;
}> = ({ documentEvaluation, assessmentRequestId, selectedReferenceId }) => {
  const { t, user } = useEssentials();
  const [paginatorData, setPaginatorData] = useState<
    IPaginationResponse<GridRowModel> | undefined
  >(undefined);
  const [query, setQuery] = useState<IEvaluationQuery>({
    statuses: { APPROVED: true, PENDING: true, REJECTED: true },
  });
  const columns = useMemo<IFilterableColumn<IReferenceEvaluation>[]>(
    () => [
      {
        field: 'question',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Question'),
        width: 90,

        renderCell: (parameters) => {
          return (
            <TooltippedCell title={`${parameters.row.question.title}`}>
              {parameters.row.question.code} [{parameters.row.question.version}]
            </TooltippedCell>
          );
        },
        cellClassName: (GridCellParams) =>
          getCellBackground(GridCellParams, selectedReferenceId),
      },
      {
        field: 'optionLabel',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Option'),
        flex: 1,
        renderCell: (parameters) => {
          const answer =
            typeof parameters.row.answerValue === 'string' ||
            typeof parameters.row.answerValue === 'number'
              ? parameters.row.answerValue
              : JSON.stringify(parameters.row.answerValue);
          return <TooltippedCell title={answer}>{answer}</TooltippedCell>;
        },
        cellClassName: (GridCellParams) =>
          getCellBackground(GridCellParams, selectedReferenceId),
      },
      {
        field: 'optionMeta',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: 'Ref',
        width: 20,
        renderCell: (parameters) => {
          const page = parameters.row.reference.page || 1;
          const description = parameters.row.reference.description || 'toto';
          if (page || description)
            return (
              <TooltippedCell
                title={t('Page : {{page}} | Description : {{description}}', {
                  page,
                  description,
                })}
              >
                <BookmarkBorderIcon />
              </TooltippedCell>
            );
          return <Noop />;
        },
        cellClassName: (GridCellParams) =>
          getCellBackground(GridCellParams, selectedReferenceId),
      },
      {
        field: 'actions',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Actions'),
        width: 130,
        renderCell: (parameters) => {
          if (UsersUtils.isGranted(IBORolesEnum.ROLE_JUNIOR_ANALYST, user)) {
            return (
              <Box p={1} display='flex'>
                <ReviewsReferenceStatusSwitch
                  documentEvaluation={documentEvaluation}
                  referenceEvaluation={parameters.row}
                />
              </Box>
            );
          }
          return <Noop />;
        },
        cellClassName: (GridCellParams) =>
          getCellBackground(GridCellParams, selectedReferenceId),
      },
    ],
    [t, selectedReferenceId, user, documentEvaluation]
  );
  const datatableHandlers = useState<DatatableState>({
    ...DatatableUtils.getInitialState(),
    criteria: {
      ...DatatableUtils.getInitialState().criteria,
      conditions: [buildConditions(query)],
    },
    selected: [],
    sort: {
      _id: 'desc',
    },
    visibleColumns: columns.map((column) => column.field),
  });
  const [, setDatatableState] = datatableHandlers;

  const queryKey = [
    ...QUERY_KEYS.REFERENCE_EVALUATION_TABLE,
    assessmentRequestId,
    documentEvaluation.document.id,
  ];

  useOnUpdate(() => {
    setDatatableState((prevQueryState) => {
      return {
        ...prevQueryState,
        criteria: {
          ...prevQueryState.criteria,
          conditions: [buildConditions(query)],
        },
      };
    });
  }, [setDatatableState, query]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          {t('All evidences in this Doc')}(
          {Helpers.nonNilFallback(paginatorData?.meta?.total, 0)})
        </Typography>

        {query && (
          <EvaluationStatusFilter
            {...{ query, setQuery }}
            progress={documentEvaluation.referencesProgress}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Datatable.Container
          queryKey={queryKey}
          columns={columns}
          fetcher={(query) =>
            ReferenceEvaluationsApi.paginateByDocumentEvaluation(
              assessmentRequestId,
              documentEvaluation.document.id,
              query
            )
          }
          stateExternalHandlers={datatableHandlers}
          onFetchSuccess={setPaginatorData}
        >
          <div style={{ height: '100%', width: '100%' }}>
            <Datatable.Table autoHeight checkboxSelection={false} />
          </div>
        </Datatable.Container>
      </Grid>
    </Grid>
  );
};

export { ReviewReferenceEvaluationTable };

function getCellBackground(
  GridCellParams: GridCellParams,
  selectedReferenceId: string | undefined
): string {
  return GridCellParams.row.id === selectedReferenceId ? 'bg-green' : '';
}

function buildConditions(query: IEvaluationQuery): {
  operator: FieldOperator;
  propertyPath: string;
  value: string;
} {
  return {
    operator: 'in',
    propertyPath: 'status',
    value: Object.keys(query.statuses)
      .filter(
        (status) => query.statuses[status as IEvaluationStatusEnum] === true
      )
      .join(','),
  };
}
