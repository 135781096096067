import { IBORolesEnum } from '@inteliam/foundation/lib/enums';

import React, { useState } from 'react';

import { UsersUtils } from '@core/utils';
import { isAnalyst, isEngagementSpecialist } from '@core/utils/users';

import { useNamedContext } from '@shared/contexts';

import type {
  DashboardType,
  IAR,
  IBOUser,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

import { DashboardContext } from './contexts';

export interface IDashboardContext {
  dashboard: DashboardType;
  checkReadOnly: (ar: IAR, user: IBOUser | JwtBOUser) => boolean;
}
const checkReadOnly = (assessmentRequest: IAR, user: IBOUser | JwtBOUser) => {
  if (UsersUtils.isGranted(IBORolesEnum.ROLE_SUPER_ADMIN, user)) return false;
  if (
    assessmentRequest.currentStatusMeta.dashboard === 'ANALYST' &&
    isAnalyst(user)
  )
    return false;
  if (
    assessmentRequest.currentStatusMeta.dashboard === 'ANALYST' &&
    !isAnalyst(user)
  )
    return UsersUtils.isGranted(
      IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
      user
    );

  if (
    assessmentRequest.currentStatusMeta.dashboard === 'ENGAGEMENT' &&
    isEngagementSpecialist(user)
  ) {
    return false;
  }

  if (
    assessmentRequest.currentStatusMeta.dashboard === 'ENGAGEMENT' &&
    !isEngagementSpecialist(user)
  ) {
    return UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user);
  }

  return true;
};

const DashboardProvider: React.FCC<{ dashboard: DashboardType }> = ({
  dashboard,
  children,
}) => {
  const [dashboardContext] = useState<IDashboardContext>({
    dashboard,
    checkReadOnly,
  });

  return (
    <DashboardContext.Provider value={dashboardContext}>
      {children}
    </DashboardContext.Provider>
  );
};

function useDashboard(): IDashboardContext {
  return useNamedContext(DashboardContext);
}

export { useDashboard, DashboardProvider };
