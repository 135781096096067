import {
  IQuestionKindEnum,
  ISsqQuestionKindEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnMount, useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useQuestionEnums } from '@core/queries';

import { QuestionsUtils } from '@core/utils';
import { QuestionI18nHelpers } from '@core/utils/i18n';

import { useEssentials } from '@core/contexts';
import type {
  IMultipleChoiceQuestionForm,
  ISingleChoiceQuestionForm,
  QuestionOptionField,
} from '@core/types';

import {
  Controls,
  IconButton,
  FormGroup,
  Box,
  FormFieldLoadingIndicator,
  ErrorHelpText,
  AddCircleIcon,
  Grid,
  RemoveCircleIcon,
} from '@shared/components';

import type {
  IPartialQuestion,
  ObligatorinessValue,
} from '@inteliam/foundation/lib/types';

interface Props {
  index: number;
  field: Partial<QuestionOptionField>;
  readOnly?: boolean;
  defaultValues?: IPartialQuestion;
}

const OptionItem: React.FCC<Props> = ({ index, field, readOnly = false }) => {
  const fieldName = Helpers.arrayDotNotation('options', index);
  const { t } = useEssentials();
  const methods = useFormContext<
    ISingleChoiceQuestionForm | IMultipleChoiceQuestionForm
  >();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const textBoxObligatoriness = useWatch({
    control: methods.control,
    name: `${fieldName}.textBox`,
  }) as ObligatorinessValue;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const dropDownObligatoriness = useWatch({
    control: methods.control,
    name: `${fieldName}.dropDown`,
  }) as ObligatorinessValue;
  const enabledHelperText = useWatch({
    control: methods.control,
    name: `${fieldName}.helpText`,
  });

  const watchedKind = useWatch({
    control: methods.control,
    name: 'kind',
  }) as IQuestionKindEnum | ISsqQuestionKindEnum;

  useOnUpdate(() => {
    if (textBoxObligatoriness === 'no')
      methods.setValue(`${fieldName}.textBoxLabel.defaultValue`, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textBoxObligatoriness]);

  useOnUpdate(() => {
    if (dropDownObligatoriness === 'no')
      methods.setValue(`${fieldName}.dropDownLabel.defaultValue`, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownObligatoriness]);

  return (
    <Box flexGrow={1}>
      <div hidden>
        <Controls.FormInput
          name={`${fieldName}.id`}
          type='text'
          required
          defaultValue={field.id}
        />
        <Controls.FormInput
          name={`${fieldName}.order`}
          type='number'
          required
          defaultValue={index}
        />
      </div>
      <Grid container spacing={2}>
        {/* Base info */}
        <Grid item sm={6} xs={12}>
          <FormGroup>
            <Box mx={1} my={1}>
              <Controls.FormCheckbox
                style={{ width: 50 }}
                name={`${fieldName}.resetOption`}
                label={t('Reset')}
                required
                defaultChecked={field.resetOption || false}
                labelPlacement='end'
                disabled={
                  readOnly ||
                  watchedKind !== IQuestionKindEnum.MultipleChoiceField
                }
              />
            </Box>

            <Controls.FormInput
              name={`${fieldName}.label.defaultValue`}
              label={t('Option {{index}}', { index: index + 1 })}
              required
              multiline
              rows={5}
              defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
                field.label || ''
              )}
            />
          </FormGroup>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormGroup>
            <Box mx={1} my={1}>
              <Controls.FormCheckbox
                name={`${fieldName}.helpText`}
                label={t('Help text')}
                defaultChecked={field.helpText}
              />
            </Box>
            <Controls.FormRichTextEditor
              name={`${fieldName}.helpTextContent.defaultValue`}
              defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
                field.helpTextContent || ''
              )}
              readOnly={!enabledHelperText || false}
            />
          </FormGroup>
        </Grid>
        {/* TextBox */}
        <TextBoxFields
          {...{ textBoxObligatoriness, fieldName, readOnly, field }}
        />
        {/* Dropdown */}
        <DropDownFields
          {...{ dropDownObligatoriness, fieldName, readOnly, field }}
        />
        {/* Evidence */}
        <Grid item sm={1} xs={12}>
          {t('Evidence')}
        </Grid>
        <Grid item sm={2} xs={12}>
          <FormGroup>
            <Controls.FormObligatorinessField
              name={`${fieldName}.reference`}
              label={t('Evidence')}
              defaultValue={field.reference || 'no'}
              required
              disabled={
                readOnly ||
                (
                  [
                    ISsqQuestionKindEnum.SingleChoiceField,
                    ISsqQuestionKindEnum.MultipleChoiceField,
                    ISsqQuestionKindEnum.SingleFreeFormField,
                  ] as Array<ISsqQuestionKindEnum | IQuestionKindEnum>
                ).includes(watchedKind)
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(OptionItem);

// Extracted to reduce re-renders
const TextBoxFields: React.FCC<{
  fieldName: string;
  readOnly: boolean;
  textBoxObligatoriness: ObligatorinessValue;
  field: Partial<QuestionOptionField>;
}> = memo(({ fieldName, readOnly, textBoxObligatoriness, field }) => {
  const { questionEnums } = useQuestionEnums();
  const { t } = useEssentials();
  return (
    <>
      <Grid item sm={1} xs={12}>
        {t('Textbox')}
      </Grid>
      <Grid item sm={2} xs={12}>
        <FormGroup>
          <Controls.FormObligatorinessField
            name={`${fieldName}.textBox`}
            label={t('TB')}
            defaultValue={field.textBox || 'no'}
            required
            disabled={readOnly}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={5} xs={12}>
        <FormGroup>
          <Controls.FormInput
            name={`${fieldName}.textBoxLabel.defaultValue`}
            label={t('TB label')}
            // TODO TBD
            defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
              field.textBoxLabel || ''
            )}
            readOnly={textBoxObligatoriness === 'no'}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={4} xs={12}>
        <FormGroup>
          {questionEnums ? (
            <Controls.FormSelect
              name={`${fieldName}.textBoxType`}
              label={t('TB type')}
              defaultValue={field.textBoxType || 'text'}
              options={questionEnums.textBoxTypes}
              required
              readOnly={textBoxObligatoriness === 'no'}
            />
          ) : (
            <FormFieldLoadingIndicator />
          )}
        </FormGroup>
      </Grid>
    </>
  );
});

const DropDownFields: React.FCC<{
  fieldName: `options.${number}`;
  readOnly: boolean;
  dropDownObligatoriness: ObligatorinessValue;
  field: Partial<QuestionOptionField>;
}> = memo(({ fieldName, readOnly, dropDownObligatoriness, field }) => {
  const { t } = useEssentials();
  const methods = useFormContext<ISingleChoiceQuestionForm>();
  const {
    fields: choices,
    append: addDDChoice,
    remove: removeDDChoice,
  } = useFieldArray<
    ISingleChoiceQuestionForm,
    `options.${number}.dropDownChoices`,
    'rhfId'
  >({
    control: methods.control,
    name: `${fieldName}.dropDownChoices`,
    keyName: 'rhfId',
  });
  const dropDownChoicesErrorMessage = get(
    methods.formState.errors,
    `${fieldName}.dropDownChoices.message`
  );

  useOnUpdate(() => {
    if (dropDownObligatoriness === 'no')
      choices.forEach((_choice, index) =>
        methods.setValue(
          `${fieldName}.${Helpers.arrayDotNotation(
            'dropDownChoices',
            index
          )}.label.defaultValue`,
          ''
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownObligatoriness, choices]);

  useOnMount(() => {
    if (choices.length === 0) {
      addDDChoice([
        { ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
        { ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
      ]);
    }
  });
  return (
    <>
      <Grid item sm={1} xs={12}>
        {t('Dropdown')}
      </Grid>
      <Grid item sm={2} xs={12}>
        <FormGroup>
          <Controls.FormObligatorinessField
            name={`${fieldName}.dropDown`}
            label={t('DD')}
            defaultValue={field.dropDown || 'no'}
            required
            disabled={readOnly}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={5} xs={12}>
        <FormGroup>
          <Controls.FormInput
            name={`${fieldName}.dropDownLabel.defaultValue`}
            label={t('DD label')}
            defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
              field.dropDownLabel
            )}
            readOnly={dropDownObligatoriness === 'no'}
          />
        </FormGroup>
      </Grid>
      <Grid item sm={4} xs={12}>
        <div>
          <FormGroup>
            <Controls.FormBoolRadioGroup
              name={`${fieldName}.dropDownMultiAnswers`}
              label={t('Choices')}
              options={[
                {
                  label: t('Multi answers'),
                  value: true,
                },
                {
                  label: t('Single answer'),
                  value: false,
                },
              ]}
              required
              row
              defaultValue={field.dropDownMultiAnswers || false}
              readOnly={readOnly || dropDownObligatoriness === 'no'}
            />
          </FormGroup>
        </div>
        <div>
          {choices.map((choice, index) => {
            return (
              <Grid container spacing={1} key={choice.rhfId}>
                <div hidden>
                  <Controls.FormInput
                    name={`${fieldName}.${Helpers.arrayDotNotation(
                      'dropDownChoices',
                      index
                    )}.id`}
                    type='text'
                    required
                    defaultValue={choice.id}
                  />
                </div>
                <Grid item md={8} xs={12}>
                  <FormGroup style={{ marginTop: 10 }}>
                    <Controls.FormInput
                      key={`${fieldName}.${Helpers.arrayDotNotation(
                        'dropDownChoices',
                        index
                      )}.id`}
                      name={`${fieldName}.${Helpers.arrayDotNotation(
                        'dropDownChoices',
                        index
                      )}.label.defaultValue`}
                      label={t('Choice {{index}}', {
                        index: index + 1,
                      })}
                      required
                      defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
                        choice.label
                      )}
                      readOnly={dropDownObligatoriness === 'no'}
                    />
                  </FormGroup>
                </Grid>
                <Grid>
                  <Box display={'flex'} alignItems={'center'} height={'100%'}>
                    {index > 1 && (
                      <IconButton
                        id={`remove-choice-${
                          QuestionI18nHelpers.getTranslatedFormValue(
                            choice.label
                          ) || index
                        }`}
                        disabled={readOnly}
                        color='secondary'
                        onClick={() => removeDDChoice(index)}
                        size='large'
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            );
          })}
          <Grid item md={1}>
            <IconButton
              id={`${fieldName}-add-choice`}
              disabled={readOnly || dropDownObligatoriness === 'no'}
              color='primary'
              onClick={() => {
                addDDChoice({
                  ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM,
                  id: Helpers.uuid(),
                });
              }}
              size='large'
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
          {dropDownChoicesErrorMessage && (
            <ErrorHelpText>{t(dropDownChoicesErrorMessage)}</ErrorHelpText>
          )}
        </div>
      </Grid>
    </>
  );
});
