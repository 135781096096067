import {
  IReviewKindEnum,
  IReviewStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { omit } from 'lodash-es';

import type { ReviewFormData, ReviewPayload } from '@core/types';

import type { IPartialReview, IReview } from '@inteliam/foundation/lib/types';

const DEFAULT_REVIEW: Omit<IReview, 'id'> = {
  code: '',
  kind: IReviewKindEnum.Strength,
  description: { defaultValue: '', translations: [] },
  theme: { id: '' } as IReview['theme'],
  criterion: { id: '' } as IReview['criterion'],
  status: IReviewStatusEnum.STATUS_DRAFT,
};

export const normalizer = {
  initializeForm: (defaultValues?: ReviewFormData): ReviewFormData => {
    return {
      ...DEFAULT_REVIEW,
      ...defaultValues,
    };
  },
  formDataToPayload: (formData: ReviewFormData) => {
    const payload = omit(formData, ['locale', 'default']);
    return {
      review: {
        ...payload,
        theme: {
          id: payload.theme?.id as string,
        } as IPartialReview['theme'],
        criterion: {
          id: payload.criterion?.id as string,
        } as IPartialReview['criterion'],
      },
      questionReviewLinkConfigurations:
        payload?.questionReviewLinkConfigurations,
    } as ReviewPayload;
  },
};
