import { IUserStatusEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Button } from '@shared/components';

import type { IUserUpdateStatusInput } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  open: boolean;
  onConfirm: (data: IUserUpdateStatusInput) => void;
  onClose: () => void;
}

const ActivateUserModal: React.FCC<Props> = ({ onClose, onConfirm, open }) => {
  const { t } = useEssentials();

  const handleConfirmation = () => {
    onConfirm({
      status: IUserStatusEnum.STATUS_ENABLED,
      sendEmail: true,
    });
  };

  return (
    <BaseDialog.Dialog keepMounted={false} open={open}>
      <BaseDialog.Title id='Activate user' onClose={onClose}>
        {t('Activate user')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        {t("An email will be sent as it's a first activation ?")}
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-update-user-status'
          onClick={onClose}
          type='button'
          color='primary'
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-update-user-status'
          onClick={handleConfirmation}
          color='primary'
        >
          {t('Validate')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(ActivateUserModal);
