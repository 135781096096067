import React, { memo } from 'react';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IARNextActionForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import NextActionForm from './next-action-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

const UpdateNextActionModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { t, notify } = useEssentials();

  const { updateNextAction } = useManageAssessmentRequest({
    updateNextAction: {
      options: {
        keysToInvalidateOnSuccess: [
          QUERY_KEYS.DASHBOARD['ENGAGEMENT'], // Next Action is not available on AoD for now.
          QUERY_KEYS.TODAYS_TO_DO_LIST,
        ],
      },
    },
  });

  const handleOnSubmit = (data: IARNextActionForm) => {
    return updateNextAction.mutateAsync(
      {
        id: assessmentRequest.id,
        description: data.description,
        assignee: data.assignee,
        date: data.date,
      },
      {
        onSuccess: () => {
          notify({
            message: t('Successfully updated.'),
            type: INotifierEnum.Success,
          });
          onClose();
        },
      }
    );
  };

  return (
    <NextActionForm
      defaultValues={assessmentRequest.nextAction as IARNextActionForm}
      open={open}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      errors={updateNextAction.error?.response?.data}
      isLoading={updateNextAction.isLoading}
    />
  );
};

export default memo(UpdateNextActionModal);
