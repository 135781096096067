import { isString } from '@inteliam/foundation/lib/guards';
import { DateFormatter } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls, Grid } from '@shared/components';

import type { FormDatePickerProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const DateRangeFilterer: React.FCC<
  Omit<FormDatePickerProps, 'pickerType'> & { defaultItem: IFilterDefaultItem }
> = ({ name, ...rest }) => {
  const defaults =
    isString(rest.defaultValue) && rest.defaultValue
      ? JSON.parse(rest.defaultValue)
      : { from: undefined, to: undefined };
  return (
    <Grid container spacing={1} wrap='nowrap'>
      <Grid item>
        <Controls.FormDatePicker
          {...omit(rest, ['defaultItem'])}
          defaultValue={defaults.from}
          name={`${name}.from`}
          label='from'
          inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
          pickerType='date'
        />
      </Grid>
      <Grid item>
        <Controls.FormDatePicker
          {...rest}
          defaultValue={defaults.to}
          name={`${name}.to`}
          label='to'
          inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
          pickerType='date'
        />
      </Grid>
    </Grid>
  );
};

export default DateRangeFilterer;
