import React, { memo, useState } from 'react';

import { CreateDistributorUserForm } from '@core/components/users';

import { useEssentials } from '@core/contexts';

import { Button, Grid } from '@shared/components';

import { BaseDialog } from '../';

interface Props {
  distributorId: string;
  open: boolean;
  onClose: () => void;
}
const CreateDistributorUserModal: React.FCC<Props> = ({
  distributorId,
  open,
  onClose,
}) => {
  const { t } = useEssentials();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Add a user')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <CreateDistributorUserForm
          distributorId={distributorId}
          mode='CREATE'
          inModal={true}
          setIsLoading={setIsLoading}
          onClose={onClose}
        />
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-create-user'
          onClick={onClose}
          type='button'
          color='primary'
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-create-user'
          type='submit'
          color='primary'
          form='create-user'
          isLoading={isLoading}
        >
          {t('Confirm')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateDistributorUserModal);
