import { yupResolver } from '@hookform/resolvers/yup';
import { TypeCaster } from '@inteliam/foundation/lib/utils';
import {
  Add as AddIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  Restore as RestoreIcon,
} from '@mui/icons-material';

import * as React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  Badge,
  Button,
  Controls,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Tooltip,
} from '@shared/components';
import * as Popover from '@shared/components/popover';

import { DatatableUtils, makeStyles } from '@shared/utils';

import type { IFilterableOption } from '@shared/types';

import type {
  FieldOperator,
  FilterChangerFn,
  IFilter,
  IFilterCondition,
} from '@inteliam/foundation/lib/types';

import FilterRow from './filter-row';
import { filterSchema } from './schema';

const useStyles = makeStyles(() => ({
  formControl: {
    width: 250,
    marginRight: 10,
  },
  paper: {
    padding: 5,
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  action: {
    padding: 10,
  },
}));

interface FilterProps {
  columns: IFilterableOption[];
  filter: IFilter;
  onChangeFilter: FilterChangerFn;
}
const DEFAULT_FILTER: IFilter['conditions'] = [
  {
    column: '',
    operator: '',
    value: '',
    propertyPath: '',
  },
];
const Filter: React.FCC<FilterProps> = ({
  columns,
  filter,
  onChangeFilter,
}) => {
  const methods = useForm<IFilter>({
    defaultValues: filter,
    resolver: yupResolver(filterSchema),
  });
  const classes = useStyles();
  const { t } = useEssentials();
  const { fields, append, remove } = useFieldArray<IFilter, 'conditions'>({
    control: methods.control,
    name: 'conditions',
  });

  const onRemove = (index?: number | number[] | undefined) => {
    if (fields.length <= 1) {
      onChangeFilter({
        conditions: DEFAULT_FILTER,
        operator: 'or',
      });
      methods.setValue('conditions', DEFAULT_FILTER);
    } else {
      remove(index);
    }
  };

  const watchedConditions =
    useWatch({
      control: methods.control,
      name: 'conditions',
    }) || [];
  return (
    <Popover.Container>
      {({ close }) => (
        <>
          <Popover.Trigger component={Tooltip} title={t('Filter')}>
            <span>
              <IconButton id='filter-table' size='large'>
                <Badge
                  badgeContent={
                    watchedConditions.filter(
                      (condition: IFilterCondition) =>
                        condition.operator &&
                        condition.column &&
                        condition.value
                    ).length
                  }
                  color='primary'
                >
                  <FilterListIcon />
                </Badge>
              </IconButton>
            </span>
          </Popover.Trigger>
          <Popover.Content keepMounted>
            <Paper elevation={3} className={classes.paper}>
              <Controls.Form
                submitHandler={(values: IFilter) => {
                  onChangeFilter(
                    DatatableUtils.normalizeCriteria(values, columns)
                  );
                  close();
                }}
                methods={methods}
              >
                {fields.map((filterRow, index) => {
                  return (
                    <FilterRow
                      key={filterRow.id}
                      index={index}
                      defaultItem={filter.conditions[index]}
                      watchedItem={watchedConditions[index]}
                      columns={columns}
                      onRemove={onRemove}
                    />
                  );
                })}
                <Button
                  id='add-filter-row'
                  color='ternary'
                  startIcon={<AddIcon />}
                  onClick={() =>
                    append({
                      column: '',
                      operator: TypeCaster.toString(
                        DatatableUtils.COLUMN_OPERATORS[0].value
                      ) as FieldOperator,
                      value: '',
                    })
                  }
                >
                  {t('Add filter')}
                </Button>
                <Divider className={classes.divider} />
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className={classes.action}
                >
                  <Button
                    id='close-filter'
                    color='secondary'
                    startIcon={<CloseIcon />}
                    onClick={close}
                  >
                    {t('Close')}
                  </Button>
                  <FormControl className={classes.formControl}>
                    <Controls.FormSelect
                      label={t('Global operator')}
                      options={DatatableUtils.GLOBAL_OPERATORS}
                      name='operator'
                      required
                    />
                  </FormControl>
                  <Button
                    id='reset-filter'
                    color='secondary'
                    startIcon={<RestoreIcon />}
                    onClick={() => {
                      close();
                      onChangeFilter({
                        conditions: [
                          DatatableUtils.createCondition('', '', ''),
                        ],
                        operator: 'or',
                      });
                      methods.setValue('conditions', DEFAULT_FILTER);
                    }}
                    type='button'
                  >
                    {t('Reset')}
                  </Button>
                  <Button
                    id='submit-filter'
                    color='primary'
                    startIcon={<CheckIcon />}
                    type='submit'
                  >
                    {t('Apply filter')}
                  </Button>
                </Grid>
              </Controls.Form>
            </Paper>
          </Popover.Content>
        </>
      )}
    </Popover.Container>
  );
};

export default Filter;
