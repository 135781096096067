import { ApiFactory } from '@inteliam/foundation/lib/api';
import type { ICampaignTypeEnum } from '@inteliam/foundation/lib/enums';
import {
  assertIsTyped,
  assertIsTypedArray,
  isICampaign,
  isICampaignType,
} from '@inteliam/foundation/lib/guards';
import { QueryString } from '@inteliam/foundation/lib/utils';

import type { ICampaignFormData } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  ICampaign,
  ICampaignType,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type CampaignActions = 'paginate' | 'create' | 'update' | 'patch';
export const FULL_STATS = 'full';
const baseCampaignApi = ApiFactory.generate<
  ICampaign,
  JwtBOUser,
  CampaignActions,
  ICampaignFormData,
  ICampaignFormData
>({
  baseRoute: '/campaigns',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isICampaign,
});

const paginateTypes = async (
  paginator: IPaginationQuery
): Promise<IPaginationResponse<ICampaignType>> => {
  const { data } = await baseCampaignApi.client.instance.get(
    `/get-types?${QueryString.serialize(paginator)}`
  );

  assertIsTypedArray(data.data, isICampaignType);

  return data;
};

const getCampaignTypeByType = async (
  type: ICampaignTypeEnum
): Promise<IAPIResponse<ICampaignType>> => {
  const { data } = await baseCampaignApi.client.instance.get(
    `/get-types/${type}`
  );

  assertIsTyped(data.data, isICampaignType);

  return data;
};

export const canUpdateOwner = async (id: string): Promise<IAPIResponse> => {
  const { data } = await baseCampaignApi.client.instance.get(
    `/${id}/can-update-owner`
  );
  return data;
};

export default {
  ...baseCampaignApi.actions,
  paginateTypes,
  getCampaignTypeByType,
  canUpdateOwner,
};
