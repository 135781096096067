import {
  IDistributorRolesEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';
import { InputLabel } from '@mui/material';
import { isNil } from 'lodash-es';

import React, { memo, useEffect, useState } from 'react';
import { FieldPath, useWatch } from 'react-hook-form';

import { BaseUserForm } from '@core/components/users';

import {
  useManageAssessmentRequest,
  useManageDistributors,
  useUpdateFOUserStatus,
} from '@core/queries';

import { UsersUtils } from '@core/utils';

import { DISTRIBUTOR_ROLES } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type {
  DistributorUserFormMode,
  IDistributorUserForm,
} from '@core/types';

import { Controls, FormGroup, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { FormMethods } from '@shared/types';

import type {
  EMAIL_PREFERENCES,
  IAR,
  IPartialFODistributorUser,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import BusinessRoleFields from './business-role-fields';

const ROLES = Object.values(DISTRIBUTOR_ROLES);

interface Props {
  defaultValues?: IPartialFODistributorUser;
  mode: DistributorUserFormMode;
  errors?: ValidationErrorResponse;
  distributorId?: string;
  methods: FormMethods<IDistributorUserForm>;
}

function checkError(
  {
    name,
    message,
    methods,
  }: {
    name: FieldPath<IDistributorUserForm>;
    message: string;
    methods: FormMethods<IDistributorUserForm>;
  },
  clear = false
) {
  if (clear) {
    methods.clearErrors(name);
  } else {
    methods.setError(name, {
      type: 'manual',
      message: message,
    });
  }
}
const BaseDistributorUserForm: React.FCC<Props> = ({
  defaultValues = {},
  distributorId,
  errors,
  mode,
  methods,
}) => {
  const baseName = 'user' as const;
  const { t } = useEssentials();
  const [canUpdateStatus, setCanUpdateStatus] = useState<undefined | boolean>(
    undefined
  );
  const { getAllByCompany } = useManageAssessmentRequest({
    getAllByCompany: {
      args: { id: distributorId, excludeQueuedAndReuse: true },
    },
  });
  const { getOneById } = useManageDistributors({
    getOneById: { args: { id: distributorId } },
  });
  const { canRun } = useUpdateFOUserStatus({
    companyId: distributorId,
    userId: defaultValues.id,
    queryKey: ['CHECK_ONLY'],
  });
  const watchedPrimaryContact = useWatch({
    control: methods.control,
    name: 'primaryContact',
  });
  const watchedSurveyContact = useWatch({
    control: methods.control,
    name: 'questionnaireContact',
  });
  const watchedQuestionnaireContactFor = useWatch({
    control: methods.control,
    name: 'questionnaireContactFor',
  });
  const roleFieldName = `${baseName}.roles` as const;
  const statusFieldName = `${baseName}.status` as const;
  const watchedRole = useWatch({
    control: methods.control,
    name: `${roleFieldName}.0.`,
  }) as IDistributorRolesEnum;

  // TODO simple fallback for now, move to enum once we have more
  const emailPreferences = defaultValues?.emailPreference || {
    SHARING_REQUEST_REMINDER_EMAILS: false,
  };
  useEffect(() => {
    const onFailure = () => {
      methods.setValue(statusFieldName, IUserStatusEnum.STATUS_DISABLED);
      checkError({
        methods,
        name: statusFieldName,
        message: t('You cannot enable user now'),
      });
      setCanUpdateStatus(false);
    };
    canRun()
      .then(() => setCanUpdateStatus(true))
      .catch(onFailure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorId, canRun, t]);

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  useOnUpdate(() => {
    if (mode !== 'PRIMARY') {
      const shouldShowWarning = getShouldShowPrimaryContactWarning(
        Boolean(watchedPrimaryContact),
        getOneById.data?.data?.primaryContact
      );
      checkError(
        {
          methods,
          name: 'primaryContact',
          message: t(
            'The primary will no longer be associated to the user "{{fullName}}"',
            {
              fullName: UsersUtils.formatShortName(
                getOneById.data?.data?.primaryContact?.contactInfo
              ),
            }
          ),
        },
        !shouldShowWarning
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedPrimaryContact, mode, getOneById.data?.data?.primaryContact]);

  useOnUpdate(() => {
    if (
      watchedSurveyContact === false &&
      watchedQuestionnaireContactFor?.length
    ) {
      methods.setValue('questionnaireContactFor', []);
    } else {
      watchedQuestionnaireContactFor?.forEach((arId) => {
        const ar = getAllByCompany.data?.data.find((_ar) => _ar.id === arId);
        const shouldShowWarning = getShouldShowSurveyContactWarning(
          Boolean(watchedSurveyContact),
          defaultValues,
          ar
        );
        checkError(
          {
            methods,
            name: 'questionnaireContactFor',
            message: t(
              'The questionnaire contact will no longer be associated to the user "{{fullName}}"',
              {
                fullName: UsersUtils.formatShortName(
                  ar?.questionnaireContact?.contactInfo
                ),
              }
            ),
          },
          !shouldShowWarning
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedQuestionnaireContactFor, watchedSurveyContact, mode]);
  return (
    <Grid container spacing={3}>
      <BaseUserForm
        allowedRoles={ROLES}
        visibility={{ status: false }}
        readOnly={{
          role:
            mode === 'PRIMARY' ||
            mode === 'SURVEY' ||
            defaultValues.primaryContact ||
            defaultValues.questionnaireContact,
        }}
        {...{ defaultValues, baseName }}
      />
      <Grid item md={6} xs={12}>
        {!isNil(canUpdateStatus) && (
          <Controls.FormRadioGroup
            label={t('Status')}
            className='mr-1'
            name={`${baseName}.status`}
            defaultValue={
              canUpdateStatus
                ? defaultValues?.status
                : IUserStatusEnum.STATUS_DISABLED
            }
            disabled={!canUpdateStatus && distributorId !== undefined}
            options={UsersUtils.getUserStatusesEnum()}
            row
          />
        )}
      </Grid>
      {mode !== 'SURVEY' &&
        mode !== 'PRIMARY' &&
        getAllByCompany.isSuccess &&
        watchedRole === IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD && (
          <BusinessRoleFields
            {...{
              mode,
              defaultValues,
              ars: getAllByCompany.data.data,
            }}
          />
        )}
      <Grid item xs={12}>
        <FormGroup>
          <InputLabel style={{ marginBottom: 10 }}>
            {t('Notification Preferences')}
          </InputLabel>
          {(Object.keys(emailPreferences) as Array<EMAIL_PREFERENCES>).map(
            (key) => {
              return (
                <Controls.FormCheckbox
                  key={key}
                  label={t(key)}
                  name={`emailPreference.${key}`}
                  value={emailPreferences[key]}
                  helperText=''
                />
              );
            }
          )}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default memo(BaseDistributorUserForm);

function getShouldShowPrimaryContactWarning(
  watchedPrimaryContact: boolean,
  primaryContact?: IPartialFODistributorUser
) {
  return primaryContact && watchedPrimaryContact === true;
}

function getShouldShowSurveyContactWarning(
  watchedSurveyContact: boolean,
  defaultValues: IPartialFODistributorUser,
  ar?: IAR
) {
  return (
    watchedSurveyContact === true &&
    ar?.questionnaireContact &&
    ar?.questionnaireContact.id !== defaultValues?.id
  );
}
