import { usePersistentState } from '@inteliam/foundation/lib/hooks';

import React, { useEffect } from 'react';

import { useEssentials } from '@core/contexts';

import {
  Datatable,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@shared/components';

type CampaignFilterType = 'ALL' | 'ONLY_ACTIVE';
const DashboardAdditionalFilters: React.FCC = () => {
  const { setter } = Datatable.useDataTableContext();
  const { t } = useEssentials();
  const [campaignFilter, setCampaignFilter] =
    usePersistentState<CampaignFilterType>(
      '_inteliam_csm_dashboard_campaign_filter',
      'ALL'
    );

  useEffect(() => {
    setter((prevQueryState) => {
      return {
        ...prevQueryState,
        criteria: {
          ...prevQueryState.criteria,
          options: { campaign: campaignFilter },
        },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignFilter]);

  return (
    <FormControl component='fieldset'>
      <RadioGroup
        value={campaignFilter}
        onChange={({ target }) =>
          setCampaignFilter(target.value as CampaignFilterType)
        }
        row
      >
        <FormControlLabel
          id='all'
          value='ALL'
          control={<Radio />}
          label={t('Display all campaigns')}
          labelPlacement='end'
        />
        <FormControlLabel
          id='only-active'
          value='ONLY_ACTIVE'
          control={<Radio />}
          label={t('Display active campaigns')}
          labelPlacement='end'
        />
      </RadioGroup>
    </FormControl>
  );
};

export default DashboardAdditionalFilters;
