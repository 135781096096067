import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Transformers } from '@inteliam/foundation/lib/utils';
import { uniqBy, isNil } from 'lodash-es';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useFetchCampaigns } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { Card, Controls, Grid, FormGroup, Divider } from '@shared/components';

import type { ICampaign, IAnyEnterprise } from '@inteliam/foundation/lib/types';

const EnterpriseCampaign: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext();
  const { campaigns, isSettled } = useFetchCampaigns({
    conditions: [
      {
        column: 'open',
        operator: 'eq',
        value: 'true',
      },
      {
        column: 'typeName',
        operator: 'eq',
        value: 'STANDARD',
      },
    ],
  });

  const manufacturers: IAnyEnterprise[] = campaigns
    .filter((campaign: ICampaign) => !isNil(campaign.owner))
    .map(
      (campaign: ICampaign): IAnyEnterprise => campaign.owner as IAnyEnterprise
    );

  const watchedEnterprise = useWatch({
    control: methods.control,
    name: 'manufacturer',
  }) as string | undefined;

  const watchedCampaign = useWatch({
    control: methods.control,
    name: 'campaign.id',
  }) as string | undefined;

  const selectedCampaign = campaigns.find(
    (campaign) => campaign.id === watchedCampaign
  );

  useOnUpdate(() => {
    if (isNil(selectedCampaign?.owner) === false) {
      methods.setValue('manufacturer', selectedCampaign?.owner?.id);
    }
  }, [methods, selectedCampaign]);

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Enterprise & Campaign')} subheader={t('')} />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            {isSettled && (
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='manufacturer'
                  label={t('Enterprise')}
                  options={Transformers.mapIntoOptionItem(
                    uniqBy(manufacturers, 'id').filter((manufacturer) =>
                      !selectedCampaign || isNil(selectedCampaign.owner)
                        ? true
                        : selectedCampaign.owner?.id === manufacturer.id
                    ),
                    'name',
                    'id'
                  )}
                />
              </FormGroup>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {isSettled && (
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='campaign.id'
                  label={t('Campaign')}
                  options={campaigns
                    .filter((campaign) =>
                      watchedEnterprise
                        ? campaign.owner?.id === watchedEnterprise
                        : true
                    )
                    .map((campaign) => {
                      return {
                        label: `${campaign.name} [${
                          campaign.owner?.name || 'Inteliam'
                        }]`,
                        value: campaign.id,
                      };
                    })}
                />
              </FormGroup>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {isSettled && (
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  multiple={true}
                  name='businessDevelopers'
                  label={t('Favorite/Business developers')}
                  options={(
                    manufacturers
                      .filter((manufacturer) =>
                        watchedEnterprise
                          ? manufacturer.id === watchedEnterprise
                          : true
                      )
                      .at(0)?.users || []
                  ).map((user) => {
                    return UsersUtils.formatUser(user);
                  })}
                />
              </FormGroup>
            )}
          </Grid>
          <Grid item md={12} xs={12}>
            <Controls.FormInput
              fullWidth
              label={t('Enterprise Specific Name')}
              name='manufacturerSpecificName'
              required
            />
          </Grid>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(EnterpriseCampaign);
