import type { TooltipProps } from '@mui/material';
import { isNil } from 'lodash-es';

import React, { useState } from 'react';

import { useEssentials } from '@core/contexts';

import { Tooltip, Box } from '@shared/components';

interface Props extends Pick<TooltipProps, 'disableInteractive'> {
  disabled?: boolean;
  title?: React.ReactNode;
  controlled?: boolean;
  textOverflowHidden?: boolean;
  children?: React.ReactNode;
}

const TooltippedCell: React.FCC<Props> = ({
  title,
  children,
  disabled = false,
  controlled = true,
  textOverflowHidden = false,
  ...rest
}) => {
  const { t } = useEssentials();
  const [show, setShow] = useState(false);

  if (isNil(title)) {
    return <span>{t('N/A')}</span>;
  }
  return (
    <Tooltip
      {...rest}
      title={<div style={{ whiteSpace: 'pre-wrap' }}>{title}</div>}
      {...(controlled
        ? {
            disableHoverListener: true,
            disableFocusListener: true,
            open: !disabled && show,
            onMouseEnter: () => !disabled && setShow(true),
            onMouseLeave: () => setShow(false),
          }
        : {})}
    >
      <Box
        style={
          textOverflowHidden
            ? {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }
            : {}
        }
        width='100%'
        maxHeight='100%'
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default TooltippedCell;
