import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { AssessmentRequestsApi } from '@core/api';
import type { IARSurvey } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchGlobalProgressById = ({
  id,
  options,
}: {
  id: string;
  options?: InteliamUseQueryOptions<
    IAPIResponse<IARSurvey | undefined>,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IAPIResponse<IARSurvey | undefined>,
  BaseAxiosErrorResponse
> & { globalProgress?: IARSurvey } => {
  const query = useQuery<
    IAPIResponse<IARSurvey | undefined>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.SURVEY_PROGRESS, id],
    () => AssessmentRequestsApi.getGlobalProgressByAR(id),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
      cacheTime: 1000 * 20,
      ...options,
    }
  );
  return {
    ...query,

    globalProgress: query.data?.data,
  };
};

export default useFetchGlobalProgressById;
