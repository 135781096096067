import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';
import type {
  IDetailedDocumentEvaluation,
  IDocumentEvaluation,
} from '@core/types';

import type { BaseAxiosErrorResponse } from '@inteliam/foundation/lib/types';

const useFetchDocumentEvaluationById = ({
  id,
  options,
}: {
  id: string;
  options?: InteliamUseQueryOptions<
    IDetailedDocumentEvaluation,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IDetailedDocumentEvaluation | undefined,
  BaseAxiosErrorResponse
> & {
  documentEvaluation?: IDocumentEvaluation;
} => {
  const query = useQuery<IDetailedDocumentEvaluation, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.DOCUMENT_EVALUATIONS, id],
    () => DocumentEvaluationsApi.detailed(id),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
      cacheTime: 1000 * 20,
      ...options,
    }
  );
  return {
    ...query,
    documentEvaluation: query.data?.data,
  };
};

export default useFetchDocumentEvaluationById;
