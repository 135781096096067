import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { AssessmentHighlightsApi } from '@core/api';
import type { IOrderHighlightPayload } from '@core/types';

import type { QueryOptimisticUpdater } from '@shared/types';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  IAPIResponse,
  IAssessmentHighlight,
  Id,
} from '@inteliam/foundation/lib/types';

type UpdatePayload = {
  ids: Array<Id>;
};
type Args = { assessmentRequestId?: string };
type Options = {
  getByAr?: InteliamUseQueryOptions<
    IAPIResponse<Array<IAssessmentHighlight>>,
    BaseAxiosErrorResponse
  >;
};

type IQueryManagementResult = {
  toggle: {
    run: (args: {
      assessmentRequestId: Id;
      analysisHighlights: UpdatePayload;
    }) => void;
    mutation: InteliamUseMutationResult<
      IAPIResponse,
      AxiosValidationErrorResponse,
      { assessmentRequestId: Id; analysisHighlights: UpdatePayload },
      unknown
    >;
  };
  reorder: {
    run: (query: IOrderHighlightPayload) => void;
    mutation: InteliamUseMutationResult<
      void,
      BaseAxiosErrorResponse,
      IOrderHighlightPayload
    >;
  };
  updater: QueryOptimisticUpdater<Array<IAssessmentHighlight>>;
  getByAR: {
    query: InteliamUseQueryResult<
      IAPIResponse<Array<IAssessmentHighlight>>,
      BaseAxiosErrorResponse
    >;
    assessmentHighlights: Array<IAssessmentHighlight>;
  };
};

const useManageAssessmentHighlights = (
  args: Args = {},
  options?: Options
): IQueryManagementResult => {
  const queryClient = useQueryClient();
  const toggleMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    { assessmentRequestId: Id; analysisHighlights: UpdatePayload }
  >((args) => AssessmentHighlightsApi.toggleSelection(args), {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
  });

  const runToggle = useCallback(
    (data: { assessmentRequestId: Id; analysisHighlights: UpdatePayload }) => {
      toggleMutation.mutate(data);
    },
    [toggleMutation]
  );

  const reorderMutation = useMutation<
    void,
    BaseAxiosErrorResponse,
    IOrderHighlightPayload
  >(AssessmentHighlightsApi.reorder, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
  });
  const runReorder = useCallback(
    (query: IOrderHighlightPayload) => {
      reorderMutation.mutate(query);
    },
    [reorderMutation]
  );

  const queryKey = QUERY_KEYS.ASSESSMENT_HIGHLIGHTS_LIST(
    args?.assessmentRequestId || '-'
  );
  const getByARQuery = useQuery<
    IAPIResponse<Array<IAssessmentHighlight>>,
    BaseAxiosErrorResponse
  >(
    queryKey,
    () => {
      if (args?.assessmentRequestId)
        return AssessmentHighlightsApi.getHighlights(args?.assessmentRequestId);
      return Promise.reject();
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData: undefined,
      ...options?.getByAr,
      enabled: Boolean(args?.assessmentRequestId),
    }
  );

  const updater: QueryOptimisticUpdater<Array<IAssessmentHighlight>> = (
    callback
  ) => {
    queryClient.setQueryData<
      IAPIResponse<Array<IAssessmentHighlight>> | undefined
    >(queryKey, callback);
  };

  return {
    toggle: {
      run: runToggle,
      mutation: toggleMutation,
    },
    reorder: {
      run: runReorder,
      mutation: reorderMutation,
    },
    getByAR: {
      query: {
        ...getByARQuery,
      },
      assessmentHighlights: getByARQuery.data?.data || [],
    },
    updater,
  };
};

export default useManageAssessmentHighlights;
