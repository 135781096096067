import React, { memo } from 'react';

import { EnterpriseUserForm } from '@core/components';

import { useManageUsers, useUpdateFOUser } from '@core/queries';

import { EnterpriseUsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { CenteredSpinner, Grid } from '@shared/components';

import type { IFOSupplierUser } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  userId: string;
  manufacturerId: string;
  open: boolean;
  onClose: () => void;
}
const UpdateEnterpriseUserModal: React.FCC<Props> = ({
  userId,
  manufacturerId,
  open,
  onClose,
}) => {
  const { t, notify, INotifierEnum } = useEssentials();

  const { getOneById } = useManageUsers<IFOSupplierUser>({
    getOneById: {
      args: { id: userId },
      options: {
        enabled: true,
        onError(error) {
          notify({
            message: t(error.response?.data.message || 'Something went wrong'),
            type: INotifierEnum.Error,
          });
          onClose();
        },
      },
    },
  });

  const { run, mutation } = useUpdateFOUser({
    companyId: manufacturerId,
    successMessage: 'The contact was successfully updated',
    onSuccess: () => onClose(),
  });
  if (!getOneById.isSettled) {
    return <CenteredSpinner />;
  }
  if (!getOneById.data || !getOneById.isSuccess) {
    return <span>{t('User not found')}</span>;
  }
  const user = getOneById.data.data;
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Edit a contact')}
        </Grid>
      </BaseDialog.Title>
      <EnterpriseUserForm
        onCancel={onClose}
        onSubmit={(data) =>
          run(
            EnterpriseUsersUtils.normalize.basePayloadToUpdatePayload(
              data,
              user.id
            )
          )
        }
        isLoading={mutation.isLoading}
        defaultValues={user}
        errors={mutation.error?.response?.data}
        manufacturerId={manufacturerId}
      />
    </BaseDialog.Dialog>
  );
};

export default memo(UpdateEnterpriseUserModal);
