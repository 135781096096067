import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterpriseDiscountsApi } from '@core/api';
import type { HasActiveDiscountResponse } from '@core/types';

import type { BaseAxiosErrorResponse } from '@inteliam/foundation/lib/types';

const useFetchHasActiveDiscount = ({
  id,
  options,
}: {
  id?: string;
  options?: InteliamUseQueryOptions<
    HasActiveDiscountResponse,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  HasActiveDiscountResponse,
  BaseAxiosErrorResponse
> => {
  return useQuery<HasActiveDiscountResponse, BaseAxiosErrorResponse>(
    id ? [...QUERY_KEYS.HAS_ACTIVE_DISCOUNTS(id), id] : [],
    () =>
      id ? EnterpriseDiscountsApi.hasActiveDiscount(id) : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      initialData: undefined,
      cacheTime: 1000 * 20,
      ...options,
    }
  );
};

export default useFetchHasActiveDiscount;
