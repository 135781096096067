import { Yup } from '@inteliam/foundation/lib/utils';

export const initiateCarbonAssessmentFormSchema = Yup.object().shape({
  manufacturer: Yup.string().nullable(),
  campaign: Yup.object().shape({
    id: Yup.string().nullable(),
  }),

  contacts: Yup.array()
    .of(Yup.string())
    .min(1, 'Please select at least one option'),
});
