import type { InputBaseProps } from '@mui/material';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

import * as React from 'react';

import { useEssentials } from '@core/contexts';

import { TextField } from '@shared/components';

export const EditableTextInput = (props: GridRenderEditCellParams<string>) => {
  const { id, field, value } = props;
  const { theme } = useEssentials();
  const [valueState, setValueState] = React.useState(value);
  const apiRef = useGridApiContext();

  const handleChange = React.useCallback<
    NonNullable<InputBaseProps['onChange']>
  >(
    async (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      await apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1000,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <TextField
        id={`${field}-id`}
        multiline
        fullWidth
        value={valueState}
        onChange={handleChange}
        autoFocus
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          zIndex: 1000,
        }}
      />
    </div>
  );
};
