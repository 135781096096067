import type { InteliamUseQueryResult } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterpriseRegionsApi } from '@core/api';
import type { IRegion } from '@core/types';

import type {
  IAPIResponse,
  IOptionItem,
  BaseAxiosErrorResponse,
  IAnyEnterprise,
} from '@inteliam/foundation/lib/types';

interface Args {
  id: IAnyEnterprise['id'];
}

const useFetchEnterpriseRegions = (
  args: Args
): InteliamUseQueryResult<
  IAPIResponse<Array<IRegion>>,
  BaseAxiosErrorResponse
> & {
  regions: Array<IOptionItem>;
} => {
  const query = useQuery<IAPIResponse<Array<IRegion>>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.MANUFACTURERS_LIST, args.id, 'region'],
    () => EnterpriseRegionsApi.getRegions(args.id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const regions = Helpers.ensureValueAsArray(query.data?.data).map(
    (region) => ({
      value: region.id,
      label: region.name,
    })
  );
  return {
    ...query,
    regions,
  };
};

export default useFetchEnterpriseRegions;
