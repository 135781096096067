import { omit } from 'lodash-es';

import * as React from 'react';

import { BoUsersField } from '@core/components/common';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const BoUsersFilterer: React.FCC<
  FormSelectProps & {
    defaultItem: IFilterDefaultItem;
  }
> = (props) => {
  return <BoUsersField {...omit(props, ['defaultItem'])} />;
};

export default BoUsersFilterer;
