/* eslint-disable sonarjs/cognitive-complexity */
import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { QuestionI18nHelpers } from '@core/utils/i18n';

import { useEssentials } from '@core/contexts';
import type { ISingleChoiceOptionForm } from '@core/types';

import { Controls, Grid, RichTextEditor, TextField } from '@shared/components';

interface Props {
  option: ISingleChoiceOptionForm;
  locale: ILanguagesEnum | 'defaultValue';
  index: number;
}

const LanguageOptionItem: React.FCC<Props> = ({ index, locale, option }) => {
  const { t } = useEssentials();
  const readonly = locale === 'defaultValue';

  const optionName = Helpers.arrayDotNotation('options', index);

  return (
    <React.Fragment>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <TextField
            {...{
              label: t('Option {{index}}', { index: index + 1 }),
              fullWidth: true,
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.label,
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormInput
            {...{
              fullWidth: true,
              label: t('Option {{index}}', { index: index + 1 }),
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.label,
                  readonly ? undefined : locale
                ) || '',
              name: `${optionName}.label.${locale}`,
              required: true,
            }}
          />
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <RichTextEditor
            {...{
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.helpTextContent,
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormRichTextEditor
            {...{
              fullWidth: true,
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.helpTextContent,
                  readonly ? undefined : locale
                ) || '',
              name: `${optionName}.helpTextContent.${locale}`,
              required: true,
            }}
          />
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <TextField
            {...{
              label: t('TB label', { index: index + 1 }),
              fullWidth: true,
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.textBoxLabel,
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormInput
            {...{
              fullWidth: true,
              label: t('TB label', { index: index + 1 }),
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.textBoxLabel,
                  readonly ? undefined : locale
                ) || '',
              name: `${optionName}.textBoxLabel.${locale}`,
              required: true,
              disabled: option?.textBox === 'no',
            }}
          />
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <TextField
            {...{
              label: t('DD label', { index: index + 1 }),
              fullWidth: true,
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.dropDownLabel,
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormInput
            {...{
              fullWidth: true,
              label: t('DD label', { index: index + 1 }),
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  option?.dropDownLabel,
                  readonly ? undefined : locale
                ) || '',
              name: `${optionName}.dropDownLabel.${locale}`,
              required: true,
              disabled: option?.dropDown === 'no',
            }}
          />
        )}
      </Grid>
      {Array.isArray(option?.dropDownChoices) &&
        option?.dropDownChoices.map((choice, ddIndex) => {
          return (
            <React.Fragment key={choice.id}>
              <Grid item md={12} xs={12}>
                {readonly ? (
                  <TextField
                    {...{
                      label: t('DD choice {{index}}', { index: ddIndex + 1 }),
                      fullWidth: true,
                      value:
                        QuestionI18nHelpers.getTranslatedFormValue(
                          option?.dropDownChoices?.[ddIndex]?.label,
                          readonly ? undefined : locale
                        ) || '',
                      readOnly: true,
                      disabled: true,
                    }}
                  />
                ) : (
                  <Controls.FormInput
                    {...{
                      fullWidth: true,
                      label: t('DD choice {{index}}', { index: ddIndex + 1 }),
                      defaultValue:
                        QuestionI18nHelpers.getTranslatedFormValue(
                          option?.dropDownChoices?.[ddIndex]?.label,
                          readonly ? undefined : locale
                        ) || '',
                      name: `${optionName}.dropDownChoices.${ddIndex}.label.${locale}`,
                      required: true,
                      disabled: option?.dropDown === 'no',
                    }}
                  />
                )}
              </Grid>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export default LanguageOptionItem;
