import type {
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import type { MutateOptions } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useMutation } from '@core/queries';

import { TranslationsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { ITranslationPatchRequest } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

type Options = {
  patch?: InteliamUseMutationOptions<
    IAPIResponse,
    AxiosValidationErrorResponse,
    ITranslationPatchRequest
  >;
};
type Args = {
  options?: Options;
};

type PatchRunFunction = (
  body: ITranslationPatchRequest,
  options?: MutateOptions<
    IAPIResponse,
    AxiosValidationErrorResponse,
    ITranslationPatchRequest
  >
) => void;
type Result = {
  patch: {
    run: PatchRunFunction;
    mutation: InteliamUseMutationResult<
      IAPIResponse,
      AxiosValidationErrorResponse,
      ITranslationPatchRequest
    >;
  };
};
const useManageTranslations = (args: Args = {}): Result => {
  const { t, notify } = useEssentials();

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    ITranslationPatchRequest
  >((payload) => TranslationsApi.patch(payload), {
    // eslint-disable-next-line sonarjs/no-identical-functions
    onSuccess: () => {
      notify({
        message: t('Second part updated successfully'),
        type: INotifierEnum.Success,
      });
    },
    ...args.options?.patch,
  });

  const runPatch: PatchRunFunction = useCallback(
    (body, options) => {
      patchMutation.mutate(body, options);
    },
    [patchMutation]
  );

  return {
    patch: {
      run: runPatch,
      mutation: patchMutation,
    },
  };
};
export default useManageTranslations;
