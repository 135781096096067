import * as React from 'react';

import { Box } from '@shared/components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FCC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
