import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IPricingCategory,
  IPricing,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/pricings',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getPricingGrid = async (): Promise<IAPIResponse<Array<IPricing>>> => {
  const { data } = await client.instance.get('');
  return data;
};

const getPricingCategories = async (): Promise<
  IAPIResponse<Array<IPricingCategory>>
> => {
  const { data } = await client.instance.get('/categories-labels');
  return data;
};

export default {
  getPricingGrid,
  getPricingCategories,
};
