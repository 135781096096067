import * as React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { Box } from '..';

const FieldLoadingIndication: React.FCC<IContentLoaderProps> = (props) => (
  <Box borderRadius='5px' style={{ background: '#f3f3f3' }}>
    <ContentLoader
      viewBox='0 0 400 160'
      height={50}
      width={'100%'}
      backgroundColor='transparent'
      {...props}
    >
      <circle cx='150' cy='86' r='8' />
      <circle cx='194' cy='86' r='8' />
      <circle cx='238' cy='86' r='8' />
    </ContentLoader>
  </Box>
);

export default FieldLoadingIndication;
