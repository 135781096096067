import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls } from '@shared/components';

import type { FormTextInputProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const TextFilterer: React.FCC<
  FormTextInputProps & { defaultItem: IFilterDefaultItem }
> = (props) => {
  return <Controls.FormInput {...omit(props, ['defaultItem'])} required />;
};

export default TextFilterer;
