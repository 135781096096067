import type { IARTransitionEnum } from '@inteliam/foundation/lib/enums';
import {
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { ARUtils } from '@core/utils';

import { AssessmentRequestsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  DashboardType,
  IAPIResponse,
  IAR,
  IARTransitionInput,
} from '@inteliam/foundation/lib/types';

interface Options {
  assessmentRequest: IAR;
  dashboard: DashboardType;
  transition: IARTransitionEnum;
  confirmMessage?: string;
  successMessage?: string;
  onSuccess?: () => void;
}
type Result = {
  mutation: InteliamUseMutationResult<
    IAPIResponse<IAR>,
    AxiosValidationErrorResponse,
    IARTransitionInput
  >;
  canRun: () => Promise<IAPIResponse>;
  run: (props: {
    data: Partial<IARDeadlinesForm>;
  }) => Promise<IAPIResponse<void | IAR>>;
};

const useSpecificTransition = ({
  assessmentRequest,
  dashboard,
  transition,
  confirmMessage,
  successMessage = 'Operation successfully completed',
  onSuccess,
}: Options): Result => {
  const { t, confirm, notify } = useEssentials();

  const mutation = useMutation<
    IAPIResponse<IAR>,
    AxiosValidationErrorResponse,
    IARTransitionInput
  >(AssessmentRequestsApi.specificTransition, {
    ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
    keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
    onSuccess: () => {
      notify({
        message: t(successMessage),
        type: INotifierEnum.Success,
      });
      onSuccess?.();
    },
  });

  const run: Result['run'] = useCallback(
    ({ data }) => {
      return confirm({
        description: t(confirmMessage || 'Are you sure ?'),
      }).then(() => {
        const patchObject = ARUtils.prepareDeadlinePayload({
          id: assessmentRequest.id,
          initiateSurveyForm: data,
          statuses: assessmentRequest.statuses,
        });
        return mutation.mutateAsync({
          ...patchObject,
          action: transition,
        });
      });
    },
    [
      assessmentRequest.id,
      assessmentRequest.statuses,
      confirm,
      confirmMessage,
      mutation,
      t,
      transition,
    ]
  );

  const canRun: Result['canRun'] = useCallback(() => {
    return AssessmentRequestsApi.transition(
      {
        body: {},
        id: assessmentRequest.id,
        action: transition,
      },
      true
    );
  }, [assessmentRequest.id, transition]);

  return {
    run,
    canRun,
    mutation,
  };
};
export default useSpecificTransition;
