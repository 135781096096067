import { SharingRequestStatusEnum } from '@inteliam/foundation/lib/enums';

import { default as decline } from './decline';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Dialogs: Partial<Record<SharingRequestStatusEnum, any>> = {
  [SharingRequestStatusEnum.ACCEPTED]: undefined,
  [SharingRequestStatusEnum.DECLINED]: decline,
  [SharingRequestStatusEnum.QUEUED]: undefined,
  [SharingRequestStatusEnum.REQUESTED]: undefined,
};

export default Dialogs;
