import { FormLabel } from '@mui/material';
import { get, omit } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  FormControlLabel,
  ErrorHelpText,
  Radio,
  FormControl,
  RadioGroup,
} from '@shared/components';

import type { FormRadioGroupProps } from '@shared/types';

const FormRadioGroup: React.FCC<FormRadioGroupProps> = (props) => {
  const { t } = useEssentials();
  const {
    helperText,
    name,
    label,
    labelPlacement,
    options,
    required,
    disabled,
    readOnly,
    id,
    ...rest
  } = props;
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormControl
      component='fieldset'
      error={!!errorMessage}
      required={required}
      disabled={disabled || readOnly}
    >
      <FormLabel component='legend'>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={rest.defaultValue}
        render={({ field }) => {
          return (
            <RadioGroup
              {...omit(rest, ['defaultValue'])}
              {...field}
              name={name}
              onChange={(event, value) => {
                rest.onChange?.(event, value);
                field.onChange(value);
              }}
              data-cy={name}
              data-disabled={disabled || readOnly}
            >
              {options.map((option) => {
                return (
                  <FormControlLabel
                    key={option.label}
                    value={option.value}
                    control={
                      <Radio
                        checked={String(option.value) === String(field.value)}
                      />
                    }
                    label={t(option.label)}
                    labelPlacement={labelPlacement}
                    id={`${id ?? name}-${option.value.toString()}`}
                  />
                );
              })}
            </RadioGroup>
          );
        }}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

export default memo(FormRadioGroup);
