import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { QuestionI18nHelpers } from '@core/utils/i18n';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import { Controls, Grid, RichTextEditor, TextField } from '@shared/components';

interface Props {
  locale: ILanguagesEnum | 'defaultValue';
}
const BaseLanguageForm: React.FCC<Props> = ({ locale }) => {
  const { t } = useEssentials();
  const methods = useFormContext<
    IQuestionForm & {
      readonly: IQuestionForm;
    }
  >();
  const readonly = locale === 'defaultValue';
  const watchedValues = useWatch({
    control: methods.control,
    name: ['title', 'helpTextContent'],
  });
  return (
    <>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <TextField
            {...{
              label: t('Question title'),
              fullWidth: true,
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  watchedValues[0],
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormInput
            {...{
              fullWidth: true,
              label: t('Question title'),
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  watchedValues[0],
                  readonly ? undefined : locale
                ) || '',
              name: `title.${locale}`,
              required: true,
            }}
          />
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        {readonly ? (
          <RichTextEditor
            {...{
              value:
                QuestionI18nHelpers.getTranslatedFormValue(
                  watchedValues[1],
                  readonly ? undefined : locale
                ) || '',
              readOnly: true,
              disabled: true,
            }}
          />
        ) : (
          <Controls.FormRichTextEditor
            {...{
              fullWidth: true,
              defaultValue:
                QuestionI18nHelpers.getTranslatedFormValue(
                  watchedValues[1],
                  readonly ? undefined : locale
                ) || '',
              name: `helpTextContent.${locale}`,
              required: true,
            }}
          />
        )}
      </Grid>
    </>
  );
};

export default memo(BaseLanguageForm);
