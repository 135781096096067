import { debounce, noop } from 'lodash-es';

import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    //['link', 'image'],  uncomment this line when we need to include image in the text editor
  ],
};
const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  //'image',  uncomment this line when we need to include image in the text editor
];

interface Props {
  name?: string;
  value?: string;
  readOnly: boolean;
  onChange?: (value: string) => void;
}

const Editor: React.FCC<Props> = ({
  readOnly,
  onChange = noop,
  value = '',
  name,
}) => {
  // https://css-tricks.com/the-difference-between-throttling-and-debouncing/
  onChange = debounce(onChange, 500);
  return (
    <ReactQuill
      data-cy={name}
      className={readOnly ? 'quill-disabled' : undefined}
      style={{
        height: 132,
        overflowY: 'auto',
        alignItems: 'center',
        marginBottom: 10,
        opacity: readOnly ? 0.7 : 1,
      }}
      {...{ modules, formats, value, readOnly }}
      onChange={(value) => onChange(value)}
    />
  );
};

export default Editor;
