/* eslint-disable @inteliam/i18n/raw-text-detected */
import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { get } from 'lodash-es';

import React, { memo } from 'react';

import { DistributorUserForm } from '@core/components/distributors';
import { BaseDialog } from '@core/components/modals';

import { useEssentials } from '@core/contexts';
import type { IBODistributor } from '@core/types';

import {
  Button,
  Noop,
  Grid,
  IconButton,
  Radio,
  TableCell,
  TableRow,
  EditIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IMergeChanger, IMergeRowType, MergeState } from './utils';

const useStyles = makeStyles(() => ({
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
}));

interface Props {
  title: string;
  attribute: 'primaryContact';
  row: IMergeRowType;
  existing: IBODistributor;
  draft: IBODistributor;
  merged: MergeState;
  onChange: IMergeChanger;
  onUpdateContact: React.Dispatch<React.SetStateAction<IBODistributor>>;
}

const ContactRow: React.FCC<Props> = ({
  title,
  attribute,
  row,
  existing,
  draft,
  merged,
  onChange,
  onUpdateContact,
}) => {
  const { t } = useEssentials();
  const classes = useStyles();
  const [open, handlers] = useBoolean(false);
  const existingContactInfo = existing[attribute].contactInfo;
  const mergedContactInfo = merged.company[attribute].contactInfo;
  const draftContactInfo = draft[attribute].contactInfo;

  if (!existingContactInfo || !mergedContactInfo || !draftContactInfo) {
    return <Noop />;
  }
  return (
    <TableRow>
      <TableCell align='left' component='th' scope='row'>
        {title}
      </TableCell>
      <TableCell align='left' className={classes.cell}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {row.renderer(existingContactInfo)}
          <Radio
            checked={
              Object.values(mergedContactInfo).join(',') ===
                Object.values(existingContactInfo).join(',') &&
              get(merged.selection, attribute) === 'EXISTING'
            }
            value={'EXISTING'}
            name={attribute}
            onChange={(event) =>
              onChange(event, existing[attribute], 'EXISTING')
            }
          />
        </Grid>
      </TableCell>
      <TableCell align='left' className={classes.cell}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {row.renderer(draftContactInfo)}
          <div>
            <Grid container direction='row' alignItems='center'>
              <IconButton id='edit-contact' onClick={handlers.setTrue}>
                <EditIcon />
              </IconButton>
              <BaseDialog.Dialog
                open={open}
                onClose={handlers.setFalse}
                maxWidth='md'
              >
                <BaseDialog.Title id='merge-contact'>{title}</BaseDialog.Title>
                <BaseDialog.Content>
                  <DistributorUserForm
                    formId={'MERGE_PRIMARY'}
                    onSubmit={(user) => {
                      onUpdateContact((prevDistributor) => ({
                        ...prevDistributor,
                        primaryContact: {
                          ...prevDistributor.primaryContact,
                          ...user,
                        },
                      }));
                      handlers.setFalse();
                    }}
                    defaultValues={draft[attribute]}
                    distributorId={draft.id}
                    mode={'PRIMARY'}
                  />
                </BaseDialog.Content>
                <BaseDialog.Actions>
                  <Button
                    id='cancel-merge'
                    type='button'
                    variant='contained'
                    color='secondary'
                    onClick={handlers.setFalse}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    id='edit'
                    form='MERGE_PRIMARY'
                    type='submit'
                    variant='contained'
                    color='primary'
                  >
                    {t('Edit')}
                  </Button>
                </BaseDialog.Actions>
              </BaseDialog.Dialog>
              <Radio
                checked={
                  Object.values(mergedContactInfo).join(',') ===
                    Object.values(draftContactInfo).join(',') &&
                  get(merged.selection, attribute) === 'DRAFT'
                }
                value={'DRAFT'}
                name={attribute}
                onChange={(event) => onChange(event, draft[attribute], 'DRAFT')}
              />
            </Grid>
          </div>
        </Grid>
      </TableCell>
      <TableCell align='left'>
        <div>
          <div>{`${mergedContactInfo.firstName || '--'} ${
            mergedContactInfo.lastName || '--'
          }`}</div>
          <div>({mergedContactInfo.email})</div>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default memo(ContactRow);
