import { INewsStatusEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import type { INewsForm, INewsPayload } from '@core/types';

import type { IPartialNews } from '@inteliam/foundation/lib/types';

export const DEFAULT_NEWS: INewsForm = {
  title: '',
  description: '',
  publishedAt: new Date(),
  website: '',
  assessmentRequests: [{ id: '' }],
  status: INewsStatusEnum.STATUS_ACTIVE,
  themes: [],
};

export const normalize = {
  getInitialValues: (assessmentRequestId: string): INewsForm => {
    return {
      ...DEFAULT_NEWS,
      assessmentRequests: [{ id: assessmentRequestId }],
    };
  },
  newsToFormData: (
    defaultValues: IPartialNews,
    assessmentRequestId: string
  ): INewsForm => {
    return {
      ...DEFAULT_NEWS,
      ...defaultValues,
      themes: defaultValues?.themes?.map((theme) => theme.id) || [],
      assessmentRequests: [{ id: assessmentRequestId }],
    };
  },
  formDataToPayload: (formData: INewsForm): INewsPayload => {
    return {
      ...formData,
      themes: Helpers.ensureValueAsArray(
        formData.themes?.map((id) => ({
          id,
        }))
      ),
    };
  },
};
