import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { AnalysisHighlightsApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IOptionItem,
} from '@inteliam/foundation/lib/types';

export type FRONT_OFFICE_SCOPE = 'FrontOffice';
export type BACK_OFFICE_SCOPE = 'BackOffice';
export type SURVEY_SCOPE = 'Survey';
export type REVIEWS_SCOPE = 'Reviews';

const useAvailableMasterHighlightsCategories = (): {
  categories: string[];
  formattedCategories: IOptionItem[];
  isLoading: boolean;
} => {
  const mutation = useQuery<
    IAPIResponse<Array<string>> | undefined,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.CATEGORIES],
    () => AnalysisHighlightsApi.getAvailableCategories(),
    {
      //we don't need to refetch this at every mount or focus or reconnect
      //and we set cacheTime to infinity for the moment because it's not something that will change
      cacheTime: Number.POSITIVE_INFINITY,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const categories = Helpers.ensureValueAsArray(mutation.data?.data);
  return {
    categories,
    formattedCategories: categories.map((category) => ({
      label: category,
      value: category,
    })),
    isLoading: mutation.isLoading || mutation.isFetching,
  };
};

export default useAvailableMasterHighlightsCategories;
