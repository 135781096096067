import type { SharingRequestStatusEnum } from '@inteliam/foundation/lib/enums';
import { isNil } from 'lodash-es';

import React, { Fragment, useCallback, useState } from 'react';

import TransitionDialogs from '@core/components/modals/sr-transition-dialogs';

import { createNamedContext, useNamedContext } from '@shared/contexts';

import type { ISharingRequest } from '@inteliam/foundation/lib/types';

type ITransitionContext = (options: {
  transition: SharingRequestStatusEnum;
  sharingRequest: ISharingRequest;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => Promise<{ data: any } | void>;

const INITIAL_CONTEXT: ITransitionContext = () => Promise.resolve();
const TransitionContext = createNamedContext<ITransitionContext>(
  'SRTransitionContext'
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Function_ = (value?: { data: any } | void) => void;
interface State {
  transition: SharingRequestStatusEnum | undefined;
  sharingRequest: ISharingRequest | undefined;
}
const TransitionProvider: React.FCC = ({ children }) => {
  const [state, setState] = useState<State>({
    transition: undefined,
    sharingRequest: undefined,
  });
  const [resolveReject, setResolveReject] = useState<Function_[]>([]);
  const [resolve, reject] = resolveReject;
  const openDialog: ITransitionContext = useCallback(
    ({ transition, sharingRequest }) => {
      return new Promise((resolve: Function_, reject) => {
        setState({ transition, sharingRequest });
        setResolveReject([resolve, reject]);
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
    setState({ transition: undefined, sharingRequest: undefined });
  }, []);

  const handleCancel = useCallback(() => {
    reject();
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = useCallback(
    (data: unknown) => {
      resolve({ data: data });
      handleClose();
    },
    [resolve, handleClose]
  );

  const Dialog =
    !isNil(state.transition) && TransitionDialogs[state.transition]
      ? TransitionDialogs[state.transition]
      : undefined;

  return (
    <Fragment>
      <TransitionContext.Provider value={openDialog}>
        {children}
      </TransitionContext.Provider>
      {Dialog ? (
        <Dialog
          open={resolveReject.length === 2}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          sharingRequest={state.sharingRequest}
        />
      ) : undefined}
    </Fragment>
  );
};

export const useOpenTransitionDialog = (): ITransitionContext => {
  return useNamedContext(TransitionContext, INITIAL_CONTEXT);
};

export default TransitionProvider;
