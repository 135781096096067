import { ITitlesEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { BaseUserForm } from '@core/components/users';

import { EnterpriseUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseForm } from '@core/types';

import {
  Button,
  Card,
  IconButton,
  Grid,
  Box,
  Divider,
  RemoveCircleIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

const useStyles = makeStyles((theme: Theme) => ({
  childCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 5,
    marginTop: 10,
  },
}));

const Contacts: React.FC = () => {
  const classes = useStyles();
  const { t } = useEssentials();
  const methods = useFormContext<IEnterpriseForm>();
  const watchedKind = useWatch({
    name: 'kind',
    defaultValue: methods.getValues('kind'),
    control: methods.control,
  });

  const watchedEnterpriseAccess = useWatch({
    control: methods.control,
    name: 'configuration.enterpriseAccess',
    defaultValue: methods.getValues('configuration.enterpriseAccess'),
  });

  const { fields, append, remove } = useFieldArray<
    IEnterpriseForm,
    'users',
    'rhfId'
  >({
    control: methods.control,
    name: 'users',
    keyName: 'rhfId',
  });
  return (
    <Card.CContainer
      styled
      style={{
        opacity: watchedEnterpriseAccess ? 1 : 0.5,
      }}
    >
      <Card.CContainer title={t('Users Management')} />
      {fields.map((field, index) => {
        return (
          <Card.CContainer className={classes.childCard} key={field.rhfId}>
            <Card.Header
              action={
                <IconButton
                  id={`remove-contact-${field.contactInfo?.email || index}`}
                  color='secondary'
                  onClick={() => remove(index)}
                  disabled={fields.length === 1}
                >
                  <RemoveCircleIcon />
                </IconButton>
              }
            />
            <Card.Body>
              <Grid container spacing={3}>
                <BaseUserForm
                  defaultValues={field}
                  allowedRoles={EnterpriseUtils.ROLES_LOOKUP[watchedKind]}
                  baseName={Helpers.arrayDotNotation('users', index)}
                  readOnly={{
                    email: !watchedEnterpriseAccess,
                    role: !watchedEnterpriseAccess,
                    firstName: !watchedEnterpriseAccess,
                    lastName: !watchedEnterpriseAccess,
                    language: !watchedEnterpriseAccess,
                    phoneNumber: !watchedEnterpriseAccess,
                    position: !watchedEnterpriseAccess,
                    status: !watchedEnterpriseAccess,
                    title: !watchedEnterpriseAccess,
                  }}
                />
              </Grid>
            </Card.Body>
          </Card.CContainer>
        );
      })}
      <Divider />
      <Card.Body>
        <Box display='flex' justifyContent='flex-end' p={2}>
          <Button
            id='manufacturer-form-button-add-user'
            color='primary'
            variant='contained'
            type='button'
            onClick={() =>
              append({
                contactInfo: {
                  title: ITitlesEnum.MR,
                  email: '',
                },
                roles: [],
              })
            }
          >
            {t('Add Contact')}
          </Button>
        </Box>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(Contacts);
