import {
  IARStatusEnum,
  IARStatusRankEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  DateFormatter,
  enhancedYupResolver,
  Helpers,
  Validations,
} from '@inteliam/foundation/lib/utils';
import { get, set } from 'lodash-es';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { useFetchCampaignTypeByType } from '@core/queries';

import { ARUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IARDeadlineFormKey, IARDeadlinesForm } from '@core/types';

import { Button, Controls, FormGroup, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IAR,
  IStatus,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';
import { updateDeadlinesSchema } from './schemas';

interface Props {
  title?: React.ReactNode;
  open: boolean;
  isLoading: boolean;
  errors?: ValidationErrorResponse;
  onClose: () => void;
  statuses: IStatus[];
  disabledStatuses?: IARStatusEnum[];
  defaultValues?: Partial<IARDeadlinesForm>;
  onSubmit: (data: IARDeadlinesForm) => void;
  assessmentRequest: IAR;
  autoCalculateDeadlines?: boolean;
}

const INITIAL_DEFAULT_VALUES: Partial<IARDeadlinesForm> = Object.values(
  IARStatusEnum
).reduce((accumulator, _, index) => {
  const statusName = Helpers.arrayDotNotation(
    'statuses',
    index as IARStatusRankEnum
  );
  const key = `${statusName}.deadline`;
  set(accumulator, key, new Date());
  return accumulator;
}, {} as Partial<IARDeadlinesForm>);

const DeadlineForm: React.FCC<Props> = ({
  onClose,
  title,
  disabledStatuses = [],
  defaultValues,
  statuses,
  open,
  onSubmit,
  assessmentRequest,
  errors,
  isLoading,
  autoCalculateDeadlines = true,
}) => {
  const { t } = useEssentials();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });
  const methods = useForm<IARDeadlinesForm>({
    defaultValues: statuses.reduce((accumulator, status) => {
      set(
        accumulator,
        ARUtils.getStatusDeadlineKey(status),
        status.deadline
          ? new Date(status.deadline)
          : get(defaultValues, ARUtils.getStatusDeadlineKey(status))
      );
      const passedKey = `statuses[${status.rank as IARStatusRankEnum}].passed`;
      set(accumulator, passedKey, status.passed);
      return accumulator;
    }, INITIAL_DEFAULT_VALUES),
    resolver: enhancedYupResolver(
      updateDeadlinesSchema,
      undefined,
      undefined,
      true,
      disabledStatuses
    ),
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  const {
    formState: { errors: formErrors },
  } = methods;

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: (name, error) =>
          methods.setError(name as keyof IARDeadlinesForm, error),
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, methods.setError]);

  if (!campaignTypeQuery.isSettled) {
    return <BaseDialog.DialogPlaceholder />;
  }
  if (!campaignTypeQuery.isSuccess) {
    return (
      <BaseDialog.DialogPlaceholder>
        {t('Unable to find the requested standard campaign')}
      </BaseDialog.DialogPlaceholder>
    );
  }

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <Controls.Form methods={methods} submitHandler={onSubmit}>
        <BaseDialog.Title id='Update Deadline' onClose={onClose}>
          {title || t('Update Deadline')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            {statuses
              .filter((status) => status.isDeadlinable)
              .map((status) => {
                const name =
                  `statuses.${status.rank}.deadline` as IARDeadlineFormKey;
                const prevName = `statuses.${
                  status.rank - 1
                }.deadline` as IARDeadlineFormKey;
                const prevStatus =
                  Object.values(IARStatusEnum)[status.rank - 1];

                const prevStatusLabel = t(prevStatus);
                return (
                  <Grid item md={12} key={status.codename}>
                    <FormGroup>
                      <Controls.FormDatePicker
                        inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
                        name={name}
                        disabled={disabledStatuses.includes(status.codename)}
                        label={t(status.codename)}
                        pickerType='date'
                        componentsProps={{
                          actionBar: {
                            actions: ['today'],
                          },
                        }}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        errorText={t(
                          get(formErrors, `${name}`)?.message || '',
                          {
                            details: get(defaultValues, prevName)
                              ? `[${prevStatusLabel}  ${DateFormatter.formatTimestamp(
                                  new Date(
                                    get(defaultValues, prevName) as Date
                                  ),
                                  DateFormatter.FORMATS.fr_FR.SHORT
                                )}]`
                              : '',
                          }
                        )}
                        onChange={(date) => {
                          if (!date || !autoCalculateDeadlines) {
                            return;
                          }
                          const nextDates =
                            ARUtils.calculateNextDefaultDeadlines({
                              assessmentRequest,
                              standardCampaignType: campaignTypeQuery.data.data,
                              updatedStatus: status,
                              updatedDate: date,
                            });

                          nextDates.forEach((newRankedDeadline) => {
                            const statusFieldName =
                              `statuses.${newRankedDeadline.rank}.deadline` as IARDeadlineFormKey;

                            methods.setValue(
                              statusFieldName,
                              newRankedDeadline.deadline,
                              { shouldValidate: true }
                            );
                          });
                        }}
                      />
                    </FormGroup>
                    <Controls.FormInput
                      name={`statuses.${status.rank}.passed`}
                      style={{ display: 'none' }}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-update-deadlines'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-update-deadlines'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(DeadlineForm);
