import { FormControlLabel, FormControlLabelProps } from '@mui/material';

import * as React from 'react';

import type { IIdentifiedObject } from '@inteliam/foundation/lib/types';

const CustomFormControlLabel: React.FCC<
  FormControlLabelProps & IIdentifiedObject
> = ({ ...rest }) => {
  return <FormControlLabel {...rest} data-cy={rest.id} />;
};

export default CustomFormControlLabel;
