import { Transformers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { useManageUsers } from '@core/queries';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

const BoUsersField: React.FCC<FormSelectProps> = (props) => {
  const { list } = useManageUsers({
    list: {
      options: {
        enabled: true,
      },
    },
  });

  return (
    <Controls.FormSelect
      {...props}
      style={{ width: '100%' }}
      options={Transformers.mapIntoOptionItem(
        list.data?.data || [],
        (user) => user.contactInfo.email,
        'id'
      )}
    />
  );
};

export default BoUsersField;
