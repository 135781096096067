import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import type { FieldArrayWithId } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import {
  Controls,
  IconButton,
  Grid,
  FormGroup,
  RemoveCircleIcon,
} from '@shared/components';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

interface Props {
  siblings: IOptionItem[];
  index: number;
  readOnly?: boolean;
  onRemove: (index: number) => void;
  child: Partial<FieldArrayWithId<IQuestionForm, 'childrenDependencies', 'id'>>;
  childOptions: IOptionItem[];
}

const LogicItem: React.FCC<Props> = ({
  child,
  siblings,
  readOnly = false,
  index,
  onRemove,
  childOptions,
}) => {
  const { t } = useEssentials();
  const fieldName = Helpers.arrayDotNotation('childrenDependencies', index);

  return (
    <React.Fragment>
      <Grid item md={1} xs={12}>
        {!readOnly && (
          <IconButton
            id={`remove-childrenDependencies[${index}]`}
            color='secondary'
            onClick={() => onRemove(index)}
            size='large'
          >
            <RemoveCircleIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item md={5} xs={12}>
        <FormGroup>
          {(!child.question?.id ||
            (child.question?.id && siblings.length > 0)) && (
            <Controls.FormSelect
              label={t('Name of children question')}
              name={`${fieldName}.question.id`}
              options={siblings}
              readOnly={readOnly}
              defaultValue={child.question?.id}
            />
          )}
        </FormGroup>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormGroup>
          {(!child.optionIds ||
            (child.optionIds && childOptions.length > 0)) && (
            <Controls.FormSelect
              label={t('Linked')}
              name={`${fieldName}.optionIds`}
              options={childOptions}
              readOnly={readOnly}
              disabled={childOptions.length === 0}
              defaultValue={child.optionIds}
              multiple
            />
          )}
        </FormGroup>
      </Grid>
    </React.Fragment>
  );
};

export default memo(LogicItem);
