import { QuestionReviewLinkConfigurationI18nHelpers } from '@core/utils/i18n';

import { CONDITIONS_OPTIONS } from '@core/enums';

import type { TFunction } from '@core/contexts';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

export const getConditionsLabels = (
  t: TFunction,
  conditions: Array<number>
): Array<string> => {
  return conditions.map((condition) =>
    t(
      QuestionReviewLinkConfigurationI18nHelpers.getConditionTranslation(
        condition
      )
    )
  );
};

export const getConditionsOptions = (t: TFunction): Array<IOptionItem> => {
  return CONDITIONS_OPTIONS.map((item) => ({
    value: item.value,
    label: t(item.label),
  }));
};
