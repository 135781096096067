import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { AnswerEvaluationsApi } from '@core/api';
import type { IAnswerEvaluationsResponse } from '@core/types';

import type { BaseAxiosErrorResponse } from '@inteliam/foundation/lib/types';

const useFetchAnswerEvaluationByARAndClassifications = ({
  assessmentRequestId,
  themeId,
  criterionId,
  options,
}: {
  assessmentRequestId: string;
  themeId: string;
  criterionId?: string;
  options?: InteliamUseQueryOptions<
    IAnswerEvaluationsResponse,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IAnswerEvaluationsResponse,
  BaseAxiosErrorResponse
> => {
  return useQuery<IAnswerEvaluationsResponse, BaseAxiosErrorResponse>(
    [
      ...QUERY_KEYS.ANSWER_EVALUATIONS,
      assessmentRequestId,
      themeId,
      criterionId,
    ],
    () =>
      AnswerEvaluationsApi.getByARAndClassifications(
        assessmentRequestId,
        themeId,
        criterionId
      ),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
      cacheTime: 1000 * 300,
      refetchOnMount: false,
      ...options,
    }
  );
};

export default useFetchAnswerEvaluationByARAndClassifications;
