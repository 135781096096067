import { IDistributorStatusEnum } from '@inteliam/foundation/lib/enums';

import React from 'react';
import { useParams } from 'react-router-dom';

import { useManageDistributors } from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IBODistributor } from '@core/types';

import { Alert, CenteredSpinner } from '@shared/components';

import { makeStyles } from '@shared/utils';

import { invariant } from '@shared/guards';
import type { Theme } from '@shared/types';

import type {
  IIdentifiedObject,
  RenderProps,
} from '@inteliam/foundation/lib/types';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));

const Container: React.FC<
  RenderProps<{
    distributor: IBODistributor;
  }>
> = ({ children }) => {
  const { id } = useParams<IIdentifiedObject>();
  invariant(id, 'Distributor id is required');
  const classes = useStyles();
  const { t } = useEssentials();

  const { getOneById } = useManageDistributors({
    getOneById: { args: { id } },
  });

  return (
    <>
      {getOneById.isError && (
        <Alert severity={INotifierEnum.Error} className={classes.alert}>
          {t('Distributor Not Found')}
        </Alert>
      )}
      {!getOneById.isSettled && <CenteredSpinner />}
      {getOneById.isSuccess &&
        getOneById.data.data.status !== IDistributorStatusEnum.DRAFT && (
          <Alert severity={INotifierEnum.Error} className={classes.alert}>
            {t('Distributor is not draft, you cannot merge it')}
          </Alert>
        )}
      {getOneById.isSuccess &&
        getOneById.data.data.status === IDistributorStatusEnum.DRAFT && (
          <>
            {typeof children === 'function'
              ? children({
                  distributor: getOneById.data.data,
                })
              : children}
          </>
        )}
    </>
  );
};

export default Container;
