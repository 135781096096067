import { omit } from 'lodash-es';

import * as React from 'react';

import { AssessmentRequestField } from '@core/components/common';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const ARFilterer: React.FCC<
  FormSelectProps & {
    companyId: string;
    defaultItem: IFilterDefaultItem;
  }
> = ({ companyId, ...rest }) => {
  return (
    <AssessmentRequestField
      companyId={companyId}
      {...omit(rest, ['defaultItem'])}
      defaultValue={[]}
    />
  );
};

export default ARFilterer;
