import { useOnMount, useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { QuestionsUtils } from '@core/utils';
import { QuestionI18nHelpers } from '@core/utils/i18n';

import { useEssentials } from '@core/contexts';
import type { ISingleChoiceQuestionForm } from '@core/types';

import {
  Button,
  Controls,
  IconButton,
  Grid,
  FormGroup,
  RemoveCircleIcon,
  AddCircleIcon,
} from '@shared/components';

import { BaseDialog } from '../';

interface Props {
  fieldName: `options.${number}`;
  readOnly: boolean;
  open: boolean;
  onClose: () => void;
}
const ManageDropDownChoicesModal: React.FCC<Props> = ({
  fieldName,
  readOnly,
  open,
  onClose,
}) => {
  const { t } = useEssentials();
  const methods = useFormContext<ISingleChoiceQuestionForm>();
  const snapShot = useRef<ISingleChoiceQuestionForm['options'] | undefined>();
  const { fields, append, remove } = useFieldArray<
    ISingleChoiceQuestionForm,
    `options.${number}.dropDownChoices`,
    'rhfId'
  >({
    control: methods.control,
    name: `${fieldName}.dropDownChoices`,
    keyName: 'rhfId',
  });

  useOnUpdate(() => {
    if (open === true) {
      snapShot.current = Helpers.ensureValueAsArray(
        methods.getValues()?.options
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useOnMount(() => {
    if (Array.isArray(fields) && fields.length === 0) {
      append([
        {
          ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM,
          id: Helpers.uuid(),
        },
        {
          ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM,
          id: Helpers.uuid(),
        },
      ]);
    }
  });

  return (
    <BaseDialog.Dialog
      keepMounted={true}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Drop-down choices')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <div>
          {Array.isArray(fields) &&
            fields.map((choice, index) => {
              return (
                <Grid container spacing={3} key={choice.rhfId}>
                  <Grid style={{ display: 'flex', alignItems: 'center' }}>
                    {index > 1 && (
                      <IconButton
                        id={`remove-choice-${
                          QuestionI18nHelpers.getTranslatedFormValue(
                            choice.label
                          ) || index
                        }`}
                        disabled={readOnly}
                        color='secondary'
                        onClick={() => remove(index)}
                        size='large'
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <div hidden>
                    <Controls.FormInput
                      name={`${fieldName}.${Helpers.arrayDotNotation(
                        'dropDownChoices',
                        index
                      )}.id`}
                      type='text'
                      required
                      defaultValue={choice.id}
                    />
                  </div>
                  <Grid item md={8} xs={12}>
                    <FormGroup>
                      <Controls.FormInput
                        key={`${fieldName}.${Helpers.arrayDotNotation(
                          'dropDownChoices',
                          index
                        )}.id`}
                        name={`${fieldName}.${Helpers.arrayDotNotation(
                          'dropDownChoices',
                          index
                        )}.label.defaultValue`}
                        label={t('Choice {{index}}', {
                          index: index + 1,
                        })}
                        required
                        defaultValue={QuestionI18nHelpers.getTranslatedFormValue(
                          choice.label
                        )}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              );
            })}
          <Grid item md={1}>
            <IconButton
              id={'add-choice'}
              disabled={readOnly}
              color='primary'
              onClick={() => {
                append({
                  ...QuestionsUtils.DEFAULT_OPTION_CHOICE_FORM,
                  id: Helpers.uuid(),
                });
              }}
              size='large'
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </div>
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-manage-choices'
          onClick={() => {
            methods.setValue('options', snapShot.current || []);
            onClose();
          }}
          type='button'
          color='primary'
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-manage-choices'
          type='submit'
          color='primary'
          onClick={onClose}
        >
          {t('Confirm')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(ManageDropDownChoicesModal);
