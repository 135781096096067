import { ApiFactory } from '@inteliam/foundation/lib/api';

import type { IBOUserPayload, IOption } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type OptionsActions =
  | 'paginate'
  | 'getOneById'
  | 'create'
  | 'update'
  | 'remove'
  | 'patch';

const baseOptionApi = ApiFactory.generate<
  IOption,
  JwtBOUser,
  OptionsActions,
  IBOUserPayload
>({
  baseRoute: '/options',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: (arg: any): arg is IOption => {
    return (
      typeof arg.id === 'string' &&
      typeof arg.name === 'string' &&
      typeof arg.description === 'string'
    );
  },
});

export default {
  ...baseOptionApi.actions,
};
