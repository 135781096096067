import * as React from 'react';

import { IQuestionFormContext, QuestionFormContext } from './form-context';

type Props = Omit<IQuestionFormContext, 'openedQuestion' | 'setOpenedQuestion'>;
export const QuestionFormProvider: React.FCC<Props> = ({
  children,
  readOnly,
  defaultValues,
  mode,
}) => {
  const [openedQuestion, setOpenedQuestion] = React.useState<
    string | undefined
  >();
  const value = React.useMemo(
    () => ({
      mode,
      readOnly,
      defaultValues,
      openedQuestion,
      setOpenedQuestion,
    }),
    [mode, readOnly, defaultValues, openedQuestion]
  );
  return (
    <QuestionFormContext.Provider value={value}>
      {children}
    </QuestionFormContext.Provider>
  );
};

export const useQuestionFormContext = () => {
  const context = React.useContext(QuestionFormContext);
  if (!context) {
    throw new Error(
      'useQuestionFormContext must be used within a QuestionFormProvider'
    );
  }
  return context;
};
