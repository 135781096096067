import React, { memo } from 'react';

import type { ICancellationAR } from '@inteliam/foundation/lib/types';

import Form from '../../cancel-assessment/form';

interface Props {
  id: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: (data: ICancellationAR) => void;
}
const DeclineSharingRequest: React.FCC<Props> = ({
  onConfirm,
  open,
  onCancel,
}) => {
  return (
    <Form
      open={open}
      onClose={onCancel}
      onSubmit={onConfirm}
      isLoading={false}
    />
  );
};

export default memo(DeclineSharingRequest);
