/* eslint-disable no-mixed-operators */
import React, { memo } from 'react';

import { useCompanyQualificationEnums } from '@core/queries';

import { QUESTION_COUNTRIES_CLASSES } from '@core/enums';

import { useEssentials } from '@core/contexts';

import {
  Controls,
  Paper,
  FormGroup,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  TableBody,
} from '@shared/components';

import type { IOptionItem, IScope } from '@inteliam/foundation/lib/types';

interface Props {
  prefix?: string;
  readOnly?: boolean;
  defaultValues?: IScope[];
}

const ScopeItem: React.FCC<Props> = ({
  prefix,
  defaultValues,
  readOnly = false,
}) => {
  const { t } = useEssentials();
  const _prefix = prefix ? prefix + '.' : '';
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  return (
    <>
      {companyQualificationEnums && (
        <TableContainer component={Paper}>
          <Table size='small' style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {companyQualificationEnums.sizes.map((size) => {
                  return (
                    <TableCell key={size.value} align='center'>
                      {size.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyQualificationEnums.categories.map(
                (category: IOptionItem, categoryIndex: number) => (
                  <TableRow key={category.value}>
                    <TableCell component='th' scope='row'>
                      {t(category.label)}
                    </TableCell>
                    {companyQualificationEnums.sizes.map(
                      (size: IOptionItem, sizeIndex: number) => {
                        return (
                          <TableCell key={size.value} align='right'>
                            <FormGroup>
                              <Controls.FormSelect
                                style={{ height: 30 }}
                                defaultValue={
                                  defaultValues === undefined
                                    ? 3
                                    : defaultValues[
                                        categoryIndex *
                                          companyQualificationEnums.sizes
                                            .length +
                                          sizeIndex
                                      ].country
                                }
                                name={`${_prefix}scopes.${
                                  categoryIndex *
                                    companyQualificationEnums.sizes.length +
                                  sizeIndex
                                }.country`}
                                options={QUESTION_COUNTRIES_CLASSES}
                                disabled={readOnly}
                              />
                            </FormGroup>
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default memo(ScopeItem);
