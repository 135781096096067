import { IARPhaseEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useManageMedia } from '@core/queries';

import { ARUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import { IPartialInitiateSurveyForm, MEDIA_KINDS } from '@core/types';

import {
  Box,
  Controls,
  Grid,
  HelpIcon,
  Tooltip,
  Typography,
  FormFieldLoadingIndicator,
} from '@shared/components';

import { DatatableUtils } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
}

const EmailingFields: React.FCC<Props> = ({ assessmentRequest }) => {
  const { t } = useEssentials();
  const methods = useFormContext<IPartialInitiateSurveyForm>();
  const questionnaireAssignees = ARUtils.getAssigneesByPhase(
    assessmentRequest.statuses,
    IARPhaseEnum.ENGAGEMENT
  );
  const watchedAttachFile = methods.watch('attachFile');
  const { list } = useManageMedia({
    list: {
      args: {
        primaryCriteria: {
          operator: 'and',
          conditions: [
            DatatableUtils.createCondition(
              'kind',
              'eq',
              MEDIA_KINDS.ENGAGEMENT_DOCUMENT
            ),
          ],
        },
      },
    },
  });
  return (
    <>
      <Typography variant='h5'>{t('Emailing')}</Typography>
      <Grid item md={12} style={{ padding: 0 }}>
        <Box display='flex' alignItems={'center'}>
          <label style={{ marginRight: 10 }}>{t('Attached files')}</label>

          <Controls.FormCheckbox
            label={t('Attach specific file')}
            name={'attachFile'}
            onChange={(_, checked) => {
              // clear file on uncheck
              if (!checked) {
                methods.resetField('presentationFile.id');
              }
            }}
          />
          {!list.isSettled && <FormFieldLoadingIndicator />}
          {list.isSuccess && (
            <Controls.FormSelect
              disabled={Boolean(watchedAttachFile) === false}
              label={t('Select a presentation file')}
              name='presentationFile.id'
              options={list.data?.data.map((item) => ({
                value: item.id,
                label: item.file.originalFileName,
              }))}
            />
          )}
        </Box>
        <Box display='flex' alignItems={'center'}>
          <label style={{ marginRight: 10 }}>{t('Receiver:')}</label>

          <Controls.FormCheckbox
            disabled={questionnaireAssignees.length === 0}
            label={t('Send Emails to sponsors ({{fullName}})', {
              fullName: questionnaireAssignees
                .map((assignee) => assignee?.contactInfo?.fullName)
                .join(', '),
            })}
            name={'sendToAssignee'}
          />
          <Tooltip
            title={
              <div>
                {t(
                  "If this option is disabled, that means that this AR has no 'Questionnaire & Review' assignee, If you select this option, the emails will be sent to the 'Questionnaire & Review' assignee of the assessment request. If you don't select this option, the emails will be sent to the distributor users."
                )}
              </div>
            }
          >
            <HelpIcon />
          </Tooltip>
        </Box>
        <Box display='flex' alignItems={'center'}>
          <label style={{ marginRight: 10 }}>{t('Sender:')}</label>
          <Typography variant='caption'>
            {t(
              "* The emails will be sent using the 'Questionnaire & Review' assignee email address, if no BO user is assigned to 'Questionnaire & Review' & Review phase, the emails will be sent using the support email (support)."
            )}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default memo(EmailingFields);
