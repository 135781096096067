import { ApiFactory } from '@inteliam/foundation/lib/api';
import { QueryString } from '@inteliam/foundation/lib/utils';

import { isIDocumentEvaluation } from '@core/guards';
import type {
  IDetailedDocumentEvaluation,
  IDocumentEvaluation,
  IEvaluateDocumentPayload,
  IEvaluationProgress,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  Id,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/document-evaluations';

const baseDocumentEvaluationsApi = ApiFactory.generate<
  IDocumentEvaluation,
  JwtBOUser
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIDocumentEvaluation,
});

const paginateByAR = async ({
  id,
  query,
  total = false,
}: {
  id: Id;
  query?: IPaginationQuery;
  total?: boolean;
}): Promise<IPaginationResponse<IDocumentEvaluation>> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.get(
    `/${id}?total=${total.toString()}`,
    {
      params: { ...query, sort: { _id: 'asc' } },
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  return data;
};

const detailed = async (id: Id): Promise<IDetailedDocumentEvaluation> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.get(
    `/${id}/detailed`
  );
  return data;
};

const getByDocumentId = async (
  id: Id
): Promise<IAPIResponse<IDocumentEvaluation>> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.get(
    `/document/${id}`
  );
  return data;
};

const evaluate = async ({
  status,
  id,
}: IEvaluateDocumentPayload): Promise<IAPIResponse<IDocumentEvaluation>> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.patch(
    `/${id}/${status}`
  );
  return data;
};

const getGlobalInsights = async (
  id: string
): Promise<IAPIResponse<IEvaluationProgress>> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.get(
    `/${id}/insights`
  );
  return data;
};

const getScopeLevelsAction = async (): Promise<IAPIResponse<Array<string>>> => {
  const { data } = await baseDocumentEvaluationsApi.client.instance.get(
    `/scope-levels`
  );
  return data;
};

export default {
  getByDocumentId,
  evaluate,
  paginateByAR,
  getGlobalInsights,
  getScopeLevelsAction,
  detailed,
};
