import { yupResolver } from '@hookform/resolvers/yup';
import { IBORolesEnum } from '@inteliam/foundation/lib/enums';
import { OptionalUserSchema } from '@inteliam/foundation/lib/schemas';
import {
  Helpers,
  Transformers,
  Validations,
} from '@inteliam/foundation/lib/utils';

import React, { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useManageEnterprises } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { BO_ROLES } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { IBOUserForm, IBOUserPayload } from '@core/types';

import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Controls,
  Divider,
  FormControlLabel,
  Grid,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IPartialBOUser,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseUserForm } from './';

const BO_ROLES_ARRAY = Object.values(BO_ROLES).filter(
  (role) => role.value !== IBORolesEnum.ROLE_TECHNICAL_SUPER_ADMIN
);
interface Props {
  onSubmit: (data: IBOUserPayload) => void;
  defaultValues?: IPartialBOUser;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const DEFAULT_VALUES = {
  roles: [],
};
const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  errors,
  isLoading,
}) => {
  const { t, user } = useEssentials();
  const { paginate } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
      },
    },
  });
  const methods = useForm<IBOUserForm>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
      manufacturers: Transformers.mapIntoOptionItem(
        Helpers.ensureValueAsArray(defaultValues?.manufacturers),
        'name',
        'id'
      ),
    },
    resolver: yupResolver(OptionalUserSchema),
  });
  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const watchedRoles =
    useWatch({
      control: methods.control,
      name: 'roles',
    }) || [];

  const onImpersonationValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      methods.setValue('roles', [
        ...watchedRoles.filter((v) => v !== IBORolesEnum.ROLE_CONNECT_AS),
        IBORolesEnum.ROLE_CONNECT_AS,
      ]);
    } else {
      methods.setValue(
        'roles',
        watchedRoles.filter((v) => v !== IBORolesEnum.ROLE_CONNECT_AS)
      );
    }
  };

  return (
    <Controls.Form
      submitHandler={(values) => {
        onSubmit({
          ...defaultValues,
          ...values,
          manufacturers:
            values.manufacturers?.map((it) => ({
              id: it.value.toString(),
            })) || [],
        });
      }}
      methods={methods}
    >
      <Grid container spacing={3}>
        <BaseUserForm
          defaultValues={defaultValues}
          allowedRoles={BO_ROLES_ARRAY}
          multipleRoleSelection
        />
      </Grid>
      <Card.CContainer styled>
        <Card.Header title={t('Available actions')} />
        <Divider />
        <Card.Body>
          <FormControlLabel
            id='user-form-actions-impersonate'
            style={{ width: 450 }}
            control={
              <Checkbox
                onChange={(event) => onImpersonationValueChange(event)}
                defaultChecked={watchedRoles?.includes(
                  IBORolesEnum.ROLE_CONNECT_AS
                )}
              />
            }
            label={t('Impersonation')}
            labelPlacement={'end'}
          />
        </Card.Body>
        <Divider />
      </Card.CContainer>
      {watchedRoles?.some((role) =>
        [IBORolesEnum.ROLE_CSM, IBORolesEnum.ROLE_SENIOR_CSM].includes(role)
      ) && (
        <Card.CContainer styled>
          <Card.Header title={t('CSM enterprises assignation')} />
          <Divider />
          <Card.Body>
            {!paginate.isSettled && (
              <CircularProgress color='inherit' size={16} />
            )}

            {paginate.isFetched && paginate.isSuccess && (
              <Controls.FormTransferList
                name='manufacturers'
                data={Transformers.mapIntoOptionItem(
                  paginate.data.data,
                  'name',
                  'id'
                )}
                disabled={
                  !UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_CSM, user)
                }
              />
            )}
          </Card.Body>
          <Divider />
        </Card.CContainer>
      )}
      <Grid
        style={{ marginTop: 20 }}
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <Grid item>
          <Button
            id='reset-user-form'
            disabled={isLoading}
            type='reset'
            variant='contained'
            color='secondary'
          >
            {t('Reset')}{' '}
          </Button>
        </Grid>
        <Grid item>
          <Button
            id='submit-user-form'
            disabled={isLoading}
            type='submit'
            variant='contained'
            color='primary'
          >
            {t('Submit')}{' '}
            {isLoading && <CircularProgress color='inherit' size={16} />}
          </Button>
        </Grid>
      </Grid>
    </Controls.Form>
  );
};
export default memo(Form);
