import { DEFAULT_ONE_TIME_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterprisesApi } from '@core/api';

import type { QueryTypes } from '@shared/types';

type Query = QueryTypes<typeof EnterprisesApi.getOneById>;

const useFetchEnterpriseById = ({
  id,
  options,
}: {
  id?: string;
  options?: Query['options'];
}): Query['result'] & { isSettled: boolean } => {
  const query: Query['result'] = useQuery(
    [...QUERY_KEYS.MANUFACTURERS_LIST, id],
    () => (id ? EnterprisesApi.getOneById(id) : Promise.reject()),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      initialData: undefined,
      refetchOnMount: false,
      ...options,
    }
  );
  return query;
};

export default useFetchEnterpriseById;
