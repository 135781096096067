import { ApiFactory } from '@inteliam/foundation/lib/api';
import { assertIsTypedArray, isIBOUser } from '@inteliam/foundation/lib/guards';
import { PublicApi } from '@inteliam/foundation/lib/utils';

import type { IBOUserPayload } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IBOUser,
  IFODistributorUser,
  IFOSupplierUser,
  IForgetPasswordPayload,
  IResetPasswordPayload,
  IUpdatePasswordInput,
  JwtBOUser,
  CompanyKindType,
  EnterpriseKindType,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const publicClient = new PublicApi(Constants.BASE_URL);

type UserActions =
  | 'paginate'
  | 'getOneById'
  | 'create'
  | 'update'
  | 'remove'
  | 'patch';

const baseUserApi = ApiFactory.generate<
  IBOUser,
  JwtBOUser,
  UserActions,
  IBOUserPayload
>({
  baseRoute: '/users',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIBOUser,
});

const forgotPassword = async (data: IForgetPasswordPayload): Promise<void> => {
  await publicClient.instance.post('/users/forgot-password', {
    ...data,
    applicationUrl: Constants.APPLICATION_URL,
  });
};

const resetPassword = async (data: IResetPasswordPayload): Promise<void> => {
  await publicClient.instance.post(`/users/reset-password/${data.token}`, data);
};

const updatePassword = async (data: IUpdatePasswordInput): Promise<void> => {
  await baseUserApi.client.instance.post(`/update-password`, data);
};

const getOneById = async <
  TKind extends CompanyKindType,
  TUser = TKind extends 'DISTRIBUTOR'
    ? IFODistributorUser
    : TKind extends EnterpriseKindType
    ? IFOSupplierUser
    : IBOUser
>(
  id: string,
  kind?: TKind
): Promise<IAPIResponse<TUser>> => {
  const { data } = await (kind
    ? baseUserApi.client.instance.get(`/${kind.toLowerCase()}/${id}`)
    : baseUserApi.client.instance.get(`/${id}`));
  // TODO generic guard
  // assertIsTyped(data, isIBOUser);
  return data;
};

const list = async (): Promise<IAPIResponse<Array<IBOUser>>> => {
  const { data } = await baseUserApi.client.instance.get(
    `/list?scope=BackOfficeUsers`
  );
  assertIsTypedArray(data.data, isIBOUser);
  return data;
};

const updateAvatar = async ({
  id,
  body,
}: IPatchObject): Promise<IAPIResponse<void>> => {
  const { data } = await baseUserApi.client.instance.patch(
    `/${id}/avatar`,
    body
  );
  return data;
};

const getAvatar = async (id: string): Promise<IAPIResponse<string>> => {
  const { data } = await baseUserApi.client.instance.get(`/${id}/avatar`);

  return data;
};

export default {
  ...baseUserApi.actions,
  forgotPassword,
  resetPassword,
  updatePassword,
  getOneById,
  list,
  updateAvatar,
  getAvatar,
};
