export const ASSESSMENT_REQUEST_ENUMS = {
  STATUSES: [
    {
      label: 'RTQ',
      value: 'RTQ',
    },
    {
      label: 'USV',
      value: 'USV',
    },
    {
      label: 'RFS',
      value: 'RFS',
    },
  ],
  threeSixty: [
    {
      label: 'Done',
      value: 'DONE',
    },
    {
      label: 'Not found',
      value: 'NOT_FOUND',
    },
    {
      label: 'Not done',
      value: 'NOT_DONE',
    },
  ],
};
