import * as React from 'react';

import { DocumentQualificationModal } from '@core/components/modals';

import type { IDocumentEvaluation } from '@core/types';

import { Button, Box, EditIcon } from '@shared/components';

import { ReviewsDocumentStatusSwitch } from '.';

interface Props {
  assessmentRequestId: string;
  documentEvaluation: IDocumentEvaluation;
  onOpen: (id: string) => void;
  onClose: () => void;
  open: boolean;
}
const ReviewDocumentsLibraryActionsCell: React.FCC<Props> = ({
  assessmentRequestId,
  documentEvaluation,
  onOpen,
  onClose,
  open,
}) => {
  return (
    <>
      <Box display='flex' flexDirection='row'>
        <Box p={1} display='flex'>
          <Button
            id={`edit-document-${documentEvaluation.document.name}`}
            type='button'
            onClick={() => onOpen(documentEvaluation.id)}
          >
            <EditIcon />
          </Button>
        </Box>
        <Box p={1} display='flex'>
          <ReviewsDocumentStatusSwitch
            documentEvaluation={documentEvaluation}
          />
        </Box>
      </Box>
      {open && (
        <DocumentQualificationModal
          {...{ documentEvaluation, onClose, assessmentRequestId }}
        />
      )}
    </>
  );
};

export { ReviewDocumentsLibraryActionsCell };
