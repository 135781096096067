import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { PricingApi } from '@core/api';

import type {
  IAPIResponse,
  IPricing,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchPricingGrid = (): InteliamUseQueryResult<
  IAPIResponse<Array<IPricing>>,
  BaseAxiosErrorResponse
> & {
  pricingGrid: Array<IPricing> | undefined;
} => {
  const query = useQuery<IAPIResponse<Array<IPricing>>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.PRICING_GRID],
    () => PricingApi.getPricingGrid(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
    }
  );
  return {
    ...query,
    pricingGrid: query.data?.data,
  };
};

export default useFetchPricingGrid;
