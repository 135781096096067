import { Yup } from '@inteliam/foundation/lib/utils';

const create = Yup.object().shape({
  assessmentRequestId: Yup.string().required(),
  interactionDate: Yup.date().required(),
  eventSubject: Yup.string().required(),
  channel: Yup.string().required(),
  details: Yup.string().required(),
  mode: Yup.string().required(),
  contacts: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      email: Yup.string().optional(),
      phoneNumber: Yup.string().optional(),
    })
  ),
});

export { create };
