/* eslint-disable @inteliam/i18n/raw-text-detected */
import { datadogRum } from '@datadog/browser-rum';

import { showReportDialog } from '@sentry/react';
import * as Sentry from '@sentry/react';
import * as React from 'react';

import { Button, Typography, Box, HomeIcon } from '@shared/components';

import { BOErrorTracking, Constants } from '@shared/utils';

const initSentry = () => {
  Sentry.init({
    dsn: Constants.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release: `back-office@${process.env.npm_package_version ?? 'unknown'}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    sampleRate: 1,
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    environment: Constants.SENTRY_ENV,
    ignoreErrors: ['Failed to fetch dynamically imported module'],
  });
};

const initDataDog = () => {
  datadogRum.init({
    applicationId: 'a937befb-c4c9-4ab9-8d00-6ccad4de096c',
    clientToken: 'pubb54cc59b7e4ce501f84c249d37e0e72f',
    site: 'datadoghq.eu',
    service: 'back-office',
    env: Constants.SENTRY_ENV,
    version: Constants.APP_VERSION,
    sampleRate: 100,
    trackInteractions: true,
  });
};
const initTracking = (): void => {
  const environment =
    Constants.NODE_ENV === 'development' ? 'development' : 'production';
  const isDevelopment = environment === 'development';
  if (!isDevelopment && Constants.SENTRY_DSN) {
    initSentry();
  }
  if (['prod', 'test', 'UAT'].includes(Constants.SENTRY_ENV)) {
    initDataDog();
  }
};
interface Props {
  prop?: string;
}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean;
}

/**
 * We should reach this error boundary only if something went wrong in 18n/Theme/router providers
 * otherwise the error should be catched be UserBoundary
 */
class TechErrorBoundary extends React.Component<
  Props & { children?: React.ReactNode },
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount(): void {
    initTracking();
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    BOErrorTracking.report(error, 'TechErrorBoundary', errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          width='100vw'
          height='100vw'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Typography variant='h2'> Something went wrong </Typography>
          <Typography>
            <a href={'/'}>
              <HomeIcon />
            </a>
          </Typography>
          <Button
            id='report-error'
            className='mt-2'
            onClick={() => showReportDialog()}
            variant='outlined'
            color='secondary'
            style={{ position: 'fixed', bottom: 40, right: 0 }}
          >
            Report feedback
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default TechErrorBoundary;
