import { IARTransitionEnum } from '@inteliam/foundation/lib/enums';

import { default as analyze } from './analyze';
import { default as answer_survey } from './answer-survey';
import { default as review } from './review';
import { default as rta } from './rta';
import { default as score } from './score';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Dialogs: Partial<Record<IARTransitionEnum, any>> = {
  [IARTransitionEnum.ANSWER_SURVEY]: answer_survey,
  [IARTransitionEnum.REVIEW]: review,
  [IARTransitionEnum.SCORE]: score,
  [IARTransitionEnum.ANALYZE]: analyze,
  [IARTransitionEnum.RTA]: rta,
};

export default Dialogs;
