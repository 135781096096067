import { yupResolver } from '@hookform/resolvers/yup';
import {
  IARStatusRankEnum,
  IARTransitionEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { DateFormatter, Validations } from '@inteliam/foundation/lib/utils';
import { addBusinessDays } from 'date-fns';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { reuseARSchema } from '@core/components/modals/update-deadlines/schemas';

import { useAssessmentMachine } from '@core/hooks';

import { useFetchCampaignTypeByType } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Button, Controls, Grid, FormGroup } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';

interface Props {
  open: boolean;
  onClose: () => void;
  assessmentRequest: IAR;
}

interface ReuseForm {
  deadline: Date;
}

const SharingRequestModal: React.FCC<Props> = ({
  onClose,
  assessmentRequest,
  open,
}) => {
  const { t } = useEssentials();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });
  const { campaign } = assessmentRequest;
  const methods = useForm<ReuseForm>({
    resolver: yupResolver(reuseARSchema),
    defaultValues: {
      deadline: addBusinessDays(
        new Date(),
        campaign?.type?.defaultDeadlines?.sharingRequestDays ||
          campaignTypeQuery.data?.data.defaultDeadlines?.sharingRequestDays ||
          5
      ),
    },
  });
  const {
    transition: { run, mutation },
  } = useAssessmentMachine({
    assessmentRequest,
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',
  });
  const handleOnSubmit = (data: ReuseForm) => {
    run({
      transition: IARTransitionEnum.INITIATE_SURVEY, // should cascade to reuse
      body: {
        sharingRequestDeadline: data.deadline,
      },
    }).catch(() => {});
  };

  useOnUpdate(() => {
    if (mutation.status === 'success') {
      onClose();
    }
  }, [mutation.status, onClose]);
  useOnUpdate(() => {
    if (mutation.error?.response?.data) {
      Validations.setFormErrors({
        setError: (_, error) => methods.setError('deadline', error),
        errors: ValidationUtils.formatValidationErrors(
          mutation.error?.response?.data
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error?.response?.data, methods.setError]);

  return (
    <BaseDialog.Dialog keepMounted={false} open={open} onClose={onClose}>
      <Controls.Form methods={methods} submitHandler={handleOnSubmit}>
        <BaseDialog.Title
          id='modals.initiate_survey_reuse_case.title'
          onClose={onClose}
        >
          {t('Invite to questionnaire')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={12}>
              {t(
                'This Distributor has a valid scorecard, the system will initiate a Sharing Request with the enterprise if it does not already exist.'
              )}
            </Grid>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormDatePicker
                  inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
                  name={'deadline'}
                  label={t('Sharing request deadline')}
                  pickerType='date'
                  componentsProps={{
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-reuse'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={mutation.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-reuse'
            type='submit'
            color='primary'
            isLoading={mutation.isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(SharingRequestModal);
