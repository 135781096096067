import {
  IARActionsEnum,
  IDistributorStatusEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IRegistrationModeType, IRegistrationState } from '@core/types';

import { Button, Card, Box, CircularProgress, Chip } from '@shared/components';

import type { IActionItem } from '@shared/types';

interface Props {
  isLoading: boolean;
  actions: IActionItem<IARActionsEnum>[];
  onChangeAction: (action: IActionItem<IARActionsEnum>) => void;
  mode: IRegistrationModeType;
  company?: IRegistrationState['company'];
}

const RegistrationActions: React.FCC<Props> = ({
  isLoading,
  actions,
  onChangeAction,
  mode,
  company,
}) => {
  const { t } = useEssentials();
  const {
    formState: { isDirty },
  } = useFormContext();

  return (
    <Card.CContainer styled>
      <Box justifyContent='space-between' display='flex' p={2}>
        <Button
          id='cancel-ar-creation'
          disabled={isLoading}
          type='button'
          variant='contained'
          color='secondary'
        >
          {t('Cancel')}{' '}
          {isLoading && <CircularProgress color='inherit' size={16} />}
        </Button>
        <div>
          {company?.id && company?.status === IDistributorStatusEnum.DRAFT && (
            <Chip
              label={IDistributorStatusEnum.DRAFT}
              style={{ marginRight: 5 }}
            />
          )}
          {actions &&
            actions.map((action, index) => (
              <Button
                id={`submit-ar-creation-${index}`}
                key={index}
                type='submit'
                variant='contained'
                color='primary'
                disabled={
                  !action.enabled ||
                  isLoading ||
                  (!isDirty && mode === 'CREATE')
                }
                style={{ marginLeft: '10px' }}
                onClick={() => onChangeAction(action)}
              >
                {t(action.label)}
              </Button>
            ))}
        </div>
      </Box>
    </Card.CContainer>
  );
};

export default memo(RegistrationActions);
