import { ApiFactory } from '@inteliam/foundation/lib/api';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAnyCompany, JwtBOUser } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/companies';
type CompaniesActions = 'paginate';

function isCompany(arg: any): arg is IAnyCompany {
  return (
    typeof arg.id === 'string' &&
    typeof arg.name === 'string' &&
    typeof arg.kind === 'string'
  );
}

const baseCompanyApi = ApiFactory.generate<
  IAnyCompany,
  JwtBOUser,
  CompaniesActions
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isCompany,
});

export default {
  ...baseCompanyApi.actions,
};
