import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';
import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { FormControl } from '@mui/material';

import React, { memo } from 'react';

import { QUERY_KEYS, useManageTranslations } from '@core/queries';

import { TranslationStatusEnum } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { ITranslationPatchRequest } from '@core/types';

import { Switch } from '@shared/components';

import type { ITranslatableItem } from '@inteliam/foundation/lib/types';

interface Props {
  translationItem?: ITranslatableItem;
  modelId: ITranslationPatchRequest['modelId'];
  model: ITranslationPatchRequest['model'];
  field: ITranslationPatchRequest['field'];
  selectedLocale: ILanguagesEnum;
}

const TranslationStatusCell: React.FCC<Props> = ({
  translationItem,
  modelId,
  model,
  field,
  selectedLocale,
}) => {
  const { notify, INotifierEnum, t } = useEssentials();
  const queryClient = useQueryClient();
  const { patch } = useManageTranslations();
  const onToggleState = (newStatus: ITranslationPatchRequest['status']) => {
    // we only allow status update if we already added the translation
    if (!translationItem) {
      return notify({
        type: INotifierEnum.Error,
        message: t(
          'Please enter content in order to validate this translation'
        ),
      });
    }
    patch.run(
      {
        modelId,
        model,
        status: newStatus,
        locale: selectedLocale,
        field,
      },
      {
        onSuccess: () => {
          queryClient
            .invalidateQueries(QUERY_KEYS.TRANSLATIONS)
            .catch(() => {});
        },
      }
    );
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onToggleState(
      event.target.checked
        ? TranslationStatusEnum.VALIDATED
        : TranslationStatusEnum.INVALIDATED
    );
  };
  const disabled =
    !translationItem ||
    (translationItem?.content === '' &&
      translationItem?.status === TranslationStatusEnum.INVALIDATED);

  return (
    <FormControl fullWidth>
      <Switch
        id={'status'}
        checked={translationItem?.status === 'validated'}
        onChange={onChange}
        name={`status`}
        disabled={disabled}
      />
    </FormControl>
  );
};

export default memo(TranslationStatusCell);
