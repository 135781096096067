import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIReview } from '@core/guards';
import type {
  QuestionReviewLinkConfiguration,
  ReviewPayload,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  Id,
  IPatchObject,
  IReview,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/reviews';

type ReviewsActions = 'paginate' | 'patch' | 'getOneById';

const baseReviewsApi = ApiFactory.generate<IReview, JwtBOUser, ReviewsActions>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),

  guard: isIReview,
});

const getQuestionReviewLinkConfigurations = async (
  id: Id
): Promise<IAPIResponse<Array<QuestionReviewLinkConfiguration>>> => {
  const { data } = await baseReviewsApi.client.instance.get<
    IAPIResponse<Array<QuestionReviewLinkConfiguration>>
  >(`/${id}/question-review-link-configurations`);
  return data;
};

const create = async (payload: ReviewPayload): Promise<void> => {
  const { data } = await baseReviewsApi.client.instance.post<void>('', payload);
  return data;
};

const update = async ({
  body,
  id,
}: IPatchObject<ReviewPayload>): Promise<IAPIResponse<IReview>> => {
  const { data } = await baseReviewsApi.client.instance.put<
    IAPIResponse<IReview>
  >(`/${id}`, body);
  return data;
};

export default {
  ...baseReviewsApi.actions,
  getQuestionReviewLinkConfigurations,
  update,
  create,
};
