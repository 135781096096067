import * as React from 'react';

import { useScoreCardHandlers, useWorkingPage } from '@core/hooks';

import { Box, Controls } from '@shared/components';

import type { IPartialScore } from '@inteliam/foundation/lib/types';

interface Props {
  themePartialScore: IPartialScore;
  themeIndex: number;
}

const ConfigurableThemeScoring: React.FCC<Props> = ({
  themePartialScore,
  themeIndex,
}) => {
  const { methods: scoringMethods, onUpdate } = useScoreCardHandlers();
  const { scoreCard } = useWorkingPage();

  return (
    <Box marginLeft={5} width={50}>
      <Controls.FormCheckbox
        id={`${themePartialScore.theme.code}-theme-disabled`}
        name={'enabledThemeScoring'}
        label='N/A'
        defaultChecked={!themePartialScore.configuration?.enabledThemeScoring}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={(_, checked) => {
          // Reset
          scoringMethods.resetField('score');
          scoringMethods.resetField('weight');
          // Disable validation for score/weight
          scoringMethods.setValue('validateScore', false);
          scoringMethods.setValue('validateWeight', false);
          const patchPayload: Record<string, boolean | null | undefined> = {
            // eslint-disable-next-line unicorn/no-null
            [`partialScores[${themeIndex}].scoringMeta.score`]: null,
            // eslint-disable-next-line unicorn/no-null
            [`partialScores[${themeIndex}].scoringMeta.weight`]: null,
            [`partialScores[${themeIndex}].configuration.enabledThemeScoring`]:
              !checked,
            [`partialScores[${themeIndex}].configuration.scorableCriteria`]:
              checked ? false : undefined,
          };
          themePartialScore.criteriaPartialScores?.map((_, criterionIndex) => {
            patchPayload[
              `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].scoringMeta.score`
              // eslint-disable-next-line unicorn/no-null
            ] = null;
          });
          onUpdate(patchPayload, scoreCard.id);
          // re enable validation
          scoringMethods.setValue('validateScore', true);
          scoringMethods.setValue('validateWeight', true);
        }}
      />
    </Box>
  );
};

export default ConfigurableThemeScoring;
