/* eslint-disable unicorn/no-null */
import { Helpers } from '@inteliam/foundation/lib/utils';

import { IDiscountStatusesEnum, IDiscountTypesEnum } from '@core/enums';

import type {
  IDiscountForm,
  ICreateDiscountPayload,
  IPartialDiscount,
} from '@core/types';

import type { IAnyEnterprise } from '@inteliam/foundation/lib/types';

const DEFAULT_VALUE: IDiscountForm = {
  type: IDiscountTypesEnum.TYPE_PERCENTAGE,
  period: '',
  status: IDiscountStatusesEnum.STATUS_INACTIVE,
  percentage: 0,
};
export const normalizer = {
  initializeForm: (defaultValues?: IPartialDiscount): IDiscountForm => {
    if (defaultValues?.type === IDiscountTypesEnum.TYPE_AMOUNT)
      return {
        type: defaultValues.type,
        status: defaultValues?.status ?? IDiscountStatusesEnum.STATUS_INACTIVE,
        period: defaultValues?.period ?? '',
        price: {
          value: defaultValues?.price?.value ?? 0,
          currency: defaultValues?.price?.currency ?? '',
          unit: defaultValues?.price?.unit ?? 'M',
          decimals: defaultValues?.price?.decimals ?? 2,
        },
      };
    else if (defaultValues?.type === IDiscountTypesEnum.TYPE_PERCENTAGE)
      return {
        type: defaultValues.type,
        status: defaultValues?.status ?? IDiscountStatusesEnum.STATUS_INACTIVE,
        period: defaultValues?.period ?? '',
        percentage: defaultValues.percentage ?? 0,
      };
    return DEFAULT_VALUE;
  },
  formDataToPayload: (
    data: IDiscountForm,
    id: IAnyEnterprise['id']
  ): ICreateDiscountPayload => {
    if (data.type === IDiscountTypesEnum.TYPE_AMOUNT) {
      if (data.price?.value === undefined) {
        throw new Error('Price is required');
      }
      return {
        ...data,
        manufacturerId: id,
        price: {
          value: data.price?.value,
          currency: Helpers.ensureValueAsString(data?.price.currency),
          unit: 'M',
          decimals: 2,
        },
        // @ts-expect-error percentage
        percentage: null,
      };
    }
    return {
      ...data,
      percentage: data.percentage,
      manufacturerId: id,
      // @ts-expect-error price
      price: null,
    };
  },
};
