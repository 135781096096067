import { yupResolver } from '@hookform/resolvers/yup';

import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  Controls,
  Button,
  RouterLink,
  Grid,
  CircularProgress,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type { ILoginPayload } from '@inteliam/foundation/lib/types';

import { signinSchema as schema } from './schema';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SigninForm: React.FC<{
  onSubmit: (data: ILoginPayload) => void;
  isLoading: boolean;
}> = ({ onSubmit, isLoading }) => {
  const classes = useStyles();
  const methods = useForm<ILoginPayload>({
    resolver: yupResolver(schema),
  });
  const { t } = useEssentials();
  return (
    <Controls.Form
      submitHandler={onSubmit}
      className={classes.form}
      methods={methods}
    >
      <Controls.FormInput
        margin='normal'
        required
        fullWidth
        label={t('Email address')}
        name='username'
        autoFocus
      />
      <Controls.FormInput
        margin='normal'
        required
        fullWidth
        name='password'
        label={t('Password')}
        type='password'
        autoComplete='current-password'
      />
      <Button
        id='submit'
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
        disabled={isLoading}
      >
        {t('Sign In')}{' '}
        {isLoading && (
          <CircularProgress
            color='inherit'
            style={{ marginLeft: 5 }}
            size={18}
          />
        )}
      </Button>
      <Grid container>
        <Grid item xs>
          <RouterLink to='/forgot-password' id='forgot-password'>
            {t('I forgot my password')}
          </RouterLink>
        </Grid>
      </Grid>
    </Controls.Form>
  );
};

export default SigninForm;
