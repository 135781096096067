import { ApiFactory } from '@inteliam/foundation/lib/api';
import { IARTransitionEnum } from '@inteliam/foundation/lib/enums';
import { assertIsTyped, isIAR } from '@inteliam/foundation/lib/guards';
import { isNil } from 'lodash-es';

import type {
  IARNextActionForm,
  IARSurvey,
  IAssessmentRequestPayload,
  IAssignUserInput,
  IBOAssessmentRequest,
  IBusinessDevelopersView,
  ISurveyProgress,
} from '@core/types';

import { getAuthClientInstance } from '@shared/utils/auth-instance';
import * as Constants from '@shared/utils/constants';

import type {
  IAPIResponse,
  IAR,
  IARTransitionInput,
  IBaseInteliamSurvey,
  Id,
  IPaginationResponse,
  IPatchObject,
  IScoreCard,
  IUpdateAssessmentRequestContactsPayload,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type AssessmentRequestActions =
  | 'paginate'
  | 'getOneById'
  | 'create'
  | 'update'
  | 'patch'
  | 'remove';

function isIBOAssessmentRequest(arg: unknown): arg is IBOAssessmentRequest {
  return isIAR(arg);
}
const baseAssessmentRequestApi = ApiFactory.generate<
  IBOAssessmentRequest,
  JwtBOUser,
  AssessmentRequestActions
>({
  baseRoute: '/assessment-requests',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIBOAssessmentRequest,
});

const updateDeadlines = async (
  { body, id }: IPatchObject,
  check = false
): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.patch<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/deadlines?checkOnly=${check.toString()}`, body);
  return data;
};

const authorizeSurveySubmission = async ({
  body,
  id,
}: IPatchObject): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.post<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/authorize-survey-submission`, body);
  return data;
};

const extendDeadlines = async (
  { body, id }: IPatchObject,
  check = false
): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.patch<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/extend/deadlines?checkOnly=${check.toString()}`, body);
  return data;
};

const assignUser = async ({
  id,
  statusRank,
  userIds,
}: IAssignUserInput): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.patch<
    IAPIResponse<IBOAssessmentRequest>
  >(`/assign/${id}/${statusRank}`, userIds);

  return data;
};

const transition = (
  { action, body, id }: IARTransitionInput,
  check = false
): Promise<IAPIResponse> => {
  return baseAssessmentRequestApi.client.instance
    .patch<IAPIResponse>(
      `/go-to/${id}/${action}?checkOnly=${check.toString()}`,
      body
    )
    .then(({ data }) => data);
};

// TODO to be removed
const specificTransition = async ({
  body,
  action,
  id,
}: IARTransitionInput): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  if (
    [
      IARTransitionEnum.ANSWER_SURVEY,
      IARTransitionEnum.REVIEW,
      IARTransitionEnum.SCORE,
      IARTransitionEnum.ANALYZE,
      IARTransitionEnum.RTA,
    ].includes(action) === false
  ) {
    throw 'Action not supported';
  }
  const { data } = await baseAssessmentRequestApi.client.instance.patch<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/${action}`, body);
  return data;
};

const create = async ({
  action,
  ...rest
}: IAssessmentRequestPayload): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.post<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${action}`, rest);
  assertIsTyped(data.data, isIAR);

  return data;
};

const updateRegistration = async ({
  action,
  ...rest
}: IAssessmentRequestPayload): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const id = rest.scoredCompany?.id;
  if (isNil(id)) {
    throw new Error('companies.register.errors.cannot_update');
  }

  const { data } = await baseAssessmentRequestApi.client.instance.put<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/${action}`, rest);
  assertIsTyped(data.data, isIAR);

  return data;
};

const getAccessToScoreFile = async (id: Id): Promise<IAPIResponse<void>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<void>
  >(`/${id}/score-file`);
  return data;
};

const getActiveCountByScoredCompany = async (
  id: Id
): Promise<IAPIResponse<number>> => {
  const stringId = typeof id === 'number' ? id.toString() : id;
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<number>
  >(`/scored-company/${stringId}/active-count`);
  return data;
};

const getAllARsByCompany = async ({
  id,
  excludeQueuedAndReuse,
}: {
  id: string;
  excludeQueuedAndReuse: boolean;
}): Promise<IAPIResponse<Array<IBOAssessmentRequest>>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<Array<IBOAssessmentRequest>>
  >(
    `/${id}/company${
      excludeQueuedAndReuse ? '?excludeQueuedAndReuse=true' : ''
    }`
  );
  return data;
};

const updateARContacts = async (
  id: string,
  body: IUpdateAssessmentRequestContactsPayload
): Promise<IAPIResponse<IBOAssessmentRequest>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.patch<
    IAPIResponse<IBOAssessmentRequest>
  >(`/${id}/contacts`, {
    questionnaireContact: body.questionnaireContact.id,
    primaryContact: body.scoredCompany.primaryContact.id,
  });
  return data;
};

const getGlobalProgressByAR = async (
  id: string
): Promise<IAPIResponse<IARSurvey>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<IARSurvey>
  >(`/${id}/progress`);
  return data;
};

const reinviteToSurvey = async (id: Id): Promise<IAPIResponse> => {
  const { data } =
    await baseAssessmentRequestApi.client.instance.post<IAPIResponse>(
      `/${id}/reinvite-to-survey`
    );
  return data;
};

const getQuestionnaireProgress = async (
  id: Id
): Promise<IAPIResponse<ISurveyProgress>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<ISurveyProgress>
  >(`/${id}/questionnaire-progress`);
  return data;
};

const getSurvey = async (
  id: Id
): Promise<IAPIResponse<IBaseInteliamSurvey>> => {
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IAPIResponse<IBaseInteliamSurvey>
  >(`/${id}/questionnaire`);
  return data;
};

const getScoreCard = async (
  id: Id,
  refresh = false
): Promise<IAPIResponse<IScoreCard>> => {
  const stringId = typeof id === 'number' ? id.toString() : id;
  const { data } = await baseAssessmentRequestApi.client.instance.get(
    `/${stringId}/score-card?refresh=${refresh.toString()}`
  );
  return data;
};

const resetAutoSia = async (id: Id): Promise<IAPIResponse> => {
  const { data } =
    await baseAssessmentRequestApi.client.instance.patch<IAPIResponse>(
      `/${id}/reset-auto-sia`
    );

  return data;
};
const tag = async ({ body, id }: IPatchObject): Promise<IAPIResponse> => {
  const { data } =
    await baseAssessmentRequestApi.client.instance.patch<IAPIResponse>(
      `/${id}/tag`,
      body
    );

  return data;
};
const updateNextAction = async (
  body: IARNextActionForm & { id: IAR['id'] }
): Promise<IAPIResponse> => {
  const { data } =
    await baseAssessmentRequestApi.client.instance.post<IAPIResponse>(
      `/${body.id}/next-action`,
      {
        ...body,
      }
    );

  return data;
};

const getBusinessDevelopersView = async ({
  enterprise,
  campaigns,
  businessDevelopers,
  page,
  itemsPerPage,
}: {
  enterprise: string;
  campaigns?: string[];
  businessDevelopers?: string[];
  page: number;
  itemsPerPage: number;
}): Promise<IPaginationResponse<IBusinessDevelopersView>> => {
  const qs = new URLSearchParams({ enterprise: enterprise });
  if (campaigns?.length) {
    qs.append('campaigns', campaigns.join(','));
  }
  if (businessDevelopers?.length) {
    qs.append('businessDevelopers', businessDevelopers.join(','));
  }
  qs.append('page', page.toString());
  qs.append('itemsPerPage', itemsPerPage.toString());
  const { data } = await baseAssessmentRequestApi.client.instance.get<
    IPaginationResponse<IBusinessDevelopersView>
  >(`/business-developers-view?${qs.toString()}`);
  return data;
};

export default {
  ...baseAssessmentRequestApi.actions,
  getAccessToScoreFile,
  updateDeadlines,
  reinviteToSurvey,
  assignUser,
  transition,
  specificTransition,
  create,
  updateRegistration,
  getActiveCountByScoredCompany,
  getAllARsByCompany,
  updateARContacts,
  getGlobalProgressByAR,
  extendDeadlines,
  authorizeSurveySubmission,
  getQuestionnaireProgress,
  getSurvey,
  getScoreCard,
  resetAutoSia,
  tag,
  updateNextAction,
  getBusinessDevelopersView,
};
