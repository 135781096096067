/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PrivateApi } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import type { IAnswerEvaluationsResponse, IThemesInsights } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { IAPIResponse, Id } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/answer-evaluations';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getByARAndClassifications = async (
  id: Id,
  themeId: Id,
  criterionId?: Id
): Promise<IAnswerEvaluationsResponse> => {
  let pathArguments = `/${id}/${themeId}`;
  if (!isNil(criterionId)) pathArguments += `/${criterionId}`;
  const { data } = await client.instance.get<IAnswerEvaluationsResponse>(
    pathArguments
  );
  return data;
};

const getThemesInsightsByAR = async (
  id: Id
): Promise<IAPIResponse<IThemesInsights>> => {
  const { data } = await client.instance.get(`/${id}/themes-insights`);
  return data;
};

export default {
  getByARAndClassifications,
  getThemesInsightsByAR,
};
