import { CarbonStatus } from '@inteliam/foundation/lib/enums';

const i18nProxyHandler = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(target: object, propertyKey: PropertyKey, receiver?: any) {
    // eslint-disable-next-line prefer-rest-params
    const value = Reflect.get(target, propertyKey, receiver);
    if (value === undefined) return 'N/A';
    return value;
  },
};
export const CARBON_STATUS = new Proxy<Record<string, string>>(
  {
    [CarbonStatus.NOT_INVITED]: 'Not Invited',
    [CarbonStatus.INVITED]: 'Invited',
    [CarbonStatus.PUBLISHED]: 'published',
    [CarbonStatus.IN_PROGRESS]: 'In progress',
    [CarbonStatus.CANCELLED]: 'Cancelled',
  },
  i18nProxyHandler
);
