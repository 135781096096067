/* eslint-disable @inteliam/i18n/raw-text-detected */
import { FormatDate } from '@inteliam/foundation/lib/components';
import { DateFormatter } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { ContactLogUtils, UsersUtils } from '@core/utils';

import { useI18n } from '@core/contexts';

import {
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from '@shared/components';

import type { IAR, IPartialContactLog } from '@inteliam/foundation/lib/types';

const ContactLogHoverPreview: React.FCC<{
  assessmentRequest: IAR;
}> = ({ assessmentRequest }) => {
  const { t } = useI18n();
  const latestContactLogs = assessmentRequest?.contactLogs
    .sort((a: IPartialContactLog, b: IPartialContactLog): number => {
      if (
        a?.interactionDate !== undefined &&
        b?.interactionDate !== undefined
      ) {
        return a?.interactionDate?.valueOf() - b?.interactionDate?.valueOf();
      }

      return 0;
    })
    .slice(0, 3);
  return (
    <div>
      <Table size='medium'>
        <TableHead>
          <TableRow>
            <TableCell>{t('Interaction date')}</TableCell>
            <TableCell>{t('Event')}</TableCell>
            <TableCell>{t('Channel')}</TableCell>
            <TableCell>{t('In/Out')}</TableCell>
            <TableCell>{t('User')}</TableCell>
            <TableCell>{t('Contact person(s)')}</TableCell>
            <TableCell width={400}>{t('Details')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {latestContactLogs.map((contactLog, index) => (
            <TableRow key={index}>
              <TableCell component='th' scope='row'>
                <FormatDate
                  date={contactLog.interactionDate}
                  format={DateFormatter.FORMATS.fr_FR.SHORT}
                />
              </TableCell>
              <TableCell align='right'>{contactLog.eventSubject}</TableCell>
              <TableCell align='right'>{contactLog.channel}</TableCell>
              <TableCell align='right'>{contactLog.mode}</TableCell>
              <TableCell align='right'>
                {ContactLogUtils.isAutomatic(contactLog)
                  ? t('Automatic')
                  : UsersUtils.formatShortName(contactLog?.user?.contactInfo)}
              </TableCell>
              <TableCell align='right'>
                {contactLog?.contacts?.map((contactInfo, index) => {
                  return (
                    <span key={index}>
                      {UsersUtils.formatShortName(contactInfo)}
                    </span>
                  );
                })}
              </TableCell>
              <TableCell align='left'>
                <div
                  style={{
                    textOverflow: 'ellipsis',
                    lineClamp: 3,
                    WebkitLineClamp: 3,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    width: '400px',
                  }}
                >
                  {contactLog.details}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ContactLogHoverPreview;
