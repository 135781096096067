/* eslint-disable @inteliam/i18n/raw-text-detected */
import {
  IFilterTypeEnum,
  IReviewKindEnum,
} from '@inteliam/foundation/lib/enums';
import { useModalState, useQueryClient } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';
import {
  AddCircle as AddCircleIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@mui/icons-material';
import { Alert, Paper, Table, TableBody } from '@mui/material';

import * as React from 'react';

import { ManageSwMatchingModal } from '@core/components/modals';

import { QUERY_KEYS, useManageArReviews } from '@core/queries';

import { useEssentials, useWorkingPageContext } from '@core/contexts';
import { isITranslatableField } from '@core/guards';
import type {
  IAnswerEvaluation,
  IEvaluationProgress,
  IEvaluationQuery,
} from '@core/types';

import {
  Box,
  Button,
  CenteredSpinner,
  Datatable,
  EditableTextInput,
  IconButton,
  TooltippedCell,
} from '@shared/components';

import { useInViewport } from '@shared/hooks';

import type { IFilterableColumn } from '@shared/types';

import type { IAnyAssessmentReview } from '@inteliam/foundation/lib/types';

import { ANSWER_ANALYSIS_RENDERER } from '../internals';

interface Props {
  assessmentRequestId: string;
  themeId: string;
  query: IEvaluationQuery;
  answerEvaluation: IAnswerEvaluation;
  referenceProgress?: Record<string, IEvaluationProgress>;
}

const QuestionItem: React.FCC<Props> = ({
  assessmentRequestId,
  themeId,
  query,
  answerEvaluation,
  referenceProgress,
}) => {
  const { t } = useEssentials();
  const { page } = useWorkingPageContext();
  const { patch, remove, paginateByEvaluation } = useManageArReviews({
    assessmentRequestId,
    answerEvaluationId: answerEvaluation.id,
  });
  const assessmentReviewResponse = paginateByEvaluation.query.data;
  const [modalState, handlers] = useModalState();
  const queryClient = useQueryClient();
  const columns = React.useMemo<Array<IFilterableColumn<IAnyAssessmentReview>>>(
    () => [
      {
        field: 'Actions',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        width: 100,
        hide: page === 'DOCUMENTS_ANALYSIS',
        renderCell: (parameters) => {
          return (
            <IconButton
              id='remove-sw'
              onClick={() => remove.run(parameters.row.id)}
              size='large'
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          );
        },
      },
      {
        field: 'description.defaultValue',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Description'),
        flex: 1,
        renderCell: (parameters) => {
          if (parameters.row.kind === IReviewKindEnum.Strength) {
            return (
              <TooltippedCell
                title={I18n.getTranslatedValue(
                  parameters.row.strength.description
                )}
              >
                [{parameters.row.kind?.charAt(0)}] {':'}
                {I18n.getTranslatedValue(parameters.row.strength.description)}
              </TooltippedCell>
            );
          }

          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(
                parameters.row.weakness.description
              )}
            >
              [{parameters.row.kind?.charAt(0)}] {':'}
              {I18n.getTranslatedValue(parameters.row.weakness.description)}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'amendment.defaultValue',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Second part'),
        editable: true,
        renderEditCell: (parameters) => <EditableTextInput {...parameters} />,
        valueGetter: (parameters) => {
          return isITranslatableField(parameters.row.amendment)
            ? parameters.row.amendment?.defaultValue
            : '';
        },
        flex: 1,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(parameters.row.amendment)}
            >
              {I18n.getTranslatedValue(parameters.row.amendment)}
            </TooltippedCell>
          );
        },
      },
    ],
    [remove, t, page]
  );

  const Component =
    ANSWER_ANALYSIS_RENDERER[answerEvaluation.answer.field.kind];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const swSectionRef = React.useRef<any>();
  // Lazy load AR reviews
  useInViewport({
    target: swSectionRef,
    props: {
      onEnterViewport: () => {
        paginateByEvaluation.query.refetch().catch(() => {});
      },
    },
  });

  return (
    <Box padding={1}>
      <Paper elevation={3} style={{ padding: '1em' }}>
        <Table stickyHeader size='small' id='answer-analysis-table'>
          <TableBody>
            <Component
              answerEvaluation={answerEvaluation}
              assessmentRequestId={assessmentRequestId}
              query={query}
              referencesProgress={referenceProgress}
            />
          </TableBody>
        </Table>
        <div ref={swSectionRef}>
          <div hidden={page === 'DOCUMENTS_ANALYSIS'}>
            <Box display='flex' flexDirection='row' padding={1}>
              <Button
                id='add-sw'
                type='button'
                onClick={() => handlers.onOpenUpdate(answerEvaluation.id)}
                variant='contained'
                color='primary'
              >
                <AddCircleIcon />
                {t('ADD S/IA')}
              </Button>
            </Box>
          </div>
          {paginateByEvaluation.query.isSettled ? (
            <>
              {assessmentReviewResponse &&
              assessmentReviewResponse?.meta.total > 0 ? (
                <Datatable.Container
                  queryKey={[
                    ...QUERY_KEYS.AR_REVIEWS_LIST(assessmentRequestId),
                    answerEvaluation.id,
                    paginateByEvaluation.query.isProcessing,
                  ]}
                  columns={columns}
                  fetcher={() => Promise.resolve(assessmentReviewResponse)}
                >
                  <Datatable.Table<IAnyAssessmentReview>
                    checkboxSelection={false}
                    onCellEditStop={(parameters, event) => {
                      patch.run(
                        {
                          [parameters.field]: event.target.value,
                        },
                        parameters.id as string
                      );
                    }}
                    rowHeight={80}
                    autoHeight
                  />
                </Datatable.Container>
              ) : (
                <Alert color='success' icon={false}>
                  {t('No data available at this moment')}
                </Alert>
              )}
            </>
          ) : (
            <CenteredSpinner />
          )}

          {modalState.data === answerEvaluation.id && (
            <ManageSwMatchingModal
              open={modalState.data === answerEvaluation.id}
              onClose={() => {
                handlers.onOpenUpdate(undefined);
                queryClient
                  .invalidateQueries([
                    ...QUERY_KEYS.ANSWER_EVALUATIONS,
                    assessmentRequestId,
                    themeId,
                  ])
                  .catch(() => {});
              }}
              answerEvaluation={answerEvaluation}
              assessmentRequestId={assessmentRequestId}
              referencesProgress={referenceProgress}
            />
          )}
        </div>
      </Paper>
    </Box>
  );
};

export default React.memo(QuestionItem);
