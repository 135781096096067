import React from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { ISustainabilityCardForm } from '@core/types';

import { Box, Button, Controls, FormGroup, Grid } from '@shared/components';

import type { IDynamicOptionItem } from '@inteliam/foundation/lib/types';

const START_YEAR = 2021;

interface Props {
  onSubmit: (data: ISustainabilityCardForm) => void;
  onClose: () => void;
  isUpdating?: boolean;
  alreadyDeclaredYears?: Array<number>;
  defaultValues?: ISustainabilityCardForm;
  isLoading: boolean;
}

const SustainabilityCardForm: React.FC<Props> = ({
  onSubmit,
  onClose,
  isUpdating = false,
  alreadyDeclaredYears = [],
  defaultValues,
  isLoading,
}) => {
  const { t } = useEssentials();
  const methods = useForm<ISustainabilityCardForm>({
    defaultValues: defaultValues || { year: undefined, report: undefined },
  });

  return (
    <Controls.Form submitHandler={onSubmit} methods={methods}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormSelect
              label={t('Year')}
              name='year'
              required={true}
              readOnly={isUpdating}
              options={[
                ...Array.from({
                  length: new Date().getFullYear() - START_YEAR + 1,
                }).keys(),
              ]
                .map((year) => year + START_YEAR)
                .filter((year) => !alreadyDeclaredYears.includes(year))
                .map((year) => {
                  return {
                    label: String(year),
                    value: year,
                  } as IDynamicOptionItem;
                })}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormDropzoneArea
              label={t('Upload an enterprise sustainability report')}
              name='report'
              filesLimit={1}
              acceptedFiles={['application/pdf']}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
        <Button
          id='close-enterprise-sustainability-card-modal'
          onClick={onClose}
          type='button'
          color='primary'
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-enterprise-sustainability-card'
          type='submit'
          color='primary'
          isLoading={isLoading}
        >
          {t('Validate')}
        </Button>
      </Box>
    </Controls.Form>
  );
};

export default SustainabilityCardForm;
