import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type { ITranslationPatchRequest } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/translations';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const patch = async ({
  modelId,
  ...body
}: ITranslationPatchRequest): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch<IAPIResponse>(
    `/${modelId}`,
    body
  );
  return data;
};

export default {
  patch,
};
