import { Helpers } from '@inteliam/foundation/lib/utils';
import {
  CheckboxProps,
  FormLabel,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { ErrorHelpText, Grid, FormGroup } from '@shared/components';

interface OptionItem {
  label: React.ReactNode;
  value: string | number | boolean;
}

// TODO Default values does not with this component, problem for Future us
const FormCheckboxGroup: React.FCC<
  CheckboxProps & {
    name: string;
    helperText?: string;
    label: string;
    options: OptionItem[];
  }
> = (props) => {
  const { t } = useEssentials();
  const { helperText, name, label, options, ...rest } = props;
  const {
    register,

    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormGroup>
      <Grid container>
        <FormLabel component='legend'>{label}</FormLabel>
        {options.map((option, index) => {
          const { onChange: rhfOnchange, ...rhfProps } = register(
            Helpers.arrayDotNotation(name, index)
          );

          return (
            <Grid container key={`${name}-${option.value.toString()}`}>
              <FormControlLabel
                id={`${name}-${option.value.toString()}`}
                control={
                  <Checkbox
                    {...rest}
                    data-cy={`${name}-${option.value.toString()}`}
                    value={option.value}
                    {...rhfProps}
                    onChange={(event, checked) => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      rhfOnchange(event);
                      if (rest.onChange) {
                        rest.onChange(event, checked);
                      }
                    }}
                  />
                }
                label={option.label}
              />
            </Grid>
          );
        })}
      </Grid>
      {(errorMessage || helperText) && (
        <ErrorHelpText>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormGroup>
  );
};
export default memo(FormCheckboxGroup);
