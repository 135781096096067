import { yupResolver } from '@hookform/resolvers/yup';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useFetchFrontOfficeUsers } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type {
  IAssignBusinessDevelopersFavoriteForm,
  IChangeScope,
} from '@core/types';

import { Button, Controls, FormGroup, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IAR,
  IFilterCondition,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import { IAssignBusinessDevelopersFavoriteSchema } from './schemas';

interface Props {
  open: boolean;
  isLoading: boolean;
  defaultValues?: IAssignBusinessDevelopersFavoriteForm;
  assessmentRequest?: IAR;
  errors?: ValidationErrorResponse;
  onClose: () => void;
  onSubmit: (data: IChangeScope) => void;
}

const INITIAL_DEFAULT_VALUES: IAssignBusinessDevelopersFavoriteForm = {
  businessDevelopers: [],
};

const FavoriteForm: React.FCC<Props> = ({
  onClose,
  defaultValues,
  assessmentRequest,
  open,
  onSubmit,
  errors,
  isLoading,
}) => {
  const { t } = useEssentials();
  const methods = useForm<IAssignBusinessDevelopersFavoriteForm>({
    defaultValues: defaultValues
      ? { ...defaultValues }
      : { ...INITIAL_DEFAULT_VALUES },
    resolver: yupResolver(IAssignBusinessDevelopersFavoriteSchema),
    shouldUnregister: true,
  });

  const [previousSelection, setPreviousSelection] = useState<Array<string>>(
    defaultValues?.businessDevelopers || []
  );
  const [changeScope, setChangeScope] = useState<IChangeScope>({
    additions: [],
    deletions: [],
  });
  const conditions: Array<IFilterCondition> = [
    {
      column: 'kind',
      operator: 'in',
      value: 'SUPPLIER,TRADING_GROUP',
    },
    {
      column: 'status',
      operator: 'eq',
      value: 'ENABLED',
    },
  ];

  if (assessmentRequest?.campaign?.owner) {
    conditions.push({
      column: 'company.id',
      operator: 'eq',
      value: assessmentRequest?.campaign.owner.id,
    });
  }

  const { users, isSettled } = useFetchFrontOfficeUsers({
    conditions: conditions,
  });

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: (name, error) =>
          methods.setError(
            name as keyof IAssignBusinessDevelopersFavoriteForm,
            error
          ),
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, methods.setError]);

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <Controls.Form
        methods={methods}
        submitHandler={() => onSubmit(changeScope)}
      >
        <BaseDialog.Title id='BDs Assignment' onClose={onClose}>
          {t('Assign BDs to this Distributor')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              {isSettled && (
                <FormGroup>
                  <Controls.FormSelect
                    fullWidth
                    multiple={true}
                    name='businessDevelopers'
                    label={t('Business Developers')}
                    options={users.map((user) => {
                      return {
                        label: `${user?.contactInfo?.firstName || ''} ${
                          user?.contactInfo?.lastName || ''
                        }`,
                        value: user?.id || '',
                      };
                    })}
                    onChange={(event) => {
                      const currentSelection = event.target
                        .value as Array<string>;
                      let diff: string | undefined = undefined;
                      if (currentSelection.length > previousSelection.length) {
                        diff = currentSelection
                          .filter((x) => !previousSelection.includes(x))
                          .at(0);
                        if (diff) {
                          setChangeScope({
                            ...changeScope,
                            additions: [...changeScope.additions, diff],
                          });
                          setPreviousSelection(currentSelection);
                        }
                      }
                      if (currentSelection.length < previousSelection.length) {
                        diff = previousSelection
                          .filter((x) => !currentSelection.includes(x))
                          .at(0);
                        if (!isNil(diff)) {
                          if (changeScope.additions.includes(diff)) {
                            setChangeScope({
                              ...changeScope,
                              additions: changeScope.additions.filter(
                                (x) => x !== diff
                              ),
                            });
                          } else {
                            setChangeScope({
                              ...changeScope,
                              deletions: [...changeScope.deletions, diff],
                            });
                          }
                          setPreviousSelection(currentSelection);
                        }
                      }
                    }}
                  />
                </FormGroup>
              )}
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-update-favorites'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-update-next-action'
            type='submit'
            color='primary'
            isLoading={isLoading}
            disabled={isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(FavoriteForm);
