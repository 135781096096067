import {
  IBaseUserRoles,
  IBORolesEnum,
  ICompanyKindEnum,
  IEnterpriseVisibilityEnum,
  ISupplierRolesEnum,
  ITradingGroupRolesEnum,
} from '@inteliam/foundation/lib/enums';
import {
  AddressSchema,
  ContactInfoSchema,
} from '@inteliam/foundation/lib/schemas';
import { Yup } from '@inteliam/foundation/lib/utils';

import { UsersUtils } from '@core/utils';

import { IDiscountTypesEnum } from '@core/enums';

import { ErrorCodesLookup } from '@shared/utils';

import type { IBOUser, JwtBOUser } from '@inteliam/foundation/lib/types';

export const userSchema = Yup.object().shape({
  contactInfo: ContactInfoSchema,
  roles: Yup.array().of(
    Yup.string()
      .oneOf(
        [
          ...Object.values(ISupplierRolesEnum),
          ...Object.values(ITradingGroupRolesEnum),
          IBaseUserRoles.ROLE_FO_USER,
          IBaseUserRoles.ROLE_USER,
        ],
        ErrorCodesLookup.E0001.title
      )
      .required(ErrorCodesLookup.E0001.title)
  ),
});

export const update = Yup.object().shape({
  kind: Yup.string().oneOf([
    ICompanyKindEnum.SUPPLIER,
    ICompanyKindEnum.TRADING_GROUP,
  ]),
  level: Yup.number().when('kind', {
    is: ICompanyKindEnum.TRADING_GROUP,
    then: Yup.number().oneOf([0, 1, 2]).required(),
    otherwise: Yup.number().nullable().optional(),
  }),
  parent: Yup.object().when(['kind', 'level'], {
    is: (kind: string, level: string) =>
      kind === ICompanyKindEnum.TRADING_GROUP && Number.parseFloat(level) !== 0,
    then: Yup.object().shape({
      id: Yup.string().required(),
    }),
    otherwise: Yup.object().nullable().optional(),
  }),
  name: Yup.string().required(ErrorCodesLookup.E0001.title),
  vat: Yup.string().optional(),
  dunsIdentifier: Yup.string()
    .matches(/^\d{9}$/, {
      message: 'DUNS should be a 9-digit number, Please enter a correct value',
      excludeEmptyString: true,
    })
    .nullable(),
  address: AddressSchema,
  // turnover: TurnoverSchema,
  // TODO it needs to be fixed on the react basics after adding value/userValue
  // option 1 add userValue to the schema instead of value
  // option 2 pass errorPathName for FormInput/FormSelect ...
  website: Yup.string()
    .website(ErrorCodesLookup.E0006.title)
    .required(ErrorCodesLookup.E0001.title),
  subscription: Yup.string().required(ErrorCodesLookup.E0001.title),
});

export const discountSchema = Yup.object().shape({
  status: Yup.string().required(ErrorCodesLookup.E0001.title),
  type: Yup.string().required(ErrorCodesLookup.E0001.title),
  price: Yup.object()
    .shape({
      value: Yup.number(),
      currency: Yup.string(),
    })
    .when('type', {
      is: IDiscountTypesEnum.TYPE_AMOUNT,
      then: Yup.object()
        .shape({
          value: Yup.number()
            .required(ErrorCodesLookup.E0001.title)
            .positive(ErrorCodesLookup.E0007.title),
          currency: Yup.string().required(ErrorCodesLookup.E0001.title),
        })
        .required(ErrorCodesLookup.E0001.title),
      otherwise: Yup.object().nullable().optional(),
    }),
  percentage: Yup.number().when('type', {
    is: IDiscountTypesEnum.TYPE_PERCENTAGE,
    then: Yup.number()
      .required(ErrorCodesLookup.E0001.title)
      .positive(ErrorCodesLookup.E0007.title)
      .max(100, 'Discount cannot be higher than 100%'),
  }),
  period: Yup.string().required(ErrorCodesLookup.E0001.title),
});

export const domainsSchema = (user: IBOUser | JwtBOUser): Yup.AnyObjectSchema =>
  Yup.object().when('configuration.enterpriseAccess', {
    is: true,
    then: Yup.object().shape({
      domainNames: UsersUtils.isGranted(IBORolesEnum.ROLE_SUPER_ADMIN, user)
        ? Yup.array().optional()
        : Yup.array()
            .min(1, ErrorCodesLookup.E0001.title)
            .required(ErrorCodesLookup.E0001.title),
      authorizationFiles: Yup.array().when('domainNames', {
        is: (domainNames: Array<string>) => domainNames.length > 0,
        then: Yup.array()
          .min(1, ErrorCodesLookup.E0001.title)
          .required(ErrorCodesLookup.E0001.title),
        otherwise: Yup.array().optional(),
      }),
    }),
    otherwise: Yup.object().optional(),
  });
export const create = (user: IBOUser | JwtBOUser): Yup.AnyObjectSchema =>
  update
    .shape({
      configuration: Yup.object().shape({
        enterpriseAccess: Yup.boolean(),
        directoryVisibility: Yup.string().oneOf(
          Object.values(IEnterpriseVisibilityEnum)
        ),
      }),
      users: Yup.array().when('configuration.enterpriseAccess', {
        is: true,
        then: Yup.array().min(1).of(userSchema),
        otherwise: Yup.array().optional(),
      }),
    })
    .concat(domainsSchema(user));
