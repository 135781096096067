import { DateFormatter, I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  useMutation,
  useAllLanguages,
  useFetchDocumentScopeLevels,
} from '@core/queries';

import { DocumentQualificationUtils } from '@core/utils';

import { DocumentsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IDocumentEvaluation } from '@core/types';

import {
  Button,
  Card,
  Controls,
  Grid,
  Box,
  FormGroup,
  CircularProgress,
  Divider,
} from '@shared/components';

import type {
  IAPIResponse,
  IPartialDocument,
  IPatchObject,
  BaseAxiosErrorResponse,
  IDocument,
} from '@inteliam/foundation/lib/types';

interface Props {
  defaultValues: IDocumentEvaluation;
  id: string;
  onUpdateSuccess?: () => void;
}

type IDocumentForm = Omit<IPartialDocument, 'lockedBy' | 'createdBy'>;

const DocumentQualificationForm: React.FCC<Props> = ({
  defaultValues,
  id,
  onUpdateSuccess,
}) => {
  const { t } = useEssentials();
  const { languages } = useAllLanguages();
  const { formattedScopeLevels } = useFetchDocumentScopeLevels();

  const methods = useForm<IDocumentForm>({
    defaultValues: DocumentQualificationUtils.initializeDocumentQualification(
      defaultValues.document
    ),
  });
  const watchedScopeLevel = useWatch({
    control: methods.control,
    name: 'qualificationData.scopeLevel',
  });
  const watchedPerpetual = useWatch({
    control: methods.control,
    name: 'qualificationData.perpetual',
  });

  const mutation = useMutation<
    IAPIResponse,
    BaseAxiosErrorResponse,
    IPatchObject
  >(DocumentsApi.patch);

  return (
    <Box justifyContent='flex-end'>
      <Controls.Form
        autoComplete='off'
        noValidate
        methods={methods}
        submitHandler={(body) => {
          const payload: Partial<IDocument> = {
            languageCode: body.languageCode,
            qualificationData: body.qualificationData,
          };
          mutation.mutate(
            { id, body: payload },
            {
              onSuccess: () => {
                onUpdateSuccess?.();
              },
            }
          );
        }}
      >
        <Card.CContainer styled>
          <Card.Header title={t('Doc qualification')} />
          <Divider />
          <Card.Body>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('FO Doc Name')}
                    name='name'
                    disabled
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('BO Doc Name')}
                    name='qualificationData.name'
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={5}>
                <FormGroup>
                  <Controls.FormDatePicker
                    label={t('Issue Date')}
                    name='qualificationData.issueDate'
                    inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
                    pickerType='date'
                    disabled={watchedPerpetual}
                    textFieldProps={{
                      style: { display: watchedPerpetual ? 'none' : 'block' },
                    }}
                  />
                  <Controls.FormDatePicker
                    label={t('Issue Date')}
                    name='_qualificationData.issueDate'
                    inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
                    pickerType='date'
                    disabled={watchedPerpetual}
                    textFieldProps={{
                      style: { display: watchedPerpetual ? 'block' : 'none' },
                    }}
                    defaultValue={''}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={5}>
                <FormGroup>
                  <Controls.FormDatePicker
                    label={t('Valid until')}
                    name='qualificationData.validUntil'
                    inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
                    pickerType='date'
                    disabled={watchedPerpetual}
                    textFieldProps={{
                      style: { display: watchedPerpetual ? 'none' : 'block' },
                    }}
                  />
                  <Controls.FormDatePicker
                    label={t('Valid until')}
                    name='_qualificationData.validUntil'
                    inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
                    pickerType='date'
                    disabled={watchedPerpetual}
                    textFieldProps={{
                      style: { display: watchedPerpetual ? 'block' : 'none' },
                    }}
                    defaultValue={''}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
                <FormGroup>
                  <Controls.FormCheckbox
                    label={t('No dates')}
                    name='qualificationData.perpetual'
                  />
                </FormGroup>
              </Grid>
              <Grid item md={4} xs={12}>
                <FormGroup>
                  <Controls.FormSelect
                    label={t('Scope Level')}
                    name='qualificationData.scopeLevel'
                    options={formattedScopeLevels}
                  />
                </FormGroup>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('Scope Name')}
                    name='qualificationData.scopeName'
                    readOnly={watchedScopeLevel === 'L0'}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <Controls.FormSelect
                    label={t('Language')}
                    name='languageCode'
                    options={I18n.mapLanguageIntoOptionItem(languages)}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
              <Button
                id='submit'
                color='primary'
                variant='contained'
                type='submit'
                disabled={mutation.isLoading}
              >
                {t('Save')}
                {mutation.isLoading && (
                  <CircularProgress color='inherit' size={16} />
                )}
              </Button>
            </Box>
          </Card.Body>
          <Divider />
        </Card.CContainer>
      </Controls.Form>
    </Box>
  );
};

export default DocumentQualificationForm;
