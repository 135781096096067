import React, { memo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useEssentials } from '@core/contexts';

interface Props {
  onMove: (indexA: number, indexB: number) => void;
  children: (options: {
    getItemStyle: (
      isDragging: boolean,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      draggableStyle: any
    ) => React.CSSProperties;
  }) => JSX.Element;
}

const DndProvider: React.FC<Props> = ({ onMove, children }) => {
  const { theme } = useEssentials();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    // change background colour if dragging
    opacity: isDragging ? 0.8 : 1,
    background: isDragging ? `${theme.palette.primary.main}` : '',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver
      ? `1px dashed ${theme.palette.primary.main}`
      : 'transparent',
  });
  return (
    <DragDropContext
      onDragEnd={(result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
        onMove(result.source.index, result.destination.index);
      }}
    >
      <Droppable droppableId='droppable'>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            style={getListStyle(droppableSnapshot.isDraggingOver)}
            {...droppableProvided.droppableProps}
          >
            {children({ getItemStyle })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(DndProvider);
