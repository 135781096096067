import {
  IDistributorRolesEnum,
  ISupplierRolesEnum,
  ITradingGroupRolesEnum,
} from '@inteliam/foundation/lib/enums';

type ISupplierRolePreset = {
  [key in ISupplierRolesEnum]: {
    value: ISupplierRolesEnum;
    label: string;
    tag: 'ENTERPRISE_MANAGER';
  };
};

type ITradingGroupRolePreset = {
  [key in ITradingGroupRolesEnum]: {
    value: ITradingGroupRolesEnum;
    label: string;
    tag: 'ENTERPRISE_MANAGER';
  };
};

export const SUPPLIER_ROLES: ISupplierRolePreset = {
  [ISupplierRolesEnum.ROLE_RISK_MANAGER]: {
    value: ISupplierRolesEnum.ROLE_RISK_MANAGER,
    label: 'Risk manager',
    tag: 'ENTERPRISE_MANAGER',
  },
  [ISupplierRolesEnum.ROLE_SALES_MANAGER]: {
    value: ISupplierRolesEnum.ROLE_SALES_MANAGER,
    label: 'Sales manager',
    tag: 'ENTERPRISE_MANAGER',
  },
};
export const TRADING_GROUP_ROLES: ITradingGroupRolePreset = {
  [ITradingGroupRolesEnum.ROLE_RISK_MANAGER]: {
    value: ITradingGroupRolesEnum.ROLE_RISK_MANAGER,
    label: 'Risk manager',
    tag: 'ENTERPRISE_MANAGER',
  },
  [ITradingGroupRolesEnum.ROLE_SALES_MANAGER]: {
    value: ITradingGroupRolesEnum.ROLE_SALES_MANAGER,
    label: 'Sales manager',
    tag: 'ENTERPRISE_MANAGER',
  },
};

type IDistributorRolePreset = {
  [key in IDistributorRolesEnum]: {
    value: IDistributorRolesEnum;
    label: string;
    tag: 'DISTRIBUTOR_STANDARD' | 'DISTRIBUTOR_LIMITED';
  };
};
export const DISTRIBUTOR_ROLES: IDistributorRolePreset = {
  [IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD]: {
    value: IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD,
    label: 'Standard',
    tag: 'DISTRIBUTOR_STANDARD',
  },
  [IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED]: {
    value: IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED,
    label: 'Limited',
    tag: 'DISTRIBUTOR_LIMITED',
  },
};
