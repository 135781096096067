import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIAnyMedia } from '@core/guards';
import type { IAnyMedia, IMediaPayload } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type MediaActions = 'paginate' | 'create' | 'remove';

const baseApi = ApiFactory.generate<
  IAnyMedia,
  JwtBOUser,
  MediaActions,
  IMediaPayload
>({
  baseRoute: '/media',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIAnyMedia,
});

export default {
  ...baseApi.actions,
};
