import React, { memo } from 'react';

import { INotifierEnum, useEssentials } from '@core/contexts';

import {
  AlternateEmailIcon,
  Box,
  Grid,
  LocalPhoneIcon,
  ContentCopyIcon,
} from '@shared/components';

import { FormatterUtils, makeStyles } from '@shared/utils';
import { FullNameFormatEnum } from '@shared/utils/formatter';

import type { IAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

const COPIED_TO_CLIPBOARD_MESSAGE = 'Copied to Clipboard!';
const useStyles = makeStyles((theme) => ({
  infoLine: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
  },
  copyIcon: {
    verticalAlign: 'middle',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.anchor?.main,
    textDecoration: 'underline',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const ContactInfoModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const { t, notify } = useEssentials();

  return (
    <BaseDialog.Dialog {...{ onClose, open }} maxWidth='md'>
      <BaseDialog.Title id='show-contact-details' onClose={onClose}>
        <span>
          <strong>{t('Contact Details')}</strong>
        </span>
      </BaseDialog.Title>
      <BaseDialog.Content>
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='center'
          alignItems='center'
          padding={3}
        >
          <Grid container spacing={2}>
            <Grid item md={12}>
              <strong className='mr-2'>{t('Primary Contact')}:</strong>
              <span>
                {FormatterUtils.formatFullName(
                  assessmentRequest.scoredCompany?.primaryContact?.contactInfo,
                  FullNameFormatEnum.JOHN_DOE
                )}
              </span>
            </Grid>
            <Grid item md={12} className={classes.infoLine}>
              <LocalPhoneIcon className={classes.icon} />:{' '}
              <a
                className={classes.link}
                href={`tel:${
                  assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                    ?.phoneNumber || ''
                }`}
              >
                {
                  assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                    ?.phoneNumber
                }
              </a>
              <ContentCopyIcon
                titleAccess={t('Copy phone number to clipboard')}
                className={classes.copyIcon}
                onClick={() => {
                  if (
                    assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                      ?.phoneNumber
                  ) {
                    navigator.clipboard
                      .writeText(
                        assessmentRequest.scoredCompany?.primaryContact
                          ?.contactInfo?.phoneNumber
                      )
                      .catch(() => {});
                    notify({
                      message: t(COPIED_TO_CLIPBOARD_MESSAGE),
                      type: INotifierEnum.Info,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item md={12} className={classes.infoLine}>
              <AlternateEmailIcon className={classes.icon} />:{' '}
              <a
                className={classes.link}
                href={`mailto:${
                  assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                    ?.email || ''
                }`}
              >
                {
                  assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                    ?.email
                }
              </a>
              <ContentCopyIcon
                titleAccess={t('Copy email address to clipboard')}
                className={classes.copyIcon}
                onClick={() => {
                  if (
                    assessmentRequest.scoredCompany?.primaryContact?.contactInfo
                      ?.email
                  ) {
                    navigator.clipboard
                      .writeText(
                        assessmentRequest.scoredCompany?.primaryContact
                          ?.contactInfo?.email
                      )
                      .catch(() => {});
                    notify({
                      message: t(COPIED_TO_CLIPBOARD_MESSAGE),
                      type: INotifierEnum.Info,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item md={12}>
              <strong className='mr-2'>{t('Questionnaire Contact')}:</strong>
              <span>
                {FormatterUtils.formatFullName(
                  assessmentRequest.questionnaireContact?.contactInfo,
                  FullNameFormatEnum.JOHN_DOE
                )}
              </span>
            </Grid>
            <Grid item md={12} className={classes.infoLine}>
              <LocalPhoneIcon className={classes.icon} />:{' '}
              <a
                className={classes.link}
                href={`tel:${
                  assessmentRequest.questionnaireContact?.contactInfo
                    ?.phoneNumber || ''
                }`}
              >
                {
                  assessmentRequest.questionnaireContact?.contactInfo
                    ?.phoneNumber
                }
              </a>
              <ContentCopyIcon
                titleAccess={t('Copy phone number to clipboard')}
                className={classes.copyIcon}
                onClick={() => {
                  if (
                    assessmentRequest.questionnaireContact?.contactInfo
                      ?.phoneNumber
                  ) {
                    navigator.clipboard
                      .writeText(
                        assessmentRequest.questionnaireContact.contactInfo
                          ?.phoneNumber
                      )
                      .catch(() => {});
                    notify({
                      message: t(COPIED_TO_CLIPBOARD_MESSAGE),
                      type: INotifierEnum.Info,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item md={12} className={classes.infoLine}>
              <AlternateEmailIcon className={classes.icon} />:{' '}
              <a
                className={classes.link}
                href={`mailto:${
                  assessmentRequest.questionnaireContact?.contactInfo?.email ||
                  ''
                }`}
              >
                {assessmentRequest.questionnaireContact?.contactInfo?.email}
              </a>
              <ContentCopyIcon
                titleAccess={t('Copy email address to clipboard')}
                className={classes.copyIcon}
                onClick={() => {
                  if (
                    assessmentRequest.questionnaireContact?.contactInfo?.email
                  ) {
                    navigator.clipboard
                      .writeText(
                        assessmentRequest.questionnaireContact?.contactInfo
                          ?.email
                      )
                      .catch(() => {});
                    notify({
                      message: t(COPIED_TO_CLIPBOARD_MESSAGE),
                      type: INotifierEnum.Info,
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(ContactInfoModal);
