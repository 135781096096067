import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const extendSurveyDeadlineSchema = Yup.object().shape({
  questionnaireDeadline: Yup.date()
    .required(ErrorCodesLookup.E0001.title)
    .min(new Date(), 'The deadline should never be in the past'),
  distributorUsers: Yup.array()
    .nullable()
    .min(1, 'Please select at least one option'),
  manufacturerUsers: Yup.array().nullable(),
});
