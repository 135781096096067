import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isToDo } from '@core/guards';
import type { IBusinessDevelopersFollowUp } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { IAPIResponse, JwtBOUser } from '@inteliam/foundation/lib/types';

type TodoActions = 'paginate' | 'patch';

const baseTodosApi = ApiFactory.generate<
  IBusinessDevelopersFollowUp,
  JwtBOUser,
  TodoActions
>({
  baseRoute: '/todos',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isToDo,
});

const countMine = async (): Promise<IAPIResponse<{ count: number }>> => {
  const { data } = await baseTodosApi.client.instance.get<
    IAPIResponse<{ count: number }>
  >('/count-mine');
  return data;
};

export default {
  ...baseTodosApi.actions,
  countMine,
};
