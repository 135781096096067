/* eslint-disable unicorn/no-null */
import { PriceUtils } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import { IDiscountTypesEnum } from '@core/enums';

import type {
  ISubscriptionStatusEntry,
  ISubscriptionStatusEntryFormData,
} from '@core/types';

import type {
  IDiscountData,
  IPrice,
  IPricing,
  PricingCategory,
} from '@inteliam/foundation/lib/types';

const DEFAULT_DISCOUNT: IDiscountData = {
  type: IDiscountTypesEnum.TYPE_AMOUNT,
  period: '',
  enabled: false,
  price: {
    value: 0,
    currency: 'EUR',
    unit: 'M',
    decimals: 2,
  },
};
const DEFAULT_VALUES: ISubscriptionStatusEntryFormData = {
  subscriptionType: {
    id: '',
  },
  comments: '',
  invoiceStatus: '',
  date: new Date(),
  inteliamDiscounts: {
    '1_YEAR': DEFAULT_DISCOUNT,
    '3_YEAR': DEFAULT_DISCOUNT,
  },
  manufacturerDiscounts: {
    '1_YEAR': DEFAULT_DISCOUNT,
    '3_YEAR': DEFAULT_DISCOUNT,
  },
};
export const normalizer = {
  initializeForm: (
    defaultValues?: Partial<ISubscriptionStatusEntry>
  ): Partial<ISubscriptionStatusEntryFormData> => {
    if (!defaultValues) return DEFAULT_VALUES;
    return {
      ...defaultValues,
      inteliamDiscount: defaultValues.inteliamDiscount || DEFAULT_DISCOUNT,
      manufacturerDiscount:
        defaultValues.manufacturerDiscount || DEFAULT_DISCOUNT,
      manufacturerDiscountEnabled: !!defaultValues.manufacturerDiscount,
      inteliamDiscountEnabled: !!defaultValues.inteliamDiscount,
    };
  },
  formDataToPayload: (
    formData: ISubscriptionStatusEntryFormData
  ): Partial<ISubscriptionStatusEntry> => {
    const inteliamDiscount = formData.inteliamDiscounts?.['1_YEAR']?.enabled
      ? formData.inteliamDiscounts?.['1_YEAR']
      : undefined;
    const manufacturerDiscount = formData.manufacturerDiscounts?.['1_YEAR']
      ?.enabled
      ? formData.manufacturerDiscounts?.['1_YEAR']
      : undefined;
    const inteliamDiscount3YearsSubscription = formData.inteliamDiscounts?.[
      '3_YEAR'
    ]?.enabled
      ? formData.inteliamDiscounts?.['3_YEAR']
      : undefined;
    const manufacturerDiscount3YearsSubscription = formData
      .manufacturerDiscounts?.['3_YEAR']?.enabled
      ? formData.manufacturerDiscounts?.['3_YEAR']
      : undefined;
    return omit(
      {
        ...formData,
        inteliamDiscount,
        manufacturerDiscount,
        inteliamDiscount3YearsSubscription,
        manufacturerDiscount3YearsSubscription,
      },
      [
        'manufacturerDiscountEnabled',
        'inteliamDiscountEnabled',
        'subscriptionType',
      ]
    );
  },
};

export const getFormattedDiscount = (discount?: IDiscountData): string => {
  if (discount) {
    if (discount.type === IDiscountTypesEnum.TYPE_PERCENTAGE)
      return `${discount.percentage}%`;
    else if (discount.price) {
      return `${discount.price?.value as number}${PriceUtils.getCurrencySign(
        discount.price?.currency
      )}`;
    }
  }
  return '0';
};

export const getSubscriptionPrice = (
  pricingGrid: Array<IPricing> | undefined,
  category: PricingCategory,
  type?: 'SUBSCRIPTION_STANDARD_1_YEAR' | 'SUBSCRIPTION_STANDARD_3_YEAR',
  countryClassification?: number // TODO
): IPrice | undefined => {
  return type
    ? pricingGrid?.find(
        (pricing) =>
          pricing.pricingCategory.id === category &&
          pricing.subscriptionType.id === type &&
          pricing.countryClassification === countryClassification
      )?.price
    : pricingGrid?.find((pricing) => pricing.pricingCategory.id === category)
        ?.price;
};

export const getOneYearDiscount = (
  manufacturerDiscount?: IDiscountData,
  inteliamDiscount?: IDiscountData
): { value: number; type: IDiscountTypesEnum } => {
  if (manufacturerDiscount?.enabled && inteliamDiscount?.enabled) {
    if (
      manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
      inteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
    ) {
      return {
        value: manufacturerDiscount?.percentage + inteliamDiscount?.percentage,
        type: IDiscountTypesEnum.TYPE_PERCENTAGE,
      };
    } else if (
      manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_AMOUNT &&
      inteliamDiscount?.type === IDiscountTypesEnum.TYPE_AMOUNT
    ) {
      return {
        value:
          (manufacturerDiscount?.price.value ?? 0) +
          (inteliamDiscount?.price.value ?? 0),
        type: IDiscountTypesEnum.TYPE_AMOUNT,
      };
    } else {
      return {
        value: 0,
        type: IDiscountTypesEnum.TYPE_AMOUNT,
      };
    }
  } else if (manufacturerDiscount?.enabled) {
    return manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
      ? {
          value: manufacturerDiscount?.percentage,
          type: IDiscountTypesEnum.TYPE_PERCENTAGE,
        }
      : {
          value: manufacturerDiscount?.price?.value ?? 0,
          type: IDiscountTypesEnum.TYPE_AMOUNT,
        };
  } else if (inteliamDiscount?.enabled) {
    return inteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
      ? {
          value: inteliamDiscount?.percentage,
          type: IDiscountTypesEnum.TYPE_PERCENTAGE,
        }
      : {
          value: inteliamDiscount?.price.value ?? 0,
          type: IDiscountTypesEnum.TYPE_AMOUNT,
        };
  } else {
    return {
      value: 0,
      type: IDiscountTypesEnum.TYPE_PERCENTAGE,
    };
  }
};

export const getThreeYearsDiscount = (
  manufacturerDiscount?: IDiscountData,
  inteliamDiscount?: IDiscountData
): { value: number; type: IDiscountTypesEnum } => {
  if (manufacturerDiscount?.enabled && inteliamDiscount?.enabled) {
    if (
      manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
      inteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
    ) {
      return {
        value:
          manufacturerDiscount?.percentage + inteliamDiscount?.percentage || 25,
        type: IDiscountTypesEnum.TYPE_PERCENTAGE,
      };
    } else if (
      manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_AMOUNT &&
      inteliamDiscount?.type === IDiscountTypesEnum.TYPE_AMOUNT
    ) {
      return {
        value:
          (manufacturerDiscount?.price.value ?? 0) +
          (inteliamDiscount?.price.value ?? 0),
        type: IDiscountTypesEnum.TYPE_AMOUNT,
      };
    } else {
      return {
        value: 25,
        type: IDiscountTypesEnum.TYPE_AMOUNT,
      };
    }
  } else if (manufacturerDiscount?.enabled) {
    return manufacturerDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
      ? {
          value: manufacturerDiscount?.percentage,
          type: IDiscountTypesEnum.TYPE_PERCENTAGE,
        }
      : {
          value: manufacturerDiscount?.price?.value ?? 0,
          type: IDiscountTypesEnum.TYPE_AMOUNT,
        };
  } else if (inteliamDiscount?.enabled) {
    return inteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE
      ? {
          value: inteliamDiscount?.percentage || 25,
          type: IDiscountTypesEnum.TYPE_PERCENTAGE,
        }
      : {
          value: inteliamDiscount?.price?.value ?? 0,
          type: IDiscountTypesEnum.TYPE_AMOUNT,
        };
  } else {
    return {
      value: 0,
      type: IDiscountTypesEnum.TYPE_PERCENTAGE,
    };
  }
};

export const applyDiscount = (
  basePrice: number,
  discount: { value: number; type: IDiscountTypesEnum }
): number => {
  if (discount.type === IDiscountTypesEnum.TYPE_PERCENTAGE) {
    return Math.round((basePrice * (100 - discount.value)) / 100);
  }

  return Math.round(basePrice - discount.value);
};
