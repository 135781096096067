import { SharingRequestStatusEnum } from '@inteliam/foundation/lib/enums';

const TransitionsMatrix = {
  [SharingRequestStatusEnum.ACCEPTED]: [
    SharingRequestStatusEnum.DECLINED,
    SharingRequestStatusEnum.REQUESTED,
  ],
  [SharingRequestStatusEnum.DECLINED]: [
    SharingRequestStatusEnum.ACCEPTED,
    SharingRequestStatusEnum.REQUESTED,
  ],
  [SharingRequestStatusEnum.QUEUED]: [
    SharingRequestStatusEnum.ACCEPTED,
    SharingRequestStatusEnum.DECLINED,
  ],
  [SharingRequestStatusEnum.REQUESTED]: [
    SharingRequestStatusEnum.ACCEPTED,
    SharingRequestStatusEnum.DECLINED,
  ],
};

export const getPossibleTransitions = ({
  current,
}: {
  current: SharingRequestStatusEnum;
}): SharingRequestStatusEnum[] => {
  return TransitionsMatrix[current];
};
