import React, { memo } from 'react';

import {
  OptionsScoringConfiguration,
  QuestionScoringConfiguration,
} from '../common';

const SingleChoiceField: React.FCC = () => {
  return (
    <QuestionScoringConfiguration>
      <OptionsScoringConfiguration />
    </QuestionScoringConfiguration>
  );
};

export default memo(SingleChoiceField);
