import { AnswerValueFormatEnum } from '@inteliam/foundation/lib/enums';
import { Tooltip } from '@mui/material';
import { isNil } from 'lodash-es';

import * as React from 'react';

import { useEssentials } from '@core/contexts';

import { Button, Popover, Box } from '@shared/components';

import type { IAnswerTextBoxValue } from '@inteliam/foundation/lib/types';

import AnswerValueDate from './answer-value-date';
import AnswerValueLongText from './answer-value-long-text';
import AnswerValueMonetary from './answer-value-monetary';
import AnswerValueNumerical from './answer-value-numerical';
import AnswerValueText from './answer-value-text';

export const TEXTBOX_VALUE_RENDERER: Record<
  AnswerValueFormatEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.ComponentType<any>
> = {
  [AnswerValueFormatEnum.FORMAT_MONETARY]: AnswerValueMonetary,
  [AnswerValueFormatEnum.FORMAT_DATE]: AnswerValueDate,
  [AnswerValueFormatEnum.FORMAT_NUMERICAL]: AnswerValueNumerical,
  [AnswerValueFormatEnum.FORMAT_SHORT_TEXT]: AnswerValueText,
  [AnswerValueFormatEnum.FORMAT_LONG_TEXT]: AnswerValueLongText,
};

type Props = {
  format: AnswerValueFormatEnum;
  value: IAnswerTextBoxValue | undefined;
};

const TextBoxValueRenderer: React.FCC<Props> = ({ format, value }) => {
  const { t } = useEssentials();
  const Component = TEXTBOX_VALUE_RENDERER[format];
  function hasValue() {
    switch (value?.format) {
      case AnswerValueFormatEnum.FORMAT_DATE: {
        return !isNil(value?.date);
      }
      case AnswerValueFormatEnum.FORMAT_MONETARY: {
        return !isNil(value?.amount);
      }
      case AnswerValueFormatEnum.FORMAT_NUMERICAL: {
        return !isNil(value?.value);
      }
      case AnswerValueFormatEnum.FORMAT_SHORT_TEXT: {
        return !isNil(value?.text);
      }
      case AnswerValueFormatEnum.FORMAT_LONG_TEXT: {
        return !isNil(value?.text);
      }
      default: {
        return false;
      }
    }
  }
  return (
    <div
      className='truncate'
      style={{
        width: 'inherit',
      }}
    >
      {hasValue() && (
        <Popover.Container>
          <Popover.Trigger component={Tooltip} title={t(' Textbox')}>
            <Button id='open-textbox'>{t(' Textbox')}</Button>
          </Popover.Trigger>
          <Popover.Content>
            <Box padding={2}>
              <Component value={value} />
            </Box>
          </Popover.Content>
        </Popover.Container>
      )}
    </div>
  );
};

export default TextBoxValueRenderer;
