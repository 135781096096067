import { IARTransitionEnum } from '@inteliam/foundation/lib/enums';
import { useOnMount } from '@inteliam/foundation/lib/hooks';

import React, { memo } from 'react';

import { useAssessmentMachine } from '@core/hooks';

import { useEssentials } from '@core/contexts';

import { Noop } from '@shared/components';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

const QueueModal: React.FCC<Props> = (props) => {
  const { confirm, t } = useEssentials();
  const { transition } = useAssessmentMachine({
    assessmentRequest: props.assessmentRequest,
    dashboard: 'ENGAGEMENT',
  });

  const runQueue = async () => {
    try {
      await confirm({
        description: t(
          'This Distributor has already an AR in progress. This AR will go to Queue?'
        ),
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      transition.run({
        confirm: false,
        transition: IARTransitionEnum.INITIATE_SURVEY, // Should cascade to queue
        body: {},
      });
      props.onClose();
    } catch {
      props.onClose();
    }
  };

  useOnMount(() => {
    void runQueue();
  });

  // Queue only we'll show the confirm dialog using confirm hook without any actual UI from the component itself
  return <Noop data-testid='modals.initiate_survey_queued_case' />;
};

export default memo(QueueModal);
