// eslint-disable-next-line no-unused-vars
import { noop } from 'lodash-es';

import * as React from 'react';

export const defaultOptions = {};
export const defaultConfig = { disconnectOnLeave: false };
export const defaultProps = {
  onEnterViewport: noop,
  onLeaveViewport: noop,
};

type Args = {
  target: React.MutableRefObject<Element>;
  options?: IntersectionObserverInit;
  config?: {
    disconnectOnLeave: boolean;
  };
  props?: {
    onEnterViewport?: VoidFunction;
    onLeaveViewport?: VoidFunction;
  };
};

type Result = {
  inViewport: boolean;
  enterCount: number;
  leaveCount: number;
};

const useInViewport = ({
  props = defaultProps,
  options,
  target,
  config = defaultConfig,
}: Args): Result => {
  const { onEnterViewport, onLeaveViewport } = props;
  const [, forceUpdate] = React.useState<boolean>();

  const observer = React.useRef<IntersectionObserver>();

  const inViewportRef = React.useRef(false);
  const intersected = React.useRef(false);

  const enterCountRef = React.useRef(0);
  const leaveCountRef = React.useRef(0);

  const startObserver = React.useCallback(
    (observerRef: IntersectionObserver) => {
      const targetRef = target.current;
      if (targetRef) {
        observerRef?.observe(targetRef);
      }
    },
    [target]
  );

  const stopObserver = React.useCallback(
    (observerRef: IntersectionObserver) => {
      const targetRef = target.current;
      if (targetRef) {
        observerRef?.unobserve(targetRef);
      }

      observerRef?.disconnect();
      observer.current = undefined;
    },
    [target]
  );

  const handleIntersection: IntersectionObserverCallback = React.useCallback(
    (entries) => {
      const entry = entries[0] || {};
      const { isIntersecting, intersectionRatio } = entry;
      const isInViewport =
        isIntersecting === undefined ? intersectionRatio > 0 : isIntersecting;

      // enter
      if (!intersected.current && isInViewport) {
        intersected.current = true;
        onEnterViewport && onEnterViewport();
        enterCountRef.current += 1;
        inViewportRef.current = isInViewport;
        forceUpdate(isInViewport);
        return;
      }

      // leave
      if (intersected.current && !isInViewport) {
        intersected.current = false;
        onLeaveViewport && onLeaveViewport();
        if (config.disconnectOnLeave && observer.current) {
          // disconnect obsever on leave
          observer.current.disconnect();
        }
        leaveCountRef.current += 1;
        inViewportRef.current = isInViewport;
        forceUpdate(isInViewport);
      }
    },
    [config.disconnectOnLeave, onEnterViewport, onLeaveViewport]
  );

  const initIntersectionObserver = React.useCallback(
    (observerRef?: IntersectionObserver) => {
      if (!observerRef) {
        // Maybe polyfil is needed here
        observer.current = new IntersectionObserver(
          handleIntersection,
          options
        );
        return observer.current;
      }
      return observerRef;
    },
    [handleIntersection, options]
  );

  React.useEffect(() => {
    let observerRef = observer.current;
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    if (!observerRef) observerRef = initIntersectionObserver(observerRef);

    if (observerRef) startObserver(observerRef);

    return () => {
      if (observerRef) stopObserver(observerRef);
    };
  }, [
    options,
    config,
    onEnterViewport,
    onLeaveViewport,
    initIntersectionObserver,
    startObserver,
    stopObserver,
  ]);

  return {
    inViewport: inViewportRef.current,
    enterCount: enterCountRef.current,
    leaveCount: leaveCountRef.current,
  };
};

export default useInViewport;
