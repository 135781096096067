import { isNil, omit } from 'lodash-es';

import { RegistrationsUtils } from '@core/utils';

import type { IBODistributor, IDistributorForm } from '@core/types';

export const normalize = {
  formDataToPayload: (data: IDistributorForm): Partial<IBODistributor> => {
    const memberOf = [...data.memberOfHierarchy].reverse().find(Boolean); // find non-null leaf value
    const integrationIds: Record<string, string> = {};
    if (!isNil(data.integrationIdsArray)) {
      data.integrationIdsArray.forEach((object) => {
        integrationIds[object.id] = object.value;
      });
    }

    return omit(
      {
        ...data,
        integrationIds: integrationIds,
        memberOf: memberOf
          ? {
              id: memberOf,
            }
          : // if set to undefined, the browser will omit it in the serialization before sending the request,
            // null(kept by the browser) means that we want to reset the value to its "empty" state
            // eslint-disable-next-line unicorn/no-null
            null,
      },
      ['memberOfHierarchy', 'integrationIdsArray']
    );
  },
};

export const DEFAULT_DISTRIBUTOR: Partial<IDistributorForm> = {
  ...RegistrationsUtils.DEFAULT_COMPANY,
  size: '',
  services: '',
  nationalIdentifier: '',
  headquarters: {
    name: '',
    country: undefined,
  },
  numberOfEmployees: 0,
  category: '',
  memberOfHierarchy: [undefined, undefined, undefined],
};
