import {
  assertIsTypedArray,
  isIOptionItem,
} from '@inteliam/foundation/lib/guards';
import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  CompanyKindType,
  IAPIResponse,
  IOptionItem,
} from '@inteliam/foundation/lib/types';

const privateClient = new PrivateApi(
  {
    baseRoute: '/search',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);
const searchCompanies = async ({
  field,
  value,
  countryCode,
  kind,
  includeDraft = false,
  level = undefined,
}: {
  field: string;
  value: string;
  countryCode?: string;
  kind: CompanyKindType | Array<CompanyKindType>;
  includeDraft?: boolean;
  level?: number;
}): Promise<IOptionItem[]> => {
  const { data } = await privateClient.instance.post<
    IAPIResponse<Array<IOptionItem>>
  >(
    `/companies/?includeDraft=${Number(includeDraft)}&level=${level ?? 'ALL'}`,
    {
      field,
      value,
      countryCode,
      kind,
    }
  );

  assertIsTypedArray(data.data, isIOptionItem);
  return data.data || [];
};

const searchUsers = async ({
  field,
  value,
  kind,
}: {
  field: string;
  value: string;
  kind: 'DISTRIBUTOR' | 'SUPPLIER' | 'TRADING_GROUP';
}) => {
  const { data } = await privateClient.instance.post<
    IAPIResponse<Array<IOptionItem>>
  >(`/users`, {
    field,
    value,
    kind,
  });

  assertIsTypedArray(data.data, isIOptionItem);
  return data.data || [];
};

export default {
  searchCompanies,
  searchUsers,
};
