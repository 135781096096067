import {
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import * as React from 'react';

import { useMutation } from '@core/queries';

import { ResourcesApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import type {
  BaseAxiosErrorResponse,
  IResource,
} from '@inteliam/foundation/lib/types';

type Options = {
  createFunction?: (data: File[]) => Promise<IResource[]>;
  onSuccess?: (data: IResource[]) => void;
  onError?: (error: BaseAxiosErrorResponse) => void;
};

type Handlers = {
  uploadOne: (file: File) => Promise<IResource>;
  uploadMany: (files: Array<File>) => Promise<Array<IResource>>;
};

const useResources = ({
  onError,
  onSuccess,
  createFunction = ResourcesApi.create,
}: Options = {}): {
  handlers: Handlers;
  mutation: InteliamUseMutationResult<
    IResource[],
    BaseAxiosErrorResponse<unknown, unknown, unknown>,
    File[],
    unknown
  >;
} => {
  const { notify, INotifierEnum, t } = useEssentials();
  const mutation = useMutation<IResource[], BaseAxiosErrorResponse, File[]>(
    createFunction,
    {
      ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
      onSuccess: (data) => {
        onSuccess?.(data);
      },
      onError: (error) => {
        onError?.(error);
        notify({
          message: t(
            error.response?.data.message || 'An unknown error has occurred.'
          ),
          type: INotifierEnum.Error,
        });
      },
    }
  );

  const run = React.useCallback(
    async (data: File[]) => {
      return mutation.mutateAsync(data);
    },
    [mutation]
  );

  const uploadOne: Handlers['uploadOne'] = React.useCallback(
    async (file) => {
      const [resource] = await run([file]);
      return resource;
    },
    [run]
  );
  const uploadMany: Handlers['uploadMany'] = React.useCallback(
    async (files) => {
      return files.length > 0 ? await run(files) : [];
    },
    [run]
  );

  const handlers = React.useMemo(
    () => ({
      uploadOne,
      uploadMany,
    }),
    [uploadMany, uploadOne]
  );
  return {
    handlers,
    mutation,
  };
};
export default useResources;
