import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { enhancedYupResolver } from '@inteliam/foundation/lib/utils';

import React, { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { DistributorContactsManagementForm } from '@core/components/users';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { AssessmentRequestsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';

import { Button, Grid } from '@shared/components';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IAR,
  IUpdateAssessmentRequestContactsPayload,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';
import updateContactsSchema from './schema';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}
const ManageARUsersModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { t, notify, confirm } = useEssentials();

  const primaryContactMethods =
    useForm<IUpdateAssessmentRequestContactsPayload>({
      defaultValues: {
        scoredCompany: {
          primaryContact: {
            id: assessmentRequest.scoredCompany.primaryContact?.id || '',
          },
        },
      },
      resolver: enhancedYupResolver(
        updateContactsSchema,
        undefined,
        undefined,
        true,
        ['questionnaireContact.id']
      ),
    });

  const surveyContactMethods = useForm<IUpdateAssessmentRequestContactsPayload>(
    {
      defaultValues: {
        questionnaireContact: {
          id: assessmentRequest.questionnaireContact?.id || '',
        },
      },
      resolver: enhancedYupResolver(
        updateContactsSchema,
        undefined,
        undefined,
        true,
        ['scoredCompany.primaryContact.id']
      ),
    }
  );

  const mutation = useMutation<
    IAPIResponse<IAR>,
    BaseAxiosErrorResponse,
    IUpdateAssessmentRequestContactsPayload
  >(
    (body) =>
      AssessmentRequestsApi.updateARContacts(assessmentRequest.id, body),
    {
      keysToInvalidateOnSuccess: [
        QUERY_KEYS.COMPANY_USERS_LIST(
          ICompanyKindEnum.DISTRIBUTOR,
          assessmentRequest.scoredCompany.id
        ),
      ],
      onSuccess: () => {
        notify({
          message: t('Assessment request contacts successfully updated'),
          type: INotifierEnum.Success,
        });
        onClose();
      },
      onError: (error) => {
        notify({
          message: t(
            error?.response?.data.message ||
              'There was a problem while updating the assessment request contacts'
          ),
          type: INotifierEnum.Error,
        });
      },
    }
  );

  const handleOnSubmit = useCallback(() => {
    const isPrimaryContactUpdated =
      primaryContactMethods.getValues().scoredCompany?.primaryContact?.id !==
      assessmentRequest.scoredCompany.primaryContact?.id;
    const isSurveyContactUpdated =
      surveyContactMethods.getValues().questionnaireContact?.id !==
      assessmentRequest.questionnaireContact?.id;

    if (isPrimaryContactUpdated || isSurveyContactUpdated) {
      const message =
        isPrimaryContactUpdated && isSurveyContactUpdated
          ? t(
              'The primary will no longer be associated to the user "{{primaryFullName}}" and the questionnaire contact for this assessment request will no longer be associated to the user "{{questionnaireContactFullName}}"',
              {
                primaryFullName: UsersUtils.formatShortName(
                  assessmentRequest.scoredCompany.primaryContact?.contactInfo
                ),
                questionnaireContactFullName: UsersUtils.formatShortName(
                  assessmentRequest.questionnaireContact?.contactInfo
                ),
              }
            )
          : t(
              isPrimaryContactUpdated
                ? 'The primary will no longer be associated to the user "{{fullName}}"'
                : 'The questionnaire contact will no longer be associated to the user "{{fullName}}"',
              {
                fullName: UsersUtils.formatShortName(
                  isPrimaryContactUpdated
                    ? assessmentRequest.scoredCompany.primaryContact
                        ?.contactInfo
                    : assessmentRequest.questionnaireContact?.contactInfo
                ),
              }
            );
      confirm({
        description: message,
      })
        .then(() => {
          mutation.mutate({
            scoredCompany: primaryContactMethods.getValues().scoredCompany,
            questionnaireContact:
              surveyContactMethods.getValues().questionnaireContact,
          });
        })
        .catch(() => {});
    } else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mutation,
    primaryContactMethods,
    surveyContactMethods,
    t,
    assessmentRequest,
    confirm,
  ]);
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Users Management')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <DistributorContactsManagementForm
          {...{
            primaryContactMethods,
            surveyContactMethods,
            assessmentRequest,
          }}
        />
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-manage-ar-users'
          onClick={onClose}
          type='button'
          color='primary'
          disabled={mutation.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-manage-ar-users'
          type='submit'
          color='primary'
          isLoading={mutation.isLoading}
          onClick={handleOnSubmit}
        >
          {t('Validate')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(ManageARUsersModal);
