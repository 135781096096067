import React, { memo } from 'react';

import { EvaluationStatusFilter } from '@core/components';

import { useFetchReferencesEvaluationGlobalInsights } from '@core/queries';

import type { IEvaluationQuery } from '@core/types';

import { Grid } from '@shared/components';

interface Props {
  assessmentRequestId: string;
  setQuery: (query: IEvaluationQuery) => void;
  query: IEvaluationQuery;
}

const ReferencesAnalysisInsightsBar: React.FCC<Props> = ({
  assessmentRequestId,
  query,
  setQuery,
}) => {
  const { data: referencesEvaluationProgress } =
    useFetchReferencesEvaluationGlobalInsights({ arId: assessmentRequestId });

  return (
    <Grid item xs={12}>
      {query && (
        <EvaluationStatusFilter
          {...{ query, setQuery }}
          progress={referencesEvaluationProgress?.data}
        />
      )}
    </Grid>
  );
};

export default memo(ReferencesAnalysisInsightsBar);
