import React, { memo } from 'react';

import { CampaignForm } from '@core/components/campaigns';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { CampaignsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { ICampaignFormData } from '@core/types';

import { Noop } from '@shared/components';

import type {
  IAPIResponse,
  ICampaign,
  IPatchObject,
  AxiosValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

interface Props {
  campaign: ICampaign;
  open: boolean;
  onClose: () => void;
}
const UpdateCampaign: React.FCC<Props> = ({ campaign, open, onClose }) => {
  const { t, notify } = useEssentials();

  const mutation = useMutation<
    IAPIResponse<ICampaign>,
    AxiosValidationErrorResponse,
    IPatchObject<ICampaignFormData>
  >(CampaignsApi.update, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.CAMPAIGNS_LIST],
    onSuccess: (data) => {
      notify({
        message: t('Campaign "{{name}}" successfully updated', {
          name: data.data?.name,
        }),
        type: INotifierEnum.Success,
      });
      onClose();
    },
    onError: (error) => {
      notify({
        message: t(
          error?.response?.data.message || 'Unable to update the campaign'
        ),
        type: INotifierEnum.Error,
      });
    },
  });

  if (!open) {
    return <Noop />;
  }
  return (
    <CampaignForm
      open={open}
      onClose={onClose}
      onSubmit={(body) => {
        const payload: ICampaignFormData = {
          name: body.name,
          owner: body.owner,
          type: body.type,
          open: body.open,
        };
        mutation.mutate({
          id: campaign.id,
          body: payload,
        });
      }}
      isLoading={mutation.isLoading}
      errors={mutation.error?.response?.data}
      title={t('Update campaign')}
      defaultValues={campaign as ICampaignFormData}
    />
  );
};

export default memo(UpdateCampaign);
