import { Transformers } from '@inteliam/foundation/lib/utils';

import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { useManageEnterprises } from '@core/queries';

import { useEssentials } from '@core/contexts';

import {
  AddCircleIcon,
  Card,
  Controls,
  Divider,
  Grid,
  IconButton,
  RemoveCircleIcon,
} from '@shared/components';

interface Props {
  prefix?: 'scoredCompany' | '';
}

interface IntegrationIdsManagementFormData {
  integrationIdsArray: Array<{ id: string; value: string }>;
}
const IntegrationIdsManagement: React.FCC<Props> = ({ prefix = '' }) => {
  const _prefix = prefix ? prefix + '.' : '';
  const { t } = useEssentials();
  const methods = useFormContext<IntegrationIdsManagementFormData>();
  const { paginate } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
      },
    },
  });

  const { fields, append, remove } = useFieldArray<
    IntegrationIdsManagementFormData,
    'integrationIdsArray',
    'rhfId'
  >({
    control: methods.control,
    name: 'integrationIdsArray',
    keyName: 'rhfId',
  });

  return (
    <Card.CContainer styled>
      <Card.Header title={t('External IDs Management')} />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          {fields.map((integrationId, index) => {
            return (
              <React.Fragment key={integrationId.rhfId}>
                {paginate.isSuccess && (
                  <>
                    <Grid item md={6} xs={12}>
                      <Controls.FormSelect
                        fullWidth
                        id={`integrationId-${integrationId.rhfId}`}
                        name={`${_prefix}integrationIdsArray.${index}.id`}
                        label={t('Enterprise')}
                        options={Transformers.mapIntoOptionItem(
                          [
                            { name: 'MyID', id: 'MY_ITG_ID' },
                            ...paginate.data.data,
                          ],
                          'name',
                          'id'
                        )}
                      />
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <Controls.FormInput
                        fullWidth
                        id={`integrationId-value-${integrationId.rhfId}`}
                        name={`${_prefix}integrationIdsArray.${index}.value`}
                        label={t('Integration Id')}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        id={`remove-integration-id`}
                        color='secondary'
                        onClick={() => remove(index)}
                        disabled={fields.length === 1}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Grid>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item md={1}>
          <IconButton
            id='add-integration-id'
            color='primary'
            onClick={() => append({ id: '', value: '' })}
            size='large'
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default IntegrationIdsManagement;
