import {
  IBORolesEnum,
  ICompanyKindEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import type { SvgIconComponent } from '@mui/icons-material';

import * as React from 'react';
import { useCallback, useState } from 'react';

import { UpdateUserStatusModal } from '@core/components/modals';

import { QUERY_KEYS, useUpdateFOUserStatus } from '@core/queries';

import { UsersUtils } from '@core/utils';
import { getTranslatedUserStatus } from '@core/utils/users';

import { SecurityApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';

import {
  Dropdown,
  IconButton,
  Tooltip,
  MoreVertIcon,
  EditIcon,
  SecurityIcon,
  CheckIcon,
  ClearIcon,
} from '@shared/components';

import { BOErrorTracking, Constants } from '@shared/utils';

import type {
  IFOSupplierUser,
  IUserUpdateStatusInput,
} from '@inteliam/foundation/lib/types';

interface Props {
  manufacturerUser: IFOSupplierUser;
  modalHandlers: {
    onOpenCreate: () => void;
    onOpenUpdate: (data: string) => void;
    onClose: () => void;
  };
  isAllowedToManageUsers: boolean;
}

const getEnableDisableActionIcon = (): {
  [key: string]: SvgIconComponent;
} => ({
  DISABLED: CheckIcon,
  ENABLED: ClearIcon,
});

export const ActionsDropdown: React.FCC<Props> = ({
  manufacturerUser,
  modalHandlers,
  isAllowedToManageUsers,
}) => {
  const { t, user, notify } = useEssentials();
  const [open, setOpen] = useState(false);
  const { run } = useUpdateFOUserStatus({
    userId: manufacturerUser.id,
    queryKey: QUERY_KEYS.COMPANY_USERS_LIST(
      ICompanyKindEnum.SUPPLIER,
      manufacturerUser.company?.id as string
    ),
  });
  const impersonate = useCallback(
    async (id: string) => {
      try {
        const data = await SecurityApi.getImpersonationToken(id);
        window.open(
          `${Constants.FRONT_OFFICE_URL}/?impersonate=${data?.data || ''}`,
          '_blank'
        );
      } catch (error) {
        BOErrorTracking.report(error);

        notify({
          message: t(
            'Could not get access rights to use the Connect as feature'
          ),
          type: INotifierEnum.Error,
        });
      }
    },
    [notify, t]
  );
  const onConfirm = (data: IUserUpdateStatusInput) => {
    run(data);
    setOpen(false);
  };

  const StatusIcon =
    getEnableDisableActionIcon()[manufacturerUser.status as string];
  return (
    <>
      <Dropdown.Container>
        <Dropdown.Trigger
          id={`row-actions-${manufacturerUser.contactInfo.email}`}
          component={Tooltip}
          title={t('Actions')}
        >
          <IconButton
            id={`icon-row-actions-${manufacturerUser.contactInfo.email}`}
            edge='start'
            color='inherit'
            size='large'
          >
            <MoreVertIcon />
          </IconButton>
        </Dropdown.Trigger>
        <Dropdown.List>
          {isAllowedToManageUsers && (
            <Dropdown.Item
              id='edit-user-item'
              onClick={() => modalHandlers.onOpenUpdate(manufacturerUser?.id)}
              icon={<EditIcon />}
            >
              {t('Edit')}
            </Dropdown.Item>
          )}
          {UsersUtils.isGranted(IBORolesEnum.ROLE_CONNECT_AS, user) && (
            <Dropdown.Item
              id={'impersonate-user-item'}
              onClick={() => impersonate(manufacturerUser?.id)}
              disabled={
                manufacturerUser.status !== IUserStatusEnum.STATUS_ENABLED
              }
              icon={<SecurityIcon />}
            >
              {t('Connect as')}
            </Dropdown.Item>
          )}
          {[
            IUserStatusEnum.STATUS_PENDING,
            IUserStatusEnum.STATUS_DRAFT,
          ].includes(manufacturerUser.status) === false &&
            isAllowedToManageUsers && (
              <Dropdown.Item
                id='toggle-user-status-item'
                onClick={() => setOpen(true)}
                icon={<StatusIcon />}
              >
                {
                  getTranslatedUserStatus(manufacturerUser.status, t)
                    .translatedAction
                }
              </Dropdown.Item>
            )}
        </Dropdown.List>
      </Dropdown.Container>
      <UpdateUserStatusModal
        open={open}
        onClose={() => setOpen(false)}
        status={manufacturerUser.status}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default React.memo(ActionsDropdown);
