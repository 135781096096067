import { Yup } from '@inteliam/foundation/lib/utils';

import { discountSchema, validatePercentagesSum } from './discount-schema';

export const LogEntrySchema = Yup.object()
  .shape({
    subscriptionType: Yup.object().shape({
      id: Yup.string().nullable(),
    }),
    comments: Yup.string().required(),
    invoiceStatus: Yup.string().required(),
    date: Yup.date().required(),
    pricingCategory: Yup.object().shape({
      id: Yup.string().required(),
    }),

    inteliamDiscount: Yup.object().when('inteliamDiscountEnabled', {
      is: true,
      then: discountSchema,
    }),
    manufacturerDiscount: Yup.object().when('manufacturerDiscountEnabled', {
      is: true,
      then: discountSchema,
    }),
  })
  .test(
    'manufacturer discount percentage validation with inteliam',
    (value, testContext) =>
      validatePercentagesSum(
        value,
        testContext,
        'manufacturerDiscount.percentage'
      )
  )
  .test(
    'Inteliam discount percentage validation with manufacturer',
    (value, testContext) =>
      validatePercentagesSum(value, testContext, 'inteliamDiscount.percentage')
  );
