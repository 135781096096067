import { yupResolver } from '@hookform/resolvers/yup';

import React, { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions } from '@core/components';
import {
  CompanyInformation,
  IntegrationIdsManagement,
  RegistrationSchemas,
} from '@core/components/assessment-requests/registration';

import { RegistrationsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IBODistributor, IDistributorForm } from '@core/types';

import { Controls } from '@shared/components';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

interface Props {
  onSubmit: (data: IDistributorForm) => void;
  defaultValues: IBODistributor;
  activeARsCount?: number;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  activeARsCount,
  isLoading,
}) => {
  const { t, confirm } = useEssentials();
  const methods = useForm<IDistributorForm>({
    defaultValues: {
      ...defaultValues,
      turnover: RegistrationsUtils.hydrateTurnover(defaultValues),
      integrationIdsArray:
        RegistrationsUtils.hydrateIntegrationIds(defaultValues),
    },
    shouldUnregister: true,

    resolver: yupResolver<typeof RegistrationSchemas.updateCompany>(
      RegistrationSchemas.updateCompany,
      { abortEarly: false }
    ),
  });

  const { watch, reset, setError, clearErrors } = methods;

  const SIZE = 'size';
  const CATEGORY = 'category';
  const COUNTRY = 'address.country';

  const watchSize = watch(SIZE);
  const watchCountry = watch(COUNTRY);
  const watchCategory = watch(CATEGORY);

  const checkError = useCallback(
    (name: typeof SIZE | typeof CATEGORY | typeof COUNTRY, clear = false) => {
      if (clear) {
        clearErrors(name);
      } else {
        if (activeARsCount && activeARsCount > 0) {
          setError(name, {
            type: 'manual',
            message: t(
              'This change will only impact Assessment Requests that are before questionnaire fresh status'
            ),
          });
        }
      }
    },
    [t, setError, clearErrors, activeARsCount]
  );

  useEffect(() => {
    checkError(SIZE, watchSize === defaultValues.size);
  }, [watchSize, defaultValues, checkError]);

  useEffect(() => {
    checkError(CATEGORY, watchCategory === defaultValues.category);
  }, [watchCategory, defaultValues, checkError]);

  useEffect(() => {
    checkError(
      COUNTRY,
      watchCountry?.code === defaultValues.address?.country?.code
    );
  }, [watchCountry, defaultValues, checkError]);

  const onReset = () => {
    confirm({
      description: t('Are you sure you want to reset this form'),
    })
      .then(() => {
        reset(defaultValues);
      })
      .catch(() => {});
  };
  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={onSubmit}
    >
      <CompanyInformation update={true} />
      <IntegrationIdsManagement />
      <FormActions
        isLoading={isLoading}
        onCancel={onReset}
        submitText={t('Save')}
      />
    </Controls.Form>
  );
};

export default memo(Form);
