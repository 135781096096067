import { Img } from '@inteliam/foundation/lib/components';
import {
  ICompanyKindEnum,
  IEnterpriseVisibilityEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { PriceUtils } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAvailableCurrencies, useManageEnterprises } from '@core/queries';

import { EnterpriseUtils, ImgUtils } from '@core/utils';

import { ENTERPRISE_SUBSCRIPTIONS, MASKS_ENUMS } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseForm } from '@core/types';

import {
  Card,
  Controls,
  Divider,
  FormFieldLoadingIndicator,
  FormGroup,
  Grid,
  Typography,
} from '@shared/components';

import { DatatableUtils, makeStyles } from '@shared/utils';

const SUBSCRIPTIONS = Object.values(ENTERPRISE_SUBSCRIPTIONS);
type Props = {
  mode: 'CREATE' | 'UPDATE';
};

const useStyles = makeStyles(() => ({
  roundedFull: {
    borderRadius: '9999px !important',
    border: '1px solid #aeaeae',
  },
  aligned: {
    lineHeight: '60px',
    height: '60px',
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

const EnterpriseInformation: React.FCC<Props> = ({ mode }) => {
  const classes = useStyles();
  const { t } = useEssentials();
  const { formattedCurrencies } = useAvailableCurrencies();

  const methods = useFormContext<IEnterpriseForm>();

  const watchedLogo = useWatch({
    name: 'logo',
    defaultValue: methods.getValues('logo'),
    control: methods.control,
  });
  const watchedTurnoverUserValue = useWatch({
    name: 'turnover.userValue',
    defaultValue: methods.getValues('turnover.userValue'),
    control: methods.control,
  });
  const watchedTurnoverUnit = useWatch({
    name: 'turnover.unit',
    defaultValue: methods.getValues('turnover.unit'),
    control: methods.control,
  });

  const watchedEngagementLetter = useWatch({
    name: 'engagementLetter',
    defaultValue: methods.getValues('engagementLetter'),
    control: methods.control,
  });

  const watchedKind = useWatch({
    name: 'kind',
    defaultValue: methods.getValues('kind'),
    control: methods.control,
  });

  const watchedLevel = useWatch({
    name: 'level',
    defaultValue: methods.getValues('level'),
    control: methods.control,
  });

  const watchedEnterpriseAccess = useWatch({
    name: 'configuration.enterpriseAccess',
    defaultValue: methods.getValues('configuration.enterpriseAccess'),
    control: methods.control,
  });

  const isRoot = Number.parseInt(String(watchedLevel)) === 0;

  const { paginate } = useManageEnterprises({
    paginate: {
      options: {
        enabled:
          watchedKind === ICompanyKindEnum.TRADING_GROUP &&
          !isNil(watchedLevel) &&
          !isRoot,
      },
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
        primaryCriteria: {
          conditions: [
            DatatableUtils.createCondition('level', 'eq', watchedLevel - 1),
            DatatableUtils.createCondition('kind', 'eq', watchedKind),
          ],
          operator: 'and',
        },
      },
    },
  });

  useOnUpdate(
    function formResetOnKindChange() {
      if (watchedKind === ICompanyKindEnum.TRADING_GROUP) {
        methods.reset(EnterpriseUtils.TRADING_GROUP_INITIAL_VALUES);
      } else if (watchedKind === ICompanyKindEnum.SUPPLIER) {
        methods.reset(EnterpriseUtils.SUPPLIER_INITIAL_VALUES);
      } else {
        //* Other kinds go here
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchedKind]
  );

  useOnUpdate(() => {
    methods.setValue(
      'turnover.value',
      PriceUtils.getRawValue(
        watchedTurnoverUserValue,
        watchedTurnoverUnit ?? ''
      ) ?? 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedTurnoverUserValue, watchedTurnoverUnit]);

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Enterprise information')} />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controls.FormRadioGroup
              name='kind'
              required
              label={t('Category')}
              options={[
                {
                  value: ICompanyKindEnum.SUPPLIER,
                  label: t('Supplier'),
                },
                {
                  value: ICompanyKindEnum.TRADING_GROUP,
                  label: t('Trading Group'),
                },
              ]}
              row
              onChange={() =>
                methods.resetField('configuration.directoryVisibility')
              }
              disabled={mode === 'UPDATE'}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.FormRadioGroup
              name='level'
              readOnly={watchedKind !== ICompanyKindEnum.TRADING_GROUP}
              required={watchedKind === ICompanyKindEnum.TRADING_GROUP}
              label={t('Level {{level}}')}
              options={[0, 1, 2].map((level) => ({
                value: level,
                label: t('Level {{level}}', { level }),
              }))}
              row
              onChange={() =>
                methods.resetField('configuration.directoryVisibility')
              }
              disabled={mode === 'UPDATE'}
            />
          </Grid>
          <Grid
            container
            item
            md={12}
            xs={12}
            rowGap={3}
            columnGap={1}
            style={{
              border: '1px solid #e0e0e0',
            }}
          >
            <Grid item md={2} xs={12}>
              <Controls.FormBoolRadioGroup
                readOnly={watchedKind !== ICompanyKindEnum.TRADING_GROUP}
                required={watchedKind === ICompanyKindEnum.TRADING_GROUP}
                name='configuration.enterpriseAccess'
                label={t('TG with associated users')}
                options={[
                  {
                    value: true,
                    label: t('Yes'),
                  },
                  {
                    value: false,
                    label: t('No'),
                  },
                ]}
                row
              />
            </Grid>
            <Grid item md={2} xs={12}>
              {!paginate.isSettled && <FormFieldLoadingIndicator />}

              {paginate.isSuccess && (
                <Controls.FormSelect
                  name='parent.id'
                  readOnly={
                    watchedKind !== ICompanyKindEnum.TRADING_GROUP || isRoot
                  }
                  required={
                    watchedKind === ICompanyKindEnum.TRADING_GROUP && !isRoot
                  }
                  label={'Parent'}
                  options={paginate.data.data.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  disabled={mode === 'UPDATE'}
                />
              )}
            </Grid>
            <Grid item md={3} xs={12}>
              <Controls.FormRadioGroup
                name='configuration.directoryVisibility'
                readOnly={watchedKind === ICompanyKindEnum.TRADING_GROUP}
                required
                label={t('Directory Visibility')}
                options={[
                  {
                    value: IEnterpriseVisibilityEnum.VISIBLE_IN_DIRECTORY,
                    label: t('Visible for all distributors'),
                  },
                  {
                    value: IEnterpriseVisibilityEnum.NONE,
                    label: t('Not visible'),
                  },
                ]}
                row
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Controls.FormBoolRadioGroup
                readOnly={watchedKind !== ICompanyKindEnum.SUPPLIER}
                required={watchedKind === ICompanyKindEnum.SUPPLIER}
                name='configuration.canSeeRecommendedCreditLimit'
                label={t('Can See Credit Limit')}
                options={[
                  {
                    value: true,
                    label: t('Yes'),
                  },
                  {
                    value: false,
                    label: t('No'),
                  },
                ]}
                row
              />
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Company name')}
                name='name'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.MaskedFormInput
                label={t('Duns')}
                name='dunsIdentifier'
                mask={MASKS_ENUMS.DUNS_MASK}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Controls.FormDropzoneArea
                readOnly={!watchedEnterpriseAccess}
                label={
                  isNil(watchedEngagementLetter)
                    ? t('Upload enterprise engagement letter (PDF version)')
                    : t(
                        'An enterprise can only have one engagement letter. Please delete the existing one before uploading a new one.'
                      )
                }
                name='engagementLetter'
                filesLimit={1}
                maxFileSize={8_388_608}
                acceptedFiles={['.pdf']}
                dropzoneProps={{ disabled: !isNil(watchedEngagementLetter) }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Controls.FormDropzoneArea
                label={t('Upload enterprise logo')}
                name='logo'
                filesLimit={1}
                acceptedFiles={['image/*']}
              />
            </FormGroup>
            <FormGroup>
              <div className={classes.aligned}>
                <Typography>
                  {t('What logo users will see on iSuite for this enterprise?')}
                </Typography>
                <Img
                  className={classes.roundedFull}
                  src={watchedLogo}
                  alt=''
                  pathResolver={ImgUtils.pathResolver}
                  height={60}
                  width={60}
                  defaultImage='/assets/images/company-logo.svg'
                />
              </div>
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Subscription')}
                name='subscription'
                options={SUBSCRIPTIONS}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput label={t('VAT')} name='vat' />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Address line 1')}
                name='address.address'
                rows={3}
                multiline
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormCountrySelect
                label={t('Country')}
                name='address.country'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('City/Region')}
                name='address.city'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput label={t('State')} name='address.state' />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Zip code')}
                name='address.zipCode'
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <div hidden>
              <Controls.FormInput
                name={'turnover.value'}
                type='number'
                required
              />
            </div>
            <FormGroup>
              <Controls.FormInput
                label={t('Turnover')}
                name='turnover.userValue'
                type='number'
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Unit')}
                name='turnover.unit'
                options={[
                  {
                    label: 'K',
                    value: 'K',
                  },
                  {
                    label: 'M',
                    value: 'M',
                  },
                  {
                    label: 'B',
                    value: 'B',
                  },
                  {
                    label: 'T',
                    value: 'T',
                  },
                ]}
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Currency')}
                name='turnover.currency'
                options={formattedCurrencies}
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Website')}
                name='website'
                type='url'
                required
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(EnterpriseInformation);
