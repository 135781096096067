import {
  ICompanyKindEnum,
  IEnterpriseSubscriptionsEnum,
  IEnterpriseVisibilityEnum,
  ITitlesEnum,
} from '@inteliam/foundation/lib/enums';

import { SUPPLIER_ROLES, TRADING_GROUP_ROLES } from '@core/enums';

import type { TFunction } from '@core/contexts';

import type {
  EnterpriseKindType,
  EnterpriseUserKindType,
  IOptionItem,
  ISupplier,
  ITradingGroup,
} from '@inteliam/foundation/lib/types';

import { EnterpriseI18nHelpers } from './i18n';

export const ROLES_LOOKUP: Record<EnterpriseKindType, Array<IOptionItem>> = {
  [ICompanyKindEnum.SUPPLIER]: Object.values(SUPPLIER_ROLES),
  [ICompanyKindEnum.TRADING_GROUP]: Object.values(TRADING_GROUP_ROLES),
};

export const ENTERPRISE_KIND_2_USER_KIND_LOOKUP: Record<
  EnterpriseKindType,
  EnterpriseUserKindType
> = {
  [ICompanyKindEnum.SUPPLIER]: ICompanyKindEnum.SUPPLIER,
  [ICompanyKindEnum.TRADING_GROUP]: ICompanyKindEnum.TRADING_GROUP,
};

const KIND_OPTIONS = [
  ICompanyKindEnum.SUPPLIER,
  ICompanyKindEnum.TRADING_GROUP,
];
export const getKindOptions = (t: TFunction): Array<IOptionItem> => {
  return KIND_OPTIONS.map((item) => ({
    value: item,
    label: t(EnterpriseI18nHelpers.getKindTranslation(item)),
  }));
};

export const SUPPLIER_INITIAL_VALUES: Partial<ISupplier> = {
  subscription: IEnterpriseSubscriptionsEnum.STANDARD,
  name: '',
  address: {
    address: '',
    country: undefined,
    city: '',
    zipCode: '',
    state: '',
  },
  website: '',
  turnover: {
    decimals: 2,
    value: 0,
    currency: '',
    unit: 'M',
  },
  authorizationFiles: [],
  kind: ICompanyKindEnum.SUPPLIER,
  users: [
    {
      contactInfo: {
        title: ITitlesEnum.MR,
        email: '',
      },
      roles: [],
      kind: 'SUPPLIER',
    },
  ],
  logo: undefined,
  configuration: {
    enterpriseAccess: true,
    canSeeRecommendedCreditLimit: false,
    directoryVisibility: IEnterpriseVisibilityEnum.VISIBLE_IN_DIRECTORY,
  },
};
export const TRADING_GROUP_INITIAL_VALUES: Partial<ITradingGroup> = {
  kind: ICompanyKindEnum.TRADING_GROUP,
  subscription: IEnterpriseSubscriptionsEnum.STANDARD,
  name: '',
  address: {
    address: '',
    country: undefined,
    city: '',
    zipCode: '',
  },
  website: '',

  level: 0,
  parent: undefined,
  turnover: {
    decimals: 2,
    value: 0,
    currency: '',
    unit: 'M',
  },
  authorizationFiles: [],
  users: [
    {
      contactInfo: {
        title: ITitlesEnum.MR,
        email: '',
      },
      roles: [],
      kind: ICompanyKindEnum.TRADING_GROUP,
    },
  ],
  logo: undefined,
  configuration: {
    enterpriseAccess: true,
    canSeeRecommendedCreditLimit: true,
    directoryVisibility: IEnterpriseVisibilityEnum.NONE,
  },
};
