import {
  IARContactTypeEnum,
  ILanguagesEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';

import React, { memo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ContactUserForm } from '@core/components/distributors';
import { ContactSelector } from '@core/components/users';

import { useEssentials } from '@core/contexts';
import type {
  IAssessmentRequestForm,
  IRegistrationModeType,
  IRegistrationState,
} from '@core/types';

import {
  Card,
  Controls,
  Grid,
  Box,
  FormGroup,
  Divider,
  Collapse,
} from '@shared/components';

import type {
  ContactType,
  IPartialFODistributorUser,
} from '@inteliam/foundation/lib/types';

interface Props {
  currentState: IRegistrationState;
}
const PRIMARY_CONTACT_FIELD = 'scoredCompany.primaryContact' as const;
const SURVEY_CONTACT_FIELD = 'questionnaireContact' as const;
type Visibility = {
  [key in ContactType]: {
    opened: boolean;
    mode: 'CREATE' | 'UPDATE';
  };
};
const CompanyContacts: React.FCC<Props> = ({ currentState }) => {
  const { t } = useEssentials();
  const methods = useFormContext<IAssessmentRequestForm>();
  const [mode, setMode] = useState<Visibility>({
    PRIMARY: { opened: currentState.mode === 'CREATE', mode: 'CREATE' },
    SURVEY: { opened: currentState.mode === 'CREATE', mode: 'CREATE' },
  });

  const resetFields = (
    field: typeof PRIMARY_CONTACT_FIELD | typeof SURVEY_CONTACT_FIELD,
    partialUser: IPartialFODistributorUser = {}
  ) => {
    methods.setValue(`${field}.id`, partialUser.id || '');
    methods.setValue(
      `${field}.contactInfo.firstName`,
      partialUser?.contactInfo?.firstName || ''
    );
    methods.setValue(
      `${field}.contactInfo.lastName`,
      partialUser?.contactInfo?.lastName || ''
    );
    methods.setValue(
      `${field}.contactInfo.title`,
      partialUser?.contactInfo?.title || ''
    );
    methods.setValue(
      `${field}.contactInfo.position`,
      partialUser?.contactInfo?.position || ''
    );
    methods.setValue(
      `${field}.contactInfo.language`,
      partialUser?.contactInfo?.language || ILanguagesEnum.EN_GB
    );
    methods.setValue(
      `${field}.contactInfo.email`,
      partialUser?.contactInfo?.email || ''
    );
    methods.setValue(
      `${field}.contactInfo.phoneNumber`,
      partialUser?.contactInfo?.phoneNumber || '33'
    );
  };

  const toggleSelector = (
    newMode: 'CREATE' | 'UPDATE',
    contact: ContactType,
    partialUser: IPartialFODistributorUser = {}
  ) => {
    if (mode[contact].opened === false || newMode !== mode[contact].mode) {
      resetFields(
        contact === 'PRIMARY' ? PRIMARY_CONTACT_FIELD : SURVEY_CONTACT_FIELD,
        newMode === 'CREATE' ? undefined : partialUser
      );
    }
    setMode((prev) => ({
      ...prev,
      [contact]: {
        opened: prev[contact].mode === newMode ? !prev[contact].opened : true,
        mode: newMode,
      },
    }));
  };

  const watchedSurveyContactAsPrimaryContact = useWatch({
    control: methods.control,
    name: 'usePrimaryContactAsQuestionnaireContact',
    defaultValue: false,
  });

  const primaryContactId = useWatch({
    control: methods.control,
    name: `${PRIMARY_CONTACT_FIELD}.id`,
    defaultValue: undefined,
  });
  const surveyContactId = useWatch({
    control: methods.control,
    name: `${SURVEY_CONTACT_FIELD}.id`,
    defaultValue: undefined,
  });

  useOnUpdate(
    (prevDeps: [prevMode: IRegistrationModeType]) => {
      const prevMode = prevDeps[0];
      if (prevMode !== currentState.mode) {
        if (
          prevMode !== 'UPDATE' &&
          currentState.mode === 'UPDATE' &&
          (currentState.company?.primaryContact.id ||
            currentState.assessmentRequest?.questionnaireContact?.id)
        ) {
          // when switching from create to update mode and we already have a contact or/and primary
          // we need to show the forms
          setMode({
            PRIMARY: {
              mode: 'UPDATE',
              opened: Boolean(currentState.company?.primaryContact.id),
            },
            SURVEY: {
              mode: 'UPDATE',
              opened: Boolean(
                currentState.assessmentRequest?.questionnaireContact?.id
              ),
            },
          });
        } else {
          setMode({
            PRIMARY: {
              mode: 'UPDATE',
              opened: false,
            },
            SURVEY: {
              mode: 'UPDATE',
              opened: false,
            },
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentState.mode]
  );

  useOnUpdate(
    (prevDeps: [prevWatchedSurveyAsPrimary: boolean]) => {
      const [prevWatchedSurveyAsPrimary] = prevDeps;
      const justUnSelectedCheckbox =
        prevWatchedSurveyAsPrimary === true &&
        watchedSurveyContactAsPrimaryContact === false;

      if (
        justUnSelectedCheckbox &&
        primaryContactId &&
        primaryContactId === surveyContactId
      ) {
        methods.setValue(`${SURVEY_CONTACT_FIELD}.id`, undefined);
      } else if (
        prevWatchedSurveyAsPrimary === false &&
        watchedSurveyContactAsPrimaryContact === false &&
        primaryContactId &&
        primaryContactId === surveyContactId
      ) {
        methods.setValue('usePrimaryContactAsQuestionnaireContact', true);
        resetFields(SURVEY_CONTACT_FIELD);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchedSurveyContactAsPrimaryContact, primaryContactId, surveyContactId]
  );

  return (
    <Card.CContainer styled>
      <Card.Header
        title={t('Contacts Information')}
        subheader={t('Fill in the contacts information')}
      />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <ContactSelector
            contactType={IARContactTypeEnum.PRIMARY}
            company={currentState.company}
            onAdd={() => toggleSelector('CREATE', 'PRIMARY')}
            onEdit={(userToUpdate) => {
              toggleSelector('UPDATE', 'PRIMARY', userToUpdate);
            }}
            onChangeUser={(newSelectedUser) => {
              if (mode.PRIMARY.opened === true) {
                resetFields(PRIMARY_CONTACT_FIELD, newSelectedUser);
              }
            }}
          />
        </Box>
        <Collapse in={mode.PRIMARY.opened === true}>
          <ContactUserForm
            baseName={PRIMARY_CONTACT_FIELD}
            distributorId={currentState.company?.id}
            mode={'PRIMARY'}
            defaultValues={currentState.company?.primaryContact}
          />
        </Collapse>
      </Card.Body>
      <>
        <Divider />
        <Card.Body>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormCheckbox
                label={t('Use Primary Contact as Questionnaire Contact')}
                name={'usePrimaryContactAsQuestionnaireContact'}
                defaultChecked={false}
              />
            </FormGroup>
          </Grid>
        </Card.Body>
      </>
      <Collapse in={!watchedSurveyContactAsPrimaryContact}>
        <Divider />
        <Card.Body>
          <Box p={2}>
            <ContactSelector
              company={currentState.company}
              contactType={IARContactTypeEnum.SURVEY}
              onAdd={() => toggleSelector('CREATE', 'SURVEY')}
              onEdit={(userToUpdate) => {
                toggleSelector('UPDATE', 'SURVEY', userToUpdate);
              }}
              onChangeUser={(newSelectedUser) => {
                if (mode.SURVEY.opened === true) {
                  resetFields(SURVEY_CONTACT_FIELD, newSelectedUser);
                }
              }}
            />
          </Box>
          <Collapse in={mode.SURVEY.opened === true}>
            <ContactUserForm
              distributorId={currentState.company?.id}
              mode={'SURVEY'}
              defaultValues={
                currentState.assessmentRequest?.questionnaireContact
              }
              baseName={SURVEY_CONTACT_FIELD}
            />
          </Collapse>
        </Card.Body>
      </Collapse>
    </Card.CContainer>
  );
};

export default memo(CompanyContacts);
