import React, { memo, useState } from 'react';

import { AnalysisDocumentEvaluationDetails } from '@core/components/assessment-requests';

import { useFetchReferenceEvaluationsByAnswerId } from '@core/queries';

import { useEssentials } from '@core/contexts';

import {
  Button,
  TabPanel,
  Grid,
  CircularProgress,
  Tab,
  Tabs,
} from '@shared/components';

import { BaseDialog } from '..';

interface Props {
  onClose: () => void;
  answerId: string;
  optionId?: string;
  assessmentRequestId: string;
}

const DocumentAnalysisModal: React.FCC<Props> = ({
  onClose,
  answerId,
  optionId,
  assessmentRequestId,
}) => {
  const { t } = useEssentials();

  const { referenceEvaluations, isLoading } =
    useFetchReferenceEvaluationsByAnswerId({
      answerId,
      optionId,
    });

  const [value, setValue] = useState(0);

  const handleChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <BaseDialog.Dialog open={true} onClose={onClose} maxWidth='lg' fullScreen>
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Doc Analysis')}
        </Grid>
        {referenceEvaluations &&
          t(
            '{{count}} evidences(s) (from {{count}} document(s)) to analyse for this option',
            {
              count: referenceEvaluations.length,
            }
          )}
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        {isLoading && (
          <Grid
            container
            style={{ height: 500, width: '100%' }}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress color='inherit' />
          </Grid>
        )}
        {referenceEvaluations && (
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
            >
              {referenceEvaluations.map((referenceEvaluation, index) => (
                <Tab
                  key={referenceEvaluation.id}
                  label={t('Ref {{index}} ({{status}})', {
                    index: index + 1,
                    status: referenceEvaluation.status,
                  })}
                />
              ))}
            </Tabs>
            {referenceEvaluations.map((referenceEvaluation, index) => (
              <TabPanel
                key={referenceEvaluation.id}
                value={value}
                index={index}
              >
                <AnalysisDocumentEvaluationDetails
                  assessmentRequestId={assessmentRequestId}
                  referenceEvaluation={referenceEvaluation}
                />
              </TabPanel>
            ))}
          </div>
        )}
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Grid container justifyContent='center'>
          <Button
            id='close-document-analysis-modal'
            type='button'
            color='primary'
            onClick={onClose}
          >
            {t('Close')}
          </Button>
        </Grid>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(DocumentAnalysisModal);
