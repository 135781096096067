import React, { memo } from 'react';

import { EvaluationStatusFilter } from '@core/components';

import { useFetchDocumentsEvaluationGlobalInsights } from '@core/queries';

import type { IEvaluationQuery } from '@core/types';

import { Grid } from '@shared/components';

interface Props {
  assessmentRequestId: string;
  setQuery: (query: IEvaluationQuery) => void;
  query: IEvaluationQuery;
}

const ReviewDocumentsLibraryInsightsBar: React.FCC<Props> = ({
  assessmentRequestId,
  query,
  setQuery,
}) => {
  const { evaluationProgress } = useFetchDocumentsEvaluationGlobalInsights({
    arId: assessmentRequestId,
  });

  return (
    <Grid item xs={12}>
      {query && (
        <EvaluationStatusFilter
          {...{ query, setQuery }}
          progress={evaluationProgress}
        />
      )}
    </Grid>
  );
};

export default memo(ReviewDocumentsLibraryInsightsBar);
