import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type {
  DnbCompanyReport,
  DnBEnrichmentData,
  DnbSearchQuery,
  DnbSearchResult,
} from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

const privateClient = new PrivateApi(
  {
    baseRoute: '/dnb',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);
const search = async ({
  query,
}: {
  query: DnbSearchQuery;
}): Promise<IAPIResponse<Array<DnbSearchResult>>> => {
  const { data } = await privateClient.instance.post<
    IAPIResponse<Array<DnbSearchResult>>
  >('/search', query);

  // assertIsTypedArray(data.data, isIOptionItem);
  return data;
};
const getCompanyReport = async ({
  duns,
}: {
  duns: string;
}): Promise<IAPIResponse<DnbCompanyReport>> => {
  const { data } = await privateClient.instance.get<
    IAPIResponse<DnbCompanyReport>
  >(`/company-report/${duns}`);

  // assertIsTypedArray(data.data, isIOptionItem);
  return data;
};

const enrich = async ({
  companyId,
}: {
  companyId: string;
}): Promise<IAPIResponse<DnBEnrichmentData>> => {
  const { data } = await privateClient.instance.get<
    IAPIResponse<DnBEnrichmentData>
  >(`/enrich/${companyId}`);

  return data;
};
export default {
  getCompanyReport,
  search,
  enrich,
};
