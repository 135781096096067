/* eslint-disable @typescript-eslint/no-floating-promises */
import { useModalState } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';
import { get, isNil } from 'lodash-es';

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ManageQuestionReviewLinkConfigurationModal } from '@core/components/modals';

import { QuestionReviewLinkConfigurationI18nHelpers } from '@core/utils/i18n';

import { QuestionReviewLinkConfigurationApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { ReviewFormData } from '@core/types';

import {
  Button,
  Paper,
  Box,
  FormGroup,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  IconButton,
  RemoveCircleOutlineIcon,
  Card,
  Divider,
  AddCircleIcon,
  Alert,
} from '@shared/components';

import type { ITranslatableField } from '@inteliam/foundation/lib/types';

interface Props {
  themeId?: string;
  criterionId?: string;
}

const QuestionReviewLinkConfigurationsTable: React.FCC<Props> = ({
  themeId,
  criterionId,
}) => {
  const { t } = useEssentials();
  const [modalState, handlers] = useModalState();

  const methods = useFormContext<ReviewFormData>();
  const questionReviewLinkConfigurations = methods.getValues(
    'questionReviewLinkConfigurations'
  );

  const handleRemove = (
    questionId: string,
    optionId: string,
    rowId?: string
  ) => {
    if (rowId) {
      QuestionReviewLinkConfigurationApi.remove(rowId);
    }
    methods.reset({
      ...methods.getValues(),
      questionReviewLinkConfigurations:
        questionReviewLinkConfigurations?.filter(
          (linkConfig) =>
            linkConfig.question.id !== questionId ||
            linkConfig.questionOptionId !== optionId
        ),
    });
  };

  const QUESTION_REVIEW_LINKS_ROWS = questionReviewLinkConfigurations ?? [];

  const QUESTION_REVIEW_LINKS_COLUMNS: Array<{ label: string }> = [
    {
      label: 'Actions',
    },
    {
      label: 'Code',
    },
    {
      label: 'Title',
    },
    {
      label: 'Option Text',
    },
    {
      label: 'Conditions',
    },
  ];

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Question options linking')}></Card.Header>
      <Divider />
      <Card.Body>
        <Box display='flex' flexDirection='row' padding={1}>
          <Button
            id='add-sw'
            type='button'
            onClick={handlers.onOpenCreate}
            variant='contained'
            color='primary'
            disabled={themeId === '' || criterionId === ''}
          >
            <AddCircleIcon />
            {t('Add')}
          </Button>
        </Box>
        {isNil(questionReviewLinkConfigurations) ? (
          <Alert color='success' icon={false}>
            {t('No data available at this moment')}
          </Alert>
        ) : (
          <TableContainer component={Paper}>
            <Table size='small' style={{ overflow: 'hidden' }}>
              <TableHead>
                <TableRow>
                  {QUESTION_REVIEW_LINKS_COLUMNS.map((column, index) => {
                    return (
                      <TableCell key={index} align='center'>
                        {t(column.label)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {QUESTION_REVIEW_LINKS_ROWS.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <Box display='flex'>
                            <div>
                              <IconButton
                                id='remove-link'
                                onClick={() =>
                                  handleRemove(
                                    row.question.id,
                                    row.questionOptionId,
                                    row.id
                                  )
                                }
                                size='large'
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </div>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <Box display='flex'>
                            <div>{get(row, 'question.code')}</div>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <Box display='flex'>
                            <div>
                              {I18n.getTranslatedValue(
                                get(row, 'question.title') as
                                  | string
                                  | ITranslatableField
                              )}
                            </div>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <Box display='flex'>
                            <div>
                              {I18n.getTranslatedValue(
                                get(row, 'questionOptionLabel')
                              )}
                            </div>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                    <TableCell align='left'>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <Box display='flex'>
                            <ul>
                              {get(row, 'conditions').map((condition) => (
                                <li key={condition}>
                                  {t(
                                    QuestionReviewLinkConfigurationI18nHelpers.getConditionTranslation(
                                      Number(condition)
                                    )
                                  )}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card.Body>
      {modalState.mode === 'CREATE' &&
        !isNil(themeId) &&
        !isNil(criterionId) && (
          <ManageQuestionReviewLinkConfigurationModal
            open={true}
            onClose={() => handlers.onClose()}
            criterionId={criterionId}
            themeId={themeId}
          />
        )}
    </Card.CContainer>
  );
};

export default QuestionReviewLinkConfigurationsTable;
