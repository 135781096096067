import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { InputAdornment, TextField as MUITextField } from '@mui/material';

import * as React from 'react';

import {
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
} from '@shared/components';

import type { TextFieldProps } from '@shared/types';

const handleMouseDownPassword = (
  event: React.MouseEvent<HTMLButtonElement>
) => {
  event.preventDefault();
};

const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (props, ref) => {
    const { required, name, id, ...rest } = props;

    const isPassword = props.type === 'password';
    const [showPassword, { toggle: toggleShowPassword }] = useBoolean(
      !isPassword
    );

    const passwordProps = React.useMemo(
      () =>
        isPassword
          ? {
              InputProps: {
                'endAdornment': (
                  <InputAdornment position='end'>
                    <IconButton
                      id='show-password'
                      onClick={toggleShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size='large'
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                'readOnly': props.readOnly,
                'data-disabled': props.readOnly || props.disabled,
              },
              type: (showPassword
                ? 'text'
                : 'password') as TextFieldProps['type'],
            }
          : {
              InputProps: {
                'readOnly': props.readOnly,
                'data-disabled': props.readOnly || props.disabled,
              },
            },
      [
        isPassword,
        props.disabled,
        props.readOnly,
        showPassword,
        toggleShowPassword,
      ]
    );

    return (
      <MUITextField
        autoComplete='new-password'
        {...rest}
        {...passwordProps}
        inputRef={ref} // needed so that RHF can focus on error
        InputProps={{ ...passwordProps.InputProps, ...props.InputProps }}
        inputProps={{
          'aria-label': name ? `${name}-label` : undefined,
          'aria-labelledby': name ? `${name}-label` : undefined,
          ...props.inputProps,
          name,
          'id': id ?? name,
          'data-cy': id ?? name,
          'data-testid': id ?? name,
          ...(props.type === 'number'
            ? {
                min: props.min,
                max: props.max,
                step: props.step,
              }
            : {}),
        }}
        InputLabelProps={{
          className: required ? 'required-label' : '',
          required: required,
          shrink: true,
          id: name ? `${name}-label` : undefined,
          htmlFor: id ?? name,
        }}
        variant='outlined'
        FormHelperTextProps={{
          // @ts-expect-error custom field are not supported by MUI :(
          'data-cy': name ? `helper-${name}` : undefined,
        }}
      />
    );
  }
);
export default TextField;
