import { SharingRequestStatusEnum } from '@inteliam/foundation/lib/enums';
import {
  InteliamUseMutationResult,
  useQueryClient,
} from '@inteliam/foundation/lib/hooks';

import { useCallback, useMemo } from 'react';

import { QUERY_KEYS, useMutation } from '@core/queries';

import { SharingRequestsApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import type {
  IAPIResponse,
  ISharingRequest,
  ISRTransitionInput,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

import { useOpenTransitionDialog } from './sr-transition-provider';

interface ITransitionRunner {
  target: SharingRequestStatusEnum;
}

interface ITransitionMutation {
  mutation: InteliamUseMutationResult<
    IAPIResponse,
    BaseAxiosErrorResponse,
    ISRTransitionInput
  >;
  canRun: (props: ITransitionRunner) => Promise<IAPIResponse>;
  run: (props: ITransitionRunner) => Promise<IAPIResponse>;
}

type PreEventHandler = (props: {
  from: SharingRequestStatusEnum;
  confirmMessage?: string;
  to?: SharingRequestStatusEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => Promise<{ data: any } | void>;

type PostEventHandler = (props: {
  from: SharingRequestStatusEnum;
  to?: SharingRequestStatusEnum;
}) => void;

type IMachine = {
  pre: {
    [key in SharingRequestStatusEnum]: PreEventHandler;
  };
  post: {
    [key in SharingRequestStatusEnum]: PostEventHandler;
  };
};

interface Options {
  sharingRequest: ISharingRequest;
}
const useSharingRequestMachine = ({
  sharingRequest,
}: Options): { machine: IMachine; transition: ITransitionMutation } => {
  const { confirm, t, notify } = useEssentials();
  const queryClient = useQueryClient();

  const openTransitionDialog = useOpenTransitionDialog();
  const defaultPreTransitionHandler: PreEventHandler = useCallback(
    async ({ confirmMessage }): Promise<void> => {
      return confirm({
        description: t(
          confirmMessage ||
            'Are you sure you want to continue with this action?'
        ),
      });
    },
    [confirm, t]
  );

  const defaultPostTransitionHandler: PostEventHandler =
    useCallback((): void => {
      notify({
        message: t('Status updated successfully'),
      });
      queryClient
        .invalidateQueries(QUERY_KEYS.CSM_SHARING_REQUEST)
        .catch(() => {});
    }, [notify, t, queryClient]);

  const sharingRequestMachine: IMachine = useMemo(
    () => ({
      pre: {
        [SharingRequestStatusEnum.ACCEPTED]: defaultPreTransitionHandler,
        [SharingRequestStatusEnum.DECLINED]: async () => {
          return openTransitionDialog({
            transition: SharingRequestStatusEnum.DECLINED,
            sharingRequest,
          });
        },
        [SharingRequestStatusEnum.QUEUED]: defaultPreTransitionHandler,
        [SharingRequestStatusEnum.REQUESTED]: defaultPreTransitionHandler,
      },
      post: {
        [SharingRequestStatusEnum.ACCEPTED]: defaultPostTransitionHandler,
        [SharingRequestStatusEnum.DECLINED]: defaultPostTransitionHandler,
        [SharingRequestStatusEnum.QUEUED]: defaultPostTransitionHandler,
        [SharingRequestStatusEnum.REQUESTED]: defaultPostTransitionHandler,
      },
    }),
    [
      defaultPreTransitionHandler,
      defaultPostTransitionHandler,
      openTransitionDialog,
      sharingRequest,
    ]
  );

  const transitionMutation = useMutation<
    IAPIResponse,
    BaseAxiosErrorResponse,
    ISRTransitionInput
  >(SharingRequestsApi.transition, {
    onSuccess: (_, variables) => {
      const from = variables.meta?.from as SharingRequestStatusEnum;
      sharingRequestMachine.post[variables.target]({
        from,
        to: variables.target,
      });
    },
  });

  const run = useCallback(
    ({ target }: ITransitionRunner) => {
      return sharingRequestMachine.pre[target]({
        from: sharingRequest.status,
      }).then((response) => {
        return transitionMutation.mutateAsync({
          meta: {
            from: sharingRequest.status,
          },
          target,
          body: response ? response.data : {},
          id: sharingRequest.id,
        });
      });
    },
    [sharingRequest, sharingRequestMachine.pre, transitionMutation]
  );

  const canRun = useCallback(
    ({ target }: ITransitionRunner) => {
      return SharingRequestsApi.transition(
        {
          body: {},
          id: sharingRequest.id,
          target,
        },
        true
      );
    },
    [sharingRequest]
  );

  return {
    machine: sharingRequestMachine,
    transition: {
      mutation: transitionMutation,
      run,
      canRun,
    },
  };
};

export default useSharingRequestMachine;
