import type { IAnalysisHighlightsKindEnum } from '@inteliam/foundation/lib/enums';

import type { CompanyKindType, IAR } from '@inteliam/foundation/lib/types';

export const DASHBOARD = {
  ENGAGEMENT: ['eod'],
  ANALYST: ['aod'],
  CSM: ['csm'],
  RENEWALS: ['renewals'],
};

export const AVATAR = ['avatar'];
export const COMPANY_LIST = ['companies'];
export const BD_VIEW = ['bd-view'];
export const TODAYS_TO_DO_LIST = ['todays-todo'];
export const TODAYS_TO_DO_LIST_COUNT = ['todays-todo-count'];
export const CONTACT_LOG_LIST = ['contact-log'];
export const USERS_LIST = ['users'];
export const OPTIONS_LIST = ['options'];
export const FO_USERS_LIST = ['fo-users'];
export const SERVICE_ACCOUNTS_LIST = ['service-accounts'];
export const MANUFACTURERS_LIST = ['manufacturers'];
export const BEST_PRACTICES_LIST = ['best-practices'];
export const DISTRIBUTORS_LIST = ['distributors'];
export const ENGAGEMENT_SPECIALISTS_LIST = ['engagement-specialists'];
export const ANALYSTS_LIST = ['analysts'];
export const OPEN_CAMPAIGNS_LIST = ['open-campaigns'];
export const FAVORITES_LIST = ['favorites-list'];
export const STANDARD_CAMPAIGN = ['standard-campaign'];
export const CAMPAIGNS_LIST = ['campaigns'];
export const CAN_UPDATE_CAMPAIGN_OWNER = ['campaign-can-update-owner'];
export const OPEN_CAMPAIGN_TYPES_LIST = ['campaign-types'];
export const ASSESSMENT_REQUEST = ['assessment-request'];
export const SURVEY_CLASSIFICATION = ['survey-classification'];
export const QUESTIONS_LIST = ['questions-list'];
export const QUESTIONS_LANGUAGES = ['questions-languages'];
export const REVIEWS_LIST = ['reviews'];
export const AR_REVIEWS_LIST = (assessmentRequestId: string): Array<string> => [
  ...ASSESSMENT_REQUEST,
  assessmentRequestId,
  'reviews',
];

export const REVIEW = ['review'];
export const QUESTION_REVIEW_LINK_CONFIGURATION = [
  'question-review-link-configuration',
];
export const NEWS_LIST = ['news'];
export const LANGUAGES_LIST = ['languages'];
export const AVAILABLE_LANGUAGES = ['available-languages'];

export const SURVEY = ['survey'];
export const SURVEY_PROGRESS = ['survey-progress'];

export const CURRENCIES = ['currencies'];
export const COUNTRIES = ['countries'];
export const CATEGORIES = ['categories'];
export const QUESTION_THEMES = ['question-themes'];
export const QUESTION_CRITERIA = ['question-criteria'];

export const CSM_SHARING_REQUEST = ['sharing-request'];
export const CSM_SHARING_REQUEST_VIEW_TABLE = [
  ...CSM_SHARING_REQUEST,
  'view-table',
];
export const CSM_SHARING_REQUEST_VIEW_CHART = [
  ...CSM_SHARING_REQUEST,
  'view-chart',
];

export const CSM_ALERT_VIEW_TABLE = ['alert-view-table'];
export const CSM_ALERT_VIEW_CHART = ['alert-view-chart'];
export const SETTINGS = ['settings'];
export const MEDIA = ['media'];

export const DOCUMENT_EVALUATIONS = ['document-evaluations'];
export const DOCUMENTS_EVALUATION_COUNT = [...DOCUMENT_EVALUATIONS, 'count'];
export const DOCUMENTS_EVALUATION_PROGRESS = [
  ...DOCUMENT_EVALUATIONS,
  'progress',
];
export const REFERENCE_EVALUATIONS = [
  ...DOCUMENT_EVALUATIONS,
  'reference-evaluations',
];
export const REFERENCE_EVALUATION_PROGRESS = [
  ...REFERENCE_EVALUATIONS,
  'progress',
];

export const REFERENCE_EVALUATION_TABLE = [...REFERENCE_EVALUATIONS, 'table'];

export const DOCUMENTS_SCOPE_LEVELS_LIST = ['scope-levels'];

export const ANSWER_EVALUATIONS = ['answer-evaluations'];
export const ANSWERS_EVALUATION_PROGRESS = [...ANSWER_EVALUATIONS, 'progress'];

export const ANSWERS_INSIGHTS_BY_THEME = [
  ...ANSWERS_EVALUATION_PROGRESS,
  'survey.themes-insights',
];

export const SCORECARD = ['scorecard'];
export const LAST_PUBLISHED_SCORECARD = ['last-published-scorecard'];

export const SURVEY_LANDING = ['survey-landing'];

export const DISTRIBUTOR_SUBSCRIPTIONS = ['distributor-subscriptions'];

export const COMPANY_QUALIFICATION_ENUMS = ['company-enums'];

export const QUESTION_ENUMS = ['question-enums'];

export const DISCOUNT_ENUMS = ['discount-enums'];

export const BO_USERS_LIST = ['users-bo'];

export const ANALYSIS_HIGHLIGHTS = ['analysis-highlights'];
export const OPTION_SCORING_RATIOS = ['option-scoring-ratios'];

export const ANALYSIS_HIGHLIGHTS_LIST = (
  kind: IAnalysisHighlightsKindEnum
): Array<string> => [...ANALYSIS_HIGHLIGHTS, kind.toLocaleLowerCase()];

export const ASSESSMENT_HIGHLIGHTS_LIST = (
  assessmentRequestId: IAR['id']
): Array<string> => [...ANALYSIS_HIGHLIGHTS, assessmentRequestId];

export const COMPANY_USERS_LIST = (
  kind: CompanyKindType,
  companyId: string
): Array<string> => [kind.toLocaleLowerCase(), companyId, 'users'];

export const MANUFACTURER_DISCOUNTS_LIST = (
  companyId: string
): Array<string> => [companyId, 'discounts'];

export const MANUFACTURER_SUSTAINABILITY_CARDS_LIST = (
  companyId: string
): Array<string> => [companyId, 'systainability-cards'];

export const PRICING_GRID = ['pricing-grid'];
export const PRICING_CATEGORIES = ['pricing-categories'];

export const DISTRIBUTOR_SUBSCRIPTIONS_LOGS = [
  ...DISTRIBUTORS_LIST,
  'subscription-logs',
];
export const CONTACT_LOG_EVENT_ENUMS = ['contact-log-event-enums'];
export const SSQ_QUESTIONNAIRES_LIST = ['ssq-questionnaires-list'];
export const SSQ_QUESTIONS_LIST = ['ssq-questions-list'];
export const DISCOUNTS_LIST = ['discounts-list'];
export const HAS_ACTIVE_DISCOUNTS = (companyId: string): Array<string> => [
  companyId,
  'discounts',
  'has-active-discounts',
];
export const TRANSLATIONS = ['translations'];
export const CUSTOM_ASSESSMENT_HIGHLIGHTS_LIST = [
  ...TRANSLATIONS,
  'custom-assessment-highlights-list',
];
export const AMENDMENT_LIST = [...TRANSLATIONS, 'amendment-list'];

export const CARBON_PARAMETERS = ['carbon-parameters'];
export const CARBON_ASSESSMENTS = ['carbon-assessments'];

export const OPTION_SCORING_RATIO = ['option-scoring-ratio'];
export const DNB_ENRICHMENT_DATA = ['dnb-enrichment-data'];
