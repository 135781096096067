import { useNamedContext } from '@shared/contexts';

import type { IThemeConfiguration } from '@inteliam/foundation/lib/types';

import { QuestionsContext } from './contexts';

export type IQuestionsContext = {
  // readonly?: boolean; // TODO
  questionnaireType: IThemeConfiguration['questionnaireType'];
};

const QuestionsProvider = QuestionsContext.Provider;

function useQuestionContext(): IQuestionsContext {
  return useNamedContext(QuestionsContext);
}

export { QuestionsProvider, useQuestionContext };
