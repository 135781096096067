import { isICountry } from '@inteliam/foundation/lib/guards';

const normalizer = (value: unknown): string => {
  if (isICountry(value)) {
    return value.value;
  }
  throw new Error('[Country Filterer] : value should be an ICountry');
};

export default normalizer;
