import {
  IARContactTypeEnum,
  ICompanyKindEnum,
} from '@inteliam/foundation/lib/enums';
import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo, useState } from 'react';

import { QUERY_KEYS } from '@core/queries';

import type { IBODistributor } from '@core/types';

import { Controls, Grid } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { FormMethods } from '@shared/types';

import type {
  IAR,
  IUpdateAssessmentRequestContactsPayload,
} from '@inteliam/foundation/lib/types';

import {
  ContactSelector,
  CreateDistributorUserForm,
  UpdateDistributorUserForm,
} from '../';

interface Props {
  primaryContactMethods: FormMethods<IUpdateAssessmentRequestContactsPayload>;
  surveyContactMethods: FormMethods<IUpdateAssessmentRequestContactsPayload>;
  assessmentRequest: IAR;
}

const useStyles = makeStyles(() => ({
  autoWidth: {
    width: 'auto',
  },
  form: {
    width: '100%',
  },
  distributorUserForm: {
    margin: '30px 0',
  },
}));

type Modes =
  | undefined
  | 'UPDATE_PRIMARY'
  | 'ADD_PRIMARY'
  | 'UPDATE_SURVEY'
  | 'ADD_SURVEY';

const Form: React.FCC<Props> = ({
  primaryContactMethods,
  surveyContactMethods,
  assessmentRequest,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [mode, setMode] = useState<Modes>(undefined);

  const onCreationClose = (
    id?: string,
    mode?: 'PRIMARY' | 'SURVEY' | 'CREATE'
  ) => {
    const config = {
      PRIMARY: {
        field: 'scoredCompany.primaryContact.id' as const,
        methods: primaryContactMethods,
      },
      SURVEY: {
        field: 'questionnaireContact.id' as const,
        methods: surveyContactMethods,
      },
    };
    setMode(undefined);
    if (id && mode && mode !== 'CREATE') {
      queryClient
        .invalidateQueries(
          QUERY_KEYS.COMPANY_USERS_LIST(
            ICompanyKindEnum.DISTRIBUTOR,
            assessmentRequest.scoredCompany.id
          )
        )
        .catch(() => {});
      config[mode].methods.setValue(config[mode].field, id);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Controls.Form
            submitHandler={() => {}}
            methods={primaryContactMethods}
            className={classes.form}
          >
            <ContactSelector
              contactType={IARContactTypeEnum.PRIMARY}
              company={assessmentRequest.scoredCompany as IBODistributor}
              onChangeUser={() => setMode(undefined)}
              onAdd={() => setMode('ADD_PRIMARY')}
              onEdit={() => setMode('UPDATE_PRIMARY')}
            />
          </Controls.Form>
        </Grid>
        {(mode === 'ADD_PRIMARY' || mode === 'UPDATE_PRIMARY') && (
          <Grid container className={classes.distributorUserForm}>
            {mode === 'ADD_PRIMARY' && (
              <CreateDistributorUserForm
                distributorId={assessmentRequest.scoredCompany.id}
                onClose={onCreationClose}
                mode={'PRIMARY'}
              />
            )}
            {mode === 'UPDATE_PRIMARY' && (
              <UpdateDistributorUserForm
                userId={
                  primaryContactMethods.getValues().scoredCompany.primaryContact
                    ?.id
                }
                distributorId={assessmentRequest.scoredCompany.id}
                onClose={() => setMode(undefined)}
                mode={'PRIMARY'}
              />
            )}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} style={{ marginTop: 30 }}>
        <Grid container>
          <Controls.Form
            submitHandler={() => {}}
            methods={surveyContactMethods}
            className={classes.form}
          >
            <ContactSelector
              contactType={IARContactTypeEnum.SURVEY}
              company={assessmentRequest.scoredCompany as IBODistributor}
              onChangeUser={() => setMode(undefined)}
              onAdd={() => setMode('ADD_SURVEY')}
              onEdit={() => setMode('UPDATE_SURVEY')}
            />
          </Controls.Form>
        </Grid>
        {(mode === 'ADD_SURVEY' || mode === 'UPDATE_SURVEY') && (
          <Grid container className={classes.distributorUserForm}>
            {mode === 'ADD_SURVEY' && (
              <CreateDistributorUserForm
                distributorId={assessmentRequest.scoredCompany.id}
                onClose={onCreationClose}
                mode={'SURVEY'}
              />
            )}
            {mode === 'UPDATE_SURVEY' && (
              <UpdateDistributorUserForm
                userId={
                  surveyContactMethods.getValues().questionnaireContact?.id
                }
                distributorId={assessmentRequest.scoredCompany.id}
                onClose={() => setMode(undefined)}
                mode={'SURVEY'}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default memo(Form);
