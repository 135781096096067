import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const analysisHighlightsFilterSchema = Yup.object().shape({
  status: Yup.string().required(ErrorCodesLookup.E0001.title),
  language: Yup.string().required(ErrorCodesLookup.E0001.title),
});

export default analysisHighlightsFilterSchema;
