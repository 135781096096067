import { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  Box,
  Controls,
  FormControl,
  IconButton,
  RemoveCircleIcon,
} from '@shared/components';

import { DatatableUtils, makeStyles } from '@shared/utils';

import type { IFilterableOption } from '@shared/types';

import type { IFilter, IFilterCondition } from '@inteliam/foundation/lib/types';

import Filterers from './filterer';

const useStyles = makeStyles(() => ({
  formControl: {
    width: 250,
    marginRight: 10,
  },
  valueFormControl: {
    width: 550,
    marginRight: 10,
  },
  close: {
    width: 50,
  },
}));

interface Props {
  index: number;
  defaultItem: Partial<FieldArrayWithId<IFilter, 'conditions', 'id'>>;
  watchedItem?: IFilterCondition;
  onRemove: (index: number) => void;
  columns: IFilterableOption[];
}

const FilterRow: React.FCC<Props> = ({
  index,
  defaultItem,
  watchedItem,
  onRemove,
  columns,
}) => {
  const methods = useFormContext();
  const { t } = useEssentials();
  const fieldName = Helpers.arrayDotNotation('conditions', index);
  const selectedColumn = columns.find(
    (column) => column.field === watchedItem?.column
  );
  const watchedFilterFieldType = useWatch({
    control: methods.control,
    name: `${fieldName}.filterFieldType`,
    defaultValue: selectedColumn?.filter.type,
  });
  const classes = useStyles();

  const commonProps = {
    label: t('Value'),
    defaultValue: watchedItem?.value,
    name: selectedColumn ? `${fieldName}.value` : 'UNKNOWN',
  };

  React.useEffect(() => {
    if (selectedColumn) {
      methods.setValue(
        `${fieldName}.filterFieldType`,
        selectedColumn.filter.type
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedItem?.column]);
  const FilterComponent =
    selectedColumn &&
    selectedColumn.filter.type !== IFilterTypeEnum.NotFiltrable &&
    selectedColumn.filter.type === watchedFilterFieldType
      ? Filterers[selectedColumn.filter.type]
      : Placeholder;

  return (
    <Box display='flex' alignItems='start' p={1}>
      <Box className={classes.close}>
        <IconButton
          id={`remove-filter-${watchedItem?.column as string}`}
          onClick={() => onRemove(index)}
          size='large'
        >
          <RemoveCircleIcon />
        </IconButton>
      </Box>
      <FormControl className={classes.formControl}>
        <Controls.FormSelect
          name={`${fieldName}.column`}
          label={t('Column')}
          defaultValue={defaultItem?.column || ''}
          options={columns.filter(
            (column) => column.filter.type !== IFilterTypeEnum.NotFiltrable
          )}
          required
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Controls.FormSelect
          name={`${fieldName}.operator`}
          label={t('Operator')}
          defaultValue={defaultItem?.operator || ''}
          options={DatatableUtils.COLUMN_OPERATORS.filter(
            (operator) =>
              selectedColumn &&
              operator.supports.includes(selectedColumn.filter.type)
          )}
          required
        />
      </FormControl>
      <FormControl
        className={classes.valueFormControl}
        key={watchedItem?.column}
      >
        {/* ONLY WHEN NO COLUMN has been SELECTED YET : STARTS */}
        {FilterComponent && (
          <FilterComponent
            {...selectedColumn?.filter.props}
            {...commonProps}
            defaultItem={defaultItem}
          />
        )}

        {/* ONLY WHEN NO COLUMN has been SELECTED YET : ENDS*/}
      </FormControl>
    </Box>
  );
};

export default FilterRow;

const Placeholder = () => (
  <Controls.FormInput name='placeholder' defaultValue={''} disabled />
);
