import React, { memo } from 'react';

import { useLanguagesByScope } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Card, Divider, ErrorHelpText } from '@shared/components';

import { ManageQuestionLanguagesForm } from './';

const LanguageManagement: React.FCC = () => {
  const { t } = useEssentials();

  const languageQuery = useLanguagesByScope({
    scope: 'QuestionnaireAdministration',
  });

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Language management')} />
      <Divider />
      <Card.Body>
        {languageQuery.isSettled && <ManageQuestionLanguagesForm />}
        {languageQuery.isSuccess &&
          languageQuery.formattedLanguages.length === 0 && (
            <ErrorHelpText>
              {t('There are no enabled languages to manage')}
            </ErrorHelpText>
          )}
      </Card.Body>
      <Divider />
    </Card.CContainer>
  );
};

export default memo(LanguageManagement);
