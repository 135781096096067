import { ApiFactory } from '@inteliam/foundation/lib/api';
import {
  assertIsTypedArray,
  isIOptionItem,
} from '@inteliam/foundation/lib/guards';
import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type { IServiceAccount } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IOptionItem,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type ServiceAccountActions =
  | 'paginate'
  | 'getOneById'
  | 'create'
  | 'update'
  | 'remove'
  | 'patch';

const privateClient = new PrivateApi(
  {
    baseRoute: '/service-accounts',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const baseServiceAccountApi = ApiFactory.generate<
  IServiceAccount,
  JwtBOUser,
  ServiceAccountActions
>({
  baseRoute: '/service-accounts',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  guard: (arg: any): arg is IServiceAccount => {
    return (
      typeof arg.id === 'string' &&
      typeof arg.client.clientId === 'string' &&
      typeof arg.client.clientSecret === 'string'
    );
  },
});

const getIntegrationIds = async (): Promise<
  IAPIResponse<Array<IOptionItem>>
> => {
  const { data } = await privateClient.instance.get<
    IAPIResponse<Array<IOptionItem>>
  >('/integration-ids');

  assertIsTypedArray(data.data, isIOptionItem);
  return data;
};

export default {
  ...baseServiceAccountApi.actions,
  getIntegrationIds,
};
