import React, { memo } from 'react';

import { EnterpriseUserForm } from '@core/components';

import { useCreateFOUser, useFetchEnterpriseById } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Grid } from '@shared/components';

import { BaseDialog } from '..';

interface Props {
  manufacturerId: string;
  open: boolean;
  onClose: () => void;
}
const CreateEnterpriseUserModal: React.FCC<Props> = ({
  manufacturerId,
  open,
  onClose,
}) => {
  const { t } = useEssentials();

  const { run, mutation } = useCreateFOUser({
    companyId: manufacturerId,
    successMessage: 'The contact was successfully created',
    onSuccess: () => onClose(),
  });
  const manufacturerQuery = useFetchEnterpriseById({ id: manufacturerId });
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Add a contact')}
        </Grid>
      </BaseDialog.Title>
      <EnterpriseUserForm
        onCancel={onClose}
        onSubmit={run}
        isLoading={mutation.isLoading}
        errors={mutation.error?.response?.data}
        manufacturerId={manufacturerId}
        enterpriseKind={manufacturerQuery.data?.data.kind}
      />
    </BaseDialog.Dialog>
  );
};

export default memo(CreateEnterpriseUserModal);
