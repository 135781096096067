import {
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useSpecificTransition } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import DeadlineForm from '../update-deadlines/deadline-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ScoreSurveyDialog: React.FCC<Props> = ({
  assessmentRequest,
  onCancel,
  onConfirm,
  open,
}) => {
  const { t } = useEssentials();
  const { run: runUpdate, mutation } = useSpecificTransition({
    assessmentRequest: assessmentRequest,
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',

    transition: IARTransitionEnum.SCORE,
    successMessage: 'The deadline was successfully updated',
    confirmMessage: 'Are you sure you want to update the next deadline ?',
    onSuccess: onConfirm,
  });

  const handleOnSubmit = (deadlines: IARDeadlinesForm) => {
    runUpdate({
      data: deadlines,
    }).catch(() => {});
  };
  return (
    <DeadlineForm
      title={t('Update Next Deadline')}
      open={open}
      onClose={onCancel}
      onSubmit={handleOnSubmit}
      errors={mutation.error?.response?.data}
      statuses={assessmentRequest.statuses.filter((status) =>
        [IARStatusEnum.IN_SCORING, IARStatusEnum.RTA].includes(status.codename)
      )}
      disabledStatuses={assessmentRequest.statuses
        .filter(
          (status) =>
            ![IARStatusEnum.IN_SCORING, IARStatusEnum.RTA].includes(
              status.codename
            )
        )
        .map((status) => status.codename)}
      isLoading={mutation.isLoading}
      assessmentRequest={assessmentRequest}
    />
  );
};

export default memo(ScoreSurveyDialog);
