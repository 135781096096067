import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const ListFilterer: React.FCC<
  FormSelectProps & { defaultItem: IFilterDefaultItem }
> = (props) => {
  return (
    <Controls.FormSelect
      {...omit(props, ['defaultItem'])}
      options={props.options}
      required
    />
  );
};

export default ListFilterer;
