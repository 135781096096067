import { ApiFactory } from '@inteliam/foundation/lib/api';
import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { isINews } from '@core/guards';
import type { INewsPayload } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  Id,
  IPaginationQuery,
  IPaginationResponse,
  IPatchObject,
  JwtBOUser,
  INews,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/news';

type NewsActions = 'paginate' | 'patch' | 'getOneById' | 'create' | 'remove';

const baseNewsApi = ApiFactory.generate<
  INews,
  JwtBOUser,
  NewsActions,
  INewsPayload,
  INewsPayload
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),

  guard: isINews,
});

const update = async ({
  body,
  id,
}: IPatchObject<INewsPayload>): Promise<IAPIResponse<INews>> => {
  const { data } = await baseNewsApi.client.instance.put(`/${id}`, body);
  return data;
};

const paginateByAR = async (
  id: Id,
  paginator: IPaginationQuery
): Promise<IPaginationResponse<INews>> => {
  const client = new PrivateApi(
    {
      baseRoute: BASE_PATH,
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  const { data } = await client.instance.get(
    `/assessment-request/${id}/paginate`
  );
  return data;
};

export default {
  ...baseNewsApi.actions,
  update,
  paginateByAR,
};
