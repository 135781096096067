import { MathUtils } from '@inteliam/foundation/lib/utils';

const IMAGE = 'image/*';
const DOCX = '.docx';
const DOC = '.doc';
const TXT = '.txt';
const APPLICATION_PDF = 'application/pdf';
const EXCEL = '.excel';
const CSV = '.csv';
const XLSX = '.xlsx';
const XLS = '.xls';
export const ACCEPTED_FILES = [
  APPLICATION_PDF,
  IMAGE,
  DOC,
  DOCX,
  EXCEL,
  TXT,
  CSV,
  XLSX,
  XLS,
];

export const getAcceptedFileFormattedName = (file: File): string => {
  const { name, size } = file;
  const nameProperties = name.split('.');
  const fileName = nameProperties[0];
  const extension = nameProperties[nameProperties.length - 1];
  return `${fileName} (${extension}, ${convertBytesToMegabytes(size)}Mb)`;
};

export const convertBytesToMegabytes = (size: number): number => {
  return MathUtils.precisionRound(size / 1_000_000, { precision: 3 });
};

export const MAX_SIZE = 20_000_000;
