import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';

import MultipleChoiceForm from './multiple-choice-form';
import SingleChoiceForm from './single-choice-form';
import SingleFreeForm from './single-free-form';

export { default as BaseLanguageForm } from './base-language-form';
export { default as LanguageOptionItem } from './language-option-item';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LanguageForms = (): Record<IQuestionKindEnum, any> => ({
  [IQuestionKindEnum.SingleFreeFormField]: SingleFreeForm,
  [IQuestionKindEnum.SingleChoiceField]: SingleChoiceForm,
  [IQuestionKindEnum.MultipleChoiceField]: MultipleChoiceForm,
});
