import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TransferList as List } from '@shared/components';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

export type Props = {
  defaultValue?: IOptionItem[];
  data: IOptionItem[];
  name: string;
  disabled: boolean;
};

const TransferList: React.FCC<Props> = ({ data = [], name, disabled }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <List
            data={data}
            defaultValue={field.value}
            onChange={field.onChange}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default TransferList;
