import { AuthHelpers, PrivateApi } from '@inteliam/foundation/lib/utils';

import {
  getAuthClientInstance,
  Constants,
  BOErrorTracking,
} from '@shared/utils';

import type { ResourceReaderFunction } from '@shared/types';

import type { IAPIResponse, IResource } from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/resources',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);
const create = async (files: File[]): Promise<Array<IResource>> => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  try {
    const { data } = await client.instance.post<IAPIResponse<Array<IResource>>>(
      '',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data.data || [];
  } catch (error) {
    BOErrorTracking.report(error, 'CreateResource', undefined, [
      { key: 'input', value: JSON.stringify(files) },
    ]);
    throw error;
  }
};

const getResourceDownloadablePath: ResourceReaderFunction = (
  id: string,
  { absolute = true, disposition = 'view' } = {}
) =>
  `${absolute ? Constants.BASE_URL : ''}/resources/${id}?${new URLSearchParams({
    access_token: AuthHelpers.getAccessToken() as string,
    disposition,
  }).toString()}`;

export default {
  create,
  getResourceDownloadablePath,
};
