import { createNamedContext } from '@shared/contexts';

import type {
  INotifierDispatcher,
  I18nContext,
  IColorModeContext,
  IUserContext,
  IDashboardContext,
  IQuestionsContext,
  IWorkingPageContext,
} from './';
import './notifier-context';

export const AuthContext = createNamedContext<IUserContext>('AuthContext');
export const DashboardContext =
  createNamedContext<IDashboardContext>('DashboardContext');
export const LocalizerContext =
  createNamedContext<I18nContext>('LocalizerContext');

export const NotifierContext =
  createNamedContext<INotifierDispatcher>('NotifierContext');

export const ColorModeContext =
  createNamedContext<IColorModeContext>('ColorModeContext');
export const QuestionsContext =
  createNamedContext<IQuestionsContext>('QuestionsContext');

export const WorkingPageContext =
  createNamedContext<IWorkingPageContext>('WorkingPageContext');
