import React, { memo } from 'react';

import { QUERY_KEYS, useManageFavorites } from '@core/queries';

import type { IAssignBusinessDevelopersFavoriteForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import FavoriteForm from './update-favorite-form';

interface Props {
  assessmentRequest: IAR;
  defaultValues: IAssignBusinessDevelopersFavoriteForm | undefined;
  open: boolean;
  onClose: () => void;
}

const UpdateFavoritesModal: React.FCC<Props> = ({
  assessmentRequest,
  defaultValues,
  open,
  onClose,
}) => {
  const { assignBDsToDistributor } = useManageFavorites({
    assignBDsToDistributor: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.FAVORITES_LIST],
      },
    },
  });

  return (
    <FavoriteForm
      open={open}
      onClose={onClose}
      assessmentRequest={assessmentRequest}
      defaultValues={defaultValues}
      onSubmit={(data) =>
        assignBDsToDistributor.mutateAsync(
          {
            id: assessmentRequest.scoredCompany.id,
            changeScope: data,
          },
          {
            onSuccess: () => {
              onClose();
            },
          }
        )
      }
      errors={assignBDsToDistributor.error?.response?.data}
      isLoading={assignBDsToDistributor.isLoading}
    />
  );
};

export default memo(UpdateFavoritesModal);
