import { FormatDate } from '@inteliam/foundation/lib/components';
import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { DateFormatter } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { ContactLogHoverPreview } from '@core/components/contact-logs';
import { CreateContactLogModal } from '@core/components/modals';

import { useEssentials } from '@core/contexts';

import {
  IconButton,
  RouterLink,
  TooltippedCell,
  Tooltip,
  Grid,
  Box,
  OpenInNewIcon,
  AddCircleIcon,
} from '@shared/components';

import { makeStyles, withStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
  readOnly?: boolean;
}

const useStyles = makeStyles(() => ({
  multiLine: {
    lineHeight: 'normal',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

type TooltippedCellProps = React.ComponentProps<typeof TooltippedCell>;

const TableTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(8),
    border: '1px solid #dadde9',
    width: '100%',
  },
}))((props: TooltippedCellProps) => <TooltippedCell {...props} />);

const ContactLogCell: React.FCC<Props> = ({
  assessmentRequest,
  readOnly = false,
}) => {
  const [open, { setFalse: onClose, setTrue: onOpen }] = useBoolean();
  const classes = useStyles();
  const { t } = useEssentials();

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box display='flex' flexDirection='row' width={1}>
        <TableTooltip
          title={
            <ContactLogHoverPreview assessmentRequest={assessmentRequest} />
          }
          disabled={open || assessmentRequest.contactLogs.length === 0}
        >
          <React.Fragment>
            {assessmentRequest.contactLogs.length > 0 ? (
              <div className={classes.multiLine}>
                <div>
                  <FormatDate
                    date={assessmentRequest.contactLogs[0].interactionDate}
                    format={DateFormatter.FORMATS.fr_FR.SHORT}
                  />
                </div>
              </div>
            ) : (
              t('N/A')
            )}
            {open && (
              <CreateContactLogModal
                view='AR'
                assessmentRequest={assessmentRequest}
                {...{ open, onClose }}
              />
            )}
          </React.Fragment>
        </TableTooltip>
        {assessmentRequest.contactLogs.length > 0 && (
          <span>
            <IconButton id={`contact-logs-${assessmentRequest.businessId}`}>
              <RouterLink
                to={`/assessment-requests/${assessmentRequest.id}/contact-logs`}
                id={`goto-contact-logs-${assessmentRequest.businessId}`}
                target='_blank'
              >
                <OpenInNewIcon />
              </RouterLink>
            </IconButton>
          </span>
        )}
        {!readOnly && (
          <Tooltip title={t('Add a new entry')}>
            <span>
              <IconButton
                id={`open-contact-log-${assessmentRequest.businessId}`}
                edge='start'
                color='primary'
                onClick={onOpen}
              >
                <AddCircleIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </Grid>
  );
};

export default ContactLogCell;
