import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';
import type { IDocumentEvaluation } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchDocumentEvaluationByDocumentId = ({
  id,
}: {
  id?: string;
}): InteliamUseQueryResult<
  IAPIResponse<IDocumentEvaluation, unknown, unknown> | undefined,
  BaseAxiosErrorResponse
> & {
  documentEvaluation?: IDocumentEvaluation;
} => {
  const query = useQuery<
    IAPIResponse<IDocumentEvaluation> | undefined,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.DOCUMENT_EVALUATIONS, id],
    () => (id ? DocumentEvaluationsApi.getByDocumentId(id) : undefined),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
      cacheTime: 1000 * 20,
    }
  );
  return {
    ...query,

    documentEvaluation: query.data?.data,
  };
};

export default useFetchDocumentEvaluationByDocumentId;
