import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';

import React, { memo, useCallback } from 'react';

import {
  ReviewReferenceEvaluationTable,
  ReviewsDocumentStatusSwitch,
} from '@core/components';
import { DocumentQualificationForm } from '@core/components/assessment-requests';

import {
  QUERY_KEYS,
  useFetchDocumentEvaluationByDocumentId,
} from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IReferenceEvaluation } from '@core/types';

import { DocViewer, Grid } from '@shared/components';

import { Constants } from '@shared/utils';

type Props = {
  referenceEvaluation: IReferenceEvaluation;
  assessmentRequestId: string;
};

const AnalysisDocumentEvaluationDetails: React.FCC<Props> = ({
  referenceEvaluation,
  assessmentRequestId,
}) => {
  const { t } = useEssentials();
  const queryClient = useQueryClient();

  const { documentEvaluation } = useFetchDocumentEvaluationByDocumentId({
    id: referenceEvaluation.reference.document.id,
  });

  const onStatusSwitchSuccess = useCallback(() => {
    if (documentEvaluation) {
      queryClient
        .invalidateQueries([
          ...QUERY_KEYS.DOCUMENT_EVALUATIONS,
          documentEvaluation.id,
        ])
        .catch(() => {});
    }
  }, [queryClient, documentEvaluation]);

  return (
    <div>
      {documentEvaluation && (
        <Grid container spacing={3}>
          <Grid item md={12}>
            <div style={{ float: 'right' }}>
              <ReviewsDocumentStatusSwitch
                documentEvaluation={documentEvaluation}
                onSuccess={onStatusSwitchSuccess}
                label={t('Doc status')}
              />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            {documentEvaluation.document.file?.mimeType && (
              <DocViewer
                url={`${Constants.BASE_URL}/documents/${
                  documentEvaluation.document.id
                }/download?access_token=${
                  AuthHelpers.getAccessToken() as string
                }`}
                name={documentEvaluation.document.name}
                type={documentEvaluation.document.file?.mimeType}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <div>
              <DocumentQualificationForm
                defaultValues={documentEvaluation}
                id={documentEvaluation.document.id}
              />
            </div>
            <div style={{ paddingTop: '10px' }}>
              <ReviewReferenceEvaluationTable
                {...{ assessmentRequestId }}
                selectedReferenceId={referenceEvaluation.id}
                documentEvaluation={documentEvaluation}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default memo(AnalysisDocumentEvaluationDetails);
