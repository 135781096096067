import {
  IARTransitionEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAssessmentMachine } from '@core/hooks';

import {
  useFetchCampaignTypeByType,
  useFetchHasValidScoreCard,
} from '@core/queries';

import { useEssentials } from '@core/contexts';

import { BOErrorTracking } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

import { InviteModal, QueueModal, ReuseModal } from '.';
import { BaseDialog } from '..';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

enum TargetDeciderStatus {
  'IDLE' = 'idle',
  'QUEUE' = 'queue',
  'REUSE' = 'reuse',
  'INVITE' = 'invite',
}
const InitiateSurveyModal: React.FCC<Props> = (props) => {
  const [targetStatus, setTargetStatus] = useState<TargetDeciderStatus>(
    TargetDeciderStatus.IDLE
  );
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });

  const scoreCardQuery = useFetchHasValidScoreCard({
    id: props.assessmentRequest.scoredCompany.id,
  });
  const {
    transition: { canRun },
  } = useAssessmentMachine({
    assessmentRequest: props.assessmentRequest,
    dashboard: 'ENGAGEMENT',
  });

  const { notify } = useEssentials();

  useEffect(() => {
    async function runQueueOrInvite() {
      try {
        // Can Queue
        await canRun({
          transition: IARTransitionEnum.QUEUE,
        });
        // Queue
        setTargetStatus(TargetDeciderStatus.QUEUE);
      } catch {
        // No You can't => Invite
        setTargetStatus(TargetDeciderStatus.INVITE);
      }
    }
    if (scoreCardQuery.isSettled) {
      if (
        scoreCardQuery.data?.data?.valid === true &&
        props.assessmentRequest.requester
      ) {
        setTargetStatus(TargetDeciderStatus.REUSE);
      } else {
        // valid === false || no scorecard yet
        runQueueOrInvite().catch(() => {});
      }
    }
  }, [
    props.assessmentRequest.requester,
    props.onClose,
    notify,
    scoreCardQuery.isSettled,
    scoreCardQuery.data?.data?.valid,
    canRun,
  ]);

  const ComponentByStatus = {
    [TargetDeciderStatus.REUSE]: ReuseModal,
    [TargetDeciderStatus.INVITE]: InviteModal,
    // Queue only we'll show the confirm dialog using confirm hook with UI
    [TargetDeciderStatus.QUEUE]: QueueModal,
  };

  // still checking ...
  if (
    targetStatus === TargetDeciderStatus.IDLE ||
    !campaignTypeQuery.isSettled
  ) {
    return <BaseDialog.DialogPlaceholder />;
  }
  if (campaignTypeQuery.isError) {
    BOErrorTracking.report(
      campaignTypeQuery.error,
      'InitiateSurveyModal',
      undefined,
      [
        {
          key: 'campaignTypeQuery.error',
          value: JSON.stringify(campaignTypeQuery.error?.response?.data),
        },
      ]
    );
    return <Navigate to='/500' />;
  }

  const Component = ComponentByStatus[targetStatus];

  return <Component {...props} />;
};

export default memo(InitiateSurveyModal);
