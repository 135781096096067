import { Theme, useTheme } from '@mui/material';

import { NavigateFunction, useNavigate } from 'react-router-dom';

import {
  IAuthenticatedUserContext,
  useAuth,
  INotifierDispatcher,
  INotifierEnum,
  useI18n,
  useNotifier,
  IConfirmerDispatcher,
  useConfirm,
  I18nContext,
} from '@core/contexts';

type IEssentials = I18nContext &
  IAuthenticatedUserContext & {
    notify: INotifierDispatcher;
    INotifierEnum: {
      Error: INotifierEnum;
      Info: INotifierEnum;
      Success: INotifierEnum;
      Warning: INotifierEnum;
    };
    confirm: IConfirmerDispatcher;
    na: string;
    theme: Theme;
    navigate: NavigateFunction;
  };
const useEssentials = (): IEssentials => {
  const authContext = useAuth();
  const i18nContext = useI18n();
  const notify = useNotifier();
  const confirm = useConfirm();
  const theme = useTheme();
  const na = i18nContext.t('N/A');
  // TODO to be remove from useEssentials as FO
  const navigate = useNavigate();
  return {
    ...authContext,
    ...i18nContext,
    notify,
    confirm,
    na,
    INotifierEnum,
    theme,
    navigate,
  };
};

export default useEssentials;
