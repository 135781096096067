import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type {
  ICalculationFormula,
  IUnsavedCalculationFormula,
} from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type {
  IAPIResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/calculation-formulas',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getByQuestionCode = async (
  code: string
): Promise<IAPIResponse<ICalculationFormula>> => {
  const { data } = await client.instance.get<IAPIResponse<ICalculationFormula>>(
    `/${code}`
  );
  return data;
};

const patch = async (
  args: IPatchObject
): Promise<IAPIResponse<ICalculationFormula>> => {
  const { data } = await client.instance.patch<
    IAPIResponse<ICalculationFormula>
  >(`/${args.id}`, args.body);
  return data;
};

const create = async (
  formula: IUnsavedCalculationFormula
): Promise<IAPIResponse<ICalculationFormula>> => {
  const { data } = await client.instance.post<
    IAPIResponse<ICalculationFormula>
  >('', formula);
  return data;
};

export default {
  getByQuestionCode,
  patch,
  create,
};
