/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
  useQueryString,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridOverlay,
  GridSlotsComponentsProps,
  GridSortModel,
} from '@mui/x-data-grid';

import * as React from 'react';

import { useQuery } from '@core/queries';

import { useEssentials } from '@core/contexts';

import * as DatatableUtils from '@shared/utils/datatable';

import type {
  TheDatatableProps,
  IFilterableColumn,
  IDataTableContext,
  IGridRow,
} from '@shared/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  Id,
  DatatableState,
  IPaginationResponse,
  VisibilityChangerFn,
} from '@inteliam/foundation/lib/types';

import TableToolbar from './table-toolbar';

const DEFAULT_PER_PAGE = 20;
const DEFAULT_SORT_ORDER = 'asc';

let Context: React.Context<any> | undefined = undefined;
function createDataTableContext<T extends IGridRow>(): React.Context<
  IDataTableContext<T>
> {
  if (Context) {
    return Context;
  }
  Context = React.createContext<IDataTableContext<T>>({
    dataGridInitialState: {},
    state: {
      itemsPerPage: DEFAULT_PER_PAGE,
      page: 1,
      selected: [],
      sort: {
        _id: 'desc',
      },
      criteria: {
        conditions: [],
        operator: 'or',
      },
      visibleColumns: [],
    },
    setter: () => {},
    columns: [],
    queryKey: [],
    query: undefined,
  });

  return Context;
}

export function useDataTableContext<T extends IGridRow>() {
  const context = React.useContext<IDataTableContext<T>>(
    createDataTableContext<T>()
  );
  if (context === undefined) {
    throw new Error(
      'useDataTableContext must be used within a DataTableProvider'
    );
  }
  return context;
}

export const Container = <T extends IGridRow>({
  fetcher,
  columns,
  queryKey,
  stateExternalHandlers,
  dataGridInitialState,
  defaults,
  onFetchSuccess,
  children,
}: TheDatatableProps<T>): React.ReactElement => {
  const Context = createDataTableContext<T>();
  const stringifiedKey = queryKey.join(',');

  const stateInternalHandlers = useQueryString<DatatableState>(
    `${stringifiedKey}-state`,
    {
      persisted: false, //temp until we figure out a clean way to invalidate  LS cache :((((
      initialValue: {
        ...DatatableUtils.getInitialState(),
        visibleColumns: columns.map((column) => column.field),
        ...defaults,
      },
      syncOnMount: true,
      formatter: (oldValue, initialValue) => ({
        ...initialValue,
        ...(typeof oldValue === 'string' ? JSON.parse(oldValue) : oldValue),
      }),
    }
  );

  const handlers = stateExternalHandlers || stateInternalHandlers;
  const [state, setter] = handlers;
  const query = useQuery<IPaginationResponse<T>, BaseAxiosErrorResponse>(
    DatatableUtils.getQueryDataKey(state, queryKey),
    () => {
      return fetcher({
        itemsPerPage: state.itemsPerPage,
        sort: state.sort,
        page: state.page,
        criteria: {
          ...state.criteria,
          options: state.criteria.options,
          conditions: state.criteria.conditions
            .filter(
              (condition) =>
                condition.propertyPath && condition.operator && condition.value
            )
            .map((condition) => ({
              column: condition.propertyPath,
              value: condition.value,
              operator: condition.operator,
            })),
        },
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      cacheTime: 0,
      initialData: DatatableUtils.createInitialPaginationResponse<T>({
        meta: { perPage: DEFAULT_PER_PAGE },
      }),
      keepPreviousData: true,
      onSuccess: onFetchSuccess,
    }
  );
  const context: IDataTableContext<T> = React.useMemo(
    () => ({
      state,
      dataGridInitialState,
      setter,
      queryKey,
      columns,
      query,
    }),
    [columns, setter, state, queryKey, query, dataGridInitialState]
  );

  return (
    <Context.Provider value={context}>
      <div style={{ position: 'relative', width: '100%' }}>{children}</div>
    </Context.Provider>
  );
};

const CustomNoRowsOverlay: React.FCC<
  GridSlotsComponentsProps & { message?: string }
> = (props: GridSlotsComponentsProps & { message?: string }) => {
  const { t } = useEssentials();
  return (
    <GridOverlay>
      <span>{t(props.message || 'No data available at this moment')}</span>
    </GridOverlay>
  );
};

type TableProps<T extends IGridRow = any> = Pick<
  DataGridProps<T>,
  | 'checkboxSelection'
  | 'disableSelectionOnClick'
  | 'disableColumnMenu'
  | 'autoHeight'
  | 'onCellEditCommit'
  | 'processRowUpdate'
  | 'rowHeight'
  | 'getRowHeight'
> & {
  onCellEditStop?: (
    parameters: GridCellEditStopParams<any, T>,
    event: React.ChangeEvent<HTMLInputElement>,
    details: GridCallbackDetails
  ) => void;
  noDataMessage?: string;
  onRowSelectionChange?: (rowId: string, selected: boolean) => void;
  dynamicColumns?: (
    queryData: InteliamUseQueryResult<
      IPaginationResponse<T, unknown>,
      BaseAxiosErrorResponse<unknown, unknown, unknown>
    >
  ) => Array<IFilterableColumn<T>>;
};
export const Table = <T extends IGridRow>({
  checkboxSelection = true,
  disableSelectionOnClick = true,
  disableColumnMenu = true,
  noDataMessage = 'No data available at this moment',
  onRowSelectionChange = () => {},
  onCellEditStop,
  dynamicColumns,
  ...rest
}: TableProps<T>): React.ReactElement => {
  const { t } = useEssentials();

  const { state, dataGridInitialState, setter, columns, query } =
    useDataTableContext<T>();

  function handlePageChange(page: number) {
    setter((prevState) => ({ ...prevState, page: page + 1 }));
  }

  function handleSortChange(model: GridSortModel) {
    const field = model[0]?.field;
    const column = columns.find((c) => c.field === field);
    handleSort(model, column, setter, field);
  }

  const dynamicColumnsList =
    dynamicColumns && query ? dynamicColumns?.(query) : [];

  const rows = Helpers.ensureValueAsArray(query?.data?.data);
  return (
    <DataGrid<T>
      initialState={dataGridInitialState}
      rows={rows}
      experimentalFeatures={{ newEditingApi: true }}
      columns={columns
        .filter((column) => state.visibleColumns.includes(column.field))
        .concat(dynamicColumnsList)}
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
      }}
      componentsProps={{
        noRowsOverlay: { message: noDataMessage },
      }}
      rowsPerPageOptions={rows.length <= 2 ? [] : [10, 20, 50]}
      selectionModel={state.selected}
      pageSize={query?.data?.meta.perPage}
      loading={query?.isFetching}
      rowCount={query?.data?.meta.total || 0}
      paginationMode='server'
      pagination
      onPageChange={handlePageChange}
      sortingMode='server'
      onSortModelChange={handleSortChange}
      onPageSizeChange={(pageSize) => {
        setter((prevState) => ({
          ...prevState,
          itemsPerPage: pageSize,
        }));
      }}
      classes={{
        virtualScrollerRenderZone: 'rows',
      }}
      onSelectionModelChange={(selectionModel) => {
        const hasSelected = selectionModel.length > state.selected.length;
        // TODO: Due to a bug in MuiGrid, we won't be able to handle select All
        setter((prevState) => ({
          ...prevState,
          selected: Array.from(new Set(selectionModel)),
        }));
        if (hasSelected) {
          const lastSelected = selectionModel[selectionModel.length - 1];
          onRowSelectionChange(lastSelected as string, hasSelected);
        } else {
          // unselect
          const lastUnselected = state.selected[state.selected.length - 1];
          onRowSelectionChange(lastUnselected as string, hasSelected);
        }
      }}
      localeText={{
        footerTotalRows: t('Rows per page'),
      }}
      {...{
        checkboxSelection,
        disableSelectionOnClick,
        disableColumnMenu,
        ...rest,
      }}
      onCellEditStop={(parameters, event, details) => {
        // Only enter key will validate the edition
        if (GridCellEditStopReasons.enterKeyDown !== parameters.reason) {
          return;
        }
        const _event = event as React.ChangeEvent<HTMLInputElement>;
        onCellEditStop?.(
          parameters as GridCellEditStopParams<any, T>,
          _event,
          details
        );
      }}
    />
  );
};

interface TableToolbarProps {
  onDelete?: (id: Id) => Promise<IAPIResponse>;
}

export const Toolbar: React.FCC<TableToolbarProps> = ({
  onDelete,
  children,
}) => {
  const { state, setter, columns, queryKey } = useDataTableContext();
  const handleChangeVisibility: VisibilityChangerFn = React.useCallback(
    (field, nextValue) => {
      setter((prevState) => ({
        ...prevState,
        visibleColumns: nextValue
          ? [...prevState.visibleColumns, field]
          : prevState.visibleColumns.filter((c) => c !== field),
      }));
    },
    [setter]
  );

  const visibilityColumns = React.useMemo(
    () =>
      columns
        .filter((column) => column.field)
        .map((column) => ({
          ...column,
          value: column.field,
          label: column.headerName || '-',
          visible: state.visibleColumns.includes(column.field),
        })),
    [state.visibleColumns, columns]
  );
  const handleOnSuccessDelete = React.useCallback(() => {
    setter((prevState) => ({ ...prevState, selected: [] }));
  }, [setter]);

  return (
    <TableToolbar
      queryKey={queryKey}
      selected={state.selected}
      onDelete={onDelete}
      onDeleteSuccess={handleOnSuccessDelete}
      onChangeVisibility={handleChangeVisibility}
      columns={visibilityColumns}
      filter={state.criteria}
      onChangeFilter={(criteria) =>
        setter((prevState) => ({
          ...prevState,
          page: DatatableUtils.getInitialState().page,
          criteria: {
            ...prevState.criteria,
            ...criteria,
          },
        }))
      }
    >
      {children}
    </TableToolbar>
  );
};

function handleSort(
  model: GridSortModel,
  column: IFilterableColumn | undefined,
  setter: React.Dispatch<React.SetStateAction<DatatableState>>,
  field: string
) {
  if (model.length === 0 || !column) {
    setter((prevState) => ({
      ...prevState,
      sort: DatatableUtils.DEFAULT_SORTER,
    }));
  } else {
    setter((prevState) => ({
      ...prevState,
      sort: {
        [column.sort?.field || field]: model[0].sort || DEFAULT_SORT_ORDER,
      },
    }));
  }
}
