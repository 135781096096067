import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterpriseSustainabilityCardsApi } from '@core/api';
import type { ISustainabilityCard } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchEnterpriseSustainabilityCardById = ({
  manufacturerId,
  sustainabilityCardId,
}: {
  manufacturerId: string;
  sustainabilityCardId: string;
}): InteliamUseQueryResult<
  IAPIResponse<ISustainabilityCard>,
  BaseAxiosErrorResponse
> => {
  return useQuery<IAPIResponse<ISustainabilityCard>, BaseAxiosErrorResponse>(
    [
      ...QUERY_KEYS.MANUFACTURER_SUSTAINABILITY_CARDS_LIST(manufacturerId),
      sustainabilityCardId,
    ],
    () =>
      EnterpriseSustainabilityCardsApi.getOneById(
        manufacturerId,
        sustainabilityCardId
      ),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      cacheTime: 1000 * 20,
    }
  );
};

export default useFetchEnterpriseSustainabilityCardById;
