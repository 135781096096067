import {
  IAnalysisHighlightsKindEnum,
  IHighlightsStatusEnum,
} from '@inteliam/foundation/lib/enums';
import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useCallback } from 'react';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { AnalysisHighlightsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';

import { DatatableUtils } from '@shared/utils';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  IAPIResponse,
  ICustomAnalysisHighlight,
  Id,
  IIdentifiedObject,
  IMasterAnalysisHighlight,
  IPaginationResponse,
  IPatchObject,
  IQueryManagementArgs,
  IQueryManagementResult,
  PatchBody,
} from '@inteliam/foundation/lib/types';

// => React basics
type IEnabledQueries = {
  paginate?: boolean;
  getOneById?: boolean;
};

type IFetchingQueryResult<T> = {
  paginate: {
    query: InteliamUseQueryResult<
      IPaginationResponse<T>,
      BaseAxiosErrorResponse
    > & {
      ensuredData: Array<T>;
    };
  };
};

const useManageAnalysisHighlights = <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlight extends IIdentifiedObject = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight,
  THighlightForm = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? Partial<IMasterAnalysisHighlight>
    : ICustomAnalysisHighlight
>(
  kind: IAnalysisHighlightsKindEnum,
  args: IQueryManagementArgs<
    THighlight,
    THighlightForm,
    THighlightForm,
    'create' | 'update' | 'patch' | 'remove'
  > = {},
  enabled: IEnabledQueries = {
    paginate: false,
    getOneById: false,
  }
): IQueryManagementResult<
  THighlight,
  THighlightForm,
  THighlightForm,
  'create' | 'update' | 'patch' | 'remove'
> &
  IFetchingQueryResult<THighlight> => {
  const { t, notify, navigate, confirm } = useEssentials();

  const createMutation = useMutation<
    IAPIResponse<THighlight>,
    AxiosValidationErrorResponse,
    THighlightForm
  >((payload) => AnalysisHighlightsApi.create(payload, kind), {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
    onSuccess: () => {
      notify({
        message: t('The Analysis Highlight was successfully created'),
        type: INotifierEnum.Success,
      });

      navigate('/master-highlights');
    },
    ...args.create,
  });

  const updateMutation = useMutation<
    IAPIResponse<THighlight>,
    AxiosValidationErrorResponse,
    IPatchObject<THighlightForm>
  >((payload) => AnalysisHighlightsApi.update(payload, kind), {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
    onSuccess: () => {
      notify({
        message: t('Master Analysis Highlight successfully updated'),
        type: INotifierEnum.Success,
      });

      if (kind === IAnalysisHighlightsKindEnum.MASTER) {
        navigate('/master-highlights');
      }
    },
    ...args.update,
  });

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >((payload) => AnalysisHighlightsApi.patch(payload, kind), {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
    onSuccess: () => {
      notify({
        message: t('Master Analysis Highlight status successfully updated'),
        type: INotifierEnum.Success,
      });
    },
    ...args.patch,
  });

  const removeMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse, //BaseAxiosErrorResponse,
    Id //THighlight['id']
  >((id) => AnalysisHighlightsApi.remove(id, kind), {
    keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
    onSuccess: () => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
    },
    // ...args.remove,
  });

  const paginateQuery = useQuery<
    IPaginationResponse<THighlight>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
    () => {
      return AnalysisHighlightsApi.paginate(
        {
          itemsPerPage: -1,
          sort: { id: 'desc' },
          page: 1,
          criteria: {
            operator: 'or',
            conditions: [
              {
                column: 'status',
                value: IHighlightsStatusEnum.STATUS_ACTIVE,
                operator: 'eq',
              },
            ],
          },
        },
        kind
      );
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData: DatatableUtils.createInitialPaginationResponse<THighlight>(),
      enabled: enabled.paginate,
    }
  );

  const runCreate = useCallback(
    (formData: THighlightForm) => {
      createMutation.mutate(formData);
    },
    [createMutation]
  );
  const runUpdate = useCallback(
    (formData: THighlightForm, id: THighlight['id']) => {
      updateMutation.mutate({
        id: id,
        body: formData,
      });
    },
    [updateMutation]
  );

  const runPatch = useCallback(
    (body: PatchBody, id: THighlight['id']) => {
      patchMutation.mutate({
        id,
        body,
      });
    },
    [patchMutation]
  );

  const runRemove = useCallback(
    (id: THighlight['id']) => {
      confirm({
        description: t('Are you sure ?'),
        content: t(
          'Are you sure you want to delete this Custom Analysis Highlight ? '
        ),
      })
        .then(() => {
          removeMutation.mutate(id);
        })
        .catch(() => {});
    },
    [confirm, removeMutation, t]
  );

  return {
    create: {
      run: runCreate,
      mutation: createMutation,
    },
    update: {
      run: runUpdate,
      mutation: updateMutation,
    },
    patch: {
      run: runPatch,
      mutation: patchMutation,
    },
    remove: {
      run: runRemove,
      mutation: removeMutation,
    },
    paginate: {
      query: {
        ...paginateQuery,
        ensuredData: Helpers.ensureValueAsArray(paginateQuery.data?.data),
      },
    },
  };
};
export default useManageAnalysisHighlights;
