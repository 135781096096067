/* eslint-disable sonarjs/no-duplicate-string */
import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { Transformers } from '@inteliam/foundation/lib/utils';
import { InputLabel } from '@mui/material';

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { useManageEnterprises } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IDistributorForm } from '@core/types';

import { Controls, FormGroup, Grid } from '@shared/components';

import { DatatableUtils } from '@shared/utils';

interface Props {
  name: keyof IDistributorForm;
}

const useFetchHierarchy = ({ name }: Props) => {
  const methods = useFormContext<IDistributorForm>();
  const watchedLevels = methods.watch(
    name
  ) as IDistributorForm['memberOfHierarchy'];
  const { paginate: paginateLevel0 } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
        primaryCriteria: {
          conditions: [
            DatatableUtils.createCondition(
              'kind',
              'eq',
              ICompanyKindEnum.TRADING_GROUP
            ),
            DatatableUtils.createCondition('level', 'eq', 0),
          ],
          operator: 'and',
        },
      },
    },
  });
  const { paginate: paginateLevel1 } = useManageEnterprises({
    paginate: {
      options: {
        enabled: !!watchedLevels[0],
      },
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
        primaryCriteria: {
          conditions: [
            DatatableUtils.createCondition(
              'kind',
              'eq',
              ICompanyKindEnum.TRADING_GROUP
            ),
            DatatableUtils.createCondition('parent.id', 'eq', watchedLevels[0]),
            DatatableUtils.createCondition('level', 'eq', 1),
          ],
          operator: 'and',
        },
      },
    },
  });
  const { paginate: paginateLevel2 } = useManageEnterprises({
    paginate: {
      options: {
        enabled: !!watchedLevels[1],
      },
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
        primaryCriteria: {
          conditions: [
            DatatableUtils.createCondition(
              'kind',
              'eq',
              ICompanyKindEnum.TRADING_GROUP
            ),
            DatatableUtils.createCondition('parent.id', 'eq', watchedLevels[1]),
            DatatableUtils.createCondition('level', 'eq', 2),
          ],
          operator: 'and',
        },
      },
    },
  });

  return {
    data: [
      paginateLevel0.data?.data || [],
      paginateLevel1.data?.data || [],
      paginateLevel2.data?.data || [],
    ],
  };
};

const LEVELS = [0, 1, 2];

const MemberOfField: React.FCC<Props> = ({ name }) => {
  const { t } = useEssentials();
  const methods = useFormContext<IDistributorForm>();
  const watchedLevels = methods.watch(
    name
  ) as IDistributorForm['memberOfHierarchy'];

  const { data } = useFetchHierarchy({ name });

  return (
    <FormGroup>
      <InputLabel style={{ marginBottom: 10 }}>{t('Member of TG')}</InputLabel>
      <Grid container spacing={2}>
        {LEVELS.map((level) => (
          <Grid item md={4} xs={12} key={level}>
            <Controls.FormSelect
              label={t('Level {{level}}', { level: level })}
              name={`${name}.${level}`}
              options={Transformers.mapIntoOptionItem(
                data[level],
                'name',
                'id'
              )}
              readOnly={level !== 0 && !watchedLevels[level - 1]}
              onChange={() => {
                // when a level has changed, reset all the levels above
                LEVELS.filter((_level) => _level > level).forEach((_level) => {
                  methods.setValue(
                    `${name}.${_level}` as keyof IDistributorForm,
                    ''
                  );
                });
              }}
            />
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  );
};

export default React.memo(MemberOfField);
