import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { StringFormatter, Helpers } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useFetchCompanyUsers } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IBODistributor } from '@core/types';

import {
  Button,
  Controls,
  Typography,
  Grid,
  FormGroup,
  CircularProgress,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type {
  ContactType,
  IFODistributorUser,
} from '@inteliam/foundation/lib/types';

interface Props {
  company?: IBODistributor;
  contactType: ContactType;
  onChangeUser?: (user: IFODistributorUser) => void;
  onEdit: (user: IFODistributorUser) => void;
  onAdd: () => void;
}

const useStyles = makeStyles(() => ({
  autoWidth: {
    width: 'auto',
  },
  form: {
    width: '100%',
  },
  distributorUserForm: {
    margin: '30px 0',
  },
}));

const config = {
  SURVEY: {
    fieldName: 'questionnaireContact.id',
    fieldLabel: 'Questionnaire contact',
    title: 'Questionnaire contact',
  },
  PRIMARY: {
    fieldName: 'scoredCompany.primaryContact.id',
    fieldLabel: 'Primary contact',
    title: 'Primary contact',
  },
};

const ContactSelector: React.FCC<Props> = ({
  company,
  contactType,
  onAdd,
  onEdit,
  onChangeUser,
}) => {
  const classes = useStyles();
  const { t } = useEssentials();
  const methods = useFormContext();
  const selectedContact = useWatch({
    control: methods.control,
    name: config[contactType].fieldName,
    defaultValue: '',
  });

  const companyUsersQuery = useFetchCompanyUsers({
    kind: ICompanyKindEnum.DISTRIBUTOR,
    id: company?.id,
  });

  const users =
    companyUsersQuery.data?.data?.map((user) => ({
      label: StringFormatter.formatName(
        user.contactInfo.firstName,
        user.contactInfo.lastName
      ),
      value: user.id,
    })) || [];

  useEffect(() => {
    if (selectedContact && companyUsersQuery.isSuccess) {
      methods.setValue(config[contactType].fieldName, selectedContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id, contactType, selectedContact, companyUsersQuery.isSuccess]);

  return (
    <Grid container spacing={3}>
      <Grid
        item
        container
        xs={12}
        md={3}
        alignItems='center'
        justifyContent='center'
      >
        <Typography component='h6' variant='h6'>
          {t(config[contactType].title)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormGroup>
          {companyUsersQuery.isSettled ? (
            <Controls.FormSelect
              fullWidth
              label={t(config[contactType].fieldLabel)}
              name={config[contactType].fieldName}
              disabled={isNil(company)}
              options={users}
              onChange={(event) => {
                const userToUpdate = Helpers.ensureValueAsArray(
                  companyUsersQuery.data?.data
                ).find((user) => user.id === event.target.value);
                if (!userToUpdate) {
                  return;
                }

                onChangeUser?.(userToUpdate);
              }}
              required
            />
          ) : (
            <CircularProgress color='inherit' size={16} />
          )}
        </FormGroup>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={3}
        spacing={3}
        alignItems='center'
        justifyContent='center'
      >
        <Grid item container alignItems='center' className={classes.autoWidth}>
          <Button
            id={`edit-${contactType}-user`.toLowerCase()}
            color='primary'
            variant='contained'
            disabled={!selectedContact || isNil(company)}
            onClick={() => {
              const userToUpdate = Helpers.ensureValueAsArray(
                companyUsersQuery.data?.data
              ).find((user) => user.id === selectedContact);
              if (!userToUpdate) {
                return;
              }
              onEdit(userToUpdate);
            }}
          >
            {t('Edit')}
          </Button>
        </Grid>
        <Grid item container alignItems='center' className={classes.autoWidth}>
          <Button
            id={`add-${contactType}-user`.toLowerCase()}
            color='primary'
            variant='contained'
            onClick={onAdd}
          >
            {t('Add')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default memo(ContactSelector);
