/* eslint-disable @inteliam/i18n/raw-text-detected */
import { I18n } from '@inteliam/foundation/lib/utils';
import { noop } from 'lodash-es';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { FormCompanyWeightSelect, ScoreInput } from '@core/components';

import { useScoreCardHandlers, useWorkingPage } from '@core/hooks';

import { useWorkingPageContext } from '@core/contexts';

import {
  Accordion,
  Box,
  Controls,
  ExpandMoreIcon,
  Grid,
  Typography,
} from '@shared/components';

import type { ICriteriaPartialScore } from '@inteliam/foundation/lib/types';

type Props = {
  children: React.ReactNode;
  themeIndex: number;
  criterionIndex: number;
};

const AnswersAnalysisCriterionAccordion: React.FCC<{
  criterionPartialScore: ICriteriaPartialScore;
  fieldName: string;
  themeIndex: number;
  scoreCardId: string;
}> = memo(({ criterionPartialScore, themeIndex, fieldName, scoreCardId }) => {
  const methods = useForm();
  const { onUpdate } = useScoreCardHandlers();
  const { readOnly } = useWorkingPageContext();
  const criterionName = I18n.getTranslatedValue(
    criterionPartialScore.criterion.name
  );

  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={noop}
      style={{ width: '100%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box display='flex' alignItems='center' p={1}>
            <Typography>{criterionName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <FormCompanyWeightSelect
            readOnly={readOnly}
            id={`${criterionPartialScore.criterion.code}-criterion-weight`}
            defaultWeight={criterionPartialScore.scoringMeta.weight}
            onChange={(value) => {
              onUpdate(
                {
                  [`${fieldName}.scoringMeta.weight`]: value,
                },
                scoreCardId
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ScoreInput
            readOnly={readOnly}
            name={`${fieldName}.scoringMeta.score`}
            key={criterionPartialScore.scoringMeta.score ?? 0}
            defaultValue={criterionPartialScore.scoringMeta.score ?? 0}
            additionalUpdate={{
              [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                'AGGREGATED_SCORING',
            }}
          />
        </Grid>
      </Grid>
    </Controls.Form>
  );
});

const AnswersAnalysisCriterionAccordionContainer: React.FCC<Props> = ({
  children,
  themeIndex,
  criterionIndex,
}) => {
  const { scoreCard } = useWorkingPage();
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const criterionPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];
  const fieldName = `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}]`;
  const criterionName = I18n.getTranslatedValue(
    criterionPartialScore.criterion.name
  );

  return (
    <Accordion.Container
      defaultExpanded={false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Accordion.Summary
        id={`${criterionName}-accordion`}
        expandIcon={<ExpandMoreIcon />}
      >
        <AnswersAnalysisCriterionAccordion
          scoreCardId={scoreCard.id}
          themeIndex={themeIndex}
          fieldName={fieldName}
          criterionPartialScore={criterionPartialScore}
        />
      </Accordion.Summary>
      <Accordion.Details style={{ display: 'grid', padding: 0 }}>
        {children}
      </Accordion.Details>
    </Accordion.Container>
  );
};

export default memo(AnswersAnalysisCriterionAccordionContainer);
