import { isParsableDate } from '@inteliam/foundation/lib/guards';
import { formatISO } from 'date-fns';

/**
 * is date range guard
 * @param arg
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRangeDate(argument: any): argument is { from: Date; to: Date } {
  return isParsableDate(argument.from) && isParsableDate(argument.to);
}

const normalizer = (value: unknown): string => {
  if (isRangeDate(value)) {
    return JSON.stringify({
      from: formatISO(new Date(value.from)),
      to: formatISO(new Date(value.to)),
    });
  }
  throw new Error('[Date range Filterer] : value should have from/to fields');
};

export default normalizer;
