import { DEFAULT_ONE_TIME_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { ContactLogsApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IOptionItem,
} from '@inteliam/foundation/lib/types';

const useFetchContactLogEventEnums = (): {
  events: Array<IOptionItem> | undefined;
  isLoading: boolean;
} => {
  const { t } = useEssentials();
  const mapIntoSelect = (item: string): IOptionItem => ({
    value: item,
    label: t(item),
  });
  const { data, isLoading, isFetching } = useQuery<
    IAPIResponse<Array<string>> | undefined,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.CONTACT_LOG_EVENT_ENUMS,
    () => {
      return ContactLogsApi.getEventEnums();
    },
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: Number.POSITIVE_INFINITY,
    }
  );

  let events = undefined;
  if (data?.data !== undefined) {
    events = data.data.map((item) => mapIntoSelect(item));
  }
  return {
    events: events,
    isLoading: isLoading || isFetching,
  };
};

export default useFetchContactLogEventEnums;
