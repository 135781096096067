import { ITitlesEnum } from '@inteliam/foundation/lib/enums';

type ITitlesPreset = {
  [key in ITitlesEnum]: {
    value: ITitlesEnum;
    label: string;
  };
};

export const TITLES: ITitlesPreset = {
  [ITitlesEnum.MRS]: {
    value: ITitlesEnum.MRS,
    label: 'Mrs',
  },
  [ITitlesEnum.MR]: {
    value: ITitlesEnum.MR,
    label: 'Mr',
  },
  [ITitlesEnum.MISS]: {
    value: ITitlesEnum.MISS,
    label: 'Miss',
  },
  [ITitlesEnum.MS]: {
    value: ITitlesEnum.MS,
    label: 'Ms',
  },
  [ITitlesEnum.DR]: {
    value: ITitlesEnum.DR,
    label: 'Dr',
  },
};
