import {
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

import * as React from 'react';
import MaskedInput from 'react-text-mask';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | undefined) => void;
  mask: (string | RegExp)[];
}

export type Props = {
  label: string;
  name: string;
  value: string;
  defaultValue?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  mask: (string | RegExp)[];
};

const TextMaskCustom = React.forwardRef<
  HTMLInputElement,
  InputBaseComponentProps | TextMaskCustomProps
>((props, ref) => {
  return (
    <MaskedInput
      {...props}
      mask={props.mask}
      // @ts-expect-error worst problem with react and typescript
      ref={ref}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});

const MaskableInput: React.FCC<Props> = ({
  label,
  name,
  value,
  defaultValue,
  onChange,
  mask,
}) => {
  return (
    <>
      <InputLabel shrink>{label}</InputLabel>
      <OutlinedInput
        {...{ onChange, name, value, defaultValue, label }}
        inputComponent={TextMaskCustom}
        notched
        inputProps={{ mask, 'data-cy': name }}
      />
    </>
  );
};

export default MaskableInput;
