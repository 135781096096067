import {
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@mui/material';

import * as React from 'react';

type SwitchProps = MUISwitchProps & { id: string };

// Note: use this when you want Switch styled as Button
export const Switch: React.FCC<SwitchProps> = (props) => {
  return <MUISwitch color='primary' {...props} />;
};
