import { makeStyles } from '@shared/utils';

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  enabledSwitch: {
    color: 'primary',
  },
  disabledSwitch: {
    color: 'secondary',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  neutralLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

function useGlobalStyle(): ReturnType<typeof useStyles> {
  return useStyles();
}

export default useGlobalStyle;
