import type { TextFieldProps } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';

import * as React from 'react';

import { TextField, CircularProgress } from '@shared/components';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

interface BaseProps<T> {
  options: T[];
  label: React.ReactNode;
  name?: string | undefined;
  loading?: boolean;
  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (options: T, value: T) => boolean;
}

type BaseAutocompleteProps<T> = AutocompleteProps<
  T,
  true | false | undefined,
  undefined,
  undefined
>;
type RequiredAutocompleteProps<T> = Omit<
  BaseAutocompleteProps<T>,
  'renderInput'
>;
type RequiredTextFieldProps = Pick<
  TextFieldProps,
  'inputRef' | 'required' | 'fullWidth' | 'helperText' | 'error'
>;
export type TypeaheadProps<T extends IOptionItem> =
  RequiredAutocompleteProps<T> &
    RequiredTextFieldProps &
    BaseProps<T> & { name: string; id?: string };

const Typeahead = <T extends IOptionItem>({
  options,
  loading = false,
  getOptionLabel = (value) => value.label ?? '',
  isOptionEqualToValue = (option, value) => option.value === value.value,
  label,
  name,
  error,
  helperText,
  inputRef,
  multiple,
  onChange,
  required,
  id,
  ...rest
}: TypeaheadProps<T>): React.ReactElement => {
  return (
    <Autocomplete
      {...{ options, isOptionEqualToValue, getOptionLabel, multiple, onChange }}
      selectOnFocus
      clearOnBlur={false}
      handleHomeEndKeys
      {...rest}
      renderInput={(parameters) => {
        return (
          <TextField
            {...parameters}
            ref={inputRef}
            {...{ label, name, error, helperText, required }}
            id={id ?? name}
            autoComplete='off'
            inputProps={{
              ...parameters.inputProps,
              autoComplete: 'off',
              form: {
                autoComplete: 'off',
              },
            }}
            InputProps={{
              ...parameters.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : undefined}
                  {parameters.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};
export default Typeahead;
