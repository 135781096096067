import {
  IReviewFiltrableStatusEnum,
  IReviewKindEnum,
  IReviewPublicationEnum,
  IReviewStatusEnum,
} from '@inteliam/foundation/lib/enums';

type IReviewFiltrableStatusPreset = {
  [key in IReviewFiltrableStatusEnum]: {
    value: IReviewFiltrableStatusEnum;
    label: string;
    tag: 'STATUS_ACTIVE' | 'STATUS_INACTIVE';
  };
};

export const REVIEW_FILTRABLE_STATUS: IReviewFiltrableStatusPreset = {
  [IReviewFiltrableStatusEnum.STATUS_ACTIVE]: {
    value: IReviewFiltrableStatusEnum.STATUS_ACTIVE,
    label: 'enabled',
    tag: 'STATUS_ACTIVE',
  },
  [IReviewFiltrableStatusEnum.STATUS_INACTIVE]: {
    value: IReviewFiltrableStatusEnum.STATUS_INACTIVE,
    label: 'disabled',
    tag: 'STATUS_INACTIVE',
  },
};

export const REVIEW_ENUMS = {
  TYPES: [
    {
      label: 'Strength',
      value: IReviewKindEnum.Strength,
    },
    {
      label: 'Improvement area',
      value: IReviewKindEnum.Weakness,
    },
  ],
  STATUS: [
    {
      label: 'active',
      value: IReviewStatusEnum.STATUS_ACTIVE,
    },
    {
      label: 'inactive',
      value: IReviewStatusEnum.STATUS_INACTIVE,
    },
    {
      label: 'draft',
      value: IReviewStatusEnum.STATUS_DRAFT,
    },
  ],
  PUBLICATION: [
    {
      label: 'draft',
      value: IReviewPublicationEnum.DRAFT,
    },
    {
      label: 'published',
      value: IReviewPublicationEnum.PUBLISHED,
    },
  ],
};
