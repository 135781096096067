import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';
import type { IEvaluationProgress } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchDocumentsEvaluationGlobalInsights = ({
  arId,
  options,
}: {
  arId: string;
  options?: InteliamUseQueryOptions<
    IAPIResponse<IEvaluationProgress>,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IAPIResponse<IEvaluationProgress>,
  BaseAxiosErrorResponse
> & { evaluationProgress?: IEvaluationProgress } => {
  const query = useQuery<
    IAPIResponse<IEvaluationProgress>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.DOCUMENTS_EVALUATION_PROGRESS, arId],
    () => DocumentEvaluationsApi.getGlobalInsights(arId),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: 1000 * 25,
      ...options,
    }
  );
  return { ...query, evaluationProgress: query.data?.data };
};

export default useFetchDocumentsEvaluationGlobalInsights;
