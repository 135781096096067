/* eslint-disable sonarjs/no-duplicate-string */
import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const create = Yup.object().shape({
  title: Yup.string().required(ErrorCodesLookup.E0001.title),
  description: Yup.string().required(ErrorCodesLookup.E0001.title),
  website: Yup.string().required(ErrorCodesLookup.E0001.title),
  publishedAt: Yup.date().required(ErrorCodesLookup.E0001.title),
});
