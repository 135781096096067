import { yupResolver } from '@hookform/resolvers/yup';
import { IAnalysisHighlightsKindEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions, HighlightSchema } from '@core/components';

import {
  QUERY_KEYS,
  useManageAnalysisHighlights,
  useManageAssessmentRequest,
} from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';

import { Controls, FormGroup, Grid } from '@shared/components';

import type { ITranslatableField } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  assessmentRequestId: string;
  open: boolean;
  onClose: () => void;
}

interface IAnalysisHighlightForm {
  description: ITranslatableField;
}

const CreateCustomAnalysisHighlightModal: React.FCC<Props> = ({
  assessmentRequestId,
  open,
  onClose,
}) => {
  const { t, notify } = useEssentials();
  const methods = useForm<IAnalysisHighlightForm>({
    defaultValues: {},
    resolver: yupResolver<typeof HighlightSchema.createCustomAnalysisHighlight>(
      HighlightSchema.createCustomAnalysisHighlight
    ),
  });
  const { getOneById } = useManageAssessmentRequest({
    getOneById: {
      args: { id: assessmentRequestId },
    },
  });
  const { create } = useManageAnalysisHighlights(
    IAnalysisHighlightsKindEnum.CUSTOM,
    {
      create: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.ANALYSIS_HIGHLIGHTS],
        onSuccess: () => {
          onClose();
          notify({
            message: t('The Analysis Highlight was successfully created'),
            type: INotifierEnum.Success,
          });
        },
      },
    }
  );

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Create a new Analysis Highlight')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        {getOneById.isSuccess && (
          <Controls.Form
            autoComplete='off'
            noValidate
            methods={methods}
            submitHandler={(data) =>
              create.run({
                ...data,
                distributor: getOneById.data.data.scoredCompany,
              })
            }
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    name='description.defaultValue'
                    label={t('Text')}
                    required
                    placeholder={t('Add text related to the news here')}
                    rows={3}
                    multiline
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <FormActions
              isLoading={create.mutation.isLoading}
              onCancel={onClose}
              cancelText='cancel'
            />
          </Controls.Form>
        )}
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateCustomAnalysisHighlightModal);
