import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type {
  IAPIResponse,
  IClassification,
  ICriterionConfiguration,
  IPaginationQuery,
  IPaginationResponse,
  IQuestion,
  IRawClassification,
  IThemeConfiguration,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/surveys',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getClassifications = async ({
  reviewsAssignableOnly = false,
  questionnaireType = 'INTELIAM',
}: {
  reviewsAssignableOnly: boolean;
  questionnaireType?: IThemeConfiguration['questionnaireType'];
}): Promise<IAPIResponse<Array<IClassification>>> => {
  const queryParameter = `?${
    reviewsAssignableOnly === true ? 'reviewsAssignable=true' : ''
  }&themeType=${questionnaireType}`;
  const { data } = await client.instance.get(
    `/get-classification-hierarchy${queryParameter}`
  );
  return data;
};

const getCriteria = async (
  paginator: IPaginationQuery
): Promise<
  IAPIResponse<
    Array<
      IRawClassification & {
        theme: IRawClassification;
        configuration: ICriterionConfiguration;
      }
    >
  >
> => {
  const { data } = await client.instance.get('/get-criteria', {
    params: paginator,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });
  return data;
};

const getApplicableQuestions = async (qualificationData: {
  country: string;
  size: string;
  category: string;
}): Promise<IPaginationResponse<IQuestion>> => {
  const { data } = await client.instance.get('/applicable-questions', {
    params: qualificationData,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });

  return data;
};

export default {
  getClassifications,
  getCriteria,
  getApplicableQuestions,
};
