import { IARTransitionEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useAssessmentMachine } from '@core/hooks';

import { useDashboard } from '@core/contexts';

import type {
  IAR,
  ICancellationAR,
  IJSONObject,
} from '@inteliam/foundation/lib/types';

import Form from './form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}
const CancelAssessmentModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { dashboard } = useDashboard();
  const {
    transition: { run, mutation },
  } = useAssessmentMachine({
    assessmentRequest,
    dashboard,
  });

  const handleOnSubmit = (cancellationReason: ICancellationAR) => {
    run({
      transition: IARTransitionEnum.CANCEL,
      body: {
        cancellationReason: cancellationReason as IJSONObject,
      },
    })
      .then((response) => {
        if (response.status === 'SUCCESS') {
          onClose();
        }
      })
      .catch(() => {});
  };

  return (
    <Form
      open={open}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      isLoading={mutation.isLoading}
    />
  );
};

export default memo(CancelAssessmentModal);
