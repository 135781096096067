import { isArray } from 'lodash-es';

const roleNormalizer = (value: unknown): string => {
  if (isArray(value)) {
    return value.join(',');
  }
  throw new Error('[ROLE Filterer] : value should be an array');
};

export default roleNormalizer;
