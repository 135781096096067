import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

import InternalErrorPage from '@core/pages/errors/500';

import { useAuth } from '@core/contexts';

import { FullPageSpinner } from '@shared/components';

const AuthenticatedApp = lazy(() => import('./authenticated-app'));
const UnAuthenticatedApp = lazy(() => import('./unauthenticated-app'));

const App: React.FCC = () => {
  const { user } = useAuth();
  return (
    <React.Suspense fallback={<FullPageSpinner id='router' />}>
      <Routes>
        <Route path={'/500'} element={<InternalErrorPage />}></Route>
        <Route
          path='*'
          element={user ? <AuthenticatedApp /> : <UnAuthenticatedApp />}
        ></Route>
      </Routes>
    </React.Suspense>
  );
};

export default App;
