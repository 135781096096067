import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { useEssentials } from '@core/contexts';
import type { IMinimalSsqQuestion } from '@core/types';

import {
  Paper,
  Button,
  DndProvider,
  DragIndicatorSharpIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

/* eslint-disable @inteliam/i18n/raw-text-detected */
const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    height: '90px',
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '15px',
  },
  title: {
    fontSize: 14,
  },
  minus: {
    fontSize: 28,
    marginLeft: 'auto',
    color: 'red',
  },
}));

interface Props {
  questions: Array<IMinimalSsqQuestion>;
  onMove: (indexA: number, indexB: number) => void;
  onRemove: (id: string) => void;
}

const SsqQuestionList: React.FCC<Props> = ({
  questions = [],
  onMove,
  onRemove,
}) => {
  const classes = useStyles();
  const { t } = useEssentials();

  return (
    <div data-cy='questions-list'>
      <DndProvider onMove={onMove}>
        {({ getItemStyle }) => (
          <>
            {questions.map((question, index) => {
              return (
                <Draggable
                  key={question.id}
                  draggableId={question.id}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => {
                    return (
                      <div
                        ref={draggableProvided.innerRef}
                        data-cy='question-item'
                        {...draggableProvided.draggableProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style
                        )}
                      >
                        <Paper
                          className={classes.paper}
                          key={question.id}
                          elevation={3}
                        >
                          <span
                            {...(questions.length > 0 &&
                              draggableProvided.dragHandleProps)}
                            style={{ cursor: 'grabbing' }}
                          >
                            <DragIndicatorSharpIcon opacity='0.3' />
                          </span>
                          <div>
                            <div>
                              {t('Code:')} {question.code},{' '}
                            </div>
                            <div>
                              {t('Title:')}{' '}
                              {I18n.getTranslatedValue(question.title)}
                            </div>
                          </div>

                          <Button
                            onClick={() => onRemove(question.id)}
                            className={classes.minus}
                            id='remove-ssq-question'
                          >
                            -
                          </Button>
                        </Paper>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
          </>
        )}
      </DndProvider>
    </div>
  );
};
export default SsqQuestionList;
