import {
  IChannelEnum,
  ICommunicationEnum,
  IModeEnum,
  ITypeEnum,
} from '@inteliam/foundation/lib/enums';

import React, { memo, useCallback, useMemo } from 'react';

import { ContactLogForm } from '@core/components/contact-logs';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { ContactLogsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IContactLogForm, ICreateContactLogRequest } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IAR,
  IContactLog,
} from '@inteliam/foundation/lib/types';

type AssessmentRequestPageProps = {
  view: 'AR';
  assessmentRequest: IAR;
};
type DistributorPageProps = {
  view: 'DISTRIBUTOR';
  distributorId: string;
};

type RequiredProps = {
  open: boolean;
  onClose: () => void;
};
type Props = (AssessmentRequestPageProps | DistributorPageProps) &
  RequiredProps;

const CreateContactLogModal: React.FCC<Props> = (props) => {
  const { t, notify, user } = useEssentials();
  const mutation = useMutation<
    IAPIResponse<IContactLog>,
    AxiosValidationErrorResponse,
    ICreateContactLogRequest
  >(ContactLogsApi.create, {
    keysToInvalidateOnSuccess: [
      QUERY_KEYS.DASHBOARD.ENGAGEMENT,
      QUERY_KEYS.DASHBOARD.ANALYST,
      QUERY_KEYS.CONTACT_LOG_LIST,
    ],
    onSuccess: () => {
      props.onClose();
      notify({
        message: t('The contact log was successfully created'),
        type: INotifierEnum.Success,
      });
    },
  });
  const createContactLog = useCallback(
    (body: IContactLogForm) => {
      mutation.mutate({
        ...body,
        assessmentRequestId: body.assessmentRequestId,
        userId: user.id,
        type: ITypeEnum.MANUAL,
        companyId:
          props.view === 'AR'
            ? props.assessmentRequest.scoredCompany.id
            : props.distributorId,
      });
    },
    [mutation, user.id, props]
  );

  const handleOnSubmit = useCallback(
    (body: IContactLogForm) => {
      createContactLog({
        ...body,
      });
    },
    [createContactLog]
  );

  const defaultValues = useMemo(
    () => ({
      interactionDate: new Date(),
      eventSubject: ICommunicationEnum.COMMUNICATION,
      channel: IChannelEnum.EMAIL,
      files: [],
      details: '',
      contacts: [],
      distributorUsers: [],
      mode: IModeEnum.OUT,
    }),
    []
  );
  return (
    <ContactLogForm
      open={props.open}
      onClose={props.onClose}
      onSubmit={handleOnSubmit}
      isLoading={mutation.isLoading || mutation.isSuccess}
      defaultValues={defaultValues}
      errors={mutation.error?.response?.data}
      title={t('Add a new interaction')}
      {...(props.view === 'AR'
        ? {
            view: 'AR',
            assessmentRequest: props.assessmentRequest,
          }
        : {
            view: 'DISTRIBUTOR',
            distributorId: props.distributorId,
          })}
    />
  );
};

export default memo(CreateContactLogModal);
