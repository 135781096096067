import React, { memo } from 'react';

import { ButtonToggler } from '@core/components';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { ReferenceEvaluationsApi } from '@core/api';
import type { IDocumentEvaluation, IReferenceEvaluation } from '@core/types';

type Props = {
  referenceEvaluation: IReferenceEvaluation;
  documentEvaluation: IDocumentEvaluation;
};

const ReviewsReferenceStatusSwitch: React.FCC<Props> = ({
  referenceEvaluation,
  documentEvaluation,
}) => {
  const mutation = useMutation(ReferenceEvaluationsApi.evaluate, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.DOCUMENT_EVALUATIONS],
  });

  return (
    <ButtonToggler
      id={`ref-${documentEvaluation.document.name}`}
      currentStatus={referenceEvaluation.status}
      onEvaluate={(status) => {
        mutation.mutate({
          id: referenceEvaluation.id,
          status,
        });
      }}
    />
  );
};

export default memo(ReviewsReferenceStatusSwitch);
