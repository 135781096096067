/* eslint-disable @inteliam/i18n/raw-text-detected */
import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';
import { usePersistentState } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';

/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import type * as I18Next from 'i18next';

import { FullPageSpinner } from '@shared/components';

import { BOErrorTracking } from '@shared/utils';

import { useNamedContext } from '@shared/contexts';

import type { Dispatcher } from '@inteliam/foundation/lib/types';

import { LocalizerContext } from './contexts';

export interface TFunction {
  // basic usage
  <
    TKeys extends string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    TInterpolationMap extends object = I18Next.StringMap
  >(
    key: TKeys | TKeys[],
    options?: I18Next.TOptions<TInterpolationMap> | string
  ): string;
  // overloaded usage
  <
    TKeys extends I18Next.TFunctionKeys = string,
    TInterpolationMap extends object = I18Next.StringMap
  >(
    key: TKeys | TKeys[],
    defaultValue?: string,
    options?: I18Next.TOptions<TInterpolationMap> | string
  ): string;
}
const fetcher = (locale: ILanguagesEnum) =>
  import(`../../translations/${locale}.json`).then((module) => module.default);
export interface I18nContext {
  language: ILanguagesEnum;
  t: TFunction;
  setLanguage: Dispatcher<ILanguagesEnum>;
  localizingState: 'idle' | 'loading' | 'success' | 'error';
}

const INITIAL_CONTEXT: I18nContext = {
  language: I18n.defaultLocale,
  t: (
    key: I18Next.TFunctionKeys | I18Next.TFunctionKeys[],
    options?: I18Next.TOptions<I18Next.StringMap> | string
  ) => I18n.I18NextInstance.default.t(key, options),
  setLanguage: () => {},
  localizingState: 'idle',
};

const Localizer: React.FCC = (props) => {
  const [state, setState] = useState<I18nContext>(INITIAL_CONTEXT);
  const [language, setLanguage] = usePersistentState<ILanguagesEnum>(
    '_inteliam_user_language_',
    INITIAL_CONTEXT.language
  );

  const flipActivityIndicator = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      localizingState: 'loading',
    }));
  }, []);
  const setLocale = useCallback(
    async (newLocale: ILanguagesEnum) => {
      const prevLanguage = language;
      setLanguage(newLocale);
      flipActivityIndicator();
      try {
        await I18n.setUiLocale(newLocale, fetcher);
        setState((prev) => ({ ...prev, localizingState: 'success' }));
      } catch (error) {
        BOErrorTracking.report(error);
        // language not found
        setLanguage(prevLanguage);
        setState((prev) => ({ ...prev, localizingState: 'error' }));
      }

      // load different package for different language (eg moment locale)
      // importRequiredModulesForLocale(newLocale);
    },
    [flipActivityIndicator, language, setLanguage]
  );

  useEffect(() => {
    setLocale(language).catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const context = useMemo(
    () => ({
      ...state,
      setLanguage,
      language,
    }),
    [state, language, setLanguage]
  );

  if (state.localizingState === 'loading' || state.localizingState === 'idle') {
    return <FullPageSpinner id='i18n' />;
  }
  if (state.localizingState === 'error') {
    // Note Since the error happened in the i18n initialization, we have to show raw text
    return <p>Something went wrong</p>;
  }
  return (
    <LocalizerContext.Provider value={context}>
      {props.children}
    </LocalizerContext.Provider>
  );
};

function useI18n(): I18nContext {
  return useNamedContext(LocalizerContext);
}

const I18nProvider = Localizer;
export { useI18n, I18nProvider };
