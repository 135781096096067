import { ITitlesEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BaseUserForm } from '@core/components/users';

import { DISTRIBUTOR_ROLES } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { IDistributorUsersForm } from '@core/types';

import {
  Button,
  Card,
  IconButton,
  Grid,
  Box,
  Divider,
  RemoveCircleIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

const useStyles = makeStyles((theme: Theme) => ({
  childCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 5,
    marginTop: 10,
  },
}));

const ARRAY_DISTRIBUTOR_ROLES = Object.values(DISTRIBUTOR_ROLES);

const Contacts: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext<IDistributorUsersForm>();

  const { fields, append, remove } = useFieldArray<
    IDistributorUsersForm,
    'users',
    'rhfId'
  >({
    control: methods.control,
    name: 'users',
    keyName: 'rhfId',
  });
  const classes = useStyles();
  return (
    <Card.CContainer styled>
      <Card.Header
        title={t('Contacts Information')}
        subheader={t('Fill in the contacts information')}
      />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <h2>{t('Primary contact')}</h2>
          <Grid container spacing={3}>
            <BaseUserForm
              allowedRoles={ARRAY_DISTRIBUTOR_ROLES}
              baseName={'primaryContact'}
            />
          </Grid>
        </Box>
      </Card.Body>
      {fields.map((field, index) => {
        return (
          <Card.CContainer className={classes.childCard} key={field.rhfId}>
            <Card.Header
              action={
                <IconButton
                  id={`remove-contact-${field.contactInfo?.email || index}`}
                  color='secondary'
                  onClick={() => remove(index)}
                  size='large'
                >
                  <RemoveCircleIcon />
                </IconButton>
              }
            />
            <Card.Body>
              <Grid container spacing={3}>
                <BaseUserForm
                  defaultValues={field}
                  allowedRoles={ARRAY_DISTRIBUTOR_ROLES}
                  baseName={Helpers.arrayDotNotation('users', index)}
                />
              </Grid>
            </Card.Body>
          </Card.CContainer>
        );
      })}
      <Divider />
      <Card.Body>
        <Box display='flex' justifyContent='flex-start' p={2}>
          <Button
            id='manufacturer-form-button-add-user'
            color='primary'
            variant='contained'
            type='button'
            onClick={() =>
              append({
                contactInfo: {
                  title: ITitlesEnum.MR,
                  email: '',
                },
                roles: [],
              } as unknown as IDistributorUsersForm['users'][0])
            }
          >
            {t('Add Contact')}
          </Button>
        </Box>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(Contacts);
