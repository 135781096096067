import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';

import type { IAnyEnterprise } from '@inteliam/foundation/lib/types';

export function getKindTranslation(kind: IAnyEnterprise['kind']): string {
  switch (kind) {
    case ICompanyKindEnum.SUPPLIER: {
      return 'Supplier';
    }
    case ICompanyKindEnum.TRADING_GROUP: {
      return 'Trading group';
    }
    default: {
      return 'N/A';
    }
  }
}
