/**
 * For dev mode, we save the env variables in the localStorage so we can easily switch between environments (e.g. dev, test, qa, ...)
 */
const getVariable = (name: string, defaultValue?: string): string => {
  try {
    if (import.meta.env.MODE === 'development') {
      const value = localStorage.getItem(name);
      if (value) {
        // eslint-disable-next-line no-console
        console.warn(`Reading ${name} from local storage`);
        return JSON.parse(value);
      } else {
        // eslint-disable-next-line no-console
        console.warn(`Reading ${name} from .env`);
        return (import.meta.env?.[name] || defaultValue) as string;
      }
    }
    return import.meta.env?.[name] as string;
  } catch {
    // catching error in case of failed parsing from localStorage
    return import.meta.env?.[name] as string;
  }
};

export const BASE_URL = getVariable(
  'VITE_APP_BASE_URL',
  'http://localhost:8080'
);
export const APPLICATION_URL = getVariable('VITE_APP_APPLICATION_URL');
export const CLIENT_ID = getVariable(
  'VITE_APP_CLIENT_ID',
  'CLIENT_ID_IS_REQUIRED'
);
export const CLIENT_SECRET = getVariable(
  'VITE_APP_CLIENT_SECRET',
  'CLIENT_SECRET_IS_REQUIRED'
);
export const NODE_ENV = getVariable('MODE', 'development');
export const SENTRY_DSN = getVariable('VITE_APP_SENTRY_DSN');
export const SENTRY_ENV = getVariable('VITE_APP_SENTRY_ENV');
export const FRONT_OFFICE_URL = getVariable(
  'VITE_APP_FRONT_OFFICE_URL',
  'http://localhost:3001'
);
export const IDLE_TIMEOUT = Number.parseInt(
  getVariable('VITE_APP_VITE_APP_IDLE_TIMEOUT', '60')
);
export const APP_VERSION =
  __APP_VERSION__ || getVariable('VITE_APP_APP_VERSION');
export const RECAPTCHA_SITE_KEY = getVariable('VITE_APP_RECAPTCHA_SITE_KEY');

export const DEFAULT_SCORECARD_EXPIRATION_PERIOD = 1; // 1 year
