import { Link as MUILink } from '@mui/material';

import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { InteliamLinkProps, InteliamButtonLinkProps } from '@shared/types';

import { Button } from '..';

// Note: use this whe you want link styled as Button
export const ButtonLinkRouter: React.FCC<InteliamButtonLinkProps> = (props) => {
  // @ts-expect-error P2 TO FIX (Broken after react-router upgrade)
  return <Button component={ReactRouterLink} {...props} />;
};

// Note: use this whe you want a classic anchor tag
export const RouterLink: React.FCC<InteliamLinkProps> = (props) => {
  return <MUILink component={ReactRouterLink} {...props} data-cy={props.id} />;
};
