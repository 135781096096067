import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIQuestionReviewLinkConfiguration } from '@core/guards';
import type { IQuestionReviewLinkConfiguration } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type QuestionReviewLinkConfigurationActions = 'remove';

const baseQuestionReviewLinkConfigurationApi = ApiFactory.generate<
  IQuestionReviewLinkConfiguration,
  JwtBOUser,
  QuestionReviewLinkConfigurationActions
>({
  baseRoute: '/question-review-link-configurations',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIQuestionReviewLinkConfiguration,
});

export default {
  ...baseQuestionReviewLinkConfigurationApi.actions,
};
