import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIAssessmentReview } from '@core/guards';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type {
  IAnyAssessmentReview,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type AssessmentReviewActions = 'paginate';

const baseAssessmentReviewApi = ApiFactory.generate<
  IAnyAssessmentReview,
  JwtBOUser,
  AssessmentReviewActions
>({
  baseRoute: '/assessment-reviews',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIAssessmentReview,
});

export default {
  ...baseAssessmentReviewApi.actions,
};
