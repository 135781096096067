import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';
import type { IDocumentEvaluation } from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

const useFetchDocumentsEvaluationByArId = ({
  id,
  total = false,
}: {
  id: string;
  total?: boolean;
}): InteliamUseQueryResult<
  IPaginationResponse<IDocumentEvaluation>,
  BaseAxiosErrorResponse
> => {
  return useQuery<
    IPaginationResponse<IDocumentEvaluation>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.DOCUMENTS_EVALUATION_COUNT],
    () => {
      return DocumentEvaluationsApi.paginateByAR({
        id: id,
        total: total,
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
    }
  );
};

export default useFetchDocumentsEvaluationByArId;
