export enum IDiscountTypesEnum {
  TYPE_AMOUNT = 'Amount',
  TYPE_PERCENTAGE = 'Percentage',
}

export enum IDiscountStatusesEnum {
  STATUS_ACTIVE = 'Active',
  STATUS_INACTIVE = 'Inactive',
}

export enum IDiscountPeriodsEnum {
  PERIOD_1_YEAR = '1 year',
  PERIOD_3_YEARS = '3 years',
}
