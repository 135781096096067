import { IReviewKindEnum } from '@inteliam/foundation/lib/enums';
import { isIDistributor } from '@inteliam/foundation/lib/guards';

import type {
  IDocumentEvaluation,
  IReferenceEvaluation,
  IAnswerEvaluation,
  ISsqQuestionnaireDefinition,
  IBODistributor,
  IFavorite,
  IBusinessDevelopersFollowUp,
} from '@core/types';

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type {
  IDocument,
  IQuestion,
  IReview,
  INews,
  IAnySsqQuestion,
  IMasterAnalysisHighlight,
  ICustomAnalysisHighlight,
  IAnyAssessmentReview,
  IAssessmentStrength,
  IAssessmentWeakness,
  ITranslatableField,
} from '@inteliam/foundation/lib/types';

export * from './csm';
export * from './alerts';
export * from './assessment-request';
export * from './sharing-request';
export * from './region';
export * from './carbon-assessment';
export * from './setting';
export * from './media';
export * from './question-review-link-configuration';

export function isIReview(argument: any): argument is IReview {
  return typeof argument.id === 'string';
}

export function isIAnalysisHighlight(
  argument: any
): argument is IMasterAnalysisHighlight | ICustomAnalysisHighlight {
  return typeof argument.id === 'string';
}

export function isINews(argument: any): argument is INews {
  return typeof argument.id === 'string' && typeof argument.title === 'string';
}
export function isIAssessmentReview(
  argument: any
): argument is IAnyAssessmentReview {
  return (
    typeof argument.id === 'string' &&
    typeof argument.kind === 'string' &&
    (typeof argument.weakness === 'object' ||
      typeof argument.strength === 'object')
  );
}
export function isIAssessmentStrength(
  argument: any
): argument is IAssessmentStrength {
  return (
    argument.kind === IReviewKindEnum.Strength &&
    argument.strength !== undefined
  );
}
export function isIAssessmentWeakness(
  argument: any
): argument is IAssessmentWeakness {
  return (
    argument.kind === IReviewKindEnum.Weakness &&
    argument.weakness !== undefined
  );
}
export function isIQuestion(argument: any): argument is IQuestion {
  return (
    //&& typeof argument.title === 'string'
    typeof argument.code === 'string'
  );
}

// eslint-disable-next-line sonarjs/no-identical-functions
export function isISsqQuestion(argument: any): argument is IAnySsqQuestion {
  return typeof argument.code === 'string';
}
// eslint-disable-next-line sonarjs/no-identical-functions
export function isISsqQuestionnaireDefinition(
  argument: any
): argument is ISsqQuestionnaireDefinition {
  return (
    typeof argument.id === 'string' &&
    typeof argument.name === 'string' &&
    Array.isArray(argument.questions)
  );
}

export function isIDocumentEvaluation(
  argument: any
): argument is IDocumentEvaluation {
  return (
    typeof argument.status === 'string' && typeof argument.document === 'object'
  );
}

export function isIAnswerEvaluation(
  argument: any
): argument is IAnswerEvaluation {
  return (
    typeof argument.status === 'string' && typeof argument.answer === 'object'
  );
}

export function isIReferenceEvaluation(
  argument: any
): argument is IReferenceEvaluation {
  return (
    typeof argument.id === 'string' &&
    typeof argument.answer === 'object' &&
    typeof argument.answerOptionsEvaluations === 'object'
  );
}

export function isIDocument(argument: any): argument is IDocument {
  return typeof argument.id === 'string';
}

export function isITranslatableField(
  argument: any
): argument is ITranslatableField {
  return typeof argument === 'object' && Array.isArray(argument.translations);
}

export function isIBODistributor(argument: any): argument is IBODistributor {
  return isIDistributor(argument);
}

export function isToDo(argument: any): argument is IBusinessDevelopersFollowUp {
  return typeof argument.id === 'string';
}
export function isIFavorite(argument: any): argument is IFavorite {
  return typeof argument.id === 'string';
}
