import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { SustainabilityCardForm } from '@core/components/enterprise';

import {
  QUERY_KEYS,
  useFetchEnterpriseSustainabilityCardById,
  useManageEnterpriseSustainabilityCards,
} from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';

import { CenteredSpinner } from '@shared/components';

import type { IIdentifiedObject } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  onClose: () => void;
  sustainabilityCardId: string;
}

const UpdateEnterpriseSustainabilityCardModal: React.FCC<Props> = ({
  onClose,
  sustainabilityCardId,
}) => {
  const { notify, t } = useEssentials();
  const { id } = useParams<IIdentifiedObject>();
  const queryClient = useQueryClient();
  const { id: manufacturerId } = useParams<IIdentifiedObject>();

  const sustainabilityCardQuery = useFetchEnterpriseSustainabilityCardById({
    sustainabilityCardId,
    manufacturerId,
  });

  const { update } = useManageEnterpriseSustainabilityCards({
    onSuccess: () => {
      notify({
        message: t('New File for Sustainability card uploaded.'),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries([
          ...QUERY_KEYS.MANUFACTURER_SUSTAINABILITY_CARDS_LIST(manufacturerId),
        ])
        .catch(() => {});
      onClose();
    },
  });

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={true}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title
        id='modals.enterprise_sustainability_card.title'
        onClose={onClose}
      >
        {t('Add a new sustainability card')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        {!sustainabilityCardQuery.isSettled && <CenteredSpinner />}
        {sustainabilityCardQuery.isSuccess && (
          <SustainabilityCardForm
            onSubmit={(data) => {
              update.run(id, sustainabilityCardId, data);
            }}
            onClose={onClose}
            isUpdating={true}
            isLoading={update.mutation.isLoading}
            defaultValues={sustainabilityCardQuery.data.data}
          />
        )}
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(UpdateEnterpriseSustainabilityCardModal);
