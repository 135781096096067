import { yupResolver } from '@hookform/resolvers/yup';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Transformers, Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useFetchCampaigns, useManageEnterprises } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IAssignCampaign } from '@core/types';

import { Button, Controls, Grid, FormGroup } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IPartialAR,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../modals';
import { assign as schema } from './schema';

const INITIAL_VALUE: IAssignCampaign = {
  campaign: {
    id: '',
  },
  manufacturer: '',
};
interface Props {
  onSubmit: (data: IAssignCampaign) => void;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
  defaultValues?: IPartialAR;
}

const Assign: React.FCC<Props> = ({
  onClose,
  open,
  onSubmit,
  defaultValues,
  isLoading,
  errors,
  title,
}) => {
  const { t } = useEssentials();
  const { paginate } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
      },
    },
  });
  const methods = useForm<IAssignCampaign>({
    defaultValues: {
      ...INITIAL_VALUE,
      campaign: { id: defaultValues?.campaign?.id || '' },
    },
    resolver: yupResolver(schema),
  });

  const watchedEnterprise = useWatch({
    control: methods.control,
    name: 'manufacturer',
  });
  const { campaigns } = useFetchCampaigns({
    conditions: [
      {
        column: 'owner.id',
        operator: 'eq',
        value: watchedEnterprise,
      },
      {
        column: 'open',
        operator: 'eq',
        value: 'true',
      },
    ],
  });
  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <Controls.Form submitHandler={onSubmit} methods={methods}>
        <BaseDialog.Title id='Manage campaign' onClose={onClose}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span>
              <strong>{title}</strong>
            </span>
          </Grid>
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormGroup>
                {paginate.isSuccess && (
                  <Controls.FormSelect
                    fullWidth
                    name='manufacturer'
                    label={t('Enterprise')}
                    options={Transformers.mapIntoOptionItem(
                      paginate.data.data,
                      'name',
                      'id'
                    )}
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='campaign.id'
                  label={t('Campaign name')}
                  options={Transformers.mapIntoOptionItem(
                    campaigns,
                    'name',
                    'id'
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='close-assign-campaign'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-assign-campaign'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Validate')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};
export default memo(Assign);
