import React, { memo } from 'react';

import { useLanguagesByScope } from '@core/queries';

import { LanguageUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import {
  Controls,
  IconButton,
  Grid,
  FormGroup,
  RemoveCircleIcon,
} from '@shared/components';

import type {
  ITranslatableItem,
  ITranslatableList,
} from '@inteliam/foundation/lib/types';

interface Props {
  name: string;
  index: number;
  handlers: {
    remove: (index: number) => void;
    // update: (field: string, value: string, updateIndex: number) => void;
  };
  translation: ITranslatableItem;
  translations: ITranslatableList;
  readOnly?: boolean;
  numberOfOldTranslations?: number | undefined;
}

const TranslationItem: React.FCC<Props> = ({
  translation,
  translations,
  name,
  index,
  readOnly,
  handlers,
  numberOfOldTranslations = 0,
}) => {
  const { t } = useEssentials();
  const fieldName = `${name}.translations.${index}`;

  const { formattedExceptEnglish } = useLanguagesByScope({
    scope: 'FrontOfficeAdministration',
  });
  const unusedLanguages = LanguageUtils.calculateUnusedLanguages(
    translations,
    formattedExceptEnglish,
    index
  );
  return (
    <Grid container spacing={3}>
      <Grid item md={1} style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          id={`remove-translation`}
          color='secondary'
          onClick={() => handlers.remove(index)}
          disabled={readOnly && index < numberOfOldTranslations}
          size='large'
        >
          <RemoveCircleIcon />
        </IconButton>
      </Grid>
      <Grid item md={3} xs={12} key={fieldName}>
        <FormGroup>
          <Controls.FormSelect
            label={t('Language')}
            name={`${fieldName}.locale`} // NOT USED AT submit, but need for UI
            options={unusedLanguages}
            defaultValue={translation.locale}
            readOnly={readOnly && index < numberOfOldTranslations}
            required
          />
        </FormGroup>
      </Grid>
      <Grid item md={8} xs={12}>
        <FormGroup>
          <Controls.FormInput
            name={`${fieldName}.content`}
            label={t('Text {{index}}', {
              index: index + 1,
            })}
            defaultValue={translation.content}
            readOnly={readOnly && index < numberOfOldTranslations}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default memo(TranslationItem);
