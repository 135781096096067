/* eslint-disable @typescript-eslint/ban-ts-comment */
import { yupResolver } from '@hookform/resolvers/yup';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Button, Controls, Grid, FormGroup } from '@shared/components';

import type { ICancellationAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';
import schema from './schema';

interface Props {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (data: ICancellationAR) => void;
}
const CANCELLATION_REASONS = [
  {
    label: 'Cancelled by Inteliam',
    value: 'CANCELLED_BY_INTELIAM',
  },
  {
    label: 'Declined by Distributor',
    value: 'DECLINED_BY_DISTRIBUTOR',
  },
  {
    label: 'Cancelled by Requester',
    value: 'CANCELLED_BY_REQUESTER',
  },
];

// TODO: Post RHF Migration
type temporaryICancellationAR = Pick<ICancellationAR, 'reason' | 'comments'>;

const Form: React.FCC<Props> = ({ onClose, open, onSubmit, isLoading }) => {
  const { t } = useEssentials();
  const methods = useForm<temporaryICancellationAR>({
    defaultValues: { reason: CANCELLATION_REASONS[0].value },
    resolver: yupResolver(schema),
  });
  return (
    <BaseDialog.Dialog keepMounted={false} open={open} onClose={onClose}>
      <Controls.Form<temporaryICancellationAR>
        methods={methods}
        submitHandler={(data) =>
          onSubmit({
            reason: data.reason,
            comments: data.comments,
          })
        }
      >
        <BaseDialog.Title id='Cancel assessment' onClose={onClose}>
          {t('Cancel assessment')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormSelect
                  name='reason'
                  label={t('Reason')}
                  options={CANCELLATION_REASONS}
                  fullWidth
                  required
                />
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormInput
                  name='comments'
                  label={t('Comment')}
                  rows={3}
                  multiline
                  fullWidth
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-cancel-assessment-request'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-cancel-assessment-request'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(Form);
