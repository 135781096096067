import { yupResolver } from '@hookform/resolvers/yup';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { DistributorUsersUtils } from '@core/utils';

import type {
  DistributorUserFormMode,
  IDistributorUserForm,
  IDistributorUserPayload,
} from '@core/types';

import { Controls } from '@shared/components';

import type {
  IFODistributorUser,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { distributorUserPayload as schema } from '../schemas';
import { BaseDistributorUserForm } from './';

interface Props {
  onSubmit: (data: IDistributorUserPayload) => void;
  defaultValues?: Partial<IFODistributorUser>;
  mode: DistributorUserFormMode;
  errors?: ValidationErrorResponse;
  formId: string;
  distributorId: string;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  formId,
  defaultValues,
  distributorId,
  errors,
  mode,
}) => {
  const methods = useForm<IDistributorUserForm>({
    defaultValues: defaultValues
      ? DistributorUsersUtils.normalize.userToFormData(
          defaultValues,
          distributorId
        )
      : DistributorUsersUtils.normalize.initialFormData(mode, distributorId),
    resolver: yupResolver(schema),
  });

  return (
    <Controls.Form
      methods={methods}
      submitHandler={(values) => {
        onSubmit(DistributorUsersUtils.normalize.formDataToBasePayload(values));
      }}
      id={formId}
    >
      <BaseDistributorUserForm
        {...{ methods, distributorId, defaultValues, mode, errors }}
      />
    </Controls.Form>
  );
};

export default memo(Form);
