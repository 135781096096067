import {
  IDistributorRolesEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useEffect, useState } from 'react';
import { FieldPath, useFormContext, useWatch } from 'react-hook-form';

import { BaseUserForm } from '@core/components/users';

import {
  useManageAssessmentRequest,
  useUpdateFOUserStatus,
} from '@core/queries';

import { UsersUtils } from '@core/utils';

import { DISTRIBUTOR_ROLES } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type {
  DistributorUserFormMode,
  IAssessmentRequestForm,
} from '@core/types';

import { Controls, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { FormMethods } from '@shared/types';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

import BusinessRoleFields from './business-role-fields';

const ROLES = Object.values(DISTRIBUTOR_ROLES);

interface Props {
  mode: DistributorUserFormMode;
  errors?: ValidationErrorResponse;
  distributorId?: string;
  baseName: 'scoredCompany.primaryContact' | 'questionnaireContact';
  defaultValues?: Partial<IAssessmentRequestForm['questionnaireContact']>;
}

function checkError(
  {
    name,
    message,
    methods,
  }: {
    name: FieldPath<IAssessmentRequestForm>;
    message: string;
    methods: FormMethods<IAssessmentRequestForm>;
  },
  clear = false
) {
  if (clear) {
    methods.clearErrors(name);
  } else {
    methods.setError(name, {
      type: 'manual',
      message: message,
    });
  }
}
const ContactUserForm: React.FCC<Props> = ({
  distributorId,
  errors,
  mode,
  baseName,
  defaultValues,
}) => {
  const methods = useFormContext<IAssessmentRequestForm>();
  const { t } = useEssentials();
  const [canUpdateStatus, setCanUpdateStatus] = useState<undefined | boolean>(
    undefined
  );
  const { getAllByCompany } = useManageAssessmentRequest({
    getAllByCompany: { args: { id: distributorId } },
  });
  const { canRun } = useUpdateFOUserStatus({
    queryKey: ['CHECK_ONLY'],
    companyId: distributorId,
  });

  const roleFieldName = `${baseName}.roles` as const;
  const statusFieldName = `${baseName}.status` as const;
  const watchedRole = useWatch({
    control: methods.control,
    name: `${roleFieldName}.0`,
  });
  useEffect(() => {
    const onFailure = () => {
      methods.setValue(statusFieldName, IUserStatusEnum.STATUS_DISABLED);
      checkError({
        methods,
        name: statusFieldName,
        message: 'You cannot enable user now',
      });
      setCanUpdateStatus(false);
    };
    canRun()
      .then(() => setCanUpdateStatus(true))
      .catch(onFailure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorId, canRun, t]);

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Grid container spacing={3}>
      <BaseUserForm
        allowedRoles={ROLES}
        visibility={{ status: false }}
        readOnly={{
          role: true,
        }}
        {...{ defaultValues, baseName }}
      />
      <Grid item md={6} xs={12}>
        {!isNil(canUpdateStatus) && (
          <Controls.FormRadioGroup
            label={t('Status')}
            className='mr-1'
            // eslint-disable-next-line sonarjs/no-nested-template-literals
            name={`${baseName ? `${baseName}.` : ''}status`}
            defaultValue={
              canUpdateStatus
                ? defaultValues?.status
                : IUserStatusEnum.STATUS_DISABLED
            }
            disabled={!canUpdateStatus && distributorId !== undefined}
            options={UsersUtils.getUserStatusesEnum()}
            row
          />
        )}
      </Grid>
      {mode !== 'SURVEY' &&
        mode !== 'PRIMARY' &&
        getAllByCompany.isSuccess &&
        watchedRole === IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD && (
          <BusinessRoleFields
            {...{
              mode,
              defaultValues,
              ars: getAllByCompany.data?.data,
            }}
          />
        )}
    </Grid>
  );
};

export default memo(ContactUserForm);
