import { yupResolver } from '@hookform/resolvers/yup';
import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { Yup } from '@inteliam/foundation/lib/utils';
import { pick } from 'lodash-es';

import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { flattie } from 'flattie';

import { MatchAndMergeSimpleRow } from '@core/components/assessment-requests';
import {
  ActionTypeEnum,
  dunsRenderer,
  IMergeChanger,
  IMergeRowType,
  initializeSelection,
  MergeAction,
  mergeReducer,
  MergeState,
  stringRenderer,
} from '@core/components/assessment-requests/match-and-merge/utils';

import { useManageDistributors, useQuery } from '@core/queries';

import { DnbApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { DnbQueryForm, DnbStepProps, IBODistributor } from '@core/types';

import {
  Alert,
  Button,
  CenteredSpinner,
  Controls,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@shared/components';

import { invariant } from '@shared/guards';

const schema = Yup.object().shape({
  distributor: Yup.string().required(),
});
export const InteliamSearchStep: React.FCC<DnbStepProps> = (props) => {
  const methods = useForm<{ distributor: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      distributor: props.state.inteliamDistributor,
    },
  });
  return (
    <Controls.Form
      id='inteliam-search-step'
      methods={methods}
      submitHandler={(data) => {
        props.setter((prev) => ({
          ...prev,
          activeStep: prev.activeStep + 1,
          inteliamDistributor: data.distributor,
        }));
      }}
    >
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Controls.FormCompanyField
          label={'Distributor'}
          name='distributor'
          kind={ICompanyKindEnum.DISTRIBUTOR}
        />
      </div>
    </Controls.Form>
  );
};

const DnbQueryFields: Array<{
  key: keyof DnbQueryForm;
  field: React.ComponentType;
}> = [
  {
    key: 'companyName',
    field: () => (
      <Controls.FormInput
        label={'Company name'}
        name={'companyName'}
        required
      />
    ),
  },
  {
    key: 'country',
    field: () => (
      <Controls.FormCountrySelect label={'Country'} name={'country'} />
    ),
  },
  {
    key: 'city',
    field: () => <Controls.FormInput label={'City'} name={'city'} />,
  },
  {
    key: 'duns',
    field: () => <Controls.FormInput label={'Duns number'} name={'duns'} />,
  },
  {
    key: 'taxId',
    field: () => <Controls.FormInput label={'National id'} name={'taxId'} />,
  },
];

export const DnbSearchStep: React.FCC<DnbStepProps> = (props) => {
  const { t } = useEssentials();
  const { getOneById } = useManageDistributors({
    getOneById: {
      args: {
        id: props.state.inteliamDistributor?.toString(),
      },
    },
  });
  const methods = useForm<DnbQueryForm>({
    defaultValues: props.state.query,
  });
  const watchedCompanyName = methods.watch('companyName');
  React.useEffect(() => {
    props.setter((prev) => ({
      ...prev,
      query: {
        ...prev.query,
        companyName: watchedCompanyName,
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCompanyName]);
  return (
    <Controls.Form
      id='dnb-search-step'
      methods={methods}
      submitHandler={(query) =>
        props.setter((prev) => ({
          ...prev,
          query,
          activeStep: prev.activeStep + 1,
        }))
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography color='textSecondary' gutterBottom>
            {t(
              'These information will be used to find the best match from Dun and Bradstreet'
            )}
          </Typography>
        </Grid>
        {!getOneById.isSettled && <CenteredSpinner />}
        {getOneById.isSuccess &&
          DnbQueryFields.map((dnbQueryField) => {
            const Component = dnbQueryField.field;
            return (
              <Grid key={dnbQueryField.key} item xs={12}>
                <FormGroup>
                  <Component />
                </FormGroup>
              </Grid>
            );
          })}
      </Grid>
    </Controls.Form>
  );
};

type DnbSearchResponse = Awaited<ReturnType<typeof DnbApi.search>>;
export const DnbSelectStep: React.FCC<DnbStepProps> = (props) => {
  const { t } = useEssentials();
  const { getOneById } = useManageDistributors({
    getOneById: {
      args: {
        id: props.state.inteliamDistributor?.toString(),
      },
    },
  });

  const query = useQuery<DnbSearchResponse>(
    ['dnb-search', props.state.query],
    () =>
      DnbApi.search({
        query: {
          ...props.state.query,
          countryCode: props.state.query?.country?.code,
        },
      })
  );

  return (
    <div>
      {!query.isSettled || (!getOneById.isSettled && 'Loading ...')}
      <Typography color='textSecondary' gutterBottom>
        {t('Proposed result by DNB')}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'></TableCell>
              <TableCell align='left'>{t('Duns')}</TableCell>
              <TableCell align='left'>{t('Name')}</TableCell>
              <TableCell align='left'>{t('Address')}</TableCell>
              <TableCell align='left'>{t('Country')}</TableCell>
              <TableCell align='left'>{t('City')}</TableCell>
              <TableCell align='left'>{t('State')}</TableCell>
              <TableCell align='left'>{t('Zip')}</TableCell>
            </TableRow>
          </TableHead>
          {query.isSuccess && getOneById.isSuccess && (
            <TableBody>
              {query.data.data.map((resultItem) => (
                <TableRow key={resultItem.name}>
                  <TableCell>
                    <Button
                      type='button'
                      variant='contained'
                      color='secondary'
                      id={`select-${resultItem.name}`}
                      onClick={() =>
                        props.setter((prev) => ({
                          ...prev,
                          selectedResult: resultItem,
                          activeStep: prev.activeStep + 1,
                        }))
                      }
                    >
                      {t('Select')}
                    </Button>
                  </TableCell>
                  <TableCell>{resultItem.duns}</TableCell>
                  <TableCell>{resultItem.name}</TableCell>
                  <TableCell>{resultItem.address.address}</TableCell>
                  <TableCell>{resultItem.address.country.name}</TableCell>
                  <TableCell>{resultItem.address.city}</TableCell>
                  <TableCell>{resultItem.address.state}</TableCell>
                  <TableCell>{resultItem.address.zipCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};
type DnbCompanyReportResponse = Awaited<
  ReturnType<typeof DnbApi.getCompanyReport>
>;

export const DnbMergeStep: React.FCC<DnbStepProps> = (props) => {
  const { t } = useEssentials();
  const { getOneById } = useManageDistributors({
    getOneById: {
      args: {
        id: props.state.inteliamDistributor?.toString(),
      },
    },
  });
  const selectedDuns = props.state.selectedResult?.duns;
  invariant(selectedDuns);

  const query = useQuery<DnbCompanyReportResponse>(
    ['dnb-report', selectedDuns],
    () =>
      DnbApi.getCompanyReport({
        duns: selectedDuns,
      })
  );

  return (
    <div>
      {!query.isSettled || (!getOneById.isSettled && 'Loading ...')}
      <Typography color='textSecondary' gutterBottom>
        {t('Proposed result by DNB')}
      </Typography>

      {query.isSuccess && getOneById.isSuccess && (
        <DnbMergerTable
          {...props}
          distributor={getOneById.data.data}
          report={query.data.data}
        />
      )}
    </div>
  );
};

const ROWS: IMergeRowType[] = [
  {
    attribute: 'Duns',
    valuePropertyPath: 'dunsIdentifier',
    labelPropertyPath: 'dunsIdentifier',
    renderer: dunsRenderer,
  },
  {
    attribute: 'Company name',
    valuePropertyPath: 'name',
    labelPropertyPath: 'name',
    renderer: stringRenderer,
  },
  {
    attribute: 'Address',
    valuePropertyPath: 'address.address',
    labelPropertyPath: 'address.address',
    renderer: stringRenderer,
  },
  {
    attribute: 'Incorporation date',
    valuePropertyPath: 'incorporationDate',
    labelPropertyPath: 'incorporationDate',
    renderer: stringRenderer,
  },
  {
    attribute: 'Number of employees',
    valuePropertyPath: 'numberOfEmployees',
    labelPropertyPath: 'numberOfEmployees',
    renderer: stringRenderer,
  },
  {
    attribute: 'Legal form',
    valuePropertyPath: 'legalForm',
    labelPropertyPath: 'legalForm',
    renderer: stringRenderer,
  },
  {
    attribute: 'Risk indicator',
    valuePropertyPath: 'riskIndicator',
    labelPropertyPath: 'riskIndicator',
    renderer: stringRenderer,
  },
  {
    attribute: 'Probability of default',
    valuePropertyPath: 'probabilityOfDefault',
    labelPropertyPath: 'probabilityOfDefault',
    renderer: stringRenderer,
  },
  {
    attribute: 'Failure score',
    valuePropertyPath: 'failureScore',
    labelPropertyPath: 'failureScore',
    renderer: stringRenderer,
  },
];
const DnbMergerTable: React.FC<
  {
    distributor: IBODistributor;
    report: DnbCompanyReportResponse['data'];
  } & DnbStepProps
> = ({ distributor, report, setter, state }) => {
  const { t } = useEssentials();
  const navigate = useNavigate();
  const { patch, getOneById } = useManageDistributors({
    getOneById: { args: { id: distributor.id } },
  });
  const methods = useForm();

  const [mergeState, dispatch] = React.useReducer<
    React.Reducer<MergeState, MergeAction>
  >(mergeReducer, {
    company: distributor,
    selection: initializeSelection('EXISTING'),
  });
  const [draftCompany] = React.useState<IBODistributor>({
    dunsIdentifier: report.duns,
    name: report.name,
    address: {
      address: report.address,
    },
    incorporationDate: report.incorporationDate,
    numberOfEmployees: report.numberOfEmployees,
    legalForm: report.legalForm,
    riskIndicator: report.riskIndicator,
    probabilityOfDefault: report.probabilityOfDefault,
    failureScore: report.failureScore,
  } as unknown as IBODistributor);
  const handleChangeValue: IMergeChanger = React.useCallback(
    (event, value, selectionType) => {
      if (event.target.checked === true) {
        dispatch({
          type: ActionTypeEnum.SIMPLE,
          payload: {
            field: event.target.name,
            value,
            selectionType,
          },
        });
      }
      if (!state.startMerging) {
        setter((prev) => ({ ...prev, startMerging: true }));
      }
    },
    [setter, state.startMerging]
  );

  return (
    <Controls.Form
      id='dnb-merge-step'
      methods={methods}
      submitHandler={() => {
        patch.mutate(
          {
            id: distributor.id,
            body: flattie(
              pick(
                mergeState.company,
                ROWS.map((row) => row.valuePropertyPath).filter(
                  (key) => key !== 'address.country.phonePrefix'
                )
              )
            ),
          },
          {
            onSuccess: () => {
              getOneById
                .refetch()
                .then(() => {
                  navigate(`/distributors/${distributor.id}`);
                })
                .catch(() => {});
            },
          }
        );
      }}
    >
      <Alert color='warning' style={{ marginTop: 20 }}>
        {t(
          "You're about to edit the Inteliam distributor [{{distributor}}] that you selected on the first step with information from the company [{{result}}] found on DNB",
          {
            distributor: distributor.name,
            result: report.name,
          }
        )}
      </Alert>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>{t('Attribute')}</TableCell>
              <TableCell align='left'>{t('Inteliam')}</TableCell>
              <TableCell align='left'>{t('DNB')}</TableCell>
              <TableCell align='left'>{t('Selection')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ROWS.map((row) => (
              <MatchAndMergeSimpleRow
                key={row.attribute}
                row={row}
                existing={distributor}
                draft={draftCompany}
                merged={mergeState}
                onChange={handleChangeValue}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Controls.Form>
  );
};
