import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { ReferenceEvaluationsApi } from '@core/api';
import type { IReferenceEvaluation } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchReferenceEvaluationsByAnswerId = ({
  answerId,
  optionId,
}: {
  answerId: string;
  optionId?: string;
}): InteliamUseQueryResult<
  IAPIResponse<Array<IReferenceEvaluation>>,
  BaseAxiosErrorResponse
> & {
  referenceEvaluations: Array<IReferenceEvaluation> | undefined;
} => {
  const query = useQuery<
    IAPIResponse<Array<IReferenceEvaluation>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.REFERENCE_EVALUATIONS, answerId, optionId],
    () => ReferenceEvaluationsApi.listByAnswer(answerId, optionId),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      initialData: undefined,
      cacheTime: 1000 * 20,
    }
  );
  return {
    ...query,

    referenceEvaluations: query.data?.data,
  };
};

export default useFetchReferenceEvaluationsByAnswerId;
