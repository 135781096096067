/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import type { IBODistributor } from '@core/types';

import { Radio, Grid, TableRow, TableCell } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { MergeState, SelectionType } from './utils';

const useStyles = makeStyles(() => ({
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  row: {
    boxShadow: '0px 3px 10px -7px rgba(0,0,0,0.56)',
  },
}));

interface Props {
  existing: IBODistributor;
  draft: IBODistributor;
  merged: MergeState;
  onSelect: (company: IBODistributor, selectedType: SelectionType) => void;
}

const SelectAllRow: React.FCC<Props> = ({
  existing,
  draft,
  merged,
  onSelect,
}) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell />
      <TableCell align='left' className={classes.cell}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <span>Select all</span>
          <Radio
            checked={merged.company === existing}
            value={'EXISTING'}
            name={'select_all'}
            onChange={() => onSelect(existing, 'EXISTING')}
          />
        </Grid>
      </TableCell>
      <TableCell align='left' className={classes.cell}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <span>Select all</span>
          <Radio
            checked={merged.company === draft}
            value={'DRAFT'}
            name={'select_all'}
            onChange={() => onSelect(draft, 'DRAFT')}
          />
        </Grid>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default SelectAllRow;
