import { Checkbox as MUICheckbox, CheckboxProps } from '@mui/material';

import * as React from 'react';

type Props = CheckboxProps & {
  onCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUncheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const Checkbox: React.FCC<Props> = ({
  onCheck,
  onUncheck,
  onChange,
  ...props
}) => {
  return (
    <MUICheckbox
      {...props}
      onChange={(event, checked) => {
        if (checked) {
          onCheck?.(event);
        } else {
          onUncheck?.(event);
        }
        onChange?.(event, checked);
      }}
    />
  );
};

export default Checkbox;
