import type { AnswerValueFormatEnum } from '@inteliam/foundation/lib/enums';
import { isNil } from 'lodash-es';

import * as React from 'react';

import { TooltippedCell, Typography } from '@shared/components';

import type { AnswerValueProps } from '@inteliam/foundation/lib/types';

type Props = {
  value: AnswerValueProps[AnswerValueFormatEnum.FORMAT_LONG_TEXT] | undefined;
};
const AnswerValueLongText: React.FCC<Props> = ({ value }) => {
  return (
    <TooltippedCell title={value?.text || ''} disabled={isNil(value)}>
      <Typography>{value?.text || ''}</Typography>
    </TooltippedCell>
  );
};

export default AnswerValueLongText;
