/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ISsqQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

import { translationFieldSchema } from '@shared/schemas';

import type { StringOrUndefined } from '@inteliam/foundation/lib/types';

export const create = Yup.object().shape({
  code: Yup.string().required(ErrorCodesLookup.E0001.title),
  title: translationFieldSchema(true),
  helpTextContent: translationFieldSchema(),
  kind: Yup.string().required(ErrorCodesLookup.E0001.title),
  document: Yup.string().required(),
  status: Yup.string().required(),
  required: Yup.boolean(),
  options: Yup.array().when('kind', {
    is: ISsqQuestionKindEnum.SingleFreeFormField,
    then: Yup.array(),
    otherwise: Yup.array()
      .of(
        Yup.object().shape({
          label: translationFieldSchema(true),
          helpTextContent: translationFieldSchema(),
          order: Yup.number(),
          scopes: Yup.array().of(
            Yup.object().shape({
              country: Yup.number(),
              size: Yup.string(),
              category: Yup.string(),
            })
          ),
          textBox: Yup.string().required(),
          textBoxLabel: Yup.object().when('textBox', {
            is: 'no',
            then: translationFieldSchema(),
            otherwise: translationFieldSchema(true),
          }),
          textBoxType: Yup.string().when('textBox', {
            is: 'no',
            then: Yup.string().optional(),
            otherwise: Yup.string().required(),
          }),
          dropDown: Yup.string().required(),
          dropDownLabel: Yup.object().when('dropDown', {
            is: 'no',
            then: translationFieldSchema(),
            otherwise: translationFieldSchema(true),
          }),
          dropDownChoices: Yup.array().when('dropDown', {
            is: 'no',
            then: Yup.array().nullable(),
            otherwise: Yup.array()
              .required(ErrorCodesLookup.E0001.title)
              .of(
                Yup.object().shape({
                  id: Yup.string().required(),
                  label: translationFieldSchema(true),
                })
              )
              .min(2, ErrorCodesLookup.E0001.title),
          }),
          dropDownMultiAnswers: Yup.boolean(),
        })
      )
      .min(2),
  }),
  parentDependency: Yup.object().shape({
    question: Yup.object().shape({
      id: Yup.string().nullable().optional(),
    }),
    optionIds: Yup.array().when('question.id', {
      is: (id: StringOrUndefined) => {
        return Boolean(id) === true;
      },
      then: Yup.array()
        .required()
        .min(1, 'Please select at least one option')
        .typeError(ErrorCodesLookup.E0001.title),
      otherwise: Yup.array().nullable(),
    }),
  }),
});
