import {
  IARActionsEnum,
  IBORolesEnum,
  IDistributorRolesEnum,
  IDistributorStatusEnum,
  ILanguagesEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { PriceUtils } from '@inteliam/foundation/lib/utils';

import { UsersUtils } from '@core/utils';

import { AssessmentRequestsApi } from '@core/api';
import type {
  IAssessmentRequestForm,
  IBODistributor,
  IDistributorForm,
  IEnterpriseForm,
  IModeDefinition,
  IRegistrationModeType,
  IRegistrationState,
} from '@core/types';

import type { IActionItem } from '@shared/types';

import type {
  IAR,
  IBOUser,
  ICampaign,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

export const DEFAULT_COMPANY: IAssessmentRequestForm['scoredCompany'] = {
  name: '',
  medalDisplayName: '',
  address: {
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: undefined,
  },
  incorporationDate: new Date().getFullYear(),
  description: '',
  size: '',
  services: '',
  dunsIdentifier: '',
  nationalIdentifier: '',
  website: '',
  headquarters: {
    name: '',
    country: undefined,
  },
  numberOfEmployees: 1,
  category: '',
  turnover: {
    value: 0,
    userValue: 0,
    currency: 'USD',
    decimals: 2,
    unit: 'M',
  },
  additionalComments: '',
  primaryContact: {
    contactInfo: {
      title: '',
      position: '',
      language: ILanguagesEnum.EN_GB,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
    roles: [IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD],
    status: IUserStatusEnum.STATUS_DISABLED,
  },
  kind: 'DISTRIBUTOR',
  memberOfHierarchy: [undefined, undefined, undefined],
  configuration: {
    visibleOnDirectory: true,
    public: false,
    showProfileViewers: true,
  },
  integrationIdsArray: [],
};

const AR_LIST_ROUTE = '/dashboard/engagement';
const redirectPostMatchAndMerge = (
  companyId?: string,
  assessmentRequest?: IAR
): string => {
  if (!companyId || !assessmentRequest) {
    throw new Error('We need a companyId here');
  }
  return `/assessment-requests/${companyId}/match-and-merge?assessmentRequestId=${assessmentRequest.id}`;
};

export const getModes = (): IModeDefinition => ({
  CREATE: {
    [IARActionsEnum.SAVE]: {
      api: AssessmentRequestsApi.create,
      redirect: redirectPostMatchAndMerge,
    },
    [IARActionsEnum.SAVE_AS_DRAFT]: {
      api: AssessmentRequestsApi.create,
      redirect: () => AR_LIST_ROUTE,
    },
  },
  CREATE_FROM_EXISTING: {
    [IARActionsEnum.SAVE_AS_DRAFT]: {
      api: (data) =>
        AssessmentRequestsApi.updateRegistration({
          ...data,
          action: IARActionsEnum.SAVE,
        }),
      redirect: () => AR_LIST_ROUTE,
    },
    [IARActionsEnum.SAVE]: {
      // eslint-disable-next-line sonarjs/no-identical-functions
      api: (data) =>
        AssessmentRequestsApi.updateRegistration({
          ...data,
          action: IARActionsEnum.SAVE,
        }),
      redirect: redirectPostMatchAndMerge,
    },
  },
  UPDATE: {
    [IARActionsEnum.SAVE_AS_DRAFT]: {
      api: AssessmentRequestsApi.updateRegistration,
      redirect: () => AR_LIST_ROUTE,
    },
    [IARActionsEnum.SAVE]: {
      api: AssessmentRequestsApi.updateRegistration,
      redirect: redirectPostMatchAndMerge,
    },
  },
});

export const SAVE_AS_DRAFT_ACTION = {
  label: 'Save as Draft',
  value: IARActionsEnum.SAVE_AS_DRAFT,
  enabled: false,
};

export function getAvailableActions(
  user: IBOUser | JwtBOUser,
  mode: IRegistrationModeType,
  company?: IRegistrationState['company']
): IActionItem<IARActionsEnum>[] {
  return [
    {
      ...SAVE_AS_DRAFT_ACTION,
      label: mode === 'CREATE' ? 'Save as Draft' : 'Save AR',
      enabled: true,
    },
    {
      label: 'Match & Merge',
      value: IARActionsEnum.SAVE,
      enabled:
        UsersUtils.isGranted(
          IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
          user
        ) && canMatchAndMerge(mode, company),
    },
  ];
}

export const canMatchAndMerge = (
  mode: IRegistrationModeType,
  company?: IRegistrationState['company']
): boolean => {
  return (
    mode === 'CREATE' ||
    (mode === 'CREATE_FROM_EXISTING' && company?.draft === true) ||
    (mode === 'UPDATE' && company?.draft === true)
  );
};

export const getConfirmationMessage = (state: IRegistrationState): string => {
  let confirmMessage = 'Are you sure you want to save this company as draft ?';
  if (state.company?.status === IDistributorStatusEnum.NEW) {
    confirmMessage = 'Are you sure you want to save this assessment request ?';
  } else if (
    state.action.value === IARActionsEnum.SAVE &&
    canMatchAndMerge(state.mode, state.company)
  ) {
    confirmMessage =
      'Are you sure you want to launch the match & merge process ?';
  }
  return confirmMessage;
};

export const initializeAR = (
  defaultCompany: IAssessmentRequestForm['scoredCompany'],
  assessmentRequest?: IAR
): IAssessmentRequestForm => {
  return {
    questionnaireContact: {
      id: '',
      contactInfo: {
        title: '',
        position: '',
        language: ILanguagesEnum.EN_GB,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },
      roles: [IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD],
      status: IUserStatusEnum.STATUS_DISABLED,
    },
    usePrimaryContactAsQuestionnaireContact: false,
    manufacturer: assessmentRequest?.campaign?.owner?.id || '',
    campaign: {
      id: '',
    } as ICampaign,
    engagementAssignees: [],
    ...assessmentRequest,
    scoredCompany: {
      ...defaultCompany,
      turnover: {
        ...assessmentRequest?.scoredCompany.turnover,
        userValue: PriceUtils.getDecomposedValue(
          assessmentRequest?.scoredCompany?.turnover?.value
        ).value,
        unit: PriceUtils.getDecomposedValue(
          assessmentRequest?.scoredCompany?.turnover?.value
        ).unit,
        currency: 'EUR',
      },
    } as IAssessmentRequestForm['scoredCompany'],
  };
};

export const hydrateTurnover = <
  T extends { turnover?: IDistributorForm['turnover'] }
>(
  company?: T
): IEnterpriseForm['turnover'] => {
  return {
    ...company?.turnover,
    userValue:
      PriceUtils.getDecomposedValue(company?.turnover?.value || 0).value || 0,
    unit: PriceUtils.getDecomposedValue(company?.turnover?.value || 0).unit,
    value: company?.turnover?.value || 0,
    currency: company?.turnover?.currency || 'EUR',
    decimals: company?.turnover?.decimals || 0,
  };
};

export const hydrateIntegrationIds = (
  company?: IBODistributor
): IDistributorForm['integrationIdsArray'] => {
  const hydratedValue: IDistributorForm['integrationIdsArray'] = [];

  Object.entries(company?.integrationIds || {}).forEach(([key, value]) => {
    hydratedValue.push({
      id: key,
      value: value,
    });
  });
  return hydratedValue;
};
