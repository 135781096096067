import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { ReferenceEvaluationsApi } from '@core/api';
import type { IEvaluationProgress } from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const useFetchReferencesEvaluationGlobalInsights = ({
  arId,
  options,
}: {
  arId: string;
  options?: InteliamUseQueryOptions<
    IAPIResponse<IEvaluationProgress>,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IAPIResponse<IEvaluationProgress>,
  BaseAxiosErrorResponse
> => {
  return useQuery<IAPIResponse<IEvaluationProgress>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.REFERENCE_EVALUATION_PROGRESS, arId],
    () => ReferenceEvaluationsApi.getGlobalInsights(arId),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: 1000 * 25,
      ...options,
    }
  );
};

export default useFetchReferencesEvaluationGlobalInsights;
