import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  useQueryClient,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery } from '@core/queries';

import { AssessmentRequestsApi } from '@core/api';

import type { QueryOptimisticUpdater } from '@shared/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IScoreCard,
} from '@inteliam/foundation/lib/types';

import * as QUERY_KEYS from './query-keys';

const useFetchScoreCardByArId = ({
  id,
  refresh,
  options,
}: {
  id?: string;
  refresh?: boolean;
  options?: InteliamUseQueryOptions<
    IAPIResponse<IScoreCard>,
    BaseAxiosErrorResponse
  >;
} = {}): InteliamUseQueryResult<
  IAPIResponse<IScoreCard>,
  BaseAxiosErrorResponse
> & {
  updater: QueryOptimisticUpdater<IScoreCard>;
} => {
  const queryKey = [...QUERY_KEYS.SCORECARD, id];
  const query = useQuery<IAPIResponse<IScoreCard>, BaseAxiosErrorResponse>(
    queryKey,
    () =>
      id ? AssessmentRequestsApi.getScoreCard(id, refresh) : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      initialData: undefined,
      cacheTime: 1000 * 20,
      enabled: Boolean(id),
      refetchOnMount: false,
      retryOnMount: false,
      ...options,
    }
  );
  const queryClient = useQueryClient();

  const updater: QueryOptimisticUpdater<IScoreCard> = (callback) => {
    queryClient.setQueryData<IAPIResponse<IScoreCard> | undefined>(
      queryKey,
      callback
    );
  };

  return {
    ...query,
    updater,
  };
};

export default useFetchScoreCardByArId;
