import { ContactInfoSchema } from '@inteliam/foundation/lib/schemas';
import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const distributorUserPayload = Yup.object().shape({
  user: Yup.object().shape({
    contactInfo: ContactInfoSchema,
    roles: Yup.array().min(1, 'Please select at least one option'),
    status: Yup.string().required(ErrorCodesLookup.E0001.title),
  }),
  primaryContact: Yup.boolean(),
  questionnaireContact: Yup.boolean(),
  questionnaireContactFor: Yup.array().when('questionnaireContact', {
    is: true,
    then: Yup.array().min(1, 'Please select at least one option'),
    otherwise: Yup.array(),
  }),
});
