import { ApiFactory } from '@inteliam/foundation/lib/api';
import { assertIsTyped } from '@inteliam/foundation/lib/guards';

import { isIFavorite } from '@core/guards';
import type { IChangeScope, IFavorite } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAR,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type FavoritesActions = 'paginate' | 'remove';

const baseFavoritesApi = ApiFactory.generate<
  IFavorite,
  JwtBOUser,
  FavoritesActions
>({
  baseRoute: '/favorites',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIFavorite,
});

const addDistributorToFavorites = async (id: string): Promise<IFavorite> => {
  const { data } = await baseFavoritesApi.client.instance.post<
    IAPIResponse<IFavorite>
  >(`/${id}`);
  assertIsTyped(data.data, isIFavorite);
  return data.data;
};
const assignBDsToDistributor = async (body: {
  id: IAR['id'];
  changeScope: IChangeScope;
}): Promise<IAPIResponse<void>> => {
  const { data } = await baseFavoritesApi.client.instance.post<
    IAPIResponse<void>
  >(`/assign-bds-to-distributor`, body);

  return data;
};

export default {
  ...baseFavoritesApi.actions,
  addDistributorToFavorites,
  assignBDsToDistributor,
};
