import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Checkbox } from '@mui/material';

import * as React from 'react';

import {
  Grid,
  Divider,
  Card,
  Button,
  ArrowRightIcon,
  ArrowLeftIcon,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: IOptionItem[], b: IOptionItem[]) {
  return a.filter(({ value }) => !b.map((it) => it.value).includes(value));
}

function intersection(a: IOptionItem[], b: IOptionItem[]) {
  return a.filter(({ value }) => b.map((it) => it.value).includes(value));
}

function union(a: IOptionItem[], b: IOptionItem[]) {
  return [...a, ...not(b, a)];
}

export type Props = {
  defaultValue?: IOptionItem[];
  data: IOptionItem[];
  onChange: (data: IOptionItem[]) => void;
  disabled?: boolean;
};

const TransferList: React.FCC<Props> = ({
  data = [],
  defaultValue = [],
  onChange,
  disabled = false,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState<IOptionItem[]>([]);
  const [left, setLeft] = React.useState<IOptionItem[]>(
    not(data, defaultValue)
  );
  const [right, setRight] = React.useState<IOptionItem[]>(defaultValue);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useOnUpdate(() => {
    onChange(right);
    //onChange dependency is removed to avoid having an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);

  const handleToggle = (option: IOptionItem) => () => {
    const currentIndex = checked.map((it) => it.value).indexOf(option.value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(option);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: IOptionItem[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: IOptionItem[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (
    title: React.ReactNode,
    items: IOptionItem[],
    id: string
  ) => (
    <Card.CContainer>
      <Card.Header
        className={classes.cardHeader}
        avatar={
          <Checkbox
            data-testid={`${id}-select-all`}
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length > 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || disabled}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component='div' role='list'>
        {items.map((item: IOptionItem) => {
          const labelId = `transfer-list-all-item-${item.value}-label`;

          return (
            <ListItem
              data-testid={`${id}-item-${item.value}`}
              key={item.value}
              role='listitem'
              button
              onClick={handleToggle(item)}
              disabled={disabled}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(item)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.label} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card.CContainer>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent='center'
      alignItems='center'
      className={classes.root}
    >
      <Grid item>{customList('Choices', left, 'left-list')}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button
            id='move-selected-right'
            variant='outlined'
            size='small'
            className={classes.button}
            data-testid={'checked-right'}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            <ArrowRightIcon />
          </Button>
          <Button
            id='move-selected-left'
            variant='outlined'
            data-testid={'checked-left'}
            size='small'
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            <ArrowLeftIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Chosen', right, 'right-list')}</Grid>
    </Grid>
  );
};

export default TransferList;
