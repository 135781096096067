import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import {
  InteliamUseMutationResult,
  useQueryClient,
} from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { FrontOfficeUserApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IAnyFOUserUpdatePayload } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAnyFOUser,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const useUpdateFOUser = ({
  companyId,
  onSuccess,
  successMessage,
}: {
  companyId?: string;
  successMessage: string;
  onSuccess?: (data: IAPIResponse<IAnyFOUser>) => void;
}): {
  run: (data: IAnyFOUserUpdatePayload) => void;
  mutation: InteliamUseMutationResult<
    IAPIResponse<IAnyFOUser> | undefined,
    AxiosValidationErrorResponse,
    IAnyFOUserUpdatePayload
  >;
} => {
  const { t, notify } = useEssentials();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    IAPIResponse<IAnyFOUser> | undefined,
    AxiosValidationErrorResponse,
    IAnyFOUserUpdatePayload
  >(
    (payload) => {
      if (companyId) {
        return payload.kind === ICompanyKindEnum.DISTRIBUTOR
          ? FrontOfficeUserApi.updateDistributorUser({
              payload: payload,
              companyId,
            })
          : FrontOfficeUserApi.updateEnterpriseUser({
              companyId,
              payload: payload.user,
            });
      } else {
        return Promise.reject(undefined);
      }
    },
    {
      onSuccess: (data, variables) => {
        if (data) {
          notify({
            message: t(successMessage),
            type: INotifierEnum.Success,
          });
          onSuccess?.(data);
          if (companyId)
            queryClient
              .invalidateQueries(
                QUERY_KEYS.COMPANY_USERS_LIST(
                  UsersUtils.USER_KIND_2_COMPANY_KIND[variables.kind],
                  companyId
                )
              )
              .catch(() => {});
        }
      },
    }
  );

  const run = useCallback(
    (body: IAnyFOUserUpdatePayload) => {
      if (companyId) {
        mutation.mutate(body);
      } else {
        throw new Error('company id is required');
      }
    },
    [mutation, companyId]
  );

  return {
    run,
    mutation,
  };
};
export default useUpdateFOUser;
