import {
  IARStatusEnum,
  ICompanyKindEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { DateFormatter, Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';

import {
  DistributorUserOptionLabel,
  EnterpriseUserOptionLabel,
} from '@core/components';

import { useFetchCompanyUsers } from '@core/queries';

import { useEssentials } from '@core/contexts';

import {
  Controls,
  Grid,
  FormGroup,
  Divider,
  Collapse,
} from '@shared/components';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
  vendorInvolvement: boolean;
}

const ExtendSurveyDeadlineForm: React.FCC<Props> = ({
  assessmentRequest,
  vendorInvolvement,
}) => {
  const { t } = useEssentials();

  const { data: distributorUsers } = useFetchCompanyUsers({
    kind: ICompanyKindEnum.DISTRIBUTOR,
    id: assessmentRequest.scoredCompany.id,
  });
  const distributorActiveUsers = Helpers.ensureValueAsArray(
    distributorUsers?.data
  ).filter((user) => user.status !== IUserStatusEnum.STATUS_DISABLED);

  const { data: manufacturerUsers } = useFetchCompanyUsers({
    kind: ICompanyKindEnum.SUPPLIER,
    id: assessmentRequest?.requester?.id,
  });

  const manufacturerActiveUsers = Helpers.ensureValueAsArray(
    manufacturerUsers?.data
  ).filter((user) => user.status !== IUserStatusEnum.STATUS_DISABLED);

  return (
    <>
      <Grid item md={12}>
        <FormGroup>
          <Controls.FormDatePicker
            inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
            name='questionnaireDeadline'
            label={t(IARStatusEnum.ANSWERING_SURVEY)}
            pickerType='date'
            componentsProps={{
              actionBar: {
                actions: ['today'],
              },
            }}
            textFieldProps={{
              fullWidth: true,
            }}
          />
        </FormGroup>
      </Grid>
      <div
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          display: 'flex',
        }}
      />
      {distributorUsers?.data && (
        <Controls.FormCheckboxGroup
          name='distributorUsers'
          label={t('Send Deadline extension email to: *')}
          options={distributorActiveUsers.map((user) => {
            return {
              value: user.id,
              label: <DistributorUserOptionLabel {...{ user }} />,
            };
          })}
        />
      )}
      {manufacturerActiveUsers.length > 0 && (
        <>
          <FormGroup style={{ paddingTop: 20 }}>
            <Divider />
            <Controls.FormCheckbox
              name='vendorInvolvement'
              label={t('MA')}
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                display: 'flex',
              }}
              disabled={assessmentRequest.extensionHistoryCount === 0}
            />
          </FormGroup>

          <Collapse in={vendorInvolvement}>
            {manufacturerUsers?.data && (
              <Controls.FormCheckboxGroup
                name='manufacturerUsers'
                label=''
                options={manufacturerActiveUsers.map((user) => {
                  return {
                    value: user.id,
                    label: <EnterpriseUserOptionLabel {...{ user }} />,
                  };
                })}
              />
            )}
          </Collapse>
        </>
      )}
    </>
  );
};

export default memo(ExtendSurveyDeadlineForm);
