import { omit } from 'lodash-es';

import * as React from 'react';

import { useCompanyQualificationEnums } from '@core/queries';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const CategoryFilterer: React.FCC<
  FormSelectProps & { defaultItem: IFilterDefaultItem }
> = (props) => {
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  return (
    <>
      {companyQualificationEnums && (
        <Controls.FormSelect
          multiple={true}
          {...omit(props, ['defaultItem'])}
          options={companyQualificationEnums.categories}
          required
        />
      )}
    </>
  );
};

export default CategoryFilterer;
