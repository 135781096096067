import { FormLabel, RadioGroupProps } from '@mui/material';
import { get } from 'lodash-es';

import React, { forwardRef, memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  FormControlLabel,
  ErrorHelpText,
  Radio,
  FormControl,
  RadioGroup,
} from '@shared/components';

import type { FormBoolRadioGroupProps } from '@shared/types';

interface OptionItem {
  label: string;
  value: string | number | boolean;
}

interface RadioButtonsProps {
  name: string;
  helperText?: string;
  rhfOnChange: (newValue: boolean) => void;
  value: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  options: OptionItem[];
}

const RadioButtons = forwardRef(
  (
    {
      options,
      name,
      value,
      labelPlacement,
      rhfOnChange,
      ...rest
    }: RadioGroupProps & RadioButtonsProps,
    ref
  ) => {
    const [radioState, setRadioState] = useState(Boolean(value));
    useEffect(() => {
      setRadioState(Boolean(value));
    }, [value]);

    return (
      <RadioGroup name={name} {...rest} data-cy={name}>
        {options.map((option) => (
          <FormControlLabel
            inputRef={ref} // needed so that RHF can focus on error
            key={option.label}
            id={`${name}-${option.value.toString()}`}
            value={option.label}
            checked={option.value === radioState}
            onChange={({ target }, checked) => {
              const nextValue = Boolean(checked);
              setRadioState(nextValue);
              // @ts-expect-error TODO: fix this
              rhfOnChange(target.value === options[0].label);
            }}
            control={<Radio />}
            label={option.label}
            labelPlacement={labelPlacement}
          />
        ))}
      </RadioGroup>
    );
  }
);

const FormBoolRadioGroup: React.FCC<FormBoolRadioGroupProps> = (props) => {
  const { t } = useEssentials();
  const {
    helperText,
    name,
    label,
    required,
    readOnly,
    defaultValue = false,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormControl
      component='fieldset'
      error={!!errorMessage}
      required={required}
      disabled={readOnly}
    >
      <FormLabel component='legend'>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field: { onChange, ...field } }) => {
          return <RadioButtons {...rest} {...field} rhfOnChange={onChange} />;
        }}
        defaultValue={defaultValue}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

export default memo(FormBoolRadioGroup);
