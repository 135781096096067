import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isScoreCard } from '@core/guards/scorecards';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  Id,
  IScoreCard,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type ScoreCardsActions = 'getOneById' | 'patch';

const baseScoreCardsApi = ApiFactory.generate<
  IScoreCard,
  JwtBOUser,
  ScoreCardsActions
>({
  baseRoute: '/score-cards',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isScoreCard,
});

const updateScore = async (id: Id): Promise<IAPIResponse> => {
  const { data } = await baseScoreCardsApi.client.instance.post<IAPIResponse>(
    `/${id}/score`
  );

  return data;
};

export default {
  ...baseScoreCardsApi.actions,
  updateScore,
};
