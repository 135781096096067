import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/reporting';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

type ReportingMeta = {
  collections: Array<string>;
  fieldsByCollection: Record<string, Array<string>>;
};
const meta = async (): Promise<IAPIResponse<ReportingMeta>> => {
  const { data } = await client.instance.get<IAPIResponse<ReportingMeta>>(
    '/meta'
  );
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getReport = async (qs: URLSearchParams): Promise<any[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await client.instance.get<any[]>(`/export?${qs.toString()}`);
  return data;
};

export default {
  meta,
  getReport,
};
