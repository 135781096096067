import { ISsqQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { Helpers, I18n, Transformers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useQuestionFormContext } from '@core/components/questions';

import { useFetchSsqQuestions } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { ISsqQuestionFormData } from '@core/types';

import {
  Card,
  Controls,
  Typography,
  Grid,
  Box,
  FormGroup,
  Divider,
} from '@shared/components';

import type {
  IAnySsqQuestion,
  IIdentifiedObject,
} from '@inteliam/foundation/lib/types';

const formatQuestions = (question: IAnySsqQuestion) => ({
  label: `${question.code}: ${I18n.getTranslatedValue(question.title)}`,
  value: question.id,
});

const getAvailableSiblings = ({
  siblings,
}: {
  siblings: IAnySsqQuestion[];
}) => {
  return siblings.filter((sibling) => {
    return sibling.kind !== ISsqQuestionKindEnum.SingleFreeFormField;
  });
};

const getOptions = (siblings: IAnySsqQuestion[], watchedParentId: unknown) => {
  const parent = siblings.find((question) => question.id === watchedParentId);
  if (!parent || parent.kind === ISsqQuestionKindEnum.SingleFreeFormField) {
    return { parentOptions: [] };
  }
  const options = Helpers.ensureValueAsArray(parent?.options);
  const parentOptions = Transformers.mapIntoOptionItem(
    options,
    (option) => I18n.getTranslatedValue(option.label),
    'id'
  );

  return { parentOptions };
};
const Logic: React.FCC = () => {
  const { t } = useEssentials();
  const { id } = useParams<IIdentifiedObject>();
  const methods = useFormContext<ISsqQuestionFormData>();
  const { readOnly } = useQuestionFormContext();
  const siblingQuery = useFetchSsqQuestions({
    manufacturerId: id,
  });
  const watchedParentId = useWatch({
    control: methods.control,
    name: 'parentDependency.question.id',
  });
  const { parentOptions } = getOptions(
    Helpers.ensureValueAsArray(siblingQuery.questions),
    watchedParentId
  );

  return (
    <Card.CContainer
      styled
      style={readOnly ? { opacity: 0.3, pointerEvents: 'none' } : undefined}
    >
      <Card.Header title={t('Logic')} />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <Typography component='h6'>{t('Link to question parent')}</Typography>
        </Box>
        {siblingQuery.isSuccess && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Name of parent question')}
                  name='parentDependency.question.id'
                  options={getAvailableSiblings({
                    siblings: Helpers.ensureValueAsArray(
                      siblingQuery.questions
                    ),

                    // eslint-disable-next-line unicorn/no-array-callback-reference
                  }).map(formatQuestions)}
                  readOnly={readOnly}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Select a parent question options')}
                  name='parentDependency.optionIds'
                  options={parentOptions}
                  disabled={parentOptions.length === 0}
                  defaultValue=''
                  readOnly={readOnly}
                  multiple
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(Logic);
