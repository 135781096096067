import * as React from 'react';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { ASSESSMENT_REQUEST_ENUMS } from '@core/enums';

import { useDashboard, useEssentials } from '@core/contexts';

import {
  NativeSelect,
  RouterLink,
  Grid,
  FormControl,
  OpenInNewIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IAR, IPatchObject } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles(() => ({
  select: {
    height: '100%',
  },
}));
interface Props {
  assessmentRequest: IAR;
}
const ThreeSixtyCell: React.FCC<Props> = ({ assessmentRequest }) => {
  const classes = useStyles();
  const { t, confirm } = useEssentials();
  const { dashboard } = useDashboard();
  const { patch } = useManageAssessmentRequest({
    patch: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const newValue: string = event.target.value as string;
    confirm({
      description: t('Are you sure you want to continue with this action?'),
    })
      .then(() => {
        patch.mutate({
          body: {
            threeSixty: newValue,
          },
          id: assessmentRequest.id,
        } as IPatchObject);
      })
      .catch(() => {});
  };

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center'>
      <FormControl style={{ width: '75%' }}>
        <NativeSelect
          id={`news-state-${assessmentRequest.businessId}`}
          value={assessmentRequest.threeSixty}
          onChange={assessmentRequest.isFinalStatus ? undefined : handleChange}
          disabled={assessmentRequest.isFinalStatus || patch.isLoading}
          name='threeSixty'
          inputProps={{
            className: classes.select,
          }}
        >
          {ASSESSMENT_REQUEST_ENUMS.threeSixty.map((it) => (
            <option
              key={it.value}
              value={it.value}
              id={`news-state-${it.label}`}
            >
              {t(it.label)}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <RouterLink
        to={`/news/${assessmentRequest.id}`}
        id={`news-${assessmentRequest.businessId}`}
        style={{ display: 'flex' }}
      >
        <OpenInNewIcon />
      </RouterLink>
    </Grid>
  );
};

export default ThreeSixtyCell;
