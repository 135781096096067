import { yupResolver } from '@hookform/resolvers/yup';
import { ISsqQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations, Helpers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { useForm } from 'react-hook-form';

import { QuestionFormProvider } from '@core/components/questions';

import { useEssentials } from '@core/contexts';
import type { ISsqQuestionFormData } from '@core/types';

import { Controls } from '@shared/components';

import { ErrorCodesLookup, ValidationUtils } from '@shared/utils';

import type {
  IAnySsqQuestion,
  IPartialQuestion,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import * as schemas from './schema';

const DEFAULT_VALUES: ISsqQuestionFormData = {
  code: '',
  kind: ISsqQuestionKindEnum.MultipleChoiceField,
  required: false,
  document: 'optional',
  status: 'draft',
  title: {
    defaultValue: '',
    translations: [],
  },
  helpText: false,
  helpTextContent: {
    defaultValue: '',
    translations: [],
  },
  options: [
    {
      id: Helpers.uuid(),
      order: 0,
      label: {
        defaultValue: '',
        translations: [],
      },
      helpText: false,
      helpTextContent: {
        defaultValue: '',
        translations: [],
      },
    },
  ],
  scopes: [],
};
interface Props {
  onSubmit: (values: ISsqQuestionFormData) => void;
  defaultValues?: IAnySsqQuestion;
  errors?: ValidationErrorResponse;
  readOnly: boolean;
}
const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  readOnly,
  errors,
  children,
}) => {
  const { notify, INotifierEnum, t } = useEssentials();
  const methods = useForm<ISsqQuestionFormData>({
    defaultValues: defaultValues || DEFAULT_VALUES,
    resolver: yupResolver(schemas.create),
    reValidateMode: 'onChange',
    mode: 'onChange',
    // criteriaMode: 'all',
    shouldFocusError: true,
  });
  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <QuestionFormProvider
      readOnly={readOnly}
      defaultValues={defaultValues as unknown as IPartialQuestion} // TODO use generics
    >
      <Controls.Form
        submitHandler={(data) => onSubmit(data)}
        failureHandler={() => {
          notify({
            message: t(ErrorCodesLookup.E0000.title),
            type: INotifierEnum.Error,
          });
        }}
        methods={methods}
      >
        {children}
      </Controls.Form>
    </QuestionFormProvider>
  );
};
export default Form;
