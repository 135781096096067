import { Yup } from '@inteliam/foundation/lib/utils';
import { has } from 'lodash-es';

import type { ValidationError } from 'yup';

import { IDiscountTypesEnum } from '@core/enums';

import type {
  IInitiateSurveyForm,
  IPartialInitiateSurveyForm,
} from '@core/types';

import { ErrorCodesLookup } from '@shared/utils/error-enum';

const requiredPrice = Yup.object().shape({
  value: Yup.number()
    .min(0, ErrorCodesLookup.E0007.title)
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .required(ErrorCodesLookup.E0001.title),
  currency: Yup.string().required(ErrorCodesLookup.E0001.title),
});
export const discountSchema = Yup.object().shape({
  type: Yup.string().required(ErrorCodesLookup.E0001.title),
  percentage: Yup.number().when('type', {
    is: IDiscountTypesEnum.TYPE_PERCENTAGE,
    then: Yup.number()
      .min(0, ErrorCodesLookup.E0007.title)
      .max(100, 'Discount cannot be higher than 100%')
      .required(ErrorCodesLookup.E0001.title),
    otherwise: Yup.number().nullable(),
  }),
  // period: Yup.string().required(ErrorCodesLookup.E0001.title),
  price: Yup.object().when('type', {
    is: IDiscountTypesEnum.TYPE_AMOUNT,
    then: requiredPrice,
    otherwise: Yup.object().nullable(),
  }),
});

type DefinedPartialInitiateSurveyForm = IPartialInitiateSurveyForm & {
  inteliamDiscountEnabled: IInitiateSurveyForm['inteliamDiscountEnabled'];
  manufacturerDiscountEnabled: IInitiateSurveyForm['manufacturerDiscountEnabled'];
  inteliamDiscounts: IInitiateSurveyForm['inteliamDiscounts'];
  manufacturerDiscounts: IInitiateSurveyForm['manufacturerDiscounts'];
};

const isIPartialInitiateSurveyForm = (
  argument: unknown
): argument is DefinedPartialInitiateSurveyForm => {
  return (
    has(argument, 'inteliamDiscountEnabled') &&
    has(argument, 'manufacturerDiscountEnabled') &&
    has(argument, 'inteliamDiscounts') &&
    has(argument, 'manufacturerDiscounts')
  );
};

export const validatePercentagesSum = (
  value: unknown,
  testContext: Yup.TestContext<IPartialInitiateSurveyForm>,
  name: string
): ValidationError | boolean => {
  if (!isIPartialInitiateSurveyForm(value)) {
    return true;
  }

  if (
    value.inteliamDiscounts?.['1_YEAR']?.enabled &&
    value.manufacturerDiscounts?.['1_YEAR']?.enabled &&
    value.inteliamDiscounts?.['1_YEAR']?.type ===
      IDiscountTypesEnum.TYPE_PERCENTAGE &&
    value.manufacturerDiscounts?.['1_YEAR']?.type ===
      IDiscountTypesEnum.TYPE_PERCENTAGE
  ) {
    const inteliamPercentageValue =
      value.inteliamDiscounts?.['1_YEAR'].percentage;
    const manufacturerPercentageValue =
      value.manufacturerDiscounts?.['1_YEAR'].percentage;

    if (inteliamPercentageValue + manufacturerPercentageValue > 100) {
      return testContext.createError({
        path: name,
        message:
          "Overall percentage for 1 year subscription discount mustn't be greater than 100%",
      });
    }
  }
  if (
    value.inteliamDiscounts?.['3_YEAR']?.enabled &&
    value.manufacturerDiscounts?.['3_YEAR']?.enabled &&
    value.inteliamDiscounts?.['3_YEAR']?.type ===
      IDiscountTypesEnum.TYPE_PERCENTAGE &&
    value.manufacturerDiscounts?.['3_YEAR']?.type ===
      IDiscountTypesEnum.TYPE_PERCENTAGE
  ) {
    const inteliamPercentageValue =
      value.inteliamDiscounts?.['3_YEAR'].percentage;
    const manufacturerPercentageValue =
      value.manufacturerDiscounts?.['3_YEAR'].percentage;

    if (inteliamPercentageValue + manufacturerPercentageValue > 100) {
      return testContext.createError({
        path: name,
        message:
          "Overall percentage for 3 years subscription discount mustn't be greater than 100%",
      });
    }
  }

  return true;
};

export const validateSameDiscountsType = (
  value: unknown,
  period: '1_YEAR' | '3_YEAR',
  testContext: Yup.TestContext<IPartialInitiateSurveyForm>,
  name: string,
  errorMessage: string
): ValidationError | boolean => {
  if (!isIPartialInitiateSurveyForm(value)) {
    return true;
  }

  const inteliamDiscount = value.inteliamDiscounts?.[period];
  const manufacturerDiscount = value.manufacturerDiscounts?.[period];
  // const { inteliamDiscount, manufacturerDiscount } = getDiscountData(value);

  if (
    inteliamDiscount?.enabled &&
    manufacturerDiscount?.enabled &&
    inteliamDiscount.type !== manufacturerDiscount.type
  ) {
    return testContext.createError({
      path: name,
      message: errorMessage,
    });
  }

  return true;
};

export default {
  discountSchema,
  validatePercentagesSum,
  validateSameDiscountsType,
};
