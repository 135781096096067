import { usePersistentState } from '@inteliam/foundation/lib/hooks';
import {
  createTheme,
  PaletteMode,
  StyledEngineProvider,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material';

import * as React from 'react';

import { useNamedContext } from '@shared/contexts';

import { ColorModeContext } from './contexts';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Palette {
    ternary: Palette['primary'];
    anchor: Palette['primary'];
  }

  interface PaletteOptions {
    ternary: PaletteOptions['primary'];
    anchor: PaletteOptions['primary'];
  }
}

export type IColorModeContext = {
  toggleMode: () => void;
};

const toggleModeDataAttribute = (mode: PaletteMode) => {
  document.documentElement.dataset.theme = mode;
};

const ColorLookup = {
  dark: {
    background: {
      default: '#303030',
    },
    text: {
      primary: '#E6E5E8',
      secondary: 'rgb(145, 158, 171)',
    },
    anchor: {
      main: '#58A5FC',
    },
    error: {
      main: '#ef8e9f',
    },
  },
  light: {
    background: {
      default: '#f5f5f9',
    },
    text: {
      primary: '#1C2025',
      secondary: 'rgba(0, 0, 0, 0.54)',
    },
    anchor: {
      main: '#0F41FC',
    },
    error: {
      main: '#f44336',
    },
  },
};
const ThemeProvider: React.FCC = ({ children }) => {
  const [mode, setMode] = usePersistentState<PaletteMode>(
    '__inteliam_theme_mode__',
    'light'
  );
  React.useLayoutEffect(() => {
    toggleModeDataAttribute(mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleMode: () => {
        setMode((prevMode) => {
          const nextMode: PaletteMode = prevMode === 'light' ? 'dark' : 'light';
          toggleModeDataAttribute(nextMode);
          return nextMode;
        });
      },
    }),
    [setMode]
  );

  const themeContextValue = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiFormHelperText: {
            styleOverrides: {
              root: {
                wordBreak: 'break-word',
              },
            },
          },
        },
        typography: {
          htmlFontSize: 18, // activate this to reduce the font size of the app globally
        },
        palette: {
          mode: mode,
          primary: {
            main: '#6EBC6E',
            light: '#6EBC6E',
            dark: '#6EBC6E',
          },
          secondary: {
            light: '#BC6C6C',
            main: '#BC6C6C',
            dark: '#BC6C6C',
          },
          ternary: {
            light: '#6C6CBC',
            main: '#6C6CBC',
            dark: '#6C6CBC',
          },
          anchor: {
            main: ColorLookup[mode].anchor.main,
          },
          text: {
            primary: ColorLookup[mode].text.primary,
            secondary: ColorLookup[mode].text.secondary,
          },
          background: {
            default: ColorLookup[mode].background.default,
          },
          success: {
            main: '#4caf50',
          },
          error: {
            main: ColorLookup[mode].error.main,
          },
          warning: {
            main: '#ff9800',
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <MUIThemeProvider theme={themeContextValue}>
        <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
      </MUIThemeProvider>
    </ColorModeContext.Provider>
  );
};

function useThemeMode(): IColorModeContext {
  return useNamedContext(ColorModeContext);
}

export { ThemeProvider, useThemeMode };
