import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { EnterpriseDiscountsForm } from '@core/components';

import {
  QUERY_KEYS,
  useFetchEnterpriseDiscountById,
  useManageEnterpriseDiscounts,
} from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';

import { CenteredSpinner } from '@shared/components';

import type { IIdentifiedObject } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  onClose: () => void;
  discountId: string;
}

const UpdateEnterpriseDiscountModal: React.FCC<Props> = ({
  onClose,
  discountId,
}) => {
  const { notify, t } = useEssentials();
  const queryClient = useQueryClient();
  const { id: manufacturerId } = useParams<IIdentifiedObject>();

  const discountQuery = useFetchEnterpriseDiscountById({
    discountId,
    manufacturerId,
  });
  const { update } = useManageEnterpriseDiscounts({
    onSuccess: () => {
      notify({
        message: t('Discount was edited.'),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries([
          ...QUERY_KEYS.MANUFACTURER_DISCOUNTS_LIST(manufacturerId),
        ])
        .catch(() => {});
      onClose();
    },
  });

  return (
    <BaseDialog.Dialog keepMounted={false} open={true} onClose={onClose}>
      <BaseDialog.Title
        id='modals.manufacturer_discount.title'
        onClose={onClose}
      >
        {t('Edit a discount')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        {!discountQuery.isSettled && <CenteredSpinner />}
        {discountQuery.isSuccess && (
          <EnterpriseDiscountsForm
            onSubmit={(data) => {
              update.run(discountId, data);
            }}
            isLoading={update.mutation.isLoading}
            onClose={onClose}
            defaultValues={discountQuery.data.data}
          />
        )}
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(UpdateEnterpriseDiscountModal);
