import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';

import React, { memo, useState } from 'react';

import { clsx } from 'clsx';

import {
  AnswersAnalysisDropdown,
  TextBoxValueRenderer,
} from '@core/components/assessment-requests/answers-analysis-v2/internals';
import { DocumentAnalysisModal } from '@core/components/modals';

import type { IEvaluationStatusEnum } from '@core/enums';

import type {
  AnswerAnalysisFieldProps,
  IAnswerEvaluation,
  IEvaluationProgress,
} from '@core/types';

import { Box } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IOptionProperties } from '@inteliam/foundation/lib/types';

import {
  AnswerAnalysisQuestionCell,
  AnswerAnalysisRow,
  AnswersAnalysisReferencesCell,
} from '../internals';

const useStyles = makeStyles(() => ({
  checked: {
    fontWeight: 'bold',
  },
  unchecked: {
    color: '#ccc',
  },
}));

const SingleChoiceFormField: React.FCC<AnswerAnalysisFieldProps> = ({
  answerEvaluation,
  referencesProgress,
  assessmentRequestId,
  query,
}) => {
  if (
    answerEvaluation.answer.field.kind !== IQuestionKindEnum.SingleChoiceField
  ) {
    throw new Error('This code should never be reached [SingleChoiceField]');
  }
  const classes = useStyles();

  const [modal, setModal] = useState<string | undefined>(undefined);

  const statuses = Object.keys(query.statuses).filter(
    (status) => query.statuses[status as IEvaluationStatusEnum] === true
  );
  return (
    <>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.FullWidth>
              <AnswerAnalysisQuestionCell answerEvaluation={answerEvaluation} />
            </Layouts.FullWidth>
          );
        }}
      </AnswerAnalysisRow>

      {answerEvaluation.answer.field.definition
        .filter((option) => {
          const checked = isChecked(answerEvaluation, option);
          return canShow(statuses, referencesProgress?.[option.value], checked);
        })
        .map((option) => {
          const checked = isChecked(answerEvaluation, option);

          return (
            <AnswerAnalysisRow key={option.value}>
              {({ Layouts }) => {
                return (
                  <>
                    <Layouts.Question>
                      <Box
                        className={clsx({
                          [classes.checked]: checked,
                          [classes.unchecked]: !checked,
                        })}
                      >
                        {option.label}
                      </Box>
                    </Layouts.Question>

                    <Layouts.Textbox>
                      {checked && option.schema.textbox?.enabled && (
                        <TextBoxValueRenderer
                          format={option.schema.textbox.format}
                          value={option.schema.textbox.defaultValue}
                        />
                      )}
                    </Layouts.Textbox>
                    <Layouts.Dropdown>
                      {checked && option.schema.dropdown?.enabled && (
                        <AnswersAnalysisDropdown
                          dropdownSchema={option.schema.dropdown}
                        />
                      )}
                    </Layouts.Dropdown>
                    <Layouts.Reference>
                      {checked && referencesProgress?.[option.value] && (
                        <AnswersAnalysisReferencesCell
                          referenceProgress={referencesProgress?.[option.value]}
                          onOpen={() => setModal(answerEvaluation.id)}
                        >
                          {modal === answerEvaluation.id && (
                            <DocumentAnalysisModal
                              {...{
                                onClose: () => setModal(undefined),
                                assessmentRequestId,
                                answerId:
                                  answerEvaluation.answer.field.answerId,
                                optionId: option.value,
                              }}
                            />
                          )}
                        </AnswersAnalysisReferencesCell>
                      )}
                    </Layouts.Reference>
                  </>
                );
              }}
            </AnswerAnalysisRow>
          );
        })}
    </>
  );
};

export default memo(SingleChoiceFormField);

function isChecked(
  answerEvaluation: IAnswerEvaluation,
  option: IOptionProperties
) {
  return answerEvaluation.answer.field.defaultValue
    ? (answerEvaluation.answer.field.defaultValue as string) === option.value
    : false;
}

function canShow(
  statuses: Array<string>,
  referencesProgress?: IEvaluationProgress,
  checked?: boolean
) {
  const progress = checked ? referencesProgress : undefined;

  if (progress) {
    return statuses.some((status) => {
      const key = status.toLocaleLowerCase() as keyof IEvaluationProgress;
      return progress[key] > 0;
    });
  }
  return true;
}
