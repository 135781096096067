/* eslint-disable @inteliam/i18n/raw-text-detected */
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { useEssentials } from '@core/contexts';

import { Grid, Box, Card } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type {
  IAssessmentStrength,
  IAssessmentWeakness,
  IPartialScore,
} from '@inteliam/foundation/lib/types';

import { ValidationDraggables } from '.';

type Props = {
  partialScore: IPartialScore;
  readOnly?: boolean;
  strengths: Array<IAssessmentStrength>;
  weaknesses: Array<IAssessmentWeakness>;
};

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    marginTop: theme.spacing(1),
  },
}));

const ReviewsGroupedByCriteria: React.FCC<Props> = ({
  partialScore,
  readOnly = false,
  strengths,
  weaknesses,
}) => {
  const { t, na } = useEssentials();

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Box p={1} />
        </Grid>
        <Grid item xs={5}>
          <Box p={1} className={classes.header}>
            {t('Strengths')}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box p={1} className={classes.header}>
            {t('Areas of Improvements')}
          </Box>
        </Grid>
      </Grid>
      <Card.CContainer>
        <Card.Body>
          <Box p={1} border='1px solid black'>
            <>
              {partialScore.criteriaPartialScores.map(
                (criterionPartialScores) => {
                  const selectedStrengths = strengths.filter(
                    (assessmentStrength) =>
                      assessmentStrength.strength.criterion.id ===
                      criterionPartialScores.criterion.id
                  );
                  const selectedWeaknesses = weaknesses.filter(
                    (assessmentWeakness) =>
                      assessmentWeakness.weakness.criterion.id ===
                      criterionPartialScores.criterion.id
                  );
                  return (
                    <Box
                      border='1px solid black'
                      p={1}
                      style={{ marginBottom: 10 }}
                      key={criterionPartialScores.criterion.id}
                    >
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 'medium',
                            fontWeight: 'bold',
                          }}
                        >
                          <Box>
                            {I18n.getTranslatedValue(
                              criterionPartialScores.criterion.name
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          {selectedStrengths.length > 0 ? (
                            <ValidationDraggables
                              reviews={selectedStrengths}
                              readOnly={readOnly}
                              themeId={partialScore.theme.id}
                            />
                          ) : (
                            <div>{na}</div>
                          )}
                        </Grid>
                        <Grid item xs={5}>
                          {selectedWeaknesses.length > 0 ? (
                            <ValidationDraggables
                              reviews={selectedWeaknesses}
                              readOnly={readOnly}
                              themeId={partialScore.theme.id}
                            />
                          ) : (
                            <div>{na}</div>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }
              )}
            </>
          </Box>
        </Card.Body>
      </Card.CContainer>
    </>
  );
};

export default ReviewsGroupedByCriteria;
