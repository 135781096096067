import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import Discount from '@core/components/distributors/subscription/discount';

import { useEssentials } from '@core/contexts';

import { Controls, Grid } from '@shared/components';

export const InitiateSurveyDiscountsForm: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext();
  const inteliamDiscountEnabled = useWatch({ name: 'inteliamDiscountEnabled' });
  const manufacturerDiscountEnabled = useWatch({
    name: 'manufacturerDiscountEnabled',
  });
  return (
    <Grid container direction='row' justifyContent='space-between' padding={0}>
      <Grid item md={5} xs={12}>
        <Controls.FormCheckbox
          label={t('Enterprise Discount')}
          name={'manufacturerDiscountEnabled'}
          onChange={(_event, _checked) => {
            methods.setValue('manufacturerDiscounts.1_YEAR.enabled', false);
            methods.setValue('manufacturerDiscounts.3_YEAR.enabled', false);
          }}
        />
        <Discount
          name='manufacturerDiscounts'
          readOnly={!manufacturerDiscountEnabled}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <Controls.FormCheckbox
          label={t('Inteliam Discount')}
          name={'inteliamDiscountEnabled'}
          onChange={(_event, _checked) => {
            methods.setValue('inteliamDiscounts.1_YEAR.enabled', false);
            methods.setValue('inteliamDiscounts.3_YEAR.enabled', false);
          }}
        />
        <Discount
          name='inteliamDiscounts'
          readOnly={!inteliamDiscountEnabled}
        />
      </Grid>
    </Grid>
  );
};

export default memo(InitiateSurveyDiscountsForm);
