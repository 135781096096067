import * as React from 'react';

import { clsx } from 'clsx';

import type { IEvaluationProgress } from '@core/types';

import { Box, BookmarkIcon, BookmarkBorderIcon } from '@shared/components';

import { makeStyles } from '@shared/utils';

import { EvaluationInsights } from '../../common';

const useStyles = makeStyles((theme) => ({
  root: {
    '& svg.pending': {
      color: 'gray',
    },
    '& svg.approved': {
      color: theme.palette.success.main,
    },
    '& svg.rejected': {
      color: theme.palette.error.main,
    },
  },
}));

interface Props {
  referenceProgress: IEvaluationProgress;
}
const ReviewDocumentsLibraryReferencesCell: React.FCC<Props> = ({
  referenceProgress,
}) => {
  const classes = useStyles();
  return (
    <Box display='flex' flexDirection='row'>
      <Box p={1} className={classes.root} display='flex'>
        {referenceProgress.pending > 0 && referenceProgress.approved === 0 ? (
          <BookmarkBorderIcon />
        ) : (
          <BookmarkIcon
            className={clsx({
              approved: referenceProgress.approved > 0,
              rejected:
                referenceProgress.rejected > 0 &&
                referenceProgress.approved === 0 &&
                referenceProgress.pending === 0,
            })}
          />
        )}
      </Box>
      <Box p={1}>
        <EvaluationInsights evaluationProgress={referenceProgress} />
      </Box>
    </Box>
  );
};

export { ReviewDocumentsLibraryReferencesCell };
