import {
  IAnalysisHighlightsKindEnum,
  IARStatusEnum,
  IFilterTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { CreateCustomAnalysisHighlightModal } from '@core/components/modals';

import { useWorkingPage } from '@core/hooks';

import { QUERY_KEYS, useManageAnalysisHighlights } from '@core/queries';

import { AnalysisHighlightsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import { isITranslatableField } from '@core/guards';

import {
  Box,
  Button,
  CreateButton,
  Datatable,
  DeleteIcon,
} from '@shared/components';

import { DatatableUtils } from '@shared/utils';

import type { IFilterableColumn } from '@shared/types';

import type { IMasterAnalysisHighlight } from '@inteliam/foundation/lib/types';

const CustomAnalysisHighlights: React.FCC = () => {
  const [opened, handlers] = useBoolean(false);
  const { assessmentRequest } = useWorkingPage();

  const canCreateCustomAnalysisHighlight = [
    IARStatusEnum.IN_DOC_ANALYSIS,
    IARStatusEnum.IN_SCORING,
  ].includes(assessmentRequest.status);
  const { t } = useEssentials();
  const { remove, patch } = useManageAnalysisHighlights(
    IAnalysisHighlightsKindEnum.CUSTOM
  );

  const columns = React.useMemo<
    Array<IFilterableColumn<IMasterAnalysisHighlight>>
  >(
    () => [
      {
        field: 'description.defaultValue',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        sortable: false,
        headerName: t('Text'),
        width: 990,
        editable: true,
        valueGetter: (parameters) => {
          return isITranslatableField(parameters.row.description)
            ? parameters.row.description?.defaultValue
            : '';
        },

        renderCell: (parameters) => {
          return (
            <span>{I18n.getTranslatedValue(parameters.row.description)}</span>
          );
        },
      },
      {
        field: 'actions',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Actions'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <Box p={1} display='flex'>
              <Button
                onClick={() => remove.run(parameters.row.id)}
                id={'delete-contact-log-resource'}
              >
                <DeleteIcon />
              </Button>
            </Box>
          );
        },
      },
    ],
    [remove, t]
  );

  return (
    <Box>
      <Box display={'flex'} justifyContent={'end'}>
        {canCreateCustomAnalysisHighlight && (
          <CreateButton
            id='add-custom-analysis-highlights'
            onClick={handlers.setTrue}
            label={t('Add')}
          />
        )}
        {opened && (
          <CreateCustomAnalysisHighlightModal
            assessmentRequestId={assessmentRequest.id}
            open={opened}
            onClose={handlers.setFalse}
          />
        )}
      </Box>
      <div style={{ marginTop: 40 }}>
        <Datatable.Container
          queryKey={[
            ...QUERY_KEYS.ANALYSIS_HIGHLIGHTS_LIST(
              IAnalysisHighlightsKindEnum.CUSTOM
            ),
            assessmentRequest.id,
          ]}
          columns={columns}
          fetcher={(paginator) =>
            AnalysisHighlightsApi.paginate(
              {
                ...paginator,
                primaryCriteria: {
                  conditions: [
                    DatatableUtils.createCondition(
                      'distributor.id',
                      'eq',
                      assessmentRequest.scoredCompany.id
                    ),
                  ],
                  operator: 'and',
                },
              },
              IAnalysisHighlightsKindEnum.CUSTOM
            )
          }
        >
          <div style={{ height: '100%', width: '100%' }}>
            <Datatable.Table
              checkboxSelection={false}
              autoHeight
              onCellEditStop={(parameters, event) => {
                patch.run(
                  {
                    [parameters.field]: event.target.value,
                  },
                  parameters.id as string
                );
              }}
            />
          </div>
        </Datatable.Container>
      </div>
    </Box>
  );
};
export default React.memo(CustomAnalysisHighlights);
