import {
  IChannelEnum,
  IModeEnum,
  ITypeEnum,
} from '@inteliam/foundation/lib/enums';

import type { IContactLogForm } from '@core/types';

import type { IPartialContactLog } from '@inteliam/foundation/lib/types';

export const isAutomatic = (contactLog: IPartialContactLog): boolean => {
  return (
    contactLog.channel === IChannelEnum.EMAIL &&
    contactLog.mode === IModeEnum.OUT &&
    contactLog.type !== ITypeEnum.MANUAL
  );
};

export const getInitialFiles = (
  defaultValues?: Partial<IContactLogForm>
): Array<File> | undefined => {
  return defaultValues?.files?.map(({ originalFileName, mimeType, size }) => {
    const file = new File([], originalFileName, {
      type: mimeType as string,
    });
    //hint: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
    //writable:false because we Cannot assign to 'size' as it is a read-only property
    Object.defineProperty(file, 'size', { value: size, writable: false });
    return file;
  });
};
