import { Helpers } from '@inteliam/foundation/lib/utils';
import { InputLabel } from '@mui/material';
import { get, isArray, isNil, omit } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { ErrorHelpText, FormControl, Select } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

const ALL_VALUE = '*';

// eslint-disable-next-line sonarjs/cognitive-complexity
const FormSelect: React.FCC<FormSelectProps> = (props) => {
  const { t } = useEssentials();

  const {
    name,
    label,
    helperText,
    defaultValue,
    required,
    options,
    size,
    onChange,
    multiple,
    disabled,
    readOnly,
    enableSelectAll,
    selectAllValue = ALL_VALUE,
    showError = true,
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormControl
      variant='outlined'
      required={required}
      size={size}
      error={!!errorMessage}
      disabled={readOnly || disabled}
      style={{
        width: '100%',
        pointerEvents: readOnly || disabled ? 'none' : 'auto',
      }}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={
          enableSelectAll &&
          Array.isArray(defaultValue) &&
          defaultValue.includes(selectAllValue)
            ? options.map(({ value }) => value)
            : Helpers.getSelectDefaultValue(defaultValue, multiple)
        }
        render={({ field }) => {
          return (
            <Select
              {...omit(props, ['helperText'])}
              {...{ name, label, multiple }}
              labelId={`${name}-label`}
              ref={field.ref} // needed so that RHF can focus on error
              value={getValue(multiple, field.value)}
              onChange={
                onChange
                  ? (withCustomHandler(
                      onChange,
                      field.onChange
                    ) as typeof onChange)
                  : (event) => {
                      const selected = event.target.value;
                      if (
                        enableSelectAll &&
                        Array.isArray(selected) &&
                        selected[selected.length - 1] === selectAllValue
                      ) {
                        field.onChange(
                          field.value.length === options.length
                            ? []
                            : options.map(({ value }) => value)
                        );
                        return;
                      }

                      field.onChange(
                        isArray(selected)
                          ? selected.filter((item: string) => item !== '')
                          : selected
                      );
                    }
              }
            ></Select>
          );
        }}
      />
      {showError && (errorMessage || helperText) && (
        <ErrorHelpText
          data-cy={`helper-${name}`}
          data-testid={`helper-${name}`}
        >
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

const withCustomHandler =
  (
    ourHandler: FormSelectProps['onChange'],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RhfHandler: (...event: any[]) => void
  ): FormSelectProps['onChange'] =>
  (event, child) => {
    ourHandler?.(event, child);
    if (event.defaultPrevented === false) {
      RhfHandler(
        isArray(event.target.value)
          ? event.target.value.filter((item) => item !== '')
          : event.target.value
      );
    }
  };

export default memo(FormSelect);

/* eslint-disable @typescript-eslint/no-explicit-any */
function getValue(multiple: boolean | undefined, value: any): unknown {
  if (multiple && !isArray(value)) {
    return [value];
  }
  if (isNil(value)) {
    return '';
  }
  return value;
}
