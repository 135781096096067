import { PriceUtils } from '@inteliam/foundation/lib/utils';
import { get, isNil } from 'lodash-es';

import * as React from 'react';

import useFetchPricingGrid from '@core/queries/use-fetch-pricing-grid';

import { getSubscriptionPrice } from '@core/utils/subscriptions';

import { useEssentials } from '@core/contexts';
import type { PricingGridDisplayConfig } from '@core/types';

import {
  Button,
  Paper,
  Box,
  FormGroup,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  TableBody,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type {
  SubscriptionPlan,
  PricingCategory,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';

interface Props {
  onClose?: () => void;
}

const useStyles = makeStyles(() => ({
  textCenter: {
    textAlign: 'center',
  },
}));

const Config: PricingGridDisplayConfig = {
  billing: {
    '1_YEAR': {
      label: '1 year',
    },
    '3_YEAR': {
      label: '3 years',
    },
  },
  plan: {
    SUBSCRIPTION_STANDARD: {
      label: 'Standard',
    },
  },
  category: {
    LESS_THAN_50: {
      label: 'Less than € 50M',
    },
    BETWEEN_50_AND_100: {
      label: 'Between € 50M and € 100M',
    },
    MORE_THAN_100: {
      label: 'More than € 100M+',
    },
  },
  classification: {
    1: { label: 'Fast Growing' },
    2: { label: 'Mature' },
  },
};

const PricingGrid: React.FCC<Props> = ({ onClose }) => {
  const { t } = useEssentials();
  const classes = useStyles();
  const { pricingGrid, isLoading } = useFetchPricingGrid();
  const PRICING_GRID_ROWS = Object.keys(Config.category).flatMap((category) => {
    return Object.keys(Config.plan).map((plan) => {
      const matureCountriesSubscription = getSubscriptionPrice(
        pricingGrid,
        category as PricingCategory,
        'SUBSCRIPTION_STANDARD_1_YEAR',
        2
      );

      const fastGrowingCountriesSubscription = getSubscriptionPrice(
        pricingGrid,
        category as PricingCategory,
        'SUBSCRIPTION_STANDARD_1_YEAR',
        1
      );

      return {
        category: t(Config.category[category as PricingCategory].label),
        plan: t(Config.plan[plan as SubscriptionPlan].label),
        matureOneYearPeriod: `${PriceUtils.getCurrencySign(
          matureCountriesSubscription?.currency || ''
        )} ${matureCountriesSubscription?.value as number}`,
        matureThreeYearsPeriod: `${PriceUtils.getCurrencySign(
          matureCountriesSubscription?.currency ?? ''
        )} ${Math.round(
          isNil(matureCountriesSubscription?.value)
            ? 0
            : (matureCountriesSubscription?.value as number) * 2.25
        )}`, // 25% off compared to full price for 3 years
        fastGrowingOneYearPeriod: `${PriceUtils.getCurrencySign(
          fastGrowingCountriesSubscription?.currency || ''
        )} ${fastGrowingCountriesSubscription?.value as number}`,
        fastGrowingThreeYearsPeriod: `${PriceUtils.getCurrencySign(
          fastGrowingCountriesSubscription?.currency ?? ''
        )} ${Math.round(
          isNil(fastGrowingCountriesSubscription?.value)
            ? 0
            : (fastGrowingCountriesSubscription?.value as number) * 2.25
        )}`, // 25% off compared to full price for 3 years
      };
    });
  });

  const PRICING_GRID_COLUMNS = [
    {
      label: t('Pricing Grid'),
      pathKey: 'category',
    },
    {
      label: t('Type'),
      pathKey: 'plan',
    },
    {
      label: t('1 year'),
      pathKey: 'matureOneYearPeriod',
    },
    {
      label: t('3 years'),
      pathKey: 'matureThreeYearsPeriod',
    },
    {
      label: t('1 years'),
      pathKey: 'fastGrowingOneYearPeriod',
    },
    {
      label: t('3 years'),
      pathKey: 'fastGrowingThreeYearsPeriod',
    },
  ];

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={true}
      onClose={onClose}
      maxWidth='md'
    >
      <TableContainer component={Paper}>
        <Table size='small' style={{ overflow: 'hidden' }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell className={classes.textCenter} colSpan={2}>
                {t('Mature Countries')}
              </TableCell>
              <TableCell className={classes.textCenter} colSpan={2}>
                {t('Fast Growing Countries')}
              </TableCell>
            </TableRow>
            <TableRow>
              {PRICING_GRID_COLUMNS.map((column, index) => {
                return (
                  <TableCell key={index} align='center'>
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {PRICING_GRID_ROWS.map((row, index) => (
              <TableRow key={index}>
                {PRICING_GRID_COLUMNS.map((column, index_) => {
                  return (
                    <TableCell key={index_} align='right'>
                      <FormGroup>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='left'
                        >
                          <Box p={1} display='flex'>
                            <div style={{ height: 30 }}>
                              {get(row, column.pathKey)}
                            </div>
                          </Box>
                        </Box>
                      </FormGroup>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BaseDialog.Actions>
        <Button
          id='close-pricing-grid'
          onClick={onClose}
          type='button'
          color='primary'
          disabled={isLoading}
        >
          {t('Close')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default PricingGrid;
