import { DEFAULT_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { FrontOfficeUserApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  IPaginationResponse,
  IFilterCondition,
  IFOUser,
} from '@inteliam/foundation/lib/types';

const useFetchFrontOfficeUsers = ({
  conditions,
}: {
  conditions: IFilterCondition[];
}): {
  users: IFOUser[];
  isLoading: boolean;
  isSettled: boolean;
} => {
  const { data, isLoading, isFetching, isSettled } = useQuery<
    IPaginationResponse<IFOUser>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.FO_USERS_LIST],
    () => {
      return FrontOfficeUserApi.paginate({
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: conditions,
          operator: 'and',
        },
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData: DatatableUtils.createInitialPaginationResponse<IFOUser>(),
    }
  );

  const users = Helpers.ensureValueAsArray(data?.data);

  return {
    users,
    isLoading: isLoading || isFetching,
    isSettled: isSettled,
  };
};

export default useFetchFrontOfficeUsers;
