import { IBORolesEnum } from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import { useQuestionEnums } from '@core/queries';

import { QuestionsUtils, UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import {
  Card,
  Controls,
  FormFieldLoadingIndicator,
  Grid,
  FormGroup,
  Divider,
} from '@shared/components';

const BasicInformation: React.FCC = () => {
  const { questionEnums } = useQuestionEnums();
  const { t, user } = useEssentials();
  const { defaultValues, readOnly, mode } = useQuestionFormContext();
  const methods = useFormContext<IQuestionForm>();
  const helpText = useWatch({
    control: methods.control,
    name: 'helpText',
  });

  return (
    <Card.CContainer styled>
      <Card.Header
        title={t('Basic')}
        subheader={
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span>{t("Fill in the question's basic information")}</span>
          </Grid>
        }
      />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Question code')}
                name='code'
                required
                readOnly={readOnly || mode === 'NEW_VERSION'}
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Version')}
                name='version'
                type='number'
                readOnly
              />
            </FormGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Status')}
                name='status'
                options={QuestionsUtils.getStatusesOptions(t)}
                disabled={
                  !UsersUtils.isGranted(
                    IBORolesEnum.ROLE_SENIOR_ANALYST,
                    user
                  ) || readOnly
                }
                required
              />
            </FormGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Question title')}
                name='title.defaultValue'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              {questionEnums ? (
                <Controls.FormSelect
                  label={t('Question type')}
                  name='kind'
                  options={questionEnums.types}
                  required
                  onChange={(event) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (confirm(t("You're about to lose everything :("))) {
                      methods.setValue('options', []);
                    } else {
                      event.preventDefault();
                    }
                  }}
                  disabled={readOnly}
                />
              ) : (
                <FormFieldLoadingIndicator />
              )}
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormCheckbox name='helpText' label={t('Help text')} />
              <Controls.FormRichTextEditor
                name='helpTextContent.defaultValue'
                defaultValue={I18n.getTranslatedValue(
                  defaultValues?.helpTextContent
                )}
                readOnly={!helpText || false}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t('Mandatory')}
                name='required'
                readOnly={readOnly}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                row
                required
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
      <Divider />
    </Card.CContainer>
  );
};

export default memo(BasicInformation);
