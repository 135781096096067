import {
  IARStatusEnum,
  IBORolesEnum,
  IDiscountTypesEnum,
  IDistributorInvoiceStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations, DateFormatter } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { useWatch } from 'react-hook-form';

import { BaseDialog } from '@core/components/modals';

import {
  useFetchAvailableSubscriptions,
  useFetchPricingCategories,
} from '@core/queries';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type {
  ISubscriptionLogDistributor,
  ISubscriptionStatusEntryFormData,
} from '@core/types';

import { Button, Controls, Grid, FormGroup } from '@shared/components';

import { makeStyles, ValidationUtils } from '@shared/utils';

import type { FormMethods } from '@shared/types';

import type {
  ISubscriptionType,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import Discount from './discount';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props {
  // TODO: context ?
  methods: FormMethods<ISubscriptionStatusEntryFormData>;
  onSubmit: (data: ISubscriptionStatusEntryFormData) => void;
  isLoading: boolean;
  errors?: ValidationErrorResponse;
  distributor: ISubscriptionLogDistributor;
  onClose: () => void;
}

const LogEntryForm: React.FCC<Props> = ({
  methods,
  isLoading,
  onSubmit,
  errors,
  distributor,
  onClose,
}) => {
  const { t, user } = useEssentials();
  const classes = useStyles();
  const { subscriptions } = useFetchAvailableSubscriptions();

  const { formattedPricingCategories } = useFetchPricingCategories();
  const inteliamDiscountEnabled = useWatch({
    name: 'inteliamDiscountEnabled',
    control: methods.control,
  });
  const manufacturerDiscountEnabled = useWatch({
    name: 'manufacturerDiscountEnabled',
    control: methods.control,
  });

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);
  const watchedInvoiceStatus = useWatch({
    control: methods.control,
    name: 'invoiceStatus',
  });
  const watchedEnterpriseDiscount = useWatch({
    control: methods.control,
    name: 'manufacturerDiscount',
  });
  const watchedInteliamDiscount = useWatch({
    control: methods.control,
    name: 'inteliamDiscount',
  });
  const manufacturerPaidSubscription =
    watchedEnterpriseDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
    watchedEnterpriseDiscount?.percentage === 100;

  const inteliamPaidSubscription =
    watchedInteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
    watchedInteliamDiscount?.percentage === 100;

  const inteliamAndEnterprisePaidSubscription =
    watchedInteliamDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
    watchedEnterpriseDiscount?.type === IDiscountTypesEnum.TYPE_PERCENTAGE &&
    watchedInteliamDiscount?.percentage +
      watchedInteliamDiscount?.percentage ===
      100;

  const fullyPaid =
    manufacturerPaidSubscription ||
    inteliamPaidSubscription ||
    inteliamAndEnterprisePaidSubscription;

  const isPricingCategoryEnabled =
    !manufacturerPaidSubscription &&
    [IDistributorInvoiceStatusEnum.PENDING_NOT_PAID].includes(
      watchedInvoiceStatus as IDistributorInvoiceStatusEnum
    );
  const isLastAssessmentRequestSubmitted =
    distributor.lastAssessmentRequest?.status &&
    ![
      IARStatusEnum.QUEUED,
      IARStatusEnum.QUALIFICATION,
      IARStatusEnum.UNICITY_AND_SCOPE,
      IARStatusEnum.READY_FOR_SURVEY,
      IARStatusEnum.FRESH_SURVEY,
      IARStatusEnum.ANSWERING_SURVEY,
    ].includes(distributor.lastAssessmentRequest.status);

  return (
    <Controls.Form
      methods={methods}
      submitHandler={(values) => onSubmit(values)}
      className={classes.form}
    >
      <Grid container spacing={3}>
        <Grid item md={12} xs={12} container spacing={3}>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                required
                fullWidth
                label={t('Invoice Status')}
                name={'invoiceStatus'}
                options={Object.values(IDistributorInvoiceStatusEnum).map(
                  (element) => {
                    return {
                      label: t(element),
                      value: element,
                    };
                  }
                )}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                id='comments'
                label={t('Comments')}
                name='comments'
                fullWidth
                required
                rows={12}
                multiline
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} container spacing={3}>
          <Grid item md={6} xs={12}>
            <Controls.FormCheckbox
              label={t('Enterprise Discount')}
              disabled={false}
              required={false}
              name='manufacturerDiscountEnabled'
              onChange={(_event, _checked) => {
                methods.setValue('manufacturerDiscounts.1_YEAR.enabled', false);
                methods.setValue('manufacturerDiscounts.3_YEAR.enabled', false);
              }}
            />
            <Discount
              name='manufacturerDiscounts'
              readOnly={!manufacturerDiscountEnabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controls.FormCheckbox
              label={t('Inteliam Discount')}
              name='inteliamDiscountEnabled'
              onChange={(_event, _checked) => {
                methods.setValue('inteliamDiscounts.1_YEAR.enabled', false);
                methods.setValue('inteliamDiscounts.3_YEAR.enabled', false);
              }}
            />
            <Discount
              name='inteliamDiscounts'
              readOnly={!inteliamDiscountEnabled}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} container>
          <Grid item md={6} xs={12} container spacing={3}>
            <Grid item md={12} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='pricingCategory.id'
                  label={t('Pricing grid')}
                  options={formattedPricingCategories}
                  required
                  readOnly={
                    ![
                      IARStatusEnum.FRESH_SURVEY,
                      IARStatusEnum.ANSWERING_SURVEY,
                    ].includes(
                      distributor.lastAssessmentRequest?.status as IARStatusEnum
                    ) && !isPricingCategoryEnabled
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  disabled={
                    !UsersUtils.isGranted(IBORolesEnum.ROLE_SUPER_ADMIN, user)
                  }
                  fullWidth
                  label={t('Subscription')}
                  name={'subscriptionType.id'}
                  options={subscriptions.map(
                    (subscription: ISubscriptionType) => {
                      return {
                        label: t(subscription.id),
                        value: subscription.id,
                      };
                    }
                  )}
                  readOnly={!isLastAssessmentRequestSubmitted || fullyPaid}
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormGroup>
                <Controls.FormDatePicker
                  label={t('Date')}
                  name={'date'}
                  pickerType='datetime'
                  textFieldProps={{
                    required: true,
                  }}
                  inputFormat={DateFormatter.FORMATS.fr_FR.LONG}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <BaseDialog.Actions>
        <Button
          id='close-subscription-log-form'
          type='button'
          color='primary'
          onClick={onClose}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='add-subscription-log'
          type='submit'
          disabled={isLoading}
          color='primary'
        >
          {t('Save')}
        </Button>
      </BaseDialog.Actions>{' '}
    </Controls.Form>
  );
};

export default LogEntryForm;
