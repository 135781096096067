import { yupResolver } from '@hookform/resolvers/yup';
import { DateFormatter, Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { useDashboard, useEssentials } from '@core/contexts';
import type { IAuthorizeSurveySubmissionPayload } from '@core/types';

import { Button, Controls } from '@shared/components';

import type { IAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import { authorizeSurveySubmissionSchema } from './schema';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}
const AuthorizeSurveySubmissionModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { t, confirm } = useEssentials();
  const { dashboard } = useDashboard();

  const methods = useForm<IAuthorizeSurveySubmissionPayload>({
    resolver: yupResolver(authorizeSurveySubmissionSchema),
    defaultValues:
      assessmentRequest?.questionnaireSubmissionAuthorization ?? {},
  });
  const hasBeenAuthorized = Boolean(
    assessmentRequest?.questionnaireSubmissionAuthorization?.authorized
  );
  const authorizationDate =
    assessmentRequest?.questionnaireSubmissionAuthorization?.authorizedAt;
  const { authorizeSurveySubmission } = useManageAssessmentRequest({
    authorizeSurveySubmission: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
  });

  const handleOnSubmit = (data: IAuthorizeSurveySubmissionPayload) => {
    confirm({
      description: t(
        'Are you sure you want to authorize questionnaire submission?'
      ),
    })
      .then(() => {
        authorizeSurveySubmission.mutate(
          {
            id: assessmentRequest.id,
            body: data,
          },
          {
            onSuccess: () => {
              onClose();
            },
          }
        );
      })
      .catch(() => {});
  };

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <Controls.Form submitHandler={handleOnSubmit} methods={methods}>
        <BaseDialog.Title id='Authorize submission' onClose={onClose}>
          {t('Authorize submission')}
        </BaseDialog.Title>

        <BaseDialog.Content dividers>
          <Controls.FormCheckbox
            name='authorized'
            label={
              hasBeenAuthorized
                ? t(
                    'Authorize questionnaire submission ({{userFullName}} on {{authorizationDate}})',
                    {
                      userFullName: Helpers.ensureValueAsString(
                        assessmentRequest?.questionnaireSubmissionAuthorization
                          ?.authorizedBy?.contactInfo.fullName
                      ),
                      authorizationDate: authorizationDate
                        ? DateFormatter.formatTimestamp(
                            new Date(authorizationDate),
                            DateFormatter.FORMATS.fr_FR.SHORT
                          )
                        : '',
                    }
                  )
                : t('Authorize questionnaire submission')
            }
            defaultChecked={false}
          />
          <Controls.FormInput
            style={{ marginTop: 20 }}
            name='comments'
            label={t('Comments')}
            rows={3}
            multiline
            fullWidth
          />
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-authorize-survey-submission-deadlines'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={authorizeSurveySubmission.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-authorize-survey-submission-deadlines'
            type='submit'
            color='primary'
            isLoading={authorizeSurveySubmission.isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};
export default memo(AuthorizeSurveySubmissionModal);
