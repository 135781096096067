import { yupResolver } from '@hookform/resolvers/yup';
import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { BaseDialog } from '@core/components/modals';
import { BaseUserForm } from '@core/components/users';

import { EnterpriseUtils, EnterpriseUsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseUserForm, IEnterpriseUserPayload } from '@core/types';

import { Button, Controls, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  EnterpriseKindType,
  IFOSupplierUser,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { userSchema as schema } from '../schema';

interface Props {
  onSubmit: (data: IEnterpriseUserPayload) => void;
  defaultValues?: IFOSupplierUser;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  onCancel: () => void;
  manufacturerId: string;
  enterpriseKind?: EnterpriseKindType;
}
const Form: React.FCC<Props> = ({
  onCancel,
  onSubmit,
  isLoading,
  defaultValues,
  errors,
  manufacturerId,
  enterpriseKind = ICompanyKindEnum.SUPPLIER,
}) => {
  const { t } = useEssentials();
  const methods = useForm<IEnterpriseUserForm>({
    defaultValues: defaultValues
      ? EnterpriseUsersUtils.normalize.userToFormData(defaultValues)
      : EnterpriseUsersUtils.normalize.initialFormData(
          manufacturerId,
          enterpriseKind
        ),

    resolver: yupResolver(schema),
  });
  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Controls.Form
      submitHandler={(values) => {
        onSubmit(EnterpriseUsersUtils.normalize.formDataToBasePayload(values));
      }}
      methods={methods}
    >
      <BaseDialog.Content dividers>
        <Grid container spacing={3}>
          <BaseUserForm
            defaultValues={defaultValues}
            allowedRoles={EnterpriseUtils.ROLES_LOOKUP[enterpriseKind]}
          />
        </Grid>
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-user-form'
          onClick={onCancel}
          type='button'
          color='primary'
          disabled={isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-user-form'
          type='submit'
          color='primary'
          isLoading={isLoading}
        >
          {t('Confirm')}
        </Button>
      </BaseDialog.Actions>
    </Controls.Form>
  );
};
export default memo(Form);
