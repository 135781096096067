import { ApiFactory } from '@inteliam/foundation/lib/api';
import { isIContactLog } from '@inteliam/foundation/lib/guards';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';

import type { ICreateContactLogRequest } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { ResourceReaderFunction } from '@shared/types';

import type {
  IAPIResponse,
  IContactLog,
  IIdentifiedObject,
  IResource,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type ContactLogActions = 'paginate' | 'create' | 'update';

const baseContactLogApi = ApiFactory.generate<
  IContactLog,
  JwtBOUser,
  ContactLogActions,
  ICreateContactLogRequest,
  ICreateContactLogRequest
>({
  baseRoute: '/contact-logs',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIContactLog,
});

const createResource = async (files: File[]): Promise<Array<IResource>> => {
  const formData = new FormData();
  files.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });
  const { data } = await baseContactLogApi.client.instance.post<
    IAPIResponse<Array<IResource>>
  >('/resources', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data.data || [];
};

export const getEventEnums = async (): Promise<IAPIResponse<Array<string>>> => {
  const { data } = await baseContactLogApi.client.instance.get<
    IAPIResponse<Array<string>>
  >('/enums/event');
  return data;
};

const remove = async (body: IIdentifiedObject): Promise<IAPIResponse> => {
  const { data } = await baseContactLogApi.client.instance.delete<IAPIResponse>(
    `/${body.id}`
  );
  return data;
};

const getResourceDownloadablePath: ResourceReaderFunction = (
  id: string,
  { absolute = true } = {}
) =>
  `${
    absolute ? Constants.BASE_URL : ''
  }/contact-logs/resources/${id}?access_token=${
    AuthHelpers.getAccessToken() as string
  }`;

export default {
  ...baseContactLogApi.actions,
  createResource,
  getEventEnums,
  remove,
  getResourceDownloadablePath,
};
