import React, { memo } from 'react';

import type {
  IAnyQuestion,
  IQuestionPartialScore,
  IScoreCard,
} from '@inteliam/foundation/lib/types';

import Form from './form';

interface Props {
  questionId: IAnyQuestion['id'];
  open: boolean;
  onClose: () => void;
  fieldName: string;
  scoreCard: IScoreCard;
  questionPartialScore: IQuestionPartialScore;
  readOnly: boolean;
}
const CommentModal: React.FCC<Props> = ({
  fieldName,
  questionPartialScore,
  open,
  onClose,
  scoreCard,
  readOnly,
}) => {
  return (
    <Form
      open={open}
      onClose={onClose}
      fieldName={fieldName}
      questionPartialScore={questionPartialScore}
      isLoading={false}
      scoreCard={scoreCard}
      readOnly={readOnly}
    />
  );
};

export default memo(CommentModal);
