import type { InteliamUseQueryResult } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { CountriesApi } from '@core/api';

import type {
  IAPIResponse,
  IOptionItem,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchCountries = (): InteliamUseQueryResult<
  IAPIResponse<Array<IOptionItem>>,
  BaseAxiosErrorResponse
> & {
  countries: Array<IOptionItem>;
} => {
  const query = useQuery<
    IAPIResponse<Array<IOptionItem>>,
    BaseAxiosErrorResponse
  >([...QUERY_KEYS.COUNTRIES], () => CountriesApi.getAll(), {
    //we don't need to refetch this at every mount or focus or reconnect
    //and we set cacheTime to infinity for the moment because it's not something that will change
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const countries = Helpers.ensureValueAsArray(query.data?.data);
  return {
    ...query,
    countries,
  };
};

export default useFetchCountries;
