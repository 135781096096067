import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  PhoneNumberInput,
  ErrorHelpText,
  FormControl,
} from '@shared/components';

import type { FormPhoneNumberFieldProps } from '@shared/types';

const FormPhoneNumberField: React.FCC<
  FormPhoneNumberFieldProps & { helperText?: string }
> = (props) => {
  const { t } = useEssentials();
  const {
    id,
    name,
    label,
    helperText,
    defaultValue,
    required,
    fullWidth,
    disabled,
    ...rest
  } = props;
  const {
    control,

    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormControl
      variant='outlined'
      error={!!errorMessage}
      {...{ required, fullWidth }}
      disabled={disabled || rest.readOnly}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <PhoneNumberInput
            {...rest}
            {...{
              name,
              id,
              fullWidth,
              disabled,
              label: required ? label + ' *' : label,
            }}
            value={field.value}
            onChange={(_, __, ___, formattedValue) => {
              field.onChange(formattedValue);
            }}
          />
        )}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

export default memo(FormPhoneNumberField);
