import { Helpers, Transformers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { CurrenciesApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IOptionItem,
  ICurrency,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

export type FRONT_OFFICE_SCOPE = 'FrontOffice';
export type BACK_OFFICE_SCOPE = 'BackOffice';
export type SURVEY_SCOPE = 'Survey';
export type REVIEWS_SCOPE = 'Reviews';

const useAvailableCurrencies = (): {
  currencies: ICurrency[];
  formattedCurrencies: IOptionItem[];
  isLoading: boolean;
} => {
  const query = useQuery<
    IAPIResponse<ICurrency[] | undefined>,
    BaseAxiosErrorResponse
  >([...QUERY_KEYS.CURRENCIES], () => CurrenciesApi.getAvailable(), {
    //we don't need to refetch this at every mount or focus or reconnect
    //and we set cacheTime to infinity for the moment because it's not something that will change
    cacheTime: Number.POSITIVE_INFINITY,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const currencies = Helpers.ensureValueAsArray(query.data?.data);
  return {
    currencies,
    formattedCurrencies: Transformers.mapIntoOptionItem(
      currencies,
      'currencyName',
      'currencyCode'
    ),
    isLoading: query.isLoading || query.isFetching,
  };
};

export default useAvailableCurrencies;
