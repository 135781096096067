import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useLanguagesByScope } from '@core/queries';

import { LanguageUtils } from '@core/utils';

import { TranslationStatusEnum } from '@core/enums';

import { IconButton, Grid, AddCircleIcon } from '@shared/components';

import type {
  ITranslatableField,
  ITranslatableList,
} from '@inteliam/foundation/lib/types';

import TranslationItem from './translation-item';

interface Props {
  readOnly?: boolean;
  defaultValue?: ITranslatableField;
  name: string;
}

interface ITranslationForm {
  [key: string]: ITranslatableField;
}

const TranslationFieldInput: React.FCC<Props> = ({
  readOnly,
  defaultValue,
  name,
}) => {
  const methods = useFormContext<ITranslationForm>();
  const numberOfOldTranslations = defaultValue?.usedLanguages?.length || 0;
  const defaultTranslations =
    defaultValue?.translations ?? ([] as ITranslatableList);
  const { formattedExceptEnglish } = useLanguagesByScope({
    scope: 'FrontOfficeAdministration',
  });
  const { fields, append, remove } = useFieldArray<
    ITranslationForm,
    `${string}.translations`
  >({
    name: `${name}.translations`,
    control: methods.control,
  });
  const watchedTranslations =
    useWatch({
      control: methods.control,
      name: `${name}.translations`,
    }) || defaultTranslations;

  const unused = LanguageUtils.calculateUnusedLanguages(
    watchedTranslations,
    formattedExceptEnglish,
    fields.length
  );

  const insert = () => {
    append({
      locale: unused[0]?.value.toString() as ILanguagesEnum,
      status: TranslationStatusEnum.VALIDATED,
      content: '',
    });
  };
  const handlers = { remove };

  return (
    <div>
      {fields.map((translation, index) => {
        return (
          <TranslationItem
            key={translation.locale}
            handlers={handlers}
            name={name}
            {...{
              translation,
              index,
              numberOfOldTranslations,
              readOnly,
              translations: fields,
            }}
          />
        );
      })}
      <Grid item md={1}>
        <IconButton
          id='add-translation'
          disabled={unused.length === 0 || readOnly}
          color='primary'
          onClick={() => {
            insert();
          }}
          size='large'
        >
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </div>
  );
};

export default memo(TranslationFieldInput);
