/* eslint-disable @inteliam/i18n/raw-text-detected */
import { TypeCaster } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';
import { matchSorter } from 'match-sorter';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { makeStyles } from '@shared/utils';

import type { FormCountrySelectProps } from '@shared/types';

import type { ICountryType } from '@inteliam/foundation/lib/types';

import { Typeahead } from '../../typeahead';
import COUNTRIES from './alpha-3.json';

const filterOptions = (
  options: ICountryType[],
  { inputValue }: { inputValue: string }
) => matchSorter(options, inputValue, { keys: ['name', 'code', 'alpha-2'] });

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return String.fromCodePoint === undefined
    ? isoCode
    : isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint((char.codePointAt(0) as number) + 127_397)
        );
}

const formatCountryValue = (
  value: FormCountrySelectProps['defaultValue'],
  multiple?: boolean
) => {
  if (multiple || Array.isArray(value)) {
    return value || [];
  }
  if (!value) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  // eslint-disable-next-line unicorn/no-null
  return value.code ? value : null;
};

const useStyles = makeStyles({
  option: {
    'fontSize': 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CountrySelect: React.FC<FormCountrySelectProps> = ({
  label,
  name,
  helperText,
  defaultValue,
  fullWidth,
  multiple,
  required,
  disabled,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();
  const error =
    get(errors, `${name}.message`) || get(errors, `${name}.code.message`);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={formatCountryValue(defaultValue, multiple)}
      render={({ field }) => {
        const value = formatCountryValue(
          field.value as FormCountrySelectProps['defaultValue'],
          multiple
        );

        return (
          <Typeahead<ICountryType>
            {...{
              required,
              disabled,
              fullWidth,
              label,
              name,
              filterOptions,
              multiple,
            }}
            value={value}
            onChange={(_, newValue) => {
              field.onChange(newValue);
            }}
            options={COUNTRIES}
            error={!!error}
            helperText={t((error || helperText) as unknown as string)}
            classes={{
              option: classes.option,
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <span>
                  {countryToFlag(TypeCaster.toString(option['alpha-2']))}
                </span>
                {option.name} ({option.code})
              </li>
            )}
            autoHighlight
            getOptionLabel={(value) => value.name ?? ''}
            isOptionEqualToValue={(option, _value) => {
              if (multiple && Array.isArray(_value)) {
                return _value
                  .map((country) => country.code)
                  .includes(option.value);
              }
              return option.value === _value.code;
            }}
          />
        );
      }}
    />
  );
};

export default CountrySelect;
