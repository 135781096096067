import { isIOptionItem } from '@inteliam/foundation/lib/guards';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

const normalizer = (value: unknown): string | IOptionItem => {
  if (isIOptionItem(value)) {
    return value;
  }
  throw new Error('FO User is not what you expect');
};

export default normalizer;
