import {
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  useQueryClient,
} from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { ScoreCardsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';

import { ErrorCodesLookup } from '@shared/utils';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  Id,
  IPatchObject,
  IQueryManagementResult,
  IScoreCard,
  PatchBody,
} from '@inteliam/foundation/lib/types';

type ManageSettings = (parameters?: {
  patch?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  score?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      Id
    >;
  };
}) => IQueryManagementResult<IScoreCard, IScoreCard, IScoreCard, 'patch'> & {
  score: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    Id
  >;
};

const useManageScoreCard: ManageSettings = (args) => {
  const { t, notify } = useEssentials();
  const queryClient = useQueryClient();
  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(ScoreCardsApi.patch, {
    onSuccess(data) {
      const responseApi = data as IAPIResponse<IScoreCard>;
      queryClient.setQueryData(
        [...QUERY_KEYS.SCORECARD, responseApi.data.assessmentRequest.id],
        data
      );
    },

    ...args?.patch?.options,
  });

  const scoreMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    Id
  >(ScoreCardsApi.updateScore, {
    onSuccess(data) {
      const responseApi = data as IAPIResponse<IScoreCard>;
      queryClient.setQueryData(
        [...QUERY_KEYS.SCORECARD, responseApi.data.assessmentRequest.id],
        data
      );
    },
    ...args?.score?.options,
    onError: () => {
      notify({
        message: t(ErrorCodesLookup.E15000.title),
        type: INotifierEnum.Error,
      });
    },
  });

  const runPatch = useCallback(
    (body: PatchBody, id: IScoreCard['id']) => {
      patchMutation.mutate({
        id,
        body,
      });
    },
    [patchMutation]
  );

  return {
    patch: {
      run: runPatch,
      mutation: patchMutation,
    },
    score: scoreMutation,
  };
};

export default useManageScoreCard;
