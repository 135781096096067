import { ApiFactory } from '@inteliam/foundation/lib/api';
import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { isISharingRequest } from '@core/guards';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IApexChartDataSet } from '@shared/types';

import type {
  IAPIResponse,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
  ISharingRequest,
  ISRTransitionInput,
} from '@inteliam/foundation/lib/types';

type SharingRequestActions = 'patch';

const baseSharingRequestApi = ApiFactory.generate<
  ISharingRequest,
  JwtBOUser,
  SharingRequestActions
>({
  baseRoute: '/sharing-request',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isISharingRequest,
});

const paginateBySupplier = async (
  manufacturerId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  query: IPaginationQuery
): Promise<IPaginationResponse<ISharingRequest>> => {
  const client = new PrivateApi(
    {
      baseRoute: '/sharing-requests',
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: query,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );

  const { data } = await client.instance.get(`/enterprise/${manufacturerId}`);
  // assertIsTypedArray(data.data, guard);
  return data;
};

const client = new PrivateApi(
  {
    baseRoute: '/sharing-requests',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getChartByEnterprise = async (
  manufacturerId: string
): Promise<IAPIResponse<IApexChartDataSet>> => {
  const { data } = await client.instance.get(
    `/enterprise/${manufacturerId}/chart`
  );

  return data;
};

const transition = (
  { target, body, id }: ISRTransitionInput,
  check = false
): Promise<IAPIResponse> => {
  return client.instance
    .patch(`/go-to/${id}/${target}?checkOnly=${check.toString()}`, body)
    .then(({ data }) => data);
};

export default {
  ...baseSharingRequestApi.actions,
  paginateBySupplier,
  getChartByEnterprise,
  transition,
};
