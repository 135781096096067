import { IARPhaseEnum } from '@inteliam/foundation/lib/enums';
import type { SelectChangeEvent } from '@mui/material';
import { findLast, isNil } from 'lodash-es';

import React, { useState } from 'react';

import {
  QUERY_KEYS,
  useFetchAssignableUsers,
  useManageAssessmentRequest,
} from '@core/queries';

import { useDashboard, useEssentials } from '@core/contexts';
import type { IAssignUserInput } from '@core/types';

import {
  FormControl,
  Grid,
  IconButton,
  Noop,
  OpenInNewIcon,
  RouterLink,
  Select,
} from '@shared/components';

import { BOErrorTracking } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
  phase: IARPhaseEnum;
  withAssignation?: boolean;
}

// Everytime you add a new rediretable status
// dont forget to add it to the guard isRedirectableStatus
type RedirectableStatus =
  | IARPhaseEnum.QUALIFICATION
  | IARPhaseEnum.UNICITY_AND_SCOPE
  | IARPhaseEnum.IN_DOC_ANALYSIS
  | IARPhaseEnum.IN_SCORING
  | IARPhaseEnum.RTA
  | IARPhaseEnum.SURVEY_AND_REVIEW
  | IARPhaseEnum.PUBLISHED;

type IRedirecter = {
  [key in RedirectableStatus]: (id: IAR) => string;
};

function isRedirectableStatus(
  argument: IARPhaseEnum
): argument is RedirectableStatus {
  return [
    IARPhaseEnum.QUALIFICATION,
    IARPhaseEnum.UNICITY_AND_SCOPE,
    IARPhaseEnum.IN_DOC_ANALYSIS,
    IARPhaseEnum.IN_SCORING,
    IARPhaseEnum.RTA,
    IARPhaseEnum.SURVEY_AND_REVIEW,
    IARPhaseEnum.PUBLISHED,
  ].includes(argument);
}

const Redirecter: IRedirecter = {
  [IARPhaseEnum.QUALIFICATION]: (assessmentRequest) =>
    `/assessment-requests/create?assessmentRequestId=${assessmentRequest.id}`,
  [IARPhaseEnum.UNICITY_AND_SCOPE]: (assessmentRequest) =>
    `/assessment-requests/${assessmentRequest.scoredCompany.id}/match-and-merge?assessmentRequestId=${assessmentRequest.id}`,
  [IARPhaseEnum.IN_DOC_ANALYSIS]: (assessmentRequest) =>
    `/assessment-requests/${assessmentRequest.id}/doc-analysis`,
  [IARPhaseEnum.IN_SCORING]: (assessmentRequest) =>
    assessmentRequest.scoringVersion === 'V2'
      ? `/assessment-requests/${assessmentRequest.id}/scoring-v2`
      : `/assessment-requests/${assessmentRequest.id}/scoring`,
  [IARPhaseEnum.RTA]: (assessmentRequest) =>
    assessmentRequest.scoringVersion === 'V2'
      ? `/assessment-requests/${assessmentRequest.id}/validation-v2`
      : `/assessment-requests/${assessmentRequest.id}/validation`,
  [IARPhaseEnum.SURVEY_AND_REVIEW]: (assessmentRequest) =>
    `/assessment-requests/${assessmentRequest.id}/review`,
  [IARPhaseEnum.PUBLISHED]: (assessmentRequest) =>
    assessmentRequest.scoringVersion === 'V2'
      ? `/assessment-requests/${assessmentRequest.id}/published-v2`
      : `/assessment-requests/${assessmentRequest.id}/published`,
};

const AssignmentCell: React.FCC<Props> = ({
  assessmentRequest,
  phase,
  withAssignation = true,
}) => {
  const { user } = useEssentials();
  const { checkReadOnly } = useDashboard();
  const { formatted: users } = useFetchAssignableUsers({ phase });

  // Get last status in phase
  // if last status not passed then we are in the phase
  const phaseItem = findLast(
    assessmentRequest.statuses,
    (p) => p.phaseCodename === phase
  );

  const [currentValue, setCurrentValue] = useState(
    phaseItem?.assignees?.map((assignee) => assignee.id) ?? []
  );

  const { assignUser } = useManageAssessmentRequest({
    assignUser: {
      options: {
        keysToInvalidateOnSuccess: [
          QUERY_KEYS.DASHBOARD.ANALYST,
          QUERY_KEYS.DASHBOARD.ENGAGEMENT,
        ],
      },
    },
  });

  if (isNil(phaseItem)) {
    BOErrorTracking.report(
      new Error(
        `Phase not found with phase Name : [${phase}] in statuses [${JSON.stringify(
          assessmentRequest.statuses
        )}]`
      )
    );

    return <Noop />;
  }

  const handleChange = (event: SelectChangeEvent<Array<string>>) => {
    const userIds = event.target.value as Array<string>;
    const id = assessmentRequest.id;

    assignUser.mutate({
      id,
      statusRank: phaseItem?.rank,
      userIds: userIds.length === 0 ? undefined : userIds,
    } as IAssignUserInput);

    setCurrentValue(userIds);
  };

  const readOnly = checkReadOnly(assessmentRequest, user);

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      {withAssignation && (
        <FormControl style={{ width: '75%' }}>
          <Select
            id={`assignment-select-${assessmentRequest.businessId}`}
            name=''
            value={currentValue}
            fullWidth
            multiple
            onChange={
              assessmentRequest.isFinalStatus || phaseItem.passed || readOnly
                ? undefined
                : (event) =>
                    handleChange(event as SelectChangeEvent<Array<string>>)
            }
            disabled={
              assessmentRequest.isFinalStatus || phaseItem.passed || readOnly
            }
            inputProps={{ style: { height: '100%' } }}
            options={users}
          ></Select>
        </FormControl>
      )}
      {phase === assessmentRequest.currentPhase &&
      !readOnly &&
      isRedirectableStatus(phase) ? (
        <RouterLink
          to={Redirecter[phase](assessmentRequest)}
          id={`${assessmentRequest.businessId}-${phase}`}
        >
          <IconButton
            id={`open-phase-page-${assessmentRequest.businessId}-${assessmentRequest.status}`}
            edge='start'
            color='primary'
            size='large'
          >
            <OpenInNewIcon />
          </IconButton>
        </RouterLink>
      ) : (
        <IconButton
          id={`open-phase-page-${assessmentRequest.businessId}`}
          edge='start'
          color='primary'
          disabled
          size='large'
        >
          <OpenInNewIcon />
        </IconButton>
      )}
    </Grid>
  );
};

export default AssignmentCell;
