import type { AnswerValueFormatEnum } from '@inteliam/foundation/lib/enums';
import { isNil } from 'lodash-es';

import * as React from 'react';

import { TooltippedCell, Typography } from '@shared/components';

import type { AnswerValueProps } from '@inteliam/foundation/lib/types';

type Props = {
  value: AnswerValueProps[AnswerValueFormatEnum.FORMAT_MONETARY] | undefined;
};

const AnswerValueMonetary: React.FCC<Props> = ({ value }) => {
  return (
    <TooltippedCell
      title={
        value?.amount !== undefined && value?.currency !== undefined
          ? `${value?.amount} ${value?.currency}`
          : ''
      }
      disabled={isNil(value)}
    >
      <Typography>
        {value?.amount !== undefined && value?.currency !== undefined
          ? `${value?.amount} ${value?.currency}`
          : ''}
      </Typography>
    </TooltippedCell>
  );
};

export default AnswerValueMonetary;
