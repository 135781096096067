import * as React from 'react';

import ConfirmationDialog from './confirm-dialog';

const DEFAULT_OPTIONS = {
  title: 'Are you sure?',
  description: '',
  content: '',
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
};

export type ConfirmOptions = typeof DEFAULT_OPTIONS & {
  customButtons?: (args: {
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactNode;
};
export type IConfirmerDispatcher = (
  options: Partial<ConfirmOptions>
) => Promise<void>;
const ConfirmContext = React.createContext<IConfirmerDispatcher>(() =>
  Promise.resolve()
);

const buildOptions = (
  defaultOptions: ConfirmOptions,
  options: Partial<ConfirmOptions>
) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...options.dialogProps,
  };
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...options.confirmationButtonProps,
  };
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...options.cancellationButtonProps,
  };
  const titleProps = {
    ...(defaultOptions.titleProps || DEFAULT_OPTIONS.titleProps),
    ...options.titleProps,
  };
  const contentProps = {
    ...(defaultOptions.contentProps || DEFAULT_OPTIONS.contentProps),
    ...options.contentProps,
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
  };
};

type Props = {
  defaultOptions?: ConfirmOptions;
};
const ConfirmProvider: React.FCC<Props> = ({
  children,
  defaultOptions = DEFAULT_OPTIONS,
}) => {
  const [{ customButtons, ...restOptions }, setOptions] =
    React.useState<ConfirmOptions>({
      ...DEFAULT_OPTIONS,
      ...defaultOptions,
    });

  const [resolveReject, setResolveReject] = React.useState<
    [
      resolve?: (value?: void | Promise<void>) => void,
      reject?: (reason?: unknown) => void
    ]
  >([]);
  const [resolve, reject] = resolveReject;

  const confirm: IConfirmerDispatcher = React.useCallback((options = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(buildOptions(defaultOptions, options));
      setResolveReject([resolve, reject]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = React.useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = React.useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = React.useCallback(() => {
    if (resolve) {
      resolve();
      handleClose();
    }
  }, [resolve, handleClose]);

  return (
    <React.Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={restOptions}
        onClose={handleClose}
        customButtons={customButtons}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </React.Fragment>
  );
};

const useConfirm = (): IConfirmerDispatcher => {
  const confirm = React.useContext(ConfirmContext);
  if (confirm === undefined) {
    throw new Error('useConfirm must be used within a ConfirmProvider');
  }
  return confirm;
};

export { ConfirmProvider, ConfirmContext, useConfirm };
