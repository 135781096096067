import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseForm } from '@core/types';

import { Card, Controls, Grid, FormGroup, Divider } from '@shared/components';

import { makeStyles } from '@shared/utils';

const useStyles = makeStyles(() => ({
  previewChip: {
    minWidth: 160,
  },
}));

const APPLICATION_PDF = 'application/pdf';
const ACCEPTED_FILES = [APPLICATION_PDF];

const DomainNames: React.FCC = () => {
  const { t } = useEssentials();
  const classes = useStyles();
  const methods = useFormContext<IEnterpriseForm>();
  const watchedEnterpriseAccess = useWatch({
    control: methods.control,
    name: 'configuration.enterpriseAccess',
    defaultValue: methods.getValues('configuration.enterpriseAccess'),
  });

  return (
    <Card.CContainer
      styled
      style={{
        pointerEvents: watchedEnterpriseAccess ? 'auto' : 'none',
        opacity: watchedEnterpriseAccess ? 1 : 0.5,
      }}
    >
      <Card.Header title={t('Authorized Domain Names')} />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormGroup>
              <Controls.FormChipInput
                label={t('Domain Names')}
                name='domainNames'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Controls.FormDropzoneArea
                label={t('Upload enterprise authorization (PDF version)')}
                name='authorizationFiles'
                filesLimit={10}
                acceptedFiles={ACCEPTED_FILES}
                previewGridProps={{
                  container: { spacing: 1, direction: 'row' },
                }}
                previewChipProps={{ classes: { root: classes.previewChip } }}
                showFileNamesInPreview
                previewText={t('Uploaded files')}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(DomainNames);
