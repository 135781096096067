import {
  ILanguagesEnum,
  ISupplierRolesEnum,
  ITitlesEnum,
} from '@inteliam/foundation/lib/enums';

import { EnterpriseUtils } from '@core/utils';

import { SUPPLIER_ROLES } from '@core/enums';

import type {
  IEnterpriseUserForm,
  IEnterpriseUserPayload,
  IUpdateSupplierUserPayload,
} from '@core/types';

import type {
  EnterpriseKindType,
  IFOSupplierUser,
  IFOTradingGroupUser,
} from '@inteliam/foundation/lib/types';

export const MANUFACTURER_ROLES_VALUES = new Set(
  Object.values(SUPPLIER_ROLES).map((role) => role.value)
);

export const normalize = {
  userToFormData: (
    user: IFOSupplierUser | IFOTradingGroupUser
  ): IEnterpriseUserForm => {
    return {
      company: { id: user.company?.id as string },
      contactInfo: user.contactInfo,
      roles: user.roles,
      kind: user.kind,
    };
  },
  formDataToBasePayload: (
    formData: IEnterpriseUserForm
  ): IEnterpriseUserPayload => {
    return {
      user: formData,
      kind: formData.kind,
    };
  },
  basePayloadToUpdatePayload: (
    basePayload: IEnterpriseUserPayload,
    userId: string
  ): IUpdateSupplierUserPayload => {
    return {
      ...basePayload,
      user: {
        ...basePayload.user,
        id: userId,
      },
    };
  },
  initialFormData: (
    manufacturerId: string,
    enterpriseKind: EnterpriseKindType
  ): IEnterpriseUserForm => {
    return {
      contactInfo: {
        title: ITitlesEnum.MR,
        phoneNumber: '',
        email: '',
        language: ILanguagesEnum.EN_GB,
      },
      roles: [ISupplierRolesEnum.ROLE_RISK_MANAGER],
      company: { id: manufacturerId },
      kind: EnterpriseUtils.ENTERPRISE_KIND_2_USER_KIND_LOOKUP[enterpriseKind],
    };
  },
};
