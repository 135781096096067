import { IARPhaseEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  DateFormatter,
  Validations,
  enhancedYupResolver,
} from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { useFetchAssignableUsers } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IARNextActionForm } from '@core/types';

import { Button, Controls, FormGroup, Grid } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import { updateNextActionSchema } from './schemas';

interface Props {
  defaultValues: IARNextActionForm;
  open: boolean;
  isLoading: boolean;
  errors?: ValidationErrorResponse;
  onClose: () => void;
  onSubmit: (data: IARNextActionForm) => void;
}

const INITIAL_DEFAULT_VALUES: Partial<IARNextActionForm> = {
  description: '',
  assignee: {
    id: '',
  },
  date: new Date(),
};

const NextActionForm: React.FCC<Props> = ({
  defaultValues,
  onClose,
  open,
  onSubmit,
  errors,
  isLoading,
}) => {
  const { t, user } = useEssentials();
  const methods = useForm<IARNextActionForm>({
    defaultValues: defaultValues ?? {
      ...INITIAL_DEFAULT_VALUES,
      assignee: { id: user.id },
    },
    resolver: enhancedYupResolver(
      updateNextActionSchema,
      undefined,
      undefined,
      true
    ),
    shouldUnregister: true,
  });

  const { users } = useFetchAssignableUsers({
    phase: IARPhaseEnum.QUALIFICATION,
  });

  const {
    formState: { errors: formErrors },
  } = methods;

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: (name, error) =>
          methods.setError(name as keyof IARNextActionForm, error),
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, methods.setError]);

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='md'
    >
      <Controls.Form methods={methods} submitHandler={onSubmit}>
        <BaseDialog.Title id='Update Next Action' onClose={onClose}>
          {t('Update Next Action')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormDatePicker
                  inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
                  name={'date'}
                  label={t('Date')}
                  pickerType='date'
                  disablePast
                  componentsProps={{
                    actionBar: {
                      actions: ['today'],
                    },
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  errorText={t(get(formErrors, 'date')?.message || '')}
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  multiple={false}
                  fullWidth
                  name='assignee.id'
                  label={t('Assign to')}
                  defaultValue={user.id}
                  options={UsersUtils.getFormattedEngagementSpecialistsForPhase(
                    users
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormInput
                  name={`description`}
                  label={t('Description: Describe what needs to be done')}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-update-next-action'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-update-next-action'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(NextActionForm);
