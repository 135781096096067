import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isISetting } from '@core/guards';
import type { ISetting } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type SettingsActions = 'patch' | 'paginate';

const baseApi = ApiFactory.generate<ISetting, JwtBOUser, SettingsActions>({
  baseRoute: '/settings',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isISetting,
});

export default {
  ...baseApi.actions,
};
