import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useCallback, useState } from 'react';

import {
  ReviewReferenceEvaluationTable,
  ReviewsDocumentStatusSwitch,
  DocumentQualificationForm,
} from '@core/components/assessment-requests';

import { QUERY_KEYS } from '@core/queries';
import useFetchDocumentEvaluationById from '@core/queries/use-fetch-document-evaluation-by-id';

import { useEssentials } from '@core/contexts';
import type { IDocumentEvaluation } from '@core/types';

import { Button, DocViewer, Grid, CircularProgress } from '@shared/components';

import { Constants } from '@shared/utils';

import { BaseDialog } from '../';

interface Props {
  onClose: () => void;
  documentEvaluation: IDocumentEvaluation;
  assessmentRequestId: string;
}
const DocumentQualificationModal: React.FCC<Props> = ({
  onClose,
  documentEvaluation,
  assessmentRequestId,
}) => {
  const { t } = useEssentials();
  const queryClient = useQueryClient();

  const [documentEvaluationId, setDocumentEvaluationId] = useState(
    documentEvaluation.id
  );
  const { data, isLoading } = useFetchDocumentEvaluationById({
    id: documentEvaluationId,
  });

  const onNextHandler = useCallback(() => {
    if (data?.meta.next) {
      setDocumentEvaluationId(data.meta.next);
    }
  }, [data, setDocumentEvaluationId]);

  const onPreviousHandler = useCallback(() => {
    if (data?.meta.previous) {
      setDocumentEvaluationId(data.meta.previous);
    }
  }, [data, setDocumentEvaluationId]);

  const onStatusSwitchSuccess = useCallback(() => {
    queryClient
      .invalidateQueries([
        ...QUERY_KEYS.DOCUMENT_EVALUATIONS,
        documentEvaluationId,
      ])
      .catch(() => {});
  }, [queryClient, documentEvaluationId]);

  return (
    <BaseDialog.Dialog open={true} onClose={onClose} maxWidth='lg' fullScreen>
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Doc Qualification')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        {isLoading && (
          <Grid
            container
            style={{ height: 500, width: '100%' }}
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress color='inherit' />
          </Grid>
        )}
        {data && (
          <Grid container spacing={3}>
            <Grid item md={12}>
              <div data-cy='modal' style={{ float: 'right' }}>
                <ReviewsDocumentStatusSwitch
                  documentEvaluation={data.data}
                  onSuccess={onStatusSwitchSuccess}
                />
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{
                height: '75vh',
                overflowY: 'auto',
              }}
            >
              {data.data.document?.file?.mimeType && (
                <DocViewer
                  url={`${Constants.BASE_URL}/documents/${
                    data.data.document.id
                  }/download?access_token=${
                    AuthHelpers.getAccessToken() as string
                  }`}
                  name={data.data.document.name}
                  type={data.data.document?.file?.mimeType}
                />
              )}
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              style={{
                height: '75vh',
                overflowY: 'auto',
              }}
            >
              <div>
                <DocumentQualificationForm
                  defaultValues={data.data}
                  id={data.data.document.id}
                  onUpdateSuccess={() => {
                    queryClient
                      .invalidateQueries(QUERY_KEYS.DOCUMENT_EVALUATIONS)
                      .catch(() => {});
                  }}
                />
              </div>
              <div style={{ paddingTop: '10px' }}>
                <ReviewReferenceEvaluationTable
                  {...{ assessmentRequestId }}
                  documentEvaluation={data.data}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Button
            id='document-qualification-previous'
            type='button'
            color='primary'
            onClick={onPreviousHandler}
            isLoading={isLoading}
            disabled={isLoading || isNil(data) || isNil(data?.meta.previous)}
          >
            {'<'} {t('Previous Document')}
          </Button>
          <Button
            id='document-qualification-next'
            type='button'
            color='primary'
            onClick={onNextHandler}
            isLoading={isLoading}
            disabled={isLoading || isNil(data) || isNil(data?.meta.next)}
          >
            {t('Next Document')} {'>'}
          </Button>
        </Grid>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(DocumentQualificationModal);
