/* eslint-disable sonarjs/no-identical-functions */
import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { UsersApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
  AxiosValidationErrorResponse,
  IPatchObject,
  IBOUser,
  IForgetPasswordPayload,
  IBaseUser,
} from '@inteliam/foundation/lib/types';

type ManageUsersParameters<T> = {
  getOneById?: {
    args: {
      id?: string;
    };
    options?: InteliamUseQueryOptions<IAPIResponse<T>, BaseAxiosErrorResponse>;
  };
  list?: {
    options?: InteliamUseQueryOptions<
      IAPIResponse<Array<IBOUser>>,
      BaseAxiosErrorResponse
    >;
  };
  update?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<T>,
      AxiosValidationErrorResponse,
      IPatchObject<T>
    >;
  };
  patch?: {
    args: IPatchObject;
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  forgotPassword?: {
    args: {
      email: string;
    };
    options?: InteliamUseMutationOptions<
      void,
      AxiosValidationErrorResponse,
      IForgetPasswordPayload
    >;
  };
};
type ManageUsersResponse<T> = {
  getOneById: InteliamUseQueryResult<IAPIResponse<T>, BaseAxiosErrorResponse>;
  list: InteliamUseQueryResult<
    IAPIResponse<Array<IBOUser>>,
    BaseAxiosErrorResponse
  >;
  update: InteliamUseMutationResult<
    IAPIResponse<IBOUser>,
    AxiosValidationErrorResponse,
    IPatchObject<IBOUser>
  >;
  patch: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  forgotPassword: InteliamUseMutationResult<
    void,
    AxiosValidationErrorResponse,
    IForgetPasswordPayload
  >;
};

const useManageUsers = <T extends IBaseUser>(
  args?: ManageUsersParameters<T>
): ManageUsersResponse<T> => {
  const { navigate, notify, t, INotifierEnum } = useEssentials();

  const getOneByIdQuery = useQuery<IAPIResponse<T>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.USERS_LIST, args?.getOneById?.args.id],
    () =>
      args?.getOneById?.args.id
        ? UsersApi.getOneById(args?.getOneById?.args.id)
        : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      enabled: false, // VERY IMPORTANT: we don't want to trigger the query on init unless the caller explicitly asks for it
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: 0,
      ...args?.getOneById?.options,
    }
  );

  const listQuery = useQuery<
    IAPIResponse<Array<IBOUser>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.BO_USERS_LIST],
    () => {
      return UsersApi.list();
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: false, // VERY IMPORTANT: we don't want to trigger the query on init unless the caller explicitly asks for it
      ...args?.list?.options,
    }
  );

  const updateMutation = useMutation<
    IAPIResponse<IBOUser>,
    AxiosValidationErrorResponse,
    IPatchObject<IBOUser>
  >(UsersApi.update, {
    onSuccess: () => {
      navigate('/users');
      notify({
        message: t('User edited successfully'),
        type: INotifierEnum.Success,
      });
    },
  });

  const forgotPasswordMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    IForgetPasswordPayload
  >(UsersApi.forgotPassword, {
    ...args?.forgotPassword?.options,
  });

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(UsersApi.patch, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.USERS_LIST],
    onSuccess: () => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
    },
    ...args?.patch?.options,
  });

  return {
    getOneById: getOneByIdQuery,
    update: updateMutation,
    list: listQuery,
    patch: patchMutation,
    forgotPassword: forgotPasswordMutation,
  };
};

export default useManageUsers;
