const ErrorCodesEnum = {
  E0000: 'E0000',
  E0001: 'E0001',
  E0002: 'E0002',
  E0003: 'E0003',
  E0004: 'E0004',
  E0005: 'E0005',
  E0006: 'E0006',
  E0007: 'E0007',
  E0008: 'E0008',
  E0009: 'E0009',
  E0010: 'E0010',
  E0011: 'E0011',

  E2105: 'E2105',
  E2106: 'E2106',
  E2107: 'E2107',
  E2108: 'E2108',
  E2109: 'E2109',
  E2110: 'E2110',
  E2111: 'E2111',
  E2112: 'E2112',
  E2113: 'E2113',

  // Carbon assessment
  E4008: 'E4008',

  // E5000: 'E5000',
  E5001: 'E5001',

  E6000: 'E6000',
  E6001: 'E6001',
  E6002: 'E6002',
  E6003: 'E6003',
  E6004: 'E6004',
  // campaign management
  E7001: 'E7001',
  E7002: 'E7002',

  E8001: 'E8001',

  // ssq
  E9001: 'E9001',
  E9002: 'E9002',

  // System Errors
  E10001: 'E10001',

  //Option Scoring Errors
  E13000: 'E13000',

  //Classifications Errors
  E14000: 'E14000',

  //Prescoring Errors
  E15000: 'E15000',
  // Local
  E0001L: 'E0001L',

  //
  E_SYSTEM_ERROR: 'E_SYSTEM_ERROR',
  E9999998: 'E9999998',
} as const;
export type ErrorCodesEnumType =
  typeof ErrorCodesEnum[keyof typeof ErrorCodesEnum];

const errorCodeEnumProxyHandler = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(target: object, propertyKey: PropertyKey, receiver?: any) {
    // eslint-disable-next-line prefer-rest-params
    const value = Reflect.get(target, propertyKey, receiver);
    if (value === undefined) return 'An unknown error has occurred.';
    return value;
  },
};
export const ErrorCodesLookup = new Proxy<
  Record<ErrorCodesEnumType, { title: string; description?: string }>
>(
  {
    E0000: {
      title: 'There are fields that requires your attention',
    },
    E0001: {
      title: 'This field cannot be empty',
    },
    E0002: {
      title: 'This value is already in use, please choose another one',
    },
    E0003: {
      title: 'Please provide a valid email address',
    },
    E0004: {
      title: 'Please select one of the provided options',
    },
    E0005: {
      title: 'The field should have at least {{count}} options',
    },
    E0006: {
      title: 'Please provide a valid URL',
    },
    E0007: {
      title: 'This field must be a positive number',
    },
    E0008: {
      title:
        'The password must contain at least eight characters, one character, one number and one special character [!@#$%^&*+=_(){}/?.,<>|;:\'"~-].',
    },
    E0009: {
      title: 'Passwords do not match',
    },
    E0010: {
      title: 'DUNS should be a 9-digit number, Please enter a correct value',
    },
    E0011: {
      title: 'The distributor should have at least one Standard user',
    },

    E2105: {
      title: 'You are not authorized to perform this transition',
    },
    E2106: {
      title:
        'This assessment request has been already scored, only the admin can make this transition',
    },
    E2107: {
      title:
        'The company still has missing information. Please fill in the missing information before proceeding.',
    },
    E2108: {
      title: 'This distributor has no valid scorecard, you cannot reuse it',
    },
    E2109: {
      title:
        'You cannot move to scoring page, the answers still has pending references',
    },

    E2110: {
      title:
        "There is a mismatch between the deadlines, this phase's deadline cannot be greater or equal to the next phase's deadline",
    },
    E2111: {
      title:
        "There is a mismatch between the deadlines, this phase's deadline cannot be less or equal to the previous phase's deadline {{details}}",
    },
    E2112: {
      title: 'The deadline should never be in the past',
    },
    E2113: {
      title: 'Deadline extension is only allowed once',
    },
    // E5000: {
    //   title: 'fod.my_colleagues.form.messages.add_colleague.error',
    // },
    E5001: {
      title:
        'A user with the same email already exist in the database. Please check again.',
    },
    E4008: {
      title:
        'You can not invite this distributor again as they already have an ongoing declaration',
    },
    E6000: {
      title:
        'Login failed. Your email or/and password are invalid, please try again!',
    },
    E6001: {
      title: 'Too many requests. Please try again later.',
    },
    E6002: {
      title: 'Your account is disabled, please contact inteliam',
    },
    E6003: {
      title:
        'Login failed. Your email or/and password are invalid, please try again!',
    },
    E6004: {
      title: 'Please confirm you are not a robot',
    },

    E7001: {
      title: 'The number of days should be between 1 and 20',
    },
    E7002: {
      title:
        'Editing the owner is not possible when some Assessment Requests were already attached to this campaign.',
    },

    E8001: {
      title:
        'Please note that the domain name of the given e-mail is registred. Please introduce the new domain name along with the manufacturer authorization letter first !',
    },
    E9001: {
      title:
        'This questionnaire has inactive questions {{inactiveQuestionsCodes}} and cannot be activated.',
    },
    E9002: {
      title:
        'This questionnaire has a question with a missing parent or child "{{missingQuestionsCodes}}" and cannot be activated.',
    },
    E10001: {
      title:
        "We couldn't complete your request due to a system error: {{errorCode}}",
    },
    E13000: {
      title: 'The Option Scoring Ratio should be between 0 and 1',
    },
    E14000: {
      title: 'The Weight be between 1 and 4',
    },
    E15000: {
      title: 'Reset Scoring failed',
    },
    E0001L: {
      title: 'The value you entered is invalid.',
    },
    E_SYSTEM_ERROR: {
      title: 'An error has occurred. Please contact you system administrator.',
    },
    E9999998: {
      title:
        "You don't have the required permissions to complete this action. Please contact your administrator.",
    },
  },
  errorCodeEnumProxyHandler
);
