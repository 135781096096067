import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { ChipInput, ErrorHelpText } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { FormChipInputProps } from '@shared/types';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  chips: {
    flex: 1,
  },
  addButton: {
    position: 'absolute',
    right: 5,
  },
}));
const FormChipInput: React.FC<FormChipInputProps> = (props) => {
  const { t } = useEssentials();
  const { name, label, helperText, defaultValue = [], required } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <div className={classes.wrapper}>
          <ChipInput
            label={label}
            value={field.value}
            className={classes.chips}
            onChange={field.onChange}
            variant='outlined'
            error={Boolean(errorMessage)}
            helperText={
              (errorMessage || helperText) && (
                <ErrorHelpText
                  data-cy={`helper-${name}`}
                  error={Boolean(errorMessage)}
                >
                  {errorMessage ? t(errorMessage, {}) : helperText}
                </ErrorHelpText>
              )
            }
            InputProps={{ required: required }}
            inputProps={{ 'data-cy': name }}
            InputLabelProps={{ required: required }}
          />
        </div>
      )}
    />
  );
};

export default memo(FormChipInput);
