import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MathUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { TextField, FormControl } from '@shared/components';

import type { FormTextInputProps } from '@shared/types';

const FormInput: React.FCC<FormTextInputProps> = (props) => {
  const { name, required, helperText, defaultValue, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormControl
      component='fieldset'
      error={!!errorMessage}
      required={required}
      disabled={rest.disabled || rest.readOnly}
      style={{ width: '100%' }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <TextField
              autoComplete='new-password'
              {...rest}
              ref={field.ref} // needed so that RHF can focus on error
              onChange={(event) => {
                field.onChange(
                  props.type === 'number'
                    ? MathUtils.convertToNumber(
                        event.target.value,
                        props.decimal,
                        props.roundingPrecision
                      )
                    : event.target.value
                );
                if (props.onChange) {
                  props.onChange(event);
                }
              }}
              value={
                props.type === 'number'
                  ? MathUtils.convertToNumber(
                      field.value,
                      props.decimal,
                      props.roundingPrecision
                    )
                  : field.value
              }
              {...{ name, required }}
              error={!!get(errors, `${name}.message`)}
              helperText={errorMessage ? t(errorMessage, {}) : helperText}
            />
          );
        }}
      />
    </FormControl>
  );
};

export default memo(FormInput);
