import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';
import { Transformers, Helpers, I18n } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { LanguagesApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ILanguage,
  IOptionItem,
  LanguageScope,
} from '@inteliam/foundation/lib/types';

const useLanguagesByScope = ({
  scope,
  options,
}: {
  scope?: LanguageScope;
  options?: InteliamUseQueryOptions<
    IAPIResponse<Array<ILanguage>>,
    BaseAxiosErrorResponse
  >;
} = {}): InteliamUseQueryResult<
  IAPIResponse<Array<ILanguage>>,
  BaseAxiosErrorResponse
> & {
  languages: ILanguage[];
  formattedLanguages: IOptionItem[];
  formattedExceptEnglish: IOptionItem[];
} => {
  const query = useQuery<
    IAPIResponse<Array<ILanguage>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.AVAILABLE_LANGUAGES, scope],
    () => LanguagesApi.fetchLanguagesByScope({ scope }),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      initialData: undefined,
      cacheTime: 1000 * 20,
      ...options,
    }
  );
  const languages = Helpers.ensureValueAsArray(query.data?.data);

  return {
    ...query,
    languages,
    formattedLanguages: Transformers.mapIntoOptionItem(
      languages,
      'name',
      'locale'
    ),
    formattedExceptEnglish: Transformers.mapIntoOptionItem(
      languages,
      'name',
      'locale'
    ).filter((language) => language.value !== I18n.defaultLocale),
  };
};

export default useLanguagesByScope;
