import {
  BO_ROLES_HIERARCHY,
  CompanyUserKindEnum,
  IARPhaseEnum,
  IBORolesEnum,
  ICompanyKindEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import type { UserStatusTranslationResponse } from '@core/utils/distributor-users';

import { USER_STATUSES } from '@core/enums';

import type { TFunction } from '@core/contexts';

import type {
  CompanyKindType,
  CompanyUserKindType,
  IBOUser,
  IBaseUser,
  IContactInfo,
  IDynamicOptionItem,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

export const regularEngagementSpecialistsOrHigher = (
  user: IBOUser
): boolean => {
  return (
    user.roles.includes(IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST) ||
    user.roles.includes(IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST)
  );
};

export const seniorEngagementSpecialists = (user: IBOUser): boolean => {
  return user.roles.includes(IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST);
};

export const regularAnalystsOrHigher = (user: IBOUser): boolean => {
  return (
    user.roles.includes(IBORolesEnum.ROLE_REGULAR_ANALYST) ||
    user.roles.includes(IBORolesEnum.ROLE_SENIOR_ANALYST)
  );
};

export const seniorAnalysts = (user: IBOUser): boolean => {
  return user.roles.includes(IBORolesEnum.ROLE_SENIOR_ANALYST);
};

export const formatShortName = (contactInfo?: IContactInfo): string => {
  let result = '';

  if (isNil(contactInfo)) {
    return '';
  }

  if (contactInfo?.firstName) {
    result += contactInfo?.firstName;

    if (contactInfo?.lastName) {
      result += ' ' + contactInfo?.lastName[0] + '.';
    }
  } else {
    if (contactInfo?.lastName) {
      result += contactInfo?.lastName;
    }
  }

  return result;
};
export const formatUser = (
  user: Partial<IBaseUser>
): IDynamicOptionItem<string, string> => {
  return {
    label: `${user?.contactInfo?.firstName || ''}. ${
      user?.contactInfo?.lastName?.charAt(0).toUpperCase() || ''
    }`,
    value: user?.id || '',
  };
};

export const getFormattedEngagementSpecialistsForPhase = (
  engagementSpecialists: IBOUser[]
): IDynamicOptionItem<string, string>[] => {
  return engagementSpecialists.map((user) => formatUser(user));
};

export const getFormattedAnalystsForPhase = (
  analysts: IBOUser[],
  phase: string
): IDynamicOptionItem<string, string>[] => {
  let filteredAndFormattedAnalysts = [];

  if (phase === IARPhaseEnum.IN_DOC_ANALYSIS) {
    // eslint-disable-next-line unicorn/no-array-callback-reference
    filteredAndFormattedAnalysts = analysts.map(formatUser);
  } else if (phase === IARPhaseEnum.IN_SCORING) {
    filteredAndFormattedAnalysts = analysts
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .filter(regularAnalystsOrHigher)
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .map(formatUser);
  } else {
    filteredAndFormattedAnalysts = analysts
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .filter(seniorAnalysts)
      // eslint-disable-next-line unicorn/no-array-callback-reference
      .map(formatUser);
  }

  return filteredAndFormattedAnalysts;
};

const getImplicitRoles = (role: IBORolesEnum): IBORolesEnum[] => {
  let subRoles = BO_ROLES_HIERARCHY[role];
  if (isNil(subRoles)) {
    return [];
  } else {
    if (subRoles.length === 0) {
      return [role];
    } else {
      subRoles.forEach((value) => {
        subRoles = subRoles.concat(getImplicitRoles(value));
      });

      return subRoles;
    }
  }
};

/**
 *
 * @param checkAgainst
 * @param user
 * @param businessOnly
 */
export const isGranted = (
  checkAgainst: IBORolesEnum | Array<IBORolesEnum>,
  user: IBOUser | JwtBOUser,
  shouldHaveAll = false
): boolean => {
  if (Array.isArray(checkAgainst)) {
    return checkAgainst[shouldHaveAll ? 'every' : 'some']((role) =>
      isGranted(role, user)
    );
  }
  let grantedRoles: IBORolesEnum[] = user.roles;
  user.roles.forEach((value) => {
    const implicitRoles = getImplicitRoles(value);
    grantedRoles = grantedRoles.concat(implicitRoles);
  });

  return grantedRoles.includes(checkAgainst);
};

export const getTranslatedUserRoles = (
  roles: Array<string> = [],
  t: TFunction
): Array<string> => {
  return Helpers.ensureValueAsArray(
    roles
      .filter(
        (role: string) =>
          role !== 'ROLE_USER' &&
          role !== 'ROLE_BO_USER' &&
          role !== 'ROLE_FO_USER'
      )
      .map((role: string) => t(role.toLowerCase()))
  );
};

export const isAnalyst = (user: IBOUser | JwtBOUser): boolean =>
  isGranted(IBORolesEnum.ROLE_JUNIOR_ANALYST, user);
export const isEngagementSpecialist = (user: IBOUser | JwtBOUser): boolean =>
  isGranted(IBORolesEnum.ROLE_JUNIOR_ENGAGEMENT_SPECIALIST, user);

export function getTranslatedUserStatus(
  status: string,
  t: TFunction
): UserStatusTranslationResponse {
  switch (status) {
    case IUserStatusEnum.STATUS_PENDING: {
      return {
        translatedAction: t('N/A'),
        translatedStatus: t('Pending'),
        translatedStatusHover: t(
          "This user is pending. This means that they have received the invitation e-mail but haven't yet completed their registration (Account set-up)."
        ),
      };
    }
    case IUserStatusEnum.STATUS_ENABLED: {
      return {
        translatedAction: t('Disable'),
        translatedStatus: t('Enabled'),
        translatedStatusHover: t(
          "This user is enabled. This means that they have activated their account and can login to the system. You can block this user' access by clicking on the action menu on the left side at any time."
        ),
      };
    }
    case IUserStatusEnum.STATUS_DISABLED: {
      return {
        translatedAction: t('Enable'),
        translatedStatus: t('Disabled'),
        translatedStatusHover: t(
          "This user is disabled. This means that the user cannot log in to the system. You can re-activate this user's account by clicking on the action menu on the left side at any time."
        ),
      };
    }
    case IUserStatusEnum.STATUS_DRAFT: {
      return {
        translatedAction: t('No data available at this moment'),
        translatedStatus: t('Draft'),
        translatedStatusHover: t(
          "This user is still draft. This means that this distributor doesn't have an assessment request yet."
        ),
      };
    }
    default: {
      return {
        translatedAction: t('No data available at this moment'),
        translatedStatus: t('N/A'),
        translatedStatusHover: '',
      };
    }
  }
}

export const USER_KIND_2_COMPANY_KIND: Record<
  CompanyUserKindType,
  CompanyKindType
> = {
  [CompanyUserKindEnum.SUPPLIER]: ICompanyKindEnum.SUPPLIER,
  [CompanyUserKindEnum.TRADING_GROUP]: ICompanyKindEnum.TRADING_GROUP,
  [CompanyUserKindEnum.DISTRIBUTOR]: ICompanyKindEnum.DISTRIBUTOR,
};

export const STATUS_UPDATE_TARGET_STATUS_LOOKUP: Record<
  IUserStatusEnum,
  IUserStatusEnum
> = {
  [IUserStatusEnum.STATUS_ENABLED]: IUserStatusEnum.STATUS_DISABLED,
  [IUserStatusEnum.STATUS_DISABLED]: IUserStatusEnum.STATUS_ENABLED,
  [IUserStatusEnum.STATUS_PENDING]: IUserStatusEnum.STATUS_PENDING,
  [IUserStatusEnum.STATUS_DRAFT]: IUserStatusEnum.STATUS_DRAFT,
  [IUserStatusEnum.STATUS_LOCKED]: IUserStatusEnum.STATUS_LOCKED,
};
export const PASSWORD_RESET_TARGET_STATUS_LOOKUP: Record<
  IUserStatusEnum,
  IUserStatusEnum
> = {
  [IUserStatusEnum.STATUS_ENABLED]: IUserStatusEnum.STATUS_ENABLED,
  [IUserStatusEnum.STATUS_DISABLED]: IUserStatusEnum.STATUS_ENABLED,
  [IUserStatusEnum.STATUS_PENDING]: IUserStatusEnum.STATUS_PENDING,
  [IUserStatusEnum.STATUS_DRAFT]: IUserStatusEnum.STATUS_DRAFT,
  [IUserStatusEnum.STATUS_LOCKED]: IUserStatusEnum.STATUS_LOCKED,
};

type UserStatuses = typeof USER_STATUSES;

// By default we exclude all technical statuses
type Args = {
  exclude?: UserStatuses[number]['value'][];
};

export const getUserStatusesEnum = ({
  exclude = [
    IUserStatusEnum.STATUS_DRAFT,
    IUserStatusEnum.STATUS_LOCKED,
    IUserStatusEnum.STATUS_PENDING,
  ],
}: Args = {}): UserStatuses =>
  USER_STATUSES.filter((status) => !exclude.includes(status.value));
