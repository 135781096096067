import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo, useState } from 'react';

import { clsx } from 'clsx';

import {
  AnswersAnalysisDropdown,
  TextBoxValueRenderer,
} from '@core/components/assessment-requests/answers-analysis-v2/internals';
import { DocumentAnalysisModal } from '@core/components/modals';

import { QUERY_KEYS } from '@core/queries';

import type { IEvaluationStatusEnum } from '@core/enums';

import type {
  AnswerAnalysisFieldProps,
  IAnswerEvaluation,
  IEvaluationProgress,
} from '@core/types';

import { Box } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IOptionProperties } from '@inteliam/foundation/lib/types';

import {
  AnswerAnalysisRow,
  AnswerAnalysisQuestionCell,
  AnswersAnalysisReferencesCell,
} from '../internals';

const useStyles = makeStyles(() => ({
  checked: {
    fontWeight: 'bold',
  },
  unchecked: {
    color: '#898888',
  },
}));

const MultipleChoiceFormField: React.FCC<AnswerAnalysisFieldProps> = ({
  answerEvaluation,
  referencesProgress,
  assessmentRequestId,
  query,
}) => {
  if (
    answerEvaluation.answer.field.kind !== IQuestionKindEnum.MultipleChoiceField
  ) {
    throw new Error('This code should never be reached [MultipleChoiceField]');
  }
  const classes = useStyles();

  const [modal, setModal] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();

  const statuses = Object.keys(query.statuses).filter(
    (status) => query.statuses[status as IEvaluationStatusEnum] === true
  );

  return (
    <>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.FullWidth>
              <AnswerAnalysisQuestionCell answerEvaluation={answerEvaluation} />
            </Layouts.FullWidth>
          );
        }}
      </AnswerAnalysisRow>
      {answerEvaluation.answer.field.definition
        .filter((option) =>
          canShow(statuses, referencesProgress?.[option.value])
        )
        .map((option) => {
          const checked = isChecked(answerEvaluation, option);
          return (
            <AnswerAnalysisRow key={option.value}>
              {({ Layouts }) => {
                return (
                  <>
                    <Layouts.Question>
                      <Box
                        className={clsx({
                          [classes.checked]: checked,
                          [classes.unchecked]: !checked,
                        })}
                      >
                        {option.label}
                      </Box>
                    </Layouts.Question>
                    <Layouts.Textbox>
                      {checked && option.schema.textbox?.enabled && (
                        <TextBoxValueRenderer
                          format={option.schema.textbox.format}
                          value={option.schema.textbox.defaultValue}
                        />
                      )}
                    </Layouts.Textbox>
                    <Layouts.Dropdown>
                      {checked && option.schema.dropdown?.enabled && (
                        <AnswersAnalysisDropdown
                          dropdownSchema={option.schema.dropdown}
                        />
                      )}
                    </Layouts.Dropdown>
                    <Layouts.Reference>
                      {referencesProgress?.[option.value] && (
                        <Box p={1}>
                          <AnswersAnalysisReferencesCell
                            referenceProgress={referencesProgress[option.value]}
                            onOpen={() => setModal(option.value)}
                          >
                            {modal === option.value && (
                              <DocumentAnalysisModal
                                {...{
                                  onClose: () => {
                                    setModal(undefined);
                                    queryClient
                                      .invalidateQueries([
                                        ...QUERY_KEYS.ANSWER_EVALUATIONS,
                                        assessmentRequestId,
                                      ])
                                      .catch(() => {});
                                  },
                                  assessmentRequestId,
                                  answerId:
                                    answerEvaluation.answer.field.answerId,
                                  optionId: option.value,
                                }}
                              />
                            )}
                          </AnswersAnalysisReferencesCell>
                        </Box>
                      )}
                    </Layouts.Reference>
                  </>
                );
              }}
            </AnswerAnalysisRow>
          );
        })}
    </>
  );
};

export default memo(MultipleChoiceFormField);

function canShow(
  statuses: Array<string>,
  referencesProgress?: IEvaluationProgress
) {
  if (referencesProgress) {
    return statuses.some((status) => {
      const key = status.toLocaleLowerCase() as keyof IEvaluationProgress;
      return referencesProgress[key] > 0;
    });
  }
  return true;
}

function isChecked(
  answerEvaluation: IAnswerEvaluation,
  option: IOptionProperties
) {
  return answerEvaluation.answer.field.defaultValue
    ? (answerEvaluation.answer.field.defaultValue as string[]).includes(
        option.value
      )
    : false;
}
