import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IPaginationQuery,
  IPatchObject,
  IThemeClassification,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/themes',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getThemes = async (
  paginator: IPaginationQuery
): Promise<IAPIResponse<Array<IThemeClassification>>> => {
  const { data } = await client.instance.get('', {
    params: paginator,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });
  //   assertIsTypedArray(data, isIOptionItem);
  return data;
};

const patch = async ({ id, body }: IPatchObject): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch(`/${id}`, body);
  return data;
};

export default {
  getThemes,
  patch,
};
