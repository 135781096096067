import type {
  IScoreCard,
  IThemeClassification,
  IPartialScore,
  ICriterionClassification,
  ICriteriaPartialScore,
} from '@inteliam/foundation/lib/types';

export const colorForValue = (value: number): string => {
  if (value >= 80) return '#07a014';
  if (value >= 60) return '#98c783';
  if (value >= 40) return '#f9cb9b';
  return '#ff9a00';
};

export const getPartialScoreByTheme = (
  scoreCard: IScoreCard | undefined,
  theme: IThemeClassification
): {
  partialScore?: IPartialScore;
  index?: number;
} => {
  const index = scoreCard?.partialScores.findIndex(
    (partialScore) => partialScore.theme.code === theme.code
  );

  if (index === -1 || index === undefined) {
    return {
      partialScore: undefined,
      index: undefined,
    };
  }
  return {
    partialScore: scoreCard?.partialScores[index],
    index,
  };
};

export const getCriteriaPartialScoreByCriterion = (
  partialScore: IPartialScore,
  criterion: ICriterionClassification
): {
  criteriaPartialScore?: ICriteriaPartialScore;
  index?: number;
} => {
  const index = partialScore.criteriaPartialScores.findIndex(
    (criteriaPartialScore) =>
      criteriaPartialScore.criterion.code === criterion.code
  );

  if (index === -1 || index === undefined) {
    return {
      criteriaPartialScore: undefined,
      index: undefined,
    };
  }
  return {
    criteriaPartialScore: partialScore.criteriaPartialScores[index],
    index,
  };
};
