import {
  IFilterTypeEnum,
  IReviewKindEnum,
  IReviewStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { ANSWER_ANALYSIS_RENDERER } from '@core/components/assessment-requests/answers-analysis-v2/configurable-theme-scoring';

import { QUERY_KEYS, useManageArReviews } from '@core/queries';

import { AssessmentReviewsUtils } from '@core/utils';

import { ReviewsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import { isITranslatableField } from '@core/guards';
import type { IAnswerEvaluation, IEvaluationProgress } from '@core/types';

import {
  Checkbox,
  Datatable,
  EditableTextInput,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TooltippedCell,
} from '@shared/components';

import { BOErrorTracking, DatatableUtils } from '@shared/utils';

import type { IFilterableColumn } from '@shared/types';

import type {
  DatatableState,
  IAnyAssessmentReview,
  IAssessmentStrength,
  IAssessmentWeakness,
  IFilterCondition,
  IPaginationQuery,
  IReview,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../';

interface Props {
  open: boolean;
  onClose: () => void;
  answerEvaluation: IAnswerEvaluation;
  assessmentRequestId: string;
  referencesProgress: Record<string, IEvaluationProgress> | undefined;
}

const assessmentReviewFilterer =
  (review: IReview) => (assessmentReview: IAnyAssessmentReview) => {
    if (assessmentReview.kind === IReviewKindEnum.Strength) {
      return assessmentReview.strength.id === review.id;
    }
    return assessmentReview.weakness.id === review.id;
  };

// They want "description" field and "contains" operator selected by DEFAULT : -2 click :)
const DEFAULT_CONDITIONS: DatatableState['criteria']['conditions'] = [
  {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    column: 'description.defaultValue',
    propertyPath: 'description.defaultValue',
    value: '',
    operator: 'contains',
  },
];
const ManageSwMatching: React.FCC<Props> = ({
  answerEvaluation,
  assessmentRequestId,
  referencesProgress,
}) => {
  const { t } = useEssentials();
  const { theme, criterion } = answerEvaluation.answer.classification;

  const { create, remove, paginateByEvaluation } = useManageArReviews(
    {
      assessmentRequestId,
      answerEvaluationId: answerEvaluation.id,
    },
    {
      paginateByEvaluation: {
        enabled: true,
      },
    }
  );
  const selectedStrength = paginateByEvaluation.query.data?.data
    .filter((review) => review.kind === IReviewKindEnum.Strength)
    .map((review) => (review as IAssessmentStrength).strength.id);
  const selectedWeakness = paginateByEvaluation.query.data?.data
    .filter((review) => review.kind === IReviewKindEnum.Weakness)
    .map((review) => (review as IAssessmentWeakness).weakness.id);
  const columns = React.useMemo<IFilterableColumn<IReview>[]>(
    () => [
      {
        field: '',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: '',
        width: 65,
        renderCell: (parameters) => {
          return (
            <Checkbox
              data-cy={`${parameters.row.code}-checkbox`}
              disabled={
                create.mutation.isLoading ||
                paginateByEvaluation.query.isProcessing
              }
              checked={
                parameters.row.kind === IReviewKindEnum.Strength
                  ? selectedStrength?.includes(parameters.row.id)
                  : selectedWeakness?.includes(parameters.row.id)
              }
              onCheck={() => {
                const newAssessmentReview =
                  AssessmentReviewsUtils.normalize.instantiate(
                    parameters.row.kind,
                    {
                      answerEvaluation,
                      reviewId: parameters.row.id,
                      assessmentRequestId,
                    }
                  );
                create.run(newAssessmentReview);
              }}
              onUncheck={() => {
                const assessmentReview =
                  paginateByEvaluation.query.data?.data.find(
                    assessmentReviewFilterer(parameters.row)
                  );
                if (assessmentReview) {
                  remove.run(assessmentReview?.id);
                } else {
                  BOErrorTracking.report(
                    new Error(
                      `Delete AR Review: Assessment Review not found for Review [[${parameters.row.id}]]`
                    )
                  );
                }
              }}
            />
          );
        },
      },
      {
        field: 'code',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Code'),
        width: 120,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.code}>
              {parameters.row?.code}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'description.defaultValue',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Description'),
        flex: 1,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(parameters.row?.description)}
            >
              {I18n.getTranslatedValue(parameters.row?.description)}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'amendment.defaultValue',
        flex: 1,
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Second part'),
        editable: true,
        renderEditCell: (parameters) => <EditableTextInput {...parameters} />,
        valueGetter: (parameters) => {
          const assessmentReview = paginateByEvaluation.query.data?.data.find(
            assessmentReviewFilterer(parameters.row)
          );
          return isITranslatableField(assessmentReview?.amendment)
            ? assessmentReview?.amendment?.defaultValue
            : '';
        },
        renderCell: (parameters) => {
          const assessmentReview = paginateByEvaluation.query.data?.data.find(
            assessmentReviewFilterer(parameters.row)
          );

          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(assessmentReview?.amendment)}
            >
              {I18n.getTranslatedValue(assessmentReview?.amendment)}
            </TooltippedCell>
          );
        },
      },
    ],
    [
      answerEvaluation,
      assessmentRequestId,
      create,
      paginateByEvaluation.query.data?.data,
      paginateByEvaluation.query.isProcessing,
      remove,
      selectedStrength,
      selectedWeakness,
      t,
    ]
  );

  const Component =
    ANSWER_ANALYSIS_RENDERER[answerEvaluation.answer.field.kind];
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableBody>
            <Component
              assessmentRequestId={assessmentRequestId}
              answerEvaluation={answerEvaluation}
              referencesProgress={referencesProgress}
              query={{
                statuses: { APPROVED: true, PENDING: true, REJECTED: true },
              }}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <StrengthsList
        {...{
          assessmentRequestId,
          themeId: theme.id,
          criterionId: criterion.id,
          answerEvaluationId: answerEvaluation.id,
          columns,
        }}
      />
      <WeaknessesList
        {...{
          assessmentRequestId,
          themeId: theme.id,
          criterionId: criterion.id,
          answerEvaluationId: answerEvaluation.id,
          columns,
        }}
      />
    </React.Fragment>
  );
};

const ModalContainer: React.FCC<Props> = (props) => {
  const { t } = useEssentials();
  return (
    <BaseDialog.Dialog
      keepMounted={true}
      open={props.open}
      onClose={props.onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={props.onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Match S/IA')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        {/* DO NOT Change this ID */}
        <div id='modal-question-item'>
          <ManageSwMatching {...props} />
        </div>
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default ModalContainer;

type ListProps = {
  columns: IFilterableColumn[];
  assessmentRequestId: string;
  answerEvaluationId: string;
  themeId: string;
  criterionId?: string;
};
// todo refactor this component
const WeaknessesList: React.FCC<ListProps> = React.memo(
  ({
    columns,
    assessmentRequestId,
    answerEvaluationId,
    themeId,
    criterionId,
  }) => {
    const { t, notify, INotifierEnum } = useEssentials();
    const { patch, paginateByEvaluation } = useManageArReviews(
      {
        assessmentRequestId,
        answerEvaluationId,
      },
      {
        paginateByEvaluation: {
          enabled: true,
        },
      }
    );
    const weaknessesDatatableHandlers = React.useState<DatatableState>({
      page: 1,
      itemsPerPage: 10,
      criteria: {
        operator: 'and',
        conditions: DEFAULT_CONDITIONS,
      },
      selected: [],
      sort: {
        _id: 'desc',
      },
      visibleColumns: columns.map((column) => column.field),
    });
    return (
      <div style={{ marginTop: 60 }}>
        <h3>{t('Areas of Improvements')}</h3>
        <Datatable.Container
          queryKey={[
            ...QUERY_KEYS.AR_REVIEWS_LIST(assessmentRequestId),
            themeId,
            criterionId,
            IReviewKindEnum.Weakness,
          ]}
          columns={columns}
          stateExternalHandlers={weaknessesDatatableHandlers}
          fetcher={(query) =>
            ReviewsApi.paginate(
              buildQuery(query, IReviewKindEnum.Weakness, themeId, criterionId)
            )
          }
        >
          <Datatable.Toolbar></Datatable.Toolbar>
          <div style={{ height: '100%', width: '100%' }}>
            <Datatable.Table
              autoHeight
              rowHeight={80}
              checkboxSelection={false}
              onCellEditStop={(parameters, event) => {
                const review = parameters.row as IReview;
                const assessmentReview =
                  paginateByEvaluation.query.data?.data.find(
                    assessmentReviewFilterer(review)
                  );
                if (assessmentReview) {
                  patch.run(
                    {
                      [parameters.field]: event.target.value,
                    },
                    assessmentReview.id
                  );
                } else {
                  notify({
                    message: t(
                      'You need to check the S/IA so that you can edit the second part'
                    ),
                    type: INotifierEnum.Error,
                  });
                }
              }}
            />
          </div>
        </Datatable.Container>
      </div>
    );
  }
);

const StrengthsList: React.FCC<ListProps> = React.memo(
  ({
    columns,
    assessmentRequestId,
    answerEvaluationId,
    themeId,
    criterionId,
  }) => {
    const { t, notify, INotifierEnum } = useEssentials();
    const { patch, paginateByEvaluation } = useManageArReviews(
      {
        assessmentRequestId,
        answerEvaluationId,
      },
      {
        paginateByEvaluation: {
          enabled: true,
        },
      }
    );

    const strengthsDatatableHandlers = React.useState<DatatableState>({
      page: 1,
      itemsPerPage: 10,
      criteria: {
        operator: 'and',
        conditions: DEFAULT_CONDITIONS,
      },
      selected: [],
      sort: {
        _id: 'desc',
      },
      visibleColumns: columns.map((column) => column.field),
    });
    return (
      <div style={{ marginTop: 60 }}>
        <h3>{t('Strengths')}</h3>
        <Datatable.Container
          queryKey={[
            ...QUERY_KEYS.AR_REVIEWS_LIST(assessmentRequestId),
            themeId,
            criterionId,
            IReviewKindEnum.Strength,
          ]}
          stateExternalHandlers={strengthsDatatableHandlers}
          columns={columns}
          fetcher={(query) =>
            ReviewsApi.paginate(
              buildQuery(query, IReviewKindEnum.Strength, themeId, criterionId)
            )
          }
        >
          <Datatable.Toolbar></Datatable.Toolbar>
          <div style={{ height: '100%', width: '100%' }}>
            <Datatable.Table
              autoHeight
              rowHeight={80}
              checkboxSelection={false}
              // eslint-disable-next-line sonarjs/no-identical-functions
              onCellEditStop={(parameters, event) => {
                const review = parameters.row as IReview;

                const assessmentReview =
                  paginateByEvaluation.query.data?.data.find(
                    assessmentReviewFilterer(review)
                  );

                if (assessmentReview) {
                  patch.run(
                    {
                      [parameters.field]: event.target.value,
                    },
                    assessmentReview.id
                  );
                } else {
                  notify({
                    message: t(
                      'You need to check the S/IA so that you can edit the second part'
                    ),
                    type: INotifierEnum.Error,
                  });
                }
              }}
            />
          </div>
        </Datatable.Container>
      </div>
    );
  }
);

function buildQuery(
  query: IPaginationQuery,
  kind: IReviewKindEnum,
  themeId: string,
  criterionId: string | undefined
): IPaginationQuery {
  const primaryConditions: Array<IFilterCondition> = [
    DatatableUtils.createCondition('kind', 'eq', kind),
    DatatableUtils.createCondition(
      'status',
      'eq',
      IReviewStatusEnum.STATUS_ACTIVE
    ),
    DatatableUtils.createCondition('theme.id', 'eq', themeId),
  ];

  if (criterionId) {
    primaryConditions.push(
      DatatableUtils.createCondition('criterion.id', 'eq', criterionId)
    );
  }

  return {
    ...query,
    itemsPerPage: -1, // -1 causes problems with Datatable
    primaryCriteria: {
      conditions: primaryConditions,
      operator: 'and',
    },
  };
}
