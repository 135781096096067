import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Grid } from '@shared/components';

import type { IPartialFOSupplierUser } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  open: boolean;
  onClose: () => void;
  salesManager: IPartialFOSupplierUser | undefined;
  riskManager: IPartialFOSupplierUser | undefined;
}

const CustomerContact: React.FCC<Props> = ({
  onClose,
  open,
  riskManager,
  salesManager,
}) => {
  const { t } = useEssentials();

  return (
    <BaseDialog.Dialog open={open} keepMounted={false} maxWidth='md'>
      <BaseDialog.Title id='Contact information' onClose={onClose}>
        {t('Contact information')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        <Grid container item xs={12}>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Contact')}</strong>
              </Grid>
              <Grid item xs={6}>
                {riskManager?.contactInfo?.fullName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Contact')}</strong>
              </Grid>
              <Grid item xs={6}>
                {salesManager?.contactInfo?.fullName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Email')}</strong>
              </Grid>
              <Grid item xs={6}>
                {riskManager?.contactInfo?.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Email')}</strong>
              </Grid>
              <Grid item xs={6}>
                {salesManager?.contactInfo?.email}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Phone number')}</strong>
              </Grid>
              <Grid item xs={6}>
                {riskManager?.contactInfo?.phoneNumber}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Phone number')}</strong>
              </Grid>
              <Grid item xs={6}>
                {salesManager?.contactInfo?.phoneNumber}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Role')}</strong>
              </Grid>
              <Grid item xs={6}>
                <strong>{t('Risk manager')}</strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ padding: 10 }}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <strong>{t('Role')}</strong>
              </Grid>
              <Grid item xs={6}>
                <strong>{t('Sales manager')}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>{' '}
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(CustomerContact);
