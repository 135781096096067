import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type {
  HasActiveDiscountResponse,
  ICreateDiscountPayload,
  IDiscount,
  IDiscountEnums,
  IDiscountPayload,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAnyEnterprise,
  IPaginationResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/enterprises';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getDiscounts = async (
  id: IAnyEnterprise['id']
): Promise<IPaginationResponse<IDiscount>> => {
  const { data } = await client.instance.get<IPaginationResponse<IDiscount>>(
    `${id}/discounts`
  );

  return data;
};

const getEnums = async (): Promise<IAPIResponse<IDiscountEnums>> => {
  const { data } = await client.instance.get<IAPIResponse<IDiscountEnums>>(
    '/discounts/enums'
  );
  return data;
};

const create = async (payload: ICreateDiscountPayload): Promise<void> => {
  const { data } = await client.instance.post<void>(
    `${payload.manufacturerId}/discounts`,
    payload
  );
  return data;
};

const update = async (
  payload: IPatchObject<IDiscountPayload>
): Promise<void> => {
  const { data } = await client.instance.put<void>(
    `/discounts/${payload.id}`,
    payload.body
  );
  return data;
};

const hasActiveDiscount = async (
  id: IAnyEnterprise['id']
): Promise<HasActiveDiscountResponse> => {
  const { data } = await client.instance.get<HasActiveDiscountResponse>(
    `/${id}/has-active-discount`
  );
  return data;
};

const getOneById = async (id: string): Promise<IAPIResponse<IDiscount>> => {
  const { data } = await client.instance.get<IAPIResponse<IDiscount>>(
    `/discounts/${id}`
  );

  return data;
};

export default {
  update,
  getOneById,
  hasActiveDiscount,
  create,
  getDiscounts,
  getEnums,
};
