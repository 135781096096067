import React, { memo } from 'react';

import { useCompanyQualificationEnums, useQuestionEnums } from '@core/queries';

import { QuestionsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { CenteredSpinner, Controls, Grid, FormGroup } from '@shared/components';

interface Props {
  readOnly?: boolean;
}
const SingleFreeFormField: React.FCC<Props> = ({ readOnly = false }) => {
  const { t } = useEssentials();
  const { questionEnums } = useQuestionEnums();
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  if (!companyQualificationEnums || !questionEnums) {
    return <CenteredSpinner />;
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <FormGroup>
            <Controls.FormSelect
              name={'textField.type'}
              label={t('TB type')}
              options={questionEnums.textBoxTypes}
              required
              defaultValue={
                QuestionsUtils.getDefaultSingleFreeFormOption(
                  companyQualificationEnums
                ).type
              }
              readOnly={readOnly}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(SingleFreeFormField);
