import { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { QUERY_KEYS, useManageArReviews } from '@core/queries';

import { REVIEW_ENUMS } from '@core/enums';

import { AssessmentRequestReviewsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import { isIAssessmentStrength } from '@core/guards';

import {
  Datatable,
  EditableTextInput,
  Grid,
  IconButton,
  RemoveCircleOutlineIcon,
  TooltippedCell,
} from '@shared/components';

import type { IFilterableColumn } from '@shared/types';

import type { IAnyAssessmentReview } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  open: boolean;
  onClose: () => void;
  themeId: string;
  assessmentRequestId: string;
}

const ManageSw: React.FCC<{
  themeId: string;
  assessmentRequestId: string;
}> = React.memo(({ assessmentRequestId, themeId }) => {
  const { t } = useEssentials();

  const { remove, patch } = useManageArReviews({
    assessmentRequestId,
    themeId,
  });

  const columns = React.useMemo<IFilterableColumn<IAnyAssessmentReview>[]>(
    () => [
      {
        field: '',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: '',
        width: 65,
        renderCell: (parameters) => {
          return (
            <IconButton
              id={`remove-sw-${parameters.row.id}`}
              onClick={() => remove.run(parameters.row.id)}
              size='large'
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          );
        },
      },
      {
        field: 'kind',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: REVIEW_ENUMS.TYPES,
            multiple: false,
          },
        },
        headerName: t('Type'),
        width: 180,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters?.row?.kind}>
              {parameters?.row?.kind}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'description.defaultValue',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Description'),
        flex: 1,
        renderCell: (parameters) => {
          const description = isIAssessmentStrength(parameters.row)
            ? parameters.row.strength.description
            : parameters.row.weakness.description;
          return (
            <TooltippedCell title={I18n.getTranslatedValue(description)}>
              {I18n.getTranslatedValue(description)}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'amendment.defaultValue',
        flex: 1,
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Second part'),
        renderEditCell: (parameters) => <EditableTextInput {...parameters} />,
        editable: true,
        valueGetter: (parameters) =>
          I18n.getTranslatedValue(parameters.row.amendment),
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(parameters.row.amendment)}
            >
              {I18n.getTranslatedValue(parameters.row.amendment)}
            </TooltippedCell>
          );
        },
      },
    ],
    [remove, t]
  );
  return (
    <>
      <Datatable.Container
        queryKey={[...QUERY_KEYS.AR_REVIEWS_LIST(assessmentRequestId), themeId]}
        columns={columns}
        fetcher={(paginator) =>
          AssessmentRequestReviewsApi.paginateByTheme({
            assessmentRequestId: assessmentRequestId,
            themeId: themeId,
            paginator,
          })
        }
      >
        <Datatable.Toolbar />
        <div style={{ height: '100%', width: '100%' }}>
          <Datatable.Table
            autoHeight
            rowHeight={80}
            checkboxSelection={false}
            onCellEditStop={(parameters, event) => {
              patch.run(
                {
                  [parameters.field]: event.target.value,
                },
                parameters.row.id
              );
            }}
          />
        </div>
      </Datatable.Container>
    </>
  );
});

const ModalContainer: React.FCC<Props> = (props) => {
  const { t } = useEssentials();
  return (
    <BaseDialog.Dialog
      keepMounted={true}
      open={props.open}
      onClose={props.onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={props.onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('S/IA')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <ManageSw
          themeId={props.themeId}
          assessmentRequestId={props.assessmentRequestId}
        />
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default ModalContainer;
