import { IconButtonProps, IconButton as MUIIconButton } from '@mui/material';

import * as React from 'react';

import { CircularProgress } from '@shared/components';

import { makeStyles } from '@shared/utils';

type Props = IconButtonProps & { id: string; isLoading?: boolean };

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, id, disabled, children, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <MUIIconButton
        ref={ref}
        {...rest}
        disabled={isLoading || disabled}
        id={id}
        data-cy={id}
      >
        {children}
        {isLoading && <CircularProgress className={classes.loader} size={24} />}
      </MUIIconButton>
    );
  }
);

export default IconButton;
