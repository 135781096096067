import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isICsmEntry } from '@core/guards';
import type { ICsmEntry } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type CsmEntryActions = 'paginate';

const baseCslEntryActions = ApiFactory.generate<
  ICsmEntry,
  JwtBOUser,
  CsmEntryActions
>({
  baseRoute: '/csm',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isICsmEntry,
});

export default {
  ...baseCslEntryActions.actions,
};
