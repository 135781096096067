import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';

import React, { memo, useState } from 'react';

import {
  AnswerAnalysisQuestionCell,
  AnswerAnalysisRow,
  AnswersAnalysisReferencesCell,
  TextBoxValueRenderer,
} from '@core/components/assessment-requests/answers-analysis/internals';
import { DocumentAnalysisModal } from '@core/components/modals';

import type { IEvaluationStatusEnum } from '@core/enums';

import type {
  AnswerAnalysisFieldProps,
  IEvaluationProgress,
} from '@core/types';

import { Noop, TableCell } from '@shared/components';

const SingleFreeFormField: React.FCC<AnswerAnalysisFieldProps> = ({
  answerEvaluation,
  referencesProgress,
  assessmentRequestId,
  query,
}) => {
  const { answer } = answerEvaluation;
  if (answer.field.kind !== IQuestionKindEnum.SingleFreeFormField) {
    throw new Error('This code should never be reached [SingleFreeFormField]');
  }
  const { field } = answer;
  const [modal, setModal] = useState<string | undefined>(undefined);

  const statuses = Object.keys(query.statuses).filter(
    (status) => query.statuses[status as IEvaluationStatusEnum] === true
  );

  if (!canShow(statuses, referencesProgress?.['1'])) {
    return <Noop />;
  }

  return (
    <>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.FullWidth>
              <AnswerAnalysisQuestionCell answerEvaluation={answerEvaluation} />
            </Layouts.FullWidth>
          );
        }}
      </AnswerAnalysisRow>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <>
              <Layouts.Question>
                <TextBoxValueRenderer
                  format={field.definition.format}
                  value={field.defaultValue}
                />
              </Layouts.Question>
              <TableCell
                style={{
                  border: 0,
                }}
              ></TableCell>
              <TableCell
                style={{
                  border: 0,
                }}
              ></TableCell>
              <Layouts.Question>
                {referencesProgress && referencesProgress['1'] ? (
                  <AnswersAnalysisReferencesCell
                    referenceProgress={referencesProgress['1']}
                    onOpen={() => setModal(answerEvaluation.id)}
                  >
                    {modal === answerEvaluation.id && (
                      <DocumentAnalysisModal
                        {...{
                          onClose: () => setModal(undefined),
                          assessmentRequestId,
                          answerId: answer.field.answerId,
                          optionId: undefined,
                        }}
                      />
                    )}
                  </AnswersAnalysisReferencesCell>
                ) : (
                  ''
                )}
              </Layouts.Question>
            </>
          );
        }}
      </AnswerAnalysisRow>
    </>
  );
};

export default memo(SingleFreeFormField);

function canShow(
  statuses: Array<string>,
  referencesProgress?: IEvaluationProgress
) {
  if (referencesProgress) {
    return statuses.some((status) => {
      const key = status.toLocaleLowerCase() as keyof IEvaluationProgress;
      return referencesProgress[key] > 0;
    });
  }
  return true;
}
