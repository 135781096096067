import React, { Reducer, useCallback, useReducer, useState } from 'react';

import { useEssentials } from '@core/contexts';
import type { IBODistributor, IMergeDistributor } from '@core/types';

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import { MatchAndMergeSimpleRow, mergeReducer } from '.';
import ContactRow from './merge-contact-row';
import SelectAllRow from './merge-select-all-row';
import {
  MergeAction,
  ActionTypeEnum,
  CONTACT_ROWS,
  IMergeChanger,
  initializeSelection,
  MergeState,
  ROWS,
  SelectionType,
} from './utils';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },

  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
}));

interface Props {
  existing: IBODistributor;
  draft: IBODistributor;
  onSkip: () => void;
  onMerge: (payload: IMergeDistributor) => void;
  isLoading: boolean;
}

const Merge: React.FCC<Props> = ({
  existing,
  draft,
  onSkip,
  isLoading,
  onMerge,
}) => {
  const classes = useStyles();

  const { t } = useEssentials();
  const [state, dispatch] = useReducer<Reducer<MergeState, MergeAction>>(
    mergeReducer,
    {
      company: existing,
      selection: initializeSelection('EXISTING'),
    }
  );
  const [draftCompany, setDraftCompany] = useState(draft);
  const handleChangeValue: IMergeChanger = useCallback(
    (event, value, selectionType) => {
      if (event.target.checked === true) {
        dispatch({
          type: ActionTypeEnum.SIMPLE,
          payload: {
            field: event.target.name,
            value,
            selectionType,
          },
        });
      }
    },
    []
  );
  const handleSelectAll = useCallback(
    (company: IBODistributor, selectionType: SelectionType) => {
      dispatch({
        type: ActionTypeEnum.SELECT_ALL,
        payload: {
          value: company,
          selectionType,
        },
      });
    },
    []
  );

  return (
    <>
      <Card.CContainer styled>
        <Card.Header
          title={t(
            'Select for each field, the attribute you want to keep in the merged company'
          )}
        />
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align='left'>{t('Attribute')}</TableCell>
                <TableCell align='left' className={classes.cell}>
                  {t('Existing company')}
                </TableCell>
                <TableCell align='left' className={classes.cell}>
                  {t('Draft company')}
                </TableCell>
                <TableCell align='left'>{t('Selection')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SelectAllRow
                existing={existing}
                draft={draftCompany}
                merged={state}
                onSelect={handleSelectAll}
              />
              {ROWS.map((row) => (
                <MatchAndMergeSimpleRow
                  key={row.attribute}
                  row={row}
                  existing={existing}
                  draft={draftCompany}
                  merged={state}
                  onChange={handleChangeValue}
                />
              ))}
              {CONTACT_ROWS.map((contactRow) => (
                <ContactRow
                  key={contactRow.attribute}
                  title={t(contactRow.attribute)}
                  row={contactRow}
                  attribute={contactRow.valuePropertyPath}
                  existing={existing}
                  draft={draftCompany}
                  merged={state}
                  onChange={handleChangeValue}
                  onUpdateContact={setDraftCompany}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card.CContainer>
      <Card.CContainer styled>
        <Box justifyContent='space-between' display='flex' p={3}>
          <Button
            id='skip'
            type='button'
            variant='contained'
            color='secondary'
            onClick={onSkip}
            disabled={isLoading}
          >
            {t('Skip')}
            {isLoading && <CircularProgress color='inherit' size={16} />}
          </Button>

          <Button
            id='merge'
            type='button'
            variant='contained'
            color='primary'
            onClick={() => {
              onMerge({
                draftCompanyId: draftCompany.id,
                newDistributor: state.company,
              });
            }}
            disabled={isLoading}
          >
            {t('Merge')}
            {isLoading && <CircularProgress color='inherit' size={16} />}
          </Button>
        </Box>
      </Card.CContainer>
    </>
  );
};

export default Merge;
