import * as React from 'react';

import { useEssentials } from '@core/contexts';

import { Box } from '@shared/components';

import { SpreadSheetViewer } from './speadsheet-viewer';

/* eslint-disable @inteliam/i18n/raw-text-detected */
type Props = {
  name?: string;
  url: string;
  type: string;
};

const fileTypeDetector = (mimeType: string) => {
  return {
    docs: () =>
      [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
      ].includes(mimeType),
    images: () =>
      ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'].includes(
        mimeType
      ),
    pdf: () => ['application/pdf'].includes(mimeType),
    sheet: () =>
      [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(mimeType),
  };
};

const DocumentViewer: React.FCC<Props> = ({ url, name = 'unnamed', type }) => {
  const { t } = useEssentials();

  const renderViewer = () => {
    if (fileTypeDetector(type).docs()) {
      const encodedUrl = encodeURIComponent(`${url}&disposition=view`);
      return (
        <iframe
          title={name}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`}
          style={{ width: '100%', height: '100%' }}
          frameBorder='1'
        ></iframe>
      );
    } else if (fileTypeDetector(type).images()) {
      return (
        <img
          src={`${url}&disposition=view`}
          alt={name}
          style={{ width: '100%', height: '100%' }}
        />
      );
    } else if (fileTypeDetector(type).pdf()) {
      return (
        <iframe
          src={`${url}&disposition=view`}
          title={name}
          style={{ width: '100%', height: '100%' }}
        />
      );
    } else if (fileTypeDetector(type).sheet()) {
      return <SpreadSheetViewer src={`${url}&disposition=view`} />;
    } else {
      return (
        <a href={url} target='_blank' rel='noreferrer' download={name}>
          <span>
            {t('File Not supported, Click to open the file on your browser')}
          </span>
        </a>
      );
    }
  };
  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <a href={url} download={name} target='_blank' rel='noreferrer'>
        <span>{t('Click here to open the file on your browser')}</span>
      </a>
      {renderViewer()}
    </Box>
  );
};

export default DocumentViewer;
