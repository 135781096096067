import { DEFAULT_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { AnswerEvaluationsApi } from '@core/api';
import type { IThemesInsights } from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const useFetchSurveyThemesInsightsByAR = ({
  id,
}: {
  id: string;
}): {
  themesInsights?: IThemesInsights;
  isLoading: boolean;
} => {
  const { data, isLoading, isFetching } = useQuery<
    IAPIResponse<IThemesInsights>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.ANSWERS_INSIGHTS_BY_THEME, id],
    () => {
      return AnswerEvaluationsApi.getThemesInsightsByAR(id);
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
    }
  );

  return { themesInsights: data?.data, isLoading: isLoading || isFetching };
};

export default useFetchSurveyThemesInsightsByAR;
