import { Yup } from '@inteliam/foundation/lib/utils';

const updateContactsSchema = Yup.object().shape({
  scoredCompany: Yup.object().shape({
    primaryContact: Yup.object().shape({
      id: Yup.string().required(),
    }),
  }),
  questionnaireContact: Yup.object().shape({
    id: Yup.string().required(),
  }),
});

export default updateContactsSchema;
