import { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';

import type {
  IFilterNormalizers,
  IFilterValidators,
} from '@inteliam/foundation/lib/types';

import * as AssessmentRequest from './assessment-request-filterer';
import * as Assignment from './assignment-filterer';
import * as BoUsers from './bo-user-filterer';
import * as Bool from './bool-filterer';
import * as Campaign from './campaign-filterer';
import * as CompanyCategories from './category-filterer';
import * as Company from './company-filterer';
import * as Country from './country-filterer';
import * as Date from './date-filterer';
import * as DateRange from './date-range-filterer';
import * as DateRangeWithPresets from './date-range-with-presets-filterer';
import * as FoUsers from './fo-user-filterer';
import * as List from './list-filterer';
import * as QuestionClassifications from './question-classifications-filterer';
import * as Role from './role-filterer';
import * as CompanySizes from './size-filterer';
import * as Text from './text-filterer';
import * as Year from './year-filterer';

export const FilterNormalizers: IFilterNormalizers = {
  AssessmentRequest: AssessmentRequest.Normalizer,
  Campaign: Campaign.Normalizer,
  Company: Company.Normalizer,
  FoUsers: FoUsers.Normalizer,
  CompanyCategories: CompanyCategories.Normalizer,
  Country: Country.Normalizer,
  Date: Date.Normalizer,
  DateRange: DateRange.Normalizer,
  DateRangeWithPresets: DateRangeWithPresets.Normalizer,
  QuestionClassifications: QuestionClassifications.Normalizer,
  Role: Role.Normalizer,
  Year: Year.Normalizer,
};

export const FilterValidators: IFilterValidators = {
  AssessmentRequest: AssessmentRequest.Validator,
  Campaign: Campaign.Validator,
  Company: Company.Validator,
  FoUsers: FoUsers.Validator,
  CompanyCategories: CompanyCategories.Validator,
  Country: Country.Validator,
  DateRange: DateRange.Validator,
  DateRangeWithPresets: DateRangeWithPresets.Validator,
  Role: Role.Validator,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Filterers: Record<IFilterTypeEnum, React.ComponentType<any>> = {
  // eslint-disable-next-line unicorn/no-null
  [IFilterTypeEnum.NotFiltrable]: () => null,
  AssessmentRequest: AssessmentRequest.Component,
  Assignment: Assignment.Component,
  MultipleAssignment: Assignment.Component,
  Boolean: Bool.Component,
  BoUsers: BoUsers.Component,
  Campaign: Campaign.Component,
  Company: Company.Component,
  FoUsers: FoUsers.Component,
  CompanyCategories: CompanyCategories.Component,
  CompanySizes: CompanySizes.Component,
  Country: Country.Component,
  Date: Date.Component,
  DateRange: DateRange.Component,
  DateRangeWithPresets: DateRangeWithPresets.Component,
  List: List.Component,
  QuestionClassifications: QuestionClassifications.Component,
  Role: Role.Component,
  Text: Text.Component,
  Year: Year.Component,
};

export default Filterers;
