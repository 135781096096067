import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery } from '@core/queries';

import { DistributorsApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

import * as QUERY_KEYS from './query-keys';

const useFetchHasValidScoreCard = ({
  id,
  options,
}: {
  id: string;
  options?: InteliamUseQueryOptions<
    IAPIResponse<{ valid: boolean }>,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IAPIResponse<{ valid: boolean }>,
  BaseAxiosErrorResponse
> => {
  return useQuery<IAPIResponse<{ valid: boolean }>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.SCORECARD, id],
    () => DistributorsApi.hasValidScoreCard(id),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      initialData: undefined,
      cacheTime: 1000 * 20,
      ...options,
    }
  );
};

export default useFetchHasValidScoreCard;
