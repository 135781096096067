import { I18n, MathUtils } from '@inteliam/foundation/lib/utils';
import { noop } from 'lodash-es';

import React, { memo } from 'react';

import {
  ConfigurableThemeScoring,
  EvaluationInsights,
  FormCompanyWeightSelect,
  ScoreInput,
} from '@core/components';

import { useScoreCardHandlers, useWorkingPage } from '@core/hooks';

import {
  useFetchSurveyThemesInsightsByAR,
  useManageAssessmentRequest,
} from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IEvaluationProgress } from '@core/types';

import {
  Accordion,
  Box,
  CenteredSpinner,
  Controls,
  ExpandMoreIcon,
  Grid,
  Typography,
} from '@shared/components';

type Props = {
  children: React.ReactNode;
  themeIndex: number;
};

const DEFAULT_INSIGHTS: IEvaluationProgress = {
  approved: 0,
  rejected: 0,
  pending: 0,
  total: 0,
};

const AnswersAnalysisThemeAccordion: React.FCC<Props> = ({
  children,
  themeIndex,
}) => {
  const { assessmentRequest, scoreCard } = useWorkingPage();
  const themePartialScore = scoreCard.partialScores[themeIndex];

  const { t } = useEssentials();
  const { themesInsights } = useFetchSurveyThemesInsightsByAR({
    id: assessmentRequest.id,
  });
  const { getAssociatedSurveyProgress } = useManageAssessmentRequest({
    getAssociatedSurveyProgress: {
      args: { id: assessmentRequest.id },
    },
  });
  const themeName = I18n.getTranslatedValue(themePartialScore.theme.name);
  const { methods: scoringMethods, onUpdate } = useScoreCardHandlers();

  if (!getAssociatedSurveyProgress.isSettled) {
    return <CenteredSpinner />;
  }

  return (
    <Accordion.Container
      defaultExpanded={false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Controls.Form
        autoComplete='off'
        noValidate
        methods={scoringMethods}
        submitHandler={noop}
      >
        <Accordion.Summary
          id={`${themeName}-accordion`}
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid container spacing={3}>
            <Grid item md={2}>
              <Box display={'flex'} alignItems='center' p={2}>
                <Typography>{themeName}</Typography>
              </Box>
            </Grid>
            <Grid item md={2}>
              <ScoreInput
                name={`partialScores[${themeIndex}].scoringMeta.score`}
                decimal={false}
                key={themePartialScore.score}
                defaultValue={MathUtils.precisionRound(
                  themePartialScore.score,
                  { precision: 0 }
                )}
                additionalUpdate={{
                  [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                    'MANUAL_SCORING',
                }}
                readOnly={!themePartialScore.configuration?.enabledThemeScoring}
              />
            </Grid>
            <Grid item md={2}>
              <FormCompanyWeightSelect
                id={`${themePartialScore.theme.code}-theme-weight`}
                defaultWeight={themePartialScore.scoringMeta.weight}
                onChange={(value) => {
                  onUpdate(
                    {
                      [`partialScores[${themeIndex}].scoringMeta.weight`]:
                        value,
                    },
                    scoreCard.id
                  );
                }}
                readOnly={!themePartialScore.configuration?.enabledThemeScoring}
              />
            </Grid>
            <Grid item md={2}>
              <Box display={'flex'} alignItems='center' p={2}>
                <Typography>{t('Answers')}</Typography>
                {` ( ${
                  themesInsights?.[themePartialScore.theme.id]
                    ?.answersInsights || 0
                } ) `}
              </Box>
            </Grid>
            <Grid item md={2}>
              {themePartialScore.configuration?.configurableThemeScoring && (
                <ConfigurableThemeScoring
                  {...{
                    themePartialScore,
                    themeIndex,
                  }}
                />
              )}
            </Grid>
            <Grid item md={2}>
              <Box display={'flex'} alignItems='center' p={2}>
                <Typography>{t('Evidences')}</Typography>
                <EvaluationInsights
                  evaluationProgress={
                    themesInsights?.[themePartialScore.theme.id]
                      ?.referencesInsights || DEFAULT_INSIGHTS
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Accordion.Summary>
      </Controls.Form>
      <Accordion.Details style={{ display: 'grid', padding: 0 }}>
        {children}
      </Accordion.Details>
    </Accordion.Container>
  );
};

export default memo(AnswersAnalysisThemeAccordion);
