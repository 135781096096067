import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { clsx } from 'clsx';

import { useAvailableCurrencies, useFetchDiscountEnums } from '@core/queries';

import { IDiscountTypesEnum } from '@core/enums';

import { useEssentials } from '@core/contexts';

import { Controls, Grid, FormGroup } from '@shared/components';

import { makeStyles } from '@shared/utils';

const useStyles = makeStyles((theme) => ({
  form: {
    border: '1px solid #888888',
    borderRadius: '2px',
    padding: '1em',
  },
  disabled: {
    opacity: 0.4,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface Props {
  name: string;
  readOnly: boolean;
}

enum YearIndexEnum {
  Year1 = '1_YEAR',
  Year3 = '3_YEAR',
}

const Discount: React.FCC<Props> = ({ name, readOnly }) => {
  const classes = useStyles();
  const { t } = useEssentials();
  const { formattedCurrencies } = useAvailableCurrencies();
  const { discountEnums } = useFetchDiscountEnums();
  const methods = useFormContext();
  const watchedTypes: Record<YearIndexEnum, IDiscountTypesEnum> = {
    '1_YEAR': useWatch({
      name: `${name}.1_YEAR.type`,
    }),
    '3_YEAR': useWatch({
      name: `${name}.3_YEAR.type`,
    }),
  };

  const watchedEnabled: Record<YearIndexEnum, boolean> = {
    '1_YEAR': useWatch({
      name: `${name}.1_YEAR.enabled`,
    }),
    '3_YEAR': useWatch({
      name: `${name}.3_YEAR.enabled`,
    }),
  };

  return (
    <Grid className={clsx(classes.form, { [classes.disabled]: readOnly })}>
      {['1', '3'].map((period) => {
        const periodYear = `${period}_YEAR` as YearIndexEnum;
        return (
          <Grid key={period} container spacing={3} padding={1}>
            <Grid item md={3} xs={12}>
              <Controls.FormCheckbox
                label={t(`${period} Year`)}
                name={`${name}.${periodYear}.enabled`}
                disabled={readOnly}
                required={!readOnly}
                onChange={(_event, checked) => {
                  if (checked) {
                    methods.setValue(
                      `${name}.${periodYear}.period`,
                      `${period} year`
                    );
                  }
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  label={t('Type')}
                  options={discountEnums?.types ?? []}
                  name={`${name}.${periodYear}.type`}
                  disabled={readOnly || !watchedEnabled[periodYear]}
                  required={!readOnly}
                  onChange={(event) => {
                    if (event.target.value === IDiscountTypesEnum.TYPE_AMOUNT) {
                      methods.setValue(`${name}.${periodYear}.price`, {
                        value: 0,
                        currency: 'EUR',
                        decimals: 2,
                      });
                      methods.setValue(
                        `${name}.${periodYear}.percentage`,
                        undefined
                      );
                    }
                    if (
                      event.target.value === IDiscountTypesEnum.TYPE_PERCENTAGE
                    ) {
                      methods.setValue(
                        `${name}.${periodYear}.price`,
                        undefined
                      );
                      methods.setValue(`${name}.${periodYear}.percentage`, 0);
                    }
                    return event.target.value;
                  }}
                />
              </FormGroup>
            </Grid>

            {watchedTypes[`${periodYear}`] ===
              IDiscountTypesEnum.TYPE_PERCENTAGE && (
              <Grid item md={3} xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    label={t('Discount')}
                    name={`${name}.${periodYear}.percentage`}
                    min={0}
                    max={100}
                    type='number'
                    required
                    disabled={readOnly || !watchedEnabled[periodYear]}
                    readOnly={readOnly || !watchedEnabled[periodYear]}
                  />
                </FormGroup>
              </Grid>
            )}
            {watchedTypes[`${periodYear}`] ===
              IDiscountTypesEnum.TYPE_AMOUNT && (
              <>
                <Grid item md={3} xs={12}>
                  <FormGroup>
                    <Controls.FormInput
                      label={t('Discount')}
                      name={`${name}.${periodYear}.price.value`}
                      type='number'
                      min={0}
                      required
                      disabled={readOnly || !watchedEnabled[periodYear]}
                      readOnly={readOnly || !watchedEnabled[periodYear]}
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={2} xs={12}>
                  <FormGroup>
                    <Controls.FormSelect
                      label={t('Currency')}
                      name={`${name}.${periodYear}.price.currency`}
                      options={formattedCurrencies.filter(
                        (currency) => currency.label === 'Euro'
                      )}
                      required
                      disabled={readOnly || !watchedEnabled[periodYear]}
                      readOnly={readOnly || !watchedEnabled[periodYear]}
                    />
                  </FormGroup>
                </Grid>
              </>
            )}
            <Controls.FormInput
              label={t('Discount')}
              name={`${name}.${periodYear}.period`}
              style={{ display: 'none' }}
              type='number'
              required
              defaultValue={period === '1' ? '1 year' : '3 years'}
              readOnly={true}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Discount;
