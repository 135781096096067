import { ApiFactory } from '@inteliam/foundation/lib/api';
import { QueryString } from '@inteliam/foundation/lib/utils';

import { isISsqQuestionnaireDefinition } from '@core/guards';
import type {
  ISsqQuestionnaireDefinition,
  ISsqQuestionnaireDefinitionPayload,
} from '@core/types';

import {
  getAuthClientInstance,
  Constants,
  DatatableUtils,
} from '@shared/utils';

import type {
  IAnyEnterprise,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type QuestionnaireDefinitionActions =
  | 'getOneById'
  | 'update'
  | 'create'
  | 'patch';

const baseSsqQuestionnaireDefinitionsApi = ApiFactory.generate<
  ISsqQuestionnaireDefinition,
  JwtBOUser,
  QuestionnaireDefinitionActions,
  ISsqQuestionnaireDefinitionPayload,
  ISsqQuestionnaireDefinitionPayload
>({
  baseRoute: '/ssq-questionnaire-definitions',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),

  guard: isISsqQuestionnaireDefinition,
});

const paginate = async (
  manufacturerId: IAnyEnterprise['id'],
  paginator: IPaginationQuery
): Promise<IPaginationResponse<ISsqQuestionnaireDefinition>> => {
  const query: IPaginationQuery = {
    ...paginator,
    primaryCriteria: {
      conditions: [
        DatatableUtils.createCondition('manufacturer.id', 'eq', manufacturerId),
      ],
      operator: 'and',
    },
  };
  const { data } = await baseSsqQuestionnaireDefinitionsApi.client.instance.get<
    IPaginationResponse<ISsqQuestionnaireDefinition>
  >('', {
    params: query,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });
  return data;
};

export default {
  ...baseSsqQuestionnaireDefinitionsApi.actions,
  paginate,
};
