import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';
import { isNil } from 'lodash-es';

import type { ITranslatableFieldForm } from '@core/types';

import type {
  ObligatorinessValue,
  QuestionPublication,
} from '@inteliam/foundation/lib/types';

export function getObligatorinessTranslation(
  obligatoriness: ObligatorinessValue
): string {
  switch (obligatoriness) {
    case 'no': {
      return 'no';
    }
    case 'required': {
      return 'required';
    }
    case 'optional': {
      return 'optional';
    }
    default: {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      return 'N/A';
    }
  }
}
export function getPublicationTranslation(status: QuestionPublication): string {
  switch (status) {
    case 'draft': {
      return 'draft';
    }
    case 'published': {
      return 'published';
    }
    default: {
      return 'N/A';
    }
  }
}

export const getTranslatedFormValue = (
  value?: string | ITranslatableFieldForm,
  locale?: ILanguagesEnum
): string => {
  if (isNil(value)) {
    return '';
  }
  if (typeof value === 'string') {
    return value;
  }
  if (locale) {
    return value.translations[locale] || '';
  }
  return value.defaultValue || '';
};
