import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls } from '@shared/components';

import type { FormCountrySelectProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const CountryFilterer: React.FCC<
  FormCountrySelectProps & { defaultItem: IFilterDefaultItem }
> = (props) => {
  return (
    <Controls.FormCountrySelect {...omit(props, ['defaultItem'])} required />
  );
};

export default CountryFilterer;
