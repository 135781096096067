/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/no-identical-functions */
import {
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  DEFAULT_QUERY_OPTIONS,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { AssessmentRequestsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type {
  IARNextActionForm,
  IAssignUserInput,
  IBOAssessmentRequest,
  ISurveyProgress,
} from '@core/types';

import type {
  IAPIResponse,
  IPatchObject,
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  Id,
  IAR,
} from '@inteliam/foundation/lib/types';

type ManageQuery = (parameters: {
  getOneById?: {
    args: {
      id: string;
    };
    options?: InteliamUseQueryOptions<
      IAPIResponse<IBOAssessmentRequest>,
      BaseAxiosErrorResponse
    >;
  };
  getAssociatedSurveyProgress?: {
    args: {
      id: string;
    };
    options?: InteliamUseQueryOptions<
      IAPIResponse<ISurveyProgress>,
      BaseAxiosErrorResponse
    >;
  };
  getAllByCompany?: {
    args: {
      id?: string;
      excludeQueuedAndReuse?: boolean;
    };
    options?: InteliamUseQueryOptions<
      IAPIResponse<IBOAssessmentRequest[]>,
      BaseAxiosErrorResponse
    >;
  };
  update?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<Partial<IBOAssessmentRequest>>,
      BaseAxiosErrorResponse,
      IPatchObject<Partial<IBOAssessmentRequest>>
    >;
  };
  patch?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  resetAutoSia?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IAR['id']
    >;
  };
  tag?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  updateNextAction?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IARNextActionForm & { id: IAR['id'] }
    >;
  };
  authorizeSurveySubmission?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<IBOAssessmentRequest>,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  extendDeadlines?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<IBOAssessmentRequest>,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  updateDeadlines?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<IBOAssessmentRequest>,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  reinviteToSurvey?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      BaseAxiosErrorResponse,
      Id
    >;
  };
  assignUser?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<IBOAssessmentRequest>,
      BaseAxiosErrorResponse,
      IAssignUserInput
    >;
  };
  accessToScoreFile?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse<void>,
      AxiosValidationErrorResponse,
      Id
    >;
  };
}) => {
  getOneById: InteliamUseQueryResult<
    IAPIResponse<IBOAssessmentRequest>,
    BaseAxiosErrorResponse
  >;
  getAssociatedSurveyProgress: InteliamUseQueryResult<
    IAPIResponse<ISurveyProgress>,
    BaseAxiosErrorResponse
  >;
  getAllByCompany: InteliamUseQueryResult<
    IAPIResponse<IBOAssessmentRequest[]>,
    BaseAxiosErrorResponse
  >;
  update: InteliamUseMutationResult<
    IAPIResponse<Partial<IBOAssessmentRequest>>,
    BaseAxiosErrorResponse,
    IPatchObject<Partial<IBOAssessmentRequest>>
  >;

  patch: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  resetAutoSia: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IAR['id']
  >;
  tag: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  updateNextAction: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IARNextActionForm & { id: IAR['id'] }
  >;
  authorizeSurveySubmission: InteliamUseMutationResult<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  extendDeadlines: InteliamUseMutationResult<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >;

  updateDeadlines: InteliamUseMutationResult<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  reinviteToSurvey: InteliamUseMutationResult<
    IAPIResponse,
    BaseAxiosErrorResponse,
    Id
  >;
  assignUser: InteliamUseMutationResult<
    IAPIResponse<IBOAssessmentRequest>,
    BaseAxiosErrorResponse,
    IAssignUserInput
  >;
  accessToScoreFile: InteliamUseMutationResult<
    IAPIResponse<void>,
    AxiosValidationErrorResponse,
    Id
  >;
};

const useManageAssessmentRequest: ManageQuery = (args) => {
  const { t, notify } = useEssentials();

  const authorizeSurveySubmissionMutation = useMutation<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >(AssessmentRequestsApi.authorizeSurveySubmission, {
    ...args.authorizeSurveySubmission?.options,
    onSuccess: (...successArgs) => {
      notify({
        message: t('Survey submission was successfully authorized'),
        type: INotifierEnum.Success,
      });
      return args.authorizeSurveySubmission?.options?.onSuccess?.(
        ...successArgs
      );
    },
    onError: (...errorArgs) => {
      notify({
        message: t(
          errorArgs[0]?.response?.data.message ||
            'Something went wrong when authorizing the survey submission'
        ),
        type: INotifierEnum.Error,
      });
      return args.authorizeSurveySubmission?.options?.onError?.(...errorArgs);
    },
  });

  const getOneByIdQuery = useQuery<
    IAPIResponse<IBOAssessmentRequest>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.ASSESSMENT_REQUEST, args.getOneById?.args.id],
    () =>
      args.getOneById?.args.id
        ? AssessmentRequestsApi.getOneById(args.getOneById?.args.id)
        : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      enabled: Boolean(args.getOneById?.args.id),
      refetchOnMount: false,
      retryOnMount: false,
      initialData: undefined,
      cacheTime: 1000 * 20,
      staleTime: 1000 * 20,
      ...args.getOneById?.options,
    }
  );

  const updateMutation = useMutation<
    IAPIResponse<Partial<IBOAssessmentRequest>>,
    BaseAxiosErrorResponse,
    IPatchObject<Partial<IBOAssessmentRequest>>
  >(AssessmentRequestsApi.update, {
    ...args?.update?.options,
    onSuccess: (...onSuccessArgs) => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
      return args.update?.options?.onSuccess?.(...onSuccessArgs);
    },
  });

  const extendDeadlinesMutation = useMutation<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >(AssessmentRequestsApi.extendDeadlines, {
    ...args.extendDeadlines?.options,
    onSuccess: (...successArgs) => {
      notify({
        message: t('The deadline was successfully updated'),
        type: INotifierEnum.Success,
      });
      return args.extendDeadlines?.options?.onSuccess?.(...successArgs);
    },
    onError: (...errorArgs) => {
      notify({
        message: t(
          errorArgs[0]?.response?.data.message ||
            'modals.update_assessment_request_next_deadline.messages.error'
        ),
        type: INotifierEnum.Error,
      });
      return args.extendDeadlines?.options?.onError?.(...errorArgs);
    },
  });

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(AssessmentRequestsApi.patch, {
    ...args?.patch?.options,
    onSuccess: (...onSuccessArgs) => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
      return args.patch?.options?.onSuccess?.(...onSuccessArgs);
    },
  });

  const resetAutoSiaMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IAR['id']
  >(AssessmentRequestsApi.resetAutoSia, {
    ...args?.resetAutoSia?.options,
    onSuccess: (...onSuccessArgs) => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
      return args.resetAutoSia?.options?.onSuccess?.(...onSuccessArgs);
    },
  });

  const tagMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(AssessmentRequestsApi.tag, {
    ...args?.tag?.options,
    onSuccess: (...onSuccessArgs) => {
      notify({
        message: t('Tag applied.'),
        type: INotifierEnum.Success,
      });
      return args.tag?.options?.onSuccess?.(...onSuccessArgs);
    },
  });
  const updateNextActionMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IARNextActionForm & { id: IAR['id'] }
  >(AssessmentRequestsApi.updateNextAction, {
    ...args?.updateNextAction?.options,
    onSuccess: (...onSuccessArgs) => {
      notify({
        message: t('Next action updated.'),
        type: INotifierEnum.Success,
      });
      return args.updateNextAction?.options?.onSuccess?.(...onSuccessArgs);
    },
  });

  const updateDeadlinesMutation = useMutation<
    IAPIResponse<IBOAssessmentRequest>,
    AxiosValidationErrorResponse,
    IPatchObject
  >(AssessmentRequestsApi.updateDeadlines, {
    ...args.updateDeadlines?.options,
  });

  const reinviteToSurveyMutation = useMutation<
    IAPIResponse,
    BaseAxiosErrorResponse,
    Id
  >(AssessmentRequestsApi.reinviteToSurvey, {
    ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
    ...args?.reinviteToSurvey?.options,
    onSuccess: () => {
      notify({
        message: t('The distributor was successfully reinvited'),
        type: INotifierEnum.Success,
      });
    },
    onError: (error) => {
      notify({
        message: t(
          error?.response?.data.message ||
            t('The distributor was successfully reinvited')
        ),
        type: INotifierEnum.Error,
      });
    },
  });

  const assignUserMutation = useMutation<
    IAPIResponse<IBOAssessmentRequest>,
    BaseAxiosErrorResponse,
    IAssignUserInput
  >(AssessmentRequestsApi.assignUser, {
    ...args.assignUser?.options,
    onSuccess: (...successArgs) => {
      notify({
        message: t('Assessment request status successfully updated'),
        type: INotifierEnum.Success,
      });

      return args.assignUser?.options?.onSuccess?.(...successArgs);
    },
  });

  const accessToScoreFileMutation = useMutation<
    IAPIResponse<void>,
    AxiosValidationErrorResponse,
    Id
  >(AssessmentRequestsApi.getAccessToScoreFile, {
    ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
    ...args?.accessToScoreFile?.options,
    onSuccess: (...successArgs) => {
      notify({
        message: t(
          "You will receive an email containing the link to the score file. Please note that the data in the score file isn't automatically updated, in order to get the most recent data, please make another request."
        ),
        type: INotifierEnum.Success,
      });
      return args.accessToScoreFile?.options?.onSuccess?.(...successArgs);
    },
  });

  const getAssociatedSurveyProgressQuery = useQuery<
    IAPIResponse<ISurveyProgress>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.SURVEY_PROGRESS, args.getAssociatedSurveyProgress?.args.id],
    () => {
      return args.getAssociatedSurveyProgress?.args.id
        ? AssessmentRequestsApi.getQuestionnaireProgress(
            args.getAssociatedSurveyProgress?.args.id
          )
        : Promise.reject();
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: Boolean(args.getAssociatedSurveyProgress?.args.id),
    }
  );

  const getAllByCompanyQuery = useQuery<
    IAPIResponse<Array<IBOAssessmentRequest>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.DASHBOARD.ENGAGEMENT, args.getAllByCompany?.args.id],
    () => {
      return args.getAllByCompany?.args.id
        ? AssessmentRequestsApi.getAllARsByCompany({
            id: args.getAllByCompany?.args.id,
            excludeQueuedAndReuse:
              args.getAllByCompany?.args.excludeQueuedAndReuse || false,
          })
        : Promise.reject();
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: Boolean(args.getAllByCompany?.args.id),
    }
  );

  return {
    getOneById: getOneByIdQuery,
    update: updateMutation,

    patch: patchMutation,
    authorizeSurveySubmission: authorizeSurveySubmissionMutation,
    extendDeadlines: extendDeadlinesMutation,
    updateDeadlines: updateDeadlinesMutation,
    reinviteToSurvey: reinviteToSurveyMutation,
    assignUser: assignUserMutation,
    accessToScoreFile: accessToScoreFileMutation,
    getAssociatedSurveyProgress: getAssociatedSurveyProgressQuery,
    getAllByCompany: getAllByCompanyQuery,
    resetAutoSia: resetAutoSiaMutation,
    tag: tagMutation,
    updateNextAction: updateNextActionMutation,
  };
};
export default useManageAssessmentRequest;
