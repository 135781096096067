/* eslint-disable @typescript-eslint/no-explicit-any */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { SnackbarOrigin, Typography, Snackbar } from '@mui/material';
import { Styles, withStyles } from '@mui/styles';

import * as React from 'react';
import Dropzone, { DropEvent, ErrorCode, FileRejection } from 'react-dropzone';

import { clsx } from 'clsx';

import type { ResourceReaderFunction, Theme } from '@shared/types';

import type { IResource } from '@inteliam/foundation/lib/types';

import { convertBytesToMbsOrKbs, isImage, readFile } from './helpers';
import PreviewList from './preview-list';
import SnackbarContentWrapper from './snackbar-content-wrapper';

const styles: Styles<Theme, any> = ({ palette, shape, spacing }: Theme) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  'root': {
    position: 'relative',
    width: '100%',
    minHeight: '80px',
    backgroundColor: palette.background.paper,
    border: 'dashed',
    borderColor: palette.divider,
    borderRadius: shape.borderRadius,
    boxSizing: 'border-box',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  'active': {
    animation: '$progress 2s linear infinite !important',
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.background.paper}, ${palette.background.paper} 25px, ${palette.divider} 25px, ${palette.divider} 50px)`,
    backgroundSize: '150% 100%',
    border: 'solid',
    borderColor: palette.primary.light,
  },
  'invalid': {
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${palette.error.light}, ${palette.error.light} 25px, ${palette.error.dark} 25px, ${palette.error.dark} 50px)`,
    borderColor: palette.error.main,
  },
  'textContainer': {
    textAlign: 'center',
  },
  'text': {
    marginBottom: spacing(3),
    marginTop: spacing(3),
  },
  'icon': {
    width: 51,
    height: 51,
    color: palette.text.primary,
  },
});

const defaultSnackbarAnchorOrigin: SnackbarOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
};

const defaultGetPreviewIcon = (
  fileObject: FileObjectShape,
  classes: Record<string, string>
): React.ReactNode => {
  if (isImage(fileObject.file)) {
    return (
      <img
        className={classes.image}
        role='presentation'
        src={fileObject.data}
      />
    );
  }

  return <AttachFileIcon className={classes.image} />;
};

const DEFAULTS = {
  getFileLimitExceedMessage: (filesLimit: number) =>
    `Maximum allowed number of files exceeded. Only ${filesLimit} allowed`,
  getFileAddedMessage: (fileName: string): string =>
    `File ${fileName} successfully added.`,
  getDropRejectMessage: (
    rejectedFile: FileRejection,
    maxFileSize: number
  ): string => {
    let message = `File ${rejectedFile.file.name} was rejected. `;

    rejectedFile.errors.forEach((error) => {
      switch (error.code) {
        case ErrorCode.FileTooLarge: {
          message +=
            'File is too big. Size limit is ' +
            convertBytesToMbsOrKbs(maxFileSize) +
            '. ';
          break;
        }
        case ErrorCode.FileInvalidType: {
          message += 'File type not supported. ';
          break;
        }
        // case ErrorCode.FileTooSmall:
        // case ErrorCode.TooManyFiles:
        default: {
          break;
        }
      }
    });
    return message;
  },
  getFileRemovedMessage: (fileName: string) => `File ${fileName} removed.`,
};

export interface DropzoneAreaBaseProps {
  /** @ignore */
  classes?: Record<string, string>;
  /** A list of file types to accept.
   * @see See [here](https://react-dropzone.js.org/#section-accepting-specific-file-types) for more details.
   */
  acceptedFiles?: string[];
  /** Maximum number of files that can be loaded into the dropzone. */
  filesLimit?: number;
  /** Icon to be displayed inside the dropzone area. */
  Icon?: React.ElementType;
  /** Currently loaded files. */
  fileObjects?: FileObjectShape[];
  /** Maximum file size (in bytes) that the dropzone will accept. */
  maxFileSize?: number;
  /** Text inside the dropzone. */
  dropzoneText?: string;
  /** Custom CSS class name for dropzone container. */
  dropzoneClass?: string;
  /** Custom CSS class name for text inside the container. */
  dropzoneParagraphClass?: string;
  /** Disable feedback effect when dropping rejected files. */
  disableRejectionFeedback?: boolean;
  /** Shows previews **BELOW** the dropzone. */
  showPreviews?: boolean;
  /** Shows preview **INSIDE** the dropzone area. */
  showPreviewsInDropzone?: boolean;
  /** Shows file name under the dropzone image. */
  showFileNames?: boolean;
  /** Shows file name under the image. */
  showFileNamesInPreview?: boolean;
  /** Uses deletable Material-UI Chip components to display file names. */
  useChipsForPreview?: boolean;
  /**
   * Props to pass to the Material-UI Chip components.<br/>Requires `useChipsForPreview` prop to be `true`.
   *
   * @see See [Material-UI Chip](https://material-ui.com/api/chip/#props) for available values.
   */
  previewChipProps?: Record<string, any>;
  /**
   * Custom CSS classNames for preview Grid components.<br/>
   * Should be in the form {container: string, item: string, image: string}.
   */
  previewGridClasses?: Record<string, string>;
  /**
   * Props to pass to the Material-UI Grid components.<br/>
   * Should be in the form {container: GridProps, item: GridProps}.
   *
   * @see See [Material-UI Grid](https://material-ui.com/api/grid/#props) for available GridProps values.
   */
  previewGridProps?: Record<string, any>;
  /** The label for the file preview section. */
  previewText?: string;
  /**
   * Shows styled Material-UI Snackbar when files are dropped, deleted or rejected.
   *
   * - can be a boolean ("global" `true` or `false` for all alerts).
   * - can be an array, with values 'error', 'info', 'success' to select to view only certain alerts:
   *  - showAlerts={['error']} for only errors.
   *  - showAlerts={['error', 'info']} for both errors and info.
   *  - showAlerts={['error', 'success', 'info']} is same as showAlerts={true}.
   *  - showAlerts={[]} is same as showAlerts={false}.
   */
  showAlerts?: boolean | ('error' | 'success' | 'info')[];
  /**
   * Props to pass to the Material-UI Snackbar components.<br/>Requires `showAlerts` prop to be `true`.
   *
   * @see See [Material-UI Snackbar](https://material-ui.com/api/snackbar/#props) for available values.
   */
  alertSnackbarProps?: Record<string, any>;
  /**
   * Props to pass to the Dropzone component.
   *
   * @see See [Dropzone props](https://react-dropzone.js.org/#src) for available values.
   */
  dropzoneProps?: Record<string, any>;
  /**
   * Attributes applied to the input element.
   *
   * @see See [MDN Input File attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Additional_attributes) for available values.
   */
  inputProps?: Record<string, any>;
  /**
   * Get alert message to display when files limit is exceed.
   *
   * *Default*: "Maximum allowed number of files exceeded. Only ${filesLimit} allowed"
   *
   * @param {number} filesLimit The `filesLimit` currently set for the component.
   */
  getFileLimitExceedMessage?(...args: unknown[]): unknown;
  /**
   * Get alert message to display when a new file is added.
   *
   * *Default*: "File ${fileName} successfully added."
   *
   * @param {string} fileName The newly added file name.
   */
  getFileAddedMessage?(...args: unknown[]): unknown;
  /**
   * Get alert message to display when a file is removed.
   *
   * *Default*: "File ${fileName} removed."
   *
   * @param {string} fileName The name of the removed file.
   */
  getFileRemovedMessage?(...args: unknown[]): unknown;
  /**
   * Get alert message to display when a file is rejected onDrop.
   *
   * *Default*: "File ${rejectedFile.name} was rejected."
   *
   * @param {Object} rejectedFile The file that got rejected
   * @param {string[]} acceptedFiles The `acceptedFiles` prop currently set for the component
   * @param {number} maxFileSize The `maxFileSize` prop currently set for the component
   */
  getDropRejectMessage?(...args: unknown[]): unknown;
  /**
   * A function which determines which icon to display for a file preview.
   *
   * *Default*: If its an image then displays a preview the image, otherwise it will display an attachment icon
   *
   * @param {FileObject} objectFile The file which the preview will belong to
   * @param {Object} classes The classes for the file preview icon, in the default case we use the 'image' className.
   */
  getPreviewIcon?(...args: unknown[]): React.ReactNode;
  /**
   * Fired when new files are added to dropzone.
   *
   * @param {FileObject[]} newFiles The new files added to the dropzone.
   */
  onAdd?(...args: unknown[]): unknown;
  /**
   * Fired when a file is deleted from the previews panel.
   *
   * @param {FileObject} deletedFileObject The file that was removed.
   * @param {number} index The index of the removed file object.
   */
  onDelete?(fileObject: FileObjectShape, index: number): unknown;
  /**
   * Fired when the user drops files into the dropzone.
   *
   * @param {File[]} droppedFiles All the files dropped into the dropzone.
   * @param {Event} event The react-dropzone drop event.
   */
  onDrop?(...args: unknown[]): unknown;
  /**
   * Fired when a file is rejected because of wrong file type, size or goes beyond the filesLimit.
   *
   * @param {File[]} rejectedFiles All the rejected files.
   * @param {Event} event The react-dropzone drop event.
   */
  onDropRejected?(...args: unknown[]): unknown;
  /**
   * Fired when an alert is triggered.
   *
   * @param {string} message Alert message.
   * @param {string} variant One of "error", "info", "success".
   */
  onAlert?(...args: unknown[]): unknown;
  resourceReader: ResourceReaderFunction;
}

type State = {
  openSnackBar: boolean;
  snackbarMessage: string;
  snackbarVariant: 'success' | 'info' | 'error';
};
/**
 * This components creates a Material-UI Dropzone, with previews and snackbar notifications.
 */
class DropzoneAreaBase extends React.Component<DropzoneAreaBaseProps> {
  state: State = {
    openSnackBar: false,
    snackbarMessage: '',
    snackbarVariant: 'success',
  };

  notifyAlert() {
    const { onAlert } = this.props;
    const { openSnackBar, snackbarMessage, snackbarVariant } = this.state;
    if (openSnackBar && onAlert) {
      onAlert(snackbarMessage, snackbarVariant);
    }
  }

  handleDropAccepted = async (
    acceptedFiles: Array<File>,
    event_: DropEvent
  ) => {
    const {
      fileObjects = [],
      filesLimit = 3,
      getFileAddedMessage = DEFAULTS.getFileAddedMessage,
      getFileLimitExceedMessage = DEFAULTS.getFileLimitExceedMessage,
      onAdd,
      onDrop,
    } = this.props;

    if (
      filesLimit > 1 &&
      fileObjects.length + acceptedFiles.length > filesLimit
    ) {
      this.setState(
        {
          openSnackBar: true,
          snackbarMessage: getFileLimitExceedMessage(filesLimit),
          snackbarVariant: 'error',
        },
        this.notifyAlert
      );
      return;
    }

    // Notify Drop event
    if (onDrop) {
      onDrop(acceptedFiles, event_);
    }

    // Retrieve fileObjects data
    const fileObjs = await Promise.all(
      acceptedFiles.map(async (file) => {
        const data = await readFile(file);
        return {
          file,
          data,
        };
      })
    );

    // Notify added files
    if (onAdd) {
      onAdd(fileObjs);
    }

    // Display message
    const message = fileObjs.reduce(
      (message_, fileObject: FileObjectShape) =>
        `${message_} ${getFileAddedMessage(fileObject.file.name) as string}`,
      ''
    );
    this.setState(
      {
        openSnackBar: true,
        snackbarMessage: message,
        snackbarVariant: 'success',
      },
      this.notifyAlert
    );
  };

  handleDropRejected = (
    rejectedFiles: Array<FileRejection>,
    event_: DropEvent
  ) => {
    const {
      filesLimit = 3,
      fileObjects = [],
      getDropRejectMessage = DEFAULTS.getDropRejectMessage,
      getFileLimitExceedMessage = DEFAULTS.getFileLimitExceedMessage,
      maxFileSize,
      onDropRejected,
    } = this.props;

    let message = '';
    if (fileObjects.length + rejectedFiles.length > filesLimit) {
      message = getFileLimitExceedMessage(filesLimit) as string;
    } else {
      rejectedFiles.forEach((rejectedFile) => {
        message = getDropRejectMessage(rejectedFile, maxFileSize) as string;
      });
    }

    if (onDropRejected) {
      onDropRejected(rejectedFiles, event_);
    }

    this.setState(
      {
        openSnackBar: true,
        snackbarMessage: message,
        snackbarVariant: 'error',
      },
      this.notifyAlert
    );
  };

  handleRemove = (fileIndex: number) => (event: React.MouseEvent) => {
    event.stopPropagation();

    const {
      fileObjects = [],
      getFileRemovedMessage = DEFAULTS.getFileRemovedMessage,
      onDelete,
    } = this.props;

    // Find removed fileObject
    const removedFileObject = fileObjects[fileIndex];

    // Notify removed file
    if (onDelete) {
      onDelete(removedFileObject, fileIndex);
    }

    this.setState(
      {
        openSnackBar: true,
        snackbarMessage: getFileRemovedMessage(removedFileObject.file.name),
        snackbarVariant: 'info',
      },
      this.notifyAlert
    );
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const {
      acceptedFiles = [],
      alertSnackbarProps = {
        anchorOrigin: defaultSnackbarAnchorOrigin,
        autoHideDuration: 6000,
      },
      classes = {},
      disableRejectionFeedback = false,
      dropzoneClass,
      dropzoneParagraphClass,
      dropzoneProps,
      dropzoneText = 'Drag and drop a file here or click',
      fileObjects = [],
      filesLimit = 3,
      getPreviewIcon = defaultGetPreviewIcon,
      Icon,
      inputProps,
      maxFileSize = 3_000_000,
      previewChipProps = {},
      previewGridClasses = {},
      previewGridProps = {},
      previewText = 'Preview',
      showAlerts = true,
      showFileNames,
      showFileNamesInPreview = false,
      showPreviews = false, // By default previews show up under in the dialog and inside in the standalone
      showPreviewsInDropzone = false,
      useChipsForPreview = false,
      resourceReader,
    } = this.props;
    const { openSnackBar, snackbarMessage, snackbarVariant } = this.state;
    const acceptFiles = acceptedFiles?.join(',');
    const isMultiple = filesLimit > 1;
    const previewsVisible = showPreviews && fileObjects.length > 0;
    const previewsInDropzoneVisible =
      showPreviewsInDropzone && fileObjects.length > 0;

    return (
      <React.Fragment>
        <Dropzone
          {...dropzoneProps}
          accept={acceptFiles}
          onDropAccepted={this.handleDropAccepted}
          onDropRejected={this.handleDropRejected}
          maxSize={maxFileSize}
          multiple={isMultiple}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div
              data-cy={inputProps?.name}
              {...getRootProps({
                className: clsx(
                  classes.root,
                  dropzoneClass,
                  isDragActive && classes.active,
                  !disableRejectionFeedback && isDragReject && classes.invalid
                ),
              })}
            >
              <input {...getInputProps(inputProps)} />

              <div className={classes.textContainer}>
                <Typography
                  variant='h5'
                  component='p'
                  className={clsx(classes.text, dropzoneParagraphClass)}
                >
                  {dropzoneText}
                </Typography>
                {Icon ? (
                  <Icon className={classes.icon} />
                ) : (
                  <CloudUploadIcon className={classes.icon} />
                )}
              </div>

              {previewsInDropzoneVisible && (
                <PreviewList
                  fileObjects={fileObjects}
                  handleRemove={this.handleRemove}
                  getPreviewIcon={getPreviewIcon}
                  showFileNames={showFileNames}
                  useChipsForPreview={useChipsForPreview}
                  previewChipProps={previewChipProps}
                  previewGridClasses={previewGridClasses}
                  previewGridProps={previewGridProps}
                  resourceReader={resourceReader}
                />
              )}
            </div>
          )}
        </Dropzone>

        {previewsVisible && (
          <React.Fragment>
            <Typography variant='subtitle1' component='span'>
              {previewText}
            </Typography>

            <PreviewList
              fileObjects={fileObjects}
              handleRemove={this.handleRemove}
              getPreviewIcon={getPreviewIcon}
              showFileNames={showFileNamesInPreview}
              useChipsForPreview={useChipsForPreview}
              previewChipProps={previewChipProps}
              previewGridClasses={previewGridClasses}
              previewGridProps={previewGridProps}
              resourceReader={resourceReader}
            />
          </React.Fragment>
        )}

        {((typeof showAlerts === 'boolean' && showAlerts) ||
          (Array.isArray(showAlerts) &&
            showAlerts.includes(snackbarVariant))) && (
          <Snackbar
            anchorOrigin={defaultSnackbarAnchorOrigin}
            autoHideDuration={6000}
            {...alertSnackbarProps}
            open={openSnackBar}
            onClose={this.handleCloseSnackbar}
          >
            <div>
              <SnackbarContentWrapper
                onClose={this.handleCloseSnackbar}
                variant={snackbarVariant}
                message={snackbarMessage}
              />
            </div>
          </Snackbar>
        )}
      </React.Fragment>
    );
  }
}

export type FileObjectShape = {
  file: {
    name: string;
    size: number;
    type: string;
  };
  data: any;
  resource?: IResource;
};

export default withStyles(styles)(DropzoneAreaBase);
