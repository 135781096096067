import { ApiFactory } from '@inteliam/foundation/lib/api';
import { assertIsTyped } from '@inteliam/foundation/lib/guards';

import { isIBODistributor } from '@core/guards';
import type {
  IBODistributor,
  IDistributorEnums,
  IMergeDistributor,
  IMatchScore,
  ISubscriptionStatusEntry,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAR,
  Id,
  IDistributor,
  IPaginationResponse,
  IPatchObject,
  IScoreCard,
  ISubscriptionType,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type DistributorActions = 'getOneById' | 'patch' | 'paginate' | 'create';

const baseDistributorApi = ApiFactory.generate<
  IBODistributor,
  JwtBOUser,
  DistributorActions
>({
  baseRoute: '/distributors',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIBODistributor,
});

const getOneByIdentifier = async ({
  field,
  value,
  countryCode,
}: {
  field: string;
  value: string;
  countryCode: string;
}): Promise<IAPIResponse<IBODistributor>> => {
  const { data } = await baseDistributorApi.client.instance.get(
    `/find/${field}/${value}/${countryCode}`
  );

  assertIsTyped(data.data, isIBODistributor);
  return data;
};

const getMatches = async (
  id: string
): Promise<IAPIResponse<Array<IMatchScore>>> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IAPIResponse<Array<IMatchScore>>
  >(`/get-matches/${id}`);

  return data;
};

const merge = async (data: IMergeDistributor): Promise<void> => {
  await baseDistributorApi.client.instance.put('/merge', data);
};
export const canEnableUsers = async (id: string): Promise<IAPIResponse> => {
  const { data } = await baseDistributorApi.client.instance.get<IAPIResponse>(
    `/${id}/can-enable-users`
  );
  return data;
};

export const getAvailableSubscriptions = async (): Promise<
  IAPIResponse<Array<ISubscriptionType>>
> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IAPIResponse<Array<ISubscriptionType>>
  >('/available-subscriptions');

  return data;
};

export const getQualificationEnums = async (): Promise<
  IAPIResponse<IDistributorEnums>
> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IAPIResponse<IDistributorEnums>
  >('/enums');
  return data;
};

export const newLogEntry = async ({
  id,
  body,
}: IPatchObject<Partial<ISubscriptionStatusEntry>>): Promise<
  IAPIResponse<ISubscriptionStatusEntry>
> => {
  const { data } = await baseDistributorApi.client.instance.patch<
    IAPIResponse<ISubscriptionStatusEntry>
  >(`${id}/new-log-entry`, body);

  return data;
};

export const getSubscriptionsLog = async (
  id: string
): Promise<IPaginationResponse<ISubscriptionStatusEntry>> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IPaginationResponse<ISubscriptionStatusEntry>
  >(`/${id}/subscriptions-log`);
  return data;
};

export const getARsByCompanyId = async (
  id: string
): Promise<IPaginationResponse<IAR>> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IPaginationResponse<IAR>
  >(`/${id}/assessment-requests`);
  return data;
};

const hasValidScoreCard = async (
  distributorId: Id
): Promise<IAPIResponse<{ valid: boolean }>> => {
  const stringId =
    typeof distributorId === 'number'
      ? distributorId.toString()
      : distributorId;
  const { data } = await baseDistributorApi.client.instance.get(
    `/${stringId}/has-valid-scorecard`
  );
  return data;
};

const getLastPublishedScoreCard = async (
  id: IDistributor['id']
): Promise<IAPIResponse<IScoreCard>> => {
  const { data } = await baseDistributorApi.client.instance.get<
    IAPIResponse<IScoreCard>
  >(`/${id}/last-published-score-card`);

  return data;
};

export default {
  ...baseDistributorApi.actions,
  getOneByIdentifier,
  getMatches,
  merge,
  canEnableUsers,
  getSubscriptionsLog,
  getAvailableSubscriptions,
  newLogEntry,
  getQualificationEnums,
  getARsByCompanyId,
  hasValidScoreCard,
  getLastPublishedScoreCard,
};
