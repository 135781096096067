/* eslint-disable @inteliam/i18n/raw-text-detected */
import { yupResolver } from '@hookform/resolvers/yup';
import { FormatDate } from '@inteliam/foundation/lib/components';
import {
  IARStatusEnum,
  IBORolesEnum,
  IFilterTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useQueryClient } from '@inteliam/foundation/lib/hooks';
import { DateFormatter, PriceUtils } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { useCallback, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
  LogEntryForm,
  LogEntrySchema as schema,
} from '@core/components/distributors/subscription';

import { QUERY_KEYS, useManageSubscriptionEntry } from '@core/queries';

import { UsersUtils, SubscriptionsUtils } from '@core/utils';

import { DistributorsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type {
  ISubscriptionLogDistributor,
  ISubscriptionStatusEntry,
  ISubscriptionStatusEntryFormData,
} from '@core/types';

import {
  Alert,
  Datatable,
  Grid,
  TooltippedCell,
  Typography,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IFilterableColumn } from '@shared/types';

import { BaseDialog } from '../';
import PricingGrid from '../initiate-survey/pricing-grid';

interface Props {
  onClose: () => void;
  distributor: ISubscriptionLogDistributor;
}
const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.anchor?.main,
    textDecoration: 'underline',
    display: 'block',
  },
}));
// todo refactor
const SubscriptionLogModal: React.FCC<Props> = ({ onClose, distributor }) => {
  const { t, notify, user, na } = useEssentials();
  const classes = useStyles();
  const queryClient = useQueryClient();
  const codename =
    distributor.lastAssessmentRequest?.currentStatusMeta?.codename;
  const pastSubmit =
    !isNil(codename) &&
    [
      IARStatusEnum.IN_REVIEW,
      IARStatusEnum.IN_DOC_ANALYSIS,
      IARStatusEnum.IN_SCORING,
      IARStatusEnum.RTA,
      IARStatusEnum.PUBLISHED,
    ].includes(codename);

  const methods = useForm<ISubscriptionStatusEntryFormData>({
    defaultValues: SubscriptionsUtils.normalizer.initializeForm(
      pastSubmit ? undefined : distributor.subscriptionInfo
    ),
    resolver: yupResolver(schema),
  });
  const [gridPriceOpen, setGridPriceOpen] = useState<boolean>(false);
  const { add } = useManageSubscriptionEntry({
    onSuccess: () => {
      notify({
        message: t('Assessment request status successfully updated'),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries([...QUERY_KEYS.DISTRIBUTORS_LIST])
        .catch(() => {});
    },
  });

  const addLogEntry = useCallback(
    (body: ISubscriptionStatusEntryFormData) => {
      add.run(distributor.id, body);
    },
    [add, distributor.id]
  );

  const columns = useMemo<IFilterableColumn<ISubscriptionStatusEntry>[]>(
    () => [
      {
        field: 'subscription',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Subscription'),
        width: 150,
        renderCell: (parameters) => {
          const subscription = parameters.row.subscriptionType?.id;
          return <span>{subscription ? t(subscription) : na}</span>;
        },
      },
      {
        field: 'subscriptionCategory',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Pricing grid'),
        width: 180,
        renderCell: (parameters) => {
          if (parameters.row.pricingCategory?.id) {
            const text = {
              BETWEEN_50_AND_100: 'Between € 50M and € 100M',
              LESS_THAN_50: 'Less than € 50M',
              MORE_THAN_100: 'More than € 100M+',
            }[parameters.row.pricingCategory.id];
            return <span>{t(text || '')}</span>;
          }
        },
      },
      {
        field: 'originalPrice',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Original price'),
        width: 100,
        renderCell: (parameters) => {
          const value = parameters.row.originalPrice?.value;
          return (
            <span>
              {isNil(value)
                ? na
                : `${PriceUtils.getCurrencySign(
                    parameters.row.originalPrice?.currency ?? ''
                  )} ${value}`}
            </span>
          );
        },
      },
      {
        field: 'discount',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Discount'),
        width: 250,
        renderCell: (parameters) => {
          return (
            <ul style={{ padding: '1px' }}>
              <li>{`1 Yr: Inteliam: ${SubscriptionsUtils.getFormattedDiscount(
                parameters.row.inteliamDiscount
              )}, ${
                parameters.row.manufacturerDiscount?.manufacturerName ??
                'Enterprise'
              }: ${SubscriptionsUtils.getFormattedDiscount(
                parameters.row.manufacturerDiscount
              )}`}</li>
              <li>{`3 Yrs: Inteliam: ${SubscriptionsUtils.getFormattedDiscount(
                parameters.row.inteliamDiscount3YearsSubscription
              )}, ${
                parameters.row.manufacturerDiscount3YearsSubscription
                  ?.manufacturerName ?? 'Enterprise'
              }: ${SubscriptionsUtils.getFormattedDiscount(
                parameters.row.manufacturerDiscount3YearsSubscription
              )}`}</li>
            </ul>
          );
        },
      },
      {
        field: 'priceAtSubmit',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Final price'),
        width: 100,
        renderCell: (parameters) => {
          const value = parameters.row.priceAtSubmit?.value;
          return (
            <span>
              {isNil(value)
                ? na
                : `${PriceUtils.getCurrencySign(
                    parameters.row.priceAtSubmit?.currency ?? ''
                  )} ${value}`}
            </span>
          );
        },
      },
      {
        field: 'invoiceStatus',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Invoice Status'),
        width: 150,
        renderCell: (parameters) => {
          return <span>{t(parameters.row.invoiceStatus)}</span>;
        },
      },
      {
        field: 'discountBy',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Discount By'),
        width: 170,
        renderCell: (parameters) => {
          const isOfferedByInteliam =
            parameters.row.inteliamDiscount?.enabled ||
            parameters.row.inteliamDiscount3YearsSubscription?.enabled;
          const isOfferedByEnterprise =
            parameters.row.manufacturerDiscount?.enabled ||
            parameters.row.manufacturerDiscount3YearsSubscription?.enabled;

          let text = '';
          if (isOfferedByInteliam && isOfferedByEnterprise) {
            text = `Inteliam & ${
              parameters.row?.manufacturerDiscount?.manufacturerName ||
              'Enterprise'
            }`;
          } else if (isOfferedByInteliam) {
            text = 'Inteliam';
          } else if (isOfferedByEnterprise) {
            text =
              parameters.row?.manufacturerDiscount?.manufacturerName ??
              'Enterprise';
          } else {
            text = na;
          }

          return (
            <TooltippedCell
              textOverflowHidden
              title={
                <FormatDate
                  date={parameters.row.date}
                  format={DateFormatter.FORMATS.fr_FR.SHORT}
                />
              }
            >
              <span>{text}</span>
            </TooltippedCell>
          );
        },
      },
      {
        field: 'date',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Date'),
        width: 120,
        renderCell: (parameters) => {
          return (
            <FormatDate
              date={parameters.row.date}
              format={DateFormatter.FORMATS.fr_FR.SHORT}
            />
          );
        },
      },
      {
        field: 'comments',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Comments'),
        width: 500,
        renderCell: (parameters) => {
          return (
            <TooltippedCell textOverflowHidden title={parameters.row.comments}>
              {parameters.row.comments}
            </TooltippedCell>
          );
        },
      },
    ],
    [t, na]
  );

  const formProps = {
    handler: addLogEntry,
    errors: add.mutation.error?.response?.data,
    isLoading: add.mutation.isLoading,
  };

  const decomposedValue = PriceUtils.getDecomposedValue(
    distributor.turnover?.value
  );
  return (
    <BaseDialog.Dialog open={true} onClose={onClose} maxWidth='lg' fullScreen>
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {distributor.name} - {t('Subscription Details')}
        </Grid>
        <Alert severity='info'>
          {t(
            pastSubmit
              ? 'You cannot modify the subscription details after questionnaire submit'
              : 'The questionnaire is not yet submitted You can still modify the subscription details. \nThis will still create a new entry in the history.\n This will also pre-fill the form with the old values'
          )}
        </Alert>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <div>
          <Typography>
            {t('Annual gross sales: {{distributorGrossSales}}', {
              distributorGrossSales: isNil(decomposedValue.value)
                ? na
                : `${decomposedValue.value} ${
                    decomposedValue.unit
                  } ${PriceUtils.getCurrencySign(
                    distributor.turnover?.currency ?? ''
                  )}`,
            })}
          </Typography>
          <Typography
            className={classes.link}
            onClick={() => setGridPriceOpen(true)}
          >
            {t('See pricing table')}
          </Typography>
          {gridPriceOpen && (
            <PricingGrid onClose={() => setGridPriceOpen(false)} />
          )}
        </div>
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          {UsersUtils.isGranted(
            IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
            user
          ) && (
            <Grid item xs={12}>
              <LogEntryForm
                methods={methods}
                isLoading={formProps.isLoading}
                onSubmit={formProps.handler}
                errors={formProps.errors}
                distributor={distributor}
                onClose={onClose}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Datatable.Container
              queryKey={QUERY_KEYS.DISTRIBUTOR_SUBSCRIPTIONS_LOGS}
              columns={columns}
              fetcher={() =>
                DistributorsApi.getSubscriptionsLog(distributor.id)
              }
            >
              <div style={{ height: '100%', width: '100%' }}>
                <Datatable.Table autoHeight checkboxSelection={false} />
              </div>
            </Datatable.Container>
          </Grid>
        </Grid>
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default SubscriptionLogModal;
