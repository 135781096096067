import { useDebounce } from '@inteliam/foundation/lib/hooks';

import React, { useState } from 'react';

import { useQuery } from '@core/queries';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

import BaseTypeahead, { TypeaheadProps } from './typeahead';

type RequiredAsyncTypeaheadProps<T extends IOptionItem> = Omit<
  TypeaheadProps<T>,
  'options' | 'onChange'
>;
interface Props<T extends IOptionItem> extends RequiredAsyncTypeaheadProps<T> {
  fetcher: (term: string) => Promise<T[]>;
  onChange: (value: T | T[] | undefined) => void;
  defaultValue?: T | T[] | undefined;
}

const labelCache: Record<string, string> = {};

const AsyncTypeahead = <T extends IOptionItem>({
  fetcher,
  onChange,
  defaultValue = undefined,
  ...rest
}: Props<T>): React.ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');
  const searchQuery = useQuery<T[]>(
    ['typeahead', rest.name, debouncedInputValue],
    () => fetcher(debouncedInputValue),
    {
      enabled: Boolean(debouncedInputValue),
    }
  );
  const [value, setValue] = useState<T | T[] | undefined>(() => {
    // TODO sometimes defaultValue is passed as string and should'nt be the case
    if (typeof defaultValue === 'string' && labelCache[defaultValue]) {
      return {
        value: defaultValue as string,
        label: labelCache[defaultValue],
      } as T;
    }
    return defaultValue;
  });
  useDebounce(
    () => {
      if (inputValue === '' || inputValue.length < 3) {
        return;
      }
      setDebouncedInputValue(inputValue);

      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
    500
  );

  return (
    <BaseTypeahead<T>
      {...rest}
      value={value}
      options={searchQuery.data || []}
      onChange={(_, newValue) => {
        setValue(newValue || undefined);
        onChange(newValue || undefined);
        if (newValue && !Array.isArray(newValue)) {
          labelCache[newValue.value] = newValue.label;
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) =>
        value && option.value === value.value
      }
      getOptionLabel={(option) => option.label ?? ''}
      // includeInputInList
      // filterSelectedOptions
      // filterOptions={(x) => x}
      // autoComplete
      loading={searchQuery.isFetching}
    />
  );
};
export default AsyncTypeahead;
