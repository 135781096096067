import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { useManageAssessmentHighlights } from '@core/queries';

import { DndUtils } from '@core/utils';

import {
  Box,
  DndProvider,
  Typography,
  DragIndicatorSharpIcon,
} from '@shared/components';

type Props = {
  assessmentRequestId: string;
};

const AnalysisHighlightsOrdering: React.FCC<Props> = ({
  assessmentRequestId,
}) => {
  const { getByAR } = useManageAssessmentHighlights({ assessmentRequestId });
  const { reorder, updater } = useManageAssessmentHighlights();

  return (
    <div>
      <DndProvider
        onMove={(indexFrom, indexTo) => {
          reorder.run({
            assessmentHighlightId: getByAR?.assessmentHighlights[indexFrom].id,
            assessmentRequestId,
            body: {
              rank: getByAR?.assessmentHighlights[indexTo].rank,
            },
          });
          // Optimistic update
          const ordered = DndUtils.reorder(
            getByAR?.assessmentHighlights,
            indexFrom,
            indexTo
          );

          updater((data) => {
            if (data?.data)
              return {
                ...data,
                data: ordered,
              };
            return data;
          });
        }}
      >
        {({ getItemStyle }) => (
          <>
            {getByAR?.assessmentHighlights?.map(
              (assessmentHighlight, index) => (
                // TODO on React basics, make id required on partials
                <Draggable
                  key={assessmentHighlight.id}
                  draggableId={assessmentHighlight.id}
                  index={index}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <div
                      key={assessmentHighlight.id}
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      style={getItemStyle(
                        draggableSnapshot.isDragging,
                        draggableProvided.draggableProps.style
                      )}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        alignItems='center'
                        border='2px solid black'
                        borderRadius='4px'
                        width='100%'
                        padding={0.5}
                        marginTop={1}
                      >
                        <Box
                          padding={1}
                          {...(getByAR?.assessmentHighlights.length > 0 &&
                            draggableProvided.dragHandleProps)}
                        >
                          <DragIndicatorSharpIcon opacity='0.3' />
                        </Box>

                        <Typography>
                          {I18n.getTranslatedValue(
                            assessmentHighlight.analysisHighlight.description
                          )}
                        </Typography>
                      </Box>
                    </div>
                  )}
                </Draggable>
              )
            )}
          </>
        )}
      </DndProvider>
    </div>
  );
};
export default React.memo(AnalysisHighlightsOrdering);
