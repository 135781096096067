import {
  IBORolesEnum,
  IARAnalystStatusEnum,
  IAREngagementStatusEnum,
  IARFinalStatusEnum,
  IARPhaseEnum,
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
} from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';
import { addBusinessDays, addDays } from 'date-fns';
import { get, isNil, omit } from 'lodash-es';

import { IDiscountTypesEnum } from '@core/enums';

import type {
  IARDeadlinesForm,
  IExtendSurveyDeadlineForm,
  IExtendSurveyDeadlinePayload,
  IRegistrationState,
  IAssessmentRequestPayload,
  IARDeadlineArrayKey,
  IRankedDeadlines,
  IInitiateSurveyForm,
  IAssessmentRequestForm,
} from '@core/types';

import type {
  DashboardType,
  IAR,
  IBOUser,
  ICampaignType,
  IJSONObject,
  IPartialBOUser,
  IPatchObject,
  IStatus,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

import { ARUtils, DistributorUtils, UsersUtils } from './';

export const getStatusDeadlineKey = (status: IStatus): IARDeadlineArrayKey => {
  return `statuses[${status.rank as IARStatusRankEnum}].deadline`;
};

type SurveyStatuses =
  | IARStatusEnum.ANSWERING_SURVEY
  | IARStatusEnum.IN_REVIEW
  | IARStatusEnum.IN_DOC_ANALYSIS
  | IARStatusEnum.IN_SCORING
  | IARStatusEnum.RTA;
type DefaultDeadlineType = {
  [key in SurveyStatuses]: (args: {
    campaignType: ICampaignType;
    startDate: Date;
    cumulative?: boolean;
  }) => Date;
};

const DEFAULT_DEADLINES: DefaultDeadlineType = {
  [IARStatusEnum.ANSWERING_SURVEY]: ({ campaignType, startDate }) => {
    return addBusinessDays(startDate, campaignType.defaultDeadlines.surveyDays);
  },
  [IARStatusEnum.IN_REVIEW]: ({ campaignType, startDate, cumulative }) => {
    if (!cumulative) {
      return addBusinessDays(
        startDate,
        campaignType.defaultDeadlines.reviewDays
      );
    }
    return addBusinessDays(
      startDate,
      campaignType.defaultDeadlines.surveyDays +
        campaignType.defaultDeadlines.reviewDays
    );
  },
  [IARStatusEnum.IN_DOC_ANALYSIS]: ({
    campaignType,
    startDate,
    cumulative,
  }) => {
    if (!cumulative) {
      return addBusinessDays(
        startDate,
        campaignType.defaultDeadlines.docAnalysisDays
      );
    }

    return addBusinessDays(
      startDate,
      campaignType.defaultDeadlines.surveyDays +
        campaignType.defaultDeadlines.reviewDays +
        campaignType.defaultDeadlines.docAnalysisDays
    );
  },
  [IARStatusEnum.IN_SCORING]: ({ campaignType, startDate, cumulative }) => {
    if (!cumulative) {
      return addBusinessDays(
        startDate,
        campaignType.defaultDeadlines.scoringDays
      );
    }
    return addBusinessDays(
      startDate,
      campaignType.defaultDeadlines.surveyDays +
        campaignType.defaultDeadlines.reviewDays +
        campaignType.defaultDeadlines.docAnalysisDays +
        campaignType.defaultDeadlines.scoringDays
    );
  },
  [IARStatusEnum.RTA]: ({ campaignType, startDate, cumulative }) => {
    if (!cumulative) {
      return addBusinessDays(
        startDate,
        campaignType.defaultDeadlines.publicationDays
      );
    }

    return addBusinessDays(
      startDate,
      campaignType.defaultDeadlines.surveyDays +
        campaignType.defaultDeadlines.reviewDays +
        campaignType.defaultDeadlines.docAnalysisDays +
        campaignType.defaultDeadlines.scoringDays +
        campaignType.defaultDeadlines.publicationDays
    );
  },
};

export const getDefaultDeadlineByStatus = ({
  targetStatus,
  campaignType,
  startDate,
  cumulative = true,
}: {
  targetStatus: IStatus;
  campaignType: ICampaignType;
  startDate?: Date;
  cumulative?: boolean;
}): Date => {
  const codeName = targetStatus.codename as SurveyStatuses;
  return DEFAULT_DEADLINES[codeName]({
    campaignType,
    startDate: startDate || new Date(),
    cumulative,
  });
};

export const getAssigneeByPhase = (
  phases: IStatus[],
  phase: IARPhaseEnum
): IPartialBOUser | undefined => {
  return phases.find((p) => p.phaseCodename === phase)?.assignees?.[0];
};
export const getAssigneesByPhase = (
  phases: IStatus[],
  phase: IARPhaseEnum
): Array<IPartialBOUser> => {
  return phases.find((p) => p.phaseCodename === phase)?.assignees || [];
};

export const prepareDeadlinePayload = ({
  id,
  statuses,
  initiateSurveyForm,
}: {
  id: string;
  statuses: IStatus[];
  initiateSurveyForm: Partial<IARDeadlinesForm>;
}): IPatchObject => {
  const payload: IPatchObject = { id, body: {} };

  statuses.forEach((status) => {
    if (
      get(initiateSurveyForm, ARUtils.getStatusDeadlineKey(status)) !==
      undefined
    ) {
      payload.body[Helpers.dot2array(ARUtils.getStatusDeadlineKey(status))] =
        get(initiateSurveyForm, ARUtils.getStatusDeadlineKey(status)) as Date;
    }
  });

  return payload;
};

export const isEngagementPhase = (phase: IARPhaseEnum): boolean => {
  return [
    IARPhaseEnum.ENGAGEMENT,
    IARPhaseEnum.QUALIFICATION, // Legacy
    IARPhaseEnum.UNICITY_AND_SCOPE, // Legacy
    IARPhaseEnum.SURVEY_AND_REVIEW, // Legacy
  ].includes(phase);
};

export const isAnalystPhase = (phase: IARPhaseEnum): boolean => {
  return [
    IARPhaseEnum.IN_DOC_ANALYSIS,
    IARPhaseEnum.IN_SCORING,
    IARPhaseEnum.RTA,
  ].includes(phase);
};

export const getAccessibleStatus = ({
  user,
  dashboard,
}: {
  user: IBOUser | JwtBOUser;
  dashboard: DashboardType;
}): IARStatusEnum[] => {
  let statuses: unknown[] = [];
  statuses =
    dashboard === 'ENGAGEMENT'
      ? [
          ...Object.values(IAREngagementStatusEnum),
          IARStatusEnum.QUEUED,
          ...(UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user) ||
          UsersUtils.isGranted(
            IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
            user
          )
            ? Object.values(IARFinalStatusEnum)
            : []),
        ]
      : [
          IARStatusEnum.QUEUED,
          ...Object.values(IARAnalystStatusEnum),
          ...(UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user) ||
          UsersUtils.isGranted(
            IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
            user
          )
            ? Object.values(IARFinalStatusEnum)
            : []),
        ];
  return statuses as IARStatusEnum[];
};

export const DEADLINE_STATUSES_LOOKUP: Record<number, number | undefined> = {
  [IARStatusRankEnum.QUEUED]: undefined,
  [IARStatusRankEnum.QUALIFICATION]: IARStatusRankEnum.QUALIFICATION,
  [IARStatusRankEnum.UNICITY_AND_SCOPE]: IARStatusRankEnum.UNICITY_AND_SCOPE,
  [IARStatusRankEnum.READY_FOR_SURVEY]: undefined,
  [IARStatusRankEnum.FRESH_SURVEY]: IARStatusRankEnum.ANSWERING_SURVEY,
  [IARStatusRankEnum.ANSWERING_SURVEY]: IARStatusRankEnum.ANSWERING_SURVEY,
  [IARStatusRankEnum.REUSING_PREVIOUS_RESULT]: undefined,
  [IARStatusRankEnum.IN_REVIEW]: IARStatusRankEnum.IN_REVIEW,
  [IARStatusRankEnum.IN_DOC_ANALYSIS]: IARStatusRankEnum.IN_DOC_ANALYSIS,
  [IARStatusRankEnum.IN_SCORING]: IARStatusRankEnum.IN_SCORING,
  [IARStatusRankEnum.RTA]: IARStatusRankEnum.RTA,
  [IARStatusRankEnum.PUBLISHED]: undefined,
  [IARStatusRankEnum.CANCELLED]: undefined,
};

export const normalize = {
  getInitialExtendSurveyDeadlineValues: (
    campaignType: ICampaignType,
    assessmentRequest: IAR
  ): IExtendSurveyDeadlineForm => {
    return {
      distributorUsers: [],
      manufacturerUsers: [],
      vendorInvolvement: false,
      questionnaireDeadline: addDays(
        assessmentRequest.statuses[IARStatusRankEnum.ANSWERING_SURVEY]?.deadline
          ? new Date(
              assessmentRequest.statuses[
                IARStatusRankEnum.ANSWERING_SURVEY
              ].deadline
            )
          : new Date(),
        campaignType.defaultDeadlines.surveyExtensionDays
      ),
    };
  },
  extendSurveyDeadlineFormToExtendSurveyDeadlinePayload: (
    formData: IExtendSurveyDeadlineForm
  ): IExtendSurveyDeadlinePayload => {
    return {
      ...formData,
      distributorUsers: formData.distributorUsers
        .filter(Boolean)
        .map((userId) => {
          return { id: userId };
        }),
      manufacturerUsers: Helpers.ensureValueAsArray(
        formData?.manufacturerUsers?.filter(Boolean)?.map((userId) => {
          return { id: userId };
        })
      ),
    };
  },
  assessmentRequestRegistrationPayload: (
    rawPayload: IAssessmentRequestForm,
    state: IRegistrationState
  ): IAssessmentRequestPayload => {
    return {
      ...rawPayload,
      questionnaireContact: {
        ...rawPayload.questionnaireContact,
        id: rawPayload.questionnaireContact?.id || undefined,
      },
      scoredCompany: DistributorUtils.normalize.formDataToPayload(
        state.mode === 'CREATE'
          ? {
              ...rawPayload.scoredCompany,
              primaryContact: {
                ...rawPayload.scoredCompany?.primaryContact,
                id: undefined,
              },
              headquarters:
                !rawPayload.scoredCompany?.headquarters?.name ||
                isNil(rawPayload.scoredCompany?.headquarters)
                  ? undefined
                  : rawPayload.scoredCompany?.headquarters,
            }
          : {
              ...rawPayload.scoredCompany,
              primaryContact: {
                ...rawPayload.scoredCompany?.primaryContact,
                id: rawPayload.scoredCompany?.primaryContact?.id || undefined,
              },
              id: state.company?.id as string,
              headquarters:
                rawPayload.scoredCompany?.headquarters?.name === '' ||
                isNil(rawPayload.scoredCompany?.headquarters)
                  ? undefined
                  : rawPayload.scoredCompany?.headquarters,
            }
      ),
      action: state.action.value,
    };
  },
  initiateQuestionnairePayload: (
    data: Partial<IInitiateSurveyForm>,
    patch: IPatchObject
  ) => {
    return {
      subscriptionCategory: data.subscriptionCategory,
      patch: patch.body,
      inteliamDiscountEnabled: data.inteliamDiscountEnabled,
      manufacturerDiscountEnabled: data.manufacturerDiscountEnabled,
      presentationFile: data.presentationFile?.id
        ? data.presentationFile
        : undefined,
      sendToAssignee: data.sendToAssignee,
      ...((data.inteliamDiscountEnabled
        ? {
            inteliamDiscounts: {
              '1_YEAR': {
                ...omit(data.inteliamDiscounts?.['1_YEAR'], [
                  'price',
                  'percentage',
                ]),
                ...(data.inteliamDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_PERCENTAGE && {
                  percentage: data.inteliamDiscounts?.['1_YEAR'].percentage,
                }),
                ...(data.inteliamDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_AMOUNT && {
                  price: data.inteliamDiscounts?.['1_YEAR'].price,
                }),
              },
              '3_YEAR': {
                ...omit(data.inteliamDiscounts?.['3_YEAR'], [
                  'price',
                  'percentage',
                ]),
                ...(data.inteliamDiscounts?.['3_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_PERCENTAGE && {
                  percentage: data.inteliamDiscounts?.['3_YEAR'].percentage,
                }),
                ...(data.inteliamDiscounts?.['3_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_AMOUNT && {
                  price: data.inteliamDiscounts?.['3_YEAR'].price,
                }),
              },
            },
          }
        : {}) as IJSONObject),
      ...((data.manufacturerDiscountEnabled
        ? {
            manufacturerDiscounts: {
              '1_YEAR': {
                ...omit(data.manufacturerDiscounts?.['1_YEAR'], [
                  'price',
                  'percentage',
                ]),
                ...(data.manufacturerDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_PERCENTAGE && {
                  percentage: data.manufacturerDiscounts?.['1_YEAR'].percentage,
                }),
                ...(data.manufacturerDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_AMOUNT && {
                  price: data.manufacturerDiscounts?.['1_YEAR'].price,
                }),
              },
              '3_YEAR': {
                ...omit(data.manufacturerDiscounts?.['1_YEAR'], [
                  'price',
                  'percentage',
                ]),
                ...(data.manufacturerDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_PERCENTAGE && {
                  percentage: data.manufacturerDiscounts?.['1_YEAR'].percentage,
                }),
                ...(data.manufacturerDiscounts?.['1_YEAR']?.type ===
                  IDiscountTypesEnum.TYPE_AMOUNT && {
                  price: data.manufacturerDiscounts?.['1_YEAR'].price,
                }),
              },
            },
          }
        : {}) as IJSONObject),
    };
  },
};

export const calculateNextDefaultDeadlines = ({
  assessmentRequest,
  standardCampaignType,
  updatedStatus,
  updatedDate,
}: {
  assessmentRequest: IAR;
  standardCampaignType: ICampaignType;
  updatedStatus: IStatus;
  updatedDate: Date;
}): IRankedDeadlines => {
  const nextDates: IRankedDeadlines = [];
  assessmentRequest.statuses
    .filter((s) => !s.passed && s.isDeadlinable && s.rank > updatedStatus.rank)
    .forEach((s) => {
      nextDates.push({
        rank: s.rank,
        deadline: ARUtils.getDefaultDeadlineByStatus({
          targetStatus: s,
          campaignType:
            assessmentRequest.campaign?.type || standardCampaignType,
          startDate:
            nextDates.length === 0
              ? updatedDate
              : nextDates[nextDates.length - 1].deadline,
          cumulative: false,
        }),
      });
    });

  return nextDates;
};

export const getWorkingPageRoute = (
  ar: IAR,
  transition: IARTransitionEnum,
  dashboard: DashboardType
) => {
  if (transition === IARTransitionEnum.ANALYZE) {
    return `/assessment-requests/${ar.id}/doc-analysis`;
  }
  if (transition === IARTransitionEnum.SCORE) {
    return `/assessment-requests/${ar.id}/${
      ar.scoringVersion === 'V1' ? 'scoring' : 'scoring-v2'
    }`;
  }
  if (transition === IARTransitionEnum.RTA) {
    return `/assessment-requests/${ar.id}/validation`;
  }
  return `/dashboard/${dashboard.toLocaleLowerCase()}`;
};
