import React, { useState } from 'react';

import type { WorkingPageType } from '@core/types';

import { useNamedContext } from '@shared/contexts';

import { WorkingPageContext } from './contexts';

export interface IWorkingPageContext {
  page: WorkingPageType;
  readOnly: boolean;
}

const WorkingPageProvider: React.FCC<{
  page: WorkingPageType;
  readOnly: boolean;
}> = ({ page, readOnly, children }) => {
  const [workingPageContext] = useState<IWorkingPageContext>({
    page,
    readOnly,
  });

  return (
    <WorkingPageContext.Provider value={workingPageContext}>
      {children}
    </WorkingPageContext.Provider>
  );
};

function useWorkingPageContext(): IWorkingPageContext {
  return useNamedContext(WorkingPageContext);
}

export { useWorkingPageContext, WorkingPageProvider };
