import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIQuestion } from '@core/guards';
import type { IQuestionEnums } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IQuestion,
  IReorderQuery,
  ISiblingsQuery,
  JwtBOUser,
  IAnyQuestion,
} from '@inteliam/foundation/lib/types';

type QuestionActions =
  | 'paginate'
  | 'create'
  | 'patch'
  | 'getOneById'
  | 'update';

const baseQuestionsApi = ApiFactory.generate<
  IQuestion,
  JwtBOUser,
  QuestionActions
>({
  baseRoute: '/questions',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),

  guard: isIQuestion,
});

const fetchSiblings = async (
  query: ISiblingsQuery
): Promise<IAPIResponse<Array<IAnyQuestion>>> => {
  const { data } = await baseQuestionsApi.client.instance.post(
    '/get-siblings',
    query
  );

  return data;
};

export const getEnums = async (): Promise<IAPIResponse<IQuestionEnums>> => {
  const { data } = await baseQuestionsApi.client.instance.get('/enums');
  return data;
};

const getActiveQuestionByQuestion = async (
  id: string
): Promise<IAPIResponse<IQuestion>> => {
  const { data } = await baseQuestionsApi.client.instance.get(
    '/active-by-question/' + id
  );

  return data;
};

const reorder = (query: IReorderQuery): Promise<void> => {
  return baseQuestionsApi.client.instance.put(
    `/re-order/${query.id}/${query.order}`
  );
};

const getParentDependencyFor = async (
  id: IQuestion['id']
): Promise<
  IAPIResponse<{ codes: Array<string>; optionIds: Array<string> }>
> => {
  const { data } = await baseQuestionsApi.client.instance.get<
    IAPIResponse<{ codes: Array<string>; optionIds: Array<string> }>
  >(`/${id}/parent-dependency-for`);

  return data;
};

export default {
  ...baseQuestionsApi.actions,
  fetchSiblings,
  getActiveQuestionByQuestion,
  reorder,
  getEnums,
  getParentDependencyFor,
};
