import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { useManageMedia } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IMediaPayload } from '@core/types';

import { Button, Controls, Grid } from '@shared/components';

import { BaseDialog } from '..';

interface Props {
  open: boolean;
  onClose: () => void;
}
const schema = Yup.object().shape({
  file: Yup.object()
    .shape({
      id: Yup.string().required(),
    })
    .required(),
});
const CreateMediaModal: React.FCC<Props> = ({ open, onClose }) => {
  const { t } = useEssentials();
  const methods = useForm<Omit<IMediaPayload, 'kind'>>({
    resolver: yupResolver(schema),
  });
  const { create } = useManageMedia();
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Add a new presentation file')}
        </Grid>
      </BaseDialog.Title>
      <Controls.Form
        methods={methods}
        submitHandler={(data) => {
          create.mutate(
            { ...data, kind: 'ENGAGEMENT_DOCUMENT' },
            {
              onSuccess: onClose,
            }
          );
        }}
      >
        <BaseDialog.Content dividers>
          <Controls.FormDropzoneArea
            name='file'
            label={t('Upload a new file')}
            filesLimit={1}
            acceptedFiles={['application/pdf']}
          />
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='close-add-file'
            onClick={onClose}
            type='button'
            color='secondary'
            isLoading={create.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-add-file'
            type='submit'
            color='primary'
            isLoading={create.isLoading}
          >
            {t('Validate')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateMediaModal);
