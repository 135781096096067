import {
  assertIsTypedArray,
  isIOptionItem,
} from '@inteliam/foundation/lib/guards';
import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse, IOptionItem } from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/geo',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getAll = async (): Promise<IAPIResponse<Array<IOptionItem>>> => {
  const { data } = await client.instance.get<IAPIResponse<Array<IOptionItem>>>(
    '/countries'
  );
  assertIsTypedArray(data.data, isIOptionItem);
  return data;
};

const getCountryClassification = async (
  code: string
): Promise<{ classification: number }> => {
  const { data } = await client.instance.get<
    IAPIResponse<{ classification: number }>
  >(`/countries/${code}/classification`);

  return data.data;
};

export default {
  getAll,
  getCountryClassification,
};
