import { yupResolver } from '@hookform/resolvers/yup';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { I18n, Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormActions } from '@core/components';

import { useLanguagesByScope } from '@core/queries';

import { useEssentials } from '@core/contexts';
import { isITranslatableField } from '@core/guards';

import {
  Controls,
  FormGroup,
  Grid,
  TranslationFieldInput,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  ITranslatableField,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { patchSchema } from './schema';

interface Props {
  defaultValues?: { name: ITranslatableField };
  onSubmit: (data: { name: ITranslatableField }) => void;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const QuestionClassificationForm: React.FCC<Props> = ({
  onSubmit,
  isLoading,
  errors,
  defaultValues,
}) => {
  const { t } = useEssentials();
  const { formattedLanguages } = useLanguagesByScope({
    scope: 'QuestionnaireAdministration',
  });
  const defaultLanguage = formattedLanguages.filter(
    (item) => item.value === I18n.defaultLocale
  );

  const methods = useForm<{
    name: ITranslatableField;
    locale: string;
  }>({ defaultValues, resolver: yupResolver(patchSchema) });

  const watchedName = useWatch({
    control: methods.control,
    name: 'name',
  });

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={onSubmit}
    >
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <Controls.FormSelect
              label={t('English')}
              name='locale'
              options={defaultLanguage}
              required
              disabled
            />
          </FormGroup>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormGroup>
            <Controls.FormInput
              name='name.defaultValue'
              label={t('Text {{index}}')}
              required
            />
          </FormGroup>
        </Grid>
        <Grid item md={12} xs={12}>
          {watchedName && (
            <TranslationFieldInput
              name='name'
              defaultValue={
                isITranslatableField(watchedName) ? watchedName : undefined
              }
            />
          )}
        </Grid>
      </Grid>
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};

export default memo(QuestionClassificationForm);
