import React, { memo, useCallback } from 'react';

import { CampaignForm } from '@core/components/campaigns';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { CampaignsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { ICampaignFormData } from '@core/types';

import { Noop } from '@shared/components';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  ICampaign,
} from '@inteliam/foundation/lib/types';

interface Props {
  open: boolean;
  onClose: () => void;
}
const CreateCampaign: React.FCC<Props> = ({ open, onClose }) => {
  const { t, notify } = useEssentials();

  const mutation = useMutation<
    IAPIResponse<ICampaign>,
    AxiosValidationErrorResponse,
    ICampaignFormData
  >(CampaignsApi.create, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.CAMPAIGNS_LIST],
    onSuccess: (data) => {
      notify({
        message: t('Campaign "{{name}}" successfully created', {
          name: data.data?.name,
        }),
        type: INotifierEnum.Success,
      });
      onClose();
    },
  });

  const createCampaign = useCallback(
    (body: ICampaignFormData) => {
      mutation.mutate(body);
    },
    [mutation]
  );
  if (!open) {
    return <Noop />;
  }
  return (
    <CampaignForm
      open={open}
      onClose={onClose}
      onSubmit={createCampaign}
      isLoading={mutation.isLoading}
      errors={mutation.error?.response?.data}
      title={t('Create a new campaign')}
    />
  );
};

export default memo(CreateCampaign);
