import { ApiFactory } from '@inteliam/foundation/lib/api';

import type { IARNextActionForm, IRenewal } from '@core/types';

import { getAuthClientInstance } from '@shared/utils/auth-instance';
import * as Constants from '@shared/utils/constants';

import type {
  IAPIResponse,
  IPatchObject,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type RenewalsActions = 'paginate' | 'patch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isIRenewal(arg: any): arg is IRenewal {
  return typeof arg?.id === 'string';
}
const baseRenewalApi = ApiFactory.generate<
  IRenewal,
  JwtBOUser,
  RenewalsActions
>({
  baseRoute: '/renewals',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIRenewal,
});

const updateNextAction = async (
  body: IARNextActionForm & { id: IRenewal['id'] }
): Promise<IAPIResponse> => {
  const { data } = await baseRenewalApi.client.instance.post<IAPIResponse>(
    `/${body.id}/next-action`,
    {
      ...body,
    }
  );

  return data;
};
const createAssessment = async ({
  id,
}: {
  id: IRenewal['id'];
}): Promise<IAPIResponse> => {
  const { data } = await baseRenewalApi.client.instance.post<IAPIResponse>(
    `/${id}/create-assessment`
  );

  return data;
};

const assignSponsors = async ({ body, id }: IPatchObject) => {
  const { data } = await baseRenewalApi.client.instance.patch<IAPIResponse>(
    `/${id}/assign-sponsors`,
    body
  );
  return data;
};

const assignCampaign = async ({ body, id }: IPatchObject) => {
  const { data } = await baseRenewalApi.client.instance.patch<IAPIResponse>(
    `/${id}/assign-campaign`,
    body
  );
  return data;
};

export default {
  ...baseRenewalApi.actions,
  updateNextAction,
  createAssessment,
  assignSponsors,
  assignCampaign,
};
