import { useActionChecker } from '@inteliam/foundation/lib/hooks';
import { EventListeners } from '@inteliam/foundation/lib/utils';
import type {
  ListItemIconProps,
  MenuItemProps,
  MenuProps,
} from '@mui/material';
import { noop } from 'lodash-es';

import React, { forwardRef, useMemo, useState } from 'react';

import {
  CircularProgress,
  MenuItem,
  Noop,
  Menu,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@shared/components';

import { createNamedContext, useNamedContext } from '@shared/contexts';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

interface IMenuContext {
  element: undefined | HTMLElement;
  open: (event: React.MouseEvent<HTMLElement>) => void;
  close: () => void;
}
const INITIAL_CONTEXT: IMenuContext = {
  element: undefined,
  open: () => {},
  close: () => {},
};
const MenuContext = createNamedContext<IMenuContext>('MenuContext');

interface ContainerProps {
  defaultOpen?: boolean;
}

export const Container: React.FCC<ContainerProps> = ({ children }) => {
  const [element, setElement] = useState<undefined | HTMLElement>(undefined);
  const context = useMemo(
    () => ({
      element,
      open: (event: React.MouseEvent<HTMLElement>) =>
        setElement(event.currentTarget),
      close: () => setElement(undefined),
    }),
    [element]
  );
  return (
    <MenuContext.Provider value={context}>{children}</MenuContext.Provider>
  );
};

type InternalMenuProps = Omit<MenuProps, 'open'>;
export const List: React.FCC<InternalMenuProps> = ({ children, ...rest }) => {
  const { close, element } = useNamedContext<IMenuContext>(
    MenuContext,
    INITIAL_CONTEXT
  );
  const isOpen = Boolean(element);

  return (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...rest}
      open={isOpen}
      onClose={close}
      anchorEl={element}
      // keepMounted
    >
      {children}
    </Menu>
  );
};

export const Item = forwardRef<
  HTMLLIElement,
  MenuItemProps & {
    actionChecker?: () => Promise<IAPIResponse>;
    icon?: React.ReactNode;
    iconsProps?: ListItemIconProps;
    id: string;
  }
>(({ actionChecker, icon, iconsProps, children, id, ...rest }, ref) => {
  const { show, loading } = useActionChecker({
    checker: actionChecker,
    runOnMount: true,
  });
  if (loading) {
    return (
      <MenuItem {...rest} onClick={undefined} ref={ref}>
        <CircularProgress color='inherit' size={14} />
      </MenuItem>
    );
  }
  if (!show) {
    return <Noop />;
  }

  return (
    <ListItem component={'li'} {...rest} {...{ ref, id }} data-cy={id} button>
      {icon && <ListItemIcon {...iconsProps}>{icon}</ListItemIcon>}
      <ListItemText primary={children} />
    </ListItem>
  );
});

// interface TriggerProps<D extends React.ElementType = 'div'> {
//   children: React.ReactChild | React.ReactChild[];
//   component: D;
// }

// TODO Dynamic props
export const Trigger = ({
  children,
  id,
  component: Component,
  onClick,
  ...rest
}: {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  id: string;
  title?: string;
  edge?: string;
  color?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}): React.ReactElement => {
  const { open } = useNamedContext(MenuContext, INITIAL_CONTEXT);
  return (
    <Component
      {...rest}
      id={id}
      onClick={EventListeners.wrapEvent(
        onClick || noop,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        open as unknown as (event: Event) => any
      )}
      data-cy={id}
    >
      {children}
    </Component>
  );
};

export { ListItemLink as ItemLink } from '@shared/components';
