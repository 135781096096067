import type { PopoverProps } from '@mui/material';

import React, { useMemo, useState } from 'react';

import { MUPopover } from '@shared/components';

import { createNamedContext, useNamedContext } from '@shared/contexts';

import type { RenderProps } from '@inteliam/foundation/lib/types';

interface IPopoverContext {
  element: undefined | HTMLElement;
  open: (event: React.MouseEvent<HTMLElement>) => void;
  close: () => void;
}
const INITIAL_CONTEXT: IPopoverContext = {
  element: undefined,
  open: () => {},
  close: () => {},
};
const PopoverContext = createNamedContext<IPopoverContext>('PopoverContext');

interface ContainerProps extends RenderProps<IPopoverContext> {
  defaultOpen?: boolean;
}

export const Container: React.FC<ContainerProps> = ({ children }) => {
  const [element, setElement] = useState<undefined | HTMLElement>(undefined);
  const context = useMemo(
    () => ({
      element,
      open: (event: React.MouseEvent<HTMLElement>) =>
        setElement(event.currentTarget),
      close: () => setElement(undefined),
    }),
    [element]
  );
  return (
    <PopoverContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </PopoverContext.Provider>
  );
};

type InternalPopoverProps = Omit<PopoverProps, 'open'>;
export const Content: React.FCC<InternalPopoverProps> = ({
  children,
  ...rest
}) => {
  const context = useNamedContext<IPopoverContext>(
    PopoverContext,
    INITIAL_CONTEXT
  );
  const isOpen = Boolean(context?.element);

  return (
    <MUPopover
      {...rest}
      open={isOpen}
      onClose={context.close}
      anchorEl={context?.element}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </MUPopover>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Trigger = ({
  children,
  component: Component,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any): React.ReactElement => {
  const { open } = useNamedContext(PopoverContext, INITIAL_CONTEXT);
  return (
    <Component {...rest} onClick={open}>
      {children}
    </Component>
  );
};
