import { useOnMount } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import {
  QuestionConfigurationOptionItem,
  QuestionConfigurationOptionContainer,
} from '@core/components';

import { useCompanyQualificationEnums } from '@core/queries';

import { QuestionsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { ISingleChoiceQuestionForm } from '@core/types';

import {
  ErrorHelpText,
  IconButton,
  Tooltip,
  Grid,
  DndProvider,
  RemoveCircleIcon,
  AddCircleIcon,
  Button,
} from '@shared/components';

import { BOErrorTracking } from '@shared/utils';

interface Props {
  readOnly?: boolean;
}
const SingleChoiceField: React.FCC<Props> = ({ readOnly = false }) => {
  const methods = useFormContext<ISingleChoiceQuestionForm>();
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  const optionsErrorMessage = get(methods.formState.errors, 'options.message');
  const { t } = useEssentials();

  const { fields, append, remove, insert } = useFieldArray<
    ISingleChoiceQuestionForm,
    'options',
    'rhfId'
  >({
    control: methods.control,
    name: 'options',
    keyName: 'rhfId',
  });

  useOnMount(() => {
    if (fields.length === 0 && companyQualificationEnums) {
      append([
        {
          ...QuestionsUtils.getDefaultSingleChoiceOption(
            companyQualificationEnums
          ),
          order: 0,
          id: Helpers.uuid(),
        },
        {
          ...QuestionsUtils.getDefaultSingleChoiceOption(
            companyQualificationEnums
          ),
          order: 1,
          id: Helpers.uuid(),
        },
      ]);
    }
  });
  return (
    <div>
      <DndProvider
        onMove={(indexFrom, indexTo) => {
          const currentOptions = methods.getValues('options');
          const value = currentOptions[indexFrom];
          remove(indexFrom);
          insert(indexTo, value);
        }}
      >
        {({ getItemStyle }) => (
          <>
            {fields
              .filter((field) => {
                if (!field || !field.rhfId) {
                  BOErrorTracking.report(
                    new Error(JSON.stringify(fields)),
                    'SingleChoiceField'
                  );
                  return false;
                }
                return true;
              })
              .map((field, index) => {
                return (
                  <Grid container key={field.rhfId} spacing={3}>
                    <Grid item md={11}>
                      <QuestionConfigurationOptionContainer
                        id={field.rhfId}
                        index={index}
                        readOnly={readOnly}
                        getItemStyle={getItemStyle}
                      >
                        <QuestionConfigurationOptionItem
                          {...{ index, field, readOnly }}
                        />
                      </QuestionConfigurationOptionContainer>
                    </Grid>
                    <Grid item>
                      {index > 1 && (
                        <Tooltip title={t('Delete')}>
                          <span>
                            <IconButton
                              id={`remove-option-${index}`}
                              color='secondary'
                              onClick={
                                readOnly
                                  ? undefined
                                  : () => {
                                      methods.setValue(
                                        'options',
                                        methods
                                          .getValues('options')
                                          .filter((_, key) => key !== index)
                                      );
                                    }
                              }
                              disabled={readOnly}
                              size='large'
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </>
        )}
      </DndProvider>
      {optionsErrorMessage && (
        <ErrorHelpText>{t(optionsErrorMessage)}</ErrorHelpText>
      )}
      {!readOnly && (
        <Grid item xs={12}>
          {companyQualificationEnums && (
            <Button
              id={`add-question-option`}
              color='primary'
              onClick={() =>
                append({
                  ...QuestionsUtils.getDefaultSingleChoiceOption(
                    companyQualificationEnums
                  ),
                  order: fields.length,
                  id: Helpers.uuid(),
                })
              }
              style={{ marginTop: '1rem', border: '1px solid' }}
              size='large'
              fullWidth
            >
              <AddCircleIcon /> {t('Add option')}
            </Button>
          )}
        </Grid>
      )}
    </div>
  );
};

export default memo(SingleChoiceField);
