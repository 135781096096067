import { I18n, Transformers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { useFetchClassifications } from '@core/queries';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

import type {
  IClassificationType,
  IThemeConfiguration,
} from '@inteliam/foundation/lib/types';

interface Props extends FormSelectProps {
  type: IClassificationType;
  questionnaireType?: IThemeConfiguration['questionnaireType'];
}
const QuestionClassificationsField: React.FCC<Props> = ({
  type,
  questionnaireType = 'INTELIAM',
  ...rest
}) => {
  const { classifications } = useFetchClassifications({
    type,
    questionnaireType,
  });

  return (
    <Controls.FormSelect
      {...rest}
      style={{ width: '100%' }}
      options={Transformers.mapIntoOptionItem(
        classifications,
        (classification) => I18n.getTranslatedValue(classification.name),
        'id'
      )}
    />
  );
};

export default QuestionClassificationsField;
