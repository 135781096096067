import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { I18n, Transformers } from '@inteliam/foundation/lib/utils';

import React, { memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import { useFetchClassificationHierarchy } from '@core/queries';

import { useEssentials, useQuestionContext } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import { Card, Controls, Grid, FormGroup, Divider } from '@shared/components';

const QuestionClassification: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext<IQuestionForm>();
  const { readOnly } = useQuestionFormContext();
  const questionContext = useQuestionContext();
  const classificationQuery = useFetchClassificationHierarchy({
    reviewsAssignableOnly: false,
    questionnaireType: questionContext.questionnaireType,
  });
  const watchedTheme = useWatch({
    control: methods.control,
    name: 'theme.id',
  });
  const selectedTheme = classificationQuery.data?.data.find(
    (item) => item.id === watchedTheme
  );

  useOnUpdate(() => {
    if (selectedTheme && selectedTheme.children.length === 1) {
      methods.setValue('criterion.id', selectedTheme.children[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme]);

  useEffect(() => {
    // @ts-expect-error i dont want to add required to the form Type, only necessary for ui validation
    methods.setValue('criterion.required', selectedTheme?.isLeaf === false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme]);

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Question classification')} />
      <Divider />
      <Card.Body>
        {classificationQuery.isSettled ? (
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormGroup>
                {classificationQuery.isSuccess && (
                  <Controls.FormSelect
                    label={t('Theme')}
                    name='theme.id'
                    options={Transformers.mapIntoOptionItem(
                      classificationQuery.data.data,
                      (theme) => I18n.getTranslatedValue(theme.name),
                      'id'
                    )}
                    required
                    readOnly={readOnly}
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup style={{ flexWrap: 'nowrap' }}>
                <Controls.FormSelect
                  label={t('Criterion')}
                  name='criterion.id'
                  options={
                    !selectedTheme || selectedTheme.isLeaf
                      ? []
                      : Transformers.mapIntoOptionItem(
                          selectedTheme.children,
                          (criterion) =>
                            I18n.getTranslatedValue(criterion.name),
                          'id'
                        )
                  }
                  disabled={!selectedTheme || selectedTheme.isLeaf || readOnly}
                  required
                />
              </FormGroup>
            </Grid>
          </Grid>
        ) : (
          'Loading ...'
        )}
      </Card.Body>
      <Divider />
    </Card.CContainer>
  );
};

export default memo(QuestionClassification);
