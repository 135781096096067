import { I18n } from '@inteliam/foundation/lib/utils';
import TreeItem from '@mui/lab/TreeItem';
import TreeView, { TreeViewProps } from '@mui/lab/TreeView';

import * as React from 'react';

import { Paper, ExpandMoreIcon, ChevronRightIcon } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IClassification } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles({
  root: {
    width: 400,
    marginRight: '15px',
    padding: '25px',
  },
});

function renderTree(nodes: IClassification[]) {
  const tree = [];
  for (const node of nodes) {
    if (node.isLeaf === true) {
      tree.push(
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={I18n.getTranslatedValue(node.name)}
        />
      );
    } else {
      tree.push(
        <TreeItem
          key={node.id}
          nodeId={node.id}
          label={I18n.getTranslatedValue(node.name)}
        >
          {renderTree(node.children)}
        </TreeItem>
      );
    }
  }
  return tree;
}

interface Props extends Pick<TreeViewProps, 'defaultExpanded'> {
  classification: IClassification[];
  onSelect: (nodeId: string) => void;
  defaultSelected?: string;
}

const QuestionStructure: React.FCC<Props> = ({
  classification,
  onSelect,
  defaultExpanded,
  defaultSelected,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={3}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={defaultExpanded}
        defaultSelected={defaultSelected}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onNodeSelect={(_: any, value: string) => onSelect(value)}
      >
        {renderTree(classification)}
      </TreeView>
    </Paper>
  );
};
export default QuestionStructure;
