/* eslint-disable @inteliam/i18n/raw-text-detected */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IChannelEnum,
  ICompanyKindEnum,
  IModeEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  DateFormatter,
  Helpers,
  Transformers,
  Validations,
} from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import {
  useFetchCompanyUsers,
  useFetchContactLogEventEnums,
  useManageAssessmentRequest,
} from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IContactLogForm } from '@core/types';

import {
  Button,
  Controls,
  IconButton,
  Grid,
  FormGroup,
  AddCircleIcon,
  RemoveCircleIcon,
  CenteredSpinner,
} from '@shared/components';

import { makeStyles, ValidationUtils } from '@shared/utils';

import type {
  IAR,
  IFODistributorUser,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { ContactLogFilesPreview } from '.';
import { BaseDialog } from '../modals';
import { create as schema } from './schema';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    width: '100%',
  },
  info: {
    marginRight: theme.spacing(6),
  },
}));

const CHANNELS_ARRAY = Object.values(IChannelEnum).map((value) => ({
  label: value,
  value,
}));
const MODES_ARRAY = Object.values(IModeEnum).map((value) => ({
  label: value,
  value,
}));

type AssessmentRequestPageProps = {
  view: 'AR';
  assessmentRequest: IAR;
};
type DistributorPageProps = {
  view: 'DISTRIBUTOR';
  distributorId: string;
};

type RequiredProps = {
  onSubmit: (data: IContactLogForm) => void;
  defaultValues?: Partial<IContactLogForm>;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
};
type Props = (AssessmentRequestPageProps | DistributorPageProps) &
  RequiredProps;

const Form: React.FCC<Props> = ({
  onClose,
  open,
  onSubmit,
  isLoading,
  defaultValues,
  errors,
  title,
  ...conditionalProps
}) => {
  const { t, user } = useEssentials();
  const classes = useStyles();
  const methods = useForm<IContactLogForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { events } = useFetchContactLogEventEnums();

  const { data: distributorUsers } = useFetchCompanyUsers({
    kind: ICompanyKindEnum.DISTRIBUTOR,
    id:
      conditionalProps.view === 'AR'
        ? conditionalProps.assessmentRequest.scoredCompany.id
        : conditionalProps.distributorId,
  });
  const { getAllByCompany } = useManageAssessmentRequest({
    getAllByCompany: {
      args: {
        id:
          conditionalProps.view === 'DISTRIBUTOR'
            ? conditionalProps.distributorId
            : conditionalProps.assessmentRequest.scoredCompany.id,
      },
    },
  });

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const { fields, append, remove } = useFieldArray<IContactLogForm, 'contacts'>(
    {
      control: methods.control,
      name: 'contacts',
    }
  );

  const watchedChannel = useWatch({
    control: methods.control,
    name: 'channel',
  });

  const handleOnChange = (
    usersIds: Array<string>,
    users: Array<IFODistributorUser>
  ) => {
    const selectedUsers = users.filter((user) => usersIds.includes(user.id));

    const newFields = selectedUsers.map((user) => {
      const { firstName, lastName, email, phoneNumber } = user.contactInfo;
      return watchedChannel === IChannelEnum.EMAIL
        ? {
            firstName,
            lastName,
            email,
          }
        : {
            firstName,
            lastName,
            phoneNumber,
          };
    }) as IContactLogForm['contacts'];
    // TODO use 'replace' after upgrading react-hook-form
    remove();
    append(newFields);
  };

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      {getAllByCompany.isSuccess ? (
        <Controls.Form
          submitHandler={(values) => {
            onSubmit({ ...defaultValues, ...values });
          }}
          methods={methods}
        >
          <BaseDialog.Title id='modals.title' onClose={onClose}>
            <Grid
              container
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <span>
                <strong>{title}</strong>
              </span>
              <span className={classes.info}>
                {t('Inteliam user:')} {user.contactInfo?.email}
              </span>
            </Grid>
          </BaseDialog.Title>
          <BaseDialog.Content dividers>
            <h3>{t('Basic information')}</h3>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormGroup>
                  <Controls.FormSelect
                    name='assessmentRequestId'
                    defaultValue={
                      conditionalProps.view === 'AR'
                        ? conditionalProps.assessmentRequest.id
                        : defaultValues?.assessmentRequestId
                    }
                    label={t('Select AR ID')}
                    options={getAllByCompany.data?.data.map((ar) => {
                      const requesterName = ar.requester
                        ? `${Helpers.ensureValueAsString(ar.campaign?.name)} ${
                            ar.requester.name
                          }`
                        : 'self-assessment';
                      return {
                        label: `${ar.businessId} (${requesterName})`,
                        value: ar.id,
                      };
                    })}
                    readOnly={conditionalProps.view === 'AR'}
                    required={true}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={3}>
                <FormGroup>
                  <Controls.FormDatePicker
                    label={t('Interaction date')}
                    name='interactionDate'
                    pickerType='date'
                    textFieldProps={{
                      required: true,
                    }}
                    inputFormat={DateFormatter.FORMATS.fr_FR.SHORT}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
                {events && (
                  <FormGroup>
                    <Controls.FormSelect
                      name='eventSubject'
                      label={t('Event')}
                      defaultValue={events[0].value}
                      options={events}
                      required
                    />
                  </FormGroup>
                )}
              </Grid>
              <Grid item xs={2}>
                <FormGroup>
                  <Controls.FormSelect
                    name='channel'
                    label={t('Channel')}
                    defaultValue={CHANNELS_ARRAY[0].value}
                    options={CHANNELS_ARRAY}
                    required
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={2}>
                <FormGroup>
                  <Controls.FormSelect
                    name='mode'
                    label={t('In/Out')}
                    defaultValue={MODES_ARRAY[0].value}
                    options={MODES_ARRAY}
                    required
                  />
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <Controls.FormInput
                    id='details'
                    name='details'
                    label={t('Details')}
                    multiline
                    rows={7}
                    required
                  />
                </FormGroup>
              </Grid>

              {watchedChannel === IChannelEnum.EMAIL && (
                <ContactLogFilesPreview />
              )}
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: 55 }}>
              <Grid item xs={1}>
                <h3>{t('Contacts')}</h3>
              </Grid>
              {distributorUsers?.data && (
                <>
                  <Grid item xs={3}>
                    <FormGroup>
                      <Controls.FormSelect
                        fullWidth
                        name='distributorUsers'
                        label={t('Please select user(s)')}
                        multiple
                        options={Transformers.mapIntoOptionItem(
                          distributorUsers?.data,
                          'contactInfo.fullName',
                          'id'
                        )}
                        onChange={(event) =>
                          handleOnChange(
                            event.target.value as Array<string>,
                            distributorUsers?.data
                          )
                        }
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton
                      id={`add-log`}
                      color='primary'
                      onClick={() =>
                        append({ firstName: '', lastName: '', email: '' })
                      }
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
            {fields.map((field, index) => {
              return (
                <Grid container spacing={3} key={field.id}>
                  <div hidden>
                    <Controls.FormInput
                      id='channel'
                      name={`${Helpers.arrayDotNotation(
                        'contacts',
                        index
                      )}.channel`}
                      required
                      defaultValue={watchedChannel}
                    />
                  </div>
                  <Grid item xs={3}>
                    <FormGroup>
                      <Controls.FormInput
                        id='firstName'
                        name={`${Helpers.arrayDotNotation(
                          'contacts',
                          index
                        )}.firstName`}
                        label={t('First Name')}
                        required
                        defaultValue={field.firstName}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <FormGroup>
                      <FormGroup>
                        <Controls.FormInput
                          id='lastName'
                          name={`${Helpers.arrayDotNotation(
                            'contacts',
                            index
                          )}.lastName`}
                          label={t('Last name')}
                          required
                          defaultValue={field.lastName}
                        />
                      </FormGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={5}>
                    <FormGroup>
                      <FormGroup>
                        {watchedChannel === IChannelEnum.EMAIL ? (
                          <Controls.FormInput
                            id='email'
                            name={`${Helpers.arrayDotNotation(
                              'contacts',
                              index
                            )}.email`}
                            label={t('Email')}
                            defaultValue={field.email}
                          />
                        ) : (
                          <Controls.FormPhoneNumberField
                            id='phoneNumber'
                            name={`${Helpers.arrayDotNotation(
                              'contacts',
                              index
                            )}.phoneNumber`}
                            label={t('Phone number')}
                            defaultValue={field.phoneNumber}
                          />
                        )}
                      </FormGroup>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      id={`remove-log`}
                      color='secondary'
                      onClick={() => remove(index)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
          </BaseDialog.Content>
          <BaseDialog.Actions>
            <Button
              id='cancel-contact-log'
              onClick={onClose}
              type='button'
              color='primary'
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            <Button
              id='submit-contact-log'
              type='submit'
              color='primary'
              isLoading={isLoading}
            >
              {t('Confirm')}
            </Button>
          </BaseDialog.Actions>
        </Controls.Form>
      ) : (
        <CenteredSpinner />
      )}
    </BaseDialog.Dialog>
  );
};
export default memo(Form);
