import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { NewsUtils } from '@core/utils';

import { NewsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { INewsForm, INewsPayload } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  Id,
  INews,
  IPatchObject,
  IQueryManagementArgs,
  IQueryManagementResult,
  PatchBody,
} from '@inteliam/foundation/lib/types';

const useManageNews = (
  args: IQueryManagementArgs<
    INews,
    INewsForm,
    INewsPayload,
    'create' | 'update' | 'patch' | 'remove'
  > = {}
): IQueryManagementResult<
  INews,
  INewsForm,
  INewsPayload,
  'create' | 'update' | 'patch' | 'remove'
> => {
  const { t, notify, navigate, confirm } = useEssentials();
  const { assessmentRequestId } = useParams<{ assessmentRequestId: string }>();

  const createMutation = useMutation<
    IAPIResponse<INews>,
    AxiosValidationErrorResponse,
    INewsPayload
  >(NewsApi.create, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.NEWS_LIST],
    onSuccess: () => {
      notify({
        message: t('The news was successfully created'),
        type: INotifierEnum.Success,
      });
      navigate(`/news/${assessmentRequestId}`);
    },

    ...args.create,
  });

  const updateMutation = useMutation<
    IAPIResponse<INews>,
    AxiosValidationErrorResponse,
    IPatchObject<INewsPayload>
  >(NewsApi.update, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.NEWS_LIST],
    onSuccess: () => {
      notify({
        message: t('News successfully updated'),
        type: INotifierEnum.Success,
      });
      navigate(`/news/${assessmentRequestId}`);
    },

    ...args.update,
  });

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(NewsApi.patch, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.NEWS_LIST],
    onSuccess: () => {
      notify({
        message: t('News status successfully updated'),
        type: INotifierEnum.Success,
      });
    },
  });

  const removeMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse, //BaseAxiosErrorResponse,
    Id // INews['id]
  >(NewsApi.remove, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.NEWS_LIST],
    onSuccess: () => {
      notify({
        message: t('Operation successfully completed'),
        type: INotifierEnum.Success,
      });
    },
  });

  const runCreate = useCallback(
    (formData: INewsForm) => {
      createMutation.mutate(NewsUtils.normalize.formDataToPayload(formData));
    },
    [createMutation]
  );
  const runUpdate = useCallback(
    (formData: INewsForm, id: INews['id']) => {
      updateMutation.mutate({
        id: id,
        body: NewsUtils.normalize.formDataToPayload(formData),
      });
    },
    [updateMutation]
  );

  const runPatch = useCallback(
    (body: PatchBody, id: INews['id']) => {
      patchMutation.mutate({
        id,
        body,
      });
    },
    [patchMutation]
  );
  const runRemove = useCallback(
    (id: INews['id']) => {
      confirm({
        description: t('Are you sure ?'),
      })
        .then(() => {
          removeMutation.mutate(id);
        })
        .catch(() => {});
    },
    [confirm, removeMutation, t]
  );

  return {
    create: {
      run: runCreate,
      mutation: createMutation,
    },
    update: {
      run: runUpdate,
      mutation: updateMutation,
    },
    patch: {
      run: runPatch,
      mutation: patchMutation,
    },
    remove: {
      run: runRemove,
      mutation: removeMutation,
    },
  };
};
export default useManageNews;
