import * as React from 'react';

import { useI18n } from '@core/contexts';

import { ListItemLink, Box, Typography, HomeIcon } from '@shared/components';

import { makeStyles } from '@shared/utils';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  label: {
    paddingRight: '1rem',
    color: `${theme.palette.text.primary} !important`,
  },
}));

const InternalErrorPage: React.FCC = ({ children }) => {
  const { t } = useI18n();
  const classes = useStyles();

  return (
    <Box
      className={classes.box}
      position='fixed'
      top={0}
      left={0}
      zIndex={999_999}
      width='100vw'
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Typography className={classes.label} variant='h2'>
        {t('500 | Something went wrong')}
      </Typography>
      <Typography>
        <ListItemLink
          className={classes.label}
          id='go-home'
          to={'/'}
          icon={<HomeIcon />}
        >
          {t('Home')}
        </ListItemLink>
      </Typography>
      <div>{children}</div>
    </Box>
  );
};

export default InternalErrorPage;
