import * as React from 'react';

import { clsx } from 'clsx';

import { CircularProgress } from '@shared/components';

import { makeStyles } from '@shared/utils';

import './style.css';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.primary,
  },
}));
const FullPageSpinner: React.FC<{
  style?: React.CSSProperties;
  id?: string;
}> = ({ style, ...props }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx('full-page-spinner', classes.backdrop)}
      style={style}
      {...props}
    >
      <CircularProgress color='primary' />
    </div>
  );
};

export default FullPageSpinner;
