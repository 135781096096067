import { pick } from 'lodash-es';

import React, { memo, useCallback } from 'react';

import { flattie } from 'flattie';

import { CampaignAssign } from '@core/components/campaigns';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IAssignCampaign } from '@core/types';

import { Noop } from '@shared/components';

import type { IAR } from '@inteliam/foundation/lib/types';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}
const AssignCampaign: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { t } = useEssentials();
  const { patch } = useManageAssessmentRequest({
    patch: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD.ENGAGEMENT],
      },
    },
  });

  const assignCampaign = useCallback(
    (body: IAssignCampaign) => {
      const campaignId = body.campaign?.id;
      if (!campaignId) {
        return;
      }
      patch.mutate(
        {
          id: assessmentRequest.id,
          body: flattie({
            campaign: campaignId,
            requester: pick(body.manufacturer, 'id'),
          }),
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    },
    [patch, assessmentRequest.id, onClose]
  );

  if (!open) {
    return <Noop />;
  }
  return (
    <CampaignAssign
      open={open}
      onClose={onClose}
      onSubmit={assignCampaign}
      isLoading={patch.isLoading}
      errors={patch.error?.response?.data}
      title={t('Manage campaign')}
      defaultValues={assessmentRequest}
    />
  );
};

export default memo(AssignCampaign);
