import { TypeCaster } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import * as React from 'react';

import { Controls, FormGroup } from '@shared/components';

import type { FormRadioGroupProps } from '@shared/types';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const BoolFilterer: React.FCC<
  Omit<FormRadioGroupProps, 'options'> & { defaultItem: IFilterDefaultItem }
> = ({ defaultValue, ...props }) => {
  return (
    <FormGroup style={{ margin: '0 10px' }}>
      <Controls.FormRadioGroup
        {...omit(props, ['defaultItem'])}
        defaultChecked={TypeCaster.toBoolean(defaultValue)}
        options={[
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ]}
        row
      />
    </FormGroup>
  );
};

export default BoolFilterer;
