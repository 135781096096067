import { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { isIOptionItem } from '@inteliam/foundation/lib/guards';

import type { QueryKey } from '@tanstack/react-query';

import { FilterNormalizers } from '@shared/components';

import type { IFilterableOption } from '@shared/types';

import type {
  DatatableState,
  FieldOperator,
  IFilter,
  IFilterCondition,
  IOperatorOption,
  IOptionItem,
  ISorter,
  IPaginationResponse,
  RecursivePartial,
  IRawFilterCondition,
} from '@inteliam/foundation/lib/types';

export const createCondition = <
  TField extends string,
  TOperator = TField extends '' ? '' : FieldOperator
>(
  column: TField,
  operator: TOperator,
  value: unknown,
  label?: string,
  propertyPath?: string
): {
  column: string;
  operator: TOperator;
  value: string;
  label: string;
  propertyPath: string;
} => {
  const stringValue = typeof value === 'string' ? value : JSON.stringify(value);
  return {
    column,
    propertyPath: propertyPath || column,
    operator,
    value: stringValue,
    label: label || stringValue,
  };
};

export const DEFAULT_SORTER: ISorter = {
  _id: 'desc',
};

export const DEFAULT_PER_PAGE = 20;
export const DEFAULT_SORT_ORDER = 'asc';

export const DEFAULT_FILTER: IFilter = {
  conditions: [createCondition('', '', '')],
  operator: 'or',
};

export const getInitialState = (): DatatableState => ({
  itemsPerPage: DEFAULT_PER_PAGE,
  page: 1,
  selected: [],
  sort: DEFAULT_SORTER,
  criteria: DEFAULT_FILTER,
  visibleColumns: [],
});

const OPERATOR_TRANSFORMER: Partial<Record<IFilterTypeEnum, string>> = {
  [IFilterTypeEnum.Year]: 'range',
};

export const COLUMN_OPERATORS: IOperatorOption[] = [
  {
    value: 'eq',
    label: 'Equals',
    supports: [
      IFilterTypeEnum.Boolean,
      IFilterTypeEnum.Date,
      IFilterTypeEnum.Number,
      IFilterTypeEnum.String,
      IFilterTypeEnum.List,
      IFilterTypeEnum.Assignment,
      IFilterTypeEnum.Country,
      IFilterTypeEnum.Company,
      IFilterTypeEnum.QuestionClassifications,
      IFilterTypeEnum.CompanySizes,
      IFilterTypeEnum.BoUsers,
      IFilterTypeEnum.Year,
    ],
  },
  {
    value: 'range',
    label: 'Between',
    supports: [IFilterTypeEnum.DateRange, IFilterTypeEnum.DateRangeWithPresets],
  },
  {
    value: 'neq',
    label: 'Not equals',
    supports: [
      IFilterTypeEnum.Boolean,
      IFilterTypeEnum.Date,
      IFilterTypeEnum.Number,
      IFilterTypeEnum.String,
      IFilterTypeEnum.List,
      IFilterTypeEnum.Country,
      IFilterTypeEnum.QuestionClassifications,
      IFilterTypeEnum.CompanySizes,
      IFilterTypeEnum.BoUsers,
    ],
  },
  {
    value: 'contains',
    label: 'Contains',
    supports: [IFilterTypeEnum.Number, IFilterTypeEnum.String],
  },
  {
    value: 'not_contains',
    label: "Doesn't contain",
    supports: [IFilterTypeEnum.String],
  },
  {
    value: 'in',
    label: 'In',
    supports: [
      IFilterTypeEnum.List,
      IFilterTypeEnum.CompanyCategories,
      IFilterTypeEnum.Role,
      IFilterTypeEnum.AssessmentRequest,
      IFilterTypeEnum.Campaign,
      IFilterTypeEnum.MultipleAssignment,
      IFilterTypeEnum.FoUsers,
    ],
  },
];

export const GLOBAL_OPERATORS: IOptionItem[] = [
  { value: 'or', label: 'or' },
  { value: 'and', label: 'and' },
];
const DEFAULT_NORMALIZER = (value: string): string => value;

export const normalizeCriteria = (
  values: IFilter,
  columns: IFilterableOption[]
): IFilter => {
  return {
    ...values,
    conditions: values.conditions.map((condition) => {
      const selectedColumn = columns.find(
        (column) => column.field === condition.column
      );
      let value: string;
      let label: string | undefined;
      const { rawValue, customNormalize, operator } = transform(
        selectedColumn,
        condition
      );
      if (selectedColumn && rawValue !== undefined) {
        let _value;
        try {
          _value = customNormalize
            ? customNormalize(rawValue)
            : DEFAULT_NORMALIZER(rawValue);
        } catch {
          _value = DEFAULT_NORMALIZER(rawValue);
        }

        if (isIOptionItem(_value)) {
          value =
            typeof _value.value === 'string'
              ? _value.value
              : _value.value.toString();
          label = _value.label;
        } else {
          value = _value;
        }
      } else {
        throw new Error(
          `Unknown field type [[${JSON.stringify({
            selectedColumn,
            condition,
          })}]]`
        );
      }
      const column = condition.column;
      if (!column || !operator) {
        throw new Error(
          `column & operator are required fields : [[${JSON.stringify({
            selectedColumn,
            condition,
          })}]]`
        );
      }
      return createCondition(
        column,
        operator,
        value,
        label,
        selectedColumn.filter.field
      );
    }),
  };
};

function transform(
  selectedColumn: IFilterableOption | undefined,
  condition: IFilterCondition
) {
  const operator =
    (selectedColumn?.filter.type &&
      OPERATOR_TRANSFORMER[selectedColumn?.filter.type]) ||
    condition.operator;

  const rawValue = selectedColumn ? condition.value : undefined;
  const customNormalize =
    selectedColumn && FilterNormalizers[selectedColumn.filter.type]
      ? FilterNormalizers[selectedColumn.filter.type]
      : undefined;
  return {
    rawValue,
    customNormalize,
    operator: operator as IRawFilterCondition['operator'],
  };
}

export const getQueryDataKey = (
  state: DatatableState,
  baseQueryKey?: QueryKey | Array<QueryKey>
): Array<unknown> => [
  ...(Array.isArray(baseQueryKey) ? baseQueryKey : [baseQueryKey]),
  state.page,
  state.itemsPerPage,
  state.sort,
  state.criteria,
];

export const createInitialPaginationResponse = <T>(
  defaults?: RecursivePartial<IPaginationResponse<T>>
): IPaginationResponse<T> => ({
  data: [],
  meta: {
    currentPage: 1,
    from: 1,
    hasMore: true,
    perPage: 100,
    total: 1000,
    ...defaults?.meta,
  },
  errors: [],
});
