/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable unicorn/no-null */
import {
  FilledInput,
  FormHelperText,
  FormHelperTextProps,
  Input,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { blue } from '@mui/material/colors';

import * as React from 'react';

import cx from 'clsx';

import {
  AddCircleIcon,
  Chip,
  FormControl,
  IconButton,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

const variantComponent = {
  standard: Input,
  filled: FilledInput,
  outlined: OutlinedInput,
};

const useStyles = makeStyles((theme) => {
  const light = theme.palette.mode === 'light';
  const bottomLineColor = light
    ? 'rgba(0, 0, 0, 0.42)'
    : 'rgba(255, 255, 255, 0.7)';

  return {
    root: {
      width: '100%',
    },
    inputRoot: {
      'display': 'inline-flex',
      'flexWrap': 'wrap',
      'flex': 1,
      'marginTop': 0,
      'minWidth': 70,
      '&$outlined,&$filled': {
        boxSizing: 'border-box',
      },
      '&$outlined': {
        paddingTop: 14,
      },
      '&$filled': {
        paddingTop: 28,
      },
    },
    input: {
      display: 'inline-block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      appearance: 'none', // Remove border in Safari, doesn't seem to break anything in other browsers
      WebkitTapHighlightColor: 'rgba(0,0,0,0)', // Remove mobile color flashing (deprecated style).
      float: 'left',
      flex: 1,
    },
    chipContainer: {
      'display': 'flex',
      'flexFlow': 'row wrap',
      'cursor': 'text',
      'marginBottom': -2,
      'minHeight': 40,
      '&$labeled&$standard': {
        marginTop: 18,
      },
    },
    outlined: {
      '& input': {
        height: 16,
        paddingTop: 4,
        paddingBottom: 12,
        marginTop: 4,
        marginBottom: 4,
      },
    },
    standard: {},
    filled: {
      '& input': {
        height: 22,
        marginBottom: 4,
        marginTop: 4,
        paddingTop: 0,
      },
      '$marginDense & input': {
        height: 26,
      },
    },
    labeled: {},
    label: {
      'top': 4,
      '&$outlined&:not($labelShrink)': {
        'top': 2,
        '$marginDense &': {
          top: 5,
        },
      },
      '&$filled&:not($labelShrink)': {
        'top': 15,
        '$marginDense &': {
          top: 20,
        },
      },
    },
    labelShrink: {
      top: 0,
    },
    helperText: {
      marginBottom: -20,
    },
    focused: {},
    disabled: {},
    underline: {
      '&:after': {
        borderBottom: `2px solid ${
          theme.palette.primary[light ? 'dark' : 'light']
        }`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '""',
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
        pointerEvents: 'none', // Transparent to the hover style.
      },
      '&$focused:after': {
        transform: 'scaleX(1)',
      },
      '&$error:after': {
        borderBottomColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
      '&:before': {
        borderBottom: `1px solid ${bottomLineColor}`,
        left: 0,
        bottom: 0,
        // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
        content: '"\\00a0"',
        position: 'absolute',
        right: 0,
        transition: theme.transitions.create('border-bottom-color', {
          duration: theme.transitions.duration.shorter,
        }),
        pointerEvents: 'none', // Transparent to the hover style.
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        'borderBottom': `2px solid ${theme.palette.text.primary}`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderBottom: `1px solid ${bottomLineColor}`,
        },
      },
      '&$disabled:before': {
        borderBottomStyle: 'dotted',
      },
    },
    error: {
      '&:after': {
        backgroundColor: theme.palette.error.main,
        transform: 'scaleX(1)', // error is always underlined in red
      },
    },
    chip: {
      margin: '0 8px 8px 0',
      float: 'left',
    },
    marginDense: {},
    addButton: {
      position: 'absolute',
      right: 5,
      top: 5,
    },
  };
});

const keyCodes = {
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
};

const ChipInput = React.forwardRef<HTMLInputElement, ChipInputProps>(
  (
    {
      alwaysShowPlaceholder,
      chipRenderer = defaultChipRenderer,
      className,
      disabled,
      value = [],
      error,
      FormHelperTextProps,
      fullWidth,
      fullWidthInput,
      helperText,
      id,
      InputProps = {},
      inputProps = {},
      InputLabelProps = {},
      label,
      onChange,
      placeholder,
      readOnly,
      required,
      disableUnderline = false,
      newChipKeyCodes = [13],
      newChipKeys = ['Enter'],
      variant = 'standard',
      ...other
    },
    ref
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const classes = useStyles();
    type State = {
      errorText: undefined;
      focusedChip: number;
      inputValue: string;
      isClean: boolean;
      isFocused: boolean;
      chipsUpdated: boolean;
    };

    const [state, actualSetState] = React.useState<State>({
      errorText: undefined,
      focusedChip: -1,
      inputValue: '',
      isClean: true,
      isFocused: false,
      chipsUpdated: false,
    });
    const chips = value || [];
    const actualInputValue = state.inputValue;
    const hasInput =
      (value || actualInputValue).length > 0 || actualInputValue.length > 0;
    const shrinkFloatingLabel =
      InputLabelProps.shrink == null
        ? label != null && (hasInput || state.isFocused || chips.length > 0)
        : InputLabelProps.shrink;

    const setState = (newPartialState: Partial<State>) => {
      actualSetState((prevState) => ({
        ...prevState,
        ...newPartialState,
      }));
    };
    const labelRef = React.useRef<any>();
    const labelNode = React.useRef<any>();
    const _keyPressed = React.useRef<boolean>(false);
    const _preventChipCreation = React.useRef<boolean>(false);

    /**
     * Focuses this component.
     * @public
     */
    const focus = () => {
      //   ref.current.focus();
      if (state.focusedChip != -1) {
        setState({ focusedChip: -1 });
      }
    };

    const handleInputFocus = () => {
      setState({ isFocused: true });
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
      event
    ) => {
      const currentInputValue = actualInputValue; //target.value
      const { focusedChip } = state;
      _keyPressed.current = false;
      _preventChipCreation.current = false;

      const chips = value;
      if (
        newChipKeyCodes.includes(event.keyCode) ||
        newChipKeys.includes(event.key)
      ) {
        const result = handleAddChip(currentInputValue);
        if (result !== false) {
          event.preventDefault();
        }
        return;
      }

      switch (event.keyCode) {
        case keyCodes.BACKSPACE: {
          if (currentInputValue === '') {
            if (focusedChip == -1) {
              setState({ focusedChip: chips.length - 1 });
            } else {
              handleDeleteChip(focusedChip);
              if (focusedChip > 0) {
                setState({ focusedChip: focusedChip - 1 });
              }
            }
          }
          break;
        }
        case keyCodes.DELETE: {
          if (currentInputValue === '' && focusedChip != -1) {
            handleDeleteChip(focusedChip);
            if (focusedChip <= chips.length - 1) {
              setState({ focusedChip });
            }
          }
          break;
        }
        case keyCodes.LEFT_ARROW: {
          if (
            focusedChip === -1 &&
            currentInputValue === '' &&
            chips.length > 0
          ) {
            setState({ focusedChip: chips.length - 1 });
          } else if (focusedChip !== -1 && focusedChip > 0) {
            setState({ focusedChip: focusedChip - 1 });
          }
          break;
        }
        case keyCodes.RIGHT_ARROW: {
          if (focusedChip !== -1 && focusedChip < chips.length - 1) {
            setState({ focusedChip: focusedChip + 1 });
          } else {
            setState({ focusedChip: -1 });
          }
          break;
        }
        default: {
          setState({ focusedChip: -1 });
          break;
        }
      }
    };

    const handleKeyUp: React.KeyboardEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      const currentInputValue = actualInputValue; //target.value
      if (
        !_preventChipCreation.current &&
        (newChipKeyCodes.includes(event.keyCode) ||
          newChipKeys.includes(event.key)) &&
        _keyPressed.current
      ) {
        clearInput();
      } else {
        updateInput(currentInputValue);
      }
    };

    const handleKeyPress = () => {
      _keyPressed.current = true;
    };

    const handleUpdateInput: React.ChangeEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    > = (event) => {
      updateInput(event.target.value);
    };

    /**
     * Handles adding a chip.
     * @param {string|object} chip Value of the chip, either a string or an object (if dataSourceConfig is set)
     * @param {object=} options Additional options
     * @param {boolean=} options.clearInputOnFail If `true`, and `onBeforeAdd` returns `false`, clear the input
     * @returns True if the chip was added (or at least `onAdd` was called), false if adding the chip was prevented
     */
    const handleAddChip = (chip: string) => {
      clearInput();

      if (chip.trim().length > 0) {
        updateChips([...value, chip]);
        return true;
      }
      return false;
    };

    const handleDeleteChip = (index: number) => {
      const chips = [...value];
      const changed = chips.splice(index, 1); // remove the chip at index i
      if (changed) {
        let focusedChip = state.focusedChip;
        if (state.focusedChip === index) {
          focusedChip = -1;
        } else if (state.focusedChip > index) {
          focusedChip = state.focusedChip - 1;
        }
        updateChips(chips, { focusedChip });
      }
    };

    const updateChips = (chips: Array<string>, additionalUpdates = {}) => {
      setState({ chipsUpdated: true, ...additionalUpdates });
      if (onChange) {
        onChange(chips);
      }
    };

    /**
     * Clears the text field for adding new chips.
     * This only works in uncontrolled input mode, i.e. if the inputValue prop is not used.
     * @public
     */
    const clearInput = () => {
      updateInput('');
    };

    const updateInput = (value: string) => {
      setState({ inputValue: value });
    };

    const chipComponents = chips.map((chip, index) => {
      return chipRenderer(
        {
          text: chip,
          isDisabled: !!disabled,
          isReadOnly: readOnly,
          isFocused: state.focusedChip === index,
          handleClick: () => setState({ focusedChip: index }),
          handleDelete: () => handleDeleteChip(index),
          className: classes.chip,
        },
        index
      );
    });

    const InputMore: any = {};
    if (variant === 'outlined') {
      InputMore.notched = shrinkFloatingLabel;
      InputMore.labelWidth =
        (shrinkFloatingLabel &&
          labelNode.current &&
          labelNode.current.offsetWidth) ||
        0;
    }

    if (variant === 'standard') {
      InputProps.disableUnderline = true;
    } else {
      InputMore.startAdornment = (
        <React.Fragment>{chipComponents}</React.Fragment>
      );
    }

    const InputComponent = variantComponent[variant];

    return (
      <FormControl
        fullWidth={fullWidth}
        className={cx(className, classes.root)}
        error={error}
        required={chips.length > 0 ? undefined : required}
        onClick={focus}
        disabled={disabled}
        variant={variant}
        {...other}
      >
        {label && (
          <InputLabel
            htmlFor={id}
            classes={{
              root: cx(classes[variant], classes.label),
              shrink: classes.labelShrink,
            }}
            shrink={shrinkFloatingLabel}
            focused={state.isFocused}
            variant={variant}
            ref={labelRef}
            required={required}
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        <div
          className={cx(classes[variant], classes.chipContainer, {
            [classes.focused]: state.isFocused,
            [classes.underline]: !disableUnderline && variant === 'standard',
            [classes.disabled]: disabled,
            [classes.labeled]: label != null,
            [classes.error]: error,
          })}
        >
          {variant === 'standard' && chipComponents}
          <InputComponent
            classes={{
              input: cx(classes.input, classes[variant]),
              root: cx(classes.inputRoot, classes[variant]),
            }}
            id={id}
            value={actualInputValue}
            onChange={handleUpdateInput}
            onKeyDown={handleKeyDown}
            onKeyPress={handleKeyPress}
            onKeyUp={handleKeyUp}
            onFocus={handleInputFocus}
            inputRef={ref}
            disabled={disabled}
            fullWidth={fullWidthInput}
            placeholder={
              (!hasInput && (shrinkFloatingLabel || label == null)) ||
              alwaysShowPlaceholder
                ? placeholder
                : null
            }
            readOnly={readOnly}
            {...InputProps}
            {...InputMore}
            inputProps={inputProps}
          />
        </div>
        {helperText && (
          <FormHelperText
            {...FormHelperTextProps}
            className={
              FormHelperTextProps
                ? cx(FormHelperTextProps.className, classes.helperText)
                : classes.helperText
            }
          >
            {helperText}
          </FormHelperText>
        )}
        <IconButton
          id={'add-tag'}
          className={classes.addButton}
          onClick={() => {
            handleAddChip(actualInputValue);
          }}
        >
          <AddCircleIcon />
        </IconButton>
      </FormControl>
    );
  }
);

export type ChipInputProps = {
  /** Allows duplicate chips if set to true. */
  allowDuplicates?: boolean;
  /** If true, the placeholder will always be visible. */
  alwaysShowPlaceholder?: boolean;
  /** Behavior when the chip input is blurred: `'clear'` clears the input, `'add'` creates a chip and `'ignore'` keeps the input. */
  blurBehavior?: 'clear' | 'add' | 'add-or-clear' | 'ignore';
  /** A function of the type `({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => node` that returns a chip based on the given properties. This can be used to customize chip styles.  Each item in the `dataSource` array will be passed to `chipRenderer` as arguments `chip`, `value` and `text`. If `dataSource` is an array of objects and `dataSourceConfig` is present, then `value` and `text` will instead correspond to the object values defined in `dataSourceConfig`. If `dataSourceConfig` is not set and `dataSource` is an array of objects, then a custom `chipRenderer` must be set. `chip` is always the raw value from `dataSource`, either an object or a string. */
  chipRenderer?: (props: ChipRendererArgs, key: number) => React.ReactNode;
  /** The chips to display by default (for uncontrolled mode). */
  defaultValue?: Array<string>;
  /** Disables the chip input if set to true. */
  disabled?: boolean;
  id?: string;
  required?: boolean;
  error?: boolean;
  /** Disable the input underline. Only valid for 'standard' variant */
  disableUnderline?: boolean;
  /** Props to pass through to the `FormHelperText` component. */
  FormHelperTextProps?: FormHelperTextProps;
  /** If true, the chip input will fill the available width. */
  fullWidth?: boolean;
  /** If true, the input field will always be below the chips and fill the available space. By default, it will try to be beside the chips. */
  fullWidthInput?: boolean;
  /** Helper text that is displayed below the input. */
  helperText?: React.ReactNode;
  /** Props to pass through to the `InputLabel`. */
  InputLabelProps?: any;
  /** Props to pass through to the `Input`. */
  InputProps?: any;
  inputProps?: InputBaseComponentProps;
  /** Use this property to pass a ref callback to the native input component. */
  /** The input value (enables controlled mode for the text input if set). */
  inputValue?: string;
  /* The content of the floating label. */
  label?: React.ReactNode;
  /** The key codes (`KeyboardEvent.keyCode`) used to determine when to create a new chip. */
  newChipKeyCodes?: number[];
  /** The keys (`KeyboardEvent.key`) used to determine when to create a new chip. */
  newChipKeys?: string[];
  /** Callback function that is called when the chips change (in uncontrolled mode). */
  onChange?: (newChips: Array<string>) => void;
  /** A placeholder that is displayed if the input has no values. */
  placeholder?: string;
  /** Makes the chip input read-only if set to true. */
  readOnly?: boolean;
  /** The chips to display (enables controlled mode if set). */
  value?: Array<string>;
  /** The variant of the Input component */
  variant?: 'outlined' | 'standard' | 'filled';
  className?: string;
};

export default ChipInput;

type ChipRendererArgs = {
  text: string;
  isFocused: boolean;
  isDisabled: boolean;
  isReadOnly?: boolean;
  handleClick: React.MouseEventHandler<HTMLDivElement>;
  handleDelete: () => void;
  className: string;
};

export const defaultChipRenderer = (
  {
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  }: ChipRendererArgs,
  key: number
): React.ReactNode => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      backgroundColor: isFocused ? blue[300] : undefined,
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text}
  />
);
