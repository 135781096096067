import { Helpers } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import { useEssentials } from '@core/contexts';

import {
  Accordion,
  Box,
  ExpandMoreIcon,
  DragIndicatorSharpIcon,
} from '@shared/components';

import type { ObligatorinessValue } from '@inteliam/foundation/lib/types';

interface Props {
  index: number;
  id: string;
  readOnly: boolean;
  getItemStyle: (
    isDragging: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    draggableStyle: any
  ) => React.CSSProperties;
  children: React.ReactNode;
}

const TextBoxLookup: Record<ObligatorinessValue, React.ReactNode> = {
  no: '-',
  optional: 'TB',
  required: 'TB*',
};
const DropDownLookup: Record<ObligatorinessValue, React.ReactNode> = {
  no: '-',
  optional: 'DD',
  required: 'DD*',
};
const EvidenceLookup: Record<ObligatorinessValue, React.ReactNode> = {
  no: '-',
  optional: 'Ev',
  required: 'Ev*',
};

const OptionContainer: React.FCC<Props> = ({
  children,
  index,
  id,
  getItemStyle,
  readOnly,
}) => {
  const { theme, t } = useEssentials();
  const methods = useFormContext();
  const { openedQuestion, setOpenedQuestion } = useQuestionFormContext();

  const fieldName = Helpers.arrayDotNotation('options', index);
  const watchedLabel = useWatch({
    control: methods.control,
    name: `${fieldName}.label.defaultValue`,
  });
  const textBoxObligatoriness = useWatch({
    control: methods.control,
    name: `${fieldName}.textBox`,
  }) as ObligatorinessValue;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const dropDownObligatoriness = useWatch({
    control: methods.control,
    name: `${fieldName}.dropDown`,
  }) as ObligatorinessValue;
  const evidenceObligatoriness = useWatch({
    control: methods.control,
    name: `${fieldName}.reference`,
  }) as ObligatorinessValue;
  const hasOptionError = get(methods.formState.errors, `${fieldName}`);
  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setOpenedQuestion(isExpanded ? panel : undefined);
    };

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(draggableProvided, draggableSnapshot) => {
        return (
          <Accordion.Container
            expanded={openedQuestion === id}
            onChange={handleChange(id)}
            style={{ marginTop: '1rem' }}
          >
            <Accordion.Summary
              id={`accordion-option-${index}`}
              expandIcon={<ExpandMoreIcon />}
              style={{
                backgroundColor: hasOptionError
                  ? theme.palette.error.main
                  : undefined,
              }}
            >
              <div
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                style={{
                  ...getItemStyle(
                    draggableSnapshot.isDragging,
                    draggableProvided.draggableProps.style
                  ),
                  width: '100%',
                  marginBottom: 0,
                }}
              >
                <Box
                  {...draggableProvided.dragHandleProps}
                  display='flex'
                  style={{
                    background: '#ccc',
                    cursor: 'grabbing',
                    padding: '0 10px',
                    margin: '0 10px',
                  }}
                >
                  {!readOnly && <DragIndicatorSharpIcon />}
                </Box>
                <Box flexGrow={1}>
                  <span style={{ opacity: 0.5 }}>
                    {t('Option {{index}} : {{title}}', {
                      index: index + 1,
                      title: watchedLabel,
                    })}
                  </span>
                </Box>
                <Box>
                  {[
                    TextBoxLookup[textBoxObligatoriness],
                    DropDownLookup[dropDownObligatoriness],
                    EvidenceLookup[evidenceObligatoriness],
                  ].join(' | ')}
                </Box>
              </div>
            </Accordion.Summary>
            <hr />
            <Accordion.Details>{children}</Accordion.Details>
          </Accordion.Container>
        );
      }}
    </Draggable>
  );
};
export default memo(
  OptionContainer,
  (prevProps, nextProps) =>
    prevProps.index === nextProps.index && prevProps.id === nextProps.id
);
