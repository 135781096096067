import { DateFormatter } from '@inteliam/foundation/lib/utils';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import type { FormDatePickerProps } from '@shared/types';

const TYPES = {
  date: DesktopDatePicker,
  // ['time']: KeyboardTimePicker,
  datetime: DateTimePicker,
  // datetime: KeyboardDateTimePicker,
};

const FormDatePicker: React.FCC<FormDatePickerProps> = (props) => {
  const {
    name,
    textFieldProps,
    errorText,
    pickerType,
    // eslint-disable-next-line unicorn/no-null
    defaultValue = null,
    inputFormat,
    onChange,
    ...rest
  } = props;
  const {
    control,

    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();
  const DateComponent = TYPES[pickerType];
  const isYearDatePicker =
    Array.isArray(rest.views) && rest.views[0] === 'year';
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => {
          let formattedValue = field.value;
          if (
            isYearDatePicker &&
            typeof formattedValue === 'number' &&
            formattedValue.toString().length === 4
          ) {
            formattedValue = new Date(formattedValue, 1, 1);
          }
          return (
            <DateComponent
              {...rest}
              data-testid={name}
              inputRef={field.ref} // needed so that RHF can focus on error
              inputFormat={inputFormat}
              value={formattedValue}
              onChange={(date, value) => {
                if (date && !DateFormatter.isValidDate(date)) {
                  return;
                }
                if (isYearDatePicker) {
                  field.onChange(date?.getFullYear());
                } else {
                  field.onChange(date ?? undefined);
                }
                if (onChange) {
                  onChange(date, value);
                }
              }}
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  {...textFieldProps}
                  error={!!get(errors, `${name}.message`)}
                  helperText={t(
                    (errorText ||
                      get(errors, `${name}.message`) ||
                      textFieldProps?.helperText) as unknown as string
                  )}
                  variant='outlined'
                  InputLabelProps={{
                    ...parameters.InputLabelProps,
                    htmlFor: name,
                  }}
                  inputProps={{
                    ...parameters.inputProps,
                    'data-cy': name,
                    'id': name,
                  }}
                />
              )}
              // rightArrowButtonProps={{ 'data-testid': 'date-selector' }}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default memo(FormDatePicker);
