import {
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { set } from 'lodash-es';

import * as React from 'react';

import { useAssessmentMachine } from '@core/hooks';

import {
  useFetchCampaignTypeByType,
  useFetchHasActiveDiscount,
} from '@core/queries';

import { ARUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type {
  IInitiateSurveyForm,
  IPartialInitiateSurveyForm,
} from '@core/types';

import type { IAR, ICampaignType } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import InitiateSurveyForm from './form';

const SURVEY_STATUSES = new Set([
  IARStatusEnum.ANSWERING_SURVEY,
  IARStatusEnum.IN_REVIEW,
  IARStatusEnum.IN_DOC_ANALYSIS,
  IARStatusEnum.IN_SCORING,
  IARStatusEnum.RTA,
]);

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

const InitiateSurvey: React.FCC<Props> = ({
  assessmentRequest,
  onClose,
  open,
}) => {
  const { t, confirm } = useEssentials();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });
  const {
    transition: { run, mutation },
  } = useAssessmentMachine({
    assessmentRequest,
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',
  });

  const handleOnSubmit = async (
    initiateSurveyForm: Partial<IInitiateSurveyForm>
  ) => {
    const patchObject = ARUtils.prepareDeadlinePayload({
      id: assessmentRequest.id,
      initiateSurveyForm,
      statuses: assessmentRequest.statuses,
    });
    try {
      await confirm({
        description: t('Are you sure you want to update these deadlines ?'),
      });
      run({
        confirm: false,
        transition: IARTransitionEnum.INITIATE_SURVEY,
        body: ARUtils.normalize.initiateQuestionnairePayload(
          initiateSurveyForm,
          patchObject
        ),
      })
        .then((response) => {
          if (response.status === 'SUCCESS') {
            onClose();
          }
        })

        .catch(() => {});
    } catch {
      // Do nothing
      // cancel modal
    }
  };

  const hasActiveDiscountQuery = useFetchHasActiveDiscount({
    id: assessmentRequest.campaign?.owner?.id,
  });

  // still getting default ...
  if (!campaignTypeQuery.isSettled || !hasActiveDiscountQuery.isSettled) {
    return <BaseDialog.DialogPlaceholder />;
  }
  if (
    !campaignTypeQuery.isSuccess ||
    (!hasActiveDiscountQuery.isSuccess && assessmentRequest.requester?.id)
  ) {
    return (
      <BaseDialog.DialogPlaceholder>
        {t('Unable to find the requested standard campaign')}
      </BaseDialog.DialogPlaceholder>
    );
  }

  return (
    <InitiateSurveyForm
      title={t('Invite to questionnaire')}
      open={open}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      errors={mutation.error?.response?.data}
      statuses={assessmentRequest.statuses.filter((status) =>
        SURVEY_STATUSES.has(status.codename)
      )}
      defaultValues={getDefaultValues(
        assessmentRequest,
        campaignTypeQuery.data.data
      )}
      isLoading={mutation.isLoading}
      hasActiveDiscount={
        hasActiveDiscountQuery.data?.data.hasActiveDiscount || false
      }
      manufacturerDiscount={hasActiveDiscountQuery.data?.data.discount}
      assessmentRequest={assessmentRequest}
    />
  );
};

export default InitiateSurvey;

/**
 * @todo UT before fixing default deadlines bug
 */
function getDefaultValues(
  assessmentRequest: IAR,
  standardCampaignType: ICampaignType
): Partial<IInitiateSurveyForm> | undefined {
  return assessmentRequest.statuses
    .filter((status) => SURVEY_STATUSES.has(status.codename))
    .reduce(
      (accumulator, status) => {
        set(
          accumulator,
          ARUtils.getStatusDeadlineKey(status),
          status.deadline
            ? new Date(status.deadline)
            : ARUtils.getDefaultDeadlineByStatus({
                targetStatus: status,
                campaignType:
                  assessmentRequest.campaign?.type || standardCampaignType,
              })
        );
        return accumulator;
      },
      {
        invoiceStatus:
          assessmentRequest.scoredCompany.subscriptionInfo?.invoiceStatus,
        subscriptionCategory:
          assessmentRequest.scoredCompany.subscriptionInfo?.pricingCategory?.id,
      } as IPartialInitiateSurveyForm
    );
}
