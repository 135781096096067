import type { Theme } from '@mui/material/styles';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import {
  AuthProvider,
  I18nProvider,
  NotifierProvider,
  ThemeProvider,
  ConfirmProvider,
} from '@core/contexts';

import { TechErrorBoundary, UserErrorBoundary } from '@shared/components';

import { getAuthClientInstance } from '@shared/utils';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();

const AppProviders: React.FCC = ({ children }) => {
  return (
    <TechErrorBoundary>
      <Router>
        <ThemeProvider>
          <I18nProvider>
            <UserErrorBoundary>
              <QueryClientProvider client={queryClient}>
                <AuthProvider authClientGetter={getAuthClientInstance}>
                  <NotifierProvider>
                    <ConfirmProvider>{children}</ConfirmProvider>
                  </NotifierProvider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </UserErrorBoundary>
          </I18nProvider>
        </ThemeProvider>
      </Router>
    </TechErrorBoundary>
  );
};

export default AppProviders;
