import React, { memo, useEffect } from 'react';

import { DistributorUserForm } from '@core/components/distributors';

import { useCreateFOUser } from '@core/queries';

import { useEssentials } from '@core/contexts';

import { Button, Grid } from '@shared/components';

interface Props {
  distributorId: string;
  mode: 'CREATE' | 'PRIMARY' | 'SURVEY';
  inModal?: boolean;
  onClose: (id?: string, mode?: 'CREATE' | 'PRIMARY' | 'SURVEY') => void;
  setIsLoading?: (isLoading: boolean) => void;
}
const CreateDistributorUserForm: React.FCC<Props> = ({
  distributorId,
  mode,
  inModal = false,
  onClose = () => {},
  setIsLoading = () => {},
}) => {
  const { t } = useEssentials();
  const { run, mutation } = useCreateFOUser({
    companyId: distributorId,
    successMessage: 'The user was successfully created',
    onSuccess: ({ data }) => onClose(data?.id, mode),
  });

  useEffect(() => {
    setIsLoading(mutation.isLoading);
  }, [mutation.isLoading, setIsLoading]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <DistributorUserForm
          formId={'create-user'}
          onSubmit={run}
          errors={mutation.error?.response?.data}
          distributorId={distributorId}
          mode={mode}
        />
      </Grid>
      {!inModal && (
        <Grid item container justifyContent='flex-end' spacing={2}>
          <Grid item>
            <Button
              id='cancel-create-user'
              onClick={() => onClose()}
              type='button'
              color='secondary'
              variant='contained'
              disabled={mutation.isLoading}
            >
              {t('Cancel')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              id='submit-create-user'
              type='submit'
              color='primary'
              variant='contained'
              isLoading={mutation.isLoading}
              form={'create-user'}
            >
              {t('Save')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(CreateDistributorUserForm);
