import { omit } from 'lodash-es';

import * as React from 'react';

import { QuestionClassificationsField } from '@core/components/common';

import type { FormSelectProps } from '@shared/types';

import type {
  IFilterDefaultItem,
  IClassificationType,
  IThemeConfiguration,
} from '@inteliam/foundation/lib/types';

const QuestionClassificationsFilterer: React.FCC<
  FormSelectProps & {
    type: IClassificationType;
    questionnaireType?: IThemeConfiguration['questionnaireType'];
    defaultItem: IFilterDefaultItem;
  }
> = ({ type, questionnaireType, ...rest }) => {
  return (
    <QuestionClassificationsField
      type={type}
      questionnaireType={questionnaireType}
      {...omit(rest, ['defaultItem'])}
      defaultValue={[]}
      required
    />
  );
};

export default QuestionClassificationsFilterer;
