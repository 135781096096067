import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';
import { Transformers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterpriseDiscountsApi } from '@core/api';
import type { IDiscountEnums, IFormattedDiscountEnums } from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const useFetchDiscountEnums = (): InteliamUseQueryResult<
  IAPIResponse<IDiscountEnums> | undefined,
  BaseAxiosErrorResponse
> & {
  discountEnums: IFormattedDiscountEnums | undefined;
} => {
  const query = useQuery<
    IAPIResponse<IDiscountEnums> | undefined,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.DISCOUNT_ENUMS,
    () => {
      return EnterpriseDiscountsApi.getEnums();
    },
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: Number.POSITIVE_INFINITY,
    }
  );
  let discountEnums = undefined;
  if (query.data?.data !== undefined) {
    discountEnums = {
      types: Transformers.mapIntoOptionItem(
        query.data.data.types,
        (type) => type,
        (type) => type
      ),
      statuses: Transformers.mapIntoOptionItem(
        query.data.data.statuses,
        (status) => status,
        (status) => status
      ),
      periods: Transformers.mapIntoOptionItem(
        query.data.data.periods,
        (period) => period,
        (period) => period
      ),
    };
  }

  return {
    ...query,
    discountEnums,
  };
};

export default useFetchDiscountEnums;
