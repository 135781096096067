import { DEFAULT_ONE_TIME_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DistributorsApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ISubscriptionType,
} from '@inteliam/foundation/lib/types';

const useFetchAvailableSubscriptions = (): {
  subscriptions: ISubscriptionType[];
  isLoading: boolean;
} => {
  const { data, isLoading, isFetching } = useQuery<
    IAPIResponse<Array<ISubscriptionType>>,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.DISTRIBUTOR_SUBSCRIPTIONS,
    () => {
      return DistributorsApi.getAvailableSubscriptions();
    },
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: 1000 * 25,
    }
  );

  const subscriptions = Helpers.ensureValueAsArray(data?.data);

  return { subscriptions, isLoading: isLoading || isFetching };
};

export default useFetchAvailableSubscriptions;
