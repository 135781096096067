import React, { memo } from 'react';

import './style.scss';

const loaderVariants = {
  'no-radius': { borderRadius: 0 },
  'rounded': {
    borderRadius: '50%',
  },
  'rounded-20': {
    borderRadius: '20%',
  },
};
export interface LoaderProps {
  style?: React.CSSProperties;
  variant?: 'no-radius' | 'rounded' | 'rounded-20';
}

const ImgLoader: React.FCC<LoaderProps> = ({
  style,
  variant = 'rounded',
  ...props
}) => {
  return (
    <div
      className='img-loader'
      {...props}
      style={{ ...loaderVariants[variant], ...style }}
    ></div>
  );
};

export default memo(ImgLoader);
