/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import { useFetchAnswerEvaluationByARAndClassifications } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IEvaluationQuery } from '@core/types';

import { CenteredSpinner, Alert } from '@shared/components';

import { AnswersAnalysisQuestionItem } from './internals';

interface Props {
  assessmentRequestId: string;
  themeId: string;
  query: IEvaluationQuery;
}

const AnswersAnalysisTable: React.FCC<Props> = ({
  assessmentRequestId,
  themeId,
  query,
}) => {
  const answerEvaluationByARAndThemeQuery =
    useFetchAnswerEvaluationByARAndClassifications({
      assessmentRequestId,
      themeId,
    });
  const { t } = useEssentials();
  if (!answerEvaluationByARAndThemeQuery.isSettled) {
    return <CenteredSpinner />;
  }
  if (!answerEvaluationByARAndThemeQuery.isSuccess) {
    return (
      <Alert color='error'>
        {t(
          answerEvaluationByARAndThemeQuery.error?.response?.data.message ||
            'Something went wrong'
        )}
      </Alert>
    );
  }
  return (
    <>
      {answerEvaluationByARAndThemeQuery.data.data.answersEvaluations?.map(
        (answerEvaluation) => {
          return (
            <AnswersAnalysisQuestionItem
              key={answerEvaluation.id}
              {...{ query, assessmentRequestId, themeId, answerEvaluation }}
              referenceProgress={
                answerEvaluationByARAndThemeQuery.data?.data.referencesProgress[
                  answerEvaluation.answer.field.answerId
                ]
              }
            />
          );
        }
      )}
    </>
  );
};

export default React.memo(AnswersAnalysisTable);
