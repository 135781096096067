import { Yup } from '@inteliam/foundation/lib/utils';

export const translationFieldSchema = (required = false): Yup.AnyObjectSchema =>
  Yup.object().shape({
    defaultValue: required ? Yup.string().required() : Yup.string(),
    translations: Yup.array()
      .of(
        Yup.object().shape({
          locale: Yup.string().required(),
          content: Yup.string().required(),
        })
      )
      .nullable(),
  });

export const questionTranslationFieldSchema = (
  required = false
): Yup.AnyObjectSchema =>
  Yup.object().shape({
    defaultValue: required ? Yup.string().required() : Yup.string(),
    translations: Yup.object().nullable(),
  });
