/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import { clsx } from 'clsx';

import { useEssentials } from '@core/contexts';

import { Grid, Button, Popover, Tooltip } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IOptionFieldDropdownSchema } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflow: 'hidden',
  },
  selectedOption: {
    background: `${theme.palette.primary.main}`,
  },
}));

type Props = {
  dropdownSchema: IOptionFieldDropdownSchema;
};
const AnswersAnalysisDropdown: React.FCC<Props> = ({ dropdownSchema }) => {
  const classes = useStyles();
  const { t } = useEssentials();

  return (
    <Grid container className={'truncate'} justifyContent='center'>
      <Popover.Container>
        <Popover.Trigger
          component={Tooltip}
          title={t('Click to see dropdown options')}
        >
          <Button id='open-dropdown'>{t('Dropdown')}</Button>
        </Popover.Trigger>
        <Popover.Content>
          <div>
            {dropdownSchema.options?.map((option) => {
              const checked = dropdownSchema.defaultValue
                ? dropdownSchema.defaultValue.includes(option.value)
                : false;

              return (
                <Grid
                  item
                  xs={12}
                  key={option.value}
                  className={clsx({
                    [classes.root]: true,
                    [classes.selectedOption]: checked,
                  })}
                >
                  {option.label}
                </Grid>
              );
            })}
          </div>
        </Popover.Content>
      </Popover.Container>
    </Grid>
  );
};

export default AnswersAnalysisDropdown;
