import {
  Accordion as MUIAccordion,
  AccordionProps as MUIAccordionProps,
  AccordionDetails as MUIAccordionDetails,
  AccordionDetailsProps as MUIAccordionDetailsProps,
  AccordionSummary as MUIAccordionSummary,
  AccordionSummaryProps as MUIAccordionSummaryProps,
} from '@mui/material';

import * as React from 'react';

type AccordionProps = MUIAccordionProps;
type AccordionSummaryProps = MUIAccordionSummaryProps & { id: string };
type AccordionDetailsProps = MUIAccordionDetailsProps;

const Container: React.FCC<AccordionProps> = ({ id, ...rest }) => {
  return <MUIAccordion {...rest} id={id} data-cy={id} />;
};

const AccordionSummary: React.FCC<AccordionSummaryProps> = (props) => {
  return (
    <MUIAccordionSummary
      style={{
        borderBottom: '1px solid #e0e0e0',
      }}
      {...props}
      data-cy={props.id}
    />
  );
};

const AccordionDetails: React.FCC<AccordionDetailsProps> = (props) => {
  return (
    <MUIAccordionDetails
      style={{ display: 'block' }}
      data-cy={props.id}
      {...props}
    />
  );
};

export default {
  Container,
  Summary: AccordionSummary,
  Details: AccordionDetails,
};
