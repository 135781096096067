import { Transformers } from '@inteliam/foundation/lib/utils';

import React from 'react';

import { useFetchCampaigns } from '@core/queries';

import { Controls, CircularProgress } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

interface Props extends Omit<FormSelectProps, 'options'> {
  companyId: string;
  onlyActive?: boolean;
  allOption?: boolean;
}

const CampaignField: React.FCC<Props> = ({
  companyId,
  onlyActive = false,
  allOption = false,
  ...rest
}) => {
  const { campaigns, isSettled } = useFetchCampaigns({
    conditions: onlyActive
      ? [
          {
            column: 'owner.id',
            operator: 'eq',
            value: companyId,
          },
        ]
      : [
          {
            column: 'owner.id',
            operator: 'eq',
            value: companyId,
          },
          {
            column: 'open',
            operator: 'eq',
            value: 'true',
          },
        ],
  });

  if (!isSettled) {
    return <CircularProgress size={16} />;
  }

  return (
    <Controls.FormSelect
      {...rest}
      style={{ width: 200 }}
      multiple
      options={Transformers.mapIntoOptionItem(campaigns, 'name', 'id')}
      enableSelectAll={allOption}
    />
  );
};

export default CampaignField;
