import { IReviewKindEnum } from '@inteliam/foundation/lib/enums';

import type {
  IAnyAssessmentReviewPayload,
  IAnswerEvaluation,
} from '@core/types';

import type { IAR, IReview } from '@inteliam/foundation/lib/types';

export const normalize = {
  instantiate: (
    kind: IReviewKindEnum,
    {
      reviewId,
      answerEvaluation,
      assessmentRequestId,
    }: {
      assessmentRequestId: IAR['id'];
      reviewId: IReview['id'];
      answerEvaluation: IAnswerEvaluation;
    }
  ): IAnyAssessmentReviewPayload => {
    const baseAssessmentReview = {
      answerEvaluation: {
        id: answerEvaluation.id,
      },
      theme: {
        id: answerEvaluation.answer.classification.theme.id,
      },
      assessmentRequest: {
        id: assessmentRequestId,
      },
    };
    return kind === IReviewKindEnum.Strength
      ? {
          ...baseAssessmentReview,
          kind,
          ['strength']: {
            id: reviewId,
          },
        }
      : {
          ...baseAssessmentReview,
          kind,
          ['weakness']: {
            id: reviewId,
          },
        };
  },
};
