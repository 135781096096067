import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useResources } from '@core/queries';

import { ResourcesApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { DropzoneArea, ErrorHelpText, FormControl } from '@shared/components';

import type { FormDropzoneAreaProps } from '@shared/types';

import type { IResource } from '@inteliam/foundation/lib/types';

// TODO : it needs a Unit Test ASAP
const FormDropzoneArea: React.FCC<FormDropzoneAreaProps> = (props) => {
  const { t } = useEssentials();
  const {
    name,
    label,
    helperText,
    defaultValues,
    acceptedFiles = [],
    filesLimit,
    readOnly,
    disabled,
    resourceReader = ResourcesApi.getResourceDownloadablePath,
    createFunction,
    ...rest
  } = props;
  const manageResource = useResources({
    createFunction,
  });
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const initialValue = defaultValues || getValues(name);
  const errorPath = filesLimit === 1 ? `${name}.id` : name;
  const errorMessage = get(errors, `${errorPath}.message`) as unknown as string;

  return (
    <FormControl
      fullWidth
      disabled={disabled || readOnly}
      data-disabled={disabled || readOnly}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={initialValue}
        render={({ field }) => {
          let initialFiles: Array<{
            file: File;
            resource: IResource;
          }> = [];
          if (field.value) {
            initialFiles =
              filesLimit === 1
                ? [
                    {
                      resource: field.value,
                      file: new File(
                        [],
                        field.value.originalFileName as string,
                        {
                          type: field.value.mimeType as string,
                        }
                      ),
                    },
                  ]
                : field.value.map((resource: IResource) => ({
                    file: new File([], resource.originalFileName || '', {
                      type: resource.mimeType || '',
                    }),
                    resource,
                  }));
          }

          return (
            <DropzoneArea
              {...rest}
              showPreviews
              useChipsForPreview
              acceptedFiles={acceptedFiles}
              dropzoneText={
                manageResource.mutation.isLoading ? 'Loading .... ' : label
              }
              onChange={async (files: Array<File>) => {
                const resources = await manageResource.handlers.uploadMany(
                  files
                );
                const nextValue = filesLimit === 1 ? resources[0] : resources;
                setValue(name, nextValue);
              }}
              filesLimit={filesLimit}
              initialFiles={initialFiles}
              inputProps={{
                name: name,
              }}
              onDelete={(_, deletedIndex) => {
                const formFiles = getValues(name) as Array<unknown>;
                const nextValue =
                  filesLimit === 1
                    ? // eslint-disable-next-line unicorn/no-null
                      null
                    : formFiles.filter(
                        (_, fileIndex) => deletedIndex !== fileIndex
                      );
                setValue(name, nextValue);
              }}
              resourceReader={resourceReader}
            />
          );
        }}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`} error={Boolean(errorMessage)}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormControl>
  );
};

export default memo(FormDropzoneArea);
