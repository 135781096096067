import { IARPhaseEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useFetchAssignableUsers } from '@core/queries';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { Card, Controls, Grid, FormGroup, Divider } from '@shared/components';

const AssignmentManagement: React.FCC = () => {
  const { t } = useEssentials();
  const { users: engagementSpecialists } = useFetchAssignableUsers({
    phase: IARPhaseEnum.QUALIFICATION,
  });

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Sponsor(s)')} subheader={t('')} />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                multiple={true}
                fullWidth
                name='engagementAssignees'
                label={t('Sponsor(s)')}
                options={UsersUtils.getFormattedEngagementSpecialistsForPhase(
                  engagementSpecialists
                )}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(AssignmentManagement);
