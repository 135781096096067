import { Transformers } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { useManageAssessmentRequest } from '@core/queries';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

interface Props extends FormSelectProps {
  companyId: string;
}
const AssessmentRequestField: React.FCC<Props> = ({ companyId, ...rest }) => {
  const { getAllByCompany } = useManageAssessmentRequest({
    getAllByCompany: { args: { id: companyId } },
  });

  return (
    <Controls.FormSelect
      {...rest}
      style={{ width: '100%' }}
      multiple
      options={
        getAllByCompany.isSuccess
          ? Transformers.mapIntoOptionItem(
              getAllByCompany.data.data,
              'businessId',
              'id'
            )
          : []
      }
    />
  );
};

export default AssessmentRequestField;
