import {
  ISsqQuestionKindEnum,
  IQuestionKindEnum,
} from '@inteliam/foundation/lib/enums';

import { default as MultipleChoiceField } from './multiple-choice-field';
import { default as SingleChoiceField } from './single-choice-field';
import { default as SingleFreeFormField } from './single-free-form-field';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const QUESTION_COMPONENTS: Record<
  ISsqQuestionKindEnum | IQuestionKindEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> = {
  [ISsqQuestionKindEnum.SingleFreeFormField]: SingleFreeFormField,
  [IQuestionKindEnum.SingleFreeFormField]: SingleFreeFormField,
  [ISsqQuestionKindEnum.SingleChoiceField]: SingleChoiceField,
  [IQuestionKindEnum.SingleChoiceField]: SingleChoiceField,
  [ISsqQuestionKindEnum.MultipleChoiceField]: MultipleChoiceField,
  [IQuestionKindEnum.MultipleChoiceField]: MultipleChoiceField,
};

export default QUESTION_COMPONENTS;
