import { IUserStatusEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Button } from '@shared/components';

import type { IUserUpdateStatusInput } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  open: boolean;
  onConfirm: (data: IUserUpdateStatusInput) => void;
  onClose: () => void;
}

const DeactivateUserModal: React.FCC<Props> = ({
  onClose,
  onConfirm,
  open,
}) => {
  const { t } = useEssentials();

  const handleConfirmation = () => {
    onConfirm({
      status: IUserStatusEnum.STATUS_DISABLED,
      sendEmail: false,
    });
  };

  return (
    <BaseDialog.Dialog keepMounted={false} open={open}>
      <BaseDialog.Title id='Deactivate user' onClose={onClose}>
        {t('Deactivate user')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        {t('Are you sure you want to deactivate the user ?')}
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-update-user-status'
          onClick={onClose}
          type='button'
          color='primary'
        >
          {t('No')}
        </Button>
        <Button
          id='submit-update-user-status'
          onClick={handleConfirmation}
          color='primary'
        >
          {t('Yes')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(DeactivateUserModal);
