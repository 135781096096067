import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorCodesLookup.E0003.title)
    // eslint-disable-next-line sonarjs/no-duplicate-string
    .required(ErrorCodesLookup.E0001.title),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      new RegExp(
        '^(?=.*\\d)(?=.*[A-Z])(?=.*[!@#$%^&*+=_(){}/?.,<>|;:\'"~-]).{8,}'
      ),
      ErrorCodesLookup.E0008.title
    )
    .required(ErrorCodesLookup.E0001.title),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], ErrorCodesLookup.E0009.title)
    .required(ErrorCodesLookup.E0001.title),
});

export const signinSchema = Yup.object().shape({
  username: Yup.string()
    .email(ErrorCodesLookup.E0003.title)
    .required(ErrorCodesLookup.E0001.title),
  password: Yup.string().required(ErrorCodesLookup.E0001.title),
});
