import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import type {
  IAnswerEvaluation,
  IAnyAssessmentReviewPayload,
  IAssessmentReviewReorderPayload,
} from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type {
  IAnyAssessmentReview,
  IAPIResponse,
  IAR,
  IPaginationQuery,
  IPaginationResponse,
  IPatchObject,
  IThemeClassification,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/assessment-requests';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const create = async (args: {
  assessmentRequestId: IAR['id'];
  assessmentReview: IAnyAssessmentReviewPayload;
}): Promise<IAPIResponse<IAnyAssessmentReview>> => {
  const { data } = await client.instance.post<
    IAPIResponse<IAnyAssessmentReview>
  >(`/${args.assessmentRequestId}/reviews`, args.assessmentReview);
  return data;
};

const patch = async (args: {
  assessmentRequestId: IAR['id'];
  patchObject: IPatchObject;
}): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch<IAPIResponse>(
    `/${args.assessmentRequestId}/reviews/${args.patchObject.id}`,
    args.patchObject.body
  );

  return data;
};

const reorder = async (
  args: IAssessmentReviewReorderPayload
): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch<IAPIResponse>(
    `/${args.assessmentRequestId}/reviews/${args.assessmentReviewId}/reorder/${args.order}`
  );

  return data;
};

const remove = async (args: {
  assessmentRequestId: IAR['id'];
  assessmentReviewId: IAnyAssessmentReview['id'];
}): Promise<IAPIResponse> => {
  const { data } = await client.instance.delete<IAPIResponse>(
    `/${args.assessmentRequestId}/reviews/${args.assessmentReviewId}`
  );
  return data;
};

const paginateByEvaluation = async (args: {
  assessmentRequestId: IAR['id'];
  answerEvaluationId: IAnswerEvaluation['id'];
  paginator: IPaginationQuery;
}): Promise<IPaginationResponse<IAnyAssessmentReview>> => {
  const { data } = await client.instance.get<
    IPaginationResponse<IAnyAssessmentReview>
  >(
    `/${args.assessmentRequestId}/evaluations/${args.answerEvaluationId}/reviews`,
    {
      params: args.paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );

  return data;
};

const paginateByTheme = async (args: {
  assessmentRequestId: IAR['id'];
  themeId: IThemeClassification['id'];
  paginator: IPaginationQuery;
}): Promise<IPaginationResponse<IAnyAssessmentReview>> => {
  const { data } = await client.instance.get<
    IPaginationResponse<IAnyAssessmentReview>
  >(`/${args.assessmentRequestId}/themes/${args.themeId}/reviews`, {
    params: args.paginator,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });

  return data;
};

export default {
  paginateByTheme,
  create,
  patch,
  remove,
  paginateByEvaluation,
  reorder,
};
