import type { IFilterTypeEnum } from '@inteliam/foundation/lib/enums';
import { Yup } from '@inteliam/foundation/lib/utils';

/* eslint-disable sonarjs/no-identical-functions */
import { FilterValidators } from './filterer/index';

const DEFAULT = Yup.string().required('This field requires your attention');

export const filterSchema = Yup.object().shape({
  conditions: Yup.array().of(
    Yup.object().shape({
      operator: Yup.string().required('required'),
      column: Yup.string().required('required'),
      filterFieldType: Yup.string(),
      propertyPath: Yup.string(),

      // FILTER VALUES STARTS HERE
      value: Yup.mixed().when(
        'filterFieldType',
        (filterFieldType: IFilterTypeEnum) => {
          return FilterValidators[filterFieldType] || DEFAULT;
          // return schema;
        }
      ),
    })
  ),
});
