import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

const cancelAssessmentSchema = Yup.object().shape({
  reason: Yup.string().required(ErrorCodesLookup.E0001.title),
  comments: Yup.string().required(),
});

export default cancelAssessmentSchema;
