import {
  ICompanyKindEnum,
  IDistributorRolesEnum,
  ILanguagesEnum,
  ITitlesEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { DISTRIBUTOR_ROLES } from '@core/enums';

import type {
  IDistributorUserForm,
  IDistributorUserPayload,
  IUpdateDistributorUserPayload,
  DistributorUserFormMode,
} from '@core/types';

import type { IFODistributorUser } from '@inteliam/foundation/lib/types';

export type UserStatusTranslationResponse = {
  translatedAction: string;
  translatedStatus: string;
  translatedStatusHover: string;
};

export const DISTRIBUTOR_ROLES_VALUES = new Set(
  Object.values(DISTRIBUTOR_ROLES).map((role) => role.value)
);

export const DEFAULT_CONTACT_FORM = {
  title: ITitlesEnum.MR,
  phoneNumber: '',
  email: '',
  language: ILanguagesEnum.EN_GB,
};
export const normalize = {
  userToFormData: (
    user: Partial<IFODistributorUser>,
    distributorId: string
  ): IDistributorUserForm => {
    return {
      user: {
        status: user.status || IUserStatusEnum.STATUS_DISABLED,
        company: { id: distributorId },
        contactInfo: user.contactInfo || DEFAULT_CONTACT_FORM,
        roles: [
          user.roles?.filter((role) =>
            DISTRIBUTOR_ROLES_VALUES.has(role)
          )?.[0] || IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED,
        ],
      },
      primaryContact: Boolean(user.primaryContact),
      questionnaireContact: Boolean(user?.questionnaireContact),
      questionnaireContactFor: Helpers.ensureValueAsArray(
        user?.questionnaireContactFor?.map((ar) => ar.id)
      ),
    };
  },
  formDataToBasePayload: (
    formData: IDistributorUserForm
  ): IDistributorUserPayload => {
    return {
      ...formData,
      questionnaireContactFor: Helpers.ensureValueAsArray(
        formData?.questionnaireContactFor?.map((id) => ({ id }))
      ),
      kind: ICompanyKindEnum.DISTRIBUTOR,
    };
  },
  basePayloadToUpdatePayload: (
    payload: IDistributorUserPayload,
    userId: string
  ): IUpdateDistributorUserPayload => {
    return {
      ...payload,
      user: {
        ...payload.user,
        id: userId,
      },
    };
  },
  initialFormData: (
    mode: DistributorUserFormMode,
    distributorId: string
  ): IDistributorUserForm => {
    return {
      user: {
        status: IUserStatusEnum.STATUS_DISABLED,
        contactInfo: DEFAULT_CONTACT_FORM,
        roles: [
          (['SURVEY', 'PRIMARY'] as Array<DistributorUserFormMode>).includes(
            mode
          )
            ? IDistributorRolesEnum.ROLE_DISTRIBUTOR_STANDARD
            : IDistributorRolesEnum.ROLE_DISTRIBUTOR_LIMITED,
        ],
        company: { id: distributorId },
      },
      questionnaireContactFor: [],
      questionnaireContact: false,
      primaryContact: false,
    };
  },
};
