import {
  IARPhaseEnum,
  IAssignableUser,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { DEFAULT_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { ARUtils, UsersUtils } from '@core/utils';

import { BO_ROLES } from '@core/enums';

import { UsersApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  IDynamicOptionItem,
  IPaginationResponse,
  IBOUser,
} from '@inteliam/foundation/lib/types';

const useFetchAssignableUsers = ({
  phase,
}: {
  phase: IARPhaseEnum;
}): {
  users: IBOUser[];
  formatted: IDynamicOptionItem<string, string>[];
  isLoading: boolean;
} => {
  const scope = ARUtils.isEngagementPhase(phase)
    ? IAssignableUser.ENGAGEMENT_SPECIALIST
    : IAssignableUser.ANALYST;

  const cacheKey =
    scope === IAssignableUser.ENGAGEMENT_SPECIALIST
      ? QUERY_KEYS.ENGAGEMENT_SPECIALISTS_LIST
      : QUERY_KEYS.ANALYSTS_LIST;

  const { data, isLoading, isFetching } = useQuery<
    IPaginationResponse<IBOUser>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.USERS_LIST, ...cacheKey],
    () => {
      return UsersApi.paginate({
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [
            DatatableUtils.createCondition(
              'roles',
              'in',
              Object.values(BO_ROLES)
                .filter((role) => role.tag === scope)
                .map((role) => role.value)
                .join(',')
            ),
            DatatableUtils.createCondition(
              'status',
              'eq',
              IUserStatusEnum.STATUS_ENABLED
            ),
            DatatableUtils.createCondition('kind', 'eq', 'BACK_OFFICE'),
          ],
          operator: 'and',
        },
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData: DatatableUtils.createInitialPaginationResponse<IBOUser>(),
    }
  );

  const users = Helpers.ensureValueAsArray(data?.data);
  let usersSelection: IDynamicOptionItem<string, string>[] = [];
  if (ARUtils.isEngagementPhase(phase)) {
    usersSelection =
      UsersUtils.getFormattedEngagementSpecialistsForPhase(users);
  } else if (ARUtils.isAnalystPhase(phase)) {
    usersSelection = UsersUtils.getFormattedAnalystsForPhase(users, phase);
  } else {
    usersSelection = [];
  }
  return {
    users,
    formatted: usersSelection,
    isLoading: isLoading || isFetching,
  };
};

export default useFetchAssignableUsers;
