import type { IContactInfo } from '@inteliam/foundation/lib/types';

export enum FullNameFormatEnum {
  J_DOT_DOE = 'J_DOT_DOE',
  JOHN_DOE = 'JOHN_DOE',
  JOHN_DOT_D = 'JOHN_DOT_D',
}
export type FormatFullNameFormat =
  | FullNameFormatEnum.J_DOT_DOE
  | FullNameFormatEnum.JOHN_DOE
  | FullNameFormatEnum.JOHN_DOT_D;

export const formatFullName = (
  contactInfo?: IContactInfo,
  format: FormatFullNameFormat = FullNameFormatEnum.J_DOT_DOE
): string => {
  if (contactInfo) {
    if (format === FullNameFormatEnum.JOHN_DOE) {
      return `${contactInfo.firstName || ''} ${contactInfo.lastName || ''}`;
    }
    if (format === FullNameFormatEnum.JOHN_DOT_D) {
      return `${contactInfo.firstName || ''}. ${
        contactInfo.lastName?.charAt(0) || ''
      }`;
    }
    return `${contactInfo.firstName?.charAt(0) || ''}. ${
      contactInfo.lastName || ''
    }`;
  } else {
    return '-';
  }
};
