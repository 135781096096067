import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components/questions';

import { useFetchSsqQuestionEnums } from '@core/queries';

import { QuestionsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { ISsqQuestionFormData } from '@core/types';

import {
  Card,
  Controls,
  FormGroup,
  Grid,
  FormFieldLoadingIndicator,
} from '@shared/components';

const BasicInformation: React.FCC = () => {
  const { questionEnums } = useFetchSsqQuestionEnums();
  const methods = useFormContext<ISsqQuestionFormData>();
  const { readOnly } = useQuestionFormContext();

  const { t } = useEssentials();
  const watchedHelpText = Boolean(
    useWatch({
      control: methods.control,
      name: 'helpText',
    })
  );

  return (
    <Card.CContainer>
      <Card.Header
        title='Basic Information'
        subheader='Fill in question basic information'
      />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={2} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Question code')}
                name='code'
                required
                readOnly={readOnly}
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              {questionEnums ? (
                <Controls.FormSelect
                  label={t('Question type')}
                  name='kind'
                  options={questionEnums.types}
                  required
                  readOnly={readOnly}
                  // onChange={(event) => {
                  //   // eslint-disable-next-line no-restricted-globals
                  //   if (confirm(t('questions.validations.on_change_kind'))) {
                  //     methods.setValue('options', []);
                  //   } else {
                  //     event.preventDefault();
                  //   }
                  // }}
                />
              ) : (
                <FormFieldLoadingIndicator />
              )}
            </FormGroup>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t('Mandatory')}
                name='required'
                readOnly={readOnly}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                row
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormObligatorinessField
                label={t('Attach document')}
                name='document'
                required
                disabled={readOnly}
              />
            </FormGroup>
          </Grid>

          <Grid item md={2} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Status')}
                name='status'
                options={QuestionsUtils.getStatusesOptions(t)}
                readOnly={readOnly}
                // disabled={
                //   !UsersUtils.isGranted(
                //     IBORolesEnum.ROLE_SENIOR_ANALYST,
                //     user
                //   ) || readOnly
                // }
                required
              />
            </FormGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Question title')}
                name='title.defaultValue'
                multiline
                rows={7}
                required
              />
            </FormGroup>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormCheckbox name='helpText' label={t('Help text')} />
              <Controls.FormRichTextEditor
                name='helpTextContent.defaultValue'
                // defaultValue={I18n.getTranslatedValue(
                //   defaultValues?.helpTextContent
                // )}
                readOnly={Boolean(!watchedHelpText)}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};
export default memo(BasicInformation);
