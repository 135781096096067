import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import {
  InteliamUseMutationResult,
  useQueryClient,
} from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { FrontOfficeUserApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { IAnyFOUserCreatePayload } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IAnyFOUser,
} from '@inteliam/foundation/lib/types';

const useCreateFOUser = ({
  companyId,
  onSuccess,
  successMessage,
}: {
  companyId: string;
  successMessage: string;
  onSuccess: (data: IAPIResponse<Partial<IAnyFOUser>>) => void;
}): {
  run: (data: IAnyFOUserCreatePayload) => void;
  mutation: InteliamUseMutationResult<
    IAPIResponse<Partial<IAnyFOUser>>,
    AxiosValidationErrorResponse,
    IAnyFOUserCreatePayload
  >;
} => {
  const { t, notify } = useEssentials();
  const queryClient = useQueryClient();

  const mutation = useMutation<
    IAPIResponse<IAnyFOUser>,
    AxiosValidationErrorResponse,
    IAnyFOUserCreatePayload
  >(
    (payload) =>
      payload.kind === ICompanyKindEnum.DISTRIBUTOR
        ? FrontOfficeUserApi.createDistributorUser({
            payload,
            companyId,
          })
        : FrontOfficeUserApi.createEnterpriseUser({
            payload: payload.user,
            companyId,
          }),
    {
      onSuccess: (data, variable) => {
        const kind =
          variable.kind === ICompanyKindEnum.DISTRIBUTOR
            ? variable.kind
            : ICompanyKindEnum.SUPPLIER;
        notify({
          message: t(successMessage),
          type: INotifierEnum.Success,
        });
        onSuccess?.(data);
        queryClient
          .invalidateQueries(QUERY_KEYS.COMPANY_USERS_LIST(kind, companyId))
          .catch(() => {});
      },
    }
  );

  const run = useCallback(
    (body: IAnyFOUserCreatePayload) => {
      mutation.mutate(body);
    },
    [mutation]
  );

  return {
    run,
    mutation,
  };
};
export default useCreateFOUser;
