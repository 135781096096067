import type { FileObjectShape } from './dropzone-area-base';

export function isImage(file: FileObjectShape['file']): boolean {
  return file.type.split('/')[0] === 'image';
}

export function convertBytesToMbsOrKbs(fileSize: number): string {
  let size = '';
  if (fileSize >= 1_048_576) {
    size = `${fileSize / 1_048_576} megabytes`;
  } else if (fileSize >= 1024) {
    size = `${fileSize / 1024}  kilobytes`;
  } else {
    size = `${fileSize} bytes`;
  }
  return size;
}

export async function createFileFromUrl(url: string): Promise<File> {
  const response = await fetch(url);
  const data = await response.blob();
  const metadata = { type: data.type };
  const filename = url.replace(/\?.+/, '').split('/').pop() || 'unnamed';
  return new File([data], filename, metadata);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readFile(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      resolve(event?.target?.result);
    });
    reader.addEventListener('error', (event) => {
      reader.abort();
      reject(event);
    });
    reader.readAsDataURL(file);
  });
}
