import { IQuestionStatusEnum } from '@inteliam/foundation/lib/enums';
import { useOnMount } from '@inteliam/foundation/lib/hooks';
import { I18n } from '@inteliam/foundation/lib/utils';
import { isString } from 'lodash-es';

import * as React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useFetchSiblingQuestions } from '@core/queries';

import { QuestionReviewLinkConfigurationUtils } from '@core/utils';

import { INotifierEnum, useEssentials } from '@core/contexts';
import type { QuestionOptionConfiguration, ReviewFormData } from '@core/types';

import {
  Accordion,
  AddCircleIcon,
  Alert,
  Box,
  Button,
  Card,
  CenteredSpinner,
  Controls,
  Divider,
  ExpandMoreIcon,
  FormGroup,
  Grid,
  Typography,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type { IAnyQuestion } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  open: boolean;
  onClose: () => void;
  themeId: string;
  criterionId: string;
}

const formatQuestion = (question: IAnyQuestion) => ({
  label: `${question.code} [V${question.version}]. ${I18n.getTranslatedValue(
    question.title
  )}`,
  value: question.id,
});
const DEFAULT_QUESTION_CONFIG: Partial<QuestionOptionConfiguration> = {
  id: undefined,
};

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));
const ManageQuestionReviewLinking: React.FCC<Props> = (props) => {
  const { t } = useEssentials();
  const classes = useStyles();
  const methods = useFormContext<ReviewFormData>();
  const siblingQuery = useFetchSiblingQuestions({
    query: {
      theme: props.themeId,
      criteria: props.criterionId,
      statuses: [IQuestionStatusEnum.draft, IQuestionStatusEnum.active],
    },
  });
  const { append: addConfiguration } = useFieldArray({
    name: 'questionReviewLinkConfigurations',
    control: methods.control,
  });

  const watchedQuestionsConfig =
    useWatch({
      control: methods.control,
      name: 'questions',
    }) || [];

  const { append } = useFieldArray<ReviewFormData, 'questions'>({
    control: methods.control,
    name: 'questions',
  });

  useOnMount(() => {
    methods.setValue('questions', [DEFAULT_QUESTION_CONFIG]);
  });

  const onSubmit = (questions: Array<IAnyQuestion>) => {
    watchedQuestionsConfig.forEach((watchedQuestion) => {
      if (watchedQuestion.options && watchedQuestion.id) {
        watchedQuestion.options.forEach((option) => {
          const selectedConditions = option.conditions.filter((condition) =>
            isString(condition)
          );
          const selectedQuestion = questions.find(
            (questionDB) => questionDB.id === watchedQuestion.id
          );
          const selectedOption = selectedQuestion?.options?.find(
            (optionDB) => optionDB.id === option.id
          );
          if (
            selectedConditions.length > 0 &&
            selectedQuestion &&
            selectedOption
          ) {
            addConfiguration({
              question: {
                id: watchedQuestion.id as string,
                code: selectedQuestion.code,
                title: selectedQuestion.title,
              },
              questionOptionId: selectedOption.id,
              questionOptionLabel: selectedOption.label,
              conditions: selectedConditions,
            });
          }
        });
      }
    });
  };

  if (!siblingQuery.isSettled) {
    return <CenteredSpinner />;
  }
  return (
    <BaseDialog.Dialog
      keepMounted={true}
      open={props.open}
      onClose={props.onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title id='modals.title' onClose={props.onClose}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          {t('Question options linking')}
        </Grid>
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <Card.CContainer>
          {siblingQuery.isError && (
            <Alert severity={INotifierEnum.Error} className={classes.alert}>
              {t(
                siblingQuery.error?.response?.data.message ||
                  'An unknown error has occurred.'
              )}
            </Alert>
          )}
          {siblingQuery.isSuccess && (
            <>
              {watchedQuestionsConfig.map((questionConfig, index) => {
                const selectedQuestion = siblingQuery.data.data.find(
                  (questionDB) => questionDB.id === questionConfig.id
                );
                return (
                  <Card.Body key={questionConfig.id}>
                    <FormGroup>
                      <Controls.FormSelect
                        fullWidth
                        name={`questions.${index}.id`}
                        label={t('Select question')}
                        options={siblingQuery.data.data.map((question) =>
                          formatQuestion(question)
                        )}
                      />
                    </FormGroup>
                    {selectedQuestion?.options?.map((option, optionIndex) => (
                      <Accordion.Container
                        key={option.id}
                        defaultExpanded={true}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <Accordion.Summary
                          id={`${option.id}-accordion`}
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography>
                            {t('Option {{index}} : {{title}}', {
                              index: optionIndex + 1,
                              title: I18n.getTranslatedValue(option.label),
                            })}
                          </Typography>
                        </Accordion.Summary>
                        <Accordion.Details>
                          <FormGroup>
                            <Controls.FormCheckboxGroup
                              className='p-4'
                              onChange={() =>
                                methods.setValue(
                                  `questions.${index}.options.${optionIndex}.id`,
                                  option.id
                                )
                              }
                              name={`questions.${index}.options.${optionIndex}.conditions`}
                              label={t('Select linking conditions')}
                              options={QuestionReviewLinkConfigurationUtils.getConditionsOptions(
                                t
                              )}
                            />
                          </FormGroup>
                        </Accordion.Details>
                      </Accordion.Container>
                    ))}
                    <Divider />
                  </Card.Body>
                );
              })}
              {watchedQuestionsConfig && (
                <Box display='flex' justifyContent='flex-start' p={1}>
                  <Button
                    id='add-question-to-be-linked'
                    color='primary'
                    variant='contained'
                    className='p-4'
                    onClick={() => {
                      append(DEFAULT_QUESTION_CONFIG);
                    }}
                  >
                    <AddCircleIcon />
                    {t('Add question')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </Card.CContainer>
        <BaseDialog.Actions>
          <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
            <Button
              id='save-links'
              color='primary'
              variant='contained'
              onClick={() => {
                onSubmit(siblingQuery.data?.data ?? []);
                props.onClose();
              }}
            >
              {t('Save')}
            </Button>
          </Box>
        </BaseDialog.Actions>
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default ManageQuestionReviewLinking;
