import { ApiFactory } from '@inteliam/foundation/lib/api';
import { QueryString } from '@inteliam/foundation/lib/utils';

import { isISsqQuestion } from '@core/guards';
import type {
  IAnyPaginatedSsqQuestion,
  IQuestionEnums,
  ISsqQuestionPayload,
} from '@core/types';

import {
  getAuthClientInstance,
  Constants,
  DatatableUtils,
} from '@shared/utils';

import type {
  IAnySsqQuestion,
  IAPIResponse,
  IAnyEnterprise,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type QuestionActions = 'getOneById' | 'update' | 'create' | 'patch';

const baseSsqQuestionsApi = ApiFactory.generate<
  IAnySsqQuestion,
  JwtBOUser,
  QuestionActions,
  ISsqQuestionPayload,
  ISsqQuestionPayload
>({
  baseRoute: '/ssq-questions',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),

  guard: isISsqQuestion,
});
const getEnums = async (): Promise<IAPIResponse<IQuestionEnums>> => {
  const { data } = await baseSsqQuestionsApi.client.instance.get<
    IAPIResponse<IQuestionEnums>
  >('/enums');
  return data;
};

const paginate = async (
  manufacturerId: IAnyEnterprise['id'],
  paginator: IPaginationQuery
): Promise<IPaginationResponse<IAnyPaginatedSsqQuestion>> => {
  const query: IPaginationQuery = {
    ...paginator,
    primaryCriteria: {
      conditions: [
        DatatableUtils.createCondition('manufacturer.id', 'eq', manufacturerId),
      ],
      operator: 'and',
    },
  };
  const { data } = await baseSsqQuestionsApi.client.instance.get<
    IPaginationResponse<IAnyPaginatedSsqQuestion>
  >('', {
    params: query,
    paramsSerializer: {
      serialize: QueryString.serialize,
    },
  });
  return data;
};

export default {
  ...baseSsqQuestionsApi.actions,
  getEnums,
  paginate,
};
