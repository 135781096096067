import { ErrorTracking } from '@inteliam/foundation/lib/utils';

import type { ErrorInfo } from 'react';

import { Constants } from '@shared/utils';

const environment =
  Constants.NODE_ENV === 'development' ? 'development' : 'production';

export const report = (
  error: Error | unknown,
  source?: string,
  errorInfo?: ErrorInfo,
  extras: Array<{ key: string; value: unknown }> = []
): void => {
  ErrorTracking.report({
    error,
    errorInfo,
    environment: environment,
    extras,
    source,
  });
};
