import React, { memo, useEffect } from 'react';

import { DistributorUserForm } from '@core/components/distributors';

import { useManageUsers, useUpdateFOUser } from '@core/queries';

import { DistributorUsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { Button, CenteredSpinner, Grid } from '@shared/components';

import type { IFODistributorUser } from '@inteliam/foundation/lib/types';

interface Props {
  distributorId: string;
  userId: string;
  mode: 'UPDATE' | 'PRIMARY' | 'SURVEY';
  inModal?: boolean;
  onClose: () => void;
  setIsLoading?: (isLoading: boolean) => void;
}
const UpdateDistributorUserForm: React.FCC<Props> = ({
  userId,
  distributorId,
  mode,
  inModal = false,
  onClose,
  setIsLoading = () => {},
}) => {
  const { t, notify, INotifierEnum } = useEssentials();
  const { getOneById } = useManageUsers<IFODistributorUser>({
    getOneById: {
      args: { id: userId },
      options: {
        enabled: true,
        onError(error) {
          notify({
            message: t(error.response?.data.message || 'Something went wrong'),
            type: INotifierEnum.Error,
          });
          onClose();
        },
      },
    },
  });
  const { run, mutation } = useUpdateFOUser({
    companyId: distributorId,
    successMessage: 'The user was successfully updated',
    onSuccess: () => onClose(),
  });

  useEffect(() => {
    setIsLoading(mutation.isLoading);
  }, [mutation.isLoading, setIsLoading]);
  if (!getOneById.isSettled) {
    return <CenteredSpinner />;
  }
  if (!getOneById.data) {
    return <span>{t('User not found')}</span>;
  }
  const user = getOneById.data.data;
  return (
    <>
      {getOneById.isSuccess && (
        <Grid container>
          <Grid item xs={12}>
            <DistributorUserForm
              formId={`update-${userId}`}
              onSubmit={(body) => {
                if (mode === 'PRIMARY' || mode === 'SURVEY') {
                  run(
                    DistributorUsersUtils.normalize.basePayloadToUpdatePayload(
                      {
                        ...body,
                        questionnaireContactFor: user?.questionnaireContactFor,
                      },
                      user.id
                    )
                  );
                } else {
                  run(
                    DistributorUsersUtils.normalize.basePayloadToUpdatePayload(
                      body,
                      user.id
                    )
                  );
                }
              }}
              defaultValues={user}
              errors={mutation.error?.response?.data}
              distributorId={distributorId}
              mode={mode}
            />
          </Grid>
          {!inModal && (
            <Grid item container justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button
                  id='cancel-update-user'
                  onClick={onClose}
                  type='button'
                  color='secondary'
                  variant='contained'
                  disabled={mutation.isLoading}
                >
                  {t('Cancel')}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  id='submit-create-user'
                  type='submit'
                  color='primary'
                  variant='contained'
                  isLoading={mutation.isLoading}
                  form={`update-${userId}`}
                >
                  {t('Save')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default memo(UpdateDistributorUserForm);
