import { IUserStatusEnum } from '@inteliam/foundation/lib/enums';

import ReactivateUserModal from './reactivate-user';
import React, { memo } from 'react';

import type { IUserUpdateStatusInput } from '@inteliam/foundation/lib/types';

import ActivateUserModal from './activate-user';
import DeactivateUserModal from './deactivate-user';

interface Props {
  status: IUserStatusEnum;
  open: boolean;
  onClose: () => void;
  onConfirm: (body: IUserUpdateStatusInput) => void;
}

const UpdateUserStatusModal: React.FCC<Props> = ({
  status,
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <>
      {IUserStatusEnum.STATUS_ENABLED === status && (
        <DeactivateUserModal
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
      {IUserStatusEnum.STATUS_DISABLED === status && (
        <ReactivateUserModal
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
      {IUserStatusEnum.STATUS_PENDING === status && (
        <ActivateUserModal
          open={open}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
};

export default memo(UpdateUserStatusModal);
