import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { Helpers, I18n, Transformers } from '@inteliam/foundation/lib/utils';

import React, { memo, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import {
  useFetchClassificationHierarchy,
  useFetchSiblingQuestions,
} from '@core/queries';

import { QuestionsUtils } from '@core/utils';
import { QuestionI18nHelpers } from '@core/utils/i18n';

import { useEssentials, useQuestionContext } from '@core/contexts';
import type { IQuestionForm, IQuestionOptionForm } from '@core/types';

import {
  AddCircleIcon,
  Box,
  Card,
  Controls,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from '@shared/components';

import type {
  IAnyQuestion,
  IQuestion,
  IQuestionDependency,
  ISiblingsQuery,
  StringOrUndefined,
} from '@inteliam/foundation/lib/types';

import { LogicItem } from './';

const formatQuestions = (question: IQuestion) => ({
  label: `${question.code} [V${question.version}]. ${I18n.getTranslatedValue(
    question.title
  )}`,
  value: question.id,
});

const availableSiblings = ({
  questionId,
  watchedChildren,
  watchedParentId,
  siblings,
  type = 'children',
}: {
  siblings: IQuestion[];
  questionId?: string;
  watchedParentId?: string;
  watchedChildren?: IQuestionDependency[];
  type?: 'parent' | 'children';
}) => {
  const selectedSiblings: string[] = (watchedChildren || []).reduce(
    (result: string[], child) => {
      if (child?.question?.id && child.question.id !== questionId) {
        result.push(child.question.id);
      }
      return result;
    },
    []
  );
  if (watchedParentId && watchedParentId !== questionId) {
    selectedSiblings.push(watchedParentId);
  }

  return siblings.filter((sibling) => {
    if (
      type === 'parent' &&
      sibling.kind === IQuestionKindEnum.SingleFreeFormField
    ) {
      return false;
    }
    return !selectedSiblings.includes(sibling.id);
  });
};

const getParentOptions = (
  siblings: IAnyQuestion[],
  watchedParentId: unknown
) => {
  const parent = siblings.find((question) => question.id === watchedParentId);
  if (!parent || parent?.kind === IQuestionKindEnum.SingleFreeFormField) {
    return [];
  }
  const options = Helpers.ensureValueAsArray(parent?.options);
  return Transformers.mapIntoOptionItem(
    options,
    (option) => I18n.getTranslatedValue(option.label),
    'id'
  );
};

const getChildOptions = (
  questionOptions: IQuestionOptionForm[] | undefined
) => {
  return Transformers.mapIntoOptionItem(
    Helpers.ensureValueAsArray(questionOptions),
    (option) => QuestionI18nHelpers.getTranslatedFormValue(option.label),
    (option) => option.id || Helpers.uuid()
  );
};
const Logic: React.FCC = () => {
  const { t } = useEssentials();
  const { mode, readOnly, defaultValues } = useQuestionFormContext();
  const methods = useFormContext<IQuestionForm>();
  const [canSetLogic, canSetLogicHandlers] = useBoolean(mode === 'EDIT');
  const questionOptions = useWatch({
    control: methods.control,
    name: 'options',
  });
  const [query, setQuery] = useState<ISiblingsQuery | undefined>();
  const questionContext = useQuestionContext();
  const siblingQuery = useFetchSiblingQuestions({ query });
  const { data: siblings } = siblingQuery;
  const classificationQuery = useFetchClassificationHierarchy({
    reviewsAssignableOnly: false,
    questionnaireType: questionContext.questionnaireType,
  });

  const {
    fields: children,
    append,
    remove,
  } = useFieldArray<IQuestionForm, 'childrenDependencies', 'rhfId'>({
    control: methods.control,
    name: 'childrenDependencies',
    keyName: 'rhfId',
  });
  const watchedClassification = useWatch({
    control: methods.control,
    name: ['theme', 'criterion'],
  });
  const watchedKind = useWatch({
    control: methods.control,
    name: 'kind',
  });
  const watchedParentId = useWatch({
    control: methods.control,
    name: 'parentDependency.question.id',
  }) as StringOrUndefined;

  // TODO Remove parent question from children question list and vice versa
  const childOptions = getChildOptions(questionOptions);
  const parentOptions = getParentOptions(
    Helpers.ensureValueAsArray(siblings?.data),
    watchedParentId
  );

  useEffect(() => {
    const themeId = watchedClassification[0]?.id;
    const query: ISiblingsQuery | undefined =
      QuestionsUtils.resolveSiblingQuery(
        watchedClassification,
        classificationQuery.data?.data || [],
        themeId
      );

    if (query) {
      setQuery({ ...query, exclude: defaultValues?.id });
      canSetLogicHandlers.setTrue();
    } else {
      canSetLogicHandlers.setFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationQuery.data?.data, watchedClassification]);
  return (
    <Card.CContainer
      styled
      style={canSetLogic ? undefined : { opacity: 0.3, pointerEvents: 'none' }}
    >
      <Card.Header title={t('Logic')} />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <Typography component='h1' variant='h6'>
            {t('Link to question parent')}
          </Typography>
        </Box>
        {siblingQuery.isSuccess && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Name of parent question')}
                  name='parentDependency.question.id'
                  options={availableSiblings({
                    siblings: Helpers.ensureValueAsArray(siblings?.data),
                    watchedChildren: children,
                    watchedParentId,
                    questionId: methods.getValues(
                      'parentDependency.question.id'
                    ),
                    type: 'parent',
                    // eslint-disable-next-line unicorn/no-array-callback-reference
                  }).map(formatQuestions)}
                  readOnly={readOnly}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Select a parent question options')}
                  name='parentDependency.optionIds'
                  options={parentOptions}
                  disabled={parentOptions.length === 0}
                  defaultValue=''
                  readOnly={readOnly}
                  multiple
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </Card.Body>
      <Divider />
      {QuestionsUtils.isDependable({ kind: watchedKind }) && (
        <Card.Body>
          <Box p={2}>
            <Typography component='h1' variant='h6'>
              {t('Link to question children')}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            {children?.map(
              (child, index) =>
                siblings?.data && (
                  <LogicItem
                    key={child.rhfId}
                    index={index}
                    onRemove={remove}
                    child={child}
                    siblings={availableSiblings({
                      siblings: siblings.data,
                      watchedChildren: children,
                      watchedParentId,
                      questionId: child?.question?.id,
                      // eslint-disable-next-line unicorn/no-array-callback-reference
                    }).map(formatQuestions)}
                    childOptions={childOptions}
                    readOnly={readOnly}
                  />
                )
            )}
            <Grid item xs={1}>
              {siblings?.data &&
                !readOnly &&
                availableSiblings({
                  siblings: siblings?.data,
                  watchedChildren: children,
                  watchedParentId: watchedParentId as string | undefined,
                  questionId: undefined,
                }).length > 0 && (
                  <IconButton
                    id={'add-child-question'}
                    color='primary'
                    onClick={() =>
                      append({ optionIds: [], question: { id: '' } })
                    }
                    size='large'
                  >
                    <AddCircleIcon />
                  </IconButton>
                )}
            </Grid>
          </Grid>
        </Card.Body>
      )}
    </Card.CContainer>
  );
};

export default memo(Logic);
