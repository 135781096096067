import { yupResolver } from '@hookform/resolvers/yup';
import {
  IBORolesEnum,
  IReviewStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  I18n,
  Transformers,
  Validations,
} from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { FormActions } from '@core/components';

import {
  useFetchClassificationHierarchy,
  useLanguagesByScope,
} from '@core/queries';

import { QuestionsUtils, ReviewsUtils, UsersUtils } from '@core/utils';

import { REVIEW_ENUMS } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { ReviewFormData, ReviewPayload } from '@core/types';

import {
  Controls,
  TranslationFieldInput,
  Grid,
  FormGroup,
  Card,
  Divider,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

import { CreationSchema } from '..';
import QuestionReviewLinkConfigurationsTable from './question-review-link-configurations-table';

interface Props {
  defaultValues?: ReviewFormData;
  onSubmit: (data: ReviewPayload) => void;
  title: string;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  readOnly?: boolean;
}

const ReviewForm: React.FCC<Props> = ({
  defaultValues,
  onSubmit,
  isLoading,
  readOnly,
  errors,
  title,
}) => {
  const classificationQuery = useFetchClassificationHierarchy({
    reviewsAssignableOnly: true,
  });
  const { t, user } = useEssentials();
  const { formattedLanguages } = useLanguagesByScope({
    scope: 'FrontOfficeAdministration',
  });
  const defaultLanguage = formattedLanguages.filter(
    (item) => item.value === I18n.defaultLocale
  );
  const methods = useForm<ReviewFormData>({
    defaultValues: ReviewsUtils.normalizer.initializeForm(defaultValues),
    resolver: yupResolver<
      typeof CreationSchema.updatePublishedReview | typeof CreationSchema.create
    >(readOnly ? CreationSchema.updatePublishedReview : CreationSchema.create),
  });
  const isAllowedToPublish = UsersUtils.isGranted(
    IBORolesEnum.ROLE_SENIOR_ANALYST,
    user
  );
  const watchedTheme = useWatch({
    control: methods.control,
    name: 'theme.id',
  });

  const watchedCriterion = useWatch({
    control: methods.control,
    name: 'criterion.id',
  });

  const selectedTheme = classificationQuery.data?.data.find(
    (item) => item.id === watchedTheme
  );
  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  useOnUpdate(() => {
    if (selectedTheme && selectedTheme.children.length === 1) {
      methods.setValue('criterion.id', selectedTheme.children[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme]);

  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={(formData) => {
        onSubmit(ReviewsUtils.normalizer.formDataToPayload(formData));
      }}
    >
      <Card.CContainer styled>
        <Card.Header title={title}></Card.Header>
        <Divider />
        <Card.Body>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Type')}
                  name='kind'
                  options={REVIEW_ENUMS.TYPES}
                  required
                  readOnly={
                    defaultValues &&
                    defaultValues.status !== IReviewStatusEnum.STATUS_DRAFT
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <Controls.FormInput
                  label={t('Code')}
                  name='code'
                  required
                  readOnly={readOnly}
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormGroup>
                {classificationQuery.isSuccess && (
                  <Controls.FormSelect
                    label={t('Theme')}
                    name='theme.id'
                    options={Transformers.mapIntoOptionItem(
                      classificationQuery.data.data,
                      (theme) => I18n.getTranslatedValue(theme.name),
                      'id'
                    )}
                    required
                    readOnly={readOnly}
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Criterion')}
                  name='criterion.id'
                  options={
                    !selectedTheme || selectedTheme.isLeaf
                      ? []
                      : Transformers.mapIntoOptionItem(
                          selectedTheme.children,
                          (criterion) =>
                            I18n.getTranslatedValue(criterion.name),
                          'id'
                        )
                  }
                  readOnly={!selectedTheme || selectedTheme.isLeaf || readOnly}
                  required
                />
              </FormGroup>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Status')}
                  name='status'
                  options={QuestionsUtils.getStatusesOptions(t)}
                  required
                  readOnly={!isAllowedToPublish || readOnly}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Card.Body>
      </Card.CContainer>
      <Card.CContainer styled>
        <Card.Body>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('English')}
                  name='locale.default'
                  options={defaultLanguage}
                  required
                  disabled
                />
              </FormGroup>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormGroup>
                <Controls.FormInput
                  name='description.defaultValue'
                  label={t('Description {{index}}')}
                  required
                  readOnly={readOnly}
                />
              </FormGroup>
            </Grid>
            <Grid item md={12} xs={12}>
              <TranslationFieldInput
                readOnly={readOnly}
                name='description'
                defaultValue={
                  typeof defaultValues?.description === 'object'
                    ? defaultValues?.description
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Card.Body>
      </Card.CContainer>
      <QuestionReviewLinkConfigurationsTable
        themeId={watchedTheme}
        criterionId={watchedCriterion}
      />
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};

export default memo(ReviewForm);
