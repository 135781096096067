import React, { memo } from 'react';

import { QuestionsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { Controls } from '@shared/components';

import type { FormSelectProps } from '@shared/types';

type Props = Omit<FormSelectProps, 'options'>;
const FormObligatorinessField: React.FCC<Props> = (props) => {
  const { t } = useEssentials();
  return (
    <Controls.FormSelect
      options={QuestionsUtils.getObligatorinessOptions(t)}
      {...props}
    />
  );
};
export default memo(FormObligatorinessField);
