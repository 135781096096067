import * as React from 'react';

import { Button, RouterLink, Box } from '..';
import { AddCircleIcon } from '../design-system';

type Props = {
  label: string;
  id: string;
  position?: 'end' | 'start';
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: React.CSSProperties;
};
const justifyContentMap = {
  start: 'flex-start',
  end: 'flex-end',
};
const CreateButton: React.FCC<Props> = ({
  label,
  to,
  onClick,
  id,
  position = 'start',
  style = undefined,
}) => {
  const _renderButton = () => {
    if (to)
      return (
        <RouterLink
          color='inherit'
          {...{ to, id }}
          style={{
            textDecoration: 'none',
          }}
        >
          <Button color='primary' variant='contained' {...{ onClick, id }}>
            {label} <AddCircleIcon />
          </Button>
        </RouterLink>
      );
    else if (onClick)
      return (
        <Button color='primary' variant='contained' {...{ onClick, id }}>
          {label} <AddCircleIcon />
        </Button>
      );
    return undefined;
  };
  return (
    <Box
      display='flex'
      alignItems='center'
      padding={2}
      justifyContent={justifyContentMap[position]}
      style={{ position: 'absolute', zIndex: 1, ...style }}
    >
      {_renderButton()}
    </Box>
  );
};
export default CreateButton;
