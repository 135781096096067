import * as React from 'react';

import { ButtonToggler } from '@core/components';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';
import type { IDocumentEvaluation } from '@core/types';

type Props = {
  label?: string;
  documentEvaluation: IDocumentEvaluation;
  onSuccess?: () => void;
};

const ReviewsDocumentStatusSwitch: React.FCC<Props> = ({
  documentEvaluation,
  onSuccess,
  label,
}) => {
  const mutation = useMutation(DocumentEvaluationsApi.evaluate, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.DOCUMENT_EVALUATIONS],
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  return (
    <ButtonToggler
      id={documentEvaluation.document.name}
      currentStatus={documentEvaluation.status}
      onEvaluate={(status) => {
        mutation.mutate({
          id: documentEvaluation.id,
          status,
        });
      }}
      label={label}
    />
  );
};

export { ReviewsDocumentStatusSwitch };
