import { IBORolesEnum } from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import {
  FormCompanyScoreInput,
  FormCompanyWeightSelect,
} from '@core/components';

import { useScoreCardHandlers } from '@core/hooks';

import { ScoreCardUtils, UsersUtils } from '@core/utils';

import { THEMES_LABELS } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { IScoringForm } from '@core/types';

import {
  Box,
  Button,
  Card,
  Controls,
  EditIcon,
  Typography,
  VisibilityIcon,
} from '@shared/components';

import type { IPartialScore } from '@inteliam/foundation/lib/types';

type Props = {
  partialScore: IPartialScore;
  scoreCardId: string;
  themeIndex: number;
  isEditable: boolean;
  readOnly: boolean;
  setIsEditable: (index?: number) => void;
};

const ThemeCard: React.FCC<Props> = ({
  themeIndex,
  partialScore,
  scoreCardId,
  isEditable,
  readOnly,
  setIsEditable,
}) => {
  const { t, user, theme } = useEssentials();
  const isSeniorAnalyst = UsersUtils.isGranted(
    IBORolesEnum.ROLE_SENIOR_ANALYST,
    user
  );
  const { methods, onUpdate } = useScoreCardHandlers();

  const applyChanges = (data: IScoringForm) => {
    setIsEditable(undefined);
    onUpdate(
      {
        [`partialScores[${themeIndex}].scoringMeta.score`]: data.score,
        [`partialScores[${themeIndex}].scoringMeta.weight`]: data.weight,
      },
      scoreCardId
    );
  };
  const enabledThemeScoring = partialScore.configuration?.enabledThemeScoring;

  return (
    <Card.CContainer style={{ height: '150px' }}>
      <Card.Body
        style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isEditable ? (
          <Controls.Form
            methods={methods}
            submitHandler={applyChanges}
            style={{ width: '100%' }}
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              width='100%'
            >
              <FormCompanyScoreInput
                defaultScore={
                  partialScore.score === undefined
                    ? undefined
                    : Math.round(partialScore.score)
                }
                readOnly={readOnly}
              />
              <FormCompanyWeightSelect
                readOnly={readOnly}
                defaultWeight={partialScore.weight}
                id={`${partialScore.theme.code}-theme-weight`}
              />
            </Box>
            <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
              <Button
                id={`cancel-edit-${partialScore.theme.code}`}
                type='button'
                onClick={() => setIsEditable(undefined)}
              >
                {t('Cancel')}
              </Button>
              {!readOnly && (
                <Button
                  id={`edit-${partialScore.theme.code}`}
                  type='submit'
                  color='primary'
                >
                  {t('Apply')}
                </Button>
              )}
            </Box>
          </Controls.Form>
        ) : (
          <Box>
            <Typography
              color='textSecondary'
              gutterBottom
              style={{ textAlign: 'center' }}
            >
              {t(THEMES_LABELS[partialScore.theme.code])}
            </Typography>
            <Typography
              variant='h5'
              component='h2'
              style={{ textAlign: 'center', marginTop: 20 }}
            >
              <I18n.ReactI18NextInstance.Trans
                i18nKey={'<0>Score: </0> <1>{{score}}</1><2> / 100</2>'}
                components={[
                  <span key='0' style={{ fontSize: '1rem' }} />,
                  <span
                    key='1'
                    style={{
                      color:
                        enabledThemeScoring === true
                          ? ScoreCardUtils.colorForValue(
                              partialScore.score || 0
                            )
                          : theme.palette.text.secondary,
                    }}
                  />,
                  <span key='2' style={{ fontSize: '1rem' }} />,
                ]}
                values={{
                  score:
                    enabledThemeScoring === true
                      ? Math.round(partialScore.score || 0)
                      : '-',
                }}
              />
              {isSeniorAnalyst && !readOnly && (
                <Button
                  id={`edit-${partialScore.theme.code}`}
                  type='submit'
                  onClick={() => setIsEditable(themeIndex)}
                >
                  <EditIcon />
                </Button>
              )}
              {isSeniorAnalyst && readOnly && (
                <Button
                  id={`edit-${partialScore.theme.code}`}
                  type='submit'
                  onClick={() => setIsEditable(themeIndex)}
                >
                  <VisibilityIcon />
                </Button>
              )}
            </Typography>
          </Box>
        )}
      </Card.Body>
    </Card.CContainer>
  );
};

export default React.memo(ThemeCard);
