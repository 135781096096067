import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import type { ISingleChoiceQuestionForm } from '@core/types';

import { Grid } from '@shared/components';

import { BaseLanguageForm, LanguageOptionItem } from './index';

interface Props {
  // question: IPartialSingleChoiceQuestion;
  locale: ILanguagesEnum | 'defaultValue';
}
const SingleChoiceForm: React.FCC<Props> = ({ locale }) => {
  const methods = useFormContext<ISingleChoiceQuestionForm>();
  const watchedOptions =
    useWatch({
      control: methods.control,
      name: 'options',
    }) || [];
  return (
    <Grid container spacing={3}>
      <BaseLanguageForm locale={locale} />

      {watchedOptions?.map((option, index) => {
        return (
          <LanguageOptionItem
            key={option.id}
            option={option}
            locale={locale}
            index={index}
          />
        );
      })}
    </Grid>
  );
};

export default memo(SingleChoiceForm);
