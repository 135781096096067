import { useBoolean } from '@inteliam/foundation/lib/hooks';
import { StringFormatter } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { SizeHelper } from '@core/components/common';
import { CreateNewsModal } from '@core/components/modals';

import { useEssentials } from '@core/contexts';

import {
  Button,
  Tooltip,
  Typography,
  Grid,
  Box,
  Card,
  AddCircleIcon,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IAR } from '@inteliam/foundation/lib/types';

type Props = {
  assessmentRequest: IAR;
  displayNewsData?: boolean;
};
const useStyles = makeStyles((theme) => ({
  custom: {
    minHeight: 360,
  },
  bg: {
    background: theme.palette.background.default,
    padding: '0 10px',
    borderRadius: '10px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.anchor?.main,
    textDecoration: 'underline',
  },
}));

const ReviewDistributorInfo: React.FCC<Props> = ({
  assessmentRequest,
  displayNewsData = true,
}) => {
  const { t } = useEssentials();
  const [newsOpen, handlers] = useBoolean(false);

  const classes = useStyles();
  return (
    <Box>
      <Card.CContainer className={classes.custom}>
        <Card.Body>
          <div>
            <Box display='flex' justifyContent={'space-between'}>
              <Typography variant='h4'>
                {assessmentRequest?.scoredCompany.name}
              </Typography>
              <Typography variant='h4'>
                {displayNewsData && (
                  <>
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                      {t('Global Scan')} {'('}
                      <span data-cy='news-count'>
                        {assessmentRequest.threeSixtyCount > 0
                          ? assessmentRequest.threeSixtyCount
                          : t('No news added')}
                      </span>
                      {')'}
                    </Typography>
                    <Button
                      id='add-news'
                      variant='contained'
                      color='primary'
                      onClick={handlers.toggle}
                    >
                      <AddCircleIcon />
                      {t('Add a News')}
                    </Button>
                    {newsOpen && (
                      <CreateNewsModal
                        open={newsOpen}
                        onClose={handlers.setFalse}
                        assessmentRequestId={assessmentRequest?.id}
                      />
                    )}
                  </>
                )}
              </Typography>
            </Box>

            <Grid container>
              <Grid container item xs={12}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t('AR ID')}</TableCell>
                      <TableCell>{assessmentRequest?.businessId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Requested by')}</TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            assessmentRequest?.campaign?.owner?.name || '--'
                          }
                        >
                          <div className='truncate'>
                            {assessmentRequest?.campaign?.owner?.name || '--'}
                          </div>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Campaign')}</TableCell>
                      <TableCell>
                        {assessmentRequest?.campaign?.name || '--'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Country')}</TableCell>
                      <TableCell>
                        {
                          assessmentRequest?.assessmentQualificationData.country
                            .name
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Category')}</TableCell>
                      <TableCell>
                        {t(
                          assessmentRequest?.assessmentQualificationData
                            .category
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Box display={'flex'}>
                          {t('Size')}
                          <SizeHelper />
                        </Box>
                      </TableCell>
                      <TableCell>
                        {`${assessmentRequest?.assessmentQualificationData.size} (${assessmentRequest?.scoredCompany.numberOfEmployees} Employees)`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('Website')}</TableCell>
                      <TableCell>
                        <a
                          className={classes.link}
                          href={assessmentRequest?.scoredCompany.website}
                          target='_blank'
                          rel='noreferrer'
                          id={`website-${assessmentRequest.businessId}`}
                        >
                          <span>
                            {assessmentRequest?.scoredCompany.website}
                          </span>
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography
                          variant='body1'
                          style={{ fontWeight: 'bold' }}
                        >
                          {assessmentRequest?.usePrimaryContactAsQuestionnaireContact
                            ? t('Contact')
                            : t('Primary contact')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Box>
                            <Box>
                              <Typography variant='body1' display='inline'>
                                {StringFormatter.formatName(
                                  assessmentRequest?.scoredCompany
                                    .primaryContact.contactInfo?.firstName,
                                  assessmentRequest?.scoredCompany
                                    .primaryContact.contactInfo?.lastName
                                )}
                              </Typography>
                              <Box className={classes.bg} display='inline'>
                                {t('Primary')}
                              </Box>
                              {assessmentRequest?.usePrimaryContactAsQuestionnaireContact && (
                                <Box className={classes.bg} display='inline'>
                                  {t('Questionnaire')}
                                </Box>
                              )}
                            </Box>

                            <Typography
                              variant='body1'
                              style={{ wordBreak: 'break-word' }}
                            >
                              {
                                assessmentRequest?.scoredCompany.primaryContact
                                  .contactInfo?.email
                              }
                            </Typography>
                            <Typography variant='body1'>
                              {
                                assessmentRequest?.scoredCompany.primaryContact
                                  .contactInfo?.phoneNumber
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {assessmentRequest?.usePrimaryContactAsQuestionnaireContact ===
                      false && (
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant='body1'
                            style={{ fontWeight: 'bold' }}
                          >
                            {t('Questionnaire contact')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Box>
                              <Typography variant='body1' display='inline'>
                                {StringFormatter.formatName(
                                  assessmentRequest?.questionnaireContact
                                    ?.contactInfo?.firstName,
                                  assessmentRequest?.questionnaireContact
                                    ?.contactInfo?.lastName
                                )}
                              </Typography>
                              <Box className={classes.bg} display='inline'>
                                {t('Questionnaire')}
                              </Box>
                            </Box>

                            <Typography variant='body1'>
                              {
                                assessmentRequest?.questionnaireContact
                                  ?.contactInfo?.email
                              }
                            </Typography>
                            <Typography variant='body1'>
                              {assessmentRequest?.questionnaireContact
                                ?.contactInfo?.phoneNumber || '-'}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </div>
        </Card.Body>
      </Card.CContainer>
    </Box>
  );
};

export { ReviewDistributorInfo };
