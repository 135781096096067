import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { useModalState } from '@inteliam/foundation/lib/hooks';
import { MathUtils } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo } from 'react';

import { ScoreInput } from '@core/components';
import { TextBoxValueRenderer } from '@core/components/assessment-requests/answers-analysis-v2/internals';
import { CreateCommentModal } from '@core/components/modals';

import { useWorkingPage } from '@core/hooks';

import { useEssentials, useWorkingPageContext } from '@core/contexts';
import type { AnswerAnalysisFieldPropsV2 } from '@core/types';

import {
  Box,
  Button,
  FormGroup,
  TableCell,
  AddCommentIcon,
} from '@shared/components';

import { AnswerAnalysisQuestionCell, AnswerAnalysisRow } from '../internals';

const QUESTION_ANSWER_COLUMNS = [
  {
    label: 'Option Text',
  },
  {
    label: 'Max Score',
  },
  {
    label: 'Adjusted Max Score',
  },
  {
    label: 'Points Rules',
  },
  {
    label: 'Final Score',
  },
];

const SingleFreeFormField: React.FCC<AnswerAnalysisFieldPropsV2> = ({
  answerEvaluation,
  themeIndex,
  criterionIndex,
  questionIndex,
}) => {
  const { scoreCard } = useWorkingPage();
  const { readOnly } = useWorkingPageContext();
  const { t } = useEssentials();
  const { answer } = answerEvaluation;
  if (answer.field.kind !== IQuestionKindEnum.SingleFreeFormField) {
    throw new Error('This code should never be reached [SingleFreeFormField]');
  }
  const { field } = answer;
  const [modalState, modalHandlers] = useModalState<string>();

  // For free form questions we consider that we have only one option that contains the answer
  const optionIndex = 0;
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const criterionPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];
  const questionPartialScore =
    criterionPartialScore.questionsPartialScores[questionIndex];
  const optionPartialScore =
    questionPartialScore.optionsPartialScores[optionIndex];
  const hasComment = !isNil(questionPartialScore.scoringMeta.comment);
  const fieldName = `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].questionsPartialScores[${questionIndex}]`;

  return (
    <>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.FullWidth>
              <AnswerAnalysisQuestionCell
                themeIndex={themeIndex}
                criterionIndex={criterionIndex}
                questionIndex={questionIndex}
                answerEvaluation={answerEvaluation}
              />
            </Layouts.FullWidth>
          );
        }}
      </AnswerAnalysisRow>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.ScoringSectionTitle>
              <h3 className='font-weight-bold p-1'>{t('Scoring')}</h3>
              <Button
                onClick={modalHandlers.onOpenCreate}
                id={'create-comment'}
              >
                <AddCommentIcon color={hasComment ? 'primary' : 'disabled'} />
              </Button>
            </Layouts.ScoringSectionTitle>
          );
        }}
      </AnswerAnalysisRow>

      <AnswerAnalysisRow>
        {QUESTION_ANSWER_COLUMNS.map((column, index) => {
          return (
            <TableCell
              style={{ fontWeight: 'bold', padding: 0 }}
              key={index}
              align='center'
            >
              {t(column.label)}
            </TableCell>
          );
        })}
      </AnswerAnalysisRow>

      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <>
              <Layouts.Question>
                <Box display={'flex'}>
                  <TextBoxValueRenderer
                    format={field.definition.format}
                    value={field.defaultValue}
                  />
                </Box>
              </Layouts.Question>
              <Layouts.MaxScore>
                <FormGroup>
                  <ScoreInput
                    readOnly={readOnly}
                    name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.maxScore`}
                    defaultValue={optionPartialScore.scoringMeta.maxScore}
                    additionalUpdate={{
                      [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                        'QUESTIONS_BASED_SCORING',
                    }}
                  />
                </FormGroup>
              </Layouts.MaxScore>
              <Layouts.AdjustedMaxScore>
                <FormGroup>
                  <ScoreInput
                    name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.adjustedMaxScore`}
                    key={optionPartialScore.scoringMeta.adjustedMaxScore}
                    defaultValue={MathUtils.precisionRound(
                      optionPartialScore.scoringMeta.adjustedMaxScore,
                      {
                        precision: 2,
                      }
                    )}
                    readOnly
                  />
                </FormGroup>
              </Layouts.AdjustedMaxScore>
              <Layouts.PointsRules>
                <FormGroup>
                  <ScoreInput
                    readOnly={readOnly}
                    name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.ratio`}
                    defaultValue={optionPartialScore.scoringMeta.ratio}
                    step={0.1}
                    min={0}
                    max={1}
                    decimal
                    additionalUpdate={{
                      [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                        'QUESTIONS_BASED_SCORING',
                    }}
                  />
                </FormGroup>
              </Layouts.PointsRules>
              <Layouts.FinalScore>
                <FormGroup>
                  <ScoreInput
                    name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.score`}
                    key={optionPartialScore.scoringMeta.score}
                    defaultValue={MathUtils.precisionRound(
                      optionPartialScore.scoringMeta.score,
                      {
                        precision: 2,
                      }
                    )}
                    readOnly
                  />
                </FormGroup>
              </Layouts.FinalScore>
            </>
          );
        }}
      </AnswerAnalysisRow>

      {modalState.mode === 'CREATE' && (
        <CreateCommentModal
          questionPartialScore={questionPartialScore}
          fieldName={fieldName}
          scoreCard={scoreCard}
          questionId={answerEvaluation.answer.field.id}
          open={modalState.mode === 'CREATE'}
          onClose={modalHandlers.onClose}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default memo(SingleFreeFormField);
