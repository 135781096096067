import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { EnterpriseDiscountsForm } from '@core/components';

import { QUERY_KEYS, useManageEnterpriseDiscounts } from '@core/queries';

import { INotifierEnum, useEssentials } from '@core/contexts';

import type { IIdentifiedObject } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  onClose: () => void;
}

const CreateEnterpriseDiscountModal: React.FCC<Props> = ({ onClose }) => {
  const { notify, t } = useEssentials();
  const queryClient = useQueryClient();
  const { id } = useParams<IIdentifiedObject>();

  const { create } = useManageEnterpriseDiscounts({
    onSuccess: () => {
      notify({
        message: t('A new discount was added.'),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries([...QUERY_KEYS.MANUFACTURER_DISCOUNTS_LIST(id)])
        .catch(() => {});
      onClose();
    },
  });
  return (
    <BaseDialog.Dialog keepMounted={false} open={true} onClose={onClose}>
      <BaseDialog.Title
        id='modals.manufacturer_discount.title'
        onClose={onClose}
      >
        {t('Add a new discount')}
      </BaseDialog.Title>
      <BaseDialog.Content>
        <EnterpriseDiscountsForm
          onSubmit={(data) => {
            create.run(id, data);
          }}
          isLoading={create.mutation.isLoading}
          onClose={onClose}
        />
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateEnterpriseDiscountModal);
