import { IAnalysisHighlightsKindEnum } from '@inteliam/foundation/lib/enums';
import { assertIsTyped } from '@inteliam/foundation/lib/guards';
import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { isIAnalysisHighlight } from '@core/guards';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  ICustomAnalysisHighlight,
  Id,
  IMasterAnalysisHighlight,
  IPaginationQuery,
  IPaginationResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/analysis-highlights';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const remove = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight
>(
  id: Id,
  kind: TKind
): Promise<IAPIResponse<THighlight>> => {
  const { data } = await client.instance.delete<IAPIResponse<THighlight>>(
    `/${kind}/${id}`
  );
  return data;
};

const create = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlightForm = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? Partial<IMasterAnalysisHighlight>
    : ICustomAnalysisHighlight,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight
>(
  payload: THighlightForm,
  kind: TKind
): Promise<IAPIResponse<THighlight>> => {
  const { data } = await client.instance.post<IAPIResponse<THighlight>>(
    `/${kind}`,
    payload
  );
  assertIsTyped(data.data, isIAnalysisHighlight);
  return data;
};

const getOneById = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight
>(
  id: string,
  kind: TKind
): Promise<IAPIResponse<THighlight>> => {
  const { data } = await client.instance.get(`/${kind}/${id}`);
  assertIsTyped(data.data, isIAnalysisHighlight);
  return data;
};

const patch = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight
>(
  patchObject: IPatchObject,
  kind: TKind
): Promise<IAPIResponse<THighlight>> => {
  const { data } = await client.instance.patch(
    `/${kind}/${patchObject.id}`,
    patchObject.body
  );
  return data;
};

const paginate = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? IMasterAnalysisHighlight
    : ICustomAnalysisHighlight
>(
  paginator: IPaginationQuery,
  kind: TKind
): Promise<IPaginationResponse<THighlight>> => {
  const { data } = await client.instance.get<IPaginationResponse<THighlight>>(
    `/${kind}`,
    {
      params: paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  return data;
};

const update = async <
  TKind extends IAnalysisHighlightsKindEnum,
  THighlightForm = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? Partial<IMasterAnalysisHighlight>
    : ICustomAnalysisHighlight,
  THighlight = TKind extends IAnalysisHighlightsKindEnum.MASTER
    ? Partial<IMasterAnalysisHighlight>
    : ICustomAnalysisHighlight
>(
  { body, id }: IPatchObject<THighlightForm>,
  kind: TKind
): Promise<IAPIResponse<THighlight>> => {
  const { data } = await client.instance.put(`/${kind}/${id}`, body);
  return data;
};

const getAvailableCategories = async (): Promise<
  IAPIResponse<Array<string>>
> => {
  const { data } = await client.instance.get(
    `${IAnalysisHighlightsKindEnum.MASTER}/get-available/categories`
  );
  return data;
};

export default {
  remove,
  create,
  getOneById,
  patch,
  paginate,
  update,
  getAvailableCategories,
};
