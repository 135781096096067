import { yupResolver } from '@hookform/resolvers/yup';
import {
  IARStatusEnum,
  IBORolesEnum,
  INewsStatusEnum,
} from '@inteliam/foundation/lib/enums';
import {
  DateFormatter,
  I18n,
  Transformers,
} from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions } from '@core/components';

import {
  useFetchClassificationHierarchy,
  useManageAssessmentRequest,
} from '@core/queries';

import { NewsUtils, UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { INewsForm } from '@core/types';

import { Controls, ErrorHelpText, FormGroup, Grid } from '@shared/components';

import type { IPartialNews } from '@inteliam/foundation/lib/types';

import { NewsCreationSchema } from '../';

interface Props {
  defaultValues?: IPartialNews;
  onSubmit: (data: INewsForm) => void;
  onCancel?: () => void;
  assessmentRequestId: string;
  isLoading: boolean;
  readOnly?: boolean;
  mode: 'CREATE' | 'EDIT';
}

const NewsForm: React.FCC<Props> = ({
  defaultValues,
  onSubmit,
  onCancel,
  isLoading,
  mode = 'CREATE',
  assessmentRequestId,
}) => {
  const { t, user } = useEssentials();
  const { getOneById } = useManageAssessmentRequest({
    getOneById: { args: { id: assessmentRequestId } },
  });

  const classificationQuery = useFetchClassificationHierarchy();
  const methods = useForm<INewsForm>({
    defaultValues: defaultValues
      ? NewsUtils.normalize.newsToFormData(defaultValues, assessmentRequestId)
      : NewsUtils.normalize.getInitialValues(assessmentRequestId),
    resolver: yupResolver<typeof NewsCreationSchema.create>(
      NewsCreationSchema.create
    ),
  });

  const shouldShowWarning =
    mode === 'EDIT' &&
    !UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user) &&
    !UsersUtils.isGranted(
      IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST,
      user
    ) &&
    getOneById.data?.data.status === IARStatusEnum.PUBLISHED;
  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={onSubmit}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormInput
              label={t('Link')}
              name='website'
              required
              readOnly={shouldShowWarning}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormDatePicker
              inputFormat={DateFormatter.FORMATS.en_GB.SHORT}
              name='publishedAt'
              label={t('News Date')}
              pickerType='date'
              textFieldProps={{
                required: true,
              }}
              componentsProps={{
                actionBar: {
                  actions: ['today'],
                },
              }}
              disabled={shouldShowWarning}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            {classificationQuery.isSuccess && (
              <Controls.FormSelectTypeahead
                label={t('Themes')}
                name='themes'
                options={Transformers.mapIntoOptionItem(
                  classificationQuery.data?.data,
                  (theme) => I18n.getTranslatedValue(theme.name),
                  'id'
                )}
                multiple
                disabled={shouldShowWarning}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormInput
              name='title'
              label={t('Title')}
              required
              readOnly={shouldShowWarning}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormInput
              label={t('AR')}
              name='assessmentRequests.0.id'
              defaultValue={assessmentRequestId}
              required
              readOnly
            />
          </FormGroup>
          {shouldShowWarning && (
            <ErrorHelpText role='alert'>
              {t("You can't edit a news of published AR")}
            </ErrorHelpText>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <Controls.FormInput
              name='description'
              label={t('Text')}
              required
              placeholder={t('Add text related to the news here')}
              rows={3}
              multiline
              readOnly={shouldShowWarning}
            />
          </FormGroup>
        </Grid>
        <div hidden>
          <Controls.FormInput
            name='status'
            required
            defaultValue={INewsStatusEnum.STATUS_ACTIVE}
          />
        </div>
      </Grid>
      <FormActions
        isLoading={isLoading}
        onCancel={onCancel}
        cancelText='cancel'
      />
    </Controls.Form>
  );
};

export default memo(NewsForm);
