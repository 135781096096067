import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Tooltip, HelpIcon, Typography } from '@shared/components';

const SizeHelper: React.FC = () => {
  const { t } = useEssentials();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Tooltip
        title={
          <div>
            <Typography>{t('S : 0-50 employees')}</Typography>
            <Typography>{t('M : 51-200 employees')}</Typography>
            <Typography>{t('L : 201+ employees')}</Typography>
          </div>
        }
      >
        <HelpIcon style={{ position: 'relative', top: '-10px', left: '0px' }} />
      </Tooltip>
    </div>
  );
};

export default memo(SizeHelper);
