import { Yup } from '@inteliam/foundation/lib/utils';

import { translationFieldSchema } from '@shared/schemas';

export const create = Yup.object().shape({
  code: Yup.string().required(),
  description: translationFieldSchema(true),
  kind: Yup.string().required(),
  theme: Yup.object().shape({
    id: Yup.string().required(),
  }),
  status: Yup.string().required(),
  criterion: Yup.object().shape({
    id: Yup.string().required(),
  }),
  default: Yup.object().shape({
    locale: Yup.string().optional(),
  }),
});

export const updatePublishedReview = Yup.object().shape({});
