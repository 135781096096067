import { IHighlightsStatusEnum } from '@inteliam/foundation/lib/enums';
import { omit } from 'lodash-es';

import type { IMasterAnalysisHighlight } from '@inteliam/foundation/lib/types';

const DEFAULT_MASTER_HIGHLIGHT: Partial<IMasterAnalysisHighlight> = {
  code: '',
  description: { defaultValue: '', translations: [] },
  status: IHighlightsStatusEnum.STATUS_DRAFT,
};

export const normalizer = {
  initializeForm: (
    defaultValues?: Partial<IMasterAnalysisHighlight>
  ): Partial<IMasterAnalysisHighlight> => {
    return {
      ...DEFAULT_MASTER_HIGHLIGHT,
      ...defaultValues,
    };
  },
  formDataToPayload: (
    formData: Partial<IMasterAnalysisHighlight>
  ): Partial<IMasterAnalysisHighlight> => {
    return omit(formData, ['locale']);
  },
};
