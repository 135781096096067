import * as React from 'react';

import type { IEvaluationProgress } from '@core/types';

import { Typography, Box } from '@shared/components';

import { makeStyles } from '@shared/utils';

/* eslint-disable @inteliam/i18n/raw-text-detected */
const useStyles = makeStyles((theme) => ({
  root: {
    '& span': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    '& .pending': {
      color: 'gray',
    },
    '& .approved': {
      color: theme.palette.success.main,
    },
    '& .rejected': {
      color: theme.palette.error.main,
    },
  },
}));

interface Props {
  evaluationProgress: IEvaluationProgress;
}
const EvaluationInsights: React.FCC<Props> = ({ evaluationProgress }) => {
  const classes = useStyles();
  return (
    <Box display='flex'>
      {' '}
      <Typography variant='body2' className={classes.root}>
        (
        <span data-cy='pending-count' className='pending'>
          {evaluationProgress.pending}
        </span>
        |
        <span data-cy='approved-count' className='approved'>
          {evaluationProgress.approved}
        </span>
        |
        <span data-cy='rejected-count' className='rejected'>
          {evaluationProgress.rejected}
        </span>
        )
      </Typography>
    </Box>
  );
};

export default EvaluationInsights;
