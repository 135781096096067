import { DEFAULT_ONE_TIME_QUERY_OPTIONS } from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { QuestionsApi } from '@core/api';
import type { IQuestionEnums, IFormattedQuestionEnums } from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const mapIntoSelect = (kind: string) => ({
  value: kind,
  label: kind.toLowerCase(),
});

const useFetchQuestionEnums = (): {
  questionEnums: IFormattedQuestionEnums | undefined;
  isLoading: boolean;
} => {
  const { data, isLoading, isFetching } = useQuery<
    IAPIResponse<IQuestionEnums> | undefined,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.QUESTION_ENUMS,
    () => {
      return QuestionsApi.getEnums();
    },
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: Number.POSITIVE_INFINITY,
    }
  );
  let questionEnums = undefined;
  if (data?.data !== undefined) {
    questionEnums = {
      types: data.data.types.map((type) => mapIntoSelect(type)),
      textBoxTypes: data.data.textBoxTypes.map((textBoxType) =>
        mapIntoSelect(textBoxType)
      ),
    };
  }

  return {
    questionEnums,
    isLoading: isLoading || isFetching,
  };
};

export default useFetchQuestionEnums;
