import type {
  IBooleanSettingValue,
  INumericSettingValue,
  ISetting,
  ISettingValue,
  IStringSettingValue,
} from '@core/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isISetting(argument: any): argument is ISetting {
  return typeof argument.id === 'string';
}

export function isBooleanSettingValue(
  argument: ISettingValue
): argument is IBooleanSettingValue {
  return argument.type === 'boolean' && typeof argument.value === 'boolean';
}
export function isStringSettingValue(
  argument: ISettingValue
): argument is IStringSettingValue {
  return argument.type === 'string' && typeof argument.value === 'string';
}
export function isNumericSettingValue(
  argument: ISettingValue
): argument is INumericSettingValue {
  return argument.type === 'number' && typeof argument.value === 'number';
}
