/* eslint-disable @inteliam/i18n/raw-text-detected */
import { assertIsTyped, isIBOUser } from '@inteliam/foundation/lib/guards';
// Note: This should not be imported from core/queries because i dont need onError/onSuccess handlers
import { useQuery } from '@inteliam/foundation/lib/hooks';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import * as React from 'react';

import { FullPageSpinner } from '@shared/components';

import type { AuthInstanceGetter } from '@shared/utils';

import { useNamedContext } from '@shared/contexts';

import type {
  BaseAxiosErrorResponse,
  Dispatcher,
  IForgetPasswordPayload,
  ILoginPayload,
  IResetPasswordPayload,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

import { AuthContext } from './contexts';

export interface IUserContext {
  user: JwtBOUser | undefined;
  login: (input: ILoginPayload) => Promise<JwtBOUser | undefined>;
  logout: () => Promise<void>;
  resetPassword: (form: IResetPasswordPayload) => Promise<void>;
  forgetPassword: (form: IForgetPasswordPayload) => Promise<void>;
  setUser: Dispatcher<JwtBOUser | undefined>;
}
export interface IAuthenticatedUserContext extends IUserContext {
  user: JwtBOUser;
}

const INITIAL_CONTEXT: IUserContext = {
  user: undefined,
  login: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  forgetPassword: () => Promise.resolve(),
  setUser: () => {},
};

const useAuthHandlers = (authClientGetter: AuthInstanceGetter) => {
  const [user, setUser] = React.useState<JwtBOUser | undefined>(
    INITIAL_CONTEXT.user
  );
  const onSetUser: IUserContext['setUser'] = React.useCallback((user) => {
    if (isNil(user)) {
      setUser(undefined);
    } else {
      setUser(user);
    }
  }, []);
  const handlers = React.useMemo(
    () => ({
      login: async (form: ILoginPayload) => {
        const newUserContext = await authClientGetter().login(form);

        onSetUser(newUserContext);
        return newUserContext;
      },
      logout: () => {
        onSetUser(undefined);
        return AuthHelpers.logout();
      },
      resetPassword: authClientGetter().resetPassword,
      forgetPassword: authClientGetter().forgetPassword,
      setUser: onSetUser,
    }),
    [authClientGetter, onSetUser]
  );

  return { user, handlers };
};

const AuthProvider: React.FCC<{
  authClientGetter: AuthInstanceGetter;
}> = ({ authClientGetter, ...props }) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const { user, handlers } = useAuthHandlers(authClientGetter);
  const { data, error, isError, isSuccess } = useQuery<
    JwtBOUser | undefined,
    BaseAxiosErrorResponse
  >(['authenticated-user'], () => authClientGetter().getUser(), {
    enabled: true,
    retry: false,
    cacheTime: 0,
  });
  const isSettled = isError || isSuccess;

  // React.useEffect(() => {
  //   refetch().catch(() => {});
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
      if (!isError && data) {
        handlers.setUser(data);
      } else {
        handlers.setUser(undefined);
        AuthHelpers.removeAccessToken();
      }
    }
  }, [isSettled, isError, data, handlers]);

  const context = React.useMemo(
    () => ({
      user,
      ...handlers,
    }),
    [user, handlers]
  );

  if (!firstAttemptFinished) {
    if (isError) {
      return (
        <div style={{ color: 'red' }}>
          <p>
            An error occurred while trying to fetch the session. Please try
            again
          </p>
          <pre>{error?.message}</pre>
        </div>
      );
    }
    if (!isSettled || !user) {
      return <FullPageSpinner id='auth' />;
    }
  }
  return <AuthContext.Provider value={context} {...props} />;
};

function useAuth(useGuard?: boolean): IAuthenticatedUserContext;
function useAuth(useGuard?: undefined): IUserContext;
function useAuth(
  useGuard?: boolean | undefined
): IAuthenticatedUserContext | IUserContext {
  const context = useNamedContext(AuthContext, INITIAL_CONTEXT);

  const { user } = context;
  if (useGuard) {
    assertIsTyped(user, isIBOUser);
    return { ...context, user };
  }
  return context;
}

export { AuthProvider, useAuth };
