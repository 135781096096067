export const THEMES_LABELS: Record<string, string> = {
  SUSTAINABILITY_AND_CSR: 'Sustainability',
  BUSINESS_EXPERTISE: 'Business expertise',
  CORPORATE_STRATEGY_AND_PROCESSES: 'Corporate process',
  FINANCIAL_METRICS: 'Financial Metrics',
};

export const CO2_THEME_CODES = {
  SCOPE_1: 'CO2_SCOPE_1',
  SCOPE_2: 'CO2_SCOPE_2',
  SCOPE_3: 'CO2_SCOPE_3',
} as const;

export type Co2ThemeCode = typeof CO2_THEME_CODES[keyof typeof CO2_THEME_CODES];

export const CO2_CRITERIA_CODES = {
  SCOPE_1_PARTS_LOGISTICS: 'SCOPE_1_PARTS_LOGISTICS',
  SCOPE_2_PARTS_LOGISTICS: 'SCOPE_2_PARTS_LOGISTICS',
  SCOPE_3_PARTS_LOGISTICS: 'SCOPE_3_PARTS_LOGISTICS',
  SCOPE_1_WAREHOUSING_OFFICE_ENERGY_CONSUMPTION:
    'SCOPE_1_WAREHOUSING_OFFICE_ENERGY_CONSUMPTION',
  SCOPE_2_WAREHOUSING_OFFICE_ENERGY_CONSUMPTION:
    'SCOPE_2_WAREHOUSING_OFFICE_ENERGY_CONSUMPTION',
  WAREHOUSE_OFFICE_WASTES: 'WAREHOUSE_OFFICE_WASTES',
  SCOPE_1_BUSINESS_TRAVEL: 'SCOPE_1_BUSINESS_TRAVEL',
  SCOPE_3_BUSINESS_TRAVEL: 'SCOPE_3_BUSINESS_TRAVEL',
  WATER: 'WATER',
  IT: 'IT',
  EMPLOYEE_COMMUTING: 'EMPLOYEE_COMMUTING',
} as const;

export type Co2CriteriaCode =
  typeof CO2_CRITERIA_CODES[keyof typeof CO2_CRITERIA_CODES];
