/* eslint-disable sonarjs/no-duplicate-string */
import { yupResolver } from '@hookform/resolvers/yup';
import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import {
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  useOnUpdate,
} from '@inteliam/foundation/lib/hooks';
import { noop } from 'lodash-es';

import React, { memo, useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useQuery } from '@core/queries';

import { MASKS_ENUMS } from '@core/enums';

import { DistributorsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IBODistributor } from '@core/types';

import {
  Accordion,
  Box,
  Button,
  Card,
  CheckCircleIcon,
  CircularProgress,
  Controls,
  Divider,
  ExpandMoreIcon,
  FormGroup,
  Grid,
  Typography,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { Theme } from '@shared/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ICountryType,
} from '@inteliam/foundation/lib/types';

import { searchSchema } from './schemas';

interface ISearchForm {
  field: 'id' | 'dunsIdentifier' | 'nationalIdentifier' | 'name';
  country?: ICountryType;
  name: string;
  dunsIdentifier?: string;
  nationalIdentifier?: string;
  id?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  searchCard: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

interface Props {
  onSelectCompany: (company: IBODistributor | undefined) => void;
  hasSelected: boolean;
}

const INTELIAM_ID_FIELD = 'id';
const DUNS_ID_FIELD = 'dunsIdentifier';
const NATIONAL_ID_FIELD = 'nationalIdentifier';
const NAME_FIELD = 'name';

const SEARCHABLE_FIELDS = [
  { label: 'Company Name', value: NAME_FIELD },
  {
    label: 'DUNS',
    value: DUNS_ID_FIELD,
  },
  { label: 'ID', value: INTELIAM_ID_FIELD },
  {
    label: 'National ID',
    value: NATIONAL_ID_FIELD,
  },
];

interface ISearchQuery {
  field: string;
  value: string;
  countryCode: string;
}

const DEFAULT_SEARCH_VALUES: ISearchForm = {
  country: undefined,
  field: 'id',
  name: '',
  dunsIdentifier: '',
  nationalIdentifier: '',
  id: '',
};

const Search: React.FCC<Props> = ({ onSelectCompany, hasSelected }) => {
  const classes = useStyles();
  const { t, confirm } = useEssentials();

  const [searchQuery, setSearchQuery] = useState<ISearchQuery | undefined>(
    undefined
  );
  const methods = useForm<ISearchForm>({
    resolver: yupResolver(searchSchema),
    defaultValues: DEFAULT_SEARCH_VALUES,
  });

  const [watchedField, watchedName, watchedCountry] = useWatch({
    control: methods.control,
    name: ['field', 'name', 'country'],
  });

  const handleOnSelectCompany = useCallback(
    (data: IAPIResponse<IBODistributor | undefined>) => {
      const company = data.data;
      if (hasSelected) {
        confirm({
          description: t(
            'Selecting this company will erase the fields that you already edited. Do you want to continue?'
          ),
        })
          .then(() => {
            onSelectCompany(company);
          })
          .catch(noop);
      } else {
        onSelectCompany(company);
      }
    },
    [t, hasSelected, onSelectCompany, confirm]
  );
  const queryById = useQuery<
    IAPIResponse<IBODistributor>,
    BaseAxiosErrorResponse
  >(['company', watchedName], () => DistributorsApi.getOneById(watchedName), {
    ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
    initialData: undefined,
    onSuccess: handleOnSelectCompany,
  });

  const queryByIdentifiers = useQuery<
    IAPIResponse<IBODistributor | undefined>,
    BaseAxiosErrorResponse
  >(
    ['company', searchQuery],
    () => {
      if (!searchQuery) {
        return Promise.resolve({ data: undefined, errors: [], meta: {} });
      }
      return DistributorsApi.getOneByIdentifier(searchQuery);
    },
    {
      ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
      initialData: undefined,
      onSuccess: handleOnSelectCompany,
    }
  );

  useOnUpdate(() => {
    if (searchQuery) {
      if (searchQuery.field === NAME_FIELD) {
        queryById.refetch().catch(noop);
      } else {
        queryByIdentifiers.refetch().catch(noop);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <Accordion.Container defaultExpanded={false}>
      <Accordion.Summary expandIcon={<ExpandMoreIcon />} id='search-accordion'>
        <Typography>{t('Search')}</Typography>
      </Accordion.Summary>
      <Accordion.Details>
        <Grid container>
          <Grid item xs={12}>
            <Controls.Form
              autoComplete='off'
              noValidate
              methods={methods}
              submitHandler={(data) => {
                setSearchQuery({
                  field: data.field,
                  value: data[data.field] || '',
                  countryCode: data.country?.value || '',
                });
              }}
            >
              <Card.CContainer className={classes.searchCard}>
                <Card.Header
                  subheader={t('Please select country first, then the fields')}
                />
                <Divider />
                <Card.Body>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <FormGroup>
                        <Controls.FormCountrySelect
                          label={t('Country')}
                          name='country'
                          fullWidth
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormGroup>
                        <Controls.FormSelect
                          fullWidth
                          label={t('Field')}
                          name='field'
                          disabled={!watchedCountry?.code}
                          options={SEARCHABLE_FIELDS}
                        />
                      </FormGroup>
                    </Grid>
                    <ValueField
                      {...{ watchedField, watchedName, watchedCountry }}
                    />
                  </Grid>
                </Card.Body>
                <Divider />
                <SearchActions
                  isError={queryByIdentifiers.isError}
                  isFetching={queryByIdentifiers.isFetching}
                />
              </Card.CContainer>
            </Controls.Form>
          </Grid>
        </Grid>
      </Accordion.Details>
    </Accordion.Container>
  );
};

const SearchActions: React.FC<{ isError?: boolean; isFetching?: boolean }> = ({
  isError,
  isFetching,
}) => {
  const { t } = useEssentials();
  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      <span>
        {isError && t('We have no matches in our database with these criteria')}
      </span>
      <Button
        id='search'
        color='primary'
        variant='contained'
        type='submit'
        disabled={isFetching}
      >
        {t('Search')}{' '}
        {isFetching && <CircularProgress color='inherit' size={16} />}
      </Button>
    </Box>
  );
};

const NATIONAL_ID_KEY: Record<string, string> = {
  FRA: 'Siret',
};

const ValueField: React.FC<{
  watchedField: string;
  watchedName: string;
  watchedCountry: ICountryType | undefined;
}> = ({ watchedField, watchedName, watchedCountry }) => {
  const { t, theme } = useEssentials();
  return (
    <Grid item md={6} xs={12}>
      {watchedField === NAME_FIELD && (
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Grid item md={11} xs={11}>
            <Controls.FormCompanyField
              label={t('Company Name')}
              name={NAME_FIELD}
              style={
                watchedName
                  ? { borderBottom: `3px solid ${theme.palette.success.main}` }
                  : {}
              }
              fullWidth
              countryCode={watchedCountry?.code}
              required
              kind={ICompanyKindEnum.DISTRIBUTOR}
            />
          </Grid>
          <Grid item md={1} xs={1}>
            <span>
              {!!watchedName && (
                <CheckCircleIcon
                  style={{ color: `${theme.palette.success.main}` }}
                  fontSize={'large'}
                />
              )}
            </span>
          </Grid>
        </Grid>
      )}
      {watchedField === DUNS_ID_FIELD && (
        <Controls.MaskedFormInput
          label={t('DUNS')}
          name={DUNS_ID_FIELD}
          mask={MASKS_ENUMS.DUNS_MASK}
          fullWidth
        />
      )}
      {watchedField === INTELIAM_ID_FIELD && (
        <Controls.FormInput
          fullWidth
          label={t('ID')}
          name={INTELIAM_ID_FIELD}
          required
        />
      )}
      {watchedField === NATIONAL_ID_FIELD && (
        <Controls.FormInput
          fullWidth
          label={
            watchedCountry
              ? t(NATIONAL_ID_KEY[watchedCountry.code] || 'National ID')
              : t('National ID')
          }
          name={NATIONAL_ID_FIELD}
          required
        />
      )}
    </Grid>
  );
};

export default memo(Search);
