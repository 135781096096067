/* eslint-disable @inteliam/i18n/raw-text-detected */
import {
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
  ICampaignTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { set } from 'lodash-es';

import React, { memo } from 'react';

import {
  useFetchCampaignTypeByType,
  useSpecificTransition,
} from '@core/queries';

import { ARUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import DeadlineForm from '../update-deadlines/deadline-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

// Handles only rollback
const RTADialog: React.FCC<Props> = ({
  assessmentRequest,
  onCancel,
  onConfirm,
  open,
}) => {
  const { t } = useEssentials();
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });

  const { run: runUpdate, mutation } = useSpecificTransition({
    assessmentRequest: assessmentRequest,
    dashboard:
      assessmentRequest.currentStatusMeta.rank <= IARStatusRankEnum.IN_REVIEW
        ? 'ENGAGEMENT'
        : 'ANALYST',
    transition: IARTransitionEnum.RTA,
    successMessage: 'The deadline was successfully updated',
    confirmMessage: 'Are you sure you want to update the next deadline ?',
    onSuccess: onConfirm,
  });

  const handleOnSubmit = (deadlines: IARDeadlinesForm) => {
    runUpdate({
      data: deadlines,
    }).catch(() => {});
  };

  if (!campaignTypeQuery.isSettled) {
    return <BaseDialog.DialogPlaceholder />;
  }
  if (!campaignTypeQuery.isSuccess) {
    return (
      <BaseDialog.DialogPlaceholder>
        {t('Unable to find the requested standard campaign')}
      </BaseDialog.DialogPlaceholder>
    );
  }
  const isRollback = assessmentRequest.status === IARStatusEnum.PUBLISHED;
  if (isRollback)
    return (
      <DeadlineForm
        title={t('Update Next Deadline')}
        open={open}
        onClose={onCancel}
        onSubmit={handleOnSubmit}
        errors={mutation.error?.response?.data}
        statuses={assessmentRequest.statuses.filter(
          (status) => status.codename === IARStatusEnum.RTA
        )}
        disabledStatuses={assessmentRequest.statuses
          .filter((status) => status.codename !== IARStatusEnum.RTA)
          .map((status) => status.codename)}
        isLoading={mutation.isLoading}
        defaultValues={assessmentRequest.statuses
          .filter(
            (status) =>
              status.codename === IARStatusEnum.RTA ||
              status.codename === IARStatusEnum.IN_SCORING
          )
          .reduce((accumulator, status) => {
            set(
              accumulator,
              ARUtils.getStatusDeadlineKey(status),
              ARUtils.getDefaultDeadlineByStatus({
                targetStatus: status,
                campaignType:
                  assessmentRequest.campaign?.type ||
                  campaignTypeQuery.data.data,
                startDate: new Date(),
                cumulative: false,
              })
            );
            return accumulator;
          }, {} as Partial<IARDeadlinesForm>)}
        assessmentRequest={assessmentRequest}
      />
    );

  return (
    <DeadlineForm
      title={t('Update Next Deadline')}
      open={open}
      onClose={onCancel}
      onSubmit={handleOnSubmit}
      errors={mutation.error?.response?.data}
      statuses={assessmentRequest.statuses.filter(
        (status) => status.codename === IARStatusEnum.RTA
      )}
      disabledStatuses={assessmentRequest.statuses
        .filter((status) => status.codename !== IARStatusEnum.RTA)
        .map((status) => status.codename)}
      isLoading={mutation.isLoading}
      assessmentRequest={assessmentRequest}
    />
  );
};

export default memo(RTADialog);
