import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@inteliam/foundation/lib/hooks';
import { Transformers, Yup } from '@inteliam/foundation/lib/utils';
import { pick } from 'lodash-es';

import React, { memo, useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { flattie } from 'flattie';

import {
  QUERY_KEYS,
  useFetchCampaigns,
  useManageEnterprises,
} from '@core/queries';

import { RenewalsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IAssignCampaign, IRenewal } from '@core/types';

import { Button, Controls, FormGroup, Grid, Noop } from '@shared/components';

import { DatatableUtils } from '@shared/utils';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../modals';

interface Props {
  renewal: IRenewal;
  open: boolean;
  onClose: () => void;
}
const INITIAL_VALUE: IAssignCampaign = {
  campaign: {
    id: '',
  },
  manufacturer: '',
};

const assign = Yup.object().shape({
  campaign: Yup.object().shape({
    id: Yup.string().required(),
  }),
  manufacturer: Yup.string(),
});

const CampaignModal: React.FCC<Props> = ({ renewal, open, onClose }) => {
  const { t } = useEssentials();
  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(RenewalsApi.assignCampaign, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD['RENEWALS']],
  });

  const { paginate } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { name: 'asc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
        primaryCriteria: {
          conditions: [
            DatatableUtils.createCondition(
              'configuration.enterpriseAccess',
              'eq',
              true
            ),
          ],
          operator: 'and',
        },
      },
    },
  });
  const methods = useForm<IAssignCampaign>({
    defaultValues: {
      ...INITIAL_VALUE,
      campaign: { id: renewal.campaign?.id || '' },
    },
    resolver: yupResolver(assign),
  });

  const watchedEnterprise = useWatch({
    control: methods.control,
    name: 'manufacturer',
  });
  const { campaigns } = useFetchCampaigns({
    conditions: [
      {
        column: 'owner.id',
        operator: 'eq',
        value: watchedEnterprise,
      },
      {
        column: 'open',
        operator: 'eq',
        value: 'true',
      },
      {
        column: 'name',
        operator: 'not_contains',
        value: 'Connected from Directory',
      },
    ],
  });

  const assignCampaign = useCallback(
    (body: IAssignCampaign) => {
      const campaignId = body.campaign?.id;
      if (!campaignId) {
        return;
      }
      patchMutation.mutate(
        {
          id: renewal.id,
          body: flattie({
            campaign: campaignId,
            requester: pick(body.manufacturer, 'id'),
          }),
        },
        {
          onSuccess: () => {
            onClose();
          },
        }
      );
    },
    [patchMutation, renewal.id, onClose]
  );

  if (!open) {
    return <Noop />;
  }
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <Controls.Form submitHandler={assignCampaign} methods={methods}>
        <BaseDialog.Title id='Manage campaign' onClose={onClose}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span>
              <strong>{'Select a campaign'}</strong>
            </span>
          </Grid>
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <FormGroup>
                {paginate.isSuccess && (
                  <Controls.FormSelect
                    fullWidth
                    name='manufacturer'
                    label={t('Enterprise')}
                    options={Transformers.mapIntoOptionItem(
                      paginate.data.data,
                      'name',
                      'id'
                    )}
                  />
                )}
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <Controls.FormSelect
                  fullWidth
                  name='campaign.id'
                  label={t('Campaign name')}
                  options={Transformers.mapIntoOptionItem(
                    campaigns,
                    'name',
                    'id'
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='close-assign-campaign'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={paginate.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-assign-campaign'
            type='submit'
            color='primary'
            isLoading={paginate.isLoading}
          >
            {t('Validate')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(CampaignModal);
