import * as React from 'react';

import { useEssentials } from '@core/contexts';
import type { IAnswerEvaluation } from '@core/types';

import { TooltippedCell, InfoIcon, Box } from '@shared/components';

type Props = {
  answerEvaluation: IAnswerEvaluation;
};
const AnswerAnalysisQuestionCell: React.FCC<Props> = ({ answerEvaluation }) => {
  const { code, version, label, helpTextContent, kind } =
    answerEvaluation.answer.field;
  const codeWithVersion = `${code} [V${version}]`;
  const { t } = useEssentials();
  return (
    <Box display={'flex'} fontSize={15}>
      <span>
        {t('{{code }} : {{ label }}', {
          code: codeWithVersion,
          label,
        })}
      </span>
      <span>
        <TooltippedCell
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: helpTextContent || '',
              }}
            ></div>
          }
          disabled={!helpTextContent}
        >
          <InfoIcon color={helpTextContent ? 'inherit' : 'disabled'} />
        </TooltippedCell>
      </span>
      {/* eslint-disable-next-line @inteliam/i18n/raw-text-detected */}
      <span>[{t(kind.toLowerCase())}]</span>
    </Box>
  );
};

export default AnswerAnalysisQuestionCell;
