import * as React from 'react';

import type { IEvaluationProgress } from '@core/types';

import { Box, Typography } from '@shared/components';

import { makeStyles } from '@shared/utils';

/* eslint-disable @inteliam/i18n/raw-text-detected */
const useStyles = makeStyles((theme) => ({
  root: {
    '& span': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
    '& .pending': {
      color: 'gray',
    },
    '& .approved': {
      color: theme.palette.success.main,
    },
    '& .rejected': {
      color: theme.palette.error.main,
    },
  },
}));

interface Props {
  documentsProgress: IEvaluationProgress;
}
const ReviewSurveyInfoDocumentsCell: React.FCC<Props> = ({
  documentsProgress,
}) => {
  const classes = useStyles();

  return (
    <Box p={1} display='flex'>
      <Typography variant='body2' className={classes.root}>
        (<span className='pending'>{documentsProgress.pending}</span>|
        <span className='approved'>{documentsProgress.approved}</span>|
        <span className='rejected'>{documentsProgress.rejected}</span>)
      </Typography>
    </Box>
  );
};

export { ReviewSurveyInfoDocumentsCell };
