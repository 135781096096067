import {
  AddressSchema,
  CountrySchema,
  OptionalUserSchema,
  UserSchema,
} from '@inteliam/foundation/lib/schemas';
import { Yup } from '@inteliam/foundation/lib/utils';

import { ErrorCodesLookup } from '@shared/utils';

export const searchSchema = Yup.object().shape({
  country: Yup.object()
    .shape({
      value: Yup.string().required('Please select the country of origin first'),
    })
    .nullable()
    .required('Please select the country of origin first'),
  field: Yup.string().required('Please select the field of criteria'),
  dunsIdentifier: Yup.string().when('field', {
    is: 'dunsIdentifier',
    then: Yup.string()
      .matches(
        /^\d+$/,
        // eslint-disable-next-line sonarjs/no-duplicate-string
        'DUNS should be a 9-digit number, Please enter a correct value'
      )
      .min(9, 'DUNS should be a 9-digit number, Please enter a correct value')
      .max(9, 'DUNS should be a 9-digit number, Please enter a correct value')
      .required(
        'DUNS should be a 9-digit number, Please enter a correct value'
      ),
    otherwise: Yup.string().optional(),
  }),
  nationalIdentifier: Yup.string().when('field', {
    is: 'nationalIdentifier',
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
  id: Yup.string().when('field', {
    is: 'id',
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
  name: Yup.string().when('field', {
    is: 'name',
    then: Yup.string().required(),
    otherwise: Yup.string().optional(),
  }),
});

const baseCompanySchema = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  name: Yup.string().required(ErrorCodesLookup.E0001.title),
  medalDisplayName: Yup.string().when('name', {
    is: (name: string) => name.length > 26,
    then: Yup.string()
      .nullable()
      .required(
        'The distributor name is greater than 26 characters (The maximum allowed on the medal). Please provide a medal display name'
      )
      .max(26),
    otherwise: Yup.string()
      .nullable()
      .optional()
      .max(26, 'The medal display name must be at most 26 characters'),
  }),
  dunsIdentifier: Yup.string()
    .matches(/^\d{9}$/, {
      message: 'DUNS should be a 9-digit number, Please enter a correct value',
      excludeEmptyString: true,
    })
    .nullable(),
  address: AddressSchema,
  nationalIdentifier: Yup.string().optional(),
  incorporationDate: Yup.number().min(0).optional(),
  headquarters: Yup.object().shape({
    name: Yup.string().nullable().optional(),
    country: Yup.object().when('name', {
      is: Boolean,
      then: Yup.object().shape(CountrySchema).nullable().required(),
      otherwise: Yup.object().nullable().optional(),
    }),
  }),

  numberOfEmployees: Yup.number()
    .min(1, 'Please select at least one option')
    .required(ErrorCodesLookup.E0001.title),
  size: Yup.string().required(ErrorCodesLookup.E0001.title),
  category: Yup.string().required(ErrorCodesLookup.E0001.title),
  turnover: Yup.object().shape({
    value: Yup.number().min(0).optional(),
    currency: Yup.string().optional(),
    unit: Yup.string().optional(),
  }),
  memberOfHierarchy: Yup.array().of(Yup.string().nullable()).optional(),
  ownershipStructure: Yup.string().nullable().optional(),
  subsidiary: Yup.boolean().optional(),
  warehousesCount: Yup.number().min(0).nullable().optional(),
  totalStorageArea: Yup.number().when('warehousesCount', {
    is: (warehouses: number) => warehouses > 0,
    then: Yup.number().min(0).required(),
    otherwise: Yup.number().min(0).nullable().optional(),
  }),
  services: Yup.string().required(ErrorCodesLookup.E0001.title),
  additionalComments: Yup.string().optional(),
  description: Yup.string().optional(),
  website: Yup.string()
    .website(ErrorCodesLookup.E0006.title)
    .required(ErrorCodesLookup.E0001.title),
};

export const createCompany = Yup.object().shape({
  ...baseCompanySchema,
  primaryContact: UserSchema,
});

export const updateCompany = Yup.object().shape({
  ...baseCompanySchema,
});

export const assessmentRequestSchema = Yup.object().shape({
  scoredCompany: createCompany,
  manufacturer: Yup.string().nullable(),
  campaign: Yup.object().when('manufacturer', {
    is: Boolean,
    then: Yup.object().shape({
      id: Yup.string().required(),
    }),
    otherwise: Yup.object().shape({
      id: Yup.string().optional(),
    }),
  }),
  usePrimaryContactAsQuestionnaireContact: Yup.boolean(),
  questionnaireContact: Yup.object().when(
    'usePrimaryContactAsQuestionnaireContact',
    {
      is: true,
      then: OptionalUserSchema,
      otherwise: UserSchema,
    }
  ),
});
