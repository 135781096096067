import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import React, { useCallback, useState } from 'react';

import { useNamedContext } from '@shared/contexts';

import { NotifierContext } from './contexts';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export enum INotifierEnum {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

interface INotifierState {
  message: React.ReactNode;
  position?: SnackbarOrigin;
  open?: boolean;
  type?: INotifierEnum;
}

export type INotifierDispatcher = (notif: INotifierState) => void;

interface AlertMapping {
  [key: string]: INotifierEnum;
}
export const ALERT_MAPPING: AlertMapping = {
  idle: INotifierEnum.Info,
  loading: INotifierEnum.Info,
  success: INotifierEnum.Success,
  error: INotifierEnum.Error,
};

const NotifierProvider: React.FCC = ({ children, ...rest }) => {
  const [state, setState] = useState<INotifierState>({
    message: undefined,
    open: false,
    position: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    type: INotifierEnum.Info,
  });

  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setState((prevState) => ({ ...prevState, open: false }));
  };

  const notify = useCallback((notification: INotifierState) => {
    setState((prevState) => ({ ...prevState, ...notification, open: true }));
  }, []);

  return (
    <NotifierContext.Provider value={notify} {...rest}>
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={6000}
        onClose={(_, reason) => handleClose(reason)}
        anchorOrigin={state.position}
      >
        <div>
          <Alert onClose={() => handleClose()} severity={state.type}>
            {state.message}
          </Alert>
        </div>
      </Snackbar>
    </NotifierContext.Provider>
  );
};

function useNotifier(): INotifierDispatcher {
  return useNamedContext(NotifierContext);
}

export { NotifierProvider, useNotifier };
