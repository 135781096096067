export const QUESTION_COUNTRIES_CLASSES: Array<{
  label: string;
  value: number;
}> = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'All',
    value: 3,
  },
  {
    label: 'Mature',
    value: 2,
  },
  {
    label: 'Fast Growing',
    value: 1,
  },
];
