import { assertIsTypedArray } from '@inteliam/foundation/lib/guards';
import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { isIRegion } from '@core/guards';
import type { IRegion } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IAnyEnterprise,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/enterprises';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getRegions = async (
  id: IAnyEnterprise['id']
): Promise<IAPIResponse<Array<IRegion>>> => {
  const { data } = await client.instance.get<IAPIResponse<Array<IRegion>>>(
    `/${id}/regions`
  );

  assertIsTypedArray(data.data, isIRegion);
  return data;
};

export default {
  getRegions,
};
