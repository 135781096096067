import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

const BASE_PATH = '/atlas';

const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const token = async (): Promise<string> => {
  const { data } = await client.instance.get<string>('/token');
  return data;
};

export default {
  token,
};
