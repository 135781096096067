import type { InteliamUseQueryResult } from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { SurveyApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IThemeConfiguration,
} from '@inteliam/foundation/lib/types';

type HookReturn = Awaited<ReturnType<typeof SurveyApi.getClassifications>>;
const useFetchClassificationHierarchy = ({
  reviewsAssignableOnly = false,
  questionnaireType = 'INTELIAM',
}: {
  reviewsAssignableOnly?: boolean;
  questionnaireType?: IThemeConfiguration['questionnaireType'];
} = {}): InteliamUseQueryResult<HookReturn> => {
  return useQuery<HookReturn, BaseAxiosErrorResponse>(
    [
      ...QUERY_KEYS.SURVEY_CLASSIFICATION,
      reviewsAssignableOnly,
      questionnaireType,
    ],
    () => {
      return SurveyApi.getClassifications({
        reviewsAssignableOnly,
        questionnaireType,
      });
    },
    {
      retry: false,
      initialData: undefined,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
    }
  );
};

export default useFetchClassificationHierarchy;
