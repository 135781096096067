import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isBestPractice, type IBestPractice } from '@core/types';

import { getAuthClientInstance } from '@shared/utils/auth-instance';
import * as Constants from '@shared/utils/constants';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type BestPracticeActions =
  | 'paginate'
  | 'getOneById'
  | 'create'
  | 'update'
  | 'patch'
  | 'remove';

const baseBestPracticeApi = ApiFactory.generate<
  IBestPractice,
  JwtBOUser,
  BestPracticeActions
>({
  baseRoute: '/best-practices',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isBestPractice,
});

export default {
  ...baseBestPracticeApi.actions,
};
