import * as React from 'react';

import { clsx } from 'clsx';

import { IEvaluationStatusEnum } from '@core/enums';

import {
  Button,
  Box,
  ButtonGroup,
  CheckIcon,
  ClearIcon,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    '& button': {
      border: '1px solid black',
    },
  },
  approved: {
    '&.active,&:hover': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.text.primary,
    },
  },
  rejected: {
    '&.active,&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.text.primary,
    },
  },
}));

type Props = {
  currentStatus: IEvaluationStatusEnum;
  id: string;
  label?: string;
  onEvaluate: (status: IEvaluationStatusEnum) => void;
  disabled?: boolean;
};

const ButtonToggler: React.FCC<Props> = ({
  onEvaluate,
  currentStatus,
  label,
  disabled = false,
  id,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      {label && <div style={{ margin: '10px' }}>{label}</div>}
      <ButtonGroup variant='contained' color='primary'>
        <Button
          id={`approve-${id}`}
          color='inherit'
          className={clsx({
            [classes.approved]: true,
            active: currentStatus === IEvaluationStatusEnum.STATUS_APPROVED,
          })}
          onClick={() => onEvaluate(IEvaluationStatusEnum.STATUS_APPROVED)}
          type='button'
          disabled={
            currentStatus === IEvaluationStatusEnum.STATUS_APPROVED || disabled
          }
        >
          <CheckIcon />
        </Button>
        <Button
          id={`reject-${id}`}
          color='inherit'
          className={clsx({
            [classes.rejected]: true,
            active: currentStatus === IEvaluationStatusEnum.STATUS_REJECTED,
          })}
          onClick={() => onEvaluate(IEvaluationStatusEnum.STATUS_REJECTED)}
          type='button'
          disabled={
            currentStatus === IEvaluationStatusEnum.STATUS_REJECTED || disabled
          }
        >
          <ClearIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ButtonToggler;
