import type { ObjectValues } from '@shared/types';

import type {
  IBlameable,
  IDocument,
  IIdentifiedObject,
  IResource,
  ITimestampable,
} from '@inteliam/foundation/lib/types';

export const MEDIA_KINDS = {
  INTELIAM_DOCUMENT: 'INTELIAM_DOCUMENT',
  ENGAGEMENT_DOCUMENT: 'ENGAGEMENT_DOCUMENT',
} as const;

export type MediaKind = ObjectValues<typeof MEDIA_KINDS>;

export interface IMedia extends IIdentifiedObject, IBlameable, ITimestampable {
  file: IResource;
  kind: MediaKind;
}

export interface IEngagementDocument extends IMedia {
  kind: 'ENGAGEMENT_DOCUMENT';
}

export type IMediaPayload = Pick<IMedia, 'file' | 'kind'>;

export type IAnyMedia = IEngagementDocument | IDocument;
