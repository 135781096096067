import { showReportDialog } from '@sentry/react';
import * as React from 'react';

import InternalErrorPage from '@core/pages/errors/500';

import { Button } from '@shared/components';

import { BOErrorTracking } from '@shared/utils';

interface Props {
  prop?: string;
}

interface State {
  // IS THIS THE CORRECT TYPE FOR THE state ?
  hasError: boolean;
}

class UserErrorBoundary extends React.Component<
  Props & { children?: React.ReactNode },
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    BOErrorTracking.report(error, 'UserErrorBoundary', errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <InternalErrorPage>
          <Button
            id='report-error'
            className='mt-2'
            onClick={() => showReportDialog()}
            variant='outlined'
            color='secondary'
            style={{ position: 'fixed', bottom: 40, right: 0 }}
            // eslint-disable-next-line @inteliam/i18n/raw-text-detected
          >
            Report feedback
          </Button>
        </InternalErrorPage>
      );
    }

    return this.props.children;
  }
}

export default UserErrorBoundary;
