import {
  ICompanyKindEnum,
  IFilterTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { useModalState } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { useMemo } from 'react';

import { DistributorUsersActions } from '@core/components';
import {
  CreateDistributorUserModal,
  UpdateDistributorUserModal,
} from '@core/components/modals';

import { QUERY_KEYS, useLanguagesByScope } from '@core/queries';

import { UsersUtils } from '@core/utils';
import { getTranslatedUserStatus } from '@core/utils/users';

import { DISTRIBUTOR_ROLES, TITLES } from '@core/enums';

import { FrontOfficeUserApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IBODistributor, ITokenAwareFODistributorUser } from '@core/types';

import {
  Chip,
  CreateButton,
  Datatable,
  TooltippedCell,
} from '@shared/components';

import type { IFilterableColumn } from '@shared/types';

const ROLES = Object.values(DISTRIBUTOR_ROLES);
const TITLES_ARRAY = Object.values(TITLES);

const DistributorUsersList: React.FCC<{ distributor: IBODistributor }> = ({
  distributor,
}) => {
  const { t } = useEssentials();

  const [modalState, modalHandlers] = useModalState<string>();

  const { formattedLanguages } = useLanguagesByScope({
    scope: 'UserCommunication',
  });

  const columns = useMemo<IFilterableColumn<ITokenAwareFODistributorUser>[]>(
    () => [
      {
        field: 'actions',
        filter: { type: IFilterTypeEnum.NotFiltrable },
        sortable: false,
        headerName: ' ',
        width: 85,
        renderCell: (parameters) => {
          return (
            <DistributorUsersActions
              distributorUser={parameters.row}
              modalHandlers={modalHandlers}
            />
          );
        },
      },
      {
        field: 'contactInfo.title',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: TITLES_ARRAY,
          },
        },
        headerName: t('Title'),
        width: 65,
        renderCell: (parameters) => {
          const title = parameters.row.contactInfo.title as string;
          return <TooltippedCell title={t(title)}>{t(title)}</TooltippedCell>;
        },
      },
      {
        field: 'contactInfo.firstName',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('First Name'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row.contactInfo.firstName}>
              {parameters.row.contactInfo.firstName}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.lastName',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Last name'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters?.row?.contactInfo.lastName}>
              {parameters?.row?.contactInfo.lastName}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.email',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Email'),
        width: 250,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters?.row?.contactInfo.email}>
              {parameters?.row?.contactInfo.email}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.phoneNumber',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Phone number'),
        width: 150,
        renderCell: (parameters) => {
          const phoneNumberOrNa =
            parameters?.row?.contactInfo.phoneNumber || t('N/A');
          return (
            <TooltippedCell title={phoneNumberOrNa}>
              {phoneNumberOrNa}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'roles',
        filter: {
          type: IFilterTypeEnum.Role,
          props: {
            options: ROLES,
            multiple: true,
          },
        },
        sortable: false,
        headerName: t('Role'),
        width: 130,
        renderCell: (parameters) => {
          const roles = UsersUtils.getTranslatedUserRoles(
            parameters.row.roles,
            t
          ).join(', ');
          return <TooltippedCell title={roles}>{roles}</TooltippedCell>;
        },
      },
      {
        field: 'businessRole',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: [
              {
                value: 'primary',
                label: t('Primary'),
              },
              {
                value: 'survey',
                label: t('Questionnaire'),
              },
            ],
          },
        },
        sortable: false,
        headerName: t('Business role'),
        width: 200,
        renderCell: (parameters) => {
          return (
            <div>
              {parameters.row.primaryContact && <Chip label={t('Primary')} />}
              {parameters.row.questionnaireContact && (
                <Chip label={t('Questionnaire')} />
              )}
            </div>
          );
        },
      },
      {
        field: 'questionnaireContactFor',
        filter: {
          type: IFilterTypeEnum.AssessmentRequest,
          props: {
            companyId: distributor.id,
          },
        },
        sortable: false,
        headerName: t('Related ARs'),
        width: 200,
        renderCell: (parameters) => {
          const ids = Helpers.ensureValueAsArray(
            parameters.row.questionnaireContactFor?.map((ar) => ar.businessId)
          );
          return (
            <div
              style={{
                overflowX: 'auto',
                overflowY: 'hidden',
              }}
              className='tiny-scrollbar'
            >
              <TooltippedCell title={ids.join(',')}>
                {ids.map((id) => (
                  <Chip key={id} label={id} />
                ))}
              </TooltippedCell>
            </div>
          );
        },
      },
      {
        field: 'contactInfo.position',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Position'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row.contactInfo.position}>
              {parameters.row.contactInfo.position}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.language',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: formattedLanguages,
          },
        },
        headerName: t('Language'),
        width: 120,
        renderCell: (parameters) => {
          const language = parameters.row.contactInfo.language as string;
          return (
            <TooltippedCell title={t(language)}>{t(language)}</TooltippedCell>
          );
        },
      },
      {
        field: 'status',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: UsersUtils.getUserStatusesEnum(),
          },
        },
        sortable: false,
        headerName: t('Status'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={
                getTranslatedUserStatus(parameters.row.status, t)
                  .translatedStatusHover
              }
            >
              <span>
                {
                  getTranslatedUserStatus(parameters.row.status, t)
                    .translatedStatus
                }
              </span>
            </TooltippedCell>
          );
        },
      },
    ],
    [t, distributor.id, formattedLanguages, modalHandlers]
  );
  return (
    <>
      {!distributor.draft && (
        <CreateButton
          id={'add-user'}
          onClick={modalHandlers.onOpenCreate}
          label={t('Add')}
          position='start'
        />
      )}
      <Datatable.Container
        queryKey={QUERY_KEYS.COMPANY_USERS_LIST(
          ICompanyKindEnum.DISTRIBUTOR,
          distributor.id
        )}
        columns={columns}
        fetcher={(paginator) =>
          FrontOfficeUserApi.paginateByCompany(
            { id: distributor.id, kind: ICompanyKindEnum.DISTRIBUTOR },
            paginator
          )
        }
      >
        <Datatable.Toolbar />
        <div style={{ height: '100%', width: '100%' }}>
          <Datatable.Table autoHeight checkboxSelection={false} />
        </div>
      </Datatable.Container>
      {modalState.mode === 'UPDATE' && modalState.data && (
        <UpdateDistributorUserModal
          userId={modalState.data}
          distributorId={distributor.id}
          open={true}
          onClose={modalHandlers.onClose}
        />
      )}
      {modalState.mode === 'CREATE' && (
        <CreateDistributorUserModal
          distributorId={distributor.id}
          open={modalState.mode === 'CREATE'}
          onClose={modalHandlers.onClose}
        />
      )}
    </>
  );
};

export default DistributorUsersList;
