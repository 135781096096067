import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { EnterpriseDiscountsApi } from '@core/api';
import type { IDiscount } from '@core/types';

import type {
  IAPIResponse,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchEnterpriseDiscountById = ({
  manufacturerId,
  discountId,
}: {
  manufacturerId: string;
  discountId: string;
}): InteliamUseQueryResult<IAPIResponse<IDiscount>, BaseAxiosErrorResponse> => {
  return useQuery<IAPIResponse<IDiscount>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.MANUFACTURER_DISCOUNTS_LIST(manufacturerId), discountId],
    () => EnterpriseDiscountsApi.getOneById(discountId),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      cacheTime: 1000 * 20,
    }
  );
};

export default useFetchEnterpriseDiscountById;
