import {
  ICompanyKindEnum,
  IFilterTypeEnum,
  IBORolesEnum,
} from '@inteliam/foundation/lib/enums';
import { useModalState } from '@inteliam/foundation/lib/hooks';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { EnterpriseUsersActions } from '@core/components';
import {
  CreateEnterpriseUserModal,
  UpdateEnterpriseUserModal,
} from '@core/components/modals';

import {
  QUERY_KEYS,
  useFetchEnterpriseById,
  useLanguagesByScope,
} from '@core/queries';

import { EnterpriseUtils, UsersUtils } from '@core/utils';
import { getTranslatedUserStatus } from '@core/utils/users';

import { TITLES } from '@core/enums';

import { FrontOfficeUserApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import {
  CreateButton,
  Datatable,
  Noop,
  TooltippedCell,
} from '@shared/components';

import type { IFilterableColumn } from '@shared/types';

import type {
  IFOSupplierUser,
  IIdentifiedObject,
} from '@inteliam/foundation/lib/types';

const TITLES_ARRAY = Object.values(TITLES);

const ListUsers: React.FCC = () => {
  const { t, user } = useEssentials();
  const { id } = useParams<IIdentifiedObject>();
  const { formattedLanguages } = useLanguagesByScope({
    scope: 'UserCommunication',
  });
  const manufacturerQuery = useFetchEnterpriseById({ id });
  const manufacturerKind =
    manufacturerQuery.data?.data.kind ?? ICompanyKindEnum.SUPPLIER;
  const [modalState, modalHandlers] = useModalState<string>();
  const isAllowedToManageUsers =
    UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_CSM, user) ||
    UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ENGAGEMENT_SPECIALIST, user);

  const columns = useMemo<IFilterableColumn<IFOSupplierUser>[]>(
    () => [
      {
        field: 'actions',
        filter: { type: IFilterTypeEnum.NotFiltrable },
        sortable: false,
        headerName: ' ',
        width: 85,
        renderCell: (parameters) => {
          return (
            <EnterpriseUsersActions
              manufacturerUser={parameters.row}
              isAllowedToManageUsers={isAllowedToManageUsers}
              modalHandlers={modalHandlers}
            />
          );
        },
      },
      {
        field: 'contactInfo.title',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: TITLES_ARRAY,
          },
        },
        headerName: t('Title'),
        width: 65,
        renderCell: (parameters) => {
          const title = parameters.row?.contactInfo.title as string;
          return <TooltippedCell title={t(title)}>{t(title)}</TooltippedCell>;
        },
      },
      {
        field: 'contactInfo.firstName',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('First Name'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.contactInfo.firstName}>
              {parameters.row?.contactInfo.firstName}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.lastName',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Last name'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.contactInfo.lastName}>
              {parameters.row?.contactInfo.lastName}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.email',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Email'),
        width: 250,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.contactInfo.email}>
              {parameters.row?.contactInfo.email}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'roles',
        filter: {
          type: IFilterTypeEnum.Role,
          props: {
            options: EnterpriseUtils.ROLES_LOOKUP[manufacturerKind],
            multiple: true,
          },
        },
        sortable: false,
        headerName: t('Role'),
        width: 130,
        renderCell: (parameters) => {
          const roles = UsersUtils.getTranslatedUserRoles(
            parameters.row.roles,
            t
          ).join(', ');
          return <TooltippedCell title={roles}>{roles}</TooltippedCell>;
        },
      },
      {
        field: 'contactInfo.position',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Position'),
        width: 150,
        renderCell: (parameters) => {
          return (
            <TooltippedCell title={parameters.row?.contactInfo.position}>
              {parameters.row?.contactInfo.position}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'contactInfo.language',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: formattedLanguages,
          },
        },
        headerName: t('Language'),
        width: 120,
        renderCell: (parameters) => {
          const language = parameters.row?.contactInfo.language as string;
          return (
            <TooltippedCell title={t(language)}>{t(language)}</TooltippedCell>
          );
        },
      },
      {
        field: 'status',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: UsersUtils.getUserStatusesEnum(),
          },
        },
        sortable: false,
        headerName: t('Status'),
        width: 100,
        renderCell: (parameters) => {
          if (isAllowedToManageUsers)
            return (
              <TooltippedCell
                title={
                  getTranslatedUserStatus(parameters.row.status, t)
                    .translatedStatus
                }
              >
                <span>
                  {
                    getTranslatedUserStatus(parameters.row.status, t)
                      .translatedStatus
                  }
                </span>
              </TooltippedCell>
            );
          return <Noop />;
        },
      },
    ],
    [
      t,
      manufacturerKind,
      formattedLanguages,
      isAllowedToManageUsers,
      modalHandlers,
    ]
  );

  return (
    <>
      {isAllowedToManageUsers && (
        <CreateButton
          id='create-user'
          onClick={modalHandlers.onOpenCreate}
          label={t('Add')}
          position='start'
        />
      )}
      <Datatable.Container
        queryKey={QUERY_KEYS.COMPANY_USERS_LIST(ICompanyKindEnum.SUPPLIER, id)}
        columns={columns}
        fetcher={(paginator) =>
          FrontOfficeUserApi.paginateByCompany(
            { id, kind: ICompanyKindEnum.SUPPLIER },
            paginator
          )
        }
      >
        <Datatable.Toolbar></Datatable.Toolbar>
        <div style={{ height: '100%', width: '100%' }}>
          <Datatable.Table autoHeight checkboxSelection={false} />
        </div>
      </Datatable.Container>
      <CreateEnterpriseUserModal
        manufacturerId={id}
        open={modalState.mode === 'CREATE'}
        onClose={modalHandlers.onClose}
      />
      {modalState.mode === 'UPDATE' && modalState.data && (
        <UpdateEnterpriseUserModal
          userId={modalState.data}
          manufacturerId={id}
          open={true}
          onClose={modalHandlers.onClose}
        />
      )}
    </>
  );
};
export default ListUsers;
