import * as React from 'react';

import { Box, CircularProgress } from '@shared/components';

const CenteredSpinner: React.FCC = () => {
  return (
    <Box display='flex' alignItems='center' justifyContent='center' p={5}>
      <CircularProgress
        color='inherit'
        data-cy='spinner'
        data-testid='spinner'
      />
    </Box>
  );
};
export default CenteredSpinner;
