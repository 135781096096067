import { yupResolver } from '@hookform/resolvers/yup';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { ExtendSurveyDeadlineForm } from '@core/components';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { ARUtils } from '@core/utils';

import { useDashboard, useEssentials } from '@core/contexts';
import type { IExtendSurveyDeadlineForm } from '@core/types';

import { Button, Controls } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type { IAR, ICampaignType } from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';
import { extendSurveyDeadlineSchema } from './schema';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
  campaignType: ICampaignType;
}
const ExtendSurveyDeadlineModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
  campaignType,
}) => {
  const { t, confirm } = useEssentials();
  const { dashboard } = useDashboard();

  const methods = useForm<IExtendSurveyDeadlineForm>({
    defaultValues: ARUtils.normalize.getInitialExtendSurveyDeadlineValues(
      campaignType,
      assessmentRequest
    ),
    resolver: yupResolver(extendSurveyDeadlineSchema),
  });
  const watchedVendorInvolvement = Boolean(
    useWatch({
      control: methods.control,
      name: 'vendorInvolvement',
    })
  );

  const { extendDeadlines } = useManageAssessmentRequest({
    extendDeadlines: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
  });

  const handleOnSubmit = (data: IExtendSurveyDeadlineForm) => {
    confirm({
      title: t(
        watchedVendorInvolvement
          ? 'Do you confirm the new deadline and the sending of emails to the selected contact(s) and the enterprise?'
          : 'Do you confirm the new deadline and the sending of emails to the selected contact(s)?'
      ),
    })
      .then(() => {
        extendDeadlines.mutate(
          {
            id: assessmentRequest.id,
            body: ARUtils.normalize.extendSurveyDeadlineFormToExtendSurveyDeadlinePayload(
              data
            ),
          },
          {
            onSuccess: () => {
              onClose();
            },
          }
        );
      })
      .catch(() => {});
  };
  useOnUpdate(() => {
    if (extendDeadlines.error?.response?.data) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors:
          ValidationUtils.formatValidationErrors(
            extendDeadlines.error?.response?.data
          ) || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendDeadlines.error?.response?.data, methods.setError]);

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      fullScreen
    >
      <Controls.Form submitHandler={handleOnSubmit} methods={methods}>
        <BaseDialog.Title id='Extend survey deadline' onClose={onClose}>
          {t('Extend survey deadline')}
        </BaseDialog.Title>

        <BaseDialog.Content dividers>
          <ExtendSurveyDeadlineForm
            assessmentRequest={assessmentRequest}
            vendorInvolvement={watchedVendorInvolvement}
          />
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-extend-deadlines'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={extendDeadlines.isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-extend-deadlines'
            type='submit'
            color='primary'
            isLoading={extendDeadlines.isLoading}
          >
            {t('Confirm')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};
export default memo(ExtendSurveyDeadlineModal);
