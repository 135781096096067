export enum CONDITIONS_CODES {
  OPTION_SELECTED_REFERENCE_APPROVED = 8,
  OPTION_SELECTED_REFERENCE_REJECTED = 4,
  OPTION_SELECTED_WITHOUT_REFERENCE = 2,
  OPTION_NOT_SELECTED = 1,
}

export const CONDITIONS_OPTIONS: Array<{
  label: string;
  value: CONDITIONS_CODES;
}> = [
  {
    label: 'Option selected, reference approved',
    value: CONDITIONS_CODES.OPTION_SELECTED_REFERENCE_APPROVED,
  },
  {
    label: 'Option selected, reference rejected',
    value: CONDITIONS_CODES.OPTION_SELECTED_REFERENCE_REJECTED,
  },
  {
    label: 'Option selected without reference',
    value: CONDITIONS_CODES.OPTION_SELECTED_WITHOUT_REFERENCE,
  },
  {
    label: 'Option not selected',
    value: CONDITIONS_CODES.OPTION_NOT_SELECTED,
  },
];
