import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIDocument } from '@core/guards';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IDocument, JwtBOUser } from '@inteliam/foundation/lib/types';

const BASE_PATH = '/documents';

type DocumentsActions = 'patch';

const baseDocumentsApi = ApiFactory.generate<
  IDocument,
  JwtBOUser,
  DocumentsActions
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIDocument,
});

export default {
  ...baseDocumentsApi.actions,
};
