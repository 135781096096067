import { get } from 'lodash-es';

import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SearchApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { AsyncTypeahead } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IFilterDefaultItem } from '@inteliam/foundation/lib/types';

const useStyles = makeStyles({
  option: {
    'fontSize': 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});
const FOUserFilter: React.FC<{
  name: string;
  defaultItem: IFilterDefaultItem;
  label: string;
  helperText: string;
  kind: 'DISTRIBUTOR' | 'SUPPLIER' | 'TRADING_GROUP';
}> = ({ name, defaultItem, label, helperText, kind }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={{
        value: defaultItem?.value ?? '',
        label: defaultItem?.label ?? '',
      }}
      render={({ field }) => {
        return (
          <AsyncTypeahead
            name={name}
            defaultValue={field.value}
            fetcher={(value) =>
              SearchApi.searchUsers({
                value,
                field: 'fullName',
                kind,
              })
            }
            label={label}
            fullWidth
            error={!!get(errors, `${name}.message`)}
            helperText={t(
              (get(errors, `${name}.message`) ||
                helperText) as unknown as string
            )}
            classes={{
              option: classes.option,
            }}
            onChange={(value) => {
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
};

export default FOUserFilter;
