import { AuthClient } from '@inteliam/foundation/lib/utils';

import { Constants } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

// singleton
let authClientInstance: AuthClient<JwtBOUser> | undefined = undefined;

const getAuthClientInstance = (): AuthClient<JwtBOUser> => {
  if (authClientInstance) {
    return authClientInstance;
  }
  authClientInstance = new AuthClient<JwtBOUser>(
    Constants.BASE_URL,
    Constants.CLIENT_ID,
    Constants.CLIENT_SECRET
  );
  return authClientInstance;
};
export type AuthInstanceGetter = typeof getAuthClientInstance;

export { getAuthClientInstance };
