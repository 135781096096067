/* eslint-disable @inteliam/i18n/raw-text-detected */
import {
  DISTRIBUTOR_SUBSCRIPTIONS,
  ICompanyKindEnum,
  IFilterTypeEnum,
} from '@inteliam/foundation/lib/enums';
import { MathUtils } from '@inteliam/foundation/lib/utils';

import React, { useMemo, useState } from 'react';

import { CustomerContactModal } from '@core/components/modals';

import { QUERY_KEYS } from '@core/queries';

import { CSMApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { ICsmEntry } from '@core/types';

import { Datatable, TooltippedCell } from '@shared/components';

import type { IFilterableColumn } from '@shared/types';

import { CSMDashboardActionCell, CSMDashboardAdditionalFilters } from './';

const SUBSCRIPTIONS = Object.values(DISTRIBUTOR_SUBSCRIPTIONS);

const Table: React.FCC = () => {
  const { t, user } = useEssentials();
  const [openedContact, setOpenedContact] = useState<string | undefined>(
    undefined
  );

  const columns = useMemo<IFilterableColumn<ICsmEntry>[]>(
    () => [
      {
        field: '',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: '',
        width: 50,
        renderCell: (parameters) => {
          return <CSMDashboardActionCell csmEntry={parameters.row} />;
        },
      },
      {
        field: 'manufacturer.name',
        filter: {
          type: IFilterTypeEnum.Company,
          field: 'id',
          props: {
            kind: [ICompanyKindEnum.SUPPLIER, ICompanyKindEnum.TRADING_GROUP],
          },
        },
        headerName: t('Enterprise'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              textOverflowHidden
              title={parameters.row.manufacturer.name}
            >
              <a
                href={`/manufacturers/${parameters.row.manufacturer.id}`}
                target='_blank'
                rel='noreferrer'
              >
                {parameters.row.manufacturer.name}
              </a>
            </TooltippedCell>
          );
        },
      },
      {
        field: 'subscription',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: SUBSCRIPTIONS,
          },
        },
        headerName: t('Subscription'),
        width: 100,
        valueGetter: (parameters) =>
          parameters?.row?.manufacturer?.subscription,
      },
      {
        field: 'contact',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Contact'),
        width: 180,
        renderCell: (parameters) => {
          return (
            <>
              <TooltippedCell
                textOverflowHidden
                title={`${
                  parameters.row.lastRiskManager?.contactInfo?.fullName || '-'
                }
                  ${
                    parameters.row.lastSalesManager?.contactInfo?.fullName ||
                    '-'
                  }`}
              >
                <span
                  onClick={() => setOpenedContact(parameters.row.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {parameters.row.lastRiskManager?.contactInfo?.fullName || '-'}
                  {parameters.row.lastSalesManager?.contactInfo?.fullName ||
                    '-'}{' '}
                  |{' '}
                </span>
              </TooltippedCell>
              <CustomerContactModal
                open={openedContact === parameters.row.id}
                onClose={() => setOpenedContact(undefined)}
                salesManager={parameters.row.lastSalesManager}
                riskManager={parameters.row.lastRiskManager}
              />
            </>
          );
        },
      },
      {
        field: 'campaigns',
        filter: {
          type: IFilterTypeEnum.Campaign,
          props: {
            companyId: undefined,
          },
        },
        headerName: t('Campaign(s) name'),
        width: 220,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              textOverflowHidden
              title={parameters.row.campaigns.map((c, index) => (
                <div key={index}>{c}</div>
              ))}
            >
              {parameters.row.campaigns.join(', ')}
            </TooltippedCell>
          );
        },
      },
      {
        field: 'campaigns_count',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Campaign'),
        width: 130,
        renderCell: (parameters) => {
          return <span>{parameters.row.campaigns.length}</span>;
        },
      },
      {
        field: 'sharing_requests_count',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Distributors'),
        width: 130,
        renderCell: (parameters) => {
          return <span>{parameters.row.sharingRequestsCount}</span>;
        },
      },
      {
        field: 'responseRate',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Response rate'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {MathUtils.precisionRound(parameters.row.responseRate, {
                precision: 1,
              })}
            </span>
          );
        },
      },
      {
        field: 'active',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Active'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {parameters.row.active.value} (
              {MathUtils.precisionRound(parameters.row.active.percent, {
                precision: 1,
              })}{' '}
              %)
            </span>
          );
        },
      },
      {
        field: 'published',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Published'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {parameters.row.published.value} (
              {MathUtils.precisionRound(parameters.row.published.percent, {
                precision: 1,
              })}{' '}
              %)
            </span>
          );
        },
      },
      {
        field: 'reuse',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Reuse'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {parameters.row.reuse.value} (
              {MathUtils.precisionRound(parameters.row.reuse.percent, {
                precision: 1,
              })}{' '}
              %)
            </span>
          );
        },
      },
      {
        field: 'queued',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Queued'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {parameters.row.queued.value} (
              {MathUtils.precisionRound(parameters.row.queued.percent, {
                precision: 1,
              })}{' '}
              %)
            </span>
          );
        },
      },
      {
        field: 'declined',
        filter: {
          type: IFilterTypeEnum.NotFiltrable,
        },
        headerName: t('Declined'),
        width: 130,
        renderCell: (parameters) => {
          return (
            <span>
              {parameters.row.declined.value} (
              {MathUtils.precisionRound(parameters.row.declined.percent, {
                precision: 1,
              })}{' '}
              %)
            </span>
          );
        },
      },
    ],
    [openedContact, t]
  );

  return (
    <div style={{ marginTop: -35 }}>
      <Datatable.Container
        queryKey={[...QUERY_KEYS.DASHBOARD.CSM, user.id]}
        columns={columns}
        fetcher={(query) =>
          CSMApi.paginate({
            ...query,
            criteria: {
              ...query.criteria,
              options: { ...query.criteria.options },
            },
          })
        }
        //   defaults={{
        //     sort: {
        //       '_scoredCompany._id': 'desc',
        //     },
        //   }}
      >
        <Datatable.Toolbar>
          <CSMDashboardAdditionalFilters />
        </Datatable.Toolbar>
        <div style={{ height: '100%', width: '100%' }}>
          <Datatable.Table checkboxSelection={false} autoHeight />
        </div>
      </Datatable.Container>
    </div>
  );
};

export default Table;
