import type { CheckboxProps } from '@mui/material';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  Checkbox,
  FormControlLabel,
  ErrorHelpText,
  FormGroup,
} from '@shared/components';

const FormCheckbox: React.FCC<
  CheckboxProps & {
    name: string;
    helperText?: string;
    label: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    labelColor?: string;
  }
> = (props) => {
  const { t } = useEssentials();
  const {
    helperText,
    name,
    label,
    labelPlacement,
    defaultChecked,
    labelColor,
    ...rest
  } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;

  return (
    <FormGroup>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultChecked}
        render={({ field }) => (
          <FormControlLabel
            id={name}
            control={
              <Checkbox
                data-cy={name}
                {...rest}
                name={name}
                onBlur={field.onBlur}
                onChange={(event, checked) => {
                  rest.onChange?.(event, checked);
                  field.onChange(event.target.checked);
                }}
                checked={field.value}
                inputRef={field.ref}
              />
            }
            label={label}
            labelPlacement={labelPlacement}
            style={{
              color: labelColor,
            }}
          />
        )}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </FormGroup>
  );
};

export default memo(FormCheckbox);
