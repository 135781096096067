import {
  assertIsTypedArray,
  isILanguage,
} from '@inteliam/foundation/lib/guards';
import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  ILanguage,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/languages',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

export const fetchLanguagesByScope = async ({
  scope = '',
}: {
  scope?: string;
}): Promise<IAPIResponse<Array<ILanguage>>> => {
  const { data } = await client.instance.get(
    `/get-available${scope ? '/' + scope : ''}`
  );
  assertIsTypedArray(data.data, isILanguage);

  return data;
};

const fetchLanguages = async (): Promise<IAPIResponse<Array<ILanguage>>> => {
  const { data } = await client.instance.get('/get-all');
  return data;
};

const patch = async (patchObject: IPatchObject): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch(
    `/${patchObject.id}`,
    patchObject.body
  );
  return data;
};

export default {
  fetchLanguagesByScope,
  fetchLanguages,
  patch,
};
