import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { AuthHelpers } from '@inteliam/foundation/lib/utils';
import { get, isNil, isObject } from 'lodash-es';

import React, { memo, useState } from 'react';

import type { IResource } from '@inteliam/foundation/lib/types';

import ImgLoader, { LoaderProps } from './img-loader';

const DEFAULT_USER_AVATAR = 'PATH/TO/DEFAULT/IMAGES';

const getResource = (key: string) =>
  `/resources/${key}?access_token=${AuthHelpers.getAccessToken() as string}`;

const resolveImgSource = ({
  src,
  defaultImage,
}: {
  src: string | IResource;
  defaultImage: string;
}) => {
  if (isNil(src)) {
    return defaultImage;
  } else if (isObject(src)) {
    return getResource(src.id);
  } else {
    return src;
  }
};

interface Props extends LoaderProps {
  withoutLoader?: boolean;
  src: string | IResource;
  alt: string;
  defaultImage?: string;
  className?: string;
  width?: number | string;
  height?: number | string;
}

const Image: React.FCC<Props> = ({
  withoutLoader = false,
  defaultImage = DEFAULT_USER_AVATAR,
  width,
  height,
  variant,
  src,
  ...rest
}) => {
  const [imageSource, setImageSource] = useState(() =>
    resolveImgSource({ src, defaultImage })
  );
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setImageSource(defaultImage);
    setLoaded(true);
  };

  useOnUpdate(() => {
    setImageSource(resolveImgSource({ src, defaultImage }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <>
      {!loaded && !withoutLoader && (
        <ImgLoader
          style={{ width: width ?? height, height: height ?? width }}
          variant={variant}
        />
      )}
      <img
        src={imageSource}
        {...rest}
        alt={rest.alt}
        onLoad={onLoad}
        onError={onError}
        style={{
          ...get(rest, 'style'),
          visibility: loaded ? 'visible' : 'hidden',
          height: loaded ? height : 0,
          width: loaded ? width : 0,
          maxWidth: loaded ? undefined : 0,
          maxHeight: loaded ? undefined : 0,
        }}
        loading='lazy'
      />
    </>
  );
};

export default memo(Image);
