import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Controls, TextField } from '@shared/components';

type Props = {
  defaultScore?: number;
  onChange?: (value: number) => void;
  readOnly?: boolean;
  id?: string;
};
const FormCompanyScoreInput: React.FCC<Props> = ({
  defaultScore,
  onChange,
  readOnly,
  id,
}) => {
  const { t } = useEssentials();
  const methods = useFormContext();
  if (readOnly) {
    return (
      <TextField
        id={id}
        key={'readonly.score'}
        label={t('Score')}
        name={'readonly.score'}
        value={defaultScore}
        readOnly
      />
    );
  }
  return (
    <>
      <Controls.FormInput
        id={id}
        name={'score'}
        label={t('Score')}
        decimal={false}
        defaultValue={defaultScore}
        type='number'
        min={0}
        max={100}
        style={{ width: '100%' }}
        onChange={({ target }) => {
          onChange?.(Number(target.value));
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        readOnly={readOnly}
      />
      <input
        type='checkbox'
        hidden
        checked
        {...methods.register('validateScore')}
      />
    </>
  );
};

export default React.memo(FormCompanyScoreInput);
