import { Button } from '@mui/material';

import * as React from 'react';

import { CircularProgress } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { ButtonProps } from '@shared/types';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));
const CustomButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, id, disabled, children, ...rest }, ref) => {
    const classes = useStyles();

    return (
      <Button
        {...rest}
        disabled={isLoading || disabled}
        id={id}
        data-cy={id}
        ref={ref}
      >
        {children}
        {isLoading && <CircularProgress className={classes.loader} size={24} />}
      </Button>
    );
  }
);

export default CustomButton;
