import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { CampaignsApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  FieldOperator,
  ICampaign,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

interface Options {
  conditions?: Conditions;
}
const useFetchCampaigns = ({
  conditions = [],
}: Options = {}): InteliamUseQueryResult<
  IPaginationResponse<ICampaign>,
  BaseAxiosErrorResponse
> & {
  campaigns: ICampaign[];
} => {
  const query = useQuery<
    IPaginationResponse<ICampaign>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.OPEN_CAMPAIGNS_LIST, conditions],
    () => {
      return CampaignsApi.paginate({
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions,
          operator: 'and',
        },
      });
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      initialData: DatatableUtils.createInitialPaginationResponse<ICampaign>(),
    }
  );

  const campaigns = Helpers.ensureValueAsArray(query.data?.data);

  return {
    ...query,
    campaigns,
  };
};

export default useFetchCampaigns;

type Conditions = Array<{
  column: string;
  operator: FieldOperator;
  value: string;
}>;
