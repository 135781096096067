import { PrivateApi } from '@inteliam/foundation/lib/utils';

import type {
  IBODistributor,
  ICarbonAssessmentPayload,
  ICarbonDebugRecord,
} from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type {
  ICarbonAssessment,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/distributors',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const create = async (args: {
  id: IBODistributor['id'];
  body: ICarbonAssessmentPayload;
}): Promise<IAPIResponse<ICarbonAssessment>> => {
  const { data } = await client.instance.post<IAPIResponse<ICarbonAssessment>>(
    `/${args.id}/carbon-assessments`,
    args.body
  );
  return data;
};

const cancel = async (id: IBODistributor['id']): Promise<IAPIResponse> => {
  const { data } = await client.instance.delete<IAPIResponse>(
    `/${id}/carbon-assessments`
  );
  return data;
};

const debug = async (
  id: IBODistributor['id'],
  caId: ICarbonAssessment['id']
): Promise<Array<ICarbonDebugRecord>> => {
  const { data } = await client.instance.get(
    `/${id}/carbon-assessments/${caId}/debug`
  );

  return data;
};

export default {
  create,
  cancel,
  debug,
};
