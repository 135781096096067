import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Controls, Grid, FormGroup } from '@shared/components';

interface Props {
  prefix?: string;
}
const HeadquarterInformation: React.FCC<Props> = ({ prefix = '' }) => {
  const { t } = useEssentials();
  const methods = useFormContext();
  const _prefix = prefix ? prefix + '.' : '';
  const watchedHeadquarters = useWatch({
    control: methods.control,
    name: `${_prefix}headquarters.name`,
  });

  return (
    <>
      <Grid item md={6} xs={12}>
        <FormGroup>
          <Controls.FormInput
            label={t('Headquarters')}
            name={`${_prefix}headquarters.name`}
          />
        </FormGroup>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormGroup>
          <Controls.FormCountrySelect
            label={t('Country')}
            name={`${_prefix}headquarters.country`}
            disabled={!watchedHeadquarters}
            required={Boolean(watchedHeadquarters)}
          />
        </FormGroup>
      </Grid>
    </>
  );
};

export default memo(HeadquarterInformation);
