import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions, EnterpriseDomainNames } from '@core/components';

import { useEssentials } from '@core/contexts';
import type { IEnterpriseForm } from '@core/types';

import { Controls } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IPartialEnterprise,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { domainsSchema as schema } from '../schema';

interface Props {
  onSubmit: (data: IPartialEnterprise) => void;
  defaultValues?: IPartialEnterprise;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  errors,
  isLoading,
}) => {
  const { user } = useEssentials();
  const methods = useForm<IEnterpriseForm>({
    defaultValues,
    resolver: yupResolver(schema(user)),
  });
  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const submitHandler = useCallback(
    (values: IEnterpriseForm) => {
      onSubmit({
        ...defaultValues,
        ...values,
      });
    },
    [defaultValues, onSubmit]
  );
  return (
    <Controls.Form submitHandler={submitHandler} methods={methods}>
      <EnterpriseDomainNames />
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};
export default memo(Form);
