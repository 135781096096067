import React, { memo, useState } from 'react';

import {
  QUERY_KEYS,
  useFetchFrontOfficeUsers,
  useMutation,
} from '@core/queries';

import { RenewalsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { IRenewal } from '@core/types';

import { Button, FormControl, Grid, Select } from '@shared/components';

import { FormatterUtils } from '@shared/utils';
import { FullNameFormatEnum } from '@shared/utils/formatter';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IFilterCondition,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../modals';

interface Props {
  renewal: IRenewal;
  open: boolean;
  onClose: () => void;
}

const ManageRenewalFavoriteModal: React.FCC<Props> = ({
  renewal,
  open,
  onClose,
}) => {
  const { t } = useEssentials();

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(RenewalsApi.patch, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD['RENEWALS']],
  });

  const [currentValue, setCurrentValue] = useState<Array<string>>(
    renewal.businessDevelopers.map((bd) => bd.id)
  );
  const conditions: Array<IFilterCondition> = [
    {
      column: 'kind',
      operator: 'in',
      value: 'SUPPLIER,TRADING_GROUP',
    },
    {
      column: 'status',
      operator: 'eq',
      value: 'ENABLED',
    },
  ];

  if (renewal?.lastAssessmentRequester) {
    conditions.push({
      column: 'company.id',
      operator: 'eq',
      value: renewal.lastAssessmentRequester.id,
    });
  }
  const { users, isSettled } = useFetchFrontOfficeUsers({
    conditions: conditions,
  });

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
    >
      <BaseDialog.Title id='BDs Assignment' onClose={onClose}>
        {t('Assign BDs to this Distributor')}
      </BaseDialog.Title>
      <BaseDialog.Content dividers>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            {isSettled && (
              <FormControl style={{ width: '75%' }}>
                <Select
                  id='businessDevelopers'
                  name='businessDevelopers'
                  value={currentValue}
                  fullWidth
                  multiple
                  onChange={(event) => {
                    setCurrentValue(event.target.value as Array<string>);
                  }}
                  inputProps={{ style: { height: '100%' } }}
                  options={users.map((user) => {
                    return {
                      label: FormatterUtils.formatFullName(
                        user.contactInfo,
                        FullNameFormatEnum.JOHN_DOE
                      ),
                      value: user?.id || '',
                    };
                  })}
                ></Select>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </BaseDialog.Content>
      <BaseDialog.Actions>
        <Button
          id='cancel-update-favorites'
          onClick={onClose}
          type='button'
          color='primary'
          disabled={patchMutation.isLoading}
        >
          {t('Cancel')}
        </Button>
        <Button
          id='submit-update-next-action'
          type='submit'
          color='primary'
          isLoading={patchMutation.isLoading}
          disabled={patchMutation.isLoading}
          onClick={() => {
            patchMutation.mutate({
              id: renewal.id,
              body: { businessDevelopers: currentValue },
            });
            onClose();
          }}
        >
          {t('Confirm')}
        </Button>
      </BaseDialog.Actions>
    </BaseDialog.Dialog>
  );
};

export default memo(ManageRenewalFavoriteModal);
