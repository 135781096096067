import type { IARTransitionEnum } from '@inteliam/foundation/lib/enums';
import { isNil } from 'lodash-es';

import React, { Fragment, useCallback, useState } from 'react';

import TransitionDialogs from '@core/components/modals/ar-transition-dialogs';

import { createNamedContext, useNamedContext } from '@shared/contexts';

import type { IAR, ITransitionResponse } from '@inteliam/foundation/lib/types';

type ITransitionContext = (options: {
  transition: IARTransitionEnum;
  assessmentRequest: IAR;
}) => Promise<ITransitionResponse>;

const INITIAL_CONTEXT: ITransitionContext = () =>
  Promise.resolve({ status: 'CANCELLED' });
const TransitionContext = createNamedContext<ITransitionContext>(
  'ARTransitionContext'
);

type Function_ = (value: ITransitionResponse) => void;
interface State {
  transition: IARTransitionEnum | undefined;
  assessmentRequest: IAR | undefined;
}
const ARTransitionProvider: React.FCC = ({ children }) => {
  const [state, setState] = useState<State>({
    transition: undefined,
    assessmentRequest: undefined,
  });
  const [resolveReject, setResolveReject] = useState<Function_[]>([]);
  const [resolve, reject] = resolveReject;
  const openDialog: ITransitionContext = useCallback(
    ({ transition, assessmentRequest }): Promise<ITransitionResponse> => {
      return new Promise((resolve, reject) => {
        setState({ transition, assessmentRequest });
        setResolveReject([resolve, reject]);
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    setResolveReject([]);
    setState({ transition: undefined, assessmentRequest: undefined });
  }, []);

  const handleCancel = useCallback(() => {
    reject({ status: 'CANCELLED' });
    handleClose();
  }, [reject, handleClose]);

  const handleConfirm = useCallback(() => {
    resolve({ status: 'SUCCESS' });
    handleClose();
  }, [resolve, handleClose]);

  const Dialog =
    !isNil(state.transition) && TransitionDialogs[state.transition]
      ? TransitionDialogs[state.transition]
      : undefined;
  return (
    <Fragment>
      <TransitionContext.Provider value={openDialog}>
        {children}
      </TransitionContext.Provider>
      {Dialog ? (
        <Dialog
          open={resolveReject.length === 2}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          assessmentRequest={state.assessmentRequest}
        />
      ) : undefined}
    </Fragment>
  );
};

export const useOpenTransitionDialog = (): ITransitionContext => {
  return useNamedContext(TransitionContext, INITIAL_CONTEXT);
};

export default ARTransitionProvider;
