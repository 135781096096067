import * as React from 'react';

import {
  BaseDialog,
  CenteredSpinner,
  CloseIcon,
  IconButton,
  Typography,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { DialogContentProps, DialogProps } from '@shared/types';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  onClose?: () => void;
}

export const Title: React.FCC<DialogTitleProps> = (props) => {
  const classes = useStyles();
  const { children, onClose, subtitle, id, ...other } = props;

  return (
    <BaseDialog.Title
      className={classes.root}
      {...other}
      data-cy={id}
      data-testid={id}
    >
      <Typography variant='h6'>{children}</Typography>
      <Typography variant='subtitle1'>{subtitle}</Typography>
      {onClose ? (
        <IconButton
          id='close-modal'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : undefined}
    </BaseDialog.Title>
  );
};

export const Content: React.FCC<DialogContentProps> = (props) => {
  const { children, ...other } = props;

  return <BaseDialog.Content {...other}>{children}</BaseDialog.Content>;
};

export const Actions: React.FCC = (props) => {
  const { children, ...other } = props;

  return <BaseDialog.Actions {...other}>{children}</BaseDialog.Actions>;
};

export const Dialog = (props: DialogProps): JSX.Element => {
  const { children, ...other } = props;

  return <BaseDialog.DContainer {...other}>{children}</BaseDialog.DContainer>;
};

export const DialogPlaceholder: React.FCC = ({
  children = <CenteredSpinner />,
}) => {
  return (
    <Dialog open>
      <Content>{children}</Content>
    </Dialog>
  );
};
