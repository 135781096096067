import { Yup } from '@inteliam/foundation/lib/utils';

const create = Yup.object().shape({
  name: Yup.string().required(),
});

const assign = Yup.object().shape({
  campaign: Yup.object().shape({
    id: Yup.string().required(),
  }),
  manufacturer: Yup.string(),
});

export { create, assign };
