import { IARActionsEnum, IBORolesEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import {
  Validations,
  enhancedYupResolver,
} from '@inteliam/foundation/lib/utils';

import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import {
  AssignmentManagement,
  CompanyContacts,
  CompanyInformation,
  EnterpriseCampaign,
  IntegrationIdsManagement,
  RegistrationActions,
  RegistrationSchemas,
} from '@core/components/assessment-requests/registration';

import { ARUtils, RegistrationsUtils, UsersUtils } from '@core/utils';

import { INotifierEnum, useEssentials } from '@core/contexts';
import type {
  IRegistrationState,
  IAssessmentRequestPayload,
  IAssessmentRequestForm,
} from '@core/types';

import { Controls } from '@shared/components';

import {
  BOErrorTracking,
  ErrorCodesLookup,
  ValidationUtils,
} from '@shared/utils';

import type { IActionItem } from '@shared/types';

import type { ValidationErrorResponse } from '@inteliam/foundation/lib/types';

type PartialIRegistrationState = Partial<IRegistrationState>;
interface Props {
  errors?: ValidationErrorResponse;
  currentState: IRegistrationState;
  onSubmit: (values: IAssessmentRequestPayload) => void;
  onSelectCompany: () => void;
  isLoading: boolean;
  onUpdateState: (state: PartialIRegistrationState) => void;
}

const RegistrationForm: React.FCC<Props> = ({
  currentState,
  onSubmit,
  onSelectCompany,
  isLoading,
  errors,
  onUpdateState,
}) => {
  const { user, t, notify, confirm } = useEssentials();
  const methods = useForm<IAssessmentRequestForm>({
    defaultValues: RegistrationsUtils.initializeAR(
      RegistrationsUtils.DEFAULT_COMPANY,
      currentState.assessmentRequest
    ),
    resolver: enhancedYupResolver<
      typeof RegistrationSchemas.assessmentRequestSchema
    >(
      RegistrationSchemas.assessmentRequestSchema,
      undefined,
      undefined,
      currentState.action.value !== IARActionsEnum.SAVE_AS_DRAFT
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const actions: IActionItem<IARActionsEnum>[] = useMemo(
    () =>
      RegistrationsUtils.getAvailableActions(
        user,
        currentState.mode,
        currentState.company
      ),
    [currentState, user]
  );

  const handleOnReset = useCallback(() => {
    confirm({
      description: t(
        'All the prefilled fields will be erased. Do you want to continue?'
      ),
    })
      .then(() => {
        methods.reset({
          scoredCompany: RegistrationsUtils.DEFAULT_COMPANY,
        });
        onUpdateState({ company: undefined, mode: 'CREATE' });
      })
      .catch(() => {});
  }, [methods, confirm, t, onUpdateState]);

  const handleOnChangeAction: React.ComponentProps<
    typeof RegistrationActions
  >['onChangeAction'] = useCallback(
    (action) => {
      onUpdateState({ action });
    },
    [onUpdateState]
  );

  useEffect(() => {
    if (currentState.company) {
      methods.reset({
        ...RegistrationsUtils.initializeAR(
          RegistrationsUtils.DEFAULT_COMPANY,
          currentState.assessmentRequest
        ),
        scoredCompany: currentState.company,
        questionnaireContact:
          currentState.mode === 'CREATE_FROM_EXISTING' &&
          currentState.company.lastSurveyContact
            ? currentState.company.lastSurveyContact
            : currentState.assessmentRequest?.questionnaireContact,
      });
      onSelectCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.assessmentRequest, currentState.company]);
  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);
  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      data-action={currentState.action.value}
      submitHandler={(data, event) => {
        event?.persist();
        const selectedAction = event?.target?.dataset?.action as IARActionsEnum;
        const nextState = {
          ...currentState,
          action: {
            ...currentState.action,
            value: selectedAction,
          },
        };
        const submit = () => {
          confirm({
            description: t(
              RegistrationsUtils.getConfirmationMessage(nextState)
            ),
          })
            .then(() => {
              if (nextState.action.value === IARActionsEnum.SAVE) {
                notify({
                  message: t('Match & Merge in progress'),
                  type: INotifierEnum.Info,
                });
              }

              onSubmit(
                ARUtils.normalize.assessmentRequestRegistrationPayload(
                  data,
                  nextState
                )
              );
            })
            .catch((error) => {
              BOErrorTracking.report(error, 'CreateResource', undefined, [
                { key: 'input', value: JSON.stringify(data) },
              ]);
            });
        };

        if (methods.getValues('campaign.id')) {
          submit();
        } else {
          confirm({
            description: t(
              'You did not assign this AR to a campaign. Do you want to move forward?'
            ),
          })
            .then(submit)
            .catch(() => {});
        }
      }}
      failureHandler={() => {
        notify({
          message: t(ErrorCodesLookup.E0000.title),
          type: INotifierEnum.Error,
        });
      }}
    >
      <CompanyInformation onReset={handleOnReset} prefix='scoredCompany' />
      <IntegrationIdsManagement prefix='scoredCompany' />
      <CompanyContacts currentState={currentState} />
      {UsersUtils.isGranted(
        IBORolesEnum.ROLE_REGULAR_ENGAGEMENT_SPECIALIST,
        user
      ) && <EnterpriseCampaign />}
      <AssignmentManagement />
      <RegistrationActions
        isLoading={isLoading}
        onChangeAction={handleOnChangeAction}
        mode={currentState.mode}
        actions={actions}
        company={currentState.company}
      />
    </Controls.Form>
  );
};

export default memo(RegistrationForm);
