import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  ICampaignType,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/campaign-types',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const updateDefaultDeadlines = async (
  body: ICampaignType
): Promise<IAPIResponse<ICampaignType>> => {
  const { data } = await client.instance.put(`/${body.id}`, body);
  return data;
};

export default {
  updateDefaultDeadlines,
};
