import type { ICampaignTypeEnum } from '@inteliam/foundation/lib/enums';
import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { CampaignsApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ICampaignType,
} from '@inteliam/foundation/lib/types';

const useFetchCampaignTypeByType = ({
  type,
}: {
  type: ICampaignTypeEnum;
}): InteliamUseQueryResult<
  IAPIResponse<ICampaignType>,
  BaseAxiosErrorResponse
> => {
  return useQuery<IAPIResponse<ICampaignType>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.STANDARD_CAMPAIGN],
    () => {
      return CampaignsApi.getCampaignTypeByType(type);
    },
    { ...DEFAULT_ONE_TIME_QUERY_OPTIONS, initialData: undefined, cacheTime: 0 }
  );
};

export default useFetchCampaignTypeByType;
