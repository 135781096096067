import * as React from 'react';

import { useScoreCardHandlers, useWorkingPage } from '@core/hooks';

import { MathUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { TextField } from '@shared/components';

import type { PatchBody } from '@inteliam/foundation/lib/types';

type Props = {
  defaultValue?: number;
  label?: string;
  name: string;
  additionalUpdate?: PatchBody;
  readOnly?: boolean;
  min?: number;
  max?: number;
  step?: number;
  decimal?: boolean;
  roundingPrecision?: number;
};

const ScoreInput: React.FCC<Props> = ({
  name,
  additionalUpdate,
  decimal,
  roundingPrecision,
  ...rest
}) => {
  const { t } = useEssentials();
  const { onUpdate } = useScoreCardHandlers();
  const { scoreCard } = useWorkingPage();
  return (
    <TextField
      {...rest}
      type='number'
      label={t('Score')}
      onChange={({ target }) => {
        onUpdate(
          {
            ...additionalUpdate,
            [name]: MathUtils.convertToNumber(
              target.value,
              decimal,
              roundingPrecision
            ),
          },
          scoreCard.id
        );
      }}
    />
  );
};

export default React.memo(ScoreInput);
