import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { DateFormatter, PriceUtils } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { MemberOfField, SizeHelper } from '@core/components';

import {
  useAvailableCurrencies,
  useCompanyQualificationEnums,
} from '@core/queries';

import { MASKS_ENUMS } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type {
  IBODistributorConfiguration,
  IDistributorForm,
} from '@core/types';

import {
  Button,
  Card,
  Controls,
  Divider,
  FormGroup,
  Grid,
} from '@shared/components';

import HeadquarterInformation from './headquarter-information';

interface Props {
  onReset?: () => void;
  update?: boolean;
  prefix?: 'scoredCompany' | '';
}

const CompanyInformation: React.FCC<Props> = ({
  onReset,
  update = false,
  prefix = '',
}) => {
  const { t } = useEssentials();
  const _prefix = prefix ? prefix + '.' : '';
  const { formattedCurrencies } = useAvailableCurrencies();
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  const methods = useFormContext();
  const watchedName = useWatch({
    name: `${_prefix}name`,
    control: methods.control,
  });
  const watchedWarehousesNumber = useWatch({
    name: `${_prefix}warehousesCount`,
    control: methods.control,
  });
  const watchedTurnoverUserValue = useWatch({
    name: `${_prefix}turnover.userValue`,
    defaultValue: methods.getValues(`${_prefix}turnover.userValue`),
    control: methods.control,
  });
  const watchedTurnoverUnit = useWatch({
    name: `${_prefix}turnover.unit`,
    defaultValue: methods.getValues(`${_prefix}turnover.unit`),
    control: methods.control,
  });

  const watchedConfiguration = useWatch({
    name: `${_prefix}configuration`,
    defaultValue: methods.getValues(`${_prefix}configuration`),
    control: methods.control,
  }) as IBODistributorConfiguration;

  useOnUpdate(() => {
    methods.setValue(
      `${_prefix}turnover.value`,
      PriceUtils.getRawValue(
        watchedTurnoverUserValue as number,
        watchedTurnoverUnit as string
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedTurnoverUserValue, watchedTurnoverUnit]);

  return (
    <Card.CContainer styled>
      <Card.Header
        title={t('Company Information')}
        subheader={
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span>{t("Fill in the company's information")}</span>
            {!update && (
              <Button
                id='erase'
                color='secondary'
                type='button'
                onClick={onReset}
              >
                {t('Erase all & create new')}
              </Button>
            )}
          </Grid>
        }
      />
      <Divider />
      <Card.Body>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Company name')}
                name={`${_prefix}name`}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Medal Display Name')}
                name={`${_prefix}medalDisplayName`}
                required={watchedName.length > 26}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.MaskedFormInput
                label={t('Duns')}
                name={`${_prefix}dunsIdentifier`}
                mask={MASKS_ENUMS.DUNS_MASK}
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormCountrySelect
                label={t('Country')}
                name={`${_prefix}address.country`}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('National ID')}
                name={`${_prefix}nationalIdentifier`}
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Address line 1')}
                name={`${_prefix}address.address`}
                rows={3}
                multiline
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('City/Region')}
                name={`${_prefix}address.city`}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('State')}
                name={`${_prefix}address.state`}
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Zip code')}
                name={`${_prefix}address.zipCode`}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Website')}
                name={`${_prefix}website`}
                type='url'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              <Controls.FormDatePicker
                label={t('Incorporation date')}
                inputFormat={'yyyy'}
                views={['year']}
                name={`${_prefix}incorporationDate`}
                pickerType='date'
                textFieldProps={{
                  required: true,
                }}
              />
            </FormGroup>
          </Grid>

          <HeadquarterInformation prefix={prefix} />
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Number of employees')}
                name={`${_prefix}numberOfEmployees`}
                type='number'
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={1} xs={12}>
            <SizeHelper />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormGroup>
              {companyQualificationEnums && (
                <Controls.FormSelect
                  label={t('Size')}
                  name={`${_prefix}size`}
                  options={companyQualificationEnums.sizes}
                  required
                />
              )}
            </FormGroup>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormGroup>
              {companyQualificationEnums && (
                <Controls.FormSelectTypeahead
                  label={t('Category')}
                  name={`${_prefix}category`}
                  options={companyQualificationEnums.categories}
                  required
                />
              )}
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <div hidden>
              <Controls.FormInput
                name={`${_prefix}turnover.decimals`}
                type='number'
                required
              />
            </div>
            <div hidden>
              <Controls.FormInput
                name={`${_prefix}turnover.value`}
                type='number'
                required
              />
            </div>
            <FormGroup>
              <Controls.FormInput
                label={t('Turnover')}
                name={`${_prefix}turnover.userValue`}
                type='number'
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Unit')}
                name={`${_prefix}turnover.unit`}
                options={[
                  {
                    label: 'K',
                    value: 'K',
                  },
                  {
                    label: 'M',
                    value: 'M',
                  },
                  {
                    label: 'B',
                    value: 'B',
                  },
                  {
                    label: 'T',
                    value: 'T',
                  },
                ]}
                disabled={!watchedTurnoverUserValue}
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormSelect
                label={t('Currency')}
                name={`${_prefix}turnover.currency`}
                options={formattedCurrencies}
                disabled={!watchedTurnoverUserValue}
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <MemberOfField
              name={`${_prefix}memberOfHierarchy` as keyof IDistributorForm}
            />
          </Grid>
          {companyQualificationEnums && (
            <Grid item xs={12} md={4}>
              <FormGroup>
                <Controls.FormSelect
                  label={t('Ownership structure')}
                  name={`${_prefix}ownershipStructure`}
                  options={companyQualificationEnums?.ownershipStructures}
                />
              </FormGroup>
            </Grid>
          )}
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormInput
                type={'number'}
                label={t('Number of warehouses')}
                name={`${_prefix}warehousesCount`}
              />
            </FormGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <FormGroup>
              <Controls.FormInput
                type={'number'}
                label={t('Total storage m²')}
                name={`${_prefix}totalStorageArea`}
                disabled={!watchedWarehousesNumber}
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t('Subsidiary')}
                name={`${_prefix}subsidiary`}
                defaultValue={false}
                options={[
                  {
                    value: true,
                    label: t('Yes'),
                  },
                  {
                    value: false,
                    label: t('No'),
                  },
                ]}
                row
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t('Visible on Directory?')}
                name={`${_prefix}configuration.visibleOnDirectory`}
                defaultValue={true}
                options={[
                  {
                    value: true,
                    label: t('Yes'),
                  },
                  {
                    value: false,
                    label: t('No'),
                  },
                ]}
                row
              />
            </FormGroup>
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t("Distributor 'Always Share'")}
                name={`${_prefix}configuration.public`}
                defaultValue={true}
                options={[
                  {
                    value: true,
                    label: t('Active'),
                  },
                  {
                    value: false,
                    label: t('Inactive'),
                  },
                ]}
                row
              />
            </FormGroup>
            {watchedConfiguration.public && watchedConfiguration.sharedAt && (
              <span style={{ opacity: 0.5 }}>
                {t('Activated on {{date}} by {{username}}', {
                  date: DateFormatter.formatTimestamp(
                    new Date(watchedConfiguration.sharedAt),
                    DateFormatter.FORMATS.fr_FR.SHORT
                  ),
                  username: watchedConfiguration.sharingUsername,
                })}
              </span>
            )}
          </Grid>
          <Grid item md={3} xs={12}>
            <FormGroup>
              <Controls.FormBoolRadioGroup
                label={t('Show Profile Viewers Section on Dashboard')}
                name={`${_prefix}configuration.showProfileViewers`}
                defaultValue={true}
                options={[
                  {
                    value: true,
                    label: t('Yes'),
                  },
                  {
                    value: false,
                    label: t('No'),
                  },
                ]}
                row
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Services/Products')}
                name={`${_prefix}services`}
                placeholder='Services/Products description'
                rows={3}
                multiline
                required
              />
            </FormGroup>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormGroup>
              <Controls.FormInput
                label={t('Inteliam internal additional comments')}
                name={`${_prefix}additionalComments`}
                placeholder='Add comments related to the company here'
                // rows={5}
                minRows={5}
                multiline
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Card.Body>
      <Divider />
    </Card.CContainer>
  );
};

export default memo(CompanyInformation);
