import { Transformers } from '@inteliam/foundation/lib/utils';
import { Select as MUISelect } from '@mui/material';
import { isArray, isNil } from 'lodash-es';

import * as React from 'react';

import { useEssentials } from '@core/contexts';

import {
  Checkbox,
  ListItemIcon,
  MenuItem,
  ListItemText,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { FormSelectProps } from '@shared/types';

const ALL_VALUE = '*';
const useStyles = makeStyles(() => ({
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    'backgroundColor': 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}));
const Select = React.forwardRef<HTMLSelectElement, FormSelectProps>(
  (props, ref) => {
    const classes = useStyles();

    const { t } = useEssentials();
    const {
      required,
      name,
      multiple,
      options,
      selectAllValue = ALL_VALUE,
      selectAllLabel = t('All'),
      enableSelectAll,
      ...rest
    } = props;

    const isAllSelected =
      enableSelectAll &&
      multiple &&
      Array.isArray(props.value) &&
      props.options.length > 0 &&
      props.value.length === options.length;

    return (
      <MUISelect
        required={required}
        multiple={multiple}
        {...rest}
        inputProps={{
          id: name,
        }}
        inputRef={ref} // needed so that RHF can focus on error
        // innerRef={ref}
        value={getValue(multiple, props.value)}
        autoComplete='new-password'
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          // getContentAnchorEl: undefined,
        }}
        {...(multiple && {
          renderValue: (selected) => {
            if (isAllSelected) {
              return selectAllLabel;
            }
            const selectedLabels: string[] = options.reduce(
              (result: string[], option) => {
                if ((selected as string[]).includes(option.value as string)) {
                  result.push(Transformers.getOptionLabel(option, t));
                }
                return result;
              },
              []
            );
            return selectedLabels.join(', ');
          },
        })}
        data-cy={props.id ?? name}
        data-testid={name}
      >
        {multiple && enableSelectAll && (
          <MenuItem
            value={selectAllValue}
            classes={{
              root: isAllSelected ? classes.selectedAll : '',
            }}
            // eslint-disable-next-line sonarjs/no-duplicate-string
            data-cy={t('Select All')}
            data-testid={t('Select All')}
          >
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={
                  Array.isArray(props.value) &&
                  props.value.length > 0 &&
                  props.value.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary={t('Select All')}
            />
          </MenuItem>
        )}
        {/* TODO Rethink this */}
        {!required && !enableSelectAll && (
          <MenuItem value={''}>
            <p></p>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            data-cy={Transformers.getOptionLabel(option, t)}
            data-testid={Transformers.getOptionLabel(option, t)}
          >
            {multiple && (
              <Checkbox
                readOnly
                checked={
                  Array.isArray(props.value)
                    ? props.value?.includes(option.value)
                    : false
                }
              />
            )}
            {Transformers.getOptionLabel(option, t)}
          </MenuItem>
        ))}
      </MUISelect>
    );
  }
);
export default Select;

/* eslint-disable @typescript-eslint/no-explicit-any */
function getValue(multiple: boolean | undefined, value: any): unknown {
  if (multiple && !isArray(value)) {
    return [value];
  }
  if (isNil(value)) {
    return '';
  }
  return value;
}
