/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { useScoreCardHandlers } from '@core/hooks';

import { useEssentials } from '@core/contexts';

import { Button, Controls, Grid, FormGroup } from '@shared/components';

import type {
  IQuestionPartialScore,
  IScoreCard,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '..';

interface Props {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  fieldName: string;
  questionPartialScore: IQuestionPartialScore;
  scoreCard: IScoreCard;
  readOnly: boolean;
}

const Form: React.FCC<Props> = ({
  onClose,
  open,
  questionPartialScore,
  isLoading,
  fieldName,
  scoreCard,
  readOnly,
}) => {
  const { t } = useEssentials();
  const methods = useForm<{ comment: string }>();
  const { onUpdate } = useScoreCardHandlers();
  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth='lg'
    >
      <Controls.Form
        methods={methods}
        submitHandler={(data) => {
          onUpdate(
            {
              [`${fieldName}.scoringMeta.comment`]: data.comment,
            },
            scoreCard.id
          );
          onClose();
        }}
      >
        <BaseDialog.Title id='question-comment' onClose={onClose}>
          {t('Question Comment')}
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <FormGroup>
                <Controls.FormInput
                  readOnly={readOnly}
                  name={'comment'}
                  label={t('Comment')}
                  rows={3}
                  multiline
                  fullWidth
                  defaultValue={questionPartialScore.scoringMeta.comment}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='cancel-update-comment'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          {!readOnly && (
            <Button
              id='submit-comment'
              type='submit'
              color='primary'
              isLoading={isLoading}
            >
              {t('Confirm')}
            </Button>
          )}
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};

export default memo(Form);
