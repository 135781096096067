import { Helpers } from '@inteliam/foundation/lib/utils';
import { sortBy } from 'lodash-es';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DocumentEvaluationsApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IOptionItem,
} from '@inteliam/foundation/lib/types';

const useFetchDocumentScopeLevels = (): {
  scopeLevels: string[];
  formattedScopeLevels: IOptionItem[];
  isLoading: boolean;
} => {
  const query = useQuery<IAPIResponse<Array<string>>, BaseAxiosErrorResponse>(
    [...QUERY_KEYS.DOCUMENTS_SCOPE_LEVELS_LIST],
    () => DocumentEvaluationsApi.getScopeLevelsAction(),
    {
      cacheTime: Number.POSITIVE_INFINITY,
      refetchOnWindowFocus: false,
    }
  );
  const scopeLevels = sortBy(Helpers.ensureValueAsArray(query.data?.data));

  return {
    scopeLevels,
    formattedScopeLevels: scopeLevels.map((scopeLevel) => ({
      label: scopeLevel,
      value: scopeLevel,
    })),
    isLoading: query.isLoading || query.isFetching,
  };
};

export default useFetchDocumentScopeLevels;
