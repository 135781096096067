import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useQuestionFormContext } from '@core/components';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import {
  Card,
  Typography,
  Grid,
  Box,
  FormGroup,
  Divider,
} from '@shared/components';

import ScopeItem from './scope-item';

/* eslint-disable @inteliam/i18n/raw-text-detected */

const QuestionAndOptionScope: React.FCC = () => {
  const { t } = useEssentials();
  const { readOnly } = useQuestionFormContext();
  const methods = useFormContext<IQuestionForm>();
  const questionType = useWatch({
    control: methods.control,
    name: 'kind',
  });

  const watchedOptions =
    useWatch({
      control: methods.control,
      name: 'options',
    }) || [];

  const availableOptions =
    (questionType === IQuestionKindEnum.SingleFreeFormField
      ? []
      : watchedOptions) || [];

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Question and option scope')} />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <Typography component='h1' variant='h6'>
            {t('Question')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <ScopeItem {...{ readOnly }} />
        </Grid>
      </Card.Body>
      {questionType !== IQuestionKindEnum.SingleFreeFormField && (
        <>
          <Divider />
          <Card.Body>
            <Box p={2}>
              <Typography component='h1' variant='h6'>
                {t('Options')}
              </Typography>
            </Box>
            {availableOptions.map((option, index) => {
              const fieldName = Helpers.arrayDotNotation('options', index);
              return (
                <Grid container spacing={1} key={option.id}>
                  <Grid
                    item
                    md={1}
                    xs={12}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <FormGroup>Option {index + 1}</FormGroup>
                  </Grid>
                  <ScopeItem
                    prefix={fieldName}
                    defaultValues={option.scopes}
                    {...{ readOnly }}
                  />
                </Grid>
              );
            })}
          </Card.Body>
        </>
      )}
    </Card.CContainer>
  );
};

export default memo(QuestionAndOptionScope);
