import { Helpers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useQuestionFormContext } from '@core/components/questions';

import {
  useCompanyQualificationEnums,
  useFetchCountries,
  useFetchEnterpriseRegions,
} from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { ISsqQuestionFormData } from '@core/types';

import {
  Controls,
  ErrorHelpText,
  FormFieldLoadingIndicator,
  Paper,
  FormGroup,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableContainer,
  TableBody,
} from '@shared/components';

import type {
  IIdentifiedObject,
  IOptionItem,
  ISsqScope,
} from '@inteliam/foundation/lib/types';

const ALL_SELECTED = ['*'];
const ScopeItem: React.FCC = () => {
  const { t } = useEssentials();
  const methods = useFormContext<ISsqQuestionFormData>();
  const { defaultValues, readOnly } = useQuestionFormContext();
  const { companyQualificationEnums } = useCompanyQualificationEnums();
  const watchedScopes = useWatch({
    control: methods.control,
    name: 'scopes',
  });

  return (
    <>
      {companyQualificationEnums && (
        <TableContainer component={Paper}>
          <Table
            size='small'
            style={{
              overflow: 'hidden',
              pointerEvents: readOnly ? 'none' : 'all',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {companyQualificationEnums.sizes.map((size) => {
                  return (
                    <TableCell key={size.value} align='center'>
                      {size.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyQualificationEnums.categories.map(
                (category: IOptionItem, categoryIndex: number) => (
                  <TableRow key={category.value}>
                    <TableCell component='th' scope='row'>
                      {t(category.label)}
                    </TableCell>
                    {companyQualificationEnums.sizes.map((size, sizeIndex) => {
                      const scopeIndex =
                        // eslint-disable-next-line no-mixed-operators
                        categoryIndex * companyQualificationEnums.sizes.length +
                        sizeIndex;
                      return (
                        <ScopeCell
                          key={size.value}
                          index={scopeIndex}
                          size={size.value.toString()}
                          category={category.value.toString()}
                          defaultValues={
                            defaultValues?.scopes as ISsqScope[] | undefined // TODO use generics
                          }
                          geoClassificationType={
                            watchedScopes[scopeIndex]?.geoClassificationType ||
                            'country'
                          }
                          readOnly={readOnly}
                        />
                      );
                    })}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const ScopeCell: React.FCC<{
  index: number;
  defaultValues?: ISsqScope[];
  geoClassificationType: 'region' | 'country';
  size: string;
  category: string;
  readOnly?: boolean;
}> = memo(
  ({
    index,
    defaultValues,
    geoClassificationType,
    size,
    category,
    readOnly,
  }) => {
    const { t } = useEssentials();
    const parameters = useParams<IIdentifiedObject>();
    const methods = useFormContext<ISsqQuestionFormData>();
    const countriesQuery = useFetchCountries();
    // todo maybe lazy load regions
    const regionsQuery = useFetchEnterpriseRegions({ id: parameters.id });
    const hasError = methods.formState.errors.scopes?.[index];
    return (
      <TableCell align='right'>
        {hasError && (
          <ErrorHelpText>
            {t('Please select at least one country/region')}
          </ErrorHelpText>
        )}
        <FormGroup>
          <Controls.FormInput
            name={`${Helpers.arrayDotNotation('scopes', index)}.size`}
            defaultValue={size}
            style={{ display: 'none' }}
          />
          <Controls.FormInput
            name={`${Helpers.arrayDotNotation('scopes', index)}.category`}
            defaultValue={category}
            style={{ display: 'none' }}
          />

          <Controls.FormRadioGroup
            label={''}
            name={`${Helpers.arrayDotNotation(
              'scopes',
              index
            )}.geoClassificationType`}
            options={[
              {
                label: t('Region'),
                value: 'region',
              },
              {
                label: t('Country'),
                value: 'country',
              },
            ]}
            defaultValue={
              defaultValues?.[index]?.geoClassificationType || 'country'
            }
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            readOnly={readOnly}
            onChange={(_, value) => {
              // clean up the other type (eg. select regions => clean up countries)
              methods.setValue(
                value === 'region'
                  ? `${Helpers.arrayDotNotation('scopes', index)}.countries`
                  : `${Helpers.arrayDotNotation('scopes', index)}.regions`,
                []
              );
              // set all values for the newly selected type
              methods.setValue(
                value === 'region'
                  ? `${Helpers.arrayDotNotation('scopes', index)}.regions`
                  : `${Helpers.arrayDotNotation('scopes', index)}.countries`,
                value === 'region'
                  ? regionsQuery.regions.map((region) =>
                      region.value.toString()
                    )
                  : countriesQuery.countries.map((country) =>
                      country.value.toString()
                    )
              );
            }}
            row
          />
        </FormGroup>
        <FormGroup style={{ alignItems: 'center' }}>
          {geoClassificationType === 'region' && (
            <>
              {regionsQuery.isSettled ? (
                <Controls.FormSelect
                  style={{ height: 30, maxWidth: 200 }}
                  defaultValue={defaultValues?.[index]?.regions || ALL_SELECTED}
                  name={`${Helpers.arrayDotNotation('scopes', index)}.regions`}
                  options={regionsQuery.regions}
                  multiple
                  enableSelectAll
                  selectAllLabel={t('All regions')}
                  showError={false}
                  readOnly={readOnly}
                />
              ) : (
                <FormFieldLoadingIndicator style={{ height: 30, width: 150 }} />
              )}
            </>
          )}
          {geoClassificationType === 'country' && (
            <>
              {countriesQuery.isSettled ? (
                <Controls.FormSelect
                  style={{ height: 30, maxWidth: 200 }}
                  defaultValue={
                    defaultValues?.[index]?.countries || ALL_SELECTED
                  }
                  name={`${Helpers.arrayDotNotation(
                    'scopes',
                    index
                  )}.countries`}
                  options={countriesQuery.countries}
                  multiple
                  enableSelectAll
                  selectAllLabel={t('All countries')}
                  showError={false}
                  readOnly={readOnly}
                />
              ) : (
                <FormFieldLoadingIndicator style={{ height: 30, width: 150 }} />
              )}
            </>
          )}
        </FormGroup>
      </TableCell>
    );
  }
);
export default memo(ScopeItem);
