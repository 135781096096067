import type { SwitchProps } from '@mui/material';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { FormControlLabel, Switch, ErrorHelpText } from '@shared/components';

const FormSwitch: React.FCC<
  SwitchProps & {
    name: string;
    helperText?: string;
    label: string;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  }
> = (props) => {
  const { t } = useEssentials();
  const { helperText, name, label, labelPlacement, ...rest } = props;
  const {
    register,

    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, `${name}.message`) as unknown as string;
  const { onChange: rhfOnchange, ...rhfProps } = register(name);
  return (
    <>
      <FormControlLabel
        id={name}
        control={
          <Switch
            id={name}
            inputProps={{
              // @ts-expect-error mui error
              'data-disabled': rest.disabled || rest.readOnly,
            }}
            {...rest}
            {...rhfProps}
            onChange={(event, checked) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              rhfOnchange(event);
              if (rest.onChange) {
                rest.onChange(event, checked);
              }
            }}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {(errorMessage || helperText) && (
        <ErrorHelpText data-cy={`helper-${name}`}>
          {errorMessage ? t(errorMessage, {}) : helperText}
        </ErrorHelpText>
      )}
    </>
  );
};

export default memo(FormSwitch);
