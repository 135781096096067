import { Yup } from '@inteliam/foundation/lib/utils';

export default Yup.array()
  .of(Yup.string())
  // Yup.object.shape({
  //     label: Yup.string().required('This field requires your attention'),
  //     value: Yup.string().required('This field requires your attention'),
  //   })

  .nullable();
