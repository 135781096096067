/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IARAlert } from '@core/types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function isIARAlert(argument: any): argument is IARAlert {
  return (
    typeof argument.assessmentRequest.id === 'string' &&
    typeof argument.type === 'string' &&
    typeof argument.level === 'string'
  );
}
