import { useModalState } from '@inteliam/foundation/lib/hooks';

import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { ManageSwByThemeModal } from '@core/components/modals';

import { useEssentials } from '@core/contexts';

import { Button, EditIcon } from '@shared/components';

import type { IPartialScore, IScoreCard } from '@inteliam/foundation/lib/types';

type Props = {
  partialScore: IPartialScore;
  scoreCard: IScoreCard;
};

const ReviewGroupingSwitch: React.FCC<Props> = ({
  partialScore,
  scoreCard,
}) => {
  const { t } = useEssentials();
  const [modalState, modalHandlers] = useModalState();
  const location = useLocation();
  if (!location.pathname.includes(partialScore.theme.id)) {
    return <></>;
  }
  return (
    <>
      <Button
        onClick={() => modalHandlers.onOpenUpdate(partialScore.theme.id)}
        style={{ padding: '10px' }}
        id={`edit-sw-${partialScore.theme.code}`}
      >
        <EditIcon />
        {t('S/IA')}
      </Button>
      {modalState.mode === 'UPDATE' &&
        modalState.data === partialScore.theme.id && (
          <ManageSwByThemeModal
            open={modalState.data === partialScore.theme.id}
            onClose={() => {
              modalHandlers.onClose();
            }}
            themeId={partialScore.theme.id}
            assessmentRequestId={scoreCard.assessmentRequest.id}
          />
        )}
    </>
  );
};

export default React.memo(ReviewGroupingSwitch);
