import {
  IBORolesEnum,
  ICompanyKindEnum,
  IUserStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { useOnMount } from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';
import type { SvgIconComponent } from '@mui/icons-material';

import * as React from 'react';

import { UpdateUserStatusModal } from '@core/components/modals';

import { QUERY_KEYS, useUpdateFOUserStatus } from '@core/queries';

import { UsersUtils } from '@core/utils';
import { getTranslatedUserStatus } from '@core/utils/users';

import { SecurityApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { ITokenAwareFODistributorUser } from '@core/types';

import {
  CheckIcon,
  ClearIcon,
  Dropdown,
  EditIcon,
  IconButton,
  LinkIcon,
  MoreVertIcon,
  SecurityIcon,
  Tooltip,
} from '@shared/components';

import { BOErrorTracking, Constants } from '@shared/utils';

import type {
  IFODistributorUser,
  IUserUpdateStatusInput,
} from '@inteliam/foundation/lib/types';

interface Props {
  distributorUser: IFODistributorUser;
  modalHandlers: {
    onOpenCreate: () => void;
    onOpenUpdate: (data: string) => void;
    onClose: () => void;
  };
}

const getEnableDisableActionIcon = (): {
  [key: string]: SvgIconComponent;
} => ({
  DISABLED: CheckIcon,
  ENABLED: ClearIcon,
});

const ActionsDropdown: React.FCC<Props> = ({
  distributorUser,
  modalHandlers,
}) => {
  const { t, user, notify } = useEssentials();
  const [open, setOpen] = React.useState(false);
  const [canUpdateStatus, setCanUpdateStatus] = React.useState<
    undefined | boolean
  >();
  const impersonate = React.useCallback(
    async (id: string) => {
      try {
        const { data } = await SecurityApi.getImpersonationToken(id);
        window.open(
          `${
            Constants.FRONT_OFFICE_URL
          }/?impersonate=${Helpers.ensureValueAsString(
            Helpers.ensureValueAsString(data)
          )}`,
          '_blank'
        );
      } catch (error) {
        BOErrorTracking.report(error);

        notify({
          message: t(
            'Could not get access rights to use the Connect as feature'
          ),
          type: INotifierEnum.Error,
        });
      }
    },
    [notify, t]
  );
  const copySetupAccountURL = React.useCallback(
    (user: ITokenAwareFODistributorUser) => {
      const setupAccountUrl = `${
        Constants.FRONT_OFFICE_URL
      }/set-up-account?email=${user.contactInfo.email}&token=${
        user.resetPasswordToken || ''
      }`;
      navigator.clipboard
        .writeText(setupAccountUrl)
        .then(
          () => {
            notify({
              message: t('Copied to clipboard !'),
              type: INotifierEnum.Success,
            });
          },
          () => {
            notify({
              message: t('Something went wrong'),
              type: INotifierEnum.Error,
            });
          }
        )
        .catch((error) => {
          BOErrorTracking.report(error);
          notify({
            message: t('Something went wrong'),
            type: INotifierEnum.Error,
          });
        });
    },
    [notify, t]
  );
  const { run, canRun } = useUpdateFOUserStatus({
    userId: distributorUser.id,
    companyId: distributorUser.company.id,
    queryKey: QUERY_KEYS.COMPANY_USERS_LIST(
      ICompanyKindEnum.DISTRIBUTOR,
      distributorUser.company?.id as string
    ),
  });
  const onConfirm = (data: IUserUpdateStatusInput) => {
    run(data);
    setOpen(false);
  };
  useOnMount(() => {
    canRun()
      .then(() => setCanUpdateStatus(true))
      .catch(() => setCanUpdateStatus(false));
  });

  const StatusIcon =
    getEnableDisableActionIcon()[distributorUser.status as string];
  return (
    <>
      <Dropdown.Container>
        <Dropdown.Trigger
          component={Tooltip}
          title={t('Actions')}
          id={`row-actions-${distributorUser.contactInfo.email}`}
        >
          <IconButton
            id='user-actions'
            edge='start'
            color='inherit'
            size='large'
          >
            <MoreVertIcon />
          </IconButton>
        </Dropdown.Trigger>
        <Dropdown.List>
          <Dropdown.Item
            id='edit-user-item'
            onClick={() => modalHandlers.onOpenUpdate(distributorUser.id)}
            icon={<EditIcon />}
          >
            {t('Edit')}
          </Dropdown.Item>
          {UsersUtils.isGranted(IBORolesEnum.ROLE_CONNECT_AS, user) && (
            <Dropdown.Item
              id='impersonate-item'
              onClick={() => impersonate(distributorUser.id)}
              disabled={
                distributorUser.status !== IUserStatusEnum.STATUS_ENABLED
              }
              icon={<SecurityIcon />}
            >
              {t('Connect as')}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            id='setup-account-url-item'
            onClick={() => {
              copySetupAccountURL(
                distributorUser as ITokenAwareFODistributorUser
              );
            }}
            disabled={distributorUser.status !== IUserStatusEnum.STATUS_PENDING}
            icon={<LinkIcon />}
          >
            {t('Get Setup Account URL')}
          </Dropdown.Item>
          {[
            IUserStatusEnum.STATUS_PENDING,
            IUserStatusEnum.STATUS_DRAFT,
          ].includes(distributorUser.status) === false &&
            canUpdateStatus && (
              <Dropdown.Item
                id='toggle-user-status-item'
                onClick={() => setOpen(true)}
                icon={<StatusIcon />}
              >
                {
                  getTranslatedUserStatus(distributorUser.status, t)
                    .translatedAction
                }
              </Dropdown.Item>
            )}
        </Dropdown.List>
      </Dropdown.Container>
      <UpdateUserStatusModal
        open={open}
        onClose={() => setOpen(false)}
        status={distributorUser.status}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default React.memo(ActionsDropdown);
