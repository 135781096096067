import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse, ICurrency } from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/currencies',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const getAvailable = async (): Promise<IAPIResponse<Array<ICurrency>>> => {
  const { data } = await client.instance.get('/get-available');
  return data;
};

export default {
  getAvailable,
};
