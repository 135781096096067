import { IBORolesEnum } from '@inteliam/foundation/lib/enums';

import * as React from 'react';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import type { ICsmEntry } from '@core/types';

import {
  Dropdown,
  IconButton,
  Noop,
  Tooltip,
  MoreVertIcon,
} from '@shared/components';

interface Props {
  csmEntry: ICsmEntry;
}
const ActionCell: React.FCC<Props> = ({ csmEntry }) => {
  const { t, user } = useEssentials();
  if (false === UsersUtils.isGranted(IBORolesEnum.ROLE_CSM, user)) {
    return <Noop />;
  }
  return (
    <Dropdown.Container>
      <Dropdown.Trigger
        id={`row-actions-${csmEntry.manufacturer.name}`}
        component={Tooltip}
        title={t('Actions')}
      >
        <IconButton
          id={`icon-row-actions-${csmEntry.manufacturer.name}`}
          edge='start'
          color='inherit'
          size='large'
        >
          <MoreVertIcon />
        </IconButton>
      </Dropdown.Trigger>
      <Dropdown.List
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        {UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_CSM, user) && (
          <Dropdown.ItemLink
            id='sharing-request-view'
            to={`/csm/sr-view/${csmEntry?.id}`}
          >
            {t('Sharing request view')}
          </Dropdown.ItemLink>
        )}
        <Dropdown.ItemLink
          id='alert-view'
          to={`/csm/alert-view/${csmEntry?.id}`}
        >
          {t('Alert view')}
        </Dropdown.ItemLink>
      </Dropdown.List>
    </Dropdown.Container>
  );
};

export default ActionCell;
