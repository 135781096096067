import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isIOptionScoringRatio } from '@core/guards/option-scoring-ratio';
import type { IOptionScoringRatio } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IAPIResponse, JwtBOUser } from '@inteliam/foundation/lib/types';

type OptionScoringRatiosActions = 'patch';

const baseApi = ApiFactory.generate<
  IOptionScoringRatio,
  JwtBOUser,
  OptionScoringRatiosActions
>({
  baseRoute: '/option-scoring-ratios',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIOptionScoringRatio,
});

const getAll = async (): Promise<IAPIResponse<Array<IOptionScoringRatio>>> => {
  const { data } = await baseApi.client.instance.get('');
  return data;
};

export default {
  ...baseApi.actions,
  getAll,
};
