import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Box, Card, Divider, Grid, Typography } from '@shared/components';

import ScopeItem from './scope-item';

const QuestionScope: React.FCC = () => {
  const { t } = useEssentials();

  return (
    <Card.CContainer styled>
      <Card.Header title={t('Question and option scope')} />
      <Divider />
      <Card.Body>
        <Box p={2}>
          <Typography component='h1' variant='h6'>
            {t('Question')}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <ScopeItem />
        </Grid>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(QuestionScope);
