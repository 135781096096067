import {
  IBORolesEnum,
  IHighlightsStatusEnum,
  IFilterTypeEnum,
  IAnalysisHighlightsKindEnum,
} from '@inteliam/foundation/lib/enums';
import { I18n } from '@inteliam/foundation/lib/utils';

import * as React from 'react';

import { useWorkingPage } from '@core/hooks';

import {
  QUERY_KEYS,
  useAvailableMasterHighlightsCategories,
  useManageAssessmentHighlights,
} from '@core/queries';

import { UsersUtils } from '@core/utils';

import { AnalysisHighlightsApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { CenteredSpinner, Datatable, TooltippedCell } from '@shared/components';

import type { IFilterableColumn } from '@shared/types';

import type {
  DatatableState,
  IMasterAnalysisHighlight,
} from '@inteliam/foundation/lib/types';

const MasterAnalysisHighlights: React.FCC = () => {
  const { t, user } = useEssentials();
  const { assessmentRequest } = useWorkingPage();

  const { formattedCategories } = useAvailableMasterHighlightsCategories();

  const columns = React.useMemo<
    Array<IFilterableColumn<IMasterAnalysisHighlight>>
  >(
    () => [
      {
        field: 'category',
        filter: {
          type: IFilterTypeEnum.List,
          props: {
            options: formattedCategories,
            multiple: false,
          },
        },
        headerName: t('Category'),
        width: 150,
        renderCell: (parameters) => {
          const category = parameters?.row?.category as string;
          return (
            <TooltippedCell title={t(category)}>{t(category)}</TooltippedCell>
          );
        },
      },
      {
        field: 'description',
        filter: {
          type: IFilterTypeEnum.String,
        },
        headerName: t('Text'),
        width: 550,
        renderCell: (parameters) => {
          return (
            <TooltippedCell
              title={I18n.getTranslatedValue(parameters.row.description)}
            >
              {I18n.getTranslatedValue(parameters.row.description)}
            </TooltippedCell>
          );
        },
      },
    ],
    [formattedCategories, t]
  );
  const DEFAULT_CONDITIONS: DatatableState['criteria']['conditions'] = [
    {
      column: 'status',
      propertyPath: 'status',
      value: IHighlightsStatusEnum.STATUS_ACTIVE,
      operator: 'eq',
    },
  ];
  const datatableHandlers = React.useState<DatatableState>({
    page: 1,
    itemsPerPage: 10,
    criteria: {
      operator: 'or',
      conditions: DEFAULT_CONDITIONS,
    },
    selected: [],
    sort: {
      _id: 'desc',
    },
    visibleColumns: columns.map((column) => column.field),
  });
  const { getByAR } = useManageAssessmentHighlights(
    { assessmentRequestId: assessmentRequest.id },
    {
      getByAr: {
        onSuccess: (response) => {
          setter((prev) => ({
            ...prev,
            selected:
              response.data
                ?.filter(
                  (highlight) =>
                    highlight.analysisHighlight.kind ===
                    IAnalysisHighlightsKindEnum.MASTER
                )
                .map(
                  (assessmentHighlight) =>
                    assessmentHighlight.analysisHighlight.id
                ) || [],
          }));
        },
      },
    }
  );
  const [, setter] = datatableHandlers;
  const { toggle } = useManageAssessmentHighlights();

  if (!getByAR.query.isSettled) {
    return <CenteredSpinner />;
  }
  return (
    <Datatable.Container
      queryKey={[
        ...QUERY_KEYS.ANALYSIS_HIGHLIGHTS_LIST(
          IAnalysisHighlightsKindEnum.MASTER
        ),
        assessmentRequest.id,
      ]}
      columns={columns}
      fetcher={(paginator) =>
        AnalysisHighlightsApi.paginate(
          paginator,
          IAnalysisHighlightsKindEnum.MASTER
        )
      }
      stateExternalHandlers={datatableHandlers}
    >
      <div style={{ height: '100%', width: '100%' }}>
        <Datatable.Table
          autoHeight
          checkboxSelection={UsersUtils.isGranted(
            IBORolesEnum.ROLE_JUNIOR_ANALYST,
            user
          )}
          onRowSelectionChange={(rowId) => {
            toggle.run({
              assessmentRequestId: assessmentRequest.id,
              analysisHighlights: { ids: [rowId] },
            });
          }}
        />
      </div>
    </Datatable.Container>
  );
};
export default React.memo(MasterAnalysisHighlights);
