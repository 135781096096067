import { IHighlightsCategoryEnum } from '@inteliam/foundation/lib/enums';
import { Yup } from '@inteliam/foundation/lib/utils';

import { translationFieldSchema } from '@shared/schemas';

export const defaults = Yup.object().shape({
  code: Yup.string().required(),
  description: translationFieldSchema(true),
  status: Yup.string().required(),
  category: Yup.string()
    .required()
    .oneOf(Object.values(IHighlightsCategoryEnum)),
});
