export const convertToNumber = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  decimal?: boolean,
  roundingPrecision?: number
): number => {
  if (decimal) {
    return roundingPrecision
      ? Number(Number(value).toFixed(roundingPrecision))
      : Number(value);
  } else {
    return Number.parseInt(value as string);
  }
};
