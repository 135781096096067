import { ApiFactory } from '@inteliam/foundation/lib/api';
import { QueryString } from '@inteliam/foundation/lib/utils';

import { isIReferenceEvaluation } from '@core/guards';
import type {
  IReferenceEvaluation,
  IEvaluateDocumentPayload,
  IEvaluationProgress,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IPaginationQuery,
  IPaginationResponse,
  Id,
  JwtBOUser,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/reference-evaluations';

const baseReferenceEvaluationsApi = ApiFactory.generate<
  IReferenceEvaluation,
  JwtBOUser
>({
  baseRoute: BASE_PATH,
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isIReferenceEvaluation,
});

const paginateByDocumentEvaluation = async (
  assessmentRequestId: Id,
  documentId: Id,
  paginator: IPaginationQuery
): Promise<IPaginationResponse<IReferenceEvaluation>> => {
  const { data } = await baseReferenceEvaluationsApi.client.instance.get(
    `/${assessmentRequestId}/${documentId}`,
    {
      params: paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  return data;
};

const listByAnswer = async (
  answerId: Id,
  optionId?: Id
): Promise<IAPIResponse<Array<IReferenceEvaluation>>> => {
  const { data } = await baseReferenceEvaluationsApi.client.instance.get(
    optionId
      ? `/answer/${answerId}/option/${optionId}`
      : `/answer/${answerId}/option`
  );
  return data;
};

const evaluate = async ({
  status,
  id,
}: IEvaluateDocumentPayload): Promise<IAPIResponse<IReferenceEvaluation>> => {
  const { data } = await baseReferenceEvaluationsApi.client.instance.patch(
    `/${id}/${status}`
  );
  return data;
};

const getGlobalInsights = async (
  id: string
): Promise<IAPIResponse<IEvaluationProgress>> => {
  const { data } = await baseReferenceEvaluationsApi.client.instance.get(
    `/${id}/insights`
  );
  return data;
};

export default {
  evaluate,
  listByAnswer,
  paginateByDocumentEvaluation,
  getGlobalInsights,
};
