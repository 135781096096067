import * as React from 'react';

import type { IPartialQuestion } from '@inteliam/foundation/lib/types';

export type IQuestionFormContext = {
  readOnly?: boolean;
  defaultValues?: IPartialQuestion;
  openedQuestion?: string;
  setOpenedQuestion: (questionId?: string) => void;
  mode?: 'CREATE' | 'NEW_VERSION' | 'EDIT';
};

export const QuestionFormContext = React.createContext<IQuestionFormContext>({
  readOnly: false,
  defaultValues: undefined,
  setOpenedQuestion: () => {},
});
