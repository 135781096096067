import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import * as React from 'react';

import type { ConfirmOptions } from '@core/contexts';

type Props = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onClose: () => void;
  options: ConfirmOptions;
  customButtons?: (args: {
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactNode;
};
const ConfirmationDialog: React.FCC<Props> = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
  customButtons,
}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
  } = options;

  return (
    <Dialog fullWidth {...dialogProps} open={open} onClose={onClose}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>
              <div
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></div>
            </DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions>
        {customButtons?.({
          onClose: onClose,
          onConfirm: onConfirm,
          onCancel: onCancel,
        }) || (
          <>
            <Button
              {...cancellationButtonProps}
              onClick={onCancel}
              data-cy='cancel-modal'
            >
              {cancellationText}
            </Button>
            <Button
              color='primary'
              {...confirmationButtonProps}
              onClick={onConfirm}
              data-cy='confirm-modal'
            >
              {confirmationText}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
