import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '@inteliam/foundation/lib/utils';

import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  useAvailableCurrencies,
  useFetchDiscountEnums,
  useFetchHasActiveDiscount,
} from '@core/queries';

import { DiscountsUtils } from '@core/utils';

import { IDiscountStatusesEnum, IDiscountTypesEnum } from '@core/enums';

import { useEssentials } from '@core/contexts';
import type { IDiscountForm, IPartialDiscount } from '@core/types';

import {
  Button,
  Card,
  CenteredSpinner,
  Controls,
  ErrorHelpText,
  FormGroup,
  Grid,
  Box,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IIdentifiedObject,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { discountSchema } from '../schema';

interface Props {
  onSubmit: (data: IDiscountForm) => void;
  onClose: () => void;
  defaultValues?: IPartialDiscount;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  onClose,
  defaultValues,
  errors,
  isLoading,
}) => {
  const { t } = useEssentials();
  const { id } = useParams<IIdentifiedObject>();
  const discountEnumsQuery = useFetchDiscountEnums();
  const hasActiveDiscountQuery = useFetchHasActiveDiscount({ id });
  const methods = useForm<IDiscountForm>({
    defaultValues: DiscountsUtils.normalizer.initializeForm(defaultValues),
    resolver: yupResolver(discountSchema),
    shouldUnregister: true,
  });
  const { formattedCurrencies } = useAvailableCurrencies();

  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const watchedType = useWatch({
    control: methods.control,
    name: 'type',
    defaultValue: defaultValues?.type || IDiscountTypesEnum.TYPE_PERCENTAGE,
  }) as IDiscountTypesEnum;

  const watchedStatus = useWatch({
    control: methods.control,
    name: 'status',
  });

  if (!discountEnumsQuery.isSettled || !hasActiveDiscountQuery.isSettled) {
    return <CenteredSpinner />;
  }
  if (!discountEnumsQuery.isSuccess || !hasActiveDiscountQuery.isSuccess) {
    return (
      <div>
        {t(
          discountEnumsQuery.error?.response?.data.message ||
            'Something went wrong'
        )}
      </div>
    );
  }

  return (
    <>
      {discountEnumsQuery.discountEnums && (
        <Controls.Form submitHandler={onSubmit} methods={methods}>
          <Card.CContainer styled>
            <Card.Header title={t('Discount details')} />
            <Card.Body>
              <Grid container spacing={3}>
                <Grid item md={5} xs={12}>
                  <FormGroup>
                    <Controls.FormSelect
                      label={t('Type')}
                      name='type'
                      options={discountEnumsQuery.discountEnums.types}
                      required
                    />
                  </FormGroup>
                </Grid>
                {watchedType === IDiscountTypesEnum.TYPE_AMOUNT && (
                  <>
                    <Grid item md={3} xs={12}>
                      <FormGroup>
                        <Controls.FormInput
                          label={t('Discount')}
                          name='price.value'
                          type='number'
                          required
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormGroup>
                        <Controls.FormSelect
                          label={t('Currency')}
                          name='price.currency'
                          options={formattedCurrencies.filter(
                            (currency) => currency.label === 'Euro'
                          )}
                          required
                        />
                      </FormGroup>
                    </Grid>
                  </>
                )}

                {watchedType === IDiscountTypesEnum.TYPE_PERCENTAGE && (
                  <Grid item md={7} xs={12}>
                    <FormGroup>
                      <Controls.FormInput
                        label={t('Discount')}
                        name='percentage'
                        type='number'
                        required
                      />
                    </FormGroup>
                  </Grid>
                )}

                <Grid item md={5} xs={12}>
                  <FormGroup>
                    <Controls.FormSelect
                      label={t('Status')}
                      name='status'
                      options={discountEnumsQuery.discountEnums.statuses}
                      required
                    />
                    {watchedStatus === IDiscountStatusesEnum.STATUS_ACTIVE &&
                      hasActiveDiscountQuery.data.data.hasActiveDiscount ===
                        true && (
                        <ErrorHelpText>
                          {t(
                            'There is currently an active discount, if you activate this one , the current discount will be deactivated'
                          )}
                        </ErrorHelpText>
                      )}
                  </FormGroup>
                </Grid>
                <Grid item md={7} xs={12}>
                  <FormGroup>
                    <Controls.FormSelect
                      label={t('Apply discount on Subscription')}
                      name='period'
                      options={discountEnumsQuery.discountEnums.periods}
                      required
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Card.Body>
          </Card.CContainer>
          <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
            <Button
              id='close-manufacturer-discount-modal'
              onClick={onClose}
              type='button'
              color='primary'
              disabled={isLoading}
            >
              {t('Cancel')}
            </Button>
            <Button
              id='submit-manufacturer-discount'
              type='submit'
              color='primary'
              isLoading={isLoading}
            >
              {t('Validate')}
            </Button>
          </Box>
        </Controls.Form>
      )}
    </>
  );
};
export default Form;
