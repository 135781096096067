import { yupResolver } from '@hookform/resolvers/yup';
import { Validations } from '@inteliam/foundation/lib/utils';
import { omit } from 'lodash-es';

import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions, EnterpriseBaseInformation } from '@core/components';

import { RegistrationsUtils } from '@core/utils';

import type { IEnterpriseForm } from '@core/types';

import { Controls } from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IAnyEnterprise,
  IPartialEnterprise,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { update as schema } from '../schema';

interface Props {
  onSubmit: (data: IPartialEnterprise) => void;
  defaultValues?: IAnyEnterprise;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
}

const Form: React.FCC<Props> = ({
  onSubmit,
  defaultValues,
  errors,
  isLoading,
}) => {
  const methods = useForm<IEnterpriseForm>({
    defaultValues: {
      ...defaultValues,
      turnover: RegistrationsUtils.hydrateTurnover(defaultValues),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Controls.Form
      submitHandler={(values) => {
        const payload: IEnterpriseForm = {
          ...defaultValues,
          ...values,
          // @ts-expect-error rhf transform null into undefined :( undefined gets ignored when sent to the server
          logo: values.logo ?? null, // eslint-disable-line unicorn/no-null
          // @ts-expect-error rhf transform null into undefined :( undefined gets ignored when sent to the server
          engagementLetter: values.engagementLetter ?? null, // eslint-disable-line unicorn/no-null
        };
        const omittedFields = [];
        if (!payload.configuration.enterpriseAccess) {
          omittedFields.push('users', 'domainNames', 'authorizationFiles');
        }
        if (!payload.level) {
          omittedFields.push('parent');
        }
        onSubmit(
          payload.configuration.enterpriseAccess
            ? payload
            : omit(payload, omittedFields)
        );
      }}
      methods={methods}
    >
      <EnterpriseBaseInformation mode='UPDATE' />
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};
export default memo(Form);
