import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import * as React from 'react';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { AssessmentRequestsApi, DistributorsApi } from '@core/api';
import type { IBODistributor, IMergeDistributor } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  DashboardType,
  IAPIResponse,
  Id,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

type CreateResponse = Awaited<ReturnType<typeof DistributorsApi.create>>;
type CreateArgs = Parameters<typeof DistributorsApi.create>[0];

type ManageDistributor = (parameters?: {
  create?: {
    options: InteliamUseMutationOptions<
      CreateResponse,
      AxiosValidationErrorResponse,
      CreateArgs
    >;
  };
  getOneById?: {
    args: {
      id?: IBODistributor['id'];
    };
    options?: InteliamUseQueryOptions<
      IAPIResponse<IBODistributor>,
      BaseAxiosErrorResponse
    >;
  };
  merge?: {
    options?: InteliamUseMutationOptions<
      void,
      BaseAxiosErrorResponse,
      IMergeDistributor
    >;
  };
  patch?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      IPatchObject
    >;
  };
  remove?: {
    args: {
      dashboard: DashboardType;
      id: Id;
    };
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      BaseAxiosErrorResponse,
      Id
    >;
  };
}) => {
  getOneById: InteliamUseQueryResult<
    IAPIResponse<IBODistributor>,
    BaseAxiosErrorResponse
  >;
  merge: InteliamUseMutationResult<
    void,
    BaseAxiosErrorResponse,
    IMergeDistributor
  >;
  patch: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >;
  remove: InteliamUseMutationResult<
    IAPIResponse,
    BaseAxiosErrorResponse,
    Id
  > & {
    canDelete: () => Promise<IAPIResponse>;
  };
  create: InteliamUseMutationResult<
    CreateResponse,
    AxiosValidationErrorResponse,
    CreateArgs
  >;
};

const useManageDistributors: ManageDistributor = (args) => {
  const createMutation = useMutation<
    CreateResponse,
    AxiosValidationErrorResponse,
    CreateArgs
  >(DistributorsApi.create, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.DISTRIBUTORS_LIST],
    ...args?.create?.options,
  });
  const getOneByIdQuery = useQuery<
    IAPIResponse<IBODistributor>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.DISTRIBUTORS_LIST, args?.getOneById?.args.id],
    () =>
      args?.getOneById?.args.id
        ? DistributorsApi.getOneById(args?.getOneById?.args.id)
        : Promise.reject(),
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      enabled: Boolean(args?.getOneById?.args.id),
      initialData: undefined,
      cacheTime: 1000 * 20,
      refetchOnMount: false,
      ...args?.getOneById?.options,
    }
  );
  const mergeMutation = useMutation<
    void,
    BaseAxiosErrorResponse,
    IMergeDistributor
  >(DistributorsApi.merge, {
    ...args?.merge?.options,
  });

  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(DistributorsApi.patch, {
    ...args?.patch?.options,
  });

  const deleteMutation = useMutation<IAPIResponse, BaseAxiosErrorResponse, Id>(
    AssessmentRequestsApi.remove,
    {
      keysToInvalidateOnSuccess: [
        args?.remove?.args.dashboard
          ? QUERY_KEYS.DASHBOARD[args?.remove?.args.dashboard]
          : [],
      ],
    }
  );

  const canDelete = React.useCallback(() => {
    if (!args?.remove?.args.id) {
      throw new Error('The id is required');
    }

    return AssessmentRequestsApi.remove(args?.remove?.args.id, true);
  }, [args?.remove?.args.id]);

  return {
    getOneById: getOneByIdQuery,
    merge: mergeMutation,
    patch: patchMutation,
    remove: { ...deleteMutation, canDelete },
    create: createMutation,
  };
};
export default useManageDistributors;
