import { FormHelperText, FormHelperTextProps } from '@mui/material';

import * as React from 'react';

import { useEssentials } from '@core/contexts';

const ErrorHelpText: React.FCC<FormHelperTextProps> = (props) => {
  const { theme } = useEssentials();

  return (
    <FormHelperText
      style={{ fontSize: 12, color: theme.palette.error.main }}
      {...props}
    />
  );
};

export default ErrorHelpText;
