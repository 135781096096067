/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  IBOCarbonAssessment,
  ICarbonCalculationParameter,
} from '@core/types';

export function isICarbonCalculationParameter(
  argument: any
): argument is ICarbonCalculationParameter {
  return (
    typeof argument.id === 'string' &&
    typeof argument.value === 'number' &&
    typeof argument.unit === 'string' &&
    typeof argument.comment === 'string' &&
    typeof argument.criteria === 'string' &&
    typeof argument.type === 'string'
  );
}

export function isICarbonAssessment(
  argument: any
): argument is IBOCarbonAssessment {
  return (
    typeof argument.id === 'string' &&
    typeof argument.scoredCompany === 'object' &&
    typeof argument.status === 'string' &&
    typeof argument.createdAt === 'string' &&
    typeof argument.source === 'string'
  );
}
