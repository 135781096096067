import {
  ILanguagesEnum,
  IQuestionKindEnum,
  IQuestionStatusEnum,
  OBLIGATORINESS_OPTIONS,
  PUBLICATION_OPTIONS,
  STATUS_OPTIONS,
} from '@inteliam/foundation/lib/enums';
import { Helpers } from '@inteliam/foundation/lib/utils';
import { isNil, isString } from 'lodash-es';

import type { TFunction } from '@core/contexts';
import type {
  IDropDownChoiceForm,
  IFormattedDistributorEnums,
  IMultipleChoiceOptionForm,
  IQuestionForm,
  IQuestionOptionForm,
  ISingleChoiceOptionForm,
  ITranslatableFieldForm,
  ISingleFreeFormOptionForm,
} from '@core/types';

import type {
  IClassification,
  ICriterionClassification,
  IIdentifiedObject,
  IMultipleChoiceOption,
  IOptionItem,
  IPartialQuestion,
  IQuestion,
  IScope,
  ISiblingsQuery,
  ISingleChoiceOption,
  IThemeClassification,
  ITranslatableField,
  ITranslatableItem,
} from '@inteliam/foundation/lib/types';

import { QuestionI18nHelpers } from './i18n';

const DEFAULT_TRANSLATABLE_FIELD: ITranslatableField = {
  defaultValue: '',
  translations: [],
};

const DEFAULT_TRANSLATABLE_FIELD_FORM: ITranslatableFieldForm = {
  defaultValue: '',
  translations: {},
};
//! WARNING: Index order is very important, Any change in the index order will result in a BREAKING CHANGE
export const getDefaultScopes = (
  qualificationEnums: IFormattedDistributorEnums
): Array<IScope> =>
  qualificationEnums.categories
    // eslint-disable-next-line unicorn/prefer-array-flat-map
    .map<Array<IScope>>((category) => {
      return qualificationEnums.sizes.map((size) => {
        return {
          country: 3,
          size: size.value.toString(),
          category: category.value.toString(),
        };
      });
    })
    .flat();

export const getDefaultQuestionOption = (
  qualificationEnums: IFormattedDistributorEnums
): Omit<IQuestionOptionForm, 'id'> => ({
  label: DEFAULT_TRANSLATABLE_FIELD_FORM,
  helpTextContent: DEFAULT_TRANSLATABLE_FIELD_FORM,
  helpText: false,
  order: 0,
  scopes: getDefaultScopes(qualificationEnums),
  scoringMeta: { maxScore: 0 },
});

export const DEFAULT_OPTION_CHOICE_FORM: IDropDownChoiceForm = {
  label: DEFAULT_TRANSLATABLE_FIELD_FORM,
  id: Helpers.uuid(),
};

export const getDefaultSingleFreeFormOption = (
  qualificationEnums: IFormattedDistributorEnums
): ISingleFreeFormOptionForm => ({
  ...getDefaultQuestionOption(qualificationEnums),
  type: 'text',
  reference: 'no',
  id: Helpers.uuid(),
});

export const getDefaultSingleChoiceOption = (
  qualificationEnums: IFormattedDistributorEnums
): ISingleChoiceOptionForm => ({
  ...getDefaultQuestionOption(qualificationEnums),
  textBoxLabel: DEFAULT_TRANSLATABLE_FIELD_FORM,
  textBox: 'no',
  textBoxType: 'text',
  dropDownLabel: DEFAULT_TRANSLATABLE_FIELD_FORM,
  dropDown: 'no',
  dropDownChoices: [
    { ...DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
    { ...DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
  ],
  dropDownMultiAnswers: false,
  reference: 'no',
  id: Helpers.uuid(),
});
export const getDefaultMultipleChoiceOption = (
  qualificationEnums: IFormattedDistributorEnums
): IMultipleChoiceOptionForm => ({
  ...getDefaultQuestionOption(qualificationEnums),
  textBoxLabel: DEFAULT_TRANSLATABLE_FIELD_FORM,
  textBox: 'no',
  textBoxType: 'text',
  dropDownLabel: DEFAULT_TRANSLATABLE_FIELD_FORM,
  dropDown: 'no',
  dropDownChoices: [
    { ...DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
    { ...DEFAULT_OPTION_CHOICE_FORM, id: Helpers.uuid() },
  ],
  dropDownMultiAnswers: false,
  reference: 'no',
  resetOption: false,
  id: Helpers.uuid(),
});

const getDefaultQuestion = (
  qualificationEnums: IFormattedDistributorEnums
): IQuestionForm => ({
  code: '',
  kind: IQuestionKindEnum.MultipleChoiceField,
  version: 1,
  title: DEFAULT_TRANSLATABLE_FIELD_FORM,
  helpTextContent: DEFAULT_TRANSLATABLE_FIELD_FORM,
  helpText: false,
  required: false,
  status: 'draft',
  theme: { id: '', name: '', code: '' },
  criterion: { id: '', name: '', code: '' },
  scopes: getDefaultScopes(qualificationEnums),
  parentDependency: {
    optionIds: [],
    question: { id: '' },
  },
  childrenDependencies: [],
});

const getDefaultByKind = (qualificationEnums: IFormattedDistributorEnums) => ({
  [IQuestionKindEnum.SingleFreeFormField]: {
    textBoxType: 'text',
    reference: {
      enabled: false,
      required: false,
    },
  },
  [IQuestionKindEnum.SingleChoiceField]: {
    options: [
      { ...getDefaultSingleChoiceOption(qualificationEnums) },
      { ...getDefaultSingleChoiceOption(qualificationEnums) },
    ],
  },
  [IQuestionKindEnum.MultipleChoiceField]: {
    options: [
      { ...getDefaultMultipleChoiceOption(qualificationEnums) },
      { ...getDefaultMultipleChoiceOption(qualificationEnums) },
    ],
  },
});

//TO DO UT
export const convertToTranslatableFieldForm = (
  translatableField?: ITranslatableField | string
): ITranslatableFieldForm => {
  if (translatableField) {
    if (!isString(translatableField)) {
      return {
        defaultValue: translatableField.defaultValue,
        translations: Object.assign(
          {},
          ...translatableField.translations.map(
            (translation: ITranslatableItem) => {
              return { [translation.locale]: translation.content };
            }
          )
        ),
      };
    }
    return {
      defaultValue: '',
      translations: {},
    };
  }
  return DEFAULT_TRANSLATABLE_FIELD_FORM;
};

export const convertToTranslatableField = (
  translatableField?: ITranslatableFieldForm | string
): ITranslatableField | string => {
  if (translatableField) {
    if (!isString(translatableField)) {
      const translations: Array<ITranslatableItem> = Object.keys(
        translatableField.translations
      ).map(function (key) {
        return {
          locale: key as ILanguagesEnum,
          content: translatableField.translations[
            key as ILanguagesEnum
          ] as string,
          status: 'validated',
        };
      });

      return {
        defaultValue: translatableField.defaultValue,
        translations: translations,
      };
    }
    return translatableField;
  }
  return DEFAULT_TRANSLATABLE_FIELD;
};

export const convertToQuestionOptionForm = (
  options: Array<IMultipleChoiceOption | ISingleChoiceOption>
): Array<IMultipleChoiceOptionForm | ISingleChoiceOptionForm> => {
  return options.map((option: IMultipleChoiceOption | ISingleChoiceOption) => {
    return {
      ...option,
      label: convertToTranslatableFieldForm(option.label),
      helpTextContent: convertToTranslatableFieldForm(option.helpTextContent),
      textBoxLabel: convertToTranslatableFieldForm(option.textBoxLabel),
      dropDownLabel: convertToTranslatableFieldForm(option.dropDownLabel),
      dropDownChoices: option.dropDownChoices.map((dropDownChoice) => {
        return {
          ...dropDownChoice,
          label: convertToTranslatableFieldForm(dropDownChoice.label),
        };
      }),
    };
  }) as Array<IMultipleChoiceOptionForm | ISingleChoiceOptionForm>;
};

export const convertToQuestionOption = (
  options: Array<IMultipleChoiceOptionForm | ISingleChoiceOptionForm>
): Array<IMultipleChoiceOption | ISingleChoiceOption> => {
  return options.map(
    (option: IMultipleChoiceOptionForm | ISingleChoiceOptionForm) => {
      return {
        ...option,
        label: convertToTranslatableField(option.label),
        helpTextContent: convertToTranslatableField(option.helpTextContent),
        textBoxLabel: convertToTranslatableField(option.textBoxLabel),
        dropDownLabel: convertToTranslatableField(option.dropDownLabel),
        dropDownChoices: option.dropDownChoices.map((dropDownChoice) => {
          return {
            ...dropDownChoice,
            label: convertToTranslatableField(dropDownChoice.label),
          };
        }),
        scoringMeta: {
          maxScore: Number.isNaN(option.scoringMeta.maxScore)
            ? 0
            : option.scoringMeta.maxScore,
        },
      };
    }
  ) as Array<IMultipleChoiceOption | ISingleChoiceOption>;
};

export const initializeQuestion = (
  qualificationEnums: IFormattedDistributorEnums,
  defaultValues?: IPartialQuestion
): IQuestionForm => {
  const defaultQuestion = getDefaultQuestion(qualificationEnums);
  return {
    ...defaultQuestion,
    ...(defaultValues?.kind
      ? getDefaultByKind(qualificationEnums)[defaultValues.kind]
      : {}),
    ...defaultValues,
    helpTextContent: convertToTranslatableFieldForm(
      defaultValues?.helpTextContent
    ),
    title: convertToTranslatableFieldForm(defaultValues?.title),
    options: defaultValues?.options
      ? convertToQuestionOptionForm(
          defaultValues?.options as Array<
            ISingleChoiceOption | IMultipleChoiceOption
          >
        )
      : [
          getDefaultMultipleChoiceOption(qualificationEnums),
          getDefaultMultipleChoiceOption(qualificationEnums),
        ],
    parentDependency:
      defaultValues?.parentDependency || defaultQuestion.parentDependency,
  };
};

export const buildSiblingQuery = (
  theme: string,
  criteria?: string,
  exclude?: string // if we add another param, make this an object
): ISiblingsQuery => {
  return {
    theme: theme,
    criteria: criteria || '',
    exclude: exclude,
  };
};

export const resolveSiblingQuery = (
  watchedClassification: [
    IIdentifiedObject | undefined,
    IIdentifiedObject | undefined
  ],
  classification: IClassification[],
  themeId?: string
): ISiblingsQuery | undefined => {
  if (!themeId) {
    return undefined;
  }
  const [, watchedCriteria] = watchedClassification;
  const criteriaId = watchedCriteria?.id;
  let query: ISiblingsQuery | undefined = undefined;
  const selectedTheme = classification.find((item) => item.id === themeId);
  if (selectedTheme?.isLeaf === true) {
    query = buildSiblingQuery(themeId);
  } else if (selectedTheme?.isLeaf === false) {
    const selectedCriteria = selectedTheme?.children.find(
      (item) => item.id === criteriaId
    );
    if (selectedCriteria?.isLeaf === true) {
      query = buildSiblingQuery(themeId, criteriaId);
    }
  }
  return query;
};

export const resolveSiblingQueryById = (
  classification: IClassification[],
  id: string
): ISiblingsQuery | undefined => {
  const theme = classification.find((it) => it.id === id);
  if (theme) {
    return buildSiblingQuery(theme.id);
  }
  for (const theme of classification) {
    const criteria = theme.children.find((it) => it.id === id);
    if (criteria) {
      return buildSiblingQuery(theme.id, criteria.id);
    }
  }
  return undefined;
};

export const isDependable = (question: IPartialQuestion): boolean => {
  return question.kind
    ? [
        IQuestionKindEnum.MultipleChoiceField,
        IQuestionKindEnum.SingleChoiceField,
      ].includes(question.kind)
    : false;
};

export const normalizer = {
  formToPayload: (formData: IQuestionForm): IPartialQuestion => {
    return {
      ...formData,
      options: formData?.options
        ? convertToQuestionOption(
            formData.options as Array<
              IMultipleChoiceOptionForm | ISingleChoiceOptionForm
            >
          )
        : [],
      title: convertToTranslatableField(formData.title),
      helpTextContent: convertToTranslatableField(formData?.helpTextContent),
      theme: formData.theme?.id
        ? (formData.theme as IThemeClassification)
        : undefined,
      criterion: formData.criterion?.id
        ? (formData.criterion as ICriterionClassification)
        : undefined,
      parentDependency: formData.parentDependency?.question?.id
        ? formData.parentDependency
        : undefined,
    };
  },
};

export const getObligatorinessOptions = (t: TFunction): Array<IOptionItem> => {
  return OBLIGATORINESS_OPTIONS.map((item) => ({
    value: item,
    label: t(QuestionI18nHelpers.getObligatorinessTranslation(item)),
  }));
};
export const getStatusesOptions = (t: TFunction): Array<IOptionItem> => {
  return STATUS_OPTIONS.map((item) => ({
    value: item,
    label: t(item),
  }));
};
export const getPublicationTranslation = (t: TFunction): Array<IOptionItem> => {
  return PUBLICATION_OPTIONS.map((item) => ({
    value: item,
    label: t(QuestionI18nHelpers.getPublicationTranslation(item)),
  }));
};

export function preSubmitHandler(
  initialQuestion: IQuestion, // state before update
  updatedQuestion: Partial<IQuestion>, // state after update
  t: TFunction,
  activeQuestion?: IQuestion // active question in DB
): {
  messages: Array<string>;
} {
  const messages = [];
  const willActivateQuestion =
    updatedQuestion.status === IQuestionStatusEnum.active &&
    initialQuestion.status !== updatedQuestion.status;
  const willDeactivateQuestion =
    updatedQuestion.status !== IQuestionStatusEnum.active &&
    initialQuestion.status === IQuestionStatusEnum.active;
  const hasDependency =
    !isNil(updatedQuestion.parentDependency) ||
    Boolean(updatedQuestion.childDependenciesCount) ||
    Boolean(updatedQuestion.childrenDependencies?.length);
  const willReplaceExistingVersion =
    willActivateQuestion && activeQuestion?.version !== updatedQuestion.version;
  if (willActivateQuestion) {
    if (hasDependency) {
      messages.push(
        t(
          'This question will be activated and <strong>all its child dependencies will be activated AS WELL</strong>. '
        )
      );
    }
    if (willReplaceExistingVersion) {
      // we are reactivating a question with multiple versions
      messages.push(
        t(
          'Old version "{{fromVersion}}" will not be available for All new questionnaire initialization (Distributor first questionnaire connection), this new version "{{toVersion}}" will be used for All new questionnaire initialization. The new version "{{toVersion}}" will be <strong>activated</strong> and old one "{{fromVersion}}" will be <strong>deactivated</strong> ?',
          {
            fromVersion: activeQuestion?.version,
            toVersion: initialQuestion.version,
          }
        )
      );
    }
    messages.push(t('Are you sure you want to activate this question ?'));
  } else if (willDeactivateQuestion) {
    // we are deactivating the question
    if (hasDependency) {
      messages.push(
        t(
          'Please note that this question has parents and/or children. This change might impact newly created questionnaire (During Distributor first questionnaire connection). This question will no longer be available for all new questionnaire initialization (Distributor first questionnaire connection)'
        )
      );
    }

    messages.push(
      t(
        'This question will no longer be available for all new questionnaire initialization (Distributor first questionnaire connection). Do you confirm deactivation?'
      )
    );
  } else {
    // the user didn't change the status
    // no specific message
    messages.push(t('Are you sure you want to save your changes ?'));
  }
  return { messages };
}
