import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';

import { Button, Card, Box, CircularProgress } from '@shared/components';

interface Props {
  isLoading: boolean;
  onCancel?: () => void;
  cancelText?: string;
  submit?: boolean;
  submitText?: string;
}

const FormActions: React.FCC<Props> = ({
  isLoading,
  onCancel,
  cancelText = 'reset',
  submit = true,
  submitText,
}) => {
  const { t } = useEssentials();

  return (
    <Card.CContainer styled>
      <Card.Body>
        <Box display='flex' justifyContent='flex-end' p={1} gap={10}>
          {onCancel && (
            <Button
              id='reset'
              color='secondary'
              variant='contained'
              type='submit'
              disabled={isLoading}
              onClick={onCancel}
            >
              {t(cancelText)}{' '}
              {isLoading && <CircularProgress color='inherit' size={16} />}
            </Button>
          )}
          {submit && (
            <Button
              id='submit'
              color='primary'
              variant='contained'
              type='submit'
              disabled={isLoading}
            >
              {submitText || t('Submit')}{' '}
              {isLoading && <CircularProgress color='inherit' size={16} />}
            </Button>
          )}
        </Box>
      </Card.Body>
    </Card.CContainer>
  );
};

export default memo(FormActions);
