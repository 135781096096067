import { IUserStatusEnum } from '@inteliam/foundation/lib/enums';

export const USER_STATUSES: Array<{
  value: IUserStatusEnum;
  label: string;
}> = [
  {
    value: IUserStatusEnum.STATUS_DISABLED,
    label: 'disabled',
  },
  {
    value: IUserStatusEnum.STATUS_ENABLED,
    label: 'enabled',
  },
  {
    value: IUserStatusEnum.STATUS_PENDING,
    label: 'pending',
  },
  {
    value: IUserStatusEnum.STATUS_LOCKED,
    label: 'locked',
  },
  {
    value: IUserStatusEnum.STATUS_DRAFT,
    label: 'draft',
  },
];
