import * as React from 'react';

import {
  AnalysisHighlightsOrdering,
  CustomAnalysisHighlights,
  MasterAnalysisHighlights,
} from '@core/components/assessment-requests';

import { useWorkingPage } from '@core/hooks';

import { useEssentials } from '@core/contexts';

import {
  Accordion,
  Card,
  Divider,
  ExpandMoreIcon,
  Grid,
  Typography,
} from '@shared/components';

const AnalysisHighlights: React.FCC = () => {
  const { t } = useEssentials();
  const { assessmentRequest } = useWorkingPage();

  return (
    <Accordion.Container defaultExpanded={false}>
      <Accordion.Summary
        id={'analyst-highlight-accordion'}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{t('Analysis highlights')}</Typography>
      </Accordion.Summary>
      <Accordion.Details>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card.CContainer>
              <Card.Header title={t('Custom Analysis highlights')} />
              <Divider />
              <Card.Body>
                <CustomAnalysisHighlights />
              </Card.Body>
              <Divider />
            </Card.CContainer>
          </Grid>
          <Grid item xs={12}>
            <Card.CContainer>
              <Card.Header title={t('Analysis highlights')} />
              <Divider />
              <Card.Body>
                <MasterAnalysisHighlights />
              </Card.Body>
              <Divider />
            </Card.CContainer>
          </Grid>
          <Grid item xs={12}>
            <Card.CContainer>
              <Card.Header title={t('Analysis highlights Ordering')} />
              <Divider />
              <Card.Body>
                {/* TODO props drilling */}
                <AnalysisHighlightsOrdering
                  assessmentRequestId={assessmentRequest.id}
                />
              </Card.Body>
              <Divider />
            </Card.CContainer>
          </Grid>
        </Grid>
      </Accordion.Details>
    </Accordion.Container>
  );
};
export default React.memo(AnalysisHighlights);
