import {
  IARStatusEnum,
  IARTransitionEnum,
} from '@inteliam/foundation/lib/enums';
import { assertIsTyped } from '@inteliam/foundation/lib/guards';
import {
  DEFAULT_NO_RETRY_QUERY_OPTIONS,
  useQueryClient,
} from '@inteliam/foundation/lib/hooks';

import { useCallback, useMemo } from 'react';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { AssessmentRequestsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import { isPartialAR } from '@core/guards';

import type {
  BaseAxiosErrorResponse,
  DashboardType,
  IAPIResponse,
  IAR,
  IARTransitionInput,
  ITransitionMutation,
  ITransitionResponse,
  ITransitionRunner,
} from '@inteliam/foundation/lib/types';

import { useOpenTransitionDialog } from './ar-transition-provider';

type PreEventHandler = (props: {
  from: IARStatusEnum;
  confirmMessage?: string;
  to?: IARStatusEnum;
}) => Promise<ITransitionResponse>;

type PostEventHandler = (props: {
  from: IARStatusEnum;
  to?: IARStatusEnum;
}) => void;

type IMachine = {
  pre: {
    [key in IARTransitionEnum]: PreEventHandler;
  };
  post: {
    [key in IARTransitionEnum]: PostEventHandler;
  };
};

interface Options {
  assessmentRequest?: IAR;
  dashboard: DashboardType;
}
const useAssessmentMachine = ({
  dashboard,
  assessmentRequest,
}: Options): { machine: IMachine; transition: ITransitionMutation } => {
  const { confirm, t, notify } = useEssentials();
  const queryClient = useQueryClient();

  const openTransitionDialog = useOpenTransitionDialog();
  const defaultPreTransitionHandler: PreEventHandler = useCallback(
    async ({ confirmMessage }) => {
      return confirm({
        description: t(
          confirmMessage ||
            'Are you sure you want to continue with this action?'
        ),
      }).then(() => {
        return { status: 'CONFIRMED' };
      });
    },
    [confirm, t]
  );

  const defaultPostTransitionHandler: PostEventHandler =
    useCallback((): void => {
      notify({
        message: t('Assessment request status successfully updated'),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries(QUERY_KEYS.DASHBOARD[dashboard])
        .catch(() => {});
    }, [notify, t, queryClient, dashboard]);

  const assessmentRequestMachine: IMachine = useMemo(
    () => ({
      pre: {
        [IARTransitionEnum.QUEUE]: defaultPreTransitionHandler,
        [IARTransitionEnum.QUALIFY]: defaultPreTransitionHandler,
        [IARTransitionEnum.CHECK_UNICITY_AND_SCOPE]: async ({ from }) => {
          if ([IARStatusEnum.READY_FOR_SURVEY].includes(from)) {
            return confirm({
              description: t(
                'this operation will roll back the Merge operation: restore a draft company and undo changes on the regular company, are you sure to proceed'
              ),
            }).then(() => {
              return { status: 'CONFIRMED' };
            });
          }
          return confirm({
            description: t(
              'Are you sure you want to continue with this action?'
            ),
          }).then(() => {
            return { status: 'CONFIRMED' };
          });
        },
        [IARTransitionEnum.ASSIGN_SURVEY]: defaultPreTransitionHandler,
        [IARTransitionEnum.INITIATE_SURVEY]: defaultPreTransitionHandler,
        [IARTransitionEnum.ANSWER_SURVEY]: async ({ from }) => {
          if (from === IARStatusEnum.IN_REVIEW) {
            assertIsTyped(assessmentRequest, isPartialAR);
            return confirm({
              description: t(
                'You are about to reopen the questionnaire. Are you sure you want to continue?'
              ),
            }).then(() => {
              return openTransitionDialog({
                transition: IARTransitionEnum.ANSWER_SURVEY,
                assessmentRequest,
              });
            });
          }

          return defaultPreTransitionHandler({ from });
        },
        [IARTransitionEnum.REUSE]: defaultPreTransitionHandler,
        [IARTransitionEnum.REVIEW]: async ({ from }) => {
          if (from === IARStatusEnum.IN_DOC_ANALYSIS) {
            assertIsTyped(assessmentRequest, isPartialAR);

            return openTransitionDialog({
              transition: IARTransitionEnum.REVIEW,
              assessmentRequest,
            });
          }

          return defaultPreTransitionHandler({ from });
        },
        [IARTransitionEnum.ANALYZE]: async ({ from }) => {
          if (from === IARStatusEnum.IN_SCORING) {
            assertIsTyped(assessmentRequest, isPartialAR);
            return openTransitionDialog({
              transition: IARTransitionEnum.ANALYZE,
              assessmentRequest,
            });
          }

          return defaultPreTransitionHandler({ from });
        },
        [IARTransitionEnum.SCORE]: async ({ from }) => {
          if (from === IARStatusEnum.RTA) {
            assertIsTyped(assessmentRequest, isPartialAR);
            return openTransitionDialog({
              transition: IARTransitionEnum.SCORE,
              assessmentRequest,
            });
          }

          return defaultPreTransitionHandler({ from });
        },
        [IARTransitionEnum.RTA]: async ({ from }) => {
          // Rollback from published
          if (from === IARStatusEnum.PUBLISHED) {
            assertIsTyped(assessmentRequest, isPartialAR);
            return openTransitionDialog({
              transition: IARTransitionEnum.RTA,
              assessmentRequest,
            });
          }

          return defaultPreTransitionHandler({ from });
        },
        [IARTransitionEnum.PUBLISH]: defaultPreTransitionHandler,
        [IARTransitionEnum.CANCEL]: defaultPreTransitionHandler,
      },
      post: {
        [IARTransitionEnum.QUEUE]: defaultPostTransitionHandler,
        [IARTransitionEnum.QUALIFY]: defaultPostTransitionHandler,
        [IARTransitionEnum.CHECK_UNICITY_AND_SCOPE]:
          defaultPostTransitionHandler,
        [IARTransitionEnum.ASSIGN_SURVEY]: defaultPostTransitionHandler,
        [IARTransitionEnum.INITIATE_SURVEY]: defaultPostTransitionHandler,
        [IARTransitionEnum.ANSWER_SURVEY]: defaultPostTransitionHandler,
        [IARTransitionEnum.REUSE]: defaultPostTransitionHandler,
        [IARTransitionEnum.REVIEW]: defaultPostTransitionHandler,
        [IARTransitionEnum.ANALYZE]: defaultPostTransitionHandler,
        [IARTransitionEnum.SCORE]: defaultPostTransitionHandler,
        [IARTransitionEnum.RTA]: defaultPostTransitionHandler,
        [IARTransitionEnum.PUBLISH]: defaultPostTransitionHandler,
        [IARTransitionEnum.CANCEL]: defaultPostTransitionHandler,
      },
    }),
    [
      defaultPreTransitionHandler,
      defaultPostTransitionHandler,
      confirm,
      t,
      openTransitionDialog,
      assessmentRequest,
    ]
  );

  const transitionMutation = useMutation<
    IAPIResponse,
    BaseAxiosErrorResponse,
    IARTransitionInput
  >(AssessmentRequestsApi.transition, {
    ...DEFAULT_NO_RETRY_QUERY_OPTIONS,
    onSuccess: (_, variables) => {
      const from = variables.meta?.from as IARStatusEnum;
      assessmentRequestMachine.post[variables.action]({ from });
    },
  });

  const run = useCallback(
    ({
      transition,
      body = {},
      confirm = true,
    }: ITransitionRunner): Promise<ITransitionResponse> => {
      if (!assessmentRequest) {
        throw new Error('Assessment request not provided');
      }

      const runRegularMutation = (): Promise<ITransitionResponse> =>
        transitionMutation
          .mutateAsync({
            meta: {
              from: assessmentRequest.status,
            },
            action: transition,
            body,
            id: assessmentRequest.id,
          })
          .then((apiResponse) => {
            return {
              status: 'SUCCESS',
              apiResponse,
            };
          });

      return confirm
        ? assessmentRequestMachine.pre[transition]({
            from: assessmentRequest.status,
          }).then((response) => {
            if (response.status === 'CONFIRMED') {
              return runRegularMutation();
            }
            return response;
          })
        : runRegularMutation();
    },
    [assessmentRequest, assessmentRequestMachine.pre, transitionMutation]
  );

  const canRun = useCallback(
    ({ transition }: ITransitionRunner) => {
      if (!assessmentRequest) {
        throw new Error('Assessment request not provied');
      }
      return AssessmentRequestsApi.transition(
        {
          body: {},
          id: assessmentRequest.id,
          action: transition,
        },
        true
      );
    },
    [assessmentRequest]
  );

  return {
    machine: assessmentRequestMachine,
    transition: {
      mutation: transitionMutation as ITransitionMutation['mutation'],
      run,
      canRun,
    },
  };
};

export default useAssessmentMachine;
