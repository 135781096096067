import { yupResolver } from '@hookform/resolvers/yup';

import * as React from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Button, Controls, RouterLink, Grid } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IForgetPasswordPayload } from '@inteliam/foundation/lib/types';

import { forgotPasswordSchema as schema } from './schema';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPasswordForm: React.FC<{
  onSubmit: (data: IForgetPasswordPayload) => void;
}> = ({ onSubmit }) => {
  const classes = useStyles();
  const methods = useForm<IForgetPasswordPayload>({
    resolver: yupResolver(schema),
  });
  const { t } = useEssentials();

  return (
    <Controls.Form
      methods={methods}
      submitHandler={onSubmit}
      className={classes.form}
    >
      <Controls.FormInput
        id='email'
        label={t('Email Address or Username')}
        name='email'
        fullWidth
        autoFocus
        required
      />
      <Button
        type='submit'
        id='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={classes.submit}
      >
        {t('Ask for a new password')}
      </Button>
      <Grid container justifyContent='flex-end'>
        <Grid item>
          <RouterLink to='/login' id='login'>
            {t('Remember your password?')}{' '}
          </RouterLink>
        </Grid>
      </Grid>
    </Controls.Form>
  );
};

export default ForgotPasswordForm;
