import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { PricingApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  IOptionItem,
  IPricingCategory,
} from '@inteliam/foundation/lib/types';

const useFetchPricingCategories = (): {
  formattedPricingCategories: IOptionItem[];
  isLoading: boolean;
} => {
  const { t } = useEssentials();

  const { data, isLoading } = useQuery<
    IAPIResponse<Array<IPricingCategory>>,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.PRICING_CATEGORIES],
    () => PricingApi.getPricingCategories(),
    {
      cacheTime: Number.POSITIVE_INFINITY,
    }
  );
  const pricingCategories = Helpers.ensureValueAsArray(data?.data);
  return {
    formattedPricingCategories: pricingCategories.map((pricingCategory) => ({
      label: t(pricingCategory.id),
      value: pricingCategory.id,
    })),
    isLoading: isLoading,
  };
};

export default useFetchPricingCategories;
