import {
  useQueryClient,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useQuery, useMutation, QUERY_KEYS } from '@core/queries';

import { AssessmentRequestReviewsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type {
  IAnyAssessmentReviewPayload,
  IAssessmentReviewReorderPayload,
} from '@core/types';

import { BOErrorTracking } from '@shared/utils';

import type { QueryOptimisticUpdater } from '@shared/types';

import type {
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  IAnyAssessmentReview,
  IAPIResponse,
  Id,
  IPaginationResponse,
  IPatchObject,
  IQueryManagementArgs,
  IQueryManagementResult,
  PatchBody,
} from '@inteliam/foundation/lib/types';

type PaginateQueryArgs = {
  paginateByEvaluation?: InteliamUseQueryOptions<
    IPaginationResponse<IAnyAssessmentReview>,
    BaseAxiosErrorResponse
  >;
  paginateByTheme?: InteliamUseQueryOptions<
    IPaginationResponse<IAnyAssessmentReview>,
    BaseAxiosErrorResponse
  >;
};
type ReorderMutationArgs = {
  reorder?: InteliamUseMutationOptions<
    IAPIResponse,
    AxiosValidationErrorResponse,
    Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>
  >;
};

type PaginateQueryResult = {
  paginateByEvaluation: {
    query: InteliamUseQueryResult<
      IPaginationResponse<IAnyAssessmentReview>,
      BaseAxiosErrorResponse
    >;
  };
  paginateByTheme: {
    query: InteliamUseQueryResult<
      IPaginationResponse<IAnyAssessmentReview>,
      BaseAxiosErrorResponse
    >;
    updater: QueryOptimisticUpdater<Array<IAnyAssessmentReview>>;
  };
};
type ReorderMutationResult = {
  reorder: {
    run: (
      payload: Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>
    ) => void;
    mutation: InteliamUseMutationResult<
      IAPIResponse,
      AxiosValidationErrorResponse,
      Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>
    >;
  };
};
const useManageArReviews = (
  args: {
    assessmentRequestId: string;
    answerEvaluationId?: string;
    themeId?: string;
  },
  options: IQueryManagementArgs<
    IAnyAssessmentReview,
    IAnyAssessmentReviewPayload,
    IAnyAssessmentReviewPayload,
    'create' | 'patch' | 'remove'
  > &
    PaginateQueryArgs &
    ReorderMutationArgs = {}
): IQueryManagementResult<
  IAnyAssessmentReview,
  IAnyAssessmentReviewPayload,
  IAnyAssessmentReviewPayload,
  'create' | 'patch' | 'remove'
> &
  PaginateQueryResult &
  ReorderMutationResult => {
  const { t, notify, confirm } = useEssentials();
  const queryClient = useQueryClient();

  const createMutation = useMutation<
    IAPIResponse<IAnyAssessmentReview>,
    AxiosValidationErrorResponse,
    IAnyAssessmentReviewPayload
  >(
    (payload) =>
      AssessmentRequestReviewsApi.create({
        assessmentRequestId: args.assessmentRequestId,
        assessmentReview: payload,
      }),
    {
      onSuccess: () => {
        paginateByEvaluationQuery.refetch().catch((error) => {
          BOErrorTracking.report(error);
        });
        notify({
          message: t('S/IA added successfully'),
          type: INotifierEnum.Success,
        });
      },
      ...options.create,
    }
  );

  const reorderMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>
  >(
    (payload) =>
      AssessmentRequestReviewsApi.reorder({
        assessmentRequestId: args.assessmentRequestId,
        assessmentReviewId: payload.assessmentReviewId,
        order: payload.order,
      }),
    {
      onSuccess: () => {
        paginateByEvaluationQuery.refetch().catch(() => {});
        notify({
          message: t('S/IA reordered successfully'),
          type: INotifierEnum.Success,
        });
      },
      ...options.reorder,
    }
  );
  const patchMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    IPatchObject
  >(
    (payload) =>
      AssessmentRequestReviewsApi.patch({
        assessmentRequestId: args.assessmentRequestId,
        patchObject: payload,
      }),
    {
      // eslint-disable-next-line sonarjs/no-identical-functions
      keysToInvalidateOnSuccess: [
        [...QUERY_KEYS.AR_REVIEWS_LIST(args.assessmentRequestId), args.themeId],
      ],
      onSuccess: () => {
        paginateByEvaluationQuery.refetch().catch(() => {});
        notify({
          message: t('Second part updated successfully'),
          type: INotifierEnum.Success,
        });
      },
      ...options.patch,
    }
  );

  const removeMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse, //BaseAxiosErrorResponse,
    Id //IAnyAssessmentReview['id']
  >(
    (id) =>
      AssessmentRequestReviewsApi.remove({
        assessmentRequestId: args.assessmentRequestId,
        assessmentReviewId: id as IAnyAssessmentReview['id'],
      }),
    {
      keysToInvalidateOnSuccess: [
        [...QUERY_KEYS.AR_REVIEWS_LIST(args.assessmentRequestId), args.themeId],
      ],
      onSuccess: () => {
        notify({
          message: t('Operation successfully completed'),
          type: INotifierEnum.Success,
        });
        paginateByEvaluationQuery.refetch().catch(() => {});
      },

      // ...options.remove,
    }
  );

  const paginateByEvaluationQuery = useQuery<
    IPaginationResponse<IAnyAssessmentReview>,
    BaseAxiosErrorResponse
  >(
    [
      ...QUERY_KEYS.AR_REVIEWS_LIST(args.assessmentRequestId),
      args.answerEvaluationId,
    ].filter(Boolean),
    () =>
      args.answerEvaluationId
        ? AssessmentRequestReviewsApi.paginateByEvaluation({
            assessmentRequestId: args.assessmentRequestId,
            answerEvaluationId: args.answerEvaluationId,
            paginator: {
              itemsPerPage: -1, // -1 causes the datatable to break
              sort: { id: 'desc' },
              page: 1,
              criteria: {
                conditions: [],
                operator: 'and',
              },
            },
          })
        : Promise.reject(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 1000 * 10,
      ...options.paginateByEvaluation,
    }
  );
  const paginateByThemeQueryKey = [
    ...QUERY_KEYS.AR_REVIEWS_LIST(args.assessmentRequestId),
    args.themeId,
  ];

  const paginateByTheme = useQuery<
    IPaginationResponse<IAnyAssessmentReview>,
    BaseAxiosErrorResponse
  >(
    paginateByThemeQueryKey,
    () =>
      args.themeId
        ? AssessmentRequestReviewsApi.paginateByTheme({
            assessmentRequestId: args.assessmentRequestId,
            themeId: args.themeId,
            paginator: {
              itemsPerPage: -1, // -1 causes the datatable to break
              sort: { rank: 'asc' },
              page: 1,
              criteria: {
                conditions: [],
                operator: 'and',
              },
            },
          })
        : Promise.reject(),
    {
      enabled: Boolean(args.assessmentRequestId && args.themeId),
      ...options.paginateByTheme,
    }
  );

  const runCreate = useCallback(
    (formData: IAnyAssessmentReviewPayload) => {
      createMutation.mutate(formData);
    },
    [createMutation]
  );

  const runPatch = useCallback(
    (body: PatchBody, id: IAnyAssessmentReview['id']) => {
      patchMutation.mutate({
        id,
        body,
      });
    },
    [patchMutation]
  );
  const runReorder = useCallback(
    (payload: Omit<IAssessmentReviewReorderPayload, 'assessmentRequestId'>) => {
      reorderMutation.mutate(payload);
    },
    [reorderMutation]
  );

  const runRemove = useCallback(
    (id: IAnyAssessmentReview['id']) => {
      confirm({
        description: t('Are you sure ?'),
        content: t('Are you sure you want to delete this item ?'),
      })
        .then(() => {
          removeMutation.mutate(id);
        })
        .catch(() => {});
    },
    [confirm, removeMutation, t]
  );

  const paginateByThemeQueryUpdater: QueryOptimisticUpdater<
    Array<IAnyAssessmentReview>
  > = (callback) => {
    queryClient.setQueryData<
      IAPIResponse<Array<IAnyAssessmentReview>> | undefined
    >(paginateByThemeQueryKey, callback);
  };

  return {
    create: {
      run: runCreate,
      mutation: createMutation,
    },
    patch: {
      run: runPatch,
      mutation: patchMutation,
    },
    reorder: {
      run: runReorder,
      mutation: reorderMutation,
    },
    remove: {
      run: runRemove,
      mutation: removeMutation,
    },
    paginateByEvaluation: {
      query: {
        ...paginateByEvaluationQuery,
      },
    },
    paginateByTheme: {
      query: {
        ...paginateByTheme,
      },
      updater: paginateByThemeQueryUpdater,
    },
  };
};
export default useManageArReviews;
