import { PrivateApi } from '@inteliam/foundation/lib/utils';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  IAPIResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const client = new PrivateApi(
  {
    baseRoute: '/criteria',
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const patch = async ({ id, body }: IPatchObject): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch(`/${id}`, body);
  return data;
};

export default {
  patch,
};
