import { Yup } from '@inteliam/foundation/lib/utils';

export default Yup.object()
  .shape({
    from: Yup.string()
      .required('This field requires your attention')
      .nullable(),
    to: Yup.string().required('This field requires your attention').nullable(),
  })
  .nullable();
