import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';
import { Helpers } from '@inteliam/foundation/lib/utils';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { LanguagesApi } from '@core/api';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ILanguage,
} from '@inteliam/foundation/lib/types';

const useAllLanguages = ({
  options,
}: {
  options?: InteliamUseQueryOptions<
    IAPIResponse<Array<ILanguage>>,
    BaseAxiosErrorResponse
  >;
} = {}): InteliamUseQueryResult<
  IAPIResponse<Array<ILanguage>>,
  BaseAxiosErrorResponse
> & {
  languages: ILanguage[];
} => {
  const query = useQuery<
    IAPIResponse<Array<ILanguage>>,
    BaseAxiosErrorResponse
  >([...QUERY_KEYS.LANGUAGES_LIST], () => LanguagesApi.fetchLanguages(), {
    ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
    initialData: undefined,
    cacheTime: 1000 * 20,
    ...options,
  });
  const languages = Helpers.ensureValueAsArray(query.data?.data);

  return {
    ...query,
    languages,
  };
};

export default useAllLanguages;
