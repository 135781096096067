import './wdyr';

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { AppProviders } from '@core/contexts';
import Router from '@core/routers';
import '@core/styles/index.scss';

// eslint-disable-next-line unicorn/prevent-abbreviations
import loadDevTools from './dev-tools/load';

const strict = false;
let Container = React.Fragment;
if (strict) {
  Container = React.StrictMode;
}

const container = document.getElementById('root');
if (!container) throw new Error('container not found!');

const root = ReactDOM.createRoot(container);

loadDevTools(() => {
  root.render(
    <Container>
      <AppProviders>
        <Router />
      </AppProviders>
    </Container>
  );
});
