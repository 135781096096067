import {
  AssessmentRequestTag,
  IARStatusEnum,
  IARStatusRankEnum,
  IARTransitionEnum,
  ICampaignTypeEnum,
  IDistributorStatusEnum,
} from '@inteliam/foundation/lib/enums';
import { DateFormatter, Helpers } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import * as React from 'react';

import {
  AuthorizeSurveySubmissionModal,
  CancelARModal,
  ExtendSurveyDeadlineModal,
  InitiateSurveyModal,
  ManageCampaignModal,
  SubscriptionLogModal,
  UpdateARDeadlinesModal,
} from '@core/components/modals';

import { ARTransitionProvider, useAssessmentMachine } from '@core/hooks';

import {
  QUERY_KEYS,
  useFetchCampaignTypeByType,
  useManageAssessmentRequest,
  useManageDistributors,
  useSpecificTransition,
} from '@core/queries';

import { useDashboard, useEssentials } from '@core/contexts';
import { AssessmentRequestVoter } from '@core/security';
import type { ISubscriptionLogDistributor } from '@core/types';

import {
  AccessTimeIcon,
  CircularProgress,
  Dropdown,
  FormatListNumberedIcon,
  Grid,
  IconButton,
  MoreVertIcon,
  PowerSettingsNewIcon,
  RouterLink,
  SosIcon,
  Tooltip,
  TooltippedCell,
  WhatshotIcon,
} from '@shared/components';

import type { DashboardType, IAR } from '@inteliam/foundation/lib/types';

const TAG_ICON_STYLE = {
  fontSize: '0.85rem',
  display: 'block',
};

const LEAD_ICON_STYLE = { ...TAG_ICON_STYLE, color: '#2196f3' };
const HELP_NEEDED_ICON_STYLE = { ...TAG_ICON_STYLE, color: '#f44336' };
const QUESTIONNAIRE_ICON_STYLE = { ...TAG_ICON_STYLE, color: '#6ebc6e' };
const STANDBY_ICON_STYLE = { ...TAG_ICON_STYLE, color: '#aeaeae' };
const APPROACHING_ICON_STYLE = { ...TAG_ICON_STYLE, color: '#ff9800' };

interface Props {
  assessmentRequest: IAR;
}

interface CompanyActivePageLinkProps {
  assessmentRequest: IAR;
  dashboard: DashboardType;
}

type OpenedModalType =
  | 'CANCEL_AR'
  | 'UPDATE_DEADLINES'
  | 'INVITE_TO_SURVEY'
  | 'MANAGE_CAMPAIGN'
  | 'EXTEND_SURVEY_DEADLINE'
  | 'AUTHORIZE_SURVEY_SUBMISSION'
  | 'SUBSCRIPTION_DETAILS'
  | undefined;

type WorkableStatus =
  | IARStatusEnum.IN_REVIEW
  | IARStatusEnum.IN_DOC_ANALYSIS
  | IARStatusEnum.IN_SCORING
  | IARStatusEnum.RTA;

type RedirectionDefinition = {
  [key in IDistributorStatusEnum]: (assessmentRequest: IAR) => string;
};

const redirectionLookup: RedirectionDefinition = {
  [IDistributorStatusEnum.NEW]: (assessmentRequest) =>
    `/distributors/${assessmentRequest.scoredCompany?.id}`,
  [IDistributorStatusEnum.DRAFT]: (assessmentRequest) =>
    `/assessment-requests/create?assessmentRequestId=${assessmentRequest.id}`,
  [IDistributorStatusEnum.ARCHIVED]: () => `/500`,
  [IDistributorStatusEnum.MERGED]: () => `/500`,
};

const WORKING_PAGES_REDIRECTS: {
  [key in WorkableStatus]: (assessmentRequest: IAR) => string;
} = {
  [IARStatusEnum.IN_REVIEW]: (assessmentRequest) =>
    `/assessment-requests/${assessmentRequest.id}/review`,
  [IARStatusEnum.IN_DOC_ANALYSIS]: (assessmentRequest) =>
    `/assessment-requests/${assessmentRequest.id}/doc-analysis`,
  [IARStatusEnum.IN_SCORING]: (assessmentRequest) =>
    assessmentRequest.scoringVersion === 'V2'
      ? `/assessment-requests/${assessmentRequest.id}/scoring-v2`
      : `/assessment-requests/${assessmentRequest.id}/scoring`,
  [IARStatusEnum.RTA]: (assessmentRequest) =>
    assessmentRequest.scoringVersion === 'V2'
      ? `/assessment-requests/${assessmentRequest.id}/validation-v2`
      : `/assessment-requests/${assessmentRequest.id}/validation`,
};
// MAYBE-TODO: remove complexity if possible
// eslint-disable-next-line sonarjs/cognitive-complexity
const CompanyCell: React.FCC<Props> = ({ assessmentRequest }) => {
  const { user, t, confirm } = useEssentials();
  const [modal, setModal] = React.useState<OpenedModalType>(undefined);
  const onClose = React.useCallback(() => setModal(undefined), []);
  const { dashboard, checkReadOnly } = useDashboard();
  const id = assessmentRequest.id;

  const { remove } = useManageDistributors({
    remove: { args: { dashboard, id } },
  });

  const manageAssessments = useManageAssessmentRequest({
    reinviteToSurvey: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
    tag: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
  });

  const { transition } = useAssessmentMachine({
    assessmentRequest,
    dashboard,
  });

  const canCancel = React.useCallback(() => {
    return transition.canRun({ transition: IARTransitionEnum.CANCEL });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { canRun: canInitiateSurvey } = useSpecificTransition({
    assessmentRequest,
    dashboard,
    transition: IARTransitionEnum.INITIATE_SURVEY,
  });

  const onOpenInviteToSurvey = () => setModal('INVITE_TO_SURVEY');
  const onOpenDeadline = () => setModal('UPDATE_DEADLINES');
  const onOpenCancel = () => setModal('CANCEL_AR');
  const onOpenManageCampaign = () => setModal('MANAGE_CAMPAIGN');
  const onOpenDeadlineExtension = () => setModal('EXTEND_SURVEY_DEADLINE');
  const onOpenSubscriptionDetailsModal = () => setModal('SUBSCRIPTION_DETAILS');
  const onOpenAuthorizeSurveySubmission = () =>
    setModal('AUTHORIZE_SURVEY_SUBMISSION');
  const campaignTypeQuery = useFetchCampaignTypeByType({
    type: ICampaignTypeEnum.STANDARD,
  });
  const handleBeforeOpenCancel = () => {
    confirm({
      title: t('Are you sure you want to cancel this assessment ?'),
      description: t(
        "Since this assessment request was created for an existing company, all edits on the original Distributor's fields that occurred when creating this assessment request will be stored"
      ),
    })
      .then(onOpenCancel)
      .catch(() => {});
  };

  const handleReinviteToSurvey = () => {
    confirm({
      description: t(
        'Are you sure you want to reinvite the distributor to the questionnaire?'
      ),
    })
      .then(() => {
        manageAssessments.reinviteToSurvey.mutate(id);
      })
      .catch(() => {});
  };

  const handleBeforeOpenInviteToSurvey = () => {
    confirm({
      description: t(
        'You did not assign this AR to a campaign. Do you want to move forward?'
      ),
    })
      .then(onOpenInviteToSurvey)
      .catch(() => {});
  };

  const readOnly = checkReadOnly(assessmentRequest, user);
  const authorizationDate =
    assessmentRequest?.questionnaireSubmissionAuthorization?.authorizedAt;
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
    >
      {!readOnly && (
        <Dropdown.Container>
          <Dropdown.Trigger
            id={`company-row-actions-${assessmentRequest.businessId}`}
            component={Tooltip}
            title={t('Actions')}
          >
            <span>
              <IconButton
                id={`company-row-actions-${assessmentRequest.businessId}`}
                color='inherit'
                size='large'
              >
                <MoreVertIcon />
              </IconButton>
            </span>
          </Dropdown.Trigger>
          <Dropdown.List
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            PaperProps={{
              style: {
                maxHeight: 500,
              },
            }}
          >
            {assessmentRequest.currentStatusMeta.rank >
              IARStatusRankEnum.ANSWERING_SURVEY &&
              !assessmentRequest.isFinalStatus && (
                <Dropdown.ItemLink
                  navTarget='_blank'
                  navRel='noopener noreferrer'
                  id={'company-cell-work-on-ar'}
                  to={WORKING_PAGES_REDIRECTS[
                    assessmentRequest.status as WorkableStatus
                  ](assessmentRequest)}
                >
                  {t('Work On...')}
                </Dropdown.ItemLink>
              )}
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canManageDeadline(user) && (
                <Dropdown.Item
                  onClick={onOpenDeadline}
                  id={'company-cell-manage-deadline'}
                >
                  {t('Manage Deadlines')}
                </Dropdown.Item>
              )}
            {AssessmentRequestVoter.canManageCampaign(user) && (
              <Dropdown.Item
                onClick={onOpenManageCampaign}
                id={'company-cell-manage-campaign'}
              >
                {t('Manage Campaign')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canCancel(user) && (
                <Dropdown.Item
                  id={'company-cell-cancel-ar'}
                  actionChecker={canCancel}
                  onClick={
                    assessmentRequest.scoredCompany.wasMerged
                      ? handleBeforeOpenCancel
                      : onOpenCancel
                  }
                >
                  {t('Cancel Assessment Request')}
                </Dropdown.Item>
              )}
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canExtendDeadline(
                user,
                assessmentRequest
              ) && (
                <Dropdown.Item
                  onClick={onOpenDeadlineExtension}
                  id={'company-cell-deadline-extension'}
                  disabled={assessmentRequest.extensionHistoryCount >= 2}
                >
                  {t(
                    assessmentRequest.extensionHistoryCount === 0
                      ? 'First Deadline Extension'
                      : 'Second Deadline Extension'
                  )}
                </Dropdown.Item>
              )}
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canAuthorizeSurveySubmission(
                user,
                assessmentRequest
              ) && (
                <TooltippedCell
                  disabled={
                    !assessmentRequest?.questionnaireSubmissionAuthorization
                      ?.authorized
                  }
                  title={t(
                    'Authorized submission by {{userFullName}} on {{authorizationDate}}',
                    {
                      userFullName: Helpers.ensureValueAsString(
                        assessmentRequest?.questionnaireSubmissionAuthorization
                          ?.authorizedBy?.contactInfo.fullName
                      ),
                      authorizationDate: authorizationDate
                        ? DateFormatter.formatTimestamp(
                            new Date(authorizationDate),
                            DateFormatter.FORMATS.fr_FR.SHORT
                          )
                        : '',
                    }
                  )}
                >
                  <Dropdown.Item
                    onClick={onOpenAuthorizeSurveySubmission}
                    id='company-cell-authorize-survey-submission'
                    disabled={
                      assessmentRequest.currentStatusMeta.rank >
                      IARStatusRankEnum.ANSWERING_SURVEY
                    }
                  >
                    {t('Authorize Submission')}
                  </Dropdown.Item>
                </TooltippedCell>
              )}
            <Dropdown.Item
              onClick={() => {
                console.log();
                confirm({
                  description: t(
                    assessmentRequest.scoredCompany.status ===
                      IDistributorStatusEnum.DRAFT
                      ? 'Are you sure that you want to delete this <strong>draft</strong> distributor?'
                      : 'Are you sure that you want to delete this distributor?'
                  ),
                })
                  .then(() => {
                    remove.mutate(id);
                  })
                  .catch(() => {});
              }}
              actionChecker={remove.canDelete}
              id={'company-cell-delete-distributor'}
            >
              {t('Delete Distributor')}
            </Dropdown.Item>
            <Dropdown.Item
              id={'company-cell-initiate-survey'}
              onClick={
                assessmentRequest.campaign
                  ? onOpenInviteToSurvey
                  : handleBeforeOpenInviteToSurvey
              }
              actionChecker={canInitiateSurvey}
            >
              {t('Invite to Questionnaire')}
            </Dropdown.Item>
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canReInvite(user, assessmentRequest) && (
                <Dropdown.Item
                  id={'company-cell-reinvite-to-survey'}
                  onClick={handleReinviteToSurvey}
                >
                  {t('Reinvite to Questionnaire')}
                </Dropdown.Item>
              )}
            {!assessmentRequest.isFinalStatus &&
              AssessmentRequestVoter.canAccessToScoreFile(user) && (
                <Dropdown.Item
                  id={'company-cell-access-to-score-file'}
                  onClick={() => manageAssessments.accessToScoreFile.mutate(id)}
                  disabled={
                    manageAssessments.accessToScoreFile.isLoading ||
                    assessmentRequest.currentStatusMeta.rank <
                      IARStatusRankEnum.ANSWERING_SURVEY
                  }
                >
                  {manageAssessments.accessToScoreFile.isLoading && (
                    <CircularProgress color='inherit' size={16} />
                  )}
                  {t('Access Score File')}
                </Dropdown.Item>
              )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                onClick={onOpenSubscriptionDetailsModal}
                id={'company-cell-deadline-extension'}
              >
                {t('Subscription Details')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={assessmentRequest.tag === AssessmentRequestTag.LEAD}
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    body: { tag: AssessmentRequestTag.LEAD },
                  });
                }}
                id={'tag-as-lead'}
              >
                {t('Tag as Lead')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={
                  assessmentRequest.tag === AssessmentRequestTag.APPROACHING
                }
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    body: { tag: AssessmentRequestTag.APPROACHING },
                  });
                }}
                id={'tag-as-approaching'}
              >
                {t('Tag as Approaching')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={assessmentRequest.tag === AssessmentRequestTag.STBY}
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    body: { tag: AssessmentRequestTag.STBY },
                  });
                }}
                id={'tag-as-stby'}
              >
                {t('Tag as STB')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={
                  assessmentRequest.tag === AssessmentRequestTag.HELP_NEEDED
                }
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    body: { tag: AssessmentRequestTag.HELP_NEEDED },
                  });
                }}
                id={'tag-as-help-needed'}
              >
                {t('Tag as Help Needed')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={
                  assessmentRequest.tag === AssessmentRequestTag.QUESTIONNAIRE
                }
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    body: { tag: AssessmentRequestTag.QUESTIONNAIRE },
                  });
                }}
                id={'tag-as-help-questionnaire'}
              >
                {t('Tag as Questionnaire')}
              </Dropdown.Item>
            )}
            {!assessmentRequest.isFinalStatus && dashboard === 'ENGAGEMENT' && (
              <Dropdown.Item
                disabled={isNil(assessmentRequest.tag)}
                onClick={() => {
                  manageAssessments.tag.mutate({
                    id,
                    // eslint-disable-next-line unicorn/no-null
                    body: { tag: null },
                  });
                }}
                id={'remove-tags'}
              >
                {t('Remove Tag')}
              </Dropdown.Item>
            )}
            {dashboard === 'ENGAGEMENT' && (
              <Dropdown.ItemLink
                id={'manage-company-favorites'}
                to={`/distributors/${assessmentRequest.scoredCompany.id}/favorites`}
              >
                {t('Manage Favorites')}
              </Dropdown.ItemLink>
            )}
          </Dropdown.List>
        </Dropdown.Container>
      )}
      {modal === 'AUTHORIZE_SURVEY_SUBMISSION' && (
        <AuthorizeSurveySubmissionModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          open
        />
      )}
      {modal === 'EXTEND_SURVEY_DEADLINE' && campaignTypeQuery.data?.data && (
        <ExtendSurveyDeadlineModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          campaignType={
            assessmentRequest.campaign?.type || campaignTypeQuery.data.data
          }
          open
        />
      )}
      {modal === 'UPDATE_DEADLINES' && (
        <UpdateARDeadlinesModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          open
        />
      )}
      {modal === 'CANCEL_AR' && (
        <CancelARModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          open
        />
      )}
      {modal === 'MANAGE_CAMPAIGN' && (
        <ManageCampaignModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          open
        />
      )}
      {modal === 'INVITE_TO_SURVEY' && (
        <InitiateSurveyModal
          assessmentRequest={assessmentRequest}
          onClose={onClose}
          open
        />
      )}
      {modal === 'SUBSCRIPTION_DETAILS' && (
        <SubscriptionLogModal
          onClose={onClose}
          distributor={
            assessmentRequest.scoredCompany as ISubscriptionLogDistributor
          }
        />
      )}
      <CompanyActivePageLink
        assessmentRequest={assessmentRequest}
        dashboard={dashboard}
      />
    </Grid>
  );
};

const CompanyActivePageLink: React.FCC<CompanyActivePageLinkProps> = ({
  assessmentRequest,
  dashboard,
}) => {
  const { user, t } = useEssentials();
  return (
    <span
      style={{
        overflowX: 'hidden',
        width: '80%',
        justifyContent: 'start',
        display: 'flex',
      }}
    >
      <TooltippedCell title={assessmentRequest.scoredCompany?.name}>
        {AssessmentRequestVoter.canAccessPage(user, assessmentRequest) ? (
          <RouterLink
            id={`distributor-link-${assessmentRequest.businessId}`}
            to={redirectionLookup[
              // cast accepted => checked on canAccessPage
              assessmentRequest.scoredCompany.status as IDistributorStatusEnum
            ](assessmentRequest)}
            target='_blank'
            rel='noreferrer'
          >
            <div style={{ width: '190px', overflowX: 'hidden' }}>
              {assessmentRequest.scoredCompany?.name}
            </div>
          </RouterLink>
        ) : (
          <div style={{ width: '190px', overflowX: 'hidden' }}>
            {assessmentRequest.scoredCompany?.name}
          </div>
        )}
      </TooltippedCell>
      {dashboard === 'ENGAGEMENT' &&
        assessmentRequest.tag === AssessmentRequestTag.LEAD && (
          <WhatshotIcon sx={LEAD_ICON_STYLE} titleAccess={t('Lead')} />
        )}
      {dashboard === 'ENGAGEMENT' &&
        assessmentRequest.tag === AssessmentRequestTag.HELP_NEEDED && (
          <SosIcon sx={HELP_NEEDED_ICON_STYLE} titleAccess={t('Help Needed')} />
        )}
      {dashboard === 'ENGAGEMENT' &&
        assessmentRequest.tag === AssessmentRequestTag.QUESTIONNAIRE && (
          <FormatListNumberedIcon
            sx={QUESTIONNAIRE_ICON_STYLE}
            titleAccess={t('Questionnaire')}
          />
        )}
      {dashboard === 'ENGAGEMENT' &&
        assessmentRequest.tag === AssessmentRequestTag.STBY && (
          <PowerSettingsNewIcon
            sx={STANDBY_ICON_STYLE}
            titleAccess={t('Standby')}
          />
        )}
      {dashboard === 'ENGAGEMENT' &&
        assessmentRequest.tag === AssessmentRequestTag.APPROACHING && (
          <AccessTimeIcon
            sx={APPROACHING_ICON_STYLE}
            titleAccess={t('Approaching')}
          />
        )}
    </span>
  );
};

const EnhancedCompanyCell: React.FCC<Props> = (props) => (
  <ARTransitionProvider>
    <CompanyCell {...props} />
  </ARTransitionProvider>
);
export default EnhancedCompanyCell;
