import { useQueryClient } from '@inteliam/foundation/lib/hooks';

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { SustainabilityCardForm } from '@core/components/enterprise';

import {
  QUERY_KEYS,
  useManageEnterpriseSustainabilityCards,
  useQuery,
} from '@core/queries';

import { EnterpriseSustainabilityCardsApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';
import type { ISustainabilityCard } from '@core/types';

import { Alert } from '@shared/components';

import type {
  BaseAxiosErrorResponse,
  IIdentifiedObject,
  IPaginationResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../..';

interface Props {
  onClose: () => void;
}

const CreateEnterpriseSustainabilityCardModal: React.FCC<Props> = ({
  onClose,
}) => {
  const { notify, t } = useEssentials();
  const queryClient = useQueryClient();
  const { id } = useParams<IIdentifiedObject>();

  const { create } = useManageEnterpriseSustainabilityCards({
    onSuccess: () => {
      notify({
        message: t(
          'The Sustainability card has been successfully uploaded. It is now available for all distributors'
        ),
        type: INotifierEnum.Success,
      });
      queryClient
        .invalidateQueries([
          ...QUERY_KEYS.MANUFACTURER_SUSTAINABILITY_CARDS_LIST(id),
        ])
        .catch(() => {});
      onClose();
    },
  });

  const query = useQuery<
    IPaginationResponse<ISustainabilityCard> | undefined,
    BaseAxiosErrorResponse
  >([...QUERY_KEYS.MANUFACTURER_SUSTAINABILITY_CARDS_LIST(id)], () => {
    return EnterpriseSustainabilityCardsApi.getSustainabilityCards(id);
  });

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={true}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <BaseDialog.Title
        id='modals.enterprise_sustainability_card.title'
        onClose={onClose}
      >
        {t('Add a new sustainability card')}
        <Alert severity='info'>
          {t(
            'Please choose the file name carefully. It will be displayed to the end users.'
          )}
        </Alert>
      </BaseDialog.Title>
      <BaseDialog.Content>
        <SustainabilityCardForm
          onSubmit={(data) => {
            create.run(id, data);
          }}
          isUpdating={false}
          alreadyDeclaredYears={query.data?.data.map((item) => item.year) || []}
          onClose={onClose}
          isLoading={create.mutation.isLoading}
        />
      </BaseDialog.Content>
    </BaseDialog.Dialog>
  );
};

export default memo(CreateEnterpriseSustainabilityCardModal);
