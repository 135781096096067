import type {
  InteliamUseMutationOptions,
  InteliamUseMutationResult,
} from '@inteliam/foundation/lib/hooks';

import { useMutation, QUERY_KEYS } from '@core/queries';

import { FavoritesApi } from '@core/api';
import type { IChangeScope } from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAPIResponse,
  IAR,
} from '@inteliam/foundation/lib/types';

type ManageFavorites = (parameters?: {
  assignBDsToDistributor?: {
    options?: InteliamUseMutationOptions<
      IAPIResponse,
      AxiosValidationErrorResponse,
      { id: IAR['id']; changeScope: IChangeScope }
    >;
  };
}) => {
  assignBDsToDistributor: InteliamUseMutationResult<
    IAPIResponse,
    AxiosValidationErrorResponse,
    { id: IAR['id']; changeScope: IChangeScope }
  >;
};

const useManageFavorites: ManageFavorites = (args) => {
  const assignBDsToDistributorMutation = useMutation<
    IAPIResponse,
    AxiosValidationErrorResponse,
    { id: IAR['id']; changeScope: IChangeScope }
  >(FavoritesApi.assignBDsToDistributor, {
    keysToInvalidateOnSuccess: [QUERY_KEYS.FAVORITES_LIST],
    ...args?.assignBDsToDistributor?.options,
  });

  return {
    assignBDsToDistributor: assignBDsToDistributorMutation,
  };
};

export default useManageFavorites;
