import { yupResolver } from '@hookform/resolvers/yup';
import { IHighlightsStatusEnum } from '@inteliam/foundation/lib/enums';
import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { I18n, Validations } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';

import { FormActions } from '@core/components';

import {
  useAvailableMasterHighlightsCategories,
  useLanguagesByScope,
} from '@core/queries';

import { HighlightsUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';
import { isITranslatableField } from '@core/guards';
import { AnalysisHighlightsVoter } from '@core/security';

import {
  Controls,
  FormGroup,
  Grid,
  TranslationFieldInput,
} from '@shared/components';

import { ValidationUtils } from '@shared/utils';

import type {
  IMasterAnalysisHighlight,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import * as schemas from './schema';

interface Props {
  defaultValues?: Partial<IMasterAnalysisHighlight>;
  onSubmit: (data: Partial<IMasterAnalysisHighlight>) => void;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  readOnly?: boolean;
  mode: 'CREATE' | 'UPDATE';
}

export const HIGHLIGHTS_STATUSES: Array<{
  label: string;
  value: IHighlightsStatusEnum;
}> = [
  {
    label: 'Active',
    value: IHighlightsStatusEnum.STATUS_ACTIVE,
  },
  {
    label: 'Inactive',
    value: IHighlightsStatusEnum.STATUS_INACTIVE,
  },
  {
    label: 'Draft',
    value: IHighlightsStatusEnum.STATUS_DRAFT,
  },
];
const MasterHighlightForm: React.FCC<Props> = ({
  defaultValues,
  onSubmit,
  isLoading,
  readOnly,
  mode = 'CREATE',
  errors,
}) => {
  const { t, user } = useEssentials();
  const { formattedLanguages } = useLanguagesByScope({
    scope: 'QuestionnaireAdministration',
  });
  const { formattedCategories } = useAvailableMasterHighlightsCategories();
  const defaultLanguage = formattedLanguages.filter(
    (item) => item.value === I18n.defaultLocale
  );
  const methods = useForm<Partial<IMasterAnalysisHighlight>>({
    defaultValues: HighlightsUtils.normalizer.initializeForm(defaultValues),
    resolver: yupResolver<typeof schemas.defaults>(schemas.defaults),
  });

  const isAllowedStatus = AnalysisHighlightsVoter.canUpdateStatus(user);
  const isAllowedCategory = AnalysisHighlightsVoter.canUpdateCategory(user);

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  return (
    <Controls.Form
      autoComplete='off'
      noValidate
      methods={methods}
      submitHandler={(formData) =>
        onSubmit(HighlightsUtils.normalizer.formDataToPayload(formData))
      }
    >
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <Controls.FormInput
              label={t('Code')}
              name='code'
              required
              readOnly={readOnly}
            />
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={6}>
          <FormGroup>
            <Controls.FormSelect
              label={t('Category')}
              name='category'
              options={formattedCategories}
              required
              readOnly={!isAllowedCategory || readOnly}
            />
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={6}>
          <FormGroup>
            <Controls.FormSelect
              label={t('Status')}
              name='status'
              options={
                mode === 'CREATE'
                  ? HIGHLIGHTS_STATUSES.filter(
                      (status) =>
                        status.value !== IHighlightsStatusEnum.STATUS_INACTIVE
                    )
                  : HIGHLIGHTS_STATUSES
              }
              required
              readOnly={!isAllowedStatus || readOnly}
            />
          </FormGroup>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormGroup>
            <Controls.FormSelect
              label={t('English')}
              name='locale.default'
              options={defaultLanguage}
              required
              disabled
            />
          </FormGroup>
        </Grid>
        <Grid item md={8} xs={12}>
          <FormGroup>
            <Controls.FormInput
              name='description.defaultValue'
              label={t('Text {{index}}')}
              required
              readOnly={readOnly}
            />
          </FormGroup>
        </Grid>
        <Grid item md={12} xs={12}>
          <TranslationFieldInput
            readOnly={readOnly}
            name='description'
            defaultValue={
              isITranslatableField(defaultValues?.description)
                ? defaultValues?.description
                : undefined
            }
          />
        </Grid>
      </Grid>
      <FormActions isLoading={isLoading} />
    </Controls.Form>
  );
};

export default memo(MasterHighlightForm);
