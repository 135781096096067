import { useOnUpdate } from '@inteliam/foundation/lib/hooks';
import { isNil } from 'lodash-es';

import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { Controls } from '@shared/components';

const WEIGHT_PRESET = [1, 2, 3, 4];

type Props = {
  defaultWeight?: number;
  onChange?: (weight: number) => void;
  readOnly?: boolean;
  id?: string;
};
export const FormCompanyWeightSelect: React.FCC<Props> = ({
  defaultWeight,
  onChange,
  id,
  readOnly,
}) => {
  const { t } = useEssentials();
  const methods = useFormContext();
  useOnUpdate(() => {
    methods.setValue('weight', isNil(defaultWeight) ? '' : defaultWeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWeight]);

  return (
    <>
      <Controls.FormSelect
        id={id}
        label={t('Weight')}
        name={'weight'}
        options={WEIGHT_PRESET.map((w) => ({
          value: w,
          label: w.toString(),
        }))}
        defaultValue={defaultWeight}
        onChange={(event) => {
          onChange?.(Number(event.target.value));
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        readOnly={readOnly}
        required
      />
      <input
        type='checkbox'
        hidden
        checked
        {...methods.register('validateWeight')}
      />
    </>
  );
};

export default React.memo(FormCompanyWeightSelect);
