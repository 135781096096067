import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import { RichTextEditor, ErrorHelpText, FormGroup } from '@shared/components';

interface Props {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  readOnly?: boolean;
}

const FormRichTextEditor: React.FCC<Props> = (props) => {
  const { t } = useEssentials();
  const { name, defaultValue, value, onChange, readOnly = false } = props;
  const methods = useFormContext();
  const errorMessage = get(
    methods.formState.errors,
    `${name}.message`
  ) as unknown as string;
  if (value !== undefined && onChange !== undefined) {
    // is controlled
    return (
      <FormGroup>
        <RichTextEditor
          {...{ readOnly, name }}
          value={value}
          onChange={(value) => onChange(value)}
        />
        {errorMessage && <ErrorHelpText>{t(errorMessage, {})}</ErrorHelpText>}
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      <Controller
        defaultValue={defaultValue || ''}
        control={methods.control}
        name={name}
        render={({ field }) => (
          <RichTextEditor
            {...{ readOnly, name }}
            value={field.value}
            onChange={(value) => field.onChange(value)}
          />
        )}
      />
      {errorMessage && <ErrorHelpText>{t(errorMessage, {})}</ErrorHelpText>}
    </FormGroup>
  );
};

export default memo(FormRichTextEditor);
