import { Yup } from '@inteliam/foundation/lib/utils';

export default Yup.object()
  .shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
    // kind: Yup.string().required(),
  })

  .nullable();
