import { yupResolver } from '@hookform/resolvers/yup';
import { ICampaignTypeEnum } from '@inteliam/foundation/lib/enums';
import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  useOnUpdate,
} from '@inteliam/foundation/lib/hooks';
import { Transformers, Validations } from '@inteliam/foundation/lib/utils';

import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  QUERY_KEYS,
  useFetchCampaignTypes,
  useManageEnterprises,
  useQuery,
} from '@core/queries';

import { CampaignsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type { ICampaignFormData } from '@core/types';

import { Button, Controls, FormGroup, Grid } from '@shared/components';

import { BOErrorTracking, makeStyles, ValidationUtils } from '@shared/utils';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
  ICampaignType,
  RecursivePartial,
  ValidationErrorResponse,
} from '@inteliam/foundation/lib/types';

import { BaseDialog } from '../modals';
import { create as schema } from './schema';

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 'auto',
    marginTop: theme.spacing(5),
  },
}));

const INITIAL_VALUE: RecursivePartial<ICampaignFormData> = {
  name: '',
  owner: {
    id: '',
  },
  open: true,
  type: {
    id: '',
  },
};
interface Props {
  onSubmit: (data: ICampaignFormData) => void;
  errors?: ValidationErrorResponse;
  isLoading: boolean;
  onClose: () => void;
  open: boolean;
  title: string;
  defaultValues?: ICampaignFormData & { id?: string };
}

const Form: React.FCC<Props> = ({
  onClose,
  open,
  onSubmit,
  defaultValues,
  isLoading,
  errors,
  title,
}) => {
  const { t } = useEssentials();
  const classes = useStyles();
  const { paginate } = useManageEnterprises({
    paginate: {
      args: {
        itemsPerPage: -1,
        sort: { id: 'desc' },
        page: 1,
        criteria: {
          conditions: [],
          operator: 'or',
        },
      },
    },
  });
  const { campaignTypes, isSuccess } = useFetchCampaignTypes();

  const methods = useForm<ICampaignFormData>({
    defaultValues: { ...INITIAL_VALUE, ...defaultValues },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isSuccess && campaignTypes.length > 0) {
      // TODO this is to be modified when we support other campaign types
      const standardCampaignId = campaignTypes.find(
        (campaignType: ICampaignType) =>
          campaignType.type === ICampaignTypeEnum.STANDARD
      )?.id;
      if (standardCampaignId) {
        methods.setValue('type.id', standardCampaignId);
      } else {
        BOErrorTracking.report(new Error('Standard campaign type not found'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignTypes, isSuccess]);

  useOnUpdate(() => {
    if (errors) {
      Validations.setFormErrors({
        setError: methods.setError,
        errors: ValidationUtils.formatValidationErrors(errors),
      });
    }
  }, [errors, methods.setError]);

  const canUpdateCheck = useQuery<
    IAPIResponse | undefined,
    BaseAxiosErrorResponse
  >(
    [...QUERY_KEYS.CAN_UPDATE_CAMPAIGN_OWNER, defaultValues?.id],
    () => {
      if (!defaultValues?.id) {
        return Promise.resolve({ data: {}, errors: [], meta: {} });
      }
      return CampaignsApi.canUpdateOwner(defaultValues.id);
    },
    { ...DEFAULT_ONE_TIME_QUERY_OPTIONS }
  );

  return (
    <BaseDialog.Dialog
      keepMounted={false}
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth={true}
    >
      <Controls.Form
        submitHandler={(values) => {
          onSubmit({ ...defaultValues, ...values });
        }}
        methods={methods}
      >
        <BaseDialog.Title id='modals.title' onClose={onClose}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <span>
              <strong>{title}</strong>
            </span>
          </Grid>
        </BaseDialog.Title>
        <BaseDialog.Content dividers>
          <Grid item xs={6} className={classes.grid}>
            <FormGroup>
              <Controls.FormInput
                id='name'
                name='name'
                label={t('Campaign name')}
                rows={1}
                required
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6} className={classes.grid}>
            <FormGroup>
              {paginate.isSuccess && (
                <Controls.FormSelect
                  fullWidth
                  id='owner.id'
                  name='owner.id'
                  label={t('Enterprise')}
                  options={Transformers.mapIntoOptionItem(
                    paginate.data.data,
                    'name',
                    'id'
                  ).concat([{ label: 'None', value: '' }])}
                  disabled={canUpdateCheck.isError}
                  helperText={
                    canUpdateCheck.isError
                      ? t(
                          'Editing the owner is not possible when some Assessment Requests were already attached to this campaign.'
                        )
                      : undefined
                  }
                />
              )}
            </FormGroup>
          </Grid>
          <Grid item xs={6} className={classes.grid}>
            <FormGroup>
              <Controls.FormSelect
                fullWidth
                id='type.id'
                name='type.id'
                disabled={true}
                label={t('Type')}
                options={campaignTypes?.map((campaignType: ICampaignType) => {
                  return {
                    label: t(campaignType.type),
                    value: campaignType.id,
                  };
                })}
              />
            </FormGroup>
          </Grid>
        </BaseDialog.Content>
        <BaseDialog.Actions>
          <Button
            id='close-create-campaign-'
            onClick={onClose}
            type='button'
            color='primary'
            disabled={isLoading}
          >
            {t('Cancel')}
          </Button>
          <Button
            id='submit-create-campaign'
            type='submit'
            color='primary'
            isLoading={isLoading}
          >
            {t('Validate')}
          </Button>
        </BaseDialog.Actions>
      </Controls.Form>
    </BaseDialog.Dialog>
  );
};
export default memo(Form);
