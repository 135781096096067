import {
  DEFAULT_ONE_TIME_QUERY_OPTIONS,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { DistributorsApi } from '@core/api';
import { useEssentials } from '@core/contexts';
import type {
  IDistributorEnums,
  IFormattedDistributorEnums,
} from '@core/types';

import type {
  BaseAxiosErrorResponse,
  IAPIResponse,
} from '@inteliam/foundation/lib/types';

const mapIntoSelect = (item: string) => ({
  value: item,
  label: item,
});

const useFetchCompanyQualificationEnums = (): InteliamUseQueryResult<
  IAPIResponse<IDistributorEnums>,
  BaseAxiosErrorResponse
> & {
  companyQualificationEnums: IFormattedDistributorEnums | undefined;
} => {
  const { t } = useEssentials();
  const query = useQuery<
    IAPIResponse<IDistributorEnums>,
    BaseAxiosErrorResponse
  >(
    QUERY_KEYS.COMPANY_QUALIFICATION_ENUMS,
    () => {
      return DistributorsApi.getQualificationEnums();
    },
    {
      ...DEFAULT_ONE_TIME_QUERY_OPTIONS,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      initialData: undefined,
      cacheTime: Number.POSITIVE_INFINITY,
    }
  );

  let companyQualificationEnums = undefined;
  if (query.data?.data !== undefined) {
    companyQualificationEnums = {
      // eslint-disable-next-line unicorn/no-array-callback-reference
      sizes: query.data.data.sizes.map(mapIntoSelect),
      categories: query.data.data.categories.map((category) =>
        mapIntoSelect(category)
      ),
      ownershipStructures: query.data.data.ownershipStructures.map(
        (structure) => {
          return {
            label: t(structure),
            value: structure,
          };
        }
      ),
    };
  }

  return {
    ...query,
    companyQualificationEnums,
  };
};

export default useFetchCompanyQualificationEnums;
