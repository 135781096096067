import type {
  InteliamUseQueryResult,
  InteliamUseQueryOptions,
} from '@inteliam/foundation/lib/hooks';

import { useQuery } from '@core/queries';

import { QuestionsApi } from '@core/api';

import type {
  IAPIResponse,
  IAnyQuestion,
  ISiblingsQuery,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

const useFetchSiblingQuestions = ({
  query,
  options,
}: {
  query?: ISiblingsQuery;
  options?: InteliamUseQueryOptions<
    IAPIResponse<Array<IAnyQuestion>>,
    BaseAxiosErrorResponse
  >;
} = {}): InteliamUseQueryResult<
  IAPIResponse<Array<IAnyQuestion>>,
  BaseAxiosErrorResponse
> & {
  queryKey: (string | ISiblingsQuery | undefined)[];
} => {
  const queryKey = ['siblings', query];
  const siblingQuery = useQuery<
    IAPIResponse<Array<IAnyQuestion>>,
    BaseAxiosErrorResponse
  >(
    queryKey,
    () => {
      return query
        ? QuestionsApi.fetchSiblings(query)
        : Promise.resolve({ data: [], errors: [], meta: {} });
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(query?.theme && query.criteria),
      ...options,
    }
  );

  return {
    ...siblingQuery,
    isSettled: siblingQuery.isError || siblingQuery.isSuccess,
    queryKey,
  };
};

export default useFetchSiblingQuestions;
