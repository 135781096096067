import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IMultipleChoiceQuestionForm } from '@core/types';

import { Grid, Box, Controls, FormGroup } from '@shared/components';

interface Props {
  children?: React.ReactNode;
}

const QuestionScoringConfiguration: React.FCC<Props> = ({ children }) => {
  const methods = useFormContext<IMultipleChoiceQuestionForm>();
  const { t } = useEssentials();

  const watchedCode = useWatch({
    control: methods.control,
    name: 'code',
  });
  const watchedTitle = useWatch({
    control: methods.control,
    name: 'title.defaultValue',
  });

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Box display={'flex'} fontSize={15} justifyContent='space-between'>
          <span>
            {t('{{code }} : {{ label }}', {
              code: watchedCode,
              label: watchedTitle,
            })}
          </span>
          <Box>
            <Controls.Form methods={methods} submitHandler={() => {}}>
              <Box>
                <Controls.FormInput
                  name='scoringMeta.maxScore'
                  label={t('Max Question Score')}
                  type='number'
                  min={0}
                  max={100}
                  required
                />
              </Box>
            </Controls.Form>
          </Box>
        </Box>
      </Grid>
      {children}
      <Grid item md={12} xs={12}>
        <FormGroup>
          <Controls.FormInput
            label={t('Question Scoring Guidelines')}
            name='scoringMeta.guidelines'
            rows={3}
            multiline
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default QuestionScoringConfiguration;
