import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';
import { useModalState } from '@inteliam/foundation/lib/hooks';
import { MathUtils } from '@inteliam/foundation/lib/utils';
import { isNil } from 'lodash-es';

import React, { memo, useState } from 'react';

import { clsx } from 'clsx';

import { ScoreInput } from '@core/components';
import {
  AnswersAnalysisDropdown,
  TextBoxValueRenderer,
} from '@core/components/assessment-requests/answers-analysis-v2/internals';
import {
  CreateCommentModal,
  DocumentAnalysisModal,
} from '@core/components/modals';

import { useWorkingPage } from '@core/hooks';

import type { IEvaluationStatusEnum } from '@core/enums';

import { useEssentials, useWorkingPageContext } from '@core/contexts';
import type {
  AnswerAnalysisFieldPropsV2,
  IAnswerEvaluation,
  IEvaluationProgress,
} from '@core/types';

import {
  AddCommentIcon,
  Box,
  Button,
  FormGroup,
  TableCell,
} from '@shared/components';

import { makeStyles } from '@shared/utils';

import type { IOptionProperties } from '@inteliam/foundation/lib/types';

import {
  AnswerAnalysisQuestionCell,
  AnswerAnalysisRow,
  AnswersAnalysisReferencesCell,
} from '../internals';

const useStyles = makeStyles(() => ({
  checked: {
    fontWeight: 'bold',
  },
  unchecked: {
    color: '#ccc',
  },
}));

const QUESTION_ANSWER_COLUMNS = [
  {
    label: 'Option Text',
  },
  {
    label: 'Reference',
  },
  {
    label: 'TextBox',
  },
  {
    label: 'DropDown',
  },
  {
    label: 'Max Score',
  },
  {
    label: 'Adjusted Max Score',
  },
  {
    label: 'Points Rules',
  },
  {
    label: 'Final Score',
  },
];

const SingleChoiceFormField: React.FCC<AnswerAnalysisFieldPropsV2> = ({
  answerEvaluation,
  referencesProgress,
  query,
  themeIndex,
  criterionIndex,
  questionIndex,
}) => {
  if (
    answerEvaluation.answer.field.kind !== IQuestionKindEnum.SingleChoiceField
  ) {
    throw new Error('This code should never be reached [SingleChoiceField]');
  }
  const { scoreCard, assessmentRequest } = useWorkingPage();
  const { readOnly } = useWorkingPageContext();

  const classes = useStyles();
  const { t } = useEssentials();
  const [modal, setModal] = useState<string | undefined>(undefined);
  const [modalState, modalHandlers] = useModalState<string>();
  const statuses = Object.keys(query.statuses).filter(
    (status) => query.statuses[status as IEvaluationStatusEnum] === true
  );
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const criterionPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];
  const questionPartialScore =
    criterionPartialScore.questionsPartialScores[questionIndex];

  const hasComment = !isNil(questionPartialScore.scoringMeta.comment);
  const optionsInsightsLookup: Record<string, IOptionProperties> = {};
  answerEvaluation.answer.field.definition
    .filter((option) => canShow(statuses, referencesProgress?.[option.value]))
    .forEach((option) => (optionsInsightsLookup[option.value] = option));
  const fieldName = `partialScores[${themeIndex}].criteriaPartialScores[${criterionIndex}].questionsPartialScores[${questionIndex}]`;

  return (
    <>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.FullWidth>
              <AnswerAnalysisQuestionCell
                themeIndex={themeIndex}
                criterionIndex={criterionIndex}
                questionIndex={questionIndex}
                answerEvaluation={answerEvaluation}
              />
            </Layouts.FullWidth>
          );
        }}
      </AnswerAnalysisRow>
      <AnswerAnalysisRow>
        {({ Layouts }) => {
          return (
            <Layouts.ScoringSectionTitle>
              <h3 className='font-weight-bold p-1'>{t('Scoring')}</h3>
              <Button
                onClick={modalHandlers.onOpenCreate}
                id={'create-comment'}
              >
                <AddCommentIcon color={hasComment ? 'primary' : 'disabled'} />
              </Button>
            </Layouts.ScoringSectionTitle>
          );
        }}
      </AnswerAnalysisRow>

      <AnswerAnalysisRow>
        {QUESTION_ANSWER_COLUMNS.map((column, index) => {
          return (
            <TableCell
              style={{ fontWeight: 'bold', padding: 0 }}
              key={index}
              align='center'
            >
              {t(column.label)}
            </TableCell>
          );
        })}
      </AnswerAnalysisRow>

      {questionPartialScore.optionsPartialScores.map(
        (optionPartialScore, optionIndex) => {
          const option = optionsInsightsLookup[optionPartialScore.optionId];

          if (isNil(option)) return;
          const checked = isChecked(answerEvaluation, option);
          return (
            <AnswerAnalysisRow key={option.value}>
              {({ Layouts }) => {
                return (
                  <>
                    <Layouts.Question>
                      <Box display={'flex'}>
                        <Box
                          className={clsx({
                            [classes.checked]: checked,
                            [classes.unchecked]: !checked,
                          })}
                        >
                          {option.label}
                        </Box>
                        {checked && referencesProgress?.[option.value] && (
                          <AnswersAnalysisReferencesCell
                            referenceProgress={
                              referencesProgress?.[option.value]
                            }
                            onOpen={() => setModal(answerEvaluation.id)}
                          >
                            {modal === answerEvaluation.id && (
                              <DocumentAnalysisModal
                                {...{
                                  onClose: () => setModal(undefined),
                                  assessmentRequestId: assessmentRequest.id,
                                  answerId:
                                    answerEvaluation.answer.field.answerId,
                                  optionId: option.value,
                                }}
                              />
                            )}
                          </AnswersAnalysisReferencesCell>
                        )}
                      </Box>
                    </Layouts.Question>
                    <Layouts.Reference>
                      {option.schema.reference?.required && (
                        <span>{t('Required')}</span>
                      )}
                      {!option.schema.reference?.required &&
                        option.schema.reference?.enabled && (
                          <span>{t('Optional')}</span>
                        )}
                      {!option.schema.reference?.enabled && (
                        <span>{t('No')}</span>
                      )}
                    </Layouts.Reference>
                    <Layouts.Textbox>
                      {checked && option.schema.textbox?.enabled && (
                        <TextBoxValueRenderer
                          format={option.schema.textbox.format}
                          value={option.schema.textbox.defaultValue}
                        />
                      )}
                    </Layouts.Textbox>
                    <Layouts.Dropdown>
                      {checked && option.schema.dropdown?.enabled && (
                        <AnswersAnalysisDropdown
                          dropdownSchema={option.schema.dropdown}
                        />
                      )}
                    </Layouts.Dropdown>
                    <Layouts.MaxScore>
                      <FormGroup>
                        <ScoreInput
                          readOnly={readOnly}
                          name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.maxScore`}
                          defaultValue={optionPartialScore.scoringMeta.maxScore}
                          additionalUpdate={{
                            [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                              'QUESTIONS_BASED_SCORING',
                          }}
                        />
                      </FormGroup>
                    </Layouts.MaxScore>
                    <Layouts.AdjustedMaxScore>
                      <FormGroup>
                        <ScoreInput
                          name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.adjustedMaxScore`}
                          key={optionPartialScore.scoringMeta.adjustedMaxScore}
                          defaultValue={MathUtils.precisionRound(
                            optionPartialScore.scoringMeta.adjustedMaxScore,
                            {
                              precision: 2,
                            }
                          )}
                          readOnly
                        />
                      </FormGroup>
                    </Layouts.AdjustedMaxScore>
                    <Layouts.PointsRules>
                      <FormGroup>
                        <ScoreInput
                          readOnly={readOnly}
                          name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.ratio`}
                          defaultValue={optionPartialScore.scoringMeta.ratio}
                          step={0.1}
                          min={0}
                          max={1}
                          decimal
                          additionalUpdate={{
                            [`partialScores[${themeIndex}].configuration.defaultScoringMode`]:
                              'QUESTIONS_BASED_SCORING',
                          }}
                        />
                      </FormGroup>
                    </Layouts.PointsRules>
                    <Layouts.FinalScore>
                      <FormGroup>
                        <ScoreInput
                          name={`${fieldName}.optionsPartialScores[${optionIndex}].scoringMeta.score`}
                          key={optionPartialScore.scoringMeta.score}
                          defaultValue={MathUtils.precisionRound(
                            optionPartialScore.scoringMeta.score,
                            {
                              precision: 2,
                            }
                          )}
                          readOnly
                        />
                      </FormGroup>
                    </Layouts.FinalScore>
                  </>
                );
              }}
            </AnswerAnalysisRow>
          );
        }
      )}

      {modalState.mode === 'CREATE' && (
        <CreateCommentModal
          questionPartialScore={questionPartialScore}
          fieldName={fieldName}
          scoreCard={scoreCard}
          questionId={answerEvaluation.answer.field.id}
          open={modalState.mode === 'CREATE'}
          onClose={modalHandlers.onClose}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default memo(SingleChoiceFormField);

function isChecked(
  answerEvaluation: IAnswerEvaluation,
  option: IOptionProperties
) {
  return answerEvaluation.answer.field.defaultValue
    ? (answerEvaluation.answer.field.defaultValue as string) === option.value
    : false;
}

function canShow(
  statuses: Array<string>,
  referencesProgress?: IEvaluationProgress,
  checked?: boolean
) {
  const progress = checked ? referencesProgress : undefined;

  if (progress) {
    return statuses.some((status) => {
      const key = status.toLocaleLowerCase() as keyof IEvaluationProgress;
      return progress[key] > 0;
    });
  }
  return true;
}
