/* eslint-disable @inteliam/i18n/raw-text-detected */
import * as React from 'react';

import { useWorkingPage } from '@core/hooks';

import { useFetchAnswerEvaluationByARAndClassifications } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IEvaluationQuery } from '@core/types';

import { Alert, AlertTitle, CenteredSpinner } from '@shared/components';

import type { IQuestionPartialScore } from '@inteliam/foundation/lib/types';

import { AnswersAnalysisQuestionItem } from './internals';

interface Props {
  query: IEvaluationQuery;
  themeIndex: number;
  criterionIndex: number;
}

const AnswersAnalysisTable: React.FCC<Props> = ({
  query,
  themeIndex,
  criterionIndex,
}) => {
  const { assessmentRequest, scoreCard } = useWorkingPage();
  const themePartialScore = scoreCard.partialScores[themeIndex];
  const criterionPartialScore =
    themePartialScore.criteriaPartialScores[criterionIndex];
  const answerEvaluationByARAndThemeQuery =
    useFetchAnswerEvaluationByARAndClassifications({
      assessmentRequestId: assessmentRequest.id,
      themeId: themePartialScore.theme.id,
      criterionId: criterionPartialScore.criterion.id,
    });

  const { t } = useEssentials();
  if (!answerEvaluationByARAndThemeQuery.isSettled) {
    return <CenteredSpinner />;
  }
  if (!answerEvaluationByARAndThemeQuery.isSuccess) {
    return (
      <Alert color='error'>
        {t(
          answerEvaluationByARAndThemeQuery.error?.response?.data.message ||
            'Something went wrong'
        )}
      </Alert>
    );
  }
  const questionsPartialLookup: Record<
    string,
    {
      partialScore: IQuestionPartialScore;
      index: number;
    }
  > = {};
  criterionPartialScore.questionsPartialScores.forEach(
    (questionPartialScore, index) => {
      questionsPartialLookup[questionPartialScore.question.code] = {
        partialScore: questionPartialScore,
        index,
      };
    }
  );
  return (
    <>
      {criterionPartialScore.questionsPartialScores.length === 0 && (
        <Alert severity='error'>
          <AlertTitle>
            {t('No Answers Available within this criterion')}
          </AlertTitle>
        </Alert>
      )}
      {answerEvaluationByARAndThemeQuery.data.data.answersEvaluations.map(
        (answerEvaluation) => {
          const questionPartialScore =
            questionsPartialLookup[answerEvaluation.answer.field.code];
          return (
            <AnswersAnalysisQuestionItem
              key={answerEvaluation.answer.field.code}
              questionIndex={questionPartialScore.index}
              {...{
                query,
                answerEvaluation,
                criterionIndex,
                themeIndex,
              }}
              referenceProgress={
                answerEvaluationByARAndThemeQuery.data?.data.referencesProgress[
                  answerEvaluation.answer.field.answerId
                ]
              }
            />
          );
        }
      )}
    </>
  );
};

export default React.memo(AnswersAnalysisTable);
