/* eslint-disable @inteliam/i18n/raw-text-detected */
import {
  assertIsTypedArray,
  isIContactInfo,
  isICountry,
  isString,
} from '@inteliam/foundation/lib/guards';
import { clone, isNil, setWith } from 'lodash-es';

import React, { ReactNode } from 'react';

import { isIBODistributor } from '@core/guards';
import type { IBODistributor, IMergePayload } from '@core/types';

import type { IContactInfo, ICountry } from '@inteliam/foundation/lib/types';

export enum ActionTypeEnum {
  SIMPLE = 'simple',
  SELECT_ALL = 'select-all',
}

export type SelectionType = 'DRAFT' | 'EXISTING';
export type MergeAction = {
  type: ActionTypeEnum;
  payload: {
    field?: string;
    value: IMergePayload;
    selectionType: SelectionType;
  };
};

export type MergeState = {
  company: IBODistributor;
  selection: Record<string, SelectionType>;
};

export type IMergeChanger = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: IMergePayload,
  selectionType: SelectionType
) => void;

export type IRendererInput =
  | string
  | string[]
  | boolean
  | ICountry[]
  | IContactInfo
  | undefined;

type IRenderer = (argument: IRendererInput) => ReactNode;

export const stringRenderer: IRenderer = (argument): ReactNode => {
  if (isString(argument) || typeof argument === 'number') {
    return argument;
  }
  return 'N/A';
};

export function maskText(value: string, pattern: string): string {
  let index = 0;
  const v = value.toString();
  return pattern.replace(/#/g, () => v[index++] || '');
}

export const dunsRenderer: IRenderer = (argument): ReactNode => {
  if (isString(argument)) {
    return maskText(argument, '##-###-####');
  }
  return 'N/A';
};

export const arrayRenderer: IRenderer = (argument): ReactNode => {
  if (isNil(argument)) {
    return 'N/A';
  }
  assertIsTypedArray(argument, isString);
  return argument.join(', ');
};

export const booleanRenderer: IRenderer = (argument): ReactNode =>
  argument ? 'Yes' : 'No';

export const countriesRenderer: IRenderer = (argument): ReactNode => {
  if (isNil(argument)) {
    return 'N/A';
  }
  assertIsTypedArray(argument, isICountry);
  return argument.map((c) => c.label).join(', ');
};

export const contactInfoRenderer: IRenderer = (argument): ReactNode => {
  if (isIContactInfo(argument)) {
    return (
      <div>
        <div>{`${argument.firstName || '--'} ${
          argument.lastName || '--'
        }`}</div>
        <div>({argument.email})</div>
      </div>
    );
  }
  return 'N/A';
};

export type IMergeRowType = {
  attribute: string;
  valuePropertyPath: string;
  labelPropertyPath: string;
  renderer: IRenderer;
};

export const ROWS: IMergeRowType[] = [
  {
    attribute: 'Duns',
    valuePropertyPath: 'dunsIdentifier',
    labelPropertyPath: 'dunsIdentifier',
    renderer: dunsRenderer,
  },
  {
    attribute: 'Company name',
    valuePropertyPath: 'name',
    labelPropertyPath: 'name',
    renderer: stringRenderer,
  },
  {
    attribute: 'Country',
    valuePropertyPath: 'address.country',
    labelPropertyPath: 'address.country.name',
    renderer: stringRenderer,
  },
  {
    attribute: 'National ID',
    valuePropertyPath: 'nationalIdentifier',
    labelPropertyPath: 'nationalIdentifier',
    renderer: stringRenderer,
  },
  {
    attribute: 'Address',
    valuePropertyPath: 'address.address',
    labelPropertyPath: 'address.address',
    renderer: stringRenderer,
  },
  {
    attribute: 'City',
    valuePropertyPath: 'address.city',
    labelPropertyPath: 'address.city',
    renderer: stringRenderer,
  },
  {
    attribute: 'State',
    valuePropertyPath: 'address.state',
    labelPropertyPath: 'address.state',
    renderer: stringRenderer,
  },
  {
    attribute: 'ZIP code',
    valuePropertyPath: 'address.zipCode',
    labelPropertyPath: 'address.zipCode',
    renderer: stringRenderer,
  },
  {
    attribute: 'Web site',
    valuePropertyPath: 'website',
    labelPropertyPath: 'website',
    renderer: stringRenderer,
  },
  {
    attribute: 'Incorporation date',
    valuePropertyPath: 'incorporationDate',
    labelPropertyPath: 'incorporationDate',
    renderer: stringRenderer,
  },
  {
    attribute: 'Headquarters',
    valuePropertyPath: 'headquarters.name',
    labelPropertyPath: 'headquarters.name',
    renderer: stringRenderer,
  },
  {
    attribute: 'Headquarters country',
    valuePropertyPath: 'headquarters.country',
    labelPropertyPath: 'headquarters.country.label',
    renderer: stringRenderer,
  },
  {
    attribute: 'Number of employees',
    valuePropertyPath: 'numberOfEmployees',
    labelPropertyPath: 'numberOfEmployees',
    renderer: stringRenderer,
  },
  {
    attribute: 'Size',
    valuePropertyPath: 'size',
    labelPropertyPath: 'size',
    renderer: stringRenderer,
  },
  {
    attribute: 'Category',
    valuePropertyPath: 'category',
    labelPropertyPath: 'category',
    renderer: stringRenderer,
  },
  {
    attribute: 'Turnover',
    valuePropertyPath: 'turnover.value',
    labelPropertyPath: 'turnover.value',
    renderer: stringRenderer,
  },
  {
    attribute: 'Currency',
    valuePropertyPath: 'turnover.currency',
    labelPropertyPath: 'turnover.currency',
    renderer: stringRenderer,
  },
  {
    attribute: 'companies.merge.table.utils', // WTF
    valuePropertyPath: 'turnover.utils', // WTF
    labelPropertyPath: 'turnover.utils', // WTF
    renderer: stringRenderer,
  },
  {
    attribute: 'Services/Product',
    valuePropertyPath: 'services',
    labelPropertyPath: 'services',
    renderer: stringRenderer,
  },
  {
    attribute: 'Inteliam international comments',
    valuePropertyPath: 'additionalComments',
    labelPropertyPath: 'additionalComments',
    renderer: stringRenderer,
  },
];

export const CONTACT_ROWS: (IMergeRowType & {
  valuePropertyPath: 'primaryContact';
})[] = [
  {
    attribute: 'Primary contact',
    valuePropertyPath: 'primaryContact',
    labelPropertyPath: 'primaryContact',
    renderer: contactInfoRenderer,
  },
];

export const initializeSelection = (
  selectionType: SelectionType
): Record<string, SelectionType> => {
  return [...ROWS, ...CONTACT_ROWS].reduce<Record<string, SelectionType>>(
    (accumulator, current) => {
      accumulator[current.valuePropertyPath] = selectionType;
      return accumulator;
    },
    {}
  );
};

export const mergeReducer = (
  state: MergeState,
  action: MergeAction
): MergeState => {
  if (action.type === ActionTypeEnum.SIMPLE && action.payload.field) {
    return {
      ...state,
      company: setWith(
        clone(state.company),
        action.payload.field,
        action.payload.value,
        clone
      ),
      selection: {
        ...state.selection,
        [action.payload.field]: action.payload.selectionType,
      },
    };
  }
  if (
    action.type === ActionTypeEnum.SELECT_ALL &&
    isIBODistributor(action.payload.value)
  ) {
    return {
      ...state,
      company: action.payload.value,
      selection: initializeSelection(action.payload.selectionType),
    };
  }
  return state;
};
