import {
  IHighlightsStatusEnum,
  IBORolesEnum,
} from '@inteliam/foundation/lib/enums';

import { UsersUtils } from '@core/utils';

import type {
  IMasterAnalysisHighlight,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

export const canEdit = (
  analysisHighlight: IMasterAnalysisHighlight,
  authenticatedUser: JwtBOUser
): boolean => {
  if (
    UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, authenticatedUser)
  ) {
    return true;
  }
  return analysisHighlight.status === IHighlightsStatusEnum.STATUS_DRAFT;
};

export const canDelete = (
  analysisHighlight: IMasterAnalysisHighlight,
  authenticatedUser: JwtBOUser
): boolean => {
  return (
    UsersUtils.isGranted(
      IBORolesEnum.ROLE_REGULAR_ANALYST,
      authenticatedUser
    ) && analysisHighlight.status === IHighlightsStatusEnum.STATUS_DRAFT
  );
};

export const canUpdateStatus = (authenticatedUser: JwtBOUser): boolean => {
  return UsersUtils.isGranted(
    IBORolesEnum.ROLE_SENIOR_ANALYST,
    authenticatedUser
  );
};

export const canUpdateCategory = (authenticatedUser: JwtBOUser): boolean => {
  if (canUpdateStatus(authenticatedUser)) {
    return true;
  }
  return UsersUtils.isGranted(
    IBORolesEnum.ROLE_REGULAR_ANALYST,
    authenticatedUser
  );
};
