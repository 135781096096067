import React from 'react';

import { ResourceUtils } from '@core/utils';

import { ContactLogsApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { Controls, Grid } from '@shared/components';

const FilesPreview: React.FCC = () => {
  const { t } = useEssentials();

  const onReject = () => t('File size exceeds the maximum limit');

  return (
    <Grid item xs={12}>
      <Controls.FormDropzoneArea
        name='files'
        label={t(
          'Drop files here or click to upload (Size limit: 20MB. Supported file types: pdf, word, excel, image, doc, txt, csv)'
        )}
        acceptedFiles={ResourceUtils.ACCEPTED_FILES}
        filesLimit={5}
        maxFileSize={ResourceUtils.MAX_SIZE}
        resourceReader={ContactLogsApi.getResourceDownloadablePath}
        createFunction={ContactLogsApi.createResource}
        getDropRejectMessage={onReject}
        getFileLimitExceedMessage={onReject}
      />
    </Grid>
  );
};

export default FilesPreview;
