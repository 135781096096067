/* eslint-disable @inteliam/i18n/raw-text-detected */
import { I18n } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';

import { EvaluationInsights } from '@core/components';

import {
  useFetchSurveyThemesInsightsByAR,
  useManageAssessmentRequest,
} from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IEvaluationProgress } from '@core/types';

import { Accordion, Box, ExpandMoreIcon, Typography } from '@shared/components';

import type { IRawClassification } from '@inteliam/foundation/lib/types';

type Props = {
  assessmentRequestId: string;
  theme: IRawClassification;
  children: React.ReactNode;
};

const DEFAULT_INSIGHTS: IEvaluationProgress = {
  approved: 0,
  rejected: 0,
  pending: 0,
  total: 0,
};

const AnswersAnalysisThemeAccordion: React.FCC<Props> = ({
  assessmentRequestId,
  theme,
  children,
}) => {
  const { t } = useEssentials();
  const { themesInsights } = useFetchSurveyThemesInsightsByAR({
    id: assessmentRequestId,
  });
  const { getAssociatedSurveyProgress } = useManageAssessmentRequest({
    getAssociatedSurveyProgress: {
      args: { id: assessmentRequestId },
    },
  });
  const themeName = I18n.getTranslatedValue(theme.name);

  return (
    <Accordion.Container
      defaultExpanded={false}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Accordion.Summary
        id={`${themeName}-accordion`}
        expandIcon={<ExpandMoreIcon />}
      >
        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          width='100%'
        >
          <Box display='flex' alignItems='center' width='220px'>
            <Typography>
              {t('{{theme}} ({{progress}}%)', {
                theme: themeName,
                progress: getAssociatedSurveyProgress.isSuccess
                  ? Math.trunc(
                      getAssociatedSurveyProgress.data.data?.themes[theme.id]
                        ?.progress ?? 0
                    )
                  : '-',
              })}
            </Typography>
          </Box>

          <Box display='flex' alignItems='center'>
            <Typography>{t('Answers')}</Typography>
            {` ( ${themesInsights?.[theme.id]?.answersInsights || 0} ) `}
          </Box>
          <Box p={1} display='flex' alignItems='center'>
            <Typography>{t('Evidences')}</Typography>
            <EvaluationInsights
              evaluationProgress={
                themesInsights?.[theme.id]?.referencesInsights ||
                DEFAULT_INSIGHTS
              }
            />
          </Box>
        </Box>
      </Accordion.Summary>
      <Accordion.Details style={{ display: 'grid', padding: 0 }}>
        {children}
      </Accordion.Details>
    </Accordion.Container>
  );
};

export default memo(AnswersAnalysisThemeAccordion);
