import { useActionChecker } from '@inteliam/foundation/lib/hooks';
import type { ListItemIconProps, MenuItemProps } from '@mui/material';

import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import cx from 'clsx';

import {
  Noop,
  CircularProgress,
  ListItemText,
  ListItemIcon,
  ListItem,
} from '@shared/components';

import { useGlobalStyle } from '@shared/hooks';

import type { IAPIResponse } from '@inteliam/foundation/lib/types';

type Props = MenuItemProps &
  Omit<NavLinkProps, 'children'> & {
    icon?: React.ReactNode;
    iconsProps?: ListItemIconProps;
    actionChecker?: () => Promise<IAPIResponse>;
    id: string;
    activeClassName?: string;
    children?: React.ReactNode;
    button?: boolean;
    navTarget?: string;
    navRel?: string;
  };

export default React.forwardRef<HTMLLIElement, Props>((props, ref) => {
  const {
    icon,
    to,
    activeClassName,
    iconsProps,
    actionChecker,
    id,
    navTarget,
    navRel,
    children,
    ...rest
  } = props;
  const globalStyles = useGlobalStyle();

  const { show, loading } = useActionChecker({
    checker: actionChecker,
    runOnMount: true,
  });
  if (loading) {
    return (
      // @ts-expect-error P2 TO FIX (Broken after react-router upgrade)
      <ListItem {...rest} ref={ref} disabled button>
        <CircularProgress color='inherit' size={14} />
      </ListItem>
    );
  }
  if (!show) {
    return <Noop />;
  }
  return (
    <ListItem
      component={'li'}
      {...rest}
      {...{ ref, id }}
      data-cy={id}
      style={{ padding: 0 }}
      button
    >
      <NavLink
        to={to}
        target={navTarget}
        rel={navRel}
        style={{
          padding: '8px 16px ',
        }}
        className={({ isActive }) =>
          cx(
            globalStyles.flexCenter,
            globalStyles.fullWidth,
            globalStyles.neutralLink,
            activeClassName
              ? {
                  [activeClassName]: isActive,
                }
              : ''
          )
        }
      >
        {icon && <ListItemIcon {...iconsProps}>{icon}</ListItemIcon>}
        <ListItemText primary={children} />
      </NavLink>
    </ListItem>
  );
});
