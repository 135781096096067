import { IQuestionKindEnum } from '@inteliam/foundation/lib/enums';

import type { AnswerAnalysisFieldPropsV2 } from '@core/types';

import { default as MultipleChoiceField } from './multiple-choice-field';
import { default as SingleChoiceField } from './single-choice-field';
import { default as SingleFreeFormField } from './single-free-form-field';

export const ANSWER_ANALYSIS_RENDERER_V2: Record<
  IQuestionKindEnum,
  React.ComponentType<AnswerAnalysisFieldPropsV2>
> = {
  [IQuestionKindEnum.SingleFreeFormField]: SingleFreeFormField,
  [IQuestionKindEnum.SingleChoiceField]: SingleChoiceField,
  [IQuestionKindEnum.MultipleChoiceField]: MultipleChoiceField,
};
export { default as NonConfigurableThemeScoringTable } from './table';
