import { Transformers } from '@inteliam/foundation/lib/utils';

import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';
import type { DistributorUserFormMode } from '@core/types';

import { Controls, Grid, Box, FormGroup } from '@shared/components';

import type {
  IAR,
  IPartialFODistributorUser,
} from '@inteliam/foundation/lib/types';

interface BusinessRoleProps {
  defaultValues?: IPartialFODistributorUser;
  mode: DistributorUserFormMode;
  ars: IAR[];
}

const BusinessRoleFields: React.FCC<BusinessRoleProps> = ({
  defaultValues,
  ars,
}) => {
  const { t } = useEssentials();
  return (
    <Grid item md={6} xs={12}>
      <FormGroup>
        {t('Business role')}
        <Controls.FormCheckbox
          label={t('Primary')}
          name='primaryContact'
          // value={mode === 'PRIMARY'}
          disabled={defaultValues?.primaryContact}
          helperText={
            defaultValues?.primaryContact
              ? t(
                  'You cannot uncheck primary, please choose your new primary contact first'
                )
              : undefined
          }
        />

        <Box display='flex' width='100%' marginTop='2%'>
          <Controls.FormCheckbox
            label={t('Questionnaire')}
            name='questionnaireContact'
            // value={mode === 'SURVEY'}
            disabled={(defaultValues?.questionnaireContactFor?.length || 0) > 0}
            helperText={
              (defaultValues?.questionnaireContactFor?.length || 0) > 0
                ? t(
                    'You cannot uncheck questionnaire, please choose your new questionnaire contact first'
                  )
                : undefined
            }
          />
          <FormGroup style={{ flex: 1 }}>
            <Controls.FormSelect
              label={t('Related ARs')}
              name='questionnaireContactFor'
              style={{ width: '100%' }}
              options={Transformers.mapIntoOptionItem(ars, 'businessId', 'id')}
              required
              multiple
            />
          </FormGroup>
        </Box>
      </FormGroup>
    </Grid>
  );
};

export default memo(BusinessRoleFields);
