import { defaults, isNil } from 'lodash-es';

import { Context, createContext, useContext } from 'react';

export function createNamedContext<T>(
  name: string
): React.Context<T | undefined> {
  const context = createContext<T | undefined>(undefined);
  context.displayName = name;

  return context;
}

export function useNamedContext<T>(
  context: Context<T | undefined>,
  defaultValues?: T
): T {
  const dataFromContext = useContext<T | undefined>(context);
  if (!context.displayName) {
    throw new Error(
      `Context should have a name : Hint => use createNamedContext `
    );
  }
  if (isNil(dataFromContext)) {
    throw new Error(`${context.displayName} cannot be found up the tree`);
  }
  return typeof defaultValues === 'object'
    ? defaults(dataFromContext, defaultValues)
    : dataFromContext;
}
