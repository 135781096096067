import { ICompanyKindEnum } from '@inteliam/foundation/lib/enums';
import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import type {
  IDistributorUserPayload,
  IEnterpriseUserPayload,
  IUpdateDistributorUserPayload,
  IUpdateSupplierUserPayload,
} from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type {
  CompanyKindType,
  IAPIResponse,
  IFODistributorUser,
  IFOSupplierUser,
  IFOUser,
  IPaginationQuery,
  IPaginationResponse,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

const BASE_PATH = '/users';
const client = new PrivateApi(
  {
    baseRoute: BASE_PATH,
    baseUrl: Constants.BASE_URL,
  },
  getAuthClientInstance()
);

const paginate = async (
  paginator: IPaginationQuery
): Promise<IPaginationResponse<IFOUser>> => {
  const client = new PrivateApi(
    {
      baseRoute: BASE_PATH,
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  const { data } = await client.instance.get('');

  return data;
};
const paginateByCompany = async <
  TKind extends CompanyKindType,
  TUser = TKind extends 'DISTRIBUTOR' ? IFODistributorUser : IFOSupplierUser
>(
  { kind, id }: { id: string; kind: TKind },
  paginator: IPaginationQuery
): Promise<IPaginationResponse<TUser>> => {
  const client = new PrivateApi(
    {
      baseRoute: BASE_PATH,
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: paginator,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );
  const { data } = await client.instance.get(
    `/${kind.toLowerCase()}/users/${id}`
  );
  // todo how to make guard generic
  // assertIsTypedArray(data.data, isIFOUser);
  return data;
};

const createDistributorUser = async ({
  payload,
  companyId,
}: {
  companyId: string;
  payload: IDistributorUserPayload;
}): Promise<IAPIResponse<IFODistributorUser>> => {
  const { data } = await client.instance.post(
    `${companyId}/${ICompanyKindEnum.DISTRIBUTOR.toLowerCase()}`,
    payload
  );
  // TODO make guard generic
  // assertIsTyped(data, isIFOUser);
  return data;
};

const createEnterpriseUser = async ({
  payload,
  companyId,
}: {
  companyId: string;
  payload: IEnterpriseUserPayload['user'];
}): Promise<IAPIResponse<IFOSupplierUser>> => {
  const { data } = await client.instance.post(
    `${companyId}/enterprise`,
    payload
  );
  // TODO make guard generic
  // assertIsTyped(data, isIFOUser);
  return data;
};

const updateDistributorUser = async ({
  payload,
  companyId,
}: {
  companyId: string;
  payload: IUpdateDistributorUserPayload;
}): Promise<IAPIResponse<IFODistributorUser>> => {
  const { data } = await client.instance.put(
    `${companyId}/${ICompanyKindEnum.DISTRIBUTOR.toLowerCase()}/${
      payload.user.id
    }`,
    payload
  );
  // TODO make guard generic
  // assertIsTyped(data, isIFOUser);
  return data;
};
const updateEnterpriseUser = async ({
  payload,
  companyId,
}: {
  companyId: string;
  payload: IUpdateSupplierUserPayload['user'];
}): Promise<IAPIResponse<IFOSupplierUser>> => {
  const { data } = await client.instance.put(
    `${companyId}/enterprise/${payload.id}`,
    payload
  );
  // TODO make guard generic
  // assertIsTyped(data, isIFOUser);
  return data;
};

const updateStatus = async ({
  id,
  body,
}: IPatchObject): Promise<IAPIResponse> => {
  const { data } = await client.instance.patch(`/${id}/status`, body);
  return data;
};

export default {
  paginate,
  paginateByCompany,
  createDistributorUser,
  createEnterpriseUser,
  updateDistributorUser,
  updateEnterpriseUser,
  updateStatus,
};
