/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type { IAR } from '@inteliam/foundation/lib/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPartialAR(argument: any): argument is IAR {
  return (
    typeof argument.businessId === 'string' &&
    typeof argument.scoredCompany === 'object' &&
    Array.isArray(argument.statuses)
  );
}
