import type { IDocument } from '@inteliam/foundation/lib/types';

export const initializeDocumentQualification = (
  defaultValues: IDocument
): Partial<IDocument> => {
  return {
    name: defaultValues.name,
    languageCode: defaultValues.languageCode,
    qualificationData: {
      scopeLevel: defaultValues.qualificationData?.scopeLevel || 'L0',
      name: defaultValues.qualificationData?.name || defaultValues.name,
      issueDate: defaultValues.qualificationData?.issueDate || undefined,
      validUntil: defaultValues.qualificationData?.validUntil || undefined,
      perpetual: defaultValues.qualificationData?.perpetual || false,
      scopeName: defaultValues.qualificationData?.scopeName || '',
    },
  };
};
