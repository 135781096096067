import type { InteliamUseMutationResult } from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation } from '@core/queries';

import { EnterpriseSustainabilityCardsApi } from '@core/api';
import type {
  ISustainabilityCardForm,
  ICreateSustainabilityCardPayload,
  IUpdateSustainabilityCardPayload,
} from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAnyEnterprise,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

type IQueryManagementArgs = {
  onSuccess: () => void;
};

type RemoveArguments = Parameters<
  typeof EnterpriseSustainabilityCardsApi.remove
>[0];

type IQueryManagementResult = {
  create: {
    run: (id: IAnyEnterprise['id'], data: ISustainabilityCardForm) => void;
    mutation: InteliamUseMutationResult<
      void,
      AxiosValidationErrorResponse,
      ICreateSustainabilityCardPayload,
      unknown
    >;
  };
  update: {
    run: (
      id: string,
      sustainabilityCardId: string,
      data: ISustainabilityCardForm
    ) => void;
    mutation: InteliamUseMutationResult<
      void,
      AxiosValidationErrorResponse,
      IPatchObject<IUpdateSustainabilityCardPayload>,
      unknown
    >;
  };
  remove: {
    run: (id: string, sustainabilityCardId: string) => void;
    mutation: InteliamUseMutationResult<
      void,
      AxiosValidationErrorResponse,
      RemoveArguments,
      unknown
    >;
  };
};

const useManageEnterpriseSustainabilityCards = (
  args: IQueryManagementArgs
): IQueryManagementResult => {
  const createMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    ICreateSustainabilityCardPayload
  >(EnterpriseSustainabilityCardsApi.create, {
    ...args,
  });

  const updateMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    IPatchObject<IUpdateSustainabilityCardPayload>
  >(EnterpriseSustainabilityCardsApi.update, {
    ...args,
  });

  const removeMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    RemoveArguments
  >(EnterpriseSustainabilityCardsApi.remove, {
    ...args,
  });

  const runCreate = useCallback(
    (id: IAnyEnterprise['id'], data: ISustainabilityCardForm) => {
      createMutation.mutate({
        ...data,
        enterpriseId: id,
      } as ICreateSustainabilityCardPayload);
    },
    [createMutation]
  );

  const runUpdate = useCallback(
    (
      id: IAnyEnterprise['id'],
      sustainabilityCardId: string,
      data: ISustainabilityCardForm
    ) => {
      updateMutation.mutate({
        id: sustainabilityCardId,
        body: {
          ...data,
          enterpriseId: id,
        } as IUpdateSustainabilityCardPayload,
      });
    },
    [updateMutation]
  );

  const runRemove = useCallback(
    (id: IAnyEnterprise['id'], sustainabilityCardId: string) => {
      removeMutation.mutate({
        id,
        sustainabilityCardId,
      });
    },
    [removeMutation]
  );

  return {
    create: {
      run: runCreate,
      mutation: createMutation,
    },
    update: {
      run: runUpdate,
      mutation: updateMutation,
    },
    remove: {
      run: runRemove,
      mutation: removeMutation,
    },
  };
};

export default useManageEnterpriseSustainabilityCards;
