import { get } from 'lodash-es';

import React, { memo } from 'react';

import { useEssentials } from '@core/contexts';
import type { IBODistributor, IMergePayload } from '@core/types';

import { Grid, Radio, TableCell, TableRow } from '@shared/components';

import { makeStyles } from '@shared/utils';

import type {
  IMergeChanger,
  IMergeRowType,
  IRendererInput,
  MergeState,
} from './utils';

const useStyles = makeStyles(() => ({
  cell: {
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
}));

interface Props {
  row: IMergeRowType;
  existing: IBODistributor;
  draft: IBODistributor;
  merged: MergeState;
  onChange: IMergeChanger;
}

const SimpleRow: React.FCC<Props> = ({
  row,
  existing,
  draft,
  merged,
  onChange,
}) => {
  const { t } = useEssentials();
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell align='left' component='th' scope='row'>
        {t(row.attribute)}
      </TableCell>
      <TableCell align='left' className={classes.cell}>
        <label htmlFor={`existing-${row.valuePropertyPath}`}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <div style={{ cursor: 'pointer' }}>
              {row.renderer(
                get(
                  existing,
                  row.labelPropertyPath,
                  undefined
                ) as IRendererInput
              )}
            </div>
            <Radio
              id={`existing-${row.valuePropertyPath}`}
              checked={
                get(merged.company, row.valuePropertyPath, undefined) ===
                  get(existing, row.valuePropertyPath, undefined) &&
                get(merged.selection, row.valuePropertyPath) === 'EXISTING'
              }
              value={'EXISTING'}
              name={row.valuePropertyPath}
              onChange={(event) =>
                onChange(
                  event,
                  get(
                    existing,
                    row.valuePropertyPath,
                    undefined
                  ) as IMergePayload,
                  'EXISTING'
                )
              }
            />
          </Grid>
        </label>
      </TableCell>
      <TableCell align='left' className={classes.cell}>
        <label htmlFor={`draft-${row.valuePropertyPath}`}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <div style={{ cursor: 'pointer' }}>
              {row.renderer(
                get(draft, row.labelPropertyPath, undefined) as IRendererInput
              )}
            </div>
            <Radio
              id={`draft-${row.valuePropertyPath}`}
              checked={
                get(merged.company, row.valuePropertyPath, undefined) ===
                  get(draft, row.valuePropertyPath, undefined) &&
                get(merged.selection, row.valuePropertyPath) === 'DRAFT'
              }
              value={'DRAFT'}
              name={row.valuePropertyPath}
              onChange={(event) =>
                onChange(
                  event,
                  get(draft, row.valuePropertyPath, undefined) as IMergePayload,
                  'DRAFT'
                )
              }
            />
          </Grid>
        </label>
      </TableCell>
      <TableCell align='left'>
        {row.renderer(
          get(
            merged.company,
            row.labelPropertyPath,
            undefined
          ) as IRendererInput
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(SimpleRow);
