import React, { useState, memo } from 'react';

import { useWorkingPage } from '@core/hooks';

import { useEssentials } from '@core/contexts';
import type { IEvaluationQuery } from '@core/types';

import {
  Accordion,
  Box,
  ExpandMoreIcon,
  Grid,
  Paper,
  Typography,
} from '@shared/components';

import type { IScoreCard } from '@inteliam/foundation/lib/types';

import {
  NonConfigurableThemeScoringTable,
  ConfigurableThemeScoringTable,
  AnswersAnalysisThemeBlock,
  ReferencesAnalysisInsightsBar,
  AnswersAnalysisThemeAccordion,
  AnswersAnalysisCriterionAccordion,
} from './internals';

type Props = {
  defaultExpanded?: boolean;
};

const AnswersAnalysis: React.FCC<{
  themePartialScore: IScoreCard['partialScores'][number];
  assessmentRequestId: string;
  themeIndex: number;
  query: IEvaluationQuery;
}> = memo(({ assessmentRequestId, themePartialScore, themeIndex, query }) => {
  return (
    <Grid item xs={12}>
      <AnswersAnalysisThemeAccordion themeIndex={themeIndex}>
        <Paper elevation={3} style={{ padding: '1em' }}>
          {themePartialScore.configuration?.configurableThemeScoring ? (
            <Box>
              <AnswersAnalysisThemeBlock themeIndex={themeIndex} />
              <ConfigurableThemeScoringTable
                assessmentRequestId={assessmentRequestId}
                query={query}
                themeId={themePartialScore.theme.id}
              />
            </Box>
          ) : (
            themePartialScore.criteriaPartialScores.map(
              (criterionPartialScore, criterionIndex) => {
                return (
                  <Box key={criterionPartialScore.criterion.id}>
                    <AnswersAnalysisCriterionAccordion
                      themeIndex={themeIndex}
                      criterionIndex={criterionIndex}
                    >
                      <NonConfigurableThemeScoringTable
                        themeIndex={themeIndex}
                        criterionIndex={criterionIndex}
                        query={query}
                      />
                    </AnswersAnalysisCriterionAccordion>
                  </Box>
                );
              }
            )
          )}
        </Paper>
      </AnswersAnalysisThemeAccordion>
    </Grid>
  );
});
const AnswersAnalysisContainer: React.FCC<Props> = ({
  defaultExpanded = true,
}) => {
  const { t } = useEssentials();
  const { scoreCard, assessmentRequest } = useWorkingPage();
  const [query, setQuery] = useState<IEvaluationQuery>({
    statuses: { APPROVED: true, PENDING: true, REJECTED: true },
  });

  return (
    <Accordion.Container
      defaultExpanded={defaultExpanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <Accordion.Summary
        id='answer-analysis-accordion'
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{t('Answers analysis')}</Typography>
      </Accordion.Summary>
      <Accordion.Details style={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <ReferencesAnalysisInsightsBar
              assessmentRequestId={assessmentRequest.id}
              query={query}
              setQuery={setQuery}
            />
          </Grid>
          {scoreCard.partialScores.map((themePartialScore, index) => {
            return (
              <AnswersAnalysis
                key={themePartialScore.theme.id}
                assessmentRequestId={assessmentRequest.id}
                themePartialScore={themePartialScore}
                themeIndex={index}
                query={query}
              />
            );
          })}
        </Grid>
      </Accordion.Details>
    </Accordion.Container>
  );
};

export default memo(AnswersAnalysisContainer);
