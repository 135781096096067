import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useEssentials } from '@core/contexts';

import {
  Controls,
  Popover,
  IconButton,
  Tooltip,
  SettingsIcon,
} from '@shared/components';

import type { IFilterableOption } from '@shared/types';

import type { VisibilityChangerFn } from '@inteliam/foundation/lib/types';

interface Props {
  columns: IFilterableOption[];
  onChangeVisibility: VisibilityChangerFn;
}

const Settings: React.FCC<Props> = ({ columns, onChangeVisibility }) => {
  const methods = useForm();
  const { t } = useEssentials();
  const [term, setTerm] = useState<string | undefined>(undefined);
  return (
    <Popover.Container>
      <Popover.Trigger component={Tooltip} title={t('Configure')}>
        <span>
          <IconButton id='configure-table' size='large'>
            <SettingsIcon />
          </IconButton>
        </span>
      </Popover.Trigger>
      <Popover.Content>
        <div style={{ padding: 10 }}>
          <Controls.Form {...{ methods }} submitHandler={() => {}}>
            <Controls.FormInput
              name='searchColumn'
              label='Find column'
              size='small'
              type='search'
              onChange={(event) => setTerm(event.target.value)}
            />
            {columns
              .filter((column) =>
                term
                  ? column.label.includes(term) || column.value.includes(term)
                  : true
              )
              .map((column) => (
                <div key={column.value} style={{ marginTop: 5 }}>
                  <Controls.FormSwitch
                    name={column.value}
                    label={column.label}
                    size='small'
                    labelPlacement='end'
                    checked={column.visible}
                    onChange={(event) => {
                      onChangeVisibility(column.value, event.target.checked);
                    }}
                  />
                </div>
              ))}

            <hr />
          </Controls.Form>
        </div>
      </Popover.Content>
    </Popover.Container>
  );
};

export default Settings;
