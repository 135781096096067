import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useEssentials } from '@core/contexts';
import type { IEvaluationQuery, IEvaluationProgress } from '@core/types';

import { Controls, Typography, Box } from '@shared/components';

type Props = {
  setQuery: (query: IEvaluationQuery) => void;
  query: IEvaluationQuery;
  progress?: IEvaluationProgress;
};

const EvaluationStatusFilter: React.FCC<Props> = ({
  query,
  setQuery,
  progress,
}) => {
  const { t, theme } = useEssentials();
  const methods = useForm<IEvaluationQuery>({
    defaultValues: query,
  });
  const { getValues } = methods;

  const watchedStatuses = useWatch({
    control: methods.control,
    name: 'statuses',
  });
  useEffect(() => {
    setQuery(getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(watchedStatuses));

  return (
    <Box display='flex' alignItems='center' justifyContent='flex-end'>
      <Box pr={2}>
        <Typography variant='body2'>{t('Show : ')}</Typography>
      </Box>
      <Box>
        <Controls.Form methods={methods} submitHandler={() => {}}>
          <Box display='flex'>
            <Box>
              <Controls.FormCheckbox
                name='statuses.PENDING'
                label={`${t('Pending')} (${progress?.pending || 0})`}
                defaultChecked={query.statuses.PENDING}
                labelColor='gray'
              />
            </Box>
            <Box>
              <Controls.FormCheckbox
                name='statuses.APPROVED'
                label={`${t('Approved')}  (${progress?.approved || 0})`}
                defaultChecked={query.statuses.APPROVED}
                labelColor={theme.palette.success.main}
              />
            </Box>
            <Box>
              <Controls.FormCheckbox
                name='statuses.REJECTED'
                label={`${t('Rejected')}  (${progress?.rejected || 0})`}
                defaultChecked={query.statuses.REJECTED}
                labelColor={theme.palette.error.main}
              />
            </Box>
          </Box>
        </Controls.Form>
      </Box>
    </Box>
  );
};

export default EvaluationStatusFilter;
