import { IQuestionStatusEnum } from '@inteliam/foundation/lib/enums';

import type { IOptionItem } from '@inteliam/foundation/lib/types';

// todo validate label key
export const SSQ_STATUSES: IOptionItem[] = [
  {
    label: 'draft',
    value: IQuestionStatusEnum.draft,
  },
  {
    label: 'active',
    value: IQuestionStatusEnum.active,
  },
  {
    label: 'inactive',
    value: IQuestionStatusEnum.inactive,
  },
];
