import { I18n } from '@inteliam/foundation/lib/utils';

import React, { memo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { LanguageForms } from '@core/components/questions/manage-question-languages';

import { useLanguagesByScope } from '@core/queries';

import { useEssentials } from '@core/contexts';
import type { IQuestionForm } from '@core/types';

import { Select, Grid, FormGroup } from '@shared/components';

import { makeStyles } from '@shared/utils';

const useStyles = makeStyles(() => ({
  fieldset: {
    height: '100%',
    width: '100%',
    border: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
}));

const ManageQuestionLanguagesForm: React.FC = () => {
  const { t } = useEssentials();
  const classes = useStyles();

  const methods = useFormContext<IQuestionForm>();
  const { formattedLanguages } = useLanguagesByScope({
    scope: 'QuestionnaireAdministration',
  });
  const questionType = useWatch({
    control: methods.control,
    name: 'kind',
  });

  const Component = questionType ? LanguageForms()[questionType] : undefined;
  const supportedLanguages = formattedLanguages.filter(
    (language) => language.value !== I18n.defaultLocale
  );
  const [locale, setLocale] = useState(supportedLanguages[0]?.value);

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        {supportedLanguages.length > 0 && (
          <FormGroup>
            <label htmlFor=''>
              {t('Select language to view/update translations')}
            </label>
            <Select
              id='language-select'
              name='language-select'
              required
              value={locale}
              fullWidth
              onChange={(event) => {
                setLocale(event.target.value as string);
              }}
              options={supportedLanguages}
            ></Select>
          </FormGroup>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        {supportedLanguages.length > 0 && (
          <FormGroup>
            <label htmlFor=''>{t('Default language')}</label>
            <Select
              id='disabled-language-select'
              name='disabled-language-select'
              value={I18n.defaultLocale}
              fullWidth
              disabled
              options={formattedLanguages}
            ></Select>
          </FormGroup>
        )}
      </Grid>
      <Grid container item md={12} xs={12} spacing={1}>
        <Grid item md={6} xs={6}>
          <fieldset className={classes.fieldset} disabled={!locale}>
            {!locale && <div className={classes.overlay}></div>}
            {Component && supportedLanguages.length > 0 && (
              <Component key={locale} locale={`translations.${locale}`} />
            )}
          </fieldset>
        </Grid>
        <Grid item md={6} xs={6}>
          <fieldset className={classes.fieldset} disabled>
            <div className={classes.overlay}></div>
            {Component && <Component locale={'defaultValue'} />}
          </fieldset>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(ManageQuestionLanguagesForm);
