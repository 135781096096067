/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import * as React from 'react';

import { clsx } from 'clsx';

import { makeStyles } from '@shared/utils';

import type { ResourceReaderFunction } from '@shared/types';

import type { FileObjectShape } from './dropzone-area-base';

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    'position': 'relative',
    'zIndex': 10,
    'textAlign': 'center',
    '&:hover $image': {
      opacity: 0.3,
    },
    '&:hover $removeButton': {
      opacity: 1,
    },
  },
  image: {
    height: 100,
    width: 'initial',
    maxWidth: '100%',
    color: theme.palette.text.primary,
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    boxSizing: 'border-box',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
    borderRadius: theme.shape.borderRadius,
    zIndex: 5,
    opacity: 1,
  },
  removeButton: {
    'transition': '.5s ease',
    'position': 'absolute',
    'opacity': 0,
    'top': theme.spacing(-1),
    'right': theme.spacing(-1),
    'width': 40,
    'height': 40,
    '&:focus': {
      opacity: 1,
    },
  },
}));

type Props = {
  fileObjects: Array<FileObjectShape>;
  getPreviewIcon: (
    fileObject: FileObjectShape,
    classes: Record<string, string>
  ) => React.ReactNode;
  handleRemove: (index: number) => (event: React.MouseEvent) => void;
  previewChipProps: any;
  previewGridClasses: Record<string, string>;
  previewGridProps: any;
  showFileNames?: boolean;
  useChipsForPreview?: boolean;
  resourceReader: ResourceReaderFunction;
};
const PreviewList: React.FCC<Props> = ({
  fileObjects,
  handleRemove,
  showFileNames,
  useChipsForPreview,
  previewChipProps,
  previewGridClasses,
  previewGridProps,
  getPreviewIcon,
  resourceReader,
}) => {
  const classes = useStyles();
  if (useChipsForPreview) {
    return (
      <Grid
        spacing={1}
        direction='row'
        {...previewGridProps.container}
        container={true}
        className={clsx(classes.root, previewGridClasses.container)}
      >
        {fileObjects.map((fileObject, index) => {
          return (
            <Grid
              {...previewGridProps.item}
              item={true}
              key={`${fileObject.file?.name ?? 'file'}-${index}`}
              className={classes.imageContainer}
            >
              <Chip
                variant='outlined'
                {...previewChipProps}
                label={
                  fileObject.resource ? (
                    <a
                      href={resourceReader(fileObject.resource.id)}
                      target='_blank'
                      rel='noreferrer'
                      data-cy={`open-file-${fileObject.file.name || 'NOOP'}`}
                    >
                      {fileObject.file.name}
                    </a>
                  ) : (
                    fileObject.file.name
                  )
                }
                onDelete={handleRemove(index)}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid
      spacing={8}
      {...previewGridProps.container}
      container={true}
      className={clsx(classes.root, previewGridClasses.container)}
    >
      {fileObjects.map((fileObject, index) => {
        return (
          <Grid
            xs={4}
            {...previewGridProps.item}
            item={true}
            key={`${fileObject.file?.name ?? 'file'}-${index}`}
            className={clsx(classes.imageContainer, previewGridClasses.item)}
          >
            {getPreviewIcon(fileObject, classes)}

            {showFileNames && (
              <Typography variant='body1' component='p'>
                {fileObject.file.name}
              </Typography>
            )}

            <Fab
              onClick={handleRemove(index)}
              aria-label='Delete'
              className={classes.removeButton}
            >
              <DeleteIcon />
            </Fab>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PreviewList;
