import type { ILanguagesEnum } from '@inteliam/foundation/lib/enums';

import React, { memo } from 'react';

import { Grid } from '@shared/components';

import { BaseLanguageForm } from './index';

interface Props {
  locale: ILanguagesEnum | 'defaultValue';
}
const SingleChoiceForm: React.FCC<Props> = ({ locale }) => {
  return (
    <Grid container spacing={3}>
      <BaseLanguageForm locale={locale} />
    </Grid>
  );
};

export default memo(SingleChoiceForm);
