import {
  DEFAULT_QUERY_OPTIONS,
  InteliamUseQueryOptions,
  InteliamUseQueryResult,
} from '@inteliam/foundation/lib/hooks';

import { useQuery, QUERY_KEYS } from '@core/queries';

import { FrontOfficeUserApi } from '@core/api';

import { DatatableUtils } from '@shared/utils';

import type {
  IFODistributorUser,
  IFOSupplierUser,
  IPaginationResponse,
  BaseAxiosErrorResponse,
  CompanyKindType,
} from '@inteliam/foundation/lib/types';

const useFetchCompanyUsers = <
  TKind extends CompanyKindType,
  TUser = TKind extends 'DISTRIBUTOR' ? IFODistributorUser : IFOSupplierUser
>({
  id = 'NOOP',
  kind,
}: {
  id?: string;
  kind: TKind;
  options?: InteliamUseQueryOptions<
    IPaginationResponse<TUser>,
    BaseAxiosErrorResponse
  >;
}): InteliamUseQueryResult<
  IPaginationResponse<TUser>,
  BaseAxiosErrorResponse
> => {
  return useQuery<IPaginationResponse<TUser>, BaseAxiosErrorResponse>(
    QUERY_KEYS.COMPANY_USERS_LIST(kind, id),
    () => {
      return id === 'NOOP'
        ? DatatableUtils.createInitialPaginationResponse<TUser>()
        : FrontOfficeUserApi.paginateByCompany(
            {
              id,
              kind,
            },
            {
              itemsPerPage: -1,
              sort: { id: 'desc' },
              page: 1,
              criteria: {
                operator: 'or',
                conditions: [],
              },
            }
          );
    },
    {
      ...DEFAULT_QUERY_OPTIONS,
      retry: false,
      enabled: id !== undefined,
    }
  );
};

export default useFetchCompanyUsers;
