import { ApiFactory } from '@inteliam/foundation/lib/api';

import { isICarbonAssessment } from '@core/guards';
import type { IBOCarbonAssessment } from '@core/types';

import { Constants, getAuthClientInstance } from '@shared/utils';

import type { JwtBOUser } from '@inteliam/foundation/lib/types';

type Actions = 'paginate';

const baseCarbonAssessmentApi = ApiFactory.generate<
  IBOCarbonAssessment,
  JwtBOUser,
  Actions
>({
  baseRoute: '/carbon-assessments',
  baseUrl: Constants.BASE_URL,
  authClient: getAuthClientInstance(),
  guard: isICarbonAssessment,
});

export default {
  ...baseCarbonAssessmentApi.actions,
};
