import { Helpers } from '@inteliam/foundation/lib/utils';
import { get } from 'lodash-es';

import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SearchApi } from '@core/api';
import { useEssentials } from '@core/contexts';

import { makeStyles } from '@shared/utils';

import type { CompanyFieldProps } from '@shared/types';

import { AsyncTypeahead } from '../typeahead';

const useStyles = makeStyles({
  option: {
    'fontSize': 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CompanyField: React.FC<CompanyFieldProps> = ({
  label,
  name,
  helperText,
  fullWidth,
  style,
  defaultValue,
  multiple,
  countryCode,
  kind,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useEssentials();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={Helpers.getSelectDefaultValue(defaultValue, multiple)}
      render={({ field }) => {
        return (
          <AsyncTypeahead
            defaultValue={field.value}
            fetcher={(value) =>
              SearchApi.searchCompanies({
                value,
                field: 'name',
                countryCode,
                kind,
                includeDraft: true,
              })
            }
            {...{ style, label, fullWidth, multiple, name }}
            error={!!get(errors, `${name}.message`)}
            helperText={t(
              (get(errors, `${name}.message`) ||
                helperText) as unknown as string
            )}
            classes={{
              option: classes.option,
            }}
            onChange={(value) => {
              const getValue = () => {
                if (value && Array.isArray(value))
                  return value.map((value) => value.value);
                else if (value) return value.value;
                else return undefined;
              };
              field.onChange(getValue());
            }}
            // renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
          />
        );
      }}
    />
  );
};

export default memo(CompanyField);
