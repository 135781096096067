import type { InteliamUseMutationResult } from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation } from '@core/queries';

import { SubscriptionsUtils } from '@core/utils';

import { DistributorsApi } from '@core/api';
import type { ISubscriptionStatusEntryFormData } from '@core/types';

import type {
  IAPIResponse,
  IPatchObject,
  AxiosValidationErrorResponse,
  BaseAxiosErrorResponse,
  ISubscriptionStatusEntry,
} from '@inteliam/foundation/lib/types';

interface Result {
  add: {
    run: (id: string, body: ISubscriptionStatusEntryFormData) => void;
    mutation: InteliamUseMutationResult<
      IAPIResponse<ISubscriptionStatusEntry>,
      AxiosValidationErrorResponse,
      IPatchObject<Partial<ISubscriptionStatusEntry>>,
      unknown
    >;
  };
}

const useManageSubscriptionEntry = ({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: IAPIResponse<ISubscriptionStatusEntry>) => void;
  onError?: (error: BaseAxiosErrorResponse) => void;
}): Result => {
  const addMutation = useMutation<
    IAPIResponse<ISubscriptionStatusEntry>,
    AxiosValidationErrorResponse,
    IPatchObject<Partial<ISubscriptionStatusEntry>>
  >(DistributorsApi.newLogEntry, {
    onSuccess,
    onError,
  });

  const runAdd = useCallback(
    (id: string, body: ISubscriptionStatusEntryFormData) => {
      addMutation.mutate({
        id,
        body: SubscriptionsUtils.normalizer.formDataToPayload(body),
      });
    },
    [addMutation]
  );

  return {
    add: { run: runAdd, mutation: addMutation },
  };
};
export default useManageSubscriptionEntry;
