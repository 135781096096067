import React, { memo } from 'react';

import { QUERY_KEYS, useManageAssessmentRequest } from '@core/queries';

import { ARUtils } from '@core/utils';

import { INotifierEnum, useDashboard, useEssentials } from '@core/contexts';
import type { IARDeadlinesForm } from '@core/types';

import type { IAR } from '@inteliam/foundation/lib/types';

import DeadlineForm from './deadline-form';

interface Props {
  assessmentRequest: IAR;
  open: boolean;
  onClose: () => void;
}

const UpdateASDeadlineModal: React.FCC<Props> = ({
  assessmentRequest,
  open,
  onClose,
}) => {
  const { t, confirm, notify } = useEssentials();
  const { dashboard } = useDashboard();

  const { updateDeadlines } = useManageAssessmentRequest({
    updateDeadlines: {
      options: {
        keysToInvalidateOnSuccess: [QUERY_KEYS.DASHBOARD[dashboard]],
      },
    },
  });

  const handleOnSubmit = (data: IARDeadlinesForm) => {
    confirm({
      description: t('Are you sure you want to update these deadlines ?'),
    })
      .then(() => {
        return updateDeadlines.mutateAsync(
          ARUtils.prepareDeadlinePayload({
            id: assessmentRequest.id,
            initiateSurveyForm: data,
            statuses: assessmentRequest.statuses,
          }),
          {
            onSuccess: () => {
              notify({
                message: t('The deadlines were successfully updated'),
                type: INotifierEnum.Success,
              });
              onClose();
            },
          }
        );
      })
      .catch(() => {});
  };

  return (
    <DeadlineForm
      open={open}
      onClose={onClose}
      onSubmit={handleOnSubmit}
      errors={updateDeadlines.error?.response?.data}
      statuses={assessmentRequest.statuses}
      disabledStatuses={assessmentRequest.statuses
        .filter((status) => status.passed === true)
        .map((status) => status.codename)}
      isLoading={updateDeadlines.isLoading}
      assessmentRequest={assessmentRequest}
    />
  );
};

export default memo(UpdateASDeadlineModal);
