import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '@inteliam/foundation/lib/utils';
import { debounce } from 'lodash-es';

import * as React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  useFetchScoreCardByArId,
  useManageAssessmentRequest,
  useManageScoreCard,
} from '@core/queries';

import type { IScoringForm } from '@core/types';

import { invariant } from '@shared/guards';

import type { IIdentifiedObject } from '@inteliam/foundation/lib/types';

type HookReturnType = ReturnType<typeof useManageScoreCard>;

type Result = {
  onUpdate: HookReturnType['patch']['run'];
  onReset: () => void;
  methods: UseFormReturn<IScoringForm>;
};

const scoringFormSchema = Yup.object().shape({
  score: Yup.number().when('validateScore', {
    is: true,
    then: Yup.number().min(0).max(100).required(),
    otherwise: Yup.number()
      .transform((currentValue, originalValue) => {
        // eslint-disable-next-line unicorn/no-null
        return originalValue === '' ? null : currentValue;
      })
      .nullable(),
  }),
  weight: Yup.number().when('validatedWeight', {
    is: true,
    then: Yup.number().required(),
    otherwise: Yup.number()
      .transform((currentValue, originalValue) => {
        // eslint-disable-next-line unicorn/no-null
        return originalValue === '' ? null : currentValue;
      })
      .nullable(),
  }),
});

export const useScoreCardHandlers = (): Result => {
  const scoringMethods = useForm<IScoringForm>({
    resolver: yupResolver(scoringFormSchema),
    reValidateMode: 'onChange',
    mode: 'all',
  });
  const manageScoreCard = useManageScoreCard();

  const handleOnReset = React.useCallback(() => {
    scoringMethods.reset({
      // @ts-expect-error : this is only way i found to clear the text input (null/undefined) doesn't work
      score: '',
      // @ts-expect-error : this is only way i found to clear the text input undefined doesn't work
      weight: null, // eslint-disable-line unicorn/no-null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdate: Result['onUpdate'] = (...args) => {
    manageScoreCard.patch.run(...args);
    // TODO update scoringFormSchema
    // scoringFormSchema
    //   .validate(scoringMethods.getValues())
    //   .then(() => {
    //     manageScoreCard.patch.run(...args);
    //   })
    //   .catch(() => {});
  };

  const debouncedOnUpdate = debounce(onUpdate, 700);
  return {
    onUpdate: debouncedOnUpdate,
    onReset: handleOnReset,
    methods: scoringMethods,
  };
};

export const useWorkingPage = () => {
  const { id } = useParams<IIdentifiedObject>();
  const { getOneById } = useManageAssessmentRequest({
    getOneById: { args: { id } },
  });
  const scoreCardQuery = useFetchScoreCardByArId({ id: id });

  invariant(id, 'Assessment request id is required');
  invariant(scoreCardQuery.data?.data, 'Empty scorecard');
  invariant(getOneById.data?.data, 'Empty assessment request');
  return {
    scoreCard: scoreCardQuery.data?.data,
    assessmentRequest: getOneById.data?.data,
  };
};
