import '@inteliam/foundation/lib/hooks';

import type { QueryKey } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useQuery, useMutation } from '@core/queries';

import { DistributorsApi, FrontOfficeUserApi } from '@core/api';
import { INotifierEnum, useEssentials } from '@core/contexts';

import type {
  IAPIResponse,
  IPatchObject,
  IUserUpdateStatusInput,
  BaseAxiosErrorResponse,
} from '@inteliam/foundation/lib/types';

type UpdateFOUserStatusQuery = (input: {
  queryKey?: QueryKey;
  companyId?: string;
  userId?: string;
}) => {
  run: (data: IUserUpdateStatusInput) => void;
  canRun: () => Promise<unknown>;
};
const useUpdateFOUserStatus: UpdateFOUserStatusQuery = ({
  queryKey = [],
  userId,
  companyId,
}) => {
  const { t, notify } = useEssentials();

  const mutation = useMutation<
    IAPIResponse,
    BaseAxiosErrorResponse,
    IPatchObject
  >(FrontOfficeUserApi.updateStatus, {
    keysToInvalidateOnSuccess: [queryKey],
    onSuccess: () => {
      notify({
        message: t('User status successfully updated'),
        type: INotifierEnum.Success,
      });
    },
    onError() {
      notify({
        message: t('Something went wrong'),
        type: INotifierEnum.Error,
      });
    },
  });
  const { refetch } = useQuery<IAPIResponse, BaseAxiosErrorResponse, string>(
    ['CAN_ENABLE_USER', companyId],
    () =>
      companyId ? DistributorsApi.canEnableUsers(companyId) : Promise.reject(),
    {
      enabled: Boolean(companyId),
      cacheTime: 1000 * 10,
    }
  );

  const run = useCallback(
    (data: IUserUpdateStatusInput) => {
      if (userId)
        mutation.mutate({
          id: userId,
          body: {
            status: data.status,
            sendEmail: data.sendEmail,
          },
        });
      else {
        throw new Error('User id is mandatory');
      }
    },
    [mutation, userId]
  );

  const canRun = useCallback(() => {
    return refetch({ throwOnError: true });
  }, [refetch]);

  return {
    run,
    canRun,
  };
};
export default useUpdateFOUserStatus;
