/* eslint-disable @inteliam/i18n/raw-text-detected */
import { IBORolesEnum } from '@inteliam/foundation/lib/enums';

import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useScoreCardHandlers } from '@core/hooks';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { Box, Controls } from '@shared/components';

import type { IPartialScore, IScoreCard } from '@inteliam/foundation/lib/types';

type Props = {
  partialScore: IPartialScore;
  scoreCard: IScoreCard;
  themeIndex: number;
};

const ReviewGroupingSwitch: React.FCC<Props> = ({
  partialScore,
  scoreCard,
  themeIndex,
}) => {
  const { t, user } = useEssentials();
  const methods = useForm();
  const { onUpdate } = useScoreCardHandlers();
  const location = useLocation();
  if (!location.pathname.includes(partialScore.theme.id)) {
    return <></>;
  }
  return (
    <Box p={2}>
      <Controls.Form {...{ methods }} submitHandler={() => {}}>
        <Controls.FormSwitch
          name={`partialScores[${themeIndex}].configuration.groupableReviews`}
          label={t('Group S/IA by criteria')}
          size='small'
          labelPlacement='start'
          checked={partialScore.configuration?.groupableReviews}
          onChange={(_, value) => {
            onUpdate(
              {
                [`partialScores[${themeIndex}].configuration.groupableReviews`]:
                  value,
              },
              scoreCard.id
            );
          }}
          disabled={
            !partialScore.configuration?.configurableReviewsGrouping ||
            !UsersUtils.isGranted(IBORolesEnum.ROLE_SENIOR_ANALYST, user)
          }
        />
      </Controls.Form>
    </Box>
  );
};

export default React.memo(ReviewGroupingSwitch);
