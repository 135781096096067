import type { InteliamUseMutationResult } from '@inteliam/foundation/lib/hooks';

import { useCallback } from 'react';

import { useMutation } from '@core/queries';

import { DiscountsUtils } from '@core/utils';

import { EnterpriseDiscountsApi } from '@core/api';
import type {
  ICreateDiscountPayload,
  IDiscountForm,
  IDiscountPayload,
} from '@core/types';

import type {
  AxiosValidationErrorResponse,
  IAnyEnterprise,
  IPatchObject,
} from '@inteliam/foundation/lib/types';

type IQueryManagementArgs = {
  onSuccess: () => void;
};

type IQueryManagementResult = {
  create: {
    run: (id: IAnyEnterprise['id'], data: IDiscountForm) => void;
    mutation: InteliamUseMutationResult<
      void,
      AxiosValidationErrorResponse,
      ICreateDiscountPayload,
      unknown
    >;
  };
  update: {
    run: (id: IAnyEnterprise['id'], data: IDiscountForm) => void;
    mutation: InteliamUseMutationResult<
      void,
      AxiosValidationErrorResponse,
      IPatchObject<IDiscountPayload>,
      unknown
    >;
  };
};

const useManageEnterpriseDiscounts = (
  args: IQueryManagementArgs
): IQueryManagementResult => {
  const createMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    ICreateDiscountPayload
  >(EnterpriseDiscountsApi.create, {
    ...args,
  });

  const updateMutation = useMutation<
    void,
    AxiosValidationErrorResponse,
    IPatchObject<IDiscountPayload>
  >(EnterpriseDiscountsApi.update, {
    ...args,
  });

  const runCreate = useCallback(
    (id: IAnyEnterprise['id'], data: IDiscountForm) => {
      createMutation.mutate(
        DiscountsUtils.normalizer.formDataToPayload(data, id)
      );
    },
    [createMutation]
  );

  const runUpdate = useCallback(
    (id: IAnyEnterprise['id'], data: IDiscountForm) => {
      updateMutation.mutate({
        id,
        body: DiscountsUtils.normalizer.formDataToPayload(data, id),
      });
    },
    [updateMutation]
  );

  return {
    create: {
      run: runCreate,
      mutation: createMutation,
    },
    update: {
      run: runUpdate,
      mutation: updateMutation,
    },
  };
};

export default useManageEnterpriseDiscounts;
