import * as React from 'react';

import { UsersUtils } from '@core/utils';

import { useEssentials } from '@core/contexts';

import { TooltippedCell, Grid } from '@shared/components';

import type { IFODistributorUser } from '@inteliam/foundation/lib/types';

const DistributorUserOptionLabel: React.FCC<{ user: IFODistributorUser }> = ({
  user,
}) => {
  const { t } = useEssentials();
  return (
    <div style={{ width: 600, display: 'flex' }}>
      <Grid item xs={2}>
        <TooltippedCell textOverflowHidden title={user.contactInfo.firstName}>
          <span>{user.contactInfo.firstName}</span>
        </TooltippedCell>
      </Grid>
      <Grid item xs={2}>
        <TooltippedCell textOverflowHidden title={user.contactInfo.lastName}>
          <span>{user.contactInfo.lastName}</span>
        </TooltippedCell>
      </Grid>
      <Grid item xs={2}>
        <span>
          {UsersUtils.getTranslatedUserRoles(user.roles, t).join(',')}
        </span>
      </Grid>
      <Grid item xs={4}>
        <span className='d-flex'>
          {user.primaryContact && t('Primary')}
          {user.primaryContact && user.questionnaireContact && ' - '}
          {user.questionnaireContact && t('Questionnaire')}
        </span>
      </Grid>
    </div>
  );
};
export default DistributorUserOptionLabel;
