import { ApiFactory } from '@inteliam/foundation/lib/api';
import { PrivateApi, QueryString } from '@inteliam/foundation/lib/utils';

import { isIARAlert } from '@core/guards';
import type { IARAlert } from '@core/types';

import { getAuthClientInstance, Constants } from '@shared/utils';

import type { IApexChartDataSet } from '@shared/types';

import type {
  IAPIResponse,
  IPaginationQuery,
  IPaginationResponse,
  JwtBOUser,
} from '@inteliam/foundation/lib/types';

type AlertActions = 'paginate';

const baseAlertActions = ApiFactory.generate<IARAlert, JwtBOUser, AlertActions>(
  {
    baseRoute: '/alerts',
    baseUrl: Constants.BASE_URL,
    authClient: getAuthClientInstance(),
    guard: isIARAlert,
  }
);

const paginateBySupplier = async (
  manufacturerId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  query: IPaginationQuery
): Promise<IPaginationResponse<IARAlert>> => {
  const client = new PrivateApi(
    {
      baseRoute: '/alerts',
      baseUrl: Constants.BASE_URL,
    },
    getAuthClientInstance(),
    {
      params: query,
      paramsSerializer: {
        serialize: QueryString.serialize,
      },
    }
  );

  const { data } = await client.instance.get(`/${manufacturerId}`);
  // assertIsTypedArray(data.data, guard);
  return data;
};

const getChartByEnterprise = async (
  manufacturerId: string,
  campaigns: Array<string>
): Promise<IAPIResponse<IApexChartDataSet>> => {
  const { data } = await baseAlertActions.client.instance.post(
    `/${manufacturerId}/chart`,
    {
      campaigns,
    }
  );

  return data;
};

export default {
  ...baseAlertActions.actions,
  paginateBySupplier,
  getChartByEnterprise,
};
